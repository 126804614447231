<div class="row">
    <div class="col-md-7">
        <h2>
            Tenants
        </h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <br />
</div>
<mt-entity-list
    [entities]="tenants"
    [total]="total"
    [(currentPage)]="currentPage"
    (pageChanged)="getTenants()"
    (itemSelectedEvent)="tenantSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
></mt-entity-list>
