<app-editable-list
    *ngIf="tenantSettingsForm"
    componentTitle="AD Settings"
    [items]="tenant?.TenantAdSettings"
    [dynamicForm]="tenantSettingsForm"
    [columns]="columns"
    [canAdd]="canAdd"
    [canRemove]="canRemove"
    [sortable]="sortable"
    [duplicateCheck]="duplicateCheck"
    (onSave)="saveSettings($event)"
></app-editable-list>
