import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { EmailContactService } from '../services/email-contact.service';
import { EmailContactDynamicConfig } from '../email-contact.dynamic-config';
import { IEmailContact } from '../../model/interfaces/email-contact';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { DynamicFieldType, DynamicFieldTypes, DynamicLabel, InputTypes } from '@mt-ng2/dynamic-form';
import { IUser } from '../../model/interfaces/user';
import { UserService } from '../../users/user.service';

@Component({
    selector: 'app-email-contact-basic-info',
    templateUrl: './email-contact-basic-info.component.html',
})
export class EmailContactBasicInfoComponent implements OnInit {
    @Input() emailContact: IEmailContact;
    @Input() canEdit: boolean;

    isEditing = false;
    isHovered: boolean;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: EmailContactDynamicConfig<IEmailContact>;
    doubleClickIsDisabled = false;
    currUser: IUser;

    get isNewEmailContact(): boolean {
        return this.emailContact && this.emailContact.Id ? false : true;
    }

    constructor(
        private emailContactService: EmailContactService,
        private notificationsService: NotificationsService,
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.authService.currentUser.subscribe((u) => {
            this.userService.getById(u.Id).subscribe((user) => {
                this.currUser = user;
            });
            this.setConfig();
        });
    }

    setConfig(): void {
        this.formFactory = new EmailContactDynamicConfig<IEmailContact>(this.emailContact);
        if (this.isNewEmailContact) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }
        this.addCreatedByOrUpdatedLabelToView();
    }

    addCreatedByOrUpdatedLabelToView(): void {
        if (!this.config || !this.config.viewOnly) {
            return;
        }
        if (this.emailContact.LastUpdatedById) {
            this.config.viewOnly.push(
                new DynamicLabel({
			    label: 'Last Updated By',
			    value: this.emailContact.LastUpdatedById
			        ? this.emailContact.LastUpdatedBy.FirstName + ' ' + this.emailContact.LastUpdatedBy.LastName
			        : 'None',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textbox,
			        scale: null,
			    })
			}
                ),
                new DynamicLabel({
			    label: 'Last Updated On',
			    value: this.emailContact.LastUpdatedOn ? this.emailContact.LastUpdatedOn : 'Unknown',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.DateInput,
			        scale: null,
			    })
			}
                ),
            );
        } else {
            this.config.viewOnly.push(
                new DynamicLabel({
			    label: 'Created By',
			    value: this.emailContact.CreatedById ? this.emailContact.CreatedBy.FirstName + ' ' + this.emailContact.CreatedBy.LastName : 'None',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textbox,
			        scale: null,
			    })
			}
                ),
                new DynamicLabel({
			    label: 'Created On',
			    value: this.emailContact.CreatedOn ? this.emailContact.CreatedOn : 'Unknown',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.DateInput,
			        scale: null,
			    })
			}
                ),
            );
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewEmailContact) {
            void this.router.navigate(['/emailContacts']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.emailContact, form.value.EmailContact as IEmailContact);
            this.saveEmailContact();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    private saveEmailContact(): void {
        if (this.isNewEmailContact) {
            const todayDate = new Date();
            this.emailContact.CreatedById = this.currUser.Id;
            this.emailContact.CreatedBy = this.currUser;
            this.emailContact.CreatedOn = todayDate;
            this.emailContactService
                .create(this.emailContact)                .subscribe((answer) => {
                    this.emailContact.Id = answer;
                    this.success(true);
                });
        } else {
            const todayDate = new Date();
            this.emailContact.LastUpdatedById = this.currUser.Id;
            this.emailContact.LastUpdatedBy = this.currUser;
            this.emailContact.LastUpdatedOn = todayDate;
            this.emailContactService
                .update(this.emailContact)                .subscribe((success) => {
                    this.success();
                });
        }
    }

    private success(newEmailContactSave?: boolean): void {
        if (newEmailContactSave) {
            void this.router.navigate([`/emailContacts/${this.emailContact.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.emailContactService.emitChange(this.emailContact);
        this.notificationsService.success('Email Contact saved successfully.');
    }
}
