export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    Users_SeeAll = 3,
    UserRoles = 4,
    Tenants = 5,
    Tenants_SeeAll = 6,
    Funds = 7,
    Clients = 8,
    Clients_SeeAll = 9,
    Tasks = 10,
    Tasks_SeeAll = 11,
    SubmitHelp = 12,

    Audit = 13,

    Users_CreateExternalTenant = 14,
    Users_CreateInternalTenant = 15,

    Investments = 16,
    Inbox = 17,
    TaskTypes = 18,
    ManageFundAltNames = 19,

    Customers = -1,
    Customers_CanChangeStatus = -2,

    AssignMessageAny = 20,
    AssignMessageSelf = 21,

    CreateOrganizationMessageSearches = 22,

    ProcessAttachments = 23,
    Tags = 24,
    ManageNotes = 25,
    EmailContacts = 26,

    Funds_SeePage = 27,
    TaxPositions_SeePage = 28,

    Tasks_canViewAllTasksRegardlessOfStatus = 29,
}
