

import { ClaimTypes } from '../model/ClaimTypes';
import { ClaimValues } from '@mt-ng2/auth-module';
import { KeyboardKeys } from '@mt-ng2/keyboard-shortcuts-module';
import { NavSidebarRowItem, NavSidebarParentRowItem } from '@mt-ng2/nav-module';

// tslint:disable:object-literal-sort-keys
export const appNavMenu: (NavSidebarRowItem | NavSidebarParentRowItem)[] = [
    new NavSidebarParentRowItem({
        children: [
            new NavSidebarRowItem({
                addLink: '/users/add',
                addClaimType: ClaimTypes.Users,
                addClaimValues: [ClaimValues.FullAccess],
                claimType: ClaimTypes.UserRoles,
                content: 'Users',
                icon: 'fa fa-fw fa-user',
                link: '/users',
            }),
            new NavSidebarRowItem({
                addLink: '/roles/add',
                addClaimType: ClaimTypes.UserRoles,
                addClaimValues: [ClaimValues.FullAccess],
                claimType: ClaimTypes.AppSettings,
                claimValues: [ClaimValues.FullAccess],
                content: 'User Roles',
                icon: 'fa fa-fw fa-lock',
                link: '/roles',
            }),
            new NavSidebarRowItem({
                claimType: ClaimTypes.Tenants_SeeAll,
                content: 'Tenants',
                icon: 'fa fa-fw fa-group',
                link: '/tenants',
            }),
            new NavSidebarRowItem({
                addLink: '/documentTypes/add',
                addClaimType: ClaimTypes.TaskTypes,
                addClaimValues: [ClaimValues.FullAccess],
                claimType: ClaimTypes.TaskTypes,
                claimValues: [ClaimValues.FullAccess],
                content: 'Document Types',
                icon: 'fa fa-fw fa-lock',
                link: '/documentTypes',
            }),
            new NavSidebarRowItem({
                addLink: '/tags/add',
                addClaimType: ClaimTypes.Tags,
                addClaimValues: [ClaimValues.FullAccess],
                claimType: ClaimTypes.Tags,
                claimValues: [ClaimValues.FullAccess],
                content: 'Tags',
                icon: 'fa fa-fw fa-briefcase',
                link: '/tags',
            }),
            new NavSidebarRowItem({
                addLink: '/attachment-tag/add',
                addClaimType: ClaimTypes.Tags,
                addClaimValues: [ClaimValues.FullAccess],
                claimType: ClaimTypes.Tags,
                claimValues: [ClaimValues.FullAccess],
                content: 'Attachment Tags',
                icon: 'fa fa-fw fa-briefcase',
                link: '/attachment-tag',
            }),
            new NavSidebarRowItem({
                claimType: ClaimTypes.ManageNotes,
                claimValues: [ClaimValues.FullAccess],
                content: 'Notes',
                icon: 'fa fa-fw fa-briefcase',
                link: '/manageNotes',
            }),
            new NavSidebarRowItem({
                addLink: '/emailContacts/add',
                addClaimType: ClaimTypes.EmailContacts,
                addClaimValues: [ClaimValues.FullAccess],
                claimType: ClaimTypes.EmailContacts,
                claimValues: [ClaimValues.FullAccess],
                content: 'Contacts',
                icon: 'fa fa-fw fa-university',
                link: '/emailContacts',
            }),
        ],
        claimType: ClaimTypes.UserRoles,
        content: 'Administration',
        icon: 'fa fa-fw fa-university',
        settingsLink: '/settings',
        settingsClaimType: ClaimTypes.AppSettings,
    }),
    // new NavSidebarRowItem({
    //     claimType: ClaimTypes.Audit,
    //     content: 'Audit Logs',
    //     icon: 'fa fa-fw fa-list-alt',
    //     link: '/audit-logs',
    //     shortcut: {
    //         keyBinding: [KeyboardKeys.Alt, KeyboardKeys.Shift, 'A'],
    //         description: 'go to audit-logs',
    //     },
    // }),
    /* new NavSidebarRowItem({
        content: 'Dashboard',
        icon: 'fa fa-fw fa-list-alt',
        link: '/home',
    }), */
    new NavSidebarRowItem({
        claimType: ClaimTypes.Funds_SeePage,
        content: 'Funds',
        icon: 'fa fa-fw fa-briefcase',
        link: '/funds',
    }),
    new NavSidebarRowItem({
        addLink: '/taxPositions/add',
        addClaimType: ClaimTypes.Investments,
        addClaimValues: [ClaimValues.FullAccess],
        claimType: ClaimTypes.TaxPositions_SeePage,
        content: 'Tax Positions',
        icon: 'fa fa-fw fa-briefcase',
        link: '/taxPositions',
    }),
    new NavSidebarRowItem({
        claimType: ClaimTypes.Clients_SeeAll,
        content: 'Clients',
        icon: 'fa fa-fw fa-briefcase',
        link: '/clients',
    }),
    new NavSidebarRowItem({
        claimType: ClaimTypes.Tasks,
        addClaimValues: [ClaimValues.FullAccess, ClaimValues.ReadOnly],
        content: 'Library',
        icon: 'fa fa-fw fa-briefcase',
        link: '/library',
    }),
    new NavSidebarRowItem({
        claimType: ClaimTypes.Inbox,
        content: 'Inbox',
        icon: 'fa fa-fw fa-briefcase',
        link: '/inbox',
    }),
    new NavSidebarRowItem({
        claimType: ClaimTypes.Audit,
        content: 'Audit Logs',
        icon: 'fa fa-fw fa-list-alt',
        link: '/audit',
        shortcut: {
            keyBinding: [KeyboardKeys.Alt, KeyboardKeys.Shift, 'A'],
            description: 'go to audit-logs',
        },
    }),
];
