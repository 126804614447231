import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { IDboMessageManagedNote } from '../model/interfaces/dbo-message-managed-note';
import { ViewNoteComponent } from '../common/components/notes-modal/view-note-component';
import { IEntityListConfigWithNotes } from '../model/interfaces/custom/EntityListConfigWithNotes';
import { MessageManagedNotesService } from './message-managed-notes.service';
import { EditableNoteComponent } from './EditableNoteComponent';
import { AddDeleteNoteComponent } from './AddDeleteNoteComponent';

export class MessageNotesEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    component: EditableNoteComponent,
                    name: 'Content',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
            delete: new EntityListDeleteColumn({
                component: AddDeleteNoteComponent,
                confirm: false,
                headerText: 'Action',
            }),
        };
        super(listConfig);
    }
}
