/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { ITenant } from './../../../model/interfaces/tenant.d';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { common } from '@mt-ng2/common-functions';

import { IAuthUser } from '../../../model/interfaces/auth-user';
import { AuthEntityService } from '../../auth-entity.service';
import { AuthUserDynamicConfig } from '../auth-user.dynamic-config';
import { forkJoin, Subscription, TeardownLogic } from 'rxjs';
import { TenantService } from '../../../tenants/tenant.service';
import { IUserRoleType } from '../../../model/interfaces/user-role-type';
import { UserRoleTypeService } from '../../../user-roles/user-role-type.service';
import { ClaimsService, AuthService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../../model/ClaimTypes';
import { TenantTypeEnum, UserRoleEnum, RoleTypeEnum } from '../../../common/constants/Enum';
import { UserService } from '../../../users/user.service';

@Component({
    selector: 'app-auth-user-portal-access',
    templateUrl: './auth-user-portal-access.html',
})
export class AuthUserPortalAccessComponent implements OnInit {
    @Input('AuthUser') authUser: IAuthUser;
    @Input('canEdit') canEdit: boolean;
    @Output('updateVersion') updateVersion: EventEmitter<string> = new EventEmitter<string>();
    isEditing: boolean;
    isHovered: boolean;
    roleTypes: IUserRoleType[];
    tenants: ITenant[];
    config: any = {};
    userId: number;
    formFactory: AuthUserDynamicConfig<IAuthUser>;
    doubleClickIsDisabled = false;
    configControls: string[] = ['Username', 'TenantId', 'RoleTypeId'];
    userForm: any;
    subscription = new Subscription();
    hasAddInternalUserAccess = false;
    hasAddExternalUserAccess = false;
    displaySuperAdmin = false;
    tenantIdOfLoggedUser: number;

    constructor(
        private route: ActivatedRoute,
        private authEntitiyService: AuthEntityService,
        private notificationsService: NotificationsService,
        private tenantService: TenantService,
        private roleTypeService: UserRoleTypeService,
        private claimsService: ClaimsService,
        private userService: UserService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.displaySuperAdmin = this.authUser.TenantTypeId === TenantTypeEnum.Internal ? true : false;
        this.config = { formObject: [], viewOnly: [] };
        this.hasAddInternalUserAccess = this.claimsService.hasClaim(ClaimTypes.Users_CreateInternalTenant, [ClaimValues.FullAccess]);
        this.hasAddExternalUserAccess = this.claimsService.hasClaim(ClaimTypes.Users_CreateExternalTenant, [ClaimValues.FullAccess]);
        forkJoin(
            this.roleTypeService.getAll(),
            this.tenantService.getAll(),
            this.userService.getById(this.authService.currentUser.getValue().Id),
        ).subscribe((response) => {
            const [roleTypes, tenants, loggedInUser] = response;
            this.roleTypes = roleTypes;
            this.tenants = tenants;
            this.tenantIdOfLoggedUser = loggedInUser.AuthUser.TenantId;
            this.setConfig();
        });
        this.userId = +this.route.snapshot.params.userId;
    }

    setConfig(): void {
        this.formFactory = new AuthUserDynamicConfig<IAuthUser>(
            this.authUser,
            false,
            this.selectTenantDisplay(),
            this.displaySuperAdmin ? this.roleTypes : this.roleTypes.filter((element) => element.Id !== RoleTypeEnum.SuperAdmin),
            this.configControls,
        );
        this.config = this.formFactory.getForUpdate();
    }

    selectTenantDisplay(): ITenant[] {
        let filteredTenants = this.tenants;
        if (!this.hasAddInternalUserAccess) {
            filteredTenants = filteredTenants.filter((e) => e.TenantTypeId === TenantTypeEnum.External);
        }
        if (!this.hasAddExternalUserAccess) {
            filteredTenants = filteredTenants.filter((element) => element.Id === this.tenantIdOfLoggedUser);
        }
        return filteredTenants;
    }

    formSubmitted(form: any): void {
        if (form.valid) {
            this.authEntitiyService
                .updatePortalAccess(
                    this.authUser.Id,
                    form.value.AuthUser.Username as string,
                    +form.value.AuthUser.TenantId,
                    +form.value.AuthUser.RoleTypeId,
                ).subscribe(() => {
                    this.success();
                    this.authUser.RoleTypeId = form.value.AuthUser.RoleTypeId;
                    this.authUser.TenantId = form.value.AuthUser.TenantId;
                    this.authUser.Username = form.value.AuthUser.Username;
                    this.setConfig();
                    this.isEditing = false;
                }, () => this.error('You may not have authorization to make this update.'));
        } else {
            common.markAllFormFieldsAsTouched(form);
            this.error();
            setTimeout(() => {
                
            });
        }
    }

    changeAccess(): void {
        this.authUser.HasAccess = !this.authUser.HasAccess;
        this.authEntitiyService.changeAccess(this.authUser.Id, this.authUser.HasAccess).subscribe(() => {
            this.isEditing = false;
            this.success();
        });
    }
    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancel(): void {
        this.isEditing = false;
    }

    error(msg = ''): void {
        if(msg){
            this.notificationsService.error(msg);
        }else{
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    formCreated(e): void {
        this.userForm = e;
        if (this.userForm.get('AuthUser.TenantId')) {
            this.subscription.add(
                this.userForm.get('AuthUser.TenantId').valueChanges.subscribe((val: number) => {
                    this.tenantService.getById(val).subscribe((data) => {
                        this.displaySuperAdmin = data.TenantTypeId === TenantTypeEnum.Internal;
                        this.setConfig();
                    });
                }) as TeardownLogic,
            );
        }
    }
}
