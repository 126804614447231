import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { ManageNotesRoutingModule } from './message-managed-notes-routing.module';
import { MessageManagedNotesComponent } from './message-managed-notes-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditableNoteComponent } from './EditableNoteComponent';
import { AddDeleteNoteComponent } from './AddDeleteNoteComponent';

@NgModule({
    declarations: [MessageManagedNotesComponent, EditableNoteComponent, AddDeleteNoteComponent],
    imports: [SharedModule, ManageNotesRoutingModule, NgSelectModule]
})
export class ManagedNotesModule {}
