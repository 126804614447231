import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ICustomerStatus } from '../../model/interfaces/customer-status';
import { StaticMetaItemService } from '@mt-ng2/base-service';

@Injectable()
export class CustomerStatusService extends StaticMetaItemService<ICustomerStatus> {
    constructor(public http: HttpClient) {
        super('CustomerStatusService', 'Status', 'StatusIds', '/customerstatuses', http);
    }
}
