import { NgModule } from '@angular/core';
import { ModalModule } from '@mt-ng2/modal-module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EntityListModule } from '@mt-ng2/entity-list-module';
import { ViewAuditComponent } from './view-audit-component';
import { AuditModalComponent } from './audit-modal-component';
import { AuditTaskChangesComponent } from './view-audit-task/audit-task-changes.component';
@NgModule({
    declarations: [ViewAuditComponent, AuditModalComponent, AuditTaskChangesComponent],
    imports: [ModalModule, CommonModule, FormsModule, NgbModule]
})
export class AuditModalModule {}
