import { Component, EventEmitter, Output, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IModalOptions } from '@mt-ng2/modal-module';
import { NotesUpdatedService } from './notes-modal-updated-service';
import { INoteService, INoteEntity } from '../../../model/interfaces/custom/EntityListConfigWithNotes';
import { IEntityListConfig } from '@mt-ng2/entity-list-module';
import { IMessageNote } from '../../../model/interfaces/message-note';
import { AuthService } from '@mt-ng2/auth-module';
import { IDboMessageManagedNote } from '../../../model/interfaces/dbo-message-managed-note';
import { MessageManagedNotesService } from '../../../message-managed-notes/message-managed-notes.service';

@Component({
    selector: 'app-notes-modal',
    styles: [
        `
            .fullModal {
                overflow-y: initial !important;
            }

            .scrollModal {
                height: 30vh;
                overflow-y: auto;
                overflow-x: hidden;
                width: 100%;
            }

            .notes {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: column;
                font-size: 14px;
            }
            .singleNote {
                display: table-row;
                vertical-align: top;
                padding: 8px;
                margin-left: 20px;
                text-align: left;
            }

            .noteBy {
                font-weight: 500;
            }

            .noteOn {
                font-size: 11px;
            }

            .noteText {
                margin-top: 20px;
                margin-bottom: 10px;
                margin-right: 20px;
            }

            .noteHeader {
                margin-left: 10px;
                text-align: left;
            }

            .buttonGroup {
                float: right;
                margin-top: 5%;
            }
        `,
    ],
    templateUrl: './notes-modal-component.html',
})
export class NotesModalComponent implements OnInit {
    modalOptions: IModalOptions = {
        showConfirmButton: false,
        width: '50%',
    };
    @ViewChild('noteInput') noteInput: ElementRef;

    entity: INoteEntity;
    entities: INoteEntity[];
    entityListConfig: IEntityListConfig;
    service: INoteService;
    isNoteList: boolean;
    doubleClickIsDisabled = false;
    isEditing = false;
    notes: IMessageNote[] = [];
    newNoteText = '';
    entityNote = '';
    managedNotes: IDboMessageManagedNote[] = [];
    selectedNote: IDboMessageManagedNote = null;

    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSave: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private notesUpdatedService: NotesUpdatedService,
        private authService: AuthService,
        private managedNotesService: MessageManagedNotesService,
    ) {}
    ngOnInit(): void {
        if (this.entity && Object.prototype.hasOwnProperty.call(this.entity, 'Notes')) {
            this.service.getNotes(this.entity.EmailId).subscribe((data) => {
                this.notes = data;
            });
        }
        setTimeout(() => {
            if (this.noteInput) {
                this.noteInput.nativeElement.focus();
            }
        }, 100);

        this.managedNotesService.getAll().subscribe((data) => {
            this.managedNotes = data;
        });

        if (this.entity?.Note) {
            this.entityNote = this.entity.Note;
        }
    }

    cancel(): void {
        this.onCancel.emit();
    }

    save(): void {
        if (this.entities && this.entities.length) {
            this.entities.forEach((entity) => {
                const newNote: IMessageNote = {
                    CreatedById: 0,
                    CreatedOn: new Date(),
                    Id: 0,
                    MessageId: entity.EmailId,
                    Note: this.entityNote,
                };
                this.authService.currentUser.subscribe((data) => {
                    newNote.CreatedById = data.Id;
                });
                this.service.addNote(entity.EmailId, newNote).subscribe(() => {
                    this.announceUpdate(true);
                    this.service.getNotes(entity.EmailId).subscribe((notes) => {
                        this.notes = notes;
                        this.onCancel.emit();
                    });
                });
            });
        } else {
            this.service.updateNote(this.entity.Id, this.entityNote).subscribe(() => {
                this.announceUpdate(this.entityNote.length > 0);
                this.onCancel.emit();
            });
        }
    }

    addNote(): void {
        const newNote: IMessageNote = {
            CreatedById: 0,
            CreatedOn: new Date(),
            Id: 0,
            MessageId: this.entity.EmailId,
            Note: this.newNoteText,
        };
        this.authService.currentUser.subscribe((data) => {
            newNote.CreatedById = data.Id;
        });
        this.service.addNote(this.entity.EmailId, newNote).subscribe(() => {
            this.announceUpdate(true);
            this.service.getNotes(this.entity.EmailId).subscribe((notes) => {
                this.notes = notes;
                this.onCancel.emit();
            });
        });
    }

    announceUpdate(hasNote: boolean): void {
        this.notesUpdatedService.refreshLogs(hasNote);
    }

    toggleEdit(): void {
        this.isEditing = !this.isEditing;
    }

    selectedNoteChanged(event: IDboMessageManagedNote): void {
        if (event) {
            this.newNoteText = event.Note;
            this.selectedNote = event;
        } else {
            this.newNoteText = '';
            this.selectedNote = null;
        }
    }
}
