import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IHasDocuments } from '@mt-ng2/entity-components-documents';

import { IDocument } from '../../model/interfaces/base';

@Injectable()
export class CustomerDocumentsService extends BaseService<IDocument> implements IHasDocuments {
    constructor(public http: HttpClient) {
        super('/customers', http);
    }

    getDocument(customerId: number, docId: number): any {
        return this.http.get(`/customers/${customerId}/documents/${docId}`, {
            responseType: 'blob' as const,
        });
    }

    getDocuments(customerId: number, searchparameters: SearchParams): Observable<HttpResponse<IDocument[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IDocument[]>(`/customers/${customerId}/documents/_search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError(void this.handleError));
    }

    saveDocument(customerId: number, file: File): any {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post(`/customers/${customerId}/documents`, formData);
    }

    deleteDocument(customerId: number, docId: number): Observable<object> {
        return this.http.delete(`/customers/${customerId}/documents/${docId}`, {
            responseType: 'text' as 'json',
        });
    }
}
