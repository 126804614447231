import { Component, Input, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { IMessageTag } from '../../model/interfaces/message-tag';
import { DynamicField, DynamicFieldType, IDynamicFieldType, IDynamicField, InputTypes, DynamicFieldTypes, DynamicLabel } from '@mt-ng2/dynamic-form';
import { IDboInvestment } from '../../model/interfaces/dbo-investment';
import { InvestmentDynamicConfig } from '../investment.dynamic-config';
import { InvestmentService } from '../investment-service';
import { IVwClient } from '../../model/interfaces/vw-client';
import { ClientService } from '../../clients/client.service';
import { IVClientInvestment } from '../../model/interfaces/v-client-investment';
import { InvestmentDealAddModalService } from '../investment-add-deal-modal/investmentDealAddModal.service';
import { Subscription } from 'rxjs';
import { AddDealUpdateService } from '../investment-add-deal-modal/investmentDealUpdateService';
import { IDboVClientAltName } from '../../model/interfaces/dbo-v-client-alt-name';

@Component({
    selector: 'app-investment-basic-info',
    styles: [
        `
            .container {
                margin-right: 5%;
                width: 100%;
            }

            .ruleContainer {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                width: 100%;
            }
            .nameAndColor {
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
            }
            .name {
                margin-right: 200px;
            }

            .form-control textarea {
                max-width: 100%;
            }

            .inactiveBtn {
                background-color: white;
                color: black;
            }
        `,
    ],
    templateUrl: './investment-basic-info.component.html',
})
export class InvestmentBasicInfoComponent implements OnInit {
    get isNewInvestment(): boolean {
        return this.investment && this.investment.Id ? false : true;
    }
    @Input() investment: IDboInvestment;
    @Input() canEdit: boolean;

    form: UntypedFormGroup;

    isEditing = false;
    isHovered: boolean;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: InvestmentDynamicConfig<IDboInvestment>;
    doubleClickIsDisabled = false;
    deals: IVClientInvestment[] = [];
    newInvestmentClientsForDeals: IVwClient[] = [];
    includeArchived = false;

    clientAltNames: IDboVClientAltName[] = [];

    subscription = new Subscription();

    constructor(
        private notificationsService: NotificationsService,
        private router: Router,
        private fb: UntypedFormBuilder,
        private investmentService: InvestmentService,
        private clientService: ClientService,
        private dealModalService: InvestmentDealAddModalService,
        private updateDealService: AddDealUpdateService,
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            investmentForm: this.fb.group({}),
        });
        this.setConfig();
        this.subscription.add(
            this.updateDealService.refreshDealsObservable.subscribe((data) => {
                this.addDeal(data);
            }),
        );
        this.clientService.getClientAltNames(0).subscribe((data) => {
            this.clientAltNames = data;
        });
    }

    addLabels(): void {
        this.config.viewOnly.push(
            new DynamicLabel({
			    label: 'Deals',
			    value: this.deals.length ? this.deals.map((e) => e.Name).join(', ') : 'None',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textbox,
			        scale: null,
			    })
			}
            ),
        );
    }

    setConfig(): void {
        this.formFactory = new InvestmentDynamicConfig<IDboInvestment>(this.investment);
        if (this.isNewInvestment) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.addLabels();
            this.getDeals();
            this.config = this.formFactory.getForUpdate();
        }
    }

    getDeals(): void {
        this.clientService.getDeals(this.investment.Id, this.includeArchived).subscribe((clients) => {
            this.deals = clients;
            this.addLabels();
        });
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewInvestment) {
            void this.router.navigate(['/taxPositions']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.investment, form.value.DboInvestment as IDboInvestment);
            this.saveInvestment();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    toggleArchiveDeal(clientId: number, deal: IVClientInvestment, index?: number): void {
        if (this.isNewInvestment) {
            this.deals.splice(index, 1);
            this.newInvestmentClientsForDeals.splice(index, 1);
        } else {
            deal.IsArchived = !deal.IsArchived;

            this.clientService.toggleArchiveDeal(clientId, this.investment.Id).subscribe(() => {
                this.notificationsService.success(this.investment.IsArchived ? 'UnArchived Successfully' : 'Archived Successfully');
                this.getDeals();
            });
        }
    }

    includeArchivedChanged(event: any): void {
        this.includeArchived = event;
        this.getDeals();
    }

    openModal(event: any): void {
        if (this.isNewInvestment) {
            this.dealModalService.showModal(this.investment, this.clientAltNames, this.newInvestmentClientsForDeals);
        } else {
            this.dealModalService.showModal(this.investment, this.clientAltNames);
        }
    }

    addDeal(newClients: IVwClient[]): void {
        if (this.isNewInvestment) {
            const newClientsIdArr = newClients.map((c) => c.Id);
            this.clientService.getByIds(newClientsIdArr).subscribe((data) => {
                this.newInvestmentClientsForDeals = data;
                this.deals = data.map((c): IVClientInvestment => ({ Id: 0, ClientId: c.Id, InvestmentId: this.investment.Id, Name: c.Name }));
            });
        } else {
            this.investmentService.addDeals(newClients, this.investment.Id).subscribe(() => {
                this.getDeals();
            });
        }
    }

    private saveInvestment(): void {
        if (this.isNewInvestment) {
            this.investmentService
                .create(this.investment)                .subscribe((answer) => {
                    this.investment.Id = answer;
                    this.success(true);
                });
        } else {
            this.investmentService
                .update(this.investment)                .subscribe(() => {
                    this.investmentService.getById(this.investment.Id).subscribe((data) => {
                        this.investment = data;
                        this.success();
                    });
                });
        }
    }

    private success(newInvestmentSave?: boolean): void {
        if (newInvestmentSave) {
            this.investmentService.addDeals(this.newInvestmentClientsForDeals, this.investment.Id).subscribe(() => {
                void this.router.navigate([`/taxPositions/${this.investment.Id}`]);
            });
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.investmentService.emitChange(this.investment);
        this.newInvestmentClientsForDeals = [];
        this.notificationsService.success('Tax Position saved successfully.');
    }
}
