import { OnInit, Component } from '@angular/core';

import { CustomerSourceDynamicControls } from '../../model/form-controls/customer-source.form-controls';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { CustomerSourceService } from '../services/customersource.service';

@Component({
    templateUrl: './customer-settings.component.html',
})
export class CustomerSettingsComponent implements OnInit {
    customerSourceDynamicForm = new CustomerSourceDynamicControls().Form;
    canAdd: boolean;
    canRemove: boolean;

    constructor(public customerSourceService: CustomerSourceService, public claimsService: ClaimsService) {}

    ngOnInit(): void {
        this.canAdd = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
        this.canRemove = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
    }
}
