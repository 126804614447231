export interface IMtSearchFilterDaterangeModuleConfig {
    afterText?: string;
    beforeText?: string;
    contextualDates?: IContextualDateTypes[];
    firstDayOfWeek?: DaysOfTheWeek;
    throughText?: string;
}

export enum DaysOfTheWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
}

export enum Months {
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December,
}

export interface ISearchFilterDaterangeValue {
    startDate: Date;
    endDate: Date;
}

export enum ContextualDateTypesEnum {
    Today = 1,
    ThisWeek = 2,
    ThisMonth = 3,
    ThisQuarter = 4,
    ThisYear = 5,
    LastWeek = 6,
    LastMonth = 7,
    LastQuarter = 8,
    LastYear = 9,
    NextWeek = 10,
    NextMonth = 11,
    NextQuarter = 12,
    NextYear = 13,
}

export interface IContextualDateTypes {
    Id: ContextualDateTypesEnum;
    Name: string;
}

export const ContextualDateTypes: IContextualDateTypes[] = [
    { Id: ContextualDateTypesEnum.Today, Name: 'Today' },
    { Id: ContextualDateTypesEnum.ThisWeek, Name: 'This Week' },
    { Id: ContextualDateTypesEnum.ThisMonth, Name: 'This Month' },
    { Id: ContextualDateTypesEnum.ThisQuarter, Name: 'This Quarter' },
    { Id: ContextualDateTypesEnum.ThisYear, Name: 'This Year' },
    { Id: ContextualDateTypesEnum.LastWeek, Name: 'Last Week' },
    { Id: ContextualDateTypesEnum.LastMonth, Name: 'Last Month' },
    { Id: ContextualDateTypesEnum.LastQuarter, Name: 'Last Quarter' },
    { Id: ContextualDateTypesEnum.LastYear, Name: 'Last Year' },
    { Id: ContextualDateTypesEnum.NextWeek, Name: 'Next Week' },
    { Id: ContextualDateTypesEnum.NextMonth, Name: 'Next Month' },
    { Id: ContextualDateTypesEnum.NextQuarter, Name: 'Next Quarter' },
    { Id: ContextualDateTypesEnum.NextYear, Name: 'Next Year' },
];

export function setQuarters(currentMonth: number, startDate: Date, endDate: Date, today: Date, isPrevYear?: boolean): ISearchFilterDaterangeValue {
    // currentMonth refers to the month +- 3 months based on current quarter, last quarter, or next quarter
    if (currentMonth < Months.April) {
        startDate = new Date(today.getFullYear(), Months.January, 1); // Jan 1st
    } else if (currentMonth < Months.July && currentMonth >= Months.April) {
        startDate = new Date(today.getFullYear(), Months.April, 1); // April 1st
    } else if (currentMonth < Months.October && currentMonth >= Months.July) {
        startDate = new Date(today.getFullYear(), Months.July, 1); // July 1st
    } else if (currentMonth < 12 && currentMonth >= Months.October) {
        startDate = new Date(today.getFullYear(), Months.October, 1); // October 1st
    }
    endDate = new Date(startDate.getTime()); // copy date
    endDate.setMonth(startDate.getMonth() + 3); // three months from now
    endDate.setDate(0); // last day of previous month

    if (isPrevYear) {
        const currYear = startDate.getFullYear();
        startDate.setFullYear(currYear - 1);
    }
    return { startDate, endDate };
}

export function getDateRangeByContextualFilter(
    contextualDateTypeId: number,
    firstDayOfTheWeek: DaysOfTheWeek,
    defaultStartDate: Date,
    defaultEndDate: Date,
): ISearchFilterDaterangeValue {
    const today = new Date();
    const todayCopy = new Date();
    let first: number;
    let startDate: Date;
    let endDate: Date;
    let last: number;
    let month: number;
    let quarterRange: ISearchFilterDaterangeValue;
    let isPrevYear: boolean;
    switch (contextualDateTypeId) {
        case ContextualDateTypesEnum.Today:
            first = today.getDate();
            startDate = new Date(today.setDate(first));
            endDate = new Date(today.setDate(first));
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            return { startDate, endDate };
        case ContextualDateTypesEnum.ThisWeek:
            first = today.getDate() - today.getDay();
            last = first + 6;
            startDate = new Date(today.setDate(first));
            endDate = new Date(todayCopy.setDate(last));
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            return { startDate, endDate };
        case ContextualDateTypesEnum.ThisMonth:
            startDate = new Date(today.getFullYear(), today.getMonth(), 1);
            endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            return { startDate, endDate };
        case ContextualDateTypesEnum.ThisQuarter:
            month = today.getMonth();
            quarterRange = setQuarters(month, startDate, endDate, today);
            return quarterRange;
        case ContextualDateTypesEnum.ThisYear:
            startDate = new Date(today.getFullYear(), Months.January, 1);
            endDate = new Date(today.getFullYear(), Months.December, 31);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            return { startDate, endDate };
        case ContextualDateTypesEnum.LastWeek:
            first = today.getDate() - today.getDay() - 7;
            last = first + 6;
            startDate = new Date(today.setDate(first));
            endDate = new Date(todayCopy.setDate(last));
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            return { startDate, endDate };
        case ContextualDateTypesEnum.LastMonth:
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            endDate = new Date(today.getFullYear(), today.getMonth(), 0);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            return { startDate, endDate };
        case ContextualDateTypesEnum.LastQuarter:
            month = today.getMonth() - 3;
            isPrevYear = false;
            if (month < Months.January) {
                month += 12;
            }
            if (today.getMonth() < 3) {
                isPrevYear = true;
            }
            quarterRange = setQuarters(month, startDate, endDate, today, isPrevYear);
            return quarterRange;
        case ContextualDateTypesEnum.LastYear:
            startDate = new Date(today.getFullYear() - 1, Months.January, 1);
            endDate = new Date(today.getFullYear() - 1, Months.December, 31);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            return { startDate, endDate };

        case ContextualDateTypesEnum.NextWeek:
            first = today.getDate() - today.getDay() + 7;
            last = first + 6;
            startDate = new Date(today.setDate(first));
            endDate = new Date(todayCopy.setDate(last));
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            return { startDate, endDate };
        case ContextualDateTypesEnum.NextMonth:
            startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
            endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            return { startDate, endDate };
        case ContextualDateTypesEnum.NextQuarter:
            month = today.getMonth() + 3;
            if (month > Months.December) {
                month -= 12;
            }
            quarterRange = setQuarters(month, startDate, endDate, today);
            return quarterRange;
        case ContextualDateTypesEnum.NextYear:
            startDate = new Date(today.getFullYear() + 1, Months.January, 1);
            endDate = new Date(today.getFullYear() + 1, Months.December, 31);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            return { startDate, endDate };
        default:
            return { startDate: defaultStartDate, endDate: defaultEndDate };
    }
}
