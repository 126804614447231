<span ngbDropdown class="dropdown" (openChange)="openChange($event)" [autoClose]="autoClose" [attr.disabled]="disabled ? disabled : null">
    <button
        ngbDropdownToggle
        class="btn dropdown-toggle"
        type="button"
        [tabindex]="disabled ? -1 : 0"
        [class.btn-transparent]="filterMode"
        [class.btn-primary-inverse]="!filterMode"
        [disabled]="disabled"
    >
        <span *ngIf="noneSelected || allSelected" [innerHtml]="selectedItemsText()"> </span>
        <span *ngIf="!noneSelected && !allSelected">
            <span
                class="listedTags"
                *ngFor="let item of selectedItems()"
                [style.background-color]="item.Item.Color"
                [style.color]="findColorContrast(item.Item.Color)"
            >
                {{ item.Item.Name }}
            </span>
        </span>
        <span class="caret"></span>
    </button>
    <ul ngbDropdownMenu class="dropdown-menu-scrollable" aria-labelledby="statusDropDown">
        <div class="scrollable-menu">
            <li *ngFor="let item of items; let i = index" [style.background-color]="item.Item.Color">
                <a (click)="itemSelected(i)" [style.color]="findColorContrast(item.Item.Color)">
                    <i class="fa fa-fw" [ngClass]="{ 'fa-check': item.Selected }"></i>{{ getItemText(item) }}
                </a>
            </li>
        </div>
        <ng-container *ngIf="showSelectAllButtons">
            <li class="select-all-separator">
                <a class="btn btn-xs btn-default btn-flat select-all-button" (click)="selectAll(true)"> select all </a>
            </li>
            <li>
                <a class="btn btn-xs btn-default btn-flat select-all-button" (click)="selectAll(false)"> deselect all </a>
            </li>
        </ng-container>
    </ul>
</span>
