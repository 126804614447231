<div *ngIf="taskId === toggleTaskIdTable" style="display: flex; justify-content: center;">
    <div style="width: 80%;">
        <table class="table">
            <thead style="font-size: 13px;">
                <tr>
                    <th>Field Name</th>
                    <th>Old</th>
                    <th>New</th>
                </tr>
            </thead>
            <tbody *ngFor="let lg of logTaskChangesChanges" style="font-size: 12px;">
                <tr style="font-size: 12px;">
                    <td>{{lg.Field}}</td>

                    <ng-container *ngIf="lg.Field === 'Clients'; else elseBlock">
                        <td>
                            <ng-container *ngFor=" let item of lg.OldValue">
                                {{item.Name}}
                                <ng-container *ngIf="lg.OldValue.length > 1">,</ng-container>
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngFor=" let item of lg.NewValue">
                                {{item.Name}}
                                <ng-container *ngIf="lg.NewValue.length > 1">,</ng-container>
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-template #elseBlock>
                        <td>
                            {{lg.OldValue}}
                        </td>
                        <td>
                            {{lg.NewValue}}
                        </td>
                    </ng-template>
                </tr>
            </tbody>
        </table>
    </div>
</div>
