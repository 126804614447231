import { IEntity } from '../../model/interfaces/base';
import { IClient } from '../../model/interfaces/custom/client';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { Component } from '@angular/core';
import { IDboTaskDTO } from '../../model/interfaces/custom/IDboTaskDTO';
import { filter } from 'rxjs/operators';

@Component({
    styles: [
        `
            ol {
                padding-inline-start: 15px;
            }
        `,
    ],
    template: `
        <div (click)="$event.stopPropagation()">
            <ol>
                <li *ngFor="let tenant of tenantNames">{{ tenant }}</li>
            </ol>
        </div>
    `,
})
export class TenantListComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.dboTaskDto = value as IDboTaskDTO;
        this.tenantNames = this.dboTaskDto.Tenants ? this.dboTaskDto.Tenants.map((t) => t.Name) : [];
    }

    tenantNames: string[];
    dboTaskDto: IDboTaskDTO;
    constructor() {}
}
