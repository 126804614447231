import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { EmailContactService } from '../services/email-contact.service';
import { IEmailContact } from '../../model/interfaces/email-contact';
import { ClaimTypes } from '../../model/ClaimTypes';

@Component({
    templateUrl: './email-contact-detail.component.html',
})
export class EmailContactDetailComponent implements OnInit {
    emailContact: IEmailContact;
    canEdit: boolean;
    canAdd: boolean;

    constructor(
        private emailContactService: EmailContactService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.EmailContacts, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = this.getIdFromRoute(this.route, 'Id');
        if (id) {
            this.getEmailContactById(id);
        } else {
            void this.router.navigate(['emailContacts']); // if no id found, go back to list
        }
    }

    getEmailContactById(id: number): void {
        this.emailContactService.getById(id).subscribe((emailContact) => {
            if (emailContact === null) {
                this.notificationsService.error('Email Contact not found');
                void this.router.navigate(['emailContacts']);
            }
            this.emailContact = emailContact;
        });
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10)) ? null : parseInt(id, 10);
    }
}
