import { Observable } from 'rxjs';
import { ITaskSearchDTO } from './../model/interfaces/custom/ITaskSearchDTO';
import { ITaskSearch } from './../model/interfaces/task-search.d';
import { BaseService } from '@mt-ng2/base-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ITaskSearchObject } from '../model/partials/task-search';

@Injectable({
    providedIn: 'root',
})
export class TaskSearchService extends BaseService<ITaskSearch> {
    constructor(public http: HttpClient) {
        super('/search', http);
    }

    saveSearch(obj: ITaskSearchObject, searchId: number): Observable<number> {
        return this.http.post<number>(`/search/saveTaskSearch/${searchId}`, obj);
    }

    getSavedSearchForUser(): Observable<HttpResponse<ITaskSearchDTO[]>> {
        return this.http.get<ITaskSearchDTO[]>(`/search/getSavedSearch`, { observe: 'response' });
    }

    deleteSearch(searchId: number): Observable<void> {
        return this.http.delete<void>(`/search/deleteTaskSearch/${searchId}`);
    }
}
