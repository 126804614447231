<div class="tagList" [style.flex-wrap]="tags.length > 4 ? 'wrap' : 'nowrap'" (click)="$event.stopPropagation()">
    <div
        *ngFor="let tag of tags; let i = index"
        class="tagItem"
        [style.background-color]="tag.Color"
        [style.width.%]="100 / task.Tags.length"
        [style.color]="findColorContrast(tag.Color)"
        [title]="tag.Name"
    >
        <p class="delete" (click)="$event.stopPropagation(); deleteTag(tag, i)">x</p>
        <p class="tagName">{{ tag.Name }}</p>
    </div>
</div>
