import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { common } from '@mt-ng2/common-functions';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { AttachmentTagsService } from '../attachment-tags.service';
import { IDboAttachmentTag } from '../../model/interfaces/dbo-attachment-tag';
import { AttachmentTagDynamicConfig } from '../attachment-tags.dynamic-config';
import { Observable } from 'rxjs';

@Component({
  selector: 'attachment-tag-basic-info',
  templateUrl: './attachment-tag-basic-info.component.html',
})
export class AttachmentTagBasicInfoComponent implements OnInit {
  isEditing = false;
  isNewAttachmentTag = false;
  doubleClickIsDisabled = false;
  canEdit = false;
  canAdd = false;
  header$ : Observable<string>;
  attachmentId = 0;
  attachmentTag: IDboAttachmentTag;
  formFactory: AttachmentTagDynamicConfig<IDboAttachmentTag>;
  config: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private claimsService: ClaimsService,
    private notificationsService: NotificationsService,
    private attachmentTagsService: AttachmentTagsService
  ) { }

  ngOnInit(): void {
    this.canEdit = this.claimsService.hasClaim(ClaimTypes.Tags, [ClaimValues.FullAccess]);
    const id = +this.route.snapshot.paramMap.get('attachmentTagId');
    this.attachmentTagsService.setInitialTitle(id);
        this.header$ = this.attachmentTagsService.title$;

    if (id > 0) {
      this.attachmentId = id;
      this.getAttachmentTagById(id);
    } else {
      const emptyAttachmentTag = this.attachmentTagsService.getEmptyAttachmentTag();
      this.setConfig(emptyAttachmentTag);
      this.isEditing = true;
      this.isNewAttachmentTag = true;
    }
  }

  setConfig(attachmentTag?: IDboAttachmentTag): void {
    this.formFactory = new AttachmentTagDynamicConfig<IDboAttachmentTag>(attachmentTag);
    if (this.isNewAttachmentTag) {
      this.config = this.formFactory.getForCreate();
    } else {
      this.config = this.formFactory.getForUpdate();
    }
  }

  getAttachmentTagById(id: number): void {
    this.attachmentTagsService.getById(id).subscribe((attachmentTag) => {
      this.setConfig(attachmentTag);
    });
  }

  formSubmitted(form: FormGroup) {
    if (form.valid) {
      const attachmentTag: IDboAttachmentTag = form.value.DboAttachmentTag;
      attachmentTag.Id = this.attachmentTag.Id;
      if (this.isNewAttachmentTag) {
        this.attachmentTagsService.create(attachmentTag).subscribe(
          (attachmentId: number) => {
            this.attachmentId = attachmentId;
            this.notificationsService.success("Attachment tag created successfully");
            this.isEditing = false;
            this.isNewAttachmentTag = false;
            this.getAttachmentTagById(attachmentId);
            void this.router.navigate([`/attachment-tag/${attachmentId}`]);
          },
        );
      } else {
        this.attachmentTagsService.update(attachmentTag).subscribe(
          () => {
            this.notificationsService.success("Attachment tag updated successfully");
            this.isEditing = false;
            this.getAttachmentTagById(this.attachmentId);
            void this.router.navigate([`/attachment-tag/${this.attachmentId}`]);
          },
        );
      }
    } else {
      common.markAllFormFieldsAsTouched(form);
      this.notificationsService.error("Please check attachment tag form");
    }
  }

  edit(): void {
    if (this.canEdit) {
      this.isEditing = true;
    }
  }

  cancelClick(): void {
    if (this.isNewAttachmentTag) {
      void this.router.navigate(['/attachment-tag']);
    } else {
      this.isEditing = false;
    }
  }


}
