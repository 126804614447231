import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IDboMessage } from '../../model/interfaces/dbo-message';
import { Observable, Subject } from 'rxjs';
import { IDboMessageDTO } from '../../model/interfaces/custom/IDboMessageDTO';
import { IMessageNote } from '../../model/interfaces/message-note';
import { IMessageSearchObject } from '../../model/partials/message.search';
import { MessageListComponent, IMessageRow } from '../message-list/message-list-component';
import { stringify } from 'querystring';
import { IMessageUserParams } from '../../model/interfaces/custom/message-user-params';
import { IDboVwMessageWithAttachment } from '../../model/interfaces/dbo-vw-message-with-attachment';
import { MessageSearchStatus, MessageSearchTaxStatus } from '../../common/constants/Enum';
import { IAssignEmailsDTO } from '../../model/interfaces/custom/IAssignEmailsDTO';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

export const emptyDboMessage: IDboMessage = {
    HasAttachments: null,
    Id: 0,
    Importance: null,
    IsHtml: null,
    SenderEmailAddress: null,
    SenderName: null,
    SentDate: null,
    Size: null,
    Subject: null,
    WasBodyConvertedToAttachment: false,
};

export const emptyDtoMessage: IDboMessageDTO = {
    Assignee: null,
    Assigner: null,
    AttachmentCount: 0,
    AutomationStatus: null,
    Body: '',
    EmailId: 0,
    From: '',
    HasAttachments: null,
    Id: 0,
    Importance: null,
    IsHtml: null,
    LastAuditLogDetails: '',
    Notes: [],
    ReplyMessages: [],
    SenderEmailAddress: null,
    SenderName: null,
    SentDate: null,
    Size: null,
    Subject: null,
    Tags: null,
    TaskIds: null,
    WasBodyConvertedToAttachment: false,
    WasProcessed: false,
};

@Injectable({
    providedIn: 'root',
})
export class DboMessageService extends BaseService<IDboMessage> {
    selectedMessages: IDboMessageDTO[] = [];

    tagOnMessageSubject = new Subject<IMessageRow>();
    tagOnMessageObservable = this.tagOnMessageSubject.asObservable();

    announceGetMessagesEvent = new Subject<void>();
    announceGetMessagesObservable = this.announceGetMessagesEvent.asObservable();

    lastOpenedRow: IMessageRow;

    numberOfEmailsAssignOptions: MtSearchFilterItem[] = [
        {
            Item: { Id: 1, Name: '1' },
            Selected: false,
        },
        {
            Item: { Id: 5, Name: '5' },
            Selected: false,
        },
        {
            Item: { Id: 10, Name: '10' },
            Selected: false,
        },
        {
            Item: { Id: 15, Name: '15' },
            Selected: false,
        },
        {
            Item: { Id: 20, Name: '20'},
            Selected: false,
        }
    ];

    constructor(public http: HttpClient) {
        super('/inbox', http);
    }

    getEmptyDboMessage(): IDboMessage {
        return { ...emptyDboMessage };
    }

    getEmptyDtoMessage(): IDboMessageDTO {
        return { ...emptyDtoMessage };
    }

    getSelectedMessages(): IDboMessageDTO[] {
        return this.selectedMessages;
    }

    setSelectedMessages(messages: IDboMessageDTO[]): void {
        this.selectedMessages = messages;
    }

    getMessages(csp: IMessageSearchObject, showHeader = false): Observable<HttpResponse<IDboMessageDTO[]>> {
        latestMessageSearch = csp;
        if (showHeader) {
            return this.http.post<IDboMessageDTO[]>(`/inbox/getmessages`, csp, { observe: 'response' });
        } else {
            return this.http.post<IDboMessageDTO[]>(`/inbox/getmessages`, csp, { headers: { ignoreProgressBar: '' }, observe: 'response' });
        }
    }

    assignUserToMessages(mup: IMessageUserParams): Observable<any> {
        return this.http.put<void>('/inbox/assignUserToMessage', mup);
    }

    assignMessagesToUser(payload: IAssignEmailsDTO): Observable<number> {
        return this.http.post<number>('/inbox/assignMessagesToUser', payload);
    }

    updateNote(id: number, note: string): Observable<void> {
        return this.http.get<void>('');
    }

    getNotes(id: number): Observable<IMessageNote[]> {
        return this.http.get<IMessageNote[]>(`/inbox/getNotes/${id}`);
    }

    addNote(id: number, newNote: IMessageNote): Observable<void> {
        return this.http.post<void>(`/inbox/addNote/${id}`, newNote);
    }

    getLatestMessageSearch(component: MessageListComponent): IMessageSearchObject {
        return latestMessageSearch || this.getDefaultMessageSearch(component);
    }

    getDefaultMessageSearch(component: MessageListComponent): IMessageSearchObject {
        return getDefaultMessageSearchObject(component);
    }

    setLatestSavedSearchId(id: number): void {
        latestSavedSearchId = id;
    }

    getLatestSavedSearchId(): number {
        return latestSavedSearchId;
    }

    setMessageState(messageIds: number[], messageState: number, userId = 0): Observable<void> {
        return this.http.put<void>(`/inbox/setMessageState/${messageState}/${userId}`, messageIds);
    }

    getDocumentsWithMessage(messageIds: number[]): Observable<IDboVwMessageWithAttachment[]> {
        return this.http.post<IDboVwMessageWithAttachment[]>(`/inbox/getAttachmentWithMessage`, messageIds);
    }

    getById(id: number): Observable<IDboMessage> {
        return this.http.get<IDboMessage>(`/inbox/${id}`, { headers: { ignoreProgressBar: '' } });
    }

    getMessageDTObyId(id: number): Observable<IDboMessageDTO> {
        return this.http.get<IDboMessageDTO>(`/inbox/getMessageById/${id}`);
    }

    getIsSearchCollapsed(): boolean {
        return isSearchCollapsed;
    }

    setIsSearchCollapsed(): void {
        isSearchCollapsed = !isSearchCollapsed;
    }

    messageTagRealtime(updatedMessage: IMessageRow): void {
        this.tagOnMessageSubject.next(updatedMessage);
    }

    announceGetMessages(): void {
        this.announceGetMessagesEvent.next();
    }

    processingLock(messageIds: number[] = [], lockVal: boolean, lockedById: number): Observable<number[]> {
        return this.http.post<number[]>(`/inbox/lockProcessing/${lockVal.toString()}/${lockedById}`, messageIds);
    }

    checkForProcessedMessages(messageIds: number[] = []): Observable<number[]> {
        return this.http.post<number[]>(`/inbox/checkForProcessed`, messageIds);
    }
}
function getDefaultMessageSearchObject(component: MessageListComponent): IMessageSearchObject {
    return {
        assigneeIds: [],
        assignerIds: [],
        automationStatusIds: [],
        body: '',
        contextualDateId: null,
        endReceivedDate: '',
        from: '',
        messageIds: '',
        messageSearchStatuses: [MessageSearchStatus.UnProcessed],
        messageSearchTaxStatuses: [MessageSearchTaxStatus.NonTax],
        name: '',
        order: component.currSearchObject.order,
        orderDirection: component.currSearchObject.orderDirection,
        query: '',
        savedSearchId: 0,
        skip: (component.currentPage - 1) * component.selectedElementPerPage,
        startReceivedDate: '',
        subject: '',
        tagIds: [],
        take: component.selectedElementPerPage,
        taskIds: '',
    };
}

let latestMessageSearch: IMessageSearchObject;
let latestSavedSearchId: number;
let isSearchCollapsed = false;
