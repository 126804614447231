<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>Funds</h2>
    </div>
</div>
<mt-search-bar (searched)="search($event)"></mt-search-bar>
<br />
<div class="row">
    <div class="col-md-6">
        <button type="button" class="btn btn-primary" (click)="export()">
            Export
        </button>
    </div>

    <div class="col-md-6">
        <div class="pull-right">
            <mt-search-filter-checkbox
            text="Include Archived"
            [value]="includeArchived"
            (searchCheckboxChanged)="includeArchivedFunds($event)"
        ></mt-search-filter-checkbox>
        </div>

    </div>
</div>
<br />
<mt-entity-list
    [entities]="funds"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getFunds()"
    (itemDeletedEvent)="toggleArchive($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
></mt-entity-list>
