import { Component, OnInit } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IVwClient } from '../../model/interfaces/vw-client';
import { ClientService } from '../client.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { ClientAltNamesService } from './client-alt-names-modal-service';

@Component({
    styles: [],
    template: `
        <span *ngIf="hasAccess" class="btn-group btn-group-toggle">
            <span *ngIf="client.HasAltNames">
                <button class="btn btn-primary" (click)="openModal($event)">View Alternative Names</button>
            </span>
        </span>
    `,
})
export class ViewClientAltNamesComponent implements IEntityListDynamicCellComponent, OnInit {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.client = value as IVwClient;
    }
    client: IVwClient;
    hasAccess = false;
    constructor(private clientService: ClientService, private claimService: ClaimsService, private altNameModalService: ClientAltNamesService) {}
    ngOnInit(): void {
        this.hasAccess = this.claimService.hasClaim(ClaimTypes.Clients, [ClaimValues.ReadOnly, ClaimValues.FullAccess]);
    }

    openModal(event: any): void {
        event.stopPropagation();
        this.altNameModalService.showModal(this.client);
    }
}
