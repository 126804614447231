/**
 * Get a type based on an existing type but excluding some of its properties.
 * Based on the definition added in TypeScript 3.5; if that version or higher is
 * used, this can be removed.
 */
export type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

export class ContrastColorText {
    findColorContrast(hexcolor: string): string {
        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }

        if (hexcolor.length === 3) {
            hexcolor = hexcolor
                .split('')
                .map(function (hex): string {
                    return hex + hex;
                })
                .join('');
        }

        const r = parseInt(hexcolor.substr(0, 2), 16);
        const g = parseInt(hexcolor.substr(2, 2), 16);
        const b = parseInt(hexcolor.substr(4, 2), 16);

        const yiq = (r * 299 + g * 587 + b * 114) / 1000;

        return yiq >= 128 ? 'black' : 'white';
    }
}

export function searchNameList<T extends { Name?: string }>(list: T[], term: string | null, minTermLength: number): T[] {
    if (list === undefined) {
        return [];
    }

    if ((term ? term.length : 0) < minTermLength) {
        return [];
    }

    if (!term) {
        return [...list];
    }

    const lower = term.toLowerCase();
    return list.filter((e: T) => e && e.Name && e.Name.toLowerCase().includes(lower));
}
