/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { ITenant } from './../../model/interfaces/tenant.d';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { UserService } from '../user.service';

import { IUser } from '../../model/interfaces/user';
import { UserDynamicConfig } from '../user.dynamic-config';
import { IAuthUser } from '../../model/interfaces/auth-user'; // added
import { AuthUserDynamicConfig } from '../../auth-entity/auth-user/auth-user.dynamic-config';
import { ICreateUserPayload } from '../../model/interfaces/custom/create-user-payload';
import { forkJoin, Subscription, TeardownLogic } from 'rxjs';
import { TenantService } from '../../tenants/tenant.service';
import { IUserRoleType } from '../../model/interfaces/user-role-type';
import { UserRoleTypeService } from '../../user-roles/user-role-type.service';
import { ClaimTypes } from '../../model/ClaimTypes';
import { TenantTypeEnum, UserRoleEnum, RoleTypeEnum } from '../../common/constants/Enum';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup, FormArray } from '@angular/forms';

@Component({
    selector: 'app-user-basic-info',
    templateUrl: './user-basic-info.component.html',
})
export class UserBasicInfoComponent implements OnInit {
    @Input() user: IUser;
    @Input() canEdit: boolean;
    authUser: IAuthUser;
    roleTypes: IUserRoleType[];
    tenants: ITenant[];
    isEditing: boolean;
    isHovered: boolean;
    config: any = {};
    userForm: any;
    formFactory: UserDynamicConfig<IUser>;
    doubleClickIsDisabled = false;
    tenantIdOfLoggedUser: number;
    hasAddInternalUserAccess = false;
    hasAddExternalUserAccess = false;
    subscription = new Subscription();
    displaySuperAdmin = false;

    constructor(
        private userService: UserService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private tenantService: TenantService,
        private roleTypeService: UserRoleTypeService,
        private router: Router,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.config = { formObject: [], viewOnly: [] };
        this.hasAddInternalUserAccess = this.claimsService.hasClaim(ClaimTypes.Users_CreateInternalTenant, [ClaimValues.FullAccess]);
        this.hasAddExternalUserAccess = this.claimsService.hasClaim(ClaimTypes.Users_CreateExternalTenant, [ClaimValues.FullAccess]);

        if (this.isNewUser()) {
            forkJoin(
                this.roleTypeService.getAll(),
                this.tenantService.getAll(),
                this.userService.getById(this.authService.currentUser.getValue().Id),
            ).subscribe((response) => {
                const [roleTypes, tenants, loggedInUser] = response;
                this.roleTypes = roleTypes;
                this.tenants = tenants;
                this.tenantIdOfLoggedUser = loggedInUser.AuthUser.TenantId;
                this.setConfig();
            });
        } else {
            this.setConfig();
        }
    }

    private isNewUser(): boolean {
        return this.user && this.user.Id && this.user.Id > 0 ? false : true;
    }

    getNewUserAdditionalConfigs(): AuthUserDynamicConfig<IAuthUser>[] {
        return [
            new AuthUserDynamicConfig<IAuthUser>(
                null,
                true,
                this.selectTenantDisplay(),
                this.displaySuperAdmin ? this.roleTypes : this.roleTypes.filter((element) => element.Id !== RoleTypeEnum.SuperAdmin),
                ['Password', 'ConfirmPassword', 'SendResetEmail', 'TenantId', 'RoleTypeId'],
                true,
            ),
        ];
    }

    selectTenantDisplay(): ITenant[] {
        let filteredTenants = this.tenants;
        if (!this.hasAddInternalUserAccess) {
            filteredTenants = filteredTenants.filter((e) => e.TenantTypeId === TenantTypeEnum.External);
        }
        if (!this.hasAddExternalUserAccess) {
            filteredTenants = filteredTenants.filter((element) => element.Id === this.tenantIdOfLoggedUser);
        }
        return filteredTenants;
    }

    setConfig(): void {
        this.formFactory = new UserDynamicConfig<IUser>(this.user);
        if (this.isNewUser()) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate(this.getNewUserAdditionalConfigs());
        } else {
            this.config = this.formFactory.getForUpdate();
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewUser()) {
            void this.router.navigate(['/users']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form): void {
        let passwordmatch = true;
        if (this.isNewUser()) {
            if (!this.authService.matchPassword(form)) {
                passwordmatch = false;
            }
        }

        if (form.valid && passwordmatch) {
            this.formFactory.assignFormValues(this.user, form.value.User as IUser);
            if (this.isNewUser()) {
                const data: ICreateUserPayload = {
                    Password: form.value.AuthUser.Password,
                    SendEmail: form.value.AuthUser.SendResetEmail || false,
                    TenantId: form.value.AuthUser.TenantId,
                    User: this.user,
                    Username: this.user.Email.split('@')[0],
                    UserRoleTypeId: form.value.RoleTypeId,
                };
                // handle new user save
                this.userService
                    .createUser(data)                    .subscribe((answer) => {
                        void this.router.navigate([`/users/${answer}`]);
                        this.userService.emitChange(this.user);
                        this.success();
                    });
            } else {
                // handle existing user save
                this.userService
                    .updateVersion(this.user).subscribe((answer: any) => {
                        answer
                            ? ((this.user.Version = answer),
                              (this.isEditing = false),
                              this.success(),
                              this.userService.emitChange(this.user),
                              this.setConfig())
                            : this.error();
                    });
            }
        } else {
            if (!passwordmatch) {
                this.error('Passwords do not match');
            } else {
                common.markAllFormFieldsAsTouched(form);
                this.error();
            }
        }
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error(`Save failed.  Please check the form and try again.`);
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    updateVersion(version): void {
        this.user.Version = version;
    }

    formCreated(e): void {
        this.userForm = e;
        if (this.userForm.get('AuthUser.TenantId')) {
            this.subscription.add(
                this.userForm.get('AuthUser.TenantId').valueChanges.subscribe((val: number) => {
                    this.tenantService.getById(val).subscribe((data) => {
                        if (data) {
                            this.displaySuperAdmin = data.TenantTypeId === TenantTypeEnum.Internal;
                            this.setConfig();
                        }
                    });
                }) as TeardownLogic,
            );
        }
    }
}
