import { DynamicField, DynamicLabel, DynamicFieldType, DynamicFieldTypes, SelectInputTypes, IDynamicFieldType } from '@mt-ng2/dynamic-form';
import { DboTaskTypeDynamicControls, IDboTaskTypeDynamicControlsParameters } from '../form-controls/dbo-task-type.form-controls';
import { IDboTaskType } from '../interfaces/dbo-task-type';
import { TaskDateType, TaskDateData } from '../TaskDateType';
import { FormRadioButtonListCustomComponent } from '../../common/components/customFormRadioButton/customFormRadioButton';

export class DboTaskTypeDynamicControlsExtended extends DboTaskTypeDynamicControls {
    constructor(dbotasktype?: IDboTaskType, additionalParameters?: IDboTaskTypeDynamicControlsParameters) {
        super(dbotasktype, additionalParameters);
        (<DynamicField>this.Form.TaskTypeBackingId).label = 'Backing';
        (<DynamicLabel>this.View.TaskTypeBackingId).label = 'Backing';
        (<DynamicField>this.Form.TaskDateType) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Task Date Type',
            name: 'TaskDateType',
            options: TaskDateData,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
                scale: null,
            } as IDynamicFieldType),
            validation: [],
            validators: {},
            value: dbotasktype && dbotasktype.hasOwnProperty('TaskDateType') ? dbotasktype.TaskDateType : null,
        });

        (<DynamicField>this.Form.TierId).component = FormRadioButtonListCustomComponent;
        (<DynamicField>this.Form.TierId).options = [...this.tiers, { Id: null, Name: 'None' }];

        (<DynamicLabel>this.View.TaskDateType).value =
            dbotasktype && dbotasktype.TaskDateType ? `${TaskDateData[dbotasktype.TaskDateType].Name}` : 'No Date';

        (<DynamicLabel>this.View.TierId).value = dbotasktype && dbotasktype.TierId ? `${this.tiers[dbotasktype.TierId - 1].Name}` : 'No Tier';
    }
}
