import { ClaimsService, ClaimValues, AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { Component, OnInit } from '@angular/core';
import { ClaimTypes } from '../../model/ClaimTypes';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskService } from '../task.service';
import { IDboTaskDTO } from '../../model/interfaces/custom/IDboTaskDTO';
import { TaskDocumentsService } from '../task-document-service';
import { UserService } from './../../users/user.service';

import { saveAs } from 'file-saver';
import { IUser } from '../../model/interfaces/user';
import { TenantEnums } from './../../../app/common/shared.module';
import { IRelatedTaskDTO } from '../../model/interfaces/custom/IRelatedTaskDTO';

@Component({
    selector: 'app-task-detail',
    templateUrl: './task-detail.component.html',
})
export class TaskDetailComponent implements OnInit {
    task: IDboTaskDTO;
    canEdit: boolean;
    hasFullAccess = false;
    relatedTasks: IRelatedTaskDTO[] = [];
    relatedTasksForDocs: IRelatedTaskDTO[] = [];

    allowedMimeType = [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'application/zip',
        'application/pdf',
        'text/plain',
        'text/csv',
        'application/rtf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        'application/vnd.ms-word.document.macroEnabled.12',
        'application/vnd.ms-word.template.macroEnabled.12',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/vnd.ms-excel.template.macroEnabled.12',
        'application/vnd.ms-excel.addin.macroEnabled.12',
        'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
        'application/x-gtar',
        'application/x-gcompress',
        'application/compress',
        'application/x-tar',
        'application/x-rar-compressed',
        'application/vnd.rar',
        'application/octet-stream',
        'application/x-zip-compressed',
        'application/zip-compressed',
        'application/x-7z-compressed',
        'application/gzip',
        'application/x-bzip2',
    ];

    constructor(
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private router: Router,
        private taskService: TaskService,
        public taskDocumentService: TaskDocumentsService,
        public userService: UserService,
        public authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Tasks, [ClaimValues.FullAccess]);
        this.authService.currentUser.subscribe((currentUser) => {
            this.userService.getTenantForUser(currentUser.Id).subscribe((user) => {
                if (user.TenantTypeId === TenantEnums.PMC || user.TenantTypeId === TenantEnums.HL) {
                    this.hasFullAccess = true;
                }
            });
        });

        // get the id from the route
        const id = this.getIdFromRoute(this.route, 'Id');
        if (id) {
            this.taskService.getTaskById(id).subscribe((response) => {
                this.task = response.body;
                this.getRelatedTasks();
            });
        } else {
            void this.router.navigate(['tasks']); // if no id found, go back to list
        }
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10)) ? null : parseInt(id, 10);
    }

    downloadEmail(): void {
        const id = this.getIdFromRoute(this.route, 'Id');

        this.taskService.downloadTaskEmail(id).subscribe((response: string) => {
            const thefile = new Blob([response]);
            saveAs(thefile, this.task.MessageSubject + '.eml');
        });
    }

    getRelatedTasks(): void {
        this.taskService.getRelatedTasks(this.task.Id).subscribe((response) => {
            this.relatedTasks = response.body;
            this.relatedTasksForDocs = JSON.parse(JSON.stringify(this.relatedTasks));
        });
    }

}
