import { ITaskColumns } from './../../model/interfaces/custom/ITaskColumns.d';
import { IDboTaskDTO } from './../../model/interfaces/custom/IDboTaskDTO';
import { ITaskRow } from './task-list.component';
import {
    EntityListConfig,
    SortDirection,
    EntityListColumn,
    IEntityListConfig,
    EntityListDeleteColumn,
    EntityListSelectColumn,
    SelectTypes,
} from '@mt-ng2/entity-list-module';
import { ClientListComponent } from './client-list-component';
import { SupportingDocumentsListComponent } from './supporting-document-list-component';
import { TenantListComponent } from './tenant-list-component';
import { DocumentNameComponent } from './document.component';
import { RowExpanderComponent } from './row-expander.component';
import { RowSelectorComponent } from './row-selector.component';
import { DatePipe } from '@angular/common';
import { TagOnTaskListComponent } from './tag-on-task-list.component';
import { TaskStatuses } from '../../../app/common/constants/Enum';

export class TasksEntityListConfig extends EntityListConfig {
    constructor(obj: ITaskColumns, datePipe: DatePipe, delFlag: boolean, taxCols: boolean, onlyTaxCols: boolean) {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    component: RowExpanderComponent,
                    fireOnColumnSelected: false,
                    name: '', // Expand
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    component: RowSelectorComponent,
                    fireOnColumnSelected: false,
                    name: '', // Select
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessors: ['Id'],
                    fireOnColumnSelected: true,
                    name: 'Task Id',
                    sort: { disableSort: false },
                }),
            ],
            rowClass: (entity: ITaskRow) => {
                const classes = ['task-list-row'];
                if (!entity.expanded) {
                    classes.push('task-list-row-collapsed');
                }
                if (entity.Archived) {
                    classes.push('archived');
                }
                return classes.join(' ');
            },
            select: new EntityListSelectColumn(SelectTypes.Multi),
        };
        if ('EmailId' in obj && obj.EmailId === true) {
            listConfig.columns.push(
                new EntityListColumn({
                    accessorFunction: (entity: IDboTaskDTO) => `${entity.EmailId ? entity.EmailId : ''}`,
                    fireOnColumnSelected: true,
                    name: 'Email Id',
                    sort: { disableSort: false },
                }),
            );
        }
        if (taxCols && (!('IsTaxDoc' in obj) || obj.IsTaxDoc)) {
            listConfig.columns.push(
                new EntityListColumn({
                    accessorFunction: (entity: IDboTaskDTO) => {
                        if (entity.IsTaxDoc) {
                            return `<div><i class="fa fa-university"></i></div>`;
                        } else {
                            return '';
                        }
                    },
                    bindToInnerHtml: true,
                    fireOnColumnSelected: true,
                    name: 'Is Tax Doc',
                    sort: { disableSort: false },
                }),
            );
        }
        listConfig.columns.push(
            new EntityListColumn({
                accessorFunction: (entity: IDboTaskDTO) => {
                    if (entity.HighPriority === true) {
                        return 'True';
                    }
                    return 'False';
                },
                bindToInnerHtml: true,
                fireOnColumnSelected: true,
                name: 'Is high priority',
            }),
        );
        listConfig.columns.push(
            new EntityListColumn({
                component: DocumentNameComponent,
                fireOnColumnSelected: true,
                name: 'Document Name',
                sort: { disableSort: true },
            }),
        );

        for (const prop in obj) {
            if (prop === 'OriginalDocumentName' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        accessorFunction: (entity: IDboTaskDTO) => `${entity.AttachmentOriginalName ? entity.AttachmentOriginalName : ''}`,
                        columnClass: 'wrapword',
                        fireOnColumnSelected: true,
                        name: 'Original Document Name',
                        sort: { disableSort: true },
                    }),
                );
            } else if (prop === 'ShowAttachmentPassword' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        accessorFunction: (entity: IDboTaskDTO) => `${entity?.AttachmentPassword != null ? entity.AttachmentPassword : ''}`,
                        columnClass: 'wrapword',
                        fireOnColumnSelected: true,
                        name: 'Attachment Password',
                        sort: { disableSort: true },
                    }),
                );
            } else if (prop === 'Clients' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        component: ClientListComponent,
                        fireOnColumnSelected: true,
                        name: 'Clients',
                        sort: { disableSort: true },
                    }),
                );
            } else if (prop === 'Fund' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        accessorFunction: (entity: any) => {
                            if (entity.FundName) {
                                return entity.FundName;
                            } else if (entity.InvestmentName) {
                                return entity.InvestmentName;
                            } else {
                                return '';
                            }
                        },
                        fireOnColumnSelected: true,
                        name: 'Fund/Tax Position',
                        sort: { disableSort: false },
                    }),
                );
            } else if (prop === 'DocumentType' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        accessorFunction: (entity: any) => {
                            if (entity.DboTaskTypeName) {
                                return entity.DboTaskTypeName;
                            } else {
                                return '';
                            }
                        },
                        fireOnColumnSelected: true,
                        name: 'Document Type',
                        sort: { disableSort: false },
                    }),
                );
            } else if (prop === 'IsPCAPAudited' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        accessorFunction: (entity: IDboTaskDTO) => {
                            if (entity?.IsPCAPAudited === true) {
                                return 'true';
                            }
                            return 'false';
                        },
                        fireOnColumnSelected: true,
                        name: 'PCAP Audited',
                        sort: { disableSort: true },
                    }),
                );
            }
            else if (prop === 'Tags' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        component: TagOnTaskListComponent,
                        fireOnColumnSelected: true,
                        name: 'Tags',
                        sort: { disableSort: true },
                    }),
                );
            }
            else if (prop === 'IsDistributionNotice' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        accessorFunction: (entity: IDboTaskDTO) => {
                            if (entity?.IsDistributionNotice === true) {
                                return 'true';
                            }
                            return 'false';
                        },
                        fireOnColumnSelected: true,
                        name: 'Distribution Notice',
                        sort: { disableSort: true },
                    }),
                );
            } else if (prop === 'Notes' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        accessorFunction: (entity: any) => {
                            if (entity.Notes) {
                                return entity.Notes;
                            } else {
                                return '';
                            }
                        },
                        fireOnColumnSelected: true,
                        name: 'Notes',
                        sort: { disableSort: false },
                    }),
                );
            } else if (prop === 'SupportingDocuments' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        component: SupportingDocumentsListComponent,
                        fireOnColumnSelected: true,
                        name: 'Supporting Documents',
                        sort: { disableSort: true },
                    }),
                );
            } else if (prop === 'DueDate' && obj[prop] === true && !onlyTaxCols) {
                listConfig.columns.push(
                    new EntityListColumn({
                        accessorFunction: (entity: any) => {
                            if (entity.DateNecessary) {
                                const DateNecessary: string | number | Date = entity.DateNecessary;
                                return datePipe.transform(DateNecessary, undefined, 'UTC');
                            } else {
                                return '';
                            }
                        },
                        fireOnColumnSelected: true,
                        name: 'Due Date',
                        sort: { disableSort: false },
                    }),
                );
            } else if (prop === 'StatementDate' && obj[prop] === true && !onlyTaxCols) {
                listConfig.columns.push(
                    new EntityListColumn({
                        accessorFunction: (entity: any) => {
                            if (entity.StatementDate) {
                                const StatementDate: string | number | Date = entity.StatementDate;
                                return datePipe.transform(StatementDate, undefined, 'UTC');
                            } else {
                                return '';
                            }
                        },
                        fireOnColumnSelected: true,
                        name: 'Statement Date',
                        sort: { disableSort: false },
                    }),
                );
            } else if (prop === 'TaxFormYear' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        accessorFunction: (entity: any) => {
                            if (entity.TaxFormYear) {
                                return entity.TaxFormYear;
                            } else {
                                return '';
                            }
                        },
                        fireOnColumnSelected: true,
                        name: 'Tax Form Year',
                        sort: { disableSort: false },
                    }),
                );
            } else if (prop === 'ReceivedDate' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        accessorFunction: (entity: any) => {
                            if (entity.ReceivedDate) {
                                const ReceivedDate: string | number | Date = entity.ReceivedDate;
                                return datePipe.transform(ReceivedDate, undefined, 'UTC');
                            } else {
                                return '';
                            }
                        },
                        fireOnColumnSelected: true,
                        name: 'Received Date',
                        sort: {
                            defaultDirection: SortDirection.Desc,
                            disableSort: false,
                            isDefaultForSort: true,
                        },
                    }),
                );
            } else if (prop === 'Tenants' && obj[prop] === true && delFlag) {
                listConfig.columns.push(
                    new EntityListColumn({
                        component: TenantListComponent,
                        fireOnColumnSelected: true,
                        name: 'Tenants',
                        sort: { disableSort: true },
                    }),
                );
            } else if (prop === 'Status' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        accessorFunction: (entity: any) => {
                            if (entity.StatusId) {
                                switch(entity.StatusId) {
                                    case TaskStatuses.Renamed:  
                                        return 'Renamed';
                                    case TaskStatuses.Pass: 
                                        return 'Pass';
                                    case TaskStatuses.Exception:
                                        return 'Exception';
                                    case TaskStatuses.NoReviewNeeded:
                                        return 'No Review Needed';
                                    default:
                                        return 'Created';
                                }
                            } else {
                                return '';
                            }
                        },
                        fireOnColumnSelected: true,
                        name: 'Status',
                        sort: { disableSort: false },
                    }),
                );
            }
        }
        if (delFlag) {
            listConfig.delete = new EntityListDeleteColumn({
                columnHtml: (entity: IDboTaskDTO) =>
                    '<div class="text-center">' +
                    '<i class="fa fa-lg fa-trash" aria-hidden="true"></i>' +
                    (entity.Archived ? '<i class="fa fa-lg fa-undo overlay-fa"></i>' : '') +
                    '</div>',
                confirm: false,
                headerText: 'Archive',
            });
        }
        super(listConfig);
    }
}
