import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IMessageLockStatus } from '../../model/interfaces/message-lock-status';

@Injectable({
    providedIn: 'root',
})
export class MessageLockStatusService extends MetaItemService<IMessageLockStatus> {
    constructor(public http: HttpClient) {
        super('MessageLockStatusService', 'Status', 'StatusIds', '/messageLockStatuses', http);
    }
}
