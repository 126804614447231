import { ITenant } from './../../model/interfaces/tenant.d';
import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { AuthUserDynamicControlsExtended } from '../../model/partials/auth-user.form-controls';

import { IAuthUser } from '../../model/interfaces/auth-user';
import { IUserRoleType } from '../../model/interfaces/user-role-type';

export class AuthUserDynamicConfig<T extends IAuthUser> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private authUser: T,
        changePassword: boolean,
        private tenants: ITenant[],
        private roleTypes: IUserRoleType[],
        private configControls?: string[],
        isNewUser = false,
        tenantId = 0,
        isPasswordEmpty?: boolean,
    ) {
        super();

        const dynamicControls = new AuthUserDynamicControlsExtended(
            this.authUser,
            changePassword,
            this.tenants,
            this.roleTypes,
            isNewUser,
            tenantId,
            isPasswordEmpty,
        );
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Username', 'TenantId', 'RoleTypeId'];
        }

        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(),
        };
    }
}
