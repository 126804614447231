import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SharedModule } from '../../common/shared.module';

@Injectable({
    providedIn: SharedModule,
})
export class InvestmentAddDealsReportService {
    dealUploadReportEvent = new Subject<string[]>();

    dealUploadReportObservable = this.dealUploadReportEvent.asObservable();

    dealReport(report: string[]): void {
        this.dealUploadReportEvent.next(report);
    }
}
