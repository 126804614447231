import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { TaskTypeService } from '../../tasks/task-doc-type-service';
import { IDboTaskType } from '../../model/interfaces/dbo-task-type';
import { ClaimTypes } from '../../model/ClaimTypes';

@Component({
    templateUrl: './dbo-task-type-detail.component.html',
})
export class DboTaskTypeDetailComponent implements OnInit {
    dboTaskType: IDboTaskType;
    canEdit: boolean;
    canAdd: boolean;

    constructor(
        private dboTaskTypeService: TaskTypeService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Tasks, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = this.getIdFromRoute(this.route, 'dboTaskTypeId');
        if (id) {
            this.getDboTaskTypeById(id);
        } else {
            void this.router.navigate(['documentTypes']); // if no id found, go back to list
        }
    }

    getDboTaskTypeById(id: number): void {
        this.dboTaskTypeService.getById(id).subscribe((dboTaskType) => {
            if (dboTaskType === null) {
                this.notificationsService.error('Document Type not found');
                void this.router.navigate(['documentTypes']);
            }
            this.dboTaskType = dboTaskType;
        });
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10)) ? null : parseInt(id, 10);
    }
}
