import { ITenant } from './../interfaces/tenant.d';
import { AuthUserDynamicControls } from '../form-controls/auth-user.form-controls';
import { IAuthUser } from '../interfaces/auth-user';
import { Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, noZeroRequiredValidator } from '@mt-ng2/dynamic-form';
import { LoginConfig } from '@mt-ng2/login-module';
import { LoginConfigOverride } from '../../common/configs/login.config';
import { IUserRoleType } from '../interfaces/user-role-type';
import { TenantTypeEnum } from '../../common/constants/Enum';
export class AuthUserDynamicControlsExtended extends AuthUserDynamicControls {
    constructor(
        private extAuthUser: IAuthUser,
        changePassword: boolean,
        tenants?: ITenant[],
        roleTypes?: IUserRoleType[],
        isNewUser = false,
        tenantId = 0,
        isPasswordEmpty?: boolean,
    ) {
        super(extAuthUser, { tenants, roleTypes });

        const loginConfig = new LoginConfig(LoginConfigOverride);
        let passwordRegexPattern = loginConfig.passwordPattern;
        let failedPatternMessage = LoginConfigOverride.messageOverrides.failedPattern;
        if (isNewUser) {
            let tenant = new DynamicField({
                formGroup: 'AuthUser',
                label: 'Tenant',
                name: 'TenantId',
                options: this.tenants,
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Select }),
                validation: [noZeroRequiredValidator],
                validators: { required: true },
                value: tenantId,
            });
            this.Form.TenantId = tenant;
            let roleTypes = new DynamicField({
                formGroup: '',
                label: 'User Role',
                name: 'RoleTypeId',
                options: this.roleTypes,
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Select }),
                validation: [noZeroRequiredValidator],
                validators: { required: true },
                value: 0,
            });
            this.Form.RoleTypeId = roleTypes;
        }
        if (changePassword) {
            let sendEmail = new DynamicField({
                formGroup: 'AuthUser',
                label: 'Send Password Reset Email',
                name: 'SendResetEmail',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Checkbox }),
                validators: { required: true },
                value: '',
            });
            this.Form.SendResetEmail = sendEmail;

            // Add the controls to validate passwords.  These are not in the model.
            this.Form.ConfirmPassword = new DynamicField({
                failedPatternMessage: failedPatternMessage,
                formGroup: 'AuthUser',
                label: 'Confirm Password',
                labelHtml: `<label>Confirm Password</label>`,
                name: 'ConfirmPassword',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Password }),
                validation: [Validators.pattern(passwordRegexPattern)],
                validators: {
                    pattern: passwordRegexPattern,
                    required: false,
                },
                value: '',
            });

            this.Form.CurrentPassword = new DynamicField({
                formGroup: 'AuthUser',
                label: 'Current Password',
                name: 'CurrentPassword',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Password }),
                validation: [Validators.required],
                validators: { required: false },
                value: '',
            });
        }

        (<DynamicField>this.Form.Password).validation.push(Validators.pattern(passwordRegexPattern));
        (<DynamicField>this.Form.Password).validators.pattern = passwordRegexPattern;
        (<DynamicField>this.Form.Password).failedPatternMessage = failedPatternMessage;
    }
}
