import { TaskListComponent } from './tasks/task-list/task-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { CustomersComponent } from './customers/customer-list/customers.component';
import { AppSettingsComponent } from './app-settings.component';
import { ClaimTypes } from './model/ClaimTypes';
import { UserService } from './users/user.service';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import {
    LoginOldStyleComponent,
    ForgotPasswordOldStyleComponent,
    ResetPasswordOldStyleComponent,
    AdminAccessOldStyleComponent,
} from '@mt-ng2/login-module';
import { NotFoundComponent } from './default-routes/not-found/not-found.component';
import { PathNotFoundResolveService } from './default-routes/path-not-found-resolve.service';
import { appPaths } from './default-routes/app-paths.library';
import { DashboardComponent } from '@mt-ng2/dashboard';
import { DownloadComponent } from './common/components/download/download.component';

import { ADLoginComponent } from './common/components/ad-login/ad-login.component';

const userEntityConfig: IEntityRouteConfig = {
    addressesPath: 'addresses',
    claimType: ClaimTypes.Users,
    documentsPath: 'documents',
    entityIdParam: 'userId',
    notesPath: 'notes',
    service: UserService,
};

const appRoutes: Routes = [
    { path: appPaths.login, component: LoginOldStyleComponent },
    { path: `${appPaths.login}/:adPath`, component: ADLoginComponent },
    { path: appPaths.forgotPassword, component: ForgotPasswordOldStyleComponent },
    { path: appPaths.resetPassword, component: ResetPasswordOldStyleComponent },
    { path: appPaths.adminAccess, component: AdminAccessOldStyleComponent },
    {
        canActivate: [AuthGuard],
        component: TaskListComponent,
        path: appPaths.home,
    },
    {
        canActivate: [AuthGuard],
        component: DownloadComponent,
        path: appPaths.download,
    },
    {
        canActivate: [AuthGuard],
        component: AppSettingsComponent,
        data: <IRoleGuarded>{
            claimType: ClaimTypes.AppSettings,
            claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        },
        path: appPaths.settings,
    },
    {
        canActivate: [AuthGuard],
        component: UserDetailComponent,
        path: appPaths.myProfile,
        pathMatch: 'full',
    },
    { path: '',
    pathMatch: 'full',
    redirectTo: appPaths.home },
    {
        canActivate: [AuthGuard],
        component: NotFoundComponent,
        path: '**',
        resolve: {
            path: PathNotFoundResolveService,
        },
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
})
export class AppRoutingModule {}
