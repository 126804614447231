import { NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { EmailContactRoutingModule } from './email-contact-routing.module';
import { EmailContactAddComponent } from './email-contact-add/email-contact-add.component';
import { EmailContactBasicInfoComponent } from './email-contact-basic-info/email-contact-basic-info.component';
import { EmailContactDetailComponent } from './email-contact-detail/email-contact-detail.component';
import { EmailContactsComponent } from './email-contact-list/email-contacts.component';
import { EmailContactHeaderComponent } from './email-contact-header/email-contact-header.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        EmailContactsComponent,
        EmailContactHeaderComponent,
        EmailContactAddComponent,
        EmailContactDetailComponent,
        EmailContactBasicInfoComponent,
    ],
    imports: [SharedModule, EmailContactRoutingModule, CommonModule],
})
export class EmailContactModule {}
