import { Component, OnInit } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IVwClient } from '../../model/interfaces/vw-client';
import { ClientService } from '../client.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';

@Component({
    styles: [],
    template: `
        <span *ngIf="hasAccess" class="btn-group btn-group-toggle">
            <span (click)="toggleTransit(false)" [ngClass]="!hasTransitioned ? 'btn btn-primary active' : 'btn btn-secondary'">
                <p>Yes</p>
            </span>
            <span (click)="toggleTransit(true)" [ngClass]="hasTransitioned ? 'btn btn-primary active' : 'btn btn-secondary'">
                <p>No</p>
            </span>
        </span>
        <span *ngIf="!hasAccess">
            <span *ngIf="!hasTransitioned">
                <p>Yes</p>
            </span>
            <span *ngIf="hasTransitioned">
                <p>No</p>
            </span>
        </span>
    `,
})
export class InTransitCellComponent implements IEntityListDynamicCellComponent, OnInit {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.client = value as IVwClient;
        this.hasTransitioned = this.client.HasTransitioned;
    }
    client: IVwClient;
    hasTransitioned: boolean;
    hasAccess = false;
    constructor(private clientService: ClientService, private claimService: ClaimsService) {}
    ngOnInit(): void {
        this.hasAccess = this.claimService.hasClaim(ClaimTypes.Clients, [ClaimValues.FullAccess]);
    }

    toggleTransit(hasTransitioned: boolean): void {
        event.stopPropagation();
        if (this.hasTransitioned !== hasTransitioned && this.hasAccess) {
            this.hasTransitioned = hasTransitioned;
            this.client.HasTransitioned = this.hasTransitioned;
            this.clientService.setTransitionStatus(this.client.Id, this.hasTransitioned).subscribe(() => {
                this.announceUpdate(this.client);
            });
        }
    }

    announceUpdate(updatedItem: IVwClient): void {
        this.clientService.refreshClients(updatedItem);
    }
}
