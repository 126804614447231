<div>
    <div class="row">
        <div class="col-md-7">
            <h2 [mtSetTitle]="header$">
                <mt-route-back-button></mt-route-back-button>
                <mt-audit-logs-for-record *ngIf="user" [recordId]="user.Id" entity="User">
                    <ng-template #auditModalTriggerTemplate let-auditModalTemplateApi>
                        <button class="btn btn-primary" (click)="auditModalTemplateApi.showLogsModal($event)">Audit Log</button>
                    </ng-template>
                </mt-audit-logs-for-record>
            </h2>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
