import { ContactSharedEntitiesConfig } from '../../model/shared-entities/contacts/contact.shared-entities-config';
import { CustomerContactService } from './customer-contact.service';

export enum CustomerSharedEntities {
    Contacts,
}

export const contactsSharedEntity = {
    config: ContactSharedEntitiesConfig,
    entityIdParam: 'contactId',
    path: 'contacts',
    service: CustomerContactService,
    sharedEntity: CustomerSharedEntities.Contacts,
};
