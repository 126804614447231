import { EntityListConfig, SortDirection, EntityListColumn, IEntityListConfig, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { IDboInvestment } from '../../model/interfaces/dbo-investment';
import { ViewNoteComponent } from '../../common/components/notes-modal/view-note-component';
import { IEntityListConfigWithNotes } from '../../model/interfaces/custom/EntityListConfigWithNotes';
import { InvestmentService } from '../investment-service';

export class InvestmentsEntityListConfig extends EntityListConfig {
    constructor(noteService: InvestmentService) {
        const listConfig: IEntityListConfigWithNotes = {
            columns: [
                new EntityListColumn({
                    name: 'Name',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        isDefaultForSort: true,
                    },
                }),
                new EntityListColumn({
                    component: ViewNoteComponent,
                    fireOnColumnSelected: false,
                    name: 'Note',
                    sort: { sortProperty: 'Note' },
                    style: { width: 140 },
                }),
            ],
            delete: new EntityListDeleteColumn({
                columnHtml: (entity: IDboInvestment) =>
                    '<div class="text-center investment-archive">' +
                    '<i class="fa fa-lg fa-trash" aria-hidden="true"></i>' +
                    (entity.IsArchived ? '<i class="fa fa-lg fa-undo overlay-fa"></i>' : '') +
                    '</div>',
                confirm: false,
                headerText: 'Archive',
            }),
            noteService: noteService,
        };
        super(listConfig);
    }
}
