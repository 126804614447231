import { UserService } from './../../users/user.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Component, OnInit, ViewChild, OnDestroy, ElementRef, HostListener } from '@angular/core';
import { IColumnSortedEvent, SortDirection, ISelectionChangedEvent } from '@mt-ng2/entity-list-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { DatePipe } from '@angular/common';
import { Observable, forkJoin, interval, Subscription, fromEvent } from 'rxjs';
import { ClaimsService, ClaimValues, AuthService } from '@mt-ng2/auth-module';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ClaimTypes } from '../../model/ClaimTypes';
import {
    DaysOfTheWeek,
    getDateRangeByContextualFilter,
    ISearchFilterDaterangeValue,
} from '../../common/components/date-time/search-filter-daterange/search-filter-daterange.library';
import { DboMessageService } from '../services/dbo-message.service';
import { MessagesEntityListConfig, messageColumnConfigDictionary } from './message-list-config';
import { IDboMessageDTO } from '../../model/interfaces/custom/IDboMessageDTO';
import { IMessageSearchObject, MessageSearchObject } from '../../model/partials/message.search';
import { IMessageSearchDTO } from '../../model/interfaces/custom/IMessageSearchDTO';
import { MessageSearchService } from '../services/message-search.service';
import { IUser } from '../../model/interfaces/user';
import { IUserWithFullName } from '../../model/partials/user';
import { IMessagePermissions } from '../../model/interfaces/custom/IMessagePermissions';
import { TaskMsgAuditLogService } from '../../audit/audit-services/task-msg-audit-log.service';
import {
    LogTypeEnum,
    AttachmentState,
    MessageSearchStatus,
    MessageSearchTaxStatus,
    LockStatuses,
    TenantTypeEnum,
    RoleTypeEnum,
} from '../../common/constants/Enum';
import { IMessageColumn } from '../../model/interfaces/custom/IMessageColumn';
import { TenantService } from '../../tenants/tenant.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotesUpdatedService } from '../../common/components/notes-modal/notes-modal-updated-service';
import { NotesModalService } from '../../common/components/notes-modal/notes-modal-service';
import { SaveSearchModalService } from '../saveSearchModal/saveSearchModal.service';
import { SaveSearchUpdateService } from '../saveSearchModal/saveSearchUpdateService';
import { IMessageTag } from '../../model/interfaces/message-tag';
import { MessageTagService } from '../../message-tags/services/message-tag.service';
import { IEntity } from '@mt-ng2/shared-entities-module';
import { ContrastColorText } from '../../common/util';
import { IDboMessageManagedNote } from '../../model/interfaces/dbo-message-managed-note';
import { MessageManagedNotesService } from '../../message-managed-notes/message-managed-notes.service';
import { MessageAutomationStatuService } from '../services/message-automation-statu.service';
import { IMessageTagParams } from '../../model/interfaces/custom/messageTagParams';
import { KeyboardShortcutHandler, KeyboardKeys } from '@mt-ng2/keyboard-shortcuts-module';

import { MessageTaxSearchOptionService } from '../services/message-tax-search-option.service';
import { MessageStatusSearchService } from '../services/message-status-search.service';
import { AssignTagUpdateService } from '../assignTagModal/assignTagUpdateService';
import { AssignUserUpdateService } from '../assignUserModal/assignUserUpdateService';
import { AssignTagModalService } from '../assignTagModal/assignTagModal.service';
import { AssignUserModalService } from '../assignUserModal/assignUserModal.service';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { AttachmentProcessingService } from '../../common/services/attachment-processing-service';
import { MessageLockStatusService } from '../services/message-lock-status.service';
import { ProcessAttachmentAddModalService } from '../../processAttachments/processAttachment-add-modal/processAttachmentAddModal.service';
import { InboxAttachmentUpdateService } from '../inbox-attachments/InboxAttachmentUpdateService';
import { AttachmentMetadataService } from '../../processAttachments/attachment-meta-data.service';
import { INoteEntity } from '../../model/interfaces/custom/EntityListConfigWithNotes';
import { ITaskMsgAuditLog } from '../../model/interfaces/task-msg-audit-log';
import { INamedEntity } from '@mt-ng2/multiselect-control';
import { IAssignEmailsDTO } from '../../model/interfaces/custom/IAssignEmailsDTO';

@Component({
    selector: 'app-message-list',
    styleUrls: ['./../../common/cdk-drag-drop-disables.css', './message-list-component.styles.less'],
    templateUrl: './message-list-component.html',
})
export class MessageListComponent extends ContrastColorText implements OnInit, OnDestroy {
    @ViewChild('stickyHead') stickyHead: ElementRef;
    @ViewChild('stickyHeadResult') stickyHeadResult: ElementRef;
    configureColumnsPopUpApi: IModalWrapperApi;
    manualNoteModalApi: IModalWrapperApi;
    assignModalApi: IModalWrapperApi;
    assignEmailModalApi: IModalWrapperApi;
    query: string;
    messages: IDboMessageDTO[];
    selectedMessages: IDboMessageDTO[];
    total: number;
    currentPage = 1;
    entityListConfig: MessagesEntityListConfig = null;
    pipe = new DatePipe('en-US');
    includeArchive = false;
    savedSearches: IMessageSearchDTO[] = [];
    selectedSearch: IMessageSearchDTO;
    orgSearchCount = 0;
    emptySearchObject: IMessageSearchDTO = this.messageSearchService.getEmptySearchObject();
    startReceivedDate: Date;
    endReceivedDate: Date;
    savedSearchId: number;
    userMessageColumns: IMessageColumn[] = [];
    columnList = [];
    hasAccess: IMessagePermissions = { FullAccess: false, AssignAny: false, AssignSelf: false, CreateOrgSearchFilters: false, Tags: false };
    elementPerPage = [100, 200, 300];
    selectedElementPerPage = 50;
    untaggedMessagesCheckbox = false;
    currSearchObject: IMessageSearchObject = {
        assigneeIds: [],
        assignerIds: [],
        automationStatusIds: [],
        body: '',
        contextualDateId: 0,
        endReceivedDate: '',
        from: '',
        isOrgSearch: false,
        lockStatuses: [LockStatuses.Unlocked],
        messageIds: '',
        messageSearchStatuses: [MessageSearchStatus.UnProcessed],
        messageSearchTaxStatuses: [MessageSearchTaxStatus.NonTax],
        name: '',
        order: '',
        orderDirection: '',
        query: '',
        savedSearchId: 0,
        skip: (this.currentPage - 1) * this.selectedElementPerPage,
        startReceivedDate: '',
        subject: '',
        tagIds: [],
        take: this.selectedElementPerPage,
        taskIds: '',
        untaggedMessages: this.untaggedMessagesCheckbox,
    };
    subscription = new Subscription();
    loadMessageInterval = interval(300000);
    isSearching = false;
    loadNewEmails = false;
    users: IUserWithFullName[];
    selectedUser: IUserWithFullName;
    currUser: IUser;
    modalOpen = false;
    isSelectionOrgSearch = undefined;
    expandedMessage: IDboMessageDTO;
    emailBodyUpdateSubscription = new Subscription();
    noteUpdateSubscription = new Subscription();
    searchUpdateSubscription = new Subscription();
    isSearchCollapsed = false;

    tags: IMessageTag[] = [];
    selectedTags: IMessageTag[] = [];
    searchFilterTags: MtSearchFilterItem[] = [];
    tagsForAssignment: MtSearchFilterItem[] = [];

    automationStatus: MtSearchFilterItem[] = [];
    taxSearchOptions: MtSearchFilterItem[] = [];
    statusSearchOptions: MtSearchFilterItem[] = [];
    lockSearchOptions: MtSearchFilterItem[] = [];

    assigneeSearch: MtSearchFilterItem[] = [];
    assignerSearch: MtSearchFilterItem[] = [];

    manualNote = '';
    selectedNote: IDboMessageManagedNote = null;
    managedNotes: IDboMessageManagedNote[] = [];

    loadingMore = false;
    mtKeyboardShortcutDef = {
        assign: { description: 'assign', handler: this.assignHotkey(), keyBinding: [KeyboardKeys.Ctrl, 'a'] },
        process: { description: 'process', handler: this.processHotkey(), keyBinding: [KeyboardKeys.Ctrl, 'p'] },
        tag: { description: 'tag', handler: this.tagHotkey(), keyBinding: [KeyboardKeys.Ctrl, KeyboardKeys.Alt, 't'] },
    };

    messageReplyRefresh = new Subscription();
    messageTagRefresh = new Subscription();

    assignTagsModalSubscription = new Subscription();
    assignUserModalSubscription = new Subscription();
    addAttachmentModalSubscription = new Subscription();
    processAllAttachmentSubscription = new Subscription();

    storedHeaderNode: HTMLTableSectionElement = null;

    resizeObservable$: Observable<Event>;
    resizeSubscription$: Subscription;

    assigneeSearchModel: any[];
    assignerSearchModel: any[];

    paramMessageId = '';
    private manualNoteMessageIds: number[];
    private manualNoteTagIds: number[];
    assignEmailSearchFilterTags: MtSearchFilterItem[] = [];
    selectedOptions: number[] = [];
    selectedTagIdsForAssignEmails: number[] = [];
    currentSelection = {Id: null, Name: null, Value: null};
    

    assignEmailModalOptions: IModalOptions = {
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Assign Emails',
        width: 650
    }

    constructor(
        public messageService: DboMessageService,
        public notificationsService: NotificationsService,
        private addAttachmentModalService: ProcessAttachmentAddModalService,
        public userService: UserService,
        public claimsService: ClaimsService,
        public messageSearchService: MessageSearchService,
        public authService: AuthService,
        public auditService: TaskMsgAuditLogService,
        public tenantService: TenantService,
        public router: Router,
        public notesUpdatedService: NotesUpdatedService,
        public saveSearchModalService: SaveSearchModalService,
        public updateSearchService: SaveSearchUpdateService,
        public tagService: MessageTagService,
        public managedNotesService: MessageManagedNotesService,
        public automationStatusService: MessageAutomationStatuService,
        public messageTaxSearchStatus: MessageTaxSearchOptionService,
        public messageStatusService: MessageStatusSearchService,
        public messageLockStatusService: MessageLockStatusService,
        public tagModalService: AssignTagModalService,
        public userModalService: AssignUserModalService,
        public tagModalUpdateService: AssignTagUpdateService,
        public userModalUpdateService: AssignUserUpdateService,
        public inboxAttachmentModalUpdateService: InboxAttachmentUpdateService,
        public attachmentProcessingService: AttachmentProcessingService,
        public metadataService: AttachmentMetadataService,
        public notesModalService: NotesModalService,
        private route: ActivatedRoute,
    ) {
        super();
        if (this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.processingWindowStillOpen) {
            this.attachmentProcessingService.setIsProcessingTabOpen(true);
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.emailBodyUpdateSubscription.unsubscribe();
        this.noteUpdateSubscription.unsubscribe();
        this.searchUpdateSubscription.unsubscribe();
        this.messageReplyRefresh.unsubscribe();
        this.messageTagRefresh.unsubscribe();
        this.assignTagsModalSubscription.unsubscribe();
        this.assignUserModalSubscription.unsubscribe();
        this.resizeSubscription$.unsubscribe();
    }

    ngOnInit(): void {
        this.paramMessageId = this.route.snapshot.paramMap.get('id');
        this.isSearchCollapsed = this.messageService.getIsSearchCollapsed();
        this.entityListConfig = new MessagesEntityListConfig(this.messageService, this.pipe);
        this.setColumnsList();
        this.savedSearchId = this.messageService.getLatestSavedSearchId();
        this.getAllSearches();
        forkJoin(
            this.tagService.getAll(),
            this.userService.getAll(),
            this.managedNotesService.getAll(),
            this.automationStatusService.getSearchFilterItems(),
            this.messageTaxSearchStatus.getSearchFilterItems(),
            this.messageStatusService.getSearchFilterItems(),
            this.messageLockStatusService.getSearchFilterItems(),
        ).subscribe((data) => {
            const [tags, allUsers, managedNotes, automationStatus, taxSearchOptions, statusSearchOptions, lockStatusOptions] = data;
            const filterUnArchivedTags = tags.filter((t) => !t.IsDeleted);

            this.tags = filterUnArchivedTags;
            this.searchFilterTags = this.convertToSearchFilter(filterUnArchivedTags);
            this.assignEmailSearchFilterTags = this.convertToSearchFilter(filterUnArchivedTags);
            this.tagsForAssignment = this.convertToSearchFilter(this.tags);

            this.users = allUsers;
            this.users = this.users.filter((u) => u.AuthUser.HasAccess && u.AuthUser.TenantTypeId === TenantTypeEnum.Internal);
            this.users.unshift({ Id: 0, FirstName: '', LastName: 'Unassigned', AuthUserId: 0, Email: null, Version: [] });
            this.users.map((u) => (u.Name = `${u.FirstName} ${u.LastName}`));

            this.assignerSearch = this.convertToSearchFilter(this.users as INamedEntity[]);
            this.assigneeSearch = this.convertToSearchFilter(this.users as INamedEntity[]);
            this.managedNotes = managedNotes;
            this.automationStatus = automationStatus;
            this.taxSearchOptions = taxSearchOptions;
            this.statusSearchOptions = statusSearchOptions;
            this.lockSearchOptions = lockStatusOptions;

            this.populateFiltersBasedOnSelectedSearch(this.messageService.getLatestMessageSearch(this), true);

            this.getMessages(false, this.paramMessageId);

            this.authService.currentUser.subscribe((data) => {
                this.userService.getById(data.Id).subscribe((user) => {
                    this.currUser = user;
                    this.setAccess();
                    if (!this.hasAccess.AssignAny && this.hasAccess.AssignSelf) {
                        this.selectedUser = user;
                    }
                });
            });
        });

        this.noteUpdateSubscription.add(
            this.notesUpdatedService.refreshLogsObservable.subscribe(() => {
                this.reloadMessagesWithPriorPageCount();
            }),
        );

        this.searchUpdateSubscription.add(
            this.updateSearchService.addSearchObservable.subscribe((data) => {
                this.selectedSearch.Name = data.Name;
                this.selectedSearch.IsOrgSearch = data.IsOrgSearch;
                if (this.canSaveSearch(data.IsOrgSearch)) {
                    // Regular user should not be able to create or update organizational searches
                    if (this.currUser.AuthUser.RoleTypeId === RoleTypeEnum.User && data.IsOrgSearch) {
                        this.currSearchObject.isOrgSearch = false;
                    } else {
                        this.currSearchObject.isOrgSearch = data.IsOrgSearch;
                    }

                    this.confirmSaveSearch(data.IsOrgSearch);
                } else {
                    this.getAllSearches();
                }
                this.removeFixedTableHeader();
                this.getMessages();
            }),
        );

        this.messageReplyRefresh.add(
            this.messageService.announceGetMessagesObservable.subscribe(() => {
                this.getMessageWithoutComponentRefresh();
            }),
        );

        this.messageTagRefresh.add(
            this.messageService.tagOnMessageObservable.subscribe(() => {
                this.reloadMessagesWithPriorPageCount();
            }),
        );

        this.assignTagsModalSubscription.add(
            this.tagModalUpdateService.assignTagObservable.subscribe((data) => {
                this.selectedTags = data;
                setTimeout(() => {
                    this.tagMessages();
                });
            }),
        );

        this.assignUserModalSubscription.add(
            this.userModalUpdateService.selectMessageObservable.subscribe((data) => {
                this.selectedMessages = data;
            }),
        );

        this.assignUserModalSubscription.add(
            this.userModalUpdateService.assignUserObservable.subscribe((data) => {
                this.selectedUser = data;
                this.modalOpen = false;
                this.assignUsersToMessages();
            }),
        );

        this.addAttachmentModalSubscription.add(
            this.inboxAttachmentModalUpdateService.attachmentCompleteObservable.subscribe(() => {
                this.modalOpen = false;
                this.reloadMessagesWithPriorPageCount();
            }),
        );

        this.attachmentProcessingService.processingTabChanges$.asObservable().subscribe((data) => {
            if (!this.attachmentProcessingService.isProcessingTabOpen) {
                const allMessagesAsIds = this.attachmentProcessingService.messageIds;
                this.messageService.processingLock(allMessagesAsIds, false, 0).subscribe(() => {
                    this.attachmentProcessingService.cleanProcessingLocalStorage();
                    this.reloadMessagesWithPriorPageCount();
                });
            }
        });

        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
            if (this.messages && this.messages.length) {
                this.createFixedTableHeader();
            }
        });
    }

    getMore(): void {
        // if no messages assigned yet, then just return
        if (!(this.messages && this.messages.length)) {
            return;
        }
        if (this.messages.length < this.total) {
            this.currentPage += 1;
            this.loadingMore = true;
            this.getMessages(true);
        }
    }

    convertToSearchFilter(items: INamedEntity[]): MtSearchFilterItem[] {
        return items.map((i) => ({ Item: i, Selected: false }));
    }

    setColumnsList(): void {
        this.userService.getUserSavedMessageColumns().subscribe((data) => {
            if (data.length && data.length === Object.keys(messageColumnConfigDictionary).length) {
                this.userMessageColumns = data;
            } else {
                for (const prop in messageColumnConfigDictionary) {
                    if (prop in messageColumnConfigDictionary) {
                        this.userMessageColumns.push({
                            IsVisible: true,
                            Name: prop,
                        });
                    }
                }
            }
            this.entityListConfig = new MessagesEntityListConfig(this.messageService, this.pipe, this.userMessageColumns);
            this.currSearchObject.order = this.entityListConfig.getDefaultSortProperty();
            this.currSearchObject.orderDirection = this.entityListConfig.getDefaultSortDirection();
        });
    }

    setAccess(): void {
        this.hasAccess.FullAccess = this.claimsService.hasClaim(ClaimTypes.Inbox, [ClaimValues.FullAccess]);
        this.hasAccess.AssignAny = this.claimsService.hasClaim(ClaimTypes.AssignMessageAny, [ClaimValues.FullAccess]);
        this.hasAccess.AssignSelf = this.claimsService.hasClaim(ClaimTypes.AssignMessageSelf, [ClaimValues.FullAccess]);

        if (this.currUser?.AuthUser.RoleTypeId === RoleTypeEnum.User) {
            this.hasAccess.CreateOrgSearchFilters = false;
        } else {
            this.hasAccess.CreateOrgSearchFilters = this.claimsService.hasClaim(ClaimTypes.CreateOrganizationMessageSearches, [
                ClaimValues.FullAccess,
            ]);
        }

        this.hasAccess.Tags = this.claimsService.hasClaim(ClaimTypes.Tags, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);
    }

    selectedRecordsPerPage(): void {
        this.currentPage = 1;
        this.currSearchObject.take = this.selectedElementPerPage;
        this.getMessages();
    }

    setReceivedDates(event: ISearchFilterDaterangeValue): void {
        this.startReceivedDate = event.startDate;
        this.endReceivedDate = event.endDate;
        this.currSearchObject.startReceivedDate = this.pipe.transform(this.startReceivedDate, 'M/d/yyyy h:mm:ss a');
        this.currSearchObject.endReceivedDate = this.pipe.transform(this.endReceivedDate, 'M/d/yyyy h:mm:ss a');
        this.currSearchObject.contextualDateId = 0;
    }

    getMessages(isPageChange = false, paramMessageId = ''): void {
        this.currSearchObject.skip = (this.currentPage - 1) * this.selectedElementPerPage;
        this.currSearchObject.untaggedMessages = this.untaggedMessagesCheckbox;
        if (paramMessageId?.length && Number.isInteger(+paramMessageId)) {
            this.currSearchObject.messageIds = paramMessageId;

            // Reset filters before search
            this.statusSearchOptions.forEach((item) => (item.Selected = false));
            this.currSearchObject.messageSearchStatuses = [];

            this.taxSearchOptions.forEach((item) => (item.Selected = false));
            this.currSearchObject.messageSearchTaxStatuses = [];
        }
        this.messageService.getMessages(this.currSearchObject, true).subscribe((response) => {
            this.loadingMore = false;
            if (!isPageChange) {
                this.selectedMessages = [];
            }
            if (this.currentPage === 1) {
                this.messages = response.body;
            } else {
                const selectAllOn = this.messages.length === this.selectedMessages.length;
                this.messages = this.messages.concat(response.body);
                if (selectAllOn) {
                    this.selectedMessages = [...this.messages];
                }
            }
            this.total = +response.headers.get('X-List-Count');
            this.createFixedTableHeader();
        });
    }

    createFixedTableHeader(): void {
        if (this.messages) {
            if (this.storedHeaderNode) {
                const head = this.storedHeaderNode.children[0].children;
                for (let i = 0; i < head.length; i++) {
                    const interiorDiv: HTMLDivElement = head[i].firstElementChild as HTMLDivElement;
                    interiorDiv.style.width = interiorDiv.parentElement.clientWidth.toString() + 'px';
                    interiorDiv.style.top = `${+this.stickyHead.nativeElement.offsetHeight + 50}px`;
                    if (this.stickyHeadResult) {
                        this.stickyHeadResult.nativeElement.style.top = `${+this.stickyHead.nativeElement.offsetHeight + 50}px`;
                    }
                }
            } else {
                setTimeout(() => {
                    const tableHead = document.querySelector('thead');
                    const clonedHead = tableHead.cloneNode(true);
                    tableHead.parentNode.prepend(clonedHead);
                    const tableHeader = document.querySelectorAll('thead')[1];
                    this.storedHeaderNode = tableHeader;
                    const head = tableHeader.children.item(0).children;
                    for (let i = 0; i < head.length; i++) {
                        const interiorDiv = document.createElement('div');
                        const originalChild = head[i].firstElementChild;
                        head[i].replaceChild(interiorDiv, originalChild);
                        head[i].firstElementChild.appendChild(originalChild);
                        interiorDiv.style.width = interiorDiv.parentElement.clientWidth.toString() + 'px';
                        interiorDiv.style.setProperty('top', `${+this.stickyHead.nativeElement.offsetHeight + 50}px`);
                    }
                    if (this.stickyHeadResult) {
                        this.stickyHeadResult.nativeElement.style.top = `${+this.stickyHead.nativeElement.offsetHeight + 50}px`;
                    }
                }, 100);
            }
        }
    }

    removeFixedTableHeader(): void {
        if (this.storedHeaderNode) {
            for (let i = 0; i < this.storedHeaderNode.children.length; i++) {
                this.storedHeaderNode.removeChild(this.storedHeaderNode.children[i]);
            }
            this.storedHeaderNode.remove();
            const stickyHeadBacker = document.getElementsByClassName('stickyHeadBacker');
            for (let i = 0; i < stickyHeadBacker.length; i++) {
                stickyHeadBacker[i].remove();
            }
        }

        this.storedHeaderNode = null;
        this.messages = null;
    }

    getMessageWithoutComponentRefresh(): void {
        this.messageService.getMessages(this.currSearchObject).subscribe((response) => {
            if (this.total !== +response.headers.get('X-List-Count')) {
                if (this.currSearchObject.order === 'EmailId' && this.currSearchObject.orderDirection === SortDirection.Asc) {
                    const lastIndex = this.messages.length - 1;
                    const messagesToAppend = response.body.slice(lastIndex + 1);
                    this.messages = [...messagesToAppend];
                    this.total = +response.headers.get('X-List-Count');
                } else {
                    const lastIndex = this.messages.length - 1;
                    this.total = +response.headers.get('X-List-Count');
                    const distanceToOldMessages = this.total - 1 - lastIndex;
                    const messagesToAppend = response.body.slice(0, distanceToOldMessages);
                    this.messages = [...messagesToAppend, ...this.messages];
                }
            }
        });
    }

    commaSeparateList(list: any[]): string {
        return list.length > 0 ? list.join(',') : null;
    }

    search(query: string): void {
        this.currSearchObject.query = query;
    }

    wasStatusFilterChanged(event: any): void {
        this.currSearchObject.messageSearchStatuses = event ? event.selectedItems.map((x) => x.Id) : [];
    }

    wasTaxFilterChanged(event: any): void {
        this.currSearchObject.messageSearchTaxStatuses = event ? event.selectedItems.map((x) => x.Id) : [];
    }

    wasLockFilterChanged(event: any): void {
        this.currSearchObject.lockStatuses = event ? event.selectedItems.map((x) => x.Id) : [];
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.currSearchObject.order = event.column.sort.sortProperty;
        this.currSearchObject.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.searchMessages();
    }

    returnSearchObject(): IMessageSearchObject {
        return {
            assigneeIds: this.currSearchObject.assigneeIds,
            assignerIds: this.currSearchObject.assignerIds,
            automationStatusIds: this.currSearchObject.automationStatusIds,
            body: this.currSearchObject.body,
            contextualDateId: this.currSearchObject.contextualDateId,
            endReceivedDate: this.endReceivedDate ? this.pipe.transform(this.endReceivedDate, 'M/d/yyyy h:mm:ss a') : '',
            from: this.currSearchObject.from,
            isOrgSearch: this.currSearchObject.isOrgSearch,
            lockStatuses: this.currSearchObject.lockStatuses,
            messageIds: this.currSearchObject.messageIds,
            messageSearchStatuses: this.currSearchObject.messageSearchStatuses,
            messageSearchTaxStatuses: this.currSearchObject.messageSearchTaxStatuses,
            name: this.selectedSearch.Name,
            order: this.currSearchObject.order,
            orderDirection: this.currSearchObject.orderDirection,
            query: this.currSearchObject.query && this.currSearchObject.query.length > 0 ? this.currSearchObject.query : '',
            savedSearchId: 0,
            skip: (this.currentPage - 1) * this.selectedElementPerPage,
            startReceivedDate: this.startReceivedDate ? this.pipe.transform(this.startReceivedDate, 'M/d/yyyy h:mm:ss a') : '',
            subject: this.currSearchObject.subject ? this.currSearchObject.subject : '',
            tagIds: this.currSearchObject.tagIds,
            take: this.selectedElementPerPage,
            taskIds: this.currSearchObject.taskIds,
        };
    }

    searchMessages(): void {
        this.currentPage = 1;
        this.getMessages();
        this.subscription.unsubscribe();
        this.subscription = this.loadMessageInterval.subscribe(() => {
            if (!this.modalOpen) {
                this.getMessageWithoutComponentRefresh();
            }
        });
    }

    reloadMessagesWithPriorPageCount(): void {
        const currMessagesOnPage = this.messages ? this.messages.length : 0;
        this.currSearchObject.skip = (1 - 1) * currMessagesOnPage;
        this.currSearchObject.take = currMessagesOnPage;
        this.messageService.getMessages(this.currSearchObject, true).subscribe((response) => {
            this.messages = response.body;
            this.total = +response.headers.get('X-List-Count');
            this.createFixedTableHeader();
            this.currSearchObject.take = this.selectedElementPerPage;
        });
    }

    getAllSearches(): void {
        this.messageSearchService.getSavedSearchForUser().subscribe((response) => {
            this.emptySearchObject = this.messageSearchService.getEmptySearchObject();
            this.savedSearches = response.body;
            this.orgSearchCount = this.savedSearches.filter((e) => e.IsOrgSearch === true).length;
            if (!this.hasAccess.CreateOrgSearchFilters) {
                if (this.savedSearches.length - this.orgSearchCount < 5) {
                    this.savedSearches.push(this.emptySearchObject);
                }
            }
            if (this.hasAccess.CreateOrgSearchFilters) {
                if (this.orgSearchCount < 10) {
                    this.savedSearches.push(this.emptySearchObject);
                }
            }

            this.selectedSearch = this.savedSearches.filter((element) => element.Id === this.savedSearchId)[0];
            if (!this.selectedSearch) {
                this.selectedSearch = this.savedSearches[this.savedSearches.length - 1];
            }
        });
    }

    canSaveSearch(isOrgSearch: boolean): boolean {
        const userSearchCount = this.savedSearches.filter((e) => !e.IsOrgSearch && e.Id !== 0).length;
        const orgSearchCount = this.savedSearches.filter((e) => e.IsOrgSearch && e.Id !== 0).length;
        if (this.selectedSearch.Id !== 0) {
            return true;
        } else if (isOrgSearch) {
            if (orgSearchCount >= 10) {
                this.notificationsService.error('Can only save 10 Organizational Searches');
            }
            return orgSearchCount < 10;
        } else {
            if (userSearchCount >= 5) {
                this.notificationsService.error('Can only save 5 User Searches');
            }
            return userSearchCount < 5;
        }
    }

    saveSearch(): void {
        this.saveSearchModalService.showModal(this.selectedSearch, this.savedSearches, this.hasAccess.CreateOrgSearchFilters);
    }

    confirmSaveSearch(isOrgSearch: boolean): void {
        // If regular user then create a new save search
        if (this.currUser?.AuthUser.RoleTypeId === RoleTypeEnum.User) {
            this.selectedSearch.Id = 0;
        }
        this.messageSearchService
            .saveSearch(new MessageSearchObject(this.returnSearchObject()), this.selectedSearch ? this.selectedSearch.Id : 0)
            .subscribe((response) => {
                this.notificationsService.success('Search Saved');
                this.savedSearchId = response;
                this.getAllSearches();
            });
    }

    saveSearchValidator(value: string): string {
        return value ? null : 'Search Name is required';
    }

    deleteSearch(): void {
        if (!this.selectedSearch) {
            this.notificationsService.error('Select the search');
            return;
        }
        this.messageSearchService.deleteSearch(this.selectedSearch.Id).subscribe(() => {
            this.getAllSearches();
            this.notificationsService.success('Successfully deleted search');
            this.removeFixedTableHeader();
            this.getMessages();
        });
    }

    clearSearch(): void {
        this.selectedSearch = null;
        this.messageService.setLatestSavedSearchId(0);
        this.resetFilters();
    }

    filterDownMessageBasedOnSelectedSearch(): void {
        this.messageService.setLatestSavedSearchId(this.selectedSearch.Id);
        if (this.selectedSearch.Id) {
            this.isSelectionOrgSearch = this.selectedSearch.UserId ? false : true;
            this.populateFiltersBasedOnSelectedSearch({
                assigneeIds: this.selectedSearch.searchObject.assigneeIds,
                assignerIds: this.selectedSearch.searchObject.assignerIds,
                automationStatusIds: this.selectedSearch.searchObject.automationStatusIds,
                body: this.selectedSearch.searchObject.body,
                contextualDateId: this.selectedSearch.searchObject.contextualDateId,
                endReceivedDate: this.selectedSearch.searchObject.endReceivedDate ? this.selectedSearch.searchObject.endReceivedDate : '',
                from: this.selectedSearch.searchObject.from,
                isOrgSearch: this.selectedSearch.searchObject.tenantId ? true : false,
                lockStatuses: this.selectedSearch.searchObject.lockStatuses,
                messageIds: this.selectedSearch.searchObject.messageIds,
                messageSearchStatuses: this.selectedSearch.searchObject.messageSearchStatuses,
                messageSearchTaxStatuses: this.selectedSearch.searchObject.messageSearchTaxStatuses,
                name: this.selectedSearch.searchObject.name,
                order: this.selectedSearch.searchObject.order,
                orderDirection: this.selectedSearch.searchObject.orderDirection,
                query: this.selectedSearch.searchObject.query,
                savedSearchId: this.selectedSearch.Id,
                skip: (this.currentPage - 1) * this.selectedElementPerPage,
                startReceivedDate: this.selectedSearch.searchObject.startReceivedDate ? this.selectedSearch.searchObject.startReceivedDate : '',
                subject: this.selectedSearch.searchObject.subject,
                tagIds: this.selectedSearch.searchObject.tagIds,
                take: this.selectedElementPerPage,
                taskIds: this.selectedSearch.searchObject.taskIds,
            });
            this.currentPage = 1;
            this.getMessages();
        } else {
            this.resetFilters();
            this.isSelectionOrgSearch = undefined;
        }
    }

    populateFiltersBasedOnSelectedSearch(obj: IMessageSearchObject, pageOne = false): void {
        this.selectedElementPerPage = obj.take;
        this.currentPage = pageOne ? 1 : obj.skip / obj.take + 1;
        this.currSearchObject.order = obj.order;
        this.currSearchObject.orderDirection = obj.orderDirection;
        this.query = obj.query;
        this.currSearchObject.messageIds = obj.messageIds;
        this.currSearchObject.savedSearchId = obj.savedSearchId;
        this.currSearchObject.isOrgSearch = obj.isOrgSearch;
        this.currSearchObject.contextualDateId = obj.contextualDateId;
        this.currSearchObject.taskIds = obj.taskIds;
        if (obj.contextualDateId) {
            this.currSearchObject.contextualDateId = obj.contextualDateId;
            const range = getDateRangeByContextualFilter(obj.contextualDateId, DaysOfTheWeek.Sunday, this.startReceivedDate, this.endReceivedDate);
            this.startReceivedDate = range.startDate;
            this.endReceivedDate = range.endDate;
            this.currSearchObject.startReceivedDate = this.pipe.transform(this.startReceivedDate, 'M/d/yyyy h:mm:ss a');
            this.currSearchObject.endReceivedDate = this.pipe.transform(this.endReceivedDate, 'M/d/yyyy h:mm:ss a');
        } else {
            if (obj.startReceivedDate) {
                this.startReceivedDate = new Date(obj.startReceivedDate);
                this.currSearchObject.startReceivedDate = obj.startReceivedDate;
            } else {
                this.startReceivedDate = null;
                this.currSearchObject.startReceivedDate = '';
            }
            if (obj.endReceivedDate) {
                this.endReceivedDate = new Date(obj.endReceivedDate);
                this.currSearchObject.endReceivedDate = obj.endReceivedDate;
            } else {
                this.endReceivedDate = null;
                this.currSearchObject.endReceivedDate = '';
            }
        }

        if (obj.from) {
            this.currSearchObject.from = obj.from;
        } else {
            this.currSearchObject.from = null;
        }
        if (obj.subject) {
            this.currSearchObject.subject = obj.subject;
        } else {
            this.currSearchObject.subject = null;
        }
        if (obj.body) {
            this.currSearchObject.body = obj.body;
        } else {
            this.currSearchObject.body = null;
        }
        if (obj.automationStatusIds) {
            if (obj.automationStatusIds.length) {
                this.currSearchObject.automationStatusIds = obj.automationStatusIds;
                this.automationStatus.forEach((status) =>
                    obj.automationStatusIds.some((x) => x === status.Item.Id) ? (status.Selected = true) : (status.Selected = false),
                );
            } else {
                this.currSearchObject.automationStatusIds = null;
                this.automationStatus.forEach((status) => (status.Selected = false));
            }
        } else {
            this.currSearchObject.automationStatusIds = null;
            this.automationStatus.forEach((status) => (status.Selected = false));
        }
        if (obj.assigneeIds) {
            this.currSearchObject.assigneeIds = this.users.filter((u) => obj.assigneeIds.some((x) => u.AuthUserId === x)).map((x) => x.AuthUserId);
            this.assigneeSearch.forEach((assignee) =>
                obj.assigneeIds.some((x) => x === assignee.Item.Id) ? (assignee.Selected = true) : (assignee.Selected = false),
            );
            this.assigneeSearchModel = [];
        } else {
            this.currSearchObject.assigneeIds = [];
        }

        if (obj.assignerIds) {
            this.currSearchObject.assignerIds = this.users.filter((u) => obj.assignerIds.some((x) => u.AuthUserId === x)).map((x) => x.AuthUserId);
            this.assignerSearch.forEach((assigner) =>
                obj.assignerIds.some((x) => x === assigner.Item.Id) ? (assigner.Selected = true) : (assigner.Selected = false),
            );
            this.assignerSearchModel = [];
        } else {
            this.currSearchObject.assignerIds = [];
        }

        if (obj.messageSearchStatuses && obj.messageSearchStatuses.length) {
            this.currSearchObject.messageSearchStatuses = obj.messageSearchStatuses;
            this.statusSearchOptions.forEach((status) =>
                obj.messageSearchStatuses.some((x) => x === status.Item.Id) ? (status.Selected = true) : (status.Selected = false),
            );
        } else {
            this.currSearchObject.messageSearchStatuses = null;
            this.statusSearchOptions.forEach((status) => (status.Selected = false));
        }

        if (obj.messageSearchTaxStatuses && obj.messageSearchTaxStatuses.length) {
            this.currSearchObject.messageSearchTaxStatuses = obj.messageSearchTaxStatuses;
            this.taxSearchOptions.forEach((status) =>
                obj.messageSearchTaxStatuses.some((x) => x === status.Item.Id) ? (status.Selected = true) : (status.Selected = false),
            );
        } else {
            this.currSearchObject.messageSearchTaxStatuses = null;
            this.taxSearchOptions.forEach((status) => (status.Selected = false));
        }

        if (obj.lockStatuses && obj.lockStatuses.length) {
            this.currSearchObject.lockStatuses = obj.lockStatuses;
            this.lockSearchOptions.forEach((status) =>
                obj.lockStatuses.some((x) => x === status.Item.Id) ? (status.Selected = true) : (status.Selected = false),
            );
        } else {
            this.currSearchObject.lockStatuses = null;
            this.lockSearchOptions.forEach((status) => (status.Selected = false));
        }

        if (obj.tagIds && obj.tagIds.length) {
            this.currSearchObject.tagIds = obj.tagIds;
            this.searchFilterTags.forEach((tag) => (obj.tagIds.some((x) => x === tag.Item.Id) ? (tag.Selected = true) : (tag.Selected = false)));
        } else {
            this.currSearchObject.tagIds = null;
            this.searchFilterTags.forEach((tag) => (tag.Selected = false));
        }
    }

    resetFilters(): void {
        this.populateFiltersBasedOnSelectedSearch(this.messageService.getDefaultMessageSearch(this));
    }

    setSearchContextualDateType(contextualDateTypeId: number): void {
        this.currSearchObject.contextualDateId = contextualDateTypeId;
    }

    saveUserColumns(): void {
        this.entityListConfig = new MessagesEntityListConfig(this.messageService, this.pipe, this.userMessageColumns);
        this.removeFixedTableHeader();
        this.getMessages();
        this.userService.saveUserMessageColumns(this.userMessageColumns).subscribe((response) => {
            this.notificationsService.success('Column Saved Successfully');
        });
    }

    configureColumns(): void {
        this.configureColumnsPopUpApi.show();
    }

    drop(event: CdkDragDrop<string[]>): any {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    onSelectionChanged(event: ISelectionChangedEvent): void {
        if (event.selectedEntities.length > 0) {
            this.selectedMessages = event.selectedEntities.map((item) => item as IDboMessageDTO);
        } else {
            this.selectedMessages = [];
        }
    }

    emailSubjectSearch(event: any): void {
        this.currSearchObject.subject = event;
    }

    emailBodySearch(event: any): void {
        this.currSearchObject.body = event;
    }

    emailFromSearch(event: any): void {
        this.currSearchObject.from = event;
    }

    showAssignUserDialog(): void {
        if (this.selectedMessages && this.selectedMessages.length) {
            this.modalOpen = true;
            this.userModalService.showModal(this.selectedMessages, this.users);
        } else {
            this.notificationsService.error('No messages selected');
        }
    }

    closeSwal(): void {
        this.modalOpen = false;
    }

    assignUsersToMessages(): void {
        const messageIds = this.selectedMessages.map((m) => m.EmailId);
        this.selectedMessages.forEach((m) => {
            m.AssignedToId = this.selectedUser.Id;
            m.AssignedById = this.currUser.Id;
            m.Assignee = this.selectedUser;
            m.Assigner = this.selectedUser.Id > 0 ? this.currUser : this.selectedUser;
        });
        this.messageService
            .assignUserToMessages({ MessageIds: messageIds, AssignedToId: this.selectedUser.Id, AssignedById: this.currUser.Id })
            .subscribe(
                () => {
                    this.selectedMessages = [];
                    const newAuditEntryList: ITaskMsgAuditLog[] = [];
                    messageIds.forEach((id) => {
                        const newAuditEntry = this.auditService.getEmptyTaskMsgAuditLog();
                        newAuditEntry.UserId = this.currUser.Id;
                        newAuditEntry.MessageId = id;
                        newAuditEntry.LogTypeId = LogTypeEnum.MailingAssigned;
                        newAuditEntry.Timestamp = new Date();
                        newAuditEntry.Details = `${this.currUser.FirstName} ${this.currUser.LastName} assigned ${id} to ${this.selectedUser.FirstName} ${this.selectedUser.LastName}`;
                        newAuditEntryList.push(newAuditEntry);
                    });
                    this.auditService.updateList(newAuditEntryList).subscribe(() => {
                        this.selectedUser = null;
                    });
                    this.notificationsService.success('User successfully assigned to Messages');
                },
                (err) => {
                    this.selectedMessages.forEach((m) => {
                        m.AssignedToId = null;
                        m.AssignedById = null;
                        m.Assignee = null;
                        m.Assigner = null;
                    });
                    this.selectedMessages = [];
                },
            );
    }

    markMessagesNoAction(markNoAction: boolean): void {
        const selectedMessageIds = this.selectedMessages.map((m) => m.EmailId);
        if (markNoAction) {
            this.messageService.setMessageState(selectedMessageIds, AttachmentState.Ignored, this.currUser.Id).subscribe(() => {
                this.getMessages();
            });
        } else {
            this.messageService.setMessageState(selectedMessageIds, AttachmentState.NotYetDispatched, this.currUser.Id).subscribe(() => {
                this.getMessages();
            });
        }
    }

    moveToProcessSelectedMessages(): void {
        if (!this.selectedMessages.length) {
            this.notificationsService.error('No Messages currently selected to process');
        } else if (!this.doSelectedMessagesHaveAttachments()) {
            this.notificationsService.error('No selected messages have attachments');
        } else {
            if (!this.attachmentProcessingService.getIsProcessingTabOpen) {
                const selectedMessageIds = this.selectedMessages.map((x) => x.EmailId);
                this.messageService.processingLock(selectedMessageIds, true, this.currUser.Id).subscribe((alreadyLockedIds) => {
                    if (alreadyLockedIds.length === selectedMessageIds.length) {
                        this.notificationsService.warning(
                            `Messages ${alreadyLockedIds.join(', ')} not added because they are being processed by another user.`,
                        );
                    } else {
                        this.checkForLockedMessagesAndMoveToProcess(alreadyLockedIds, selectedMessageIds);
                        void this.router.navigate([]).then(() => {
                            window.open(location.origin + '#' + '/processAttachments', '_blank');
                        });
                        this.reloadMessagesWithPriorPageCount();
                    }
                });
            } else {
                const selectedMessageIds = this.selectedMessages.map((x) => x.EmailId);
                this.messageService.processingLock(selectedMessageIds, true, this.currUser.Id).subscribe((alreadyLockedIds) => {
                    if (alreadyLockedIds.length === selectedMessageIds.length) {
                        this.notificationsService.warning(
                            `Messages ${alreadyLockedIds.join(', ')} not added because they are being processed by another user.`,
                        );
                    } else {
                        this.checkForLockedMessagesAndMoveToProcess(alreadyLockedIds, selectedMessageIds);
                        this.reloadMessagesWithPriorPageCount();
                    }
                });
            }
        }
    }

    checkForLockedMessagesAndMoveToProcess(alreadyLockedIds: number[], selectedMessageIds: number[]): void {
        const filteredMessageIds = selectedMessageIds.filter((x) => !alreadyLockedIds.some((y) => y === x));
        if (alreadyLockedIds.length) {
            this.notificationsService.warning(`Messages ${alreadyLockedIds.join(', ')} not added because they are being processed by another user.`);
        }
        if (filteredMessageIds.length) {
            this.attachmentProcessingService.addMessages(filteredMessageIds);
        }
        this.selectedMessages = [];
    }

    addAttachment(): void {
        this.modalOpen = true;
        this.addAttachmentModalService.showModal(
            null,
            null,
            this.selectedMessages.map((m) => m.EmailId),
        );
        this.selectedMessages = [];
    }

    doSelectedMessagesHaveAttachments(): boolean {
        let totalAttachments = 0;
        this.selectedMessages.forEach((e) => (totalAttachments += e.AttachmentCount));
        return totalAttachments !== 0;
    }

    toggleSearchCollapse(): void {
        this.isSearchCollapsed = !this.isSearchCollapsed;
        this.messageService.setIsSearchCollapsed();
        setTimeout(() => {
            this.createFixedTableHeader();
        }, 0);
    }

    selectTags(event: any): void {
        this.modalOpen = true;
        this.tagModalService.showModal(this.selectedMessages, this.tagsForAssignment);
    }

    tagMessages(): void {
        const anyTagsRequireManualNote = this.selectedTags.some((a) => a.RequiresNote);
        const messageIds = this.selectedMessages.map((m) => m.EmailId);
        const tagIds = this.selectedTags.map((t) => t.Id);
        if (anyTagsRequireManualNote) {
            this.showManualNoteDialog(messageIds, tagIds);
        } else {
            const messageTagParams: IMessageTagParams = {
                manualNote: null,
                messageIds: messageIds,
                tagIds: tagIds,
                userId: this.currUser.Id,
            };
            this.tagService.tagMessage(messageTagParams).subscribe(() => {
                this.modalOpen = false;
                this.manualNote = '';
                this.selectedNote = null;
                this.deselectTags();
                this.getMessageWithoutComponentRefresh();
                this.selectedMessages = [];
            });
        }
    }

    showManualNoteDialog(messageIds: number[], tagIds: number[]): void {
        this.modalOpen = true;
        this.manualNoteMessageIds = messageIds;
        this.manualNoteTagIds = tagIds;
        this.manualNoteModalApi.show();
    }

    manualNoteDialogConfirm(): void {
        let manualNote = null;
        if (this.manualNote.length) {
            manualNote = this.manualNote;
        }
        const messageTagParams: IMessageTagParams = {
            manualNote: manualNote,
            messageIds: this.manualNoteMessageIds,
            tagIds: this.manualNoteTagIds,
            userId: this.currUser.Id,
        };
        this.manualNoteModalApi.close();
        this.tagService.tagMessage(messageTagParams).subscribe(() => {
            this.modalOpen = false;
            this.manualNote = '';
            this.selectedNote = null;
            this.deselectTags();
            this.getMessageWithoutComponentRefresh();
            this.selectedMessages = [];
        });
    }

    manualNoteDialogCancel(): void {
        this.manualNote = '';
        this.selectedNote = null;
        this.deselectTags();
        this.manualNoteModalApi.close();
    }

    selectedTagsChanged(event: any): void {
        this.currSearchObject.tagIds = event.selectedItems.map((t) => t.Id);
    }

    selectedStatusChanged(event: any): void {
        this.currSearchObject.automationStatusIds = event.selectedItems.map((a) => a.Id);
    }

    selectedAssigneeChanged(event: any): void {
        this.currSearchObject.assigneeIds = event.map((a) => a.Item.Id);
    }

    selectedAssignerChanged(event: any): void {
        this.currSearchObject.assignerIds = event.map((a) => a.Item.Id);
    }

    deselectTags(): void {
        this.tagsForAssignment.forEach((t) => (t.Selected = false));
    }

    selectedNoteChanged(event: any): void {
        if (event) {
            this.manualNote = event.Note;
        } else {
            this.manualNote = '';
        }
        this.selectedNote = event;
    }

    assignHotkey(): KeyboardShortcutHandler {
        return (event: KeyboardEvent) => {
            event.preventDefault();
            this.showAssignUserDialog();
        };
    }

    processHotkey(): KeyboardShortcutHandler {
        return (event: KeyboardEvent) => {
            event.preventDefault();
            if (this.selectedMessages) {
                this.moveToProcessSelectedMessages();
            } else {
                this.notificationsService.error('No Messages selected to process');
            }
        };
    }

    tagHotkey(): KeyboardShortcutHandler {
        return (event: KeyboardEvent) => {
            event.preventDefault();
            if (this.selectedMessages && this.selectedMessages.length) {
                this.selectTags(null);
            } else {
                this.notificationsService.error('No Tags Selected');
            }
        };
    }

    getStickyHeadMarginTop(): number {
        return document.getElementById('headerForHeight').clientHeight - document.getElementById('resultsForHeight').clientHeight;
    }

    unlockSelectMessages(): void {
        const selectedMessages: IDboMessageDTO[] = this.selectedMessages;

        selectedMessages.forEach((selectedMessage) => {
            if (selectedMessage.IsProcessingLocked) {
                this.messageService.processingLock([selectedMessage.EmailId], false, 0).subscribe(() => {
                    this.messageService.tagOnMessageSubject.next(selectedMessage);
                    this.notificationsService.success('Unlocked Message');
                });
            } else {
                this.notificationsService.warning(`Email ID: ${selectedMessage.EmailId} is not locked`);
            }
        });
    }

    addNoteSelectMessage(): void {
        const entities: INoteEntity[] = [];

        this.selectedMessages.forEach((message) => {
            entities.push({ Id: message.EmailId, ...message });
        });

        this.notesModalService.showModal(null, this.messageService, false, entities);
    }

    onUntaggedChanged(untaggedMessagesCheckbox: boolean): void {
        if (untaggedMessagesCheckbox) {
            this.currSearchObject.tagIds = [];
            this.searchFilterTags = this.searchFilterTags.map((s) => {
                s.Selected = false;
                return s;
            });
        }
        this.untaggedMessagesCheckbox = untaggedMessagesCheckbox;
    }

    openGetEmailModal(): void {
        this.assignEmailModalApi.show();
    }

    selectedTagsForAssignEmails(event: any): void {
        this.selectedTagIdsForAssignEmails = event.selectedItems.map((t) => t.Id);
    }

    selectSources(event: any): void {
        this.currentSelection = event.selectedItems.find((item) => item.Id !== this.currentSelection?.Id);
        this.messageService.numberOfEmailsAssignOptions.map((source) => {
            if (source.Item.Id !== this.currentSelection?.Id) {
                source.Selected = false;
            }
        });
    }

    assignEmails(event): void {
        if(this.currentSelection.Id === null || this.currentSelection.Id === undefined) {
            this.notificationsService.warning('Please select the number of emails to be ');
            return;
        }

        const payload: IAssignEmailsDTO = {
            numberOfEmails : this.currentSelection.Id,
            userId: this.currUser.Id,
            tagIds: this.selectedTagIdsForAssignEmails.length === 0 ? null : this.selectedTagIdsForAssignEmails
        };

        this.messageService.assignMessagesToUser(payload).subscribe((totalMessagesAssigned: number) => {
            if(totalMessagesAssigned === 0) {
                this.notificationsService.warning(`No messages matched the search criteria.`);
            }
            if(totalMessagesAssigned < payload.numberOfEmails) {
                this.notificationsService.warning(`Only ${totalMessagesAssigned} emails were found and assigned out of requested ${payload.numberOfEmails}.`);
            }
            if(totalMessagesAssigned === payload.numberOfEmails) {
                this.notificationsService.success(`Total: ${totalMessagesAssigned} Emails assigned successfully`);
            }
        })
    }
}

export interface IMessageRow extends IDboMessageDTO {
    expanded?: boolean;
}
