import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';

import { TaskTypeService } from '../../tasks/task-doc-type-service';
import { DboTaskTypeDynamicConfig } from '../dbo-task-type.dynamic-config';
import { IDboTaskType } from '../../model/interfaces/dbo-task-type';
import { IDboTaskTypeBacking } from '../../model/interfaces/dbo-task-type-backing';
import { DboTaskTypeBackingService } from '../services/dbo-task-type-backing.service';
import { ITaskTypeTier } from '../../model/interfaces/task-type-tier';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-dbo-task-type-basic-info',
    styles: [
        `
            .toolTip {
                position: absolute;
            }
        `,
    ],
    templateUrl: './dbo-task-type-basic-info.component.html',
})
export class DboTaskTypeBasicInfoComponent implements OnInit {
    @Input() dboTaskType: IDboTaskType;
    @Input() canEdit: boolean;

    isEditing = false;
    isHovered: boolean;
    config: IDynamicFormConfig = { formObject: [], viewOnly: [] };
    formFactory: DboTaskTypeDynamicConfig<IDboTaskType>;
    doubleClickIsDisabled = false;
    backings: IDboTaskTypeBacking[] = [];
    tiers: ITaskTypeTier[] = [];

    get isNewDboTaskType(): boolean {
        return this.dboTaskType && this.dboTaskType.Id ? false : true;
    }

    constructor(
        private dboTaskTypeService: TaskTypeService,
        private notificationsService: NotificationsService,
        private router: Router,
        private taskTypeBackingService: DboTaskTypeBackingService,
        private renderer: Renderer2,
    ) {}

    ngOnInit(): void {
        forkJoin(this.taskTypeBackingService.getAll(), this.taskTypeBackingService.getTiers()).subscribe((data) => {
            const [backings, tiers] = data;
            this.backings = backings;
            this.tiers = tiers;
            this.setConfig();
        });
    }

    setConfig(): void {
        this.formFactory = new DboTaskTypeDynamicConfig<IDboTaskType>(this.dboTaskType, this.backings, this.tiers);

        if (this.isNewDboTaskType) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewDboTaskType) {
            void this.router.navigate(['/documentTypes']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.dboTaskType, form.value.DboTaskType as IDboTaskType);
            this.saveDboTaskType();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    private saveDboTaskType(): void {
        if (this.isNewDboTaskType) {
            this.dboTaskTypeService
                .create(this.dboTaskType)                .subscribe((answer) => {
                    this.dboTaskType.Id = answer;
                    this.success(true);
                });
        } else {
            this.dboTaskTypeService
                .update(this.dboTaskType)                .subscribe((success) => {
                    this.success();
                });
        }
    }

    private success(newDboTaskTypeSave?: boolean): void {
        if (newDboTaskTypeSave) {
            void this.router.navigate([`/documentTypes/${this.dboTaskType.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.dboTaskTypeService.emitChange(this.dboTaskType);
        this.notificationsService.success('Document Type saved successfully.');
    }
}
