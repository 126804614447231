import { IDboInvestment } from '../model/interfaces/dbo-investment';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { INoteService } from '../model/interfaces/custom/EntityListConfigWithNotes';
import { IVwClient } from '../model/interfaces/vw-client';
import { IBlobDownload } from '../model/interfaces/custom/blob-download';
import { map } from 'rxjs/operators';
import { CommonService } from '../common/services/common.service';
import { IUploadDealReport } from '../model/interfaces/custom/upload-deal-report';

@Injectable({
    providedIn: 'root',
})
export class InvestmentService extends BaseService<IDboInvestment> implements INoteService {
    baseUrl: string;
    constructor(public http: HttpClient, public commonService: CommonService) {
        super('/investments', http, null, { entityName: 'Investment' });
        this.baseUrl = '/investments';
    }

    getEmptyInvestment(): IDboInvestment {
        return {
            Id: 0,
            IsArchived: false,
            Name: '',
        };
    }

    updateNote(id: number, note: string): Observable<void> {
        return this.http.put<void>(`/investments/note/${id}`, { Note: note });
    }

    toggleArchive(tagId: number, archive: boolean): any {
        return this.http.put(`/investments/archive/${tagId}`, archive, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
    }

    addDeals(clients: IVwClient[], investmentId: number): Observable<void> {
        return this.http.post<void>(`/investments/addDeals/${investmentId}`, clients, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }

    uploadExcel(file: File): Observable<IUploadDealReport> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<IUploadDealReport>(`/investments/uploadExcel`, formData);
    }
}
