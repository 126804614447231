import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ISelectionChangedEvent } from '@mt-ng2/multiselect-control';
import { ISelectedItemsEvent, MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

@Component({
    selector: 'mt-tag-search-filter-select',
    template: `
        <tag-multiselect
            [items]="items"
            [filterMode]="true"
            [entity]="entity"
            [showSelectAllButtons]="showSelectAllButtons"
            [maxToShowInSelectedText]="maxToShowInSelectedText"
            [disabled]="disabled"
            [autoClose]="autoClose"
            (onSelectionChanged)="multiSelectSelectionChanged($event)"
            (blur)="handleBlur($event)"
            (focus)="focus.emit($event)"
        >
        </tag-multiselect>
    `,
})
export class MtTagFilterSelectComponent {
    @Input() items: MtSearchFilterItem[];
    @Input() entity: string;
    @Input() showSelectAllButtons = true;
    @Input() maxToShowInSelectedText: number;
    @Input() disabled = false;
    @Input() autoClose: boolean | 'outside' | 'inside';

    @Output('onSelectionChanged') selectionChanged: EventEmitter<ISelectedItemsEvent> = new EventEmitter<ISelectedItemsEvent>();
    @Output('onSelectListClosed') selectListClosed: EventEmitter<ISelectedItemsEvent> = new EventEmitter<ISelectedItemsEvent>();
    @Output() blur: EventEmitter<any> = new EventEmitter<any>();
    @Output() focus: EventEmitter<any> = new EventEmitter<any>();

    private selectedItems: any[];

    constructor() {}

    multiSelectSelectionChanged(event: ISelectionChangedEvent): void {
        this.selectedItems = event.selectedItems;
        const eventToEmit: ISelectedItemsEvent = {
            selectedItems: this.selectedItems,
        };
        this.selectionChanged.emit(eventToEmit);
    }

    handleBlur(event: Event): void {
        this.blur.emit(event);
        const eventToEmit: ISelectedItemsEvent = {
            selectedItems: this.selectedItems,
        };
        this.selectListClosed.emit(eventToEmit);
    }
}
