<input
    class="form-control"
    colorPicker
    [cpWidth]="cpWidth"
    [cpHeight]="cpHeight"
    [cpToggle]="cpToggle"
    [cpDisabled]="cpDisabled"
    [cpColorMode]="cpColorMode"
    [cpCmykEnabled]="cpCmykEnabled"
    [cpOutputFormat]="cpOutputFormat"
    [cpAlphaChannel]="cpAlphaChannel"
    [cpFallbackColor]="cpFallbackColor"
    [cpPosition]="cpPosition"
    [cpPositionOffset]="cpPositionOffset"
    [cpPositionRelativeToArrow]="cpPositionRelativeToArrow"
    [cpPresetLabel]="cpPresetLabel"
    [cpPresetColors]="cpPresetColors"
    [cpDisableInput]="cpDisableInput"
    [cpDialogDisplay]="cpDialogDisplay"
    [cpIgnoredElements]="cpIgnoredElements"
    [cpSaveClickOutside]="cpSaveClickOutside"
    [cpCloseClickOutside]="cpCloseClickOutside"
    [cpOKButton]="cpOKButton"
    [cpOKButtonText]="cpOKButtonText"
    [cpOKButtonClass]="cpOKButtonClass"
    [cpCancelButton]="cpCancelButton"
    [cpCancelButtonText]="cpCancelButtonText"
    [cpCancelButtonClass]="cpCancelButtonClass"
    [cpAddColorButton]="cpAddColorButton"
    [cpAddColorButtonText]="cpAddColorButtonText"
    [cpAddColorButtonClass]="cpAddColorButtonClass"
    [cpRemoveColorButtonClass]="cpRemoveColorButtonClass"
    [cpMaxPresetColorsLength]="cpMaxPresetColorsLength"
    [cpPresetEmptyMessage]="cpPresetEmptyMessage"
    [cpPresetEmptyMessageClass]="cpPresetEmptyMessageClass"
    [cpUseRootViewContainer]="cpUseRootViewContainer"
    (colorPickerOpen)="colorPickerOpen.emit($event)"
    (colorPickerClose)="colorPickerClose.emit($event)"
    (colorPickerChange)="colorPickerChange.emit($event)"
    (colorPickerCancel)="colorPickerCancel.emit($event)"
    (colorPickerSelect)="colorPickerSelect.emit($event)"
    (cpToggleChange)="cpToggleChange.emit($event)"
    (cpInputChange)="cpInputChange.emit($event)"
    (cpSliderChange)="cpSliderChange.emit($event)"
    (cpSliderDragEnd)="cpSliderDragEnd.emit($event)"
    (cpSliderDragStart)="cpSliderDragStart.emit($event)"
    (cpCmykColorChange)="cpCmykColorChange.emit($event)"
    (cpPresetColorsChange)="cpPresetColorsChange.emit($event)"
    [style.background]="colorPicker"
    type="text"
    readonly
/>
