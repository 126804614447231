import { Component, OnInit, Injector } from '@angular/core';
import { NavHeaderComponent, NavService } from '@mt-ng2/nav-module';
import { ClaimValues, ClaimsService, AuthService } from '@mt-ng2/auth-module';
import { ClaimTypes } from './../../../model/ClaimTypes';

@Component({
    selector: 'app-custom-nav-header',
    styles: [
        `
            .main-header {
                position: fixed;
                top: 0;
                width: 100vw;
            }
            .navbar-custom-menu {
                margin-right: 20px;
            }
            .custom-header-area {
                width: 345px;
                padding-top: 15px;
                color: #fff;
            }
            .mobile-app-name {
                width: auto;
                float: unset;
                display: inherit;
                padding: 0;
            }
            .main-header > .navbar {
                margin-left: 500px;
            }
            .main-header > .navbar.mobile-navbar {
                margin-left: 0;
            }
            .main-header > .navbar.mobile-navbar > a {
                text-decoration-line: none;
            }
            .main-header .logo {
                width: 500px;
            }
        `,
    ],
    styleUrls: [`./customer-nav-header.less`],
    templateUrl: './customer-nav-header.component.html',
})
export class CustomNavHeaderComponent extends NavHeaderComponent implements OnInit {
    constructor(private navS: NavService, private claimsService: ClaimsService, private authService: AuthService, injector: Injector) {
        super(injector);
    }
    ngOnInit(): void {
        this.logoUrl = this.navS.logoUrl;
        this.fullLogoUrl = this.navS.fullLogoUrl;
        this.appName = this.navS.appName;

        this.showNav = this.navS.showNav.getValue();
        this.subscriptions.add(
            this.navS.showNav.subscribe((showNav: boolean) => {
                this.showNav = showNav;
            }),
        );

        this.customHeaderHtml = this.navS.customHeaderHtml.getValue();
        this.subscriptions.add(
            this.navS.customHeaderHtml.subscribe((headerHtml: string) => {
                this.customHeaderHtml = headerHtml;
            }),
        );

        this.subscriptions.add(
            this.authService.appReady.subscribe((ready) => {
                if (ready) {
                    this.navS.showSubmitHelpButton = this.claimsService.hasClaim(ClaimTypes.SubmitHelp, [ClaimValues.FullAccess]);
                }
            }),
        );
    }
}
