import { IEntityListDynamicCellComponent, IEntityListComponentMembers, IEntity, IItemDeletedEvent } from '@mt-ng2/entity-list-module';
import { OnInit, OnDestroy, Component } from '@angular/core';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { MessageTagService } from '../message-tags/services/message-tag.service';
import { IDboMessageManagedNote } from '../model/interfaces/dbo-message-managed-note';
import { MessageManagedNotesService } from './message-managed-notes.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-addDelete-note-cell',
    styles: [
        `
            .container {
                width: 100%;
                height: 100%;
                z-index: 999;
                display: flex;
            }
        `,
    ],
    template: `
        <div *ngIf="item.Id > 0" class="container" (click)="$event.stopPropagation()">
            <div class="text-center">
                <i
                    *ngIf="!isEditing && !item.IsArchived"
                    class="fa fa-lg fa-trash"
                    style="padding: 10px 5px;"
                    aria-hidden="true"
                    (click)="addDeleteNote($event)"
                ></i>
                <i
                    *ngIf="!isEditing && item.IsArchived"
                    class="fa fa-lg fa-undo"
                    style="padding: 10px 5px;"
                    aria-hidden="true"
                    (click)="addDeleteNote($event)"
                ></i>
                <i *ngIf="isEditing" class="fa fa-lg fa-check" aria-hidden="true" style="padding: 10px 5px;" (click)="saveNote($event)"></i>
                <i *ngIf="isEditing" class="fa fa-lg fa-undo" aria-hidden="true" style="padding: 10px 5px;" (click)="stopAdding()"></i>
            </div>
        </div>
        <div *ngIf="item.Id <= 0" class="container" (click)="$event.stopPropagation()">
            <div class="text-center">
                <i class="fa fa-lg fa-plus" aria-hidden="true" style="padding: 10px 5px;" (click)="addDeleteNote($event)"></i>
                <i class="fa fa-lg fa-undo" aria-hidden="true" style="padding: 10px 5px;" (click)="stopAdding($event)"></i>
            </div>
        </div>
    `,
})
export class AddDeleteNoteComponent implements IEntityListDynamicCellComponent, OnInit, OnDestroy {
    entityListComponentMembers: IEntityListComponentMembers;

    set entity(value: IEntity) {
        this.item = value as IDboMessageManagedNote;
    }
    item: IDboMessageManagedNote;
    isEditing = false;
    subscription = new Subscription();
    isSingleNoteBeingEdited = false;

    constructor(private noteService: MessageManagedNotesService, private notificationService: NotificationsService) {}
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
    ngOnInit(): void {
        this.subscription.add(
            this.noteService.isEditingObservable.subscribe((id) => {
                if (id === this.item.Id) {
                    this.isEditing = !this.isEditing;
                } else {
                    this.isEditing = false;
                }
            }),
        );
    }

    addDeleteNote(event: IItemDeletedEvent): void {
        if (this.item.Id > 0) {
            if (this.item.IsArchived === null) {
                this.item.IsArchived = true;
            } else {
                this.item.IsArchived = !this.item.IsArchived;
            }
            this.noteService.update(this.item).subscribe(
                () => {
                    this.notificationService.success(this.item.IsArchived ? 'Archived Successfully' : 'Unarchived Successfully');
                    this.noteService.noteAddedOrDeleted();
                },
                (err) => this.notificationService.error('Note in use by an existing tag. Could not delete'),
            );
        } else {
            this.noteService.create(this.item).subscribe(() => {
                this.notificationService.success('Note Created');
                this.noteService.noteAddedOrDeleted();
            });
        }
    }

    stopAdding(event: any): void {
        this.noteService.noteAddedOrDeleted();
    }

    saveNote(event: any): void {
        event.stopPropagation();
        if (this.item.Id !== 0) {
            this.noteService.update(this.item).subscribe(() => {
                this.notificationService.success('Note Updated');
                this.noteService.isEditingUpdate(this.item.Id);
                this.noteService.noteAddedOrDeleted();
            });
        }
    }
}
