<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>Attachment Tags</h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <br />
</div>

<div class="row">
    <div class="col-md-12">
        <div class="pull-right">
            <button class="btn btn-transparent" type="button">
                <input type="checkbox" [(ngModel)]="includeArchive" (ngModelChange)="includeArchivedChanged($event)" />
                <span>Include Archived</span>
            </button>
        </div>
    </div>
</div>

<mt-entity-list
    [entities]="attachmentTags"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getAttachmentTags()"
    (itemSelectedEvent)="attachmentTagSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    (itemDeletedEvent)="toggleArchive($event)"
    [entityListConfig]="entityListConfig"
>
</mt-entity-list>

<div *ngIf="canAddAttachmentTag" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/attachment-tag', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
