/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AttachmentTagsService } from '../attachment-tags.service';
import { ClaimTypes } from '../../model/ClaimTypes';
import { entityListModuleConfig } from '../../common/shared.module';
import { IDboAttachmentTag } from '../../model/interfaces/dbo-attachment-tag';
import { AttachmentTagsEntityListConfig } from './attachment-tag.entity-list-config';

@Component({
  selector: 'attachment-tag-list',
  templateUrl: './attachment-tag-list.component.html',
})
export class AttachmentTagListComponent implements OnInit {
  attachmentTags: IDboAttachmentTag[] = [];
  canAddAttachmentTag = false;
  currentPage = 1;
  query = '';
  total: number;
  itemsPerPage = entityListModuleConfig.itemsPerPage;
  entityListConfig = new AttachmentTagsEntityListConfig();
  order = this.entityListConfig.getDefaultSortProperty();
  orderDirection: string = this.entityListConfig.getDefaultSortDirection();
  includeArchive = false;

  constructor(
    private claimsService: ClaimsService,
    private router: Router,
    private notificationsService: NotificationsService,
    private attachmentTagsService: AttachmentTagsService
  ) { }

  ngOnInit(): void {
    this.canAddAttachmentTag = this.claimsService.hasClaim(ClaimTypes.Tags, [ClaimValues.FullAccess]);
    this.getAttachmentTags();
  }

  search(query: string): void {
    this.currentPage = 1;
    this.query = query;
    this.getAttachmentTags();
  }

  getAttachmentTagsCall(): Observable<HttpResponse<IDboAttachmentTag[]>> {
    const search = this.query;
    const _extraSearchParams: ExtraSearchParams[] = [];

    _extraSearchParams.push(
      new ExtraSearchParams({
        name: 'includeArchived',
        value: String(this.includeArchive),
      }),
    );

    const searchEntity: IEntitySearchParams = {
      extraParams: _extraSearchParams,
      order: this.order,
      orderDirection: this.orderDirection,
      query: search && search.length > 0 ? search : '',
      skip: (this.currentPage - 1) * this.itemsPerPage,
      take: this.itemsPerPage,
    };

    const searchparams = new SearchParams(searchEntity);
    return this.attachmentTagsService.get(searchparams);
  }

  getAttachmentTags(): void {
    this.getAttachmentTagsCall().subscribe((answer) => {
      this.attachmentTags = answer.body;
      this.total = +answer.headers.get('X-List-Count');
    });
  }

  filterSelectionChanged(): void {
    this.currentPage = 1;
    this.getAttachmentTags();
  }

  columnSorted(event: IColumnSortedEvent): void {
    this.order = event.column.sort.sortProperty;
    this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
    this.getAttachmentTags();
  }

  attachmentTagSelected(event: IItemSelectedEvent): void {
    void this.router.navigate(['/attachment-tag', event.entity.Id]);
  }

  includeArchivedChanged(event: boolean): void {
    this.includeArchive = event;
    this.getAttachmentTags();
  }

  toggleArchive(event: any): void {
    const entity = event.entity as IDboAttachmentTag;
    const archived = !entity.Archived;
    entity.Archived = archived;
    this.attachmentTagsService.toggleArchive(entity.Id, archived).subscribe(() => {
      this.getAttachmentTags();
      this.notificationsService.success(`${archived ? 'Archived' : 'UnArchived'} successfully`);
    });
  }

}
