import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef, EventEmitter } from '@angular/core';
import { SharedModule } from '../../common/shared.module';
import { take } from 'rxjs/operators';
import { InvestmentDealAddModalComponent } from './investmentDealAddModal';
import { AddDealUpdateService } from './investmentDealUpdateService';
import { IDboInvestment } from '../../model/interfaces/dbo-investment';
import { ClientService } from '../../clients/client.service';
import { TaskService } from '../../tasks/task.service';
import { IVwClient } from '../../model/partials/vw-client';
import { IDboVClientAltName } from '../../model/interfaces/dbo-v-client-alt-name';

@Injectable({
    providedIn: SharedModule,
})
export class InvestmentDealAddModalService {
    private _componentRef: ComponentRef<InvestmentDealAddModalComponent>;

    saved: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        private modalService: AddDealUpdateService,
        private clientService: ClientService,
        private taskService: TaskService,
    ) {}

    showModal(investment: IDboInvestment, clientAltNames: IDboVClientAltName[], newInvestmentSelectedClients?: IVwClient[]): void {
        this.destroyComponent();
        this._componentRef = this.componentFactoryResolver.resolveComponentFactory(InvestmentDealAddModalComponent).create(this.injector);
        this._componentRef.instance.entity = investment;
        this._componentRef.instance.newInvestmentSelectedClients = newInvestmentSelectedClients;
        this._componentRef.instance.clientAltNames = clientAltNames;
        this._componentRef.instance.modalService = this.modalService;
        this._componentRef.instance.clientService = this.clientService;
        this._componentRef.instance.taskService = this.taskService;
        this._componentRef.instance.onSave.pipe(take(1)).subscribe(() => this.saved.emit());
        this._componentRef.instance.onCancel.pipe(take(1)).subscribe(() => {
            this.destroyComponent();
        });

        this.appRef.attachView(this._componentRef.hostView);
        const domElem = (this._componentRef.hostView as EmbeddedViewRef<InvestmentDealAddModalComponent>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
    }

    private destroyComponent(): void {
        if (this._componentRef) {
            this.appRef.detachView(this._componentRef.hostView);
            this._componentRef.destroy();
            this._componentRef = null;
        }
    }
}
