import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IMessageTaxSearchOption } from '../../model/interfaces/message-tax-search-option';

@Injectable({
    providedIn: 'root',
})
export class MessageTaxSearchOptionService extends MetaItemService<IMessageTaxSearchOption> {
    constructor(public http: HttpClient) {
        super('MessageTaxSearchOptionService', 'Option', 'OptionIds', '/messageTaxSearch', http);
    }
}
