import { Component, OnInit } from '@angular/core';

import { IEmailContact } from '../../model/interfaces/email-contact';
import { EmailContactService } from '../services/email-contact.service';

@Component({
    templateUrl: './email-contact-add.component.html',
})
export class EmailContactAddComponent implements OnInit {
    emailContact: IEmailContact;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private emailcontactService: EmailContactService) {}

    ngOnInit(): void {
        this.emailContact = this.emailcontactService.getEmptyEmailContact();
    }
}
