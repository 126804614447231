<mt-modal-wrapper [options]="modalOptions" (cancelClick)="cancel()">
    <div class="miles-form padded">
        <mt-custom-document
            class="justUploader"
            [maxFileSize]="maxFileSize"
            [allowedMimeType]="allowedMimeType"
            (onAfterAddingAll)="addAllFiles($event)"
            (onAddingFileFailed)="onWhenAddingFileFailed($event)"
        ></mt-custom-document>
    </div>
    <button type="button" class="btn btn-primary btn-default" (click)="cancel()" style="float: right; margin-top: 20px;">
        Cancel
    </button>
</mt-modal-wrapper>

<mt-modal-wrapper (ready)="modalApi = $event" (okClick)="addDocs()" (cancelClick)="modalApi.close()" title="The following files appear to be duplicates" [autoShow]="false" [options]="duplicateAttachmentModalOptions">
    <div class="miles-form-padded" style="max-height: 500px">
        <div style="overflow-y:auto;">
            <p *ngFor="let file of duplicateFiles">{{file.name}}</p>
        </div>
    </div>
    <div>
        <button type="button" (click)="addDocs(true)" Class="btn btn-primary">
            Skip Duplicates
        </button>
        <button type="button" Class="btn btn-default" (click)="addDocs()">
            Upload All
        </button>
    </div>


</mt-modal-wrapper>
