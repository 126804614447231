import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent, ISelectionChangedEvent } from '@mt-ng2/entity-list-module';

import { TaskTypeService } from '../../tasks/task-doc-type-service';
import { IDboTaskType } from '../../model/interfaces/dbo-task-type';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { DboTaskTypesEntityListConfig } from './dbo-task-types.entity-list-config';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ISearchFilterCheckboxValueChangedEvent } from '@mt-ng2/search-filter-checkbox-control';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { TaskDateData } from '../../model/TaskDateType';
import { DboTaskTypeBackingService } from '../services/dbo-task-type-backing.service';

@Component({
    selector: 'app-dbo-task-types',
    templateUrl: './dbo-task-types.component.html',
})
export class DboTaskTypesComponent implements OnInit {
    dboTaskTypes: IDboTaskType[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new DboTaskTypesEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    includeArchive = false;
    tiers: MtSearchFilterItem[] = [];
    selectedTierIds: number[] = [];
    dateTypes: MtSearchFilterItem[] = [];
    selectedDateTypeIds: number[] = [];
    backingTypes: MtSearchFilterItem[] = [];
    selectedBackingTypeIds: number[] = [];

    canAddDboTaskType = false;

    constructor(
        private taskTypeService: TaskTypeService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationsService: NotificationsService,
        private tierService: DboTaskTypeBackingService,
        private backingService: DboTaskTypeBackingService,
    ) {}

    ngOnInit(): void {
        this.canAddDboTaskType = this.claimsService.hasClaim(ClaimTypes.Tasks, [ClaimValues.FullAccess]);

        this.tierService.getTiers().subscribe((data) => {
            data.unshift({ Id: 0, Name: 'None' });
            this.tiers = data.map((t): MtSearchFilterItem => ({ Item: t, Selected: false }));
        });
        this.backingService.getSearchFilterItems().subscribe((data) => {
            this.backingTypes = data;
        });
        this.dateTypes = TaskDateData.map((tt): MtSearchFilterItem => ({ Item: tt, Selected: false }));
        this.getDboTaskTypes();
    }

    getDboTaskTypesCall(): Observable<HttpResponse<IDboTaskType[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchive',
                value: String(this.includeArchive),
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'tierIds',
                value: String(this.selectedTierIds),
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'dateTypeIds',
                value: String(this.selectedDateTypeIds),
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'backingTypeIds',
                value: String(this.selectedBackingTypeIds),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.taskTypeService.get(searchparams);
    }

    getDboTaskTypes(): void {
        this.getDboTaskTypesCall().subscribe((answer) => {
            this.dboTaskTypes = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getDboTaskTypes();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getDboTaskTypes();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getDboTaskTypes();
    }

    dboTaskTypeSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/documentTypes', event.entity.Id]);
    }

    toggleArchive(event: IItemDeletedEvent): void {
        event.entity.IsArchived = !event.entity.IsArchived;
        this.taskTypeService.toggleArchive(+event.entity.Id, Boolean(event.entity.IsArchived).valueOf()).subscribe(() => {
            if (event.entity.IsArchived) {
                this.notificationsService.success('Archived successfully');
            } else {
                this.notificationsService.success('UnArchived successfully');
            }
        });
    }

    includeArchivedChanged(event: any): void {
        this.includeArchive = event;
        this.getDboTaskTypes();
    }

    selectedBackingTypesChanged(event: any): void {
        this.selectedBackingTypeIds = event.selectedItems.map((e) => e.Id);
        this.getDboTaskTypes();
    }

    selectedTiersChanged(event: any): void {
        this.selectedTierIds = event.selectedItems.map((e) => e.Id);
        this.getDboTaskTypes();
    }

    selectedDateTypesChanged(event: any): void {
        this.selectedDateTypeIds = event.selectedItems.map((e) => e.Id);
        this.getDboTaskTypes();
    }
}
