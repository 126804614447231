import { NgModule } from '@angular/core';
import { TenantListComponent } from './tenant-list/tenant-list.component';
import { TenantDetailComponent } from './tenant-detail/tenant-detail.component';
import { TenantADSettingsComponent } from './tenant-detail/tenant-ad-settings/tenant-ad-settings.component';
import { TenantRoutingModule } from './tenant-routing.module';
import { SharedModule } from '../common/shared.module';
import { TenantAccessSettingsComponent } from './tenant-detail/tenant-access-settings/tenant-access-settings.component';

@NgModule({
    declarations: [TenantListComponent, TenantDetailComponent, TenantADSettingsComponent, TenantAccessSettingsComponent],
    imports: [SharedModule, TenantRoutingModule],
})
export class TenantModule {}
