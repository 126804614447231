import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef, EventEmitter } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { take } from 'rxjs/operators';
import { NotesModalComponent } from './notes-modal-component';
import { INoteEntity, INoteService } from '../../../model/interfaces/custom/EntityListConfigWithNotes';

@Injectable({
    providedIn: SharedModule,
})
export class NotesModalService {
    private _componentRef: ComponentRef<NotesModalComponent>;

    saved: EventEmitter<void> = new EventEmitter<void>();

    constructor(private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) {}

    showModal(entity: INoteEntity, service: INoteService, isNoteList = false, entities?: INoteEntity[]): void {
        this.destroyComponent();

        this._componentRef = this.componentFactoryResolver.resolveComponentFactory(NotesModalComponent).create(this.injector);
        this._componentRef.instance.entity = entity;

        if (entities && entities.length) {
            this._componentRef.instance.entities = entities;
        }

        this._componentRef.instance.service = service;
        this._componentRef.instance.isNoteList = isNoteList;
        this._componentRef.instance.onSave.pipe(take(1)).subscribe(() => this.saved.emit());
        this._componentRef.instance.onCancel.pipe(take(1)).subscribe(() => {
            this.destroyComponent();
        });

        this.appRef.attachView(this._componentRef.hostView);
        const domElem = (this._componentRef.hostView as EmbeddedViewRef<NotesModalComponent>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
    }

    private destroyComponent(): void {
        if (this._componentRef) {
            this.appRef.detachView(this._componentRef.hostView);
            this._componentRef.destroy();
            this._componentRef = null;
        }
    }
}
