import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';

import { IEmailContact } from '../../model/interfaces/email-contact';

export const emptyEmailContact: IEmailContact = {
    CreatedOn: new Date(),
    Email: null,
    FirstName: '',
    Id: 0,
    IsArchived: false,
    LastName: '',
    MiddleName: '',
};

@Injectable({
    providedIn: 'root',
})
export class EmailContactService extends BaseService<IEmailContact> {
    constructor(public http: HttpClient) {
        super('/emailContacts', http, null, { entityName: 'Email Contact' });
    }

    getEmptyEmailContact(): IEmailContact {
        return { ...emptyEmailContact };
    }

    searchNameList<T extends { FirstName?: string; MiddleName?: string; LastName: string; Email: string }>(
        list: T[],
        term: string | null,
        minTermLength: number,
    ): T[] {
        if ((term ? term.length : 0) < minTermLength) {
            return [];
        }
        if (!term) {
            return [...list];
        }
        const lower = term.toLowerCase();

        return list.filter(
            (e: T) =>
                (e && e.Email && e.Email.toLowerCase().includes(lower)) ||
                (e && e.FirstName && e.FirstName.toLowerCase().includes(lower)) ||
                (e && e.MiddleName && e.MiddleName.toLowerCase().includes(lower)) ||
                (e && e.LastName && e.LastName.toLowerCase().includes(lower)),
        );
    }

    checkEmailExistAndCreate(emails: string[]): Observable<IEmailContact[]> {
        return this.http.post<IEmailContact[]>(`/emailContacts/checkEmailsExist`, emails, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }
}
