<div class="dynamic-field form-input space-for-validation" [class.has-error]="hasError">
    <span *ngIf="label" class="control-label">
        <span class="dynamic-field-label">{{ label }}</span>
    </span>
    <span>
        <br />
        <label tabindex="0" class="btn-primary-inverse selected-date-label" ngbButtonLabel (click)="toggleDatePicker()">
            <span class="date-text">
                <span [class.hidden]="showDatePicker">{{ selectedDateText }}</span>
                <input
                    type="text"
                    [class.hidden]="!showDatePicker"
                    [(ngModel)]="editDateText"
                    [ngModelOptions]="{ standalone: true }"
                    (ngModelChange)="editDateChanged($event)"
                    (click)="$event.stopPropagation()"
                />
            </span>
            <i class="fa fa-fw fa-calendar"></i>
        </label>
        <br />
        <ngb-datepicker
            class="date-picker-styles"
            [class.hidden]="!showDatePicker"
            #datePicker
            [(ngModel)]="model"
            [ngModelOptions]="{ standalone: true }"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [dayTemplate]="customDay"
            [firstDayOfWeek]="firstDayOfTheWeek"
            (dateSelect)="dateSelected($event)"
        ></ngb-datepicker>
        <ngb-timepicker
            *ngIf="value && showTimePicker"
            class="date-picker-styles"
            [class.hidden]="showDatePicker"
            #timePicker
            [meridian]="true"
            [(ngModel)]="modelTime"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="timeSelected($event)"
        ></ngb-timepicker>
    </span>
</div>
<ng-template #customDay let-date="date" let-selected="selected">
    <div class="date-picker-day-view" [class.selected-date]="selected">
        <span>{{ date.day }}</span>
    </div>
</ng-template>
