import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';

import { ITenant } from '../../model/interfaces/tenant';
import { TenantService } from '../tenant.service';
import { TenantsEntityListConfig } from './tenants.entity-list-config';
import { entityListModuleConfig } from '../../common/shared.module';
import { SortDirection, IColumnSortedEvent, IItemSelectedEvent } from '@mt-ng2/entity-list-module';

@Component({
    selector: 'app-tenant-list',
    templateUrl: './tenant-list.component.html',
})
export class TenantListComponent {
    searchControl = new UntypedFormControl();
    tenants: ITenant[];
    currentPage = 1;
    query = '';
    total: number;

    entityListConfig = new TenantsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    constructor(private tenantService: TenantService, private router: Router) {}

    ngOnInit(): void {
        this.getTenants();
    }

    getTenants(): void {
        const search = this.query;
        const searchEntity: IEntitySearchParams = {
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.tenantService.get(searchparams).subscribe((answer) => {
            this.tenants = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.getTenants();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getTenants();
    }

    tenantSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/tenants', event.entity.Id]);
    }
}
