<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && emailContact.Id > 0"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Email Contact Info
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered" (click)="edit()">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <mt-dynamic-view [config]="config.viewOnly"></mt-dynamic-view>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Email Contact Info</h4>
    <mt-dynamic-form [config]="config.formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)">
        <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-success">
            Save
        </button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
            Cancel
        </button>
    </mt-dynamic-form>
</div>
