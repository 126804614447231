import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { DboTaskTypeAddComponent } from './dbo-task-type-add/dbo-task-type-add.component';
import { DboTaskTypeDetailComponent } from './dbo-task-type-detail/dbo-task-type-detail.component';
import { DboTaskTypesComponent } from './dbo-task-type-list/dbo-task-types.component';
import { TaskTypeService } from '../tasks/task-doc-type-service';
import { DboTaskTypeHeaderComponent } from './dbo-task-type-header/dbo-task-type-header.component';

import { ClaimTypes } from '../model/ClaimTypes';

const dboTaskTypeEntityConfig = {
    claimType: ClaimTypes.Tasks,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'dboTaskTypeId',
    service: TaskTypeService,
    title: 'DboTaskType Detail',
};

const dboTaskTypeListRoleGuard: any = {
    claimType: ClaimTypes.Tasks,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Document Types',
};

const dboTaskTypeAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Tasks,
    claimValues: [ClaimValues.FullAccess],
};

export const dboTaskTypePaths = {
    dboTaskTypes: 'documentTypes',
    dboTaskTypesAdd: 'documentTypes/add',
};

const dboTaskTypeRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: DboTaskTypesComponent,
        data: dboTaskTypeListRoleGuard,
        path: dboTaskTypePaths.dboTaskTypes,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: DboTaskTypeAddComponent, path: '', pathMatch: 'full' }],
        component: DboTaskTypeHeaderComponent,
        data: dboTaskTypeAddRoleGuard,
        path: dboTaskTypePaths.dboTaskTypesAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: DboTaskTypeDetailComponent, path: '', pathMatch: 'full' }],
        component: DboTaskTypeHeaderComponent,
        data: dboTaskTypeEntityConfig,
        path: `documentTypes/:${dboTaskTypeEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(dboTaskTypeRoutes)],
})
export class DboTaskTypeRoutingModule {}
