<span [class.hidden]="!enabled" ngbDropdown autoClose="outside" style="max-height: 300px;" #ngbDropdown="ngbDropdown">
    <button ngbDropdownToggle class="btn btn-transparent dropdown-toggle" type="button">
        <span [innerHtml]="selectedItemsText()"></span>
        <span class="caret"></span>
    </button>
    <div ngbDropdownMenu class="dropdown-calendar-menu">
        <div class="form-padding">
            <div class="action-div row">
                <div class="col-md-6">
                    <label for="ContextualDateType">Other Dates</label>
                    <select name="ContextualDateType" [(ngModel)]="contextualDateType" (ngModelChange)="contextualDateTypeChange($event)">
                        <option *ngFor="let type of contextualDateTypes" [ngValue]="type.Id"> {{ type.Name }}</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <button (click)="applyChanges()" class="btn btn-primary btn-sm pull-right" type="button">
                        Apply
                    </button>
                    <button (click)="clearValues()" class="btn btn-default btn-sm pull-right btn-clear" type="button">
                        Clear
                    </button>
                </div>
            </div>
            <div>
                <app-date-picker
                    class="start-date"
                    label="start"
                    [value]="startDate"
                    [firstDayOfTheWeek]="firstDayOfTheWeek"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    (valueChanged)="startDateChanged($event)"
                    (timeChanged)="startDateTimeChanged($event)"
                    [showTimePicker]="showTimepicker"
                ></app-date-picker>
                <app-date-picker
                    class="end-date"
                    label="end"
                    [value]="endDate"
                    [firstDayOfTheWeek]="firstDayOfTheWeek"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    (valueChanged)="endDateChanged($event)"
                    (timeChanged)="endDateTimeChanged($event)"
                    [isAM]="false"
                    [showTimePicker]="showTimepicker"
                ></app-date-picker>
                <br class="error-spacer" />
                <span *ngIf="hasDatesReversedError()" id="start-after-end-error" class="small errortext">Start must be earlier than end</span>
            </div>
        </div>
    </div>
</span>
<span *ngIf="!enabled">
    <button disabled class="btn btn-transparent" type="button">
        <span [innerHtml]="selectedItemsText()"></span>
    </button>
</span>
