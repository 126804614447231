<div style="width: 25%;">
    <h3>Setup new user</h3>
    <form [formGroup]="form" (ngSubmit)="submitForm()">
        <mt-dynamic-field [form]="form" [field]="firstNameTextbox"></mt-dynamic-field>
        <mt-dynamic-field [form]="form" [field]="lastNameTextbox"></mt-dynamic-field>
        <mt-dynamic-field [form]="form" [field]="userTypeDropdown"></mt-dynamic-field>
        <mt-dynamic-field [form]="form" [field]="emailTextbox"></mt-dynamic-field>
        <button type="submit" class="btn btn-flat btn-success">
            Save
        </button>
    </form>
</div>
