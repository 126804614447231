import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IVwClient } from '../../model/interfaces/vw-client';
import { SharedModule } from '../../common/shared.module';

@Injectable({
    providedIn: SharedModule,
})
export class AddDealUpdateService {
    addDealsEvent = new Subject<IVwClient[]>();

    refreshDealsObservable = this.addDealsEvent.asObservable();

    addDeals(deals: IVwClient[]): void {
        this.addDealsEvent.next(deals);
    }
}
