import { IDboTag } from '../interfaces/dbo-tag';
import { IUser } from '../interfaces/user';
import { IUserWithFullName } from './user';
import { IMessageTag } from '../interfaces/message-tag';

export interface IMessageSearchObject {
    skip: number;
    take: number;
    messageIds: string;
    startReceivedDate: string;
    endReceivedDate: string;
    name: string;
    query: string;
    order: string;
    orderDirection: string;
    savedSearchId: number;
    from: string;
    subject: string;
    body: string;
    assigneeIds?: number[];
    tagIds: number[];
    automationStatusIds?: number[];
    assignerIds?: number[];
    isOrgSearch?: boolean;
    tenantId?: number;
    messageSearchStatuses?: number[];
    messageSearchTaxStatuses?: number[];
    lockStatuses?: number[];
    contextualDateId?: number;
    taskIds?: string;
    untaggedMessages?: boolean;
}

export class MessageSearchObject implements IMessageSearchObject {
    skip: number;
    take: number;
    messageIds: string;
    startReceivedDate: string;
    endReceivedDate: string;
    includeArchive: boolean;
    name: string;
    query: string;
    order: string;
    orderDirection: string;
    savedSearchId: number;
    from: string;
    subject: string;
    body: string;
    assigneeIds?: number[];
    tagIds: number[];
    automationStatusIds?: number[];
    assignerIds?: number[];
    automationStatus?: string;
    isOrgSearch?: boolean;
    tenantId?: number;
    messageSearchStatuses?: number[];
    messageSearchTaxStatuses?: number[];
    lockStatuses: number[];
    contextualDateId?: number;
    taskIds?: string;
    untaggedMessages?: boolean;
    constructor(valueObject: IMessageSearchObject) {
        this.skip = valueObject.skip;
        this.take = valueObject.take;
        this.messageIds = valueObject.messageIds;
        this.startReceivedDate = valueObject.startReceivedDate;
        this.endReceivedDate = valueObject.endReceivedDate;
        this.name = valueObject.name;
        this.query = valueObject.query;
        this.order = valueObject.order;
        this.orderDirection = valueObject.orderDirection;
        this.savedSearchId = valueObject.savedSearchId;
        this.from = valueObject.from;
        this.body = valueObject.body;
        this.subject = valueObject.subject;
        this.assigneeIds = valueObject.assigneeIds;
        this.tagIds = valueObject.tagIds;
        this.assignerIds = valueObject.assignerIds;
        this.automationStatusIds = valueObject.automationStatusIds;
        this.isOrgSearch = valueObject.isOrgSearch;
        this.tenantId = valueObject.tenantId;
        this.messageSearchStatuses = valueObject.messageSearchStatuses;
        this.messageSearchTaxStatuses = valueObject.messageSearchTaxStatuses;
        this.lockStatuses = valueObject.lockStatuses;
        this.contextualDateId = valueObject.contextualDateId;
        this.taskIds = valueObject.taskIds;
        this.untaggedMessages = valueObject.untaggedMessages;
    }
}
