import { Component, EventEmitter, Output, OnInit, Input, Injector } from '@angular/core';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { IEntityListConfig, IEntity } from '@mt-ng2/entity-list-module';
import { AuthService, ClaimsService } from '@mt-ng2/auth-module';
import { IAttachmentWithMetadata, AttachmentMetadataService } from '../attachment-meta-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDocumentsComponent, IDocument } from '@mt-ng2/entity-components-documents';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { DboMessageDocumentService } from '../../messages/services/dbo-message-document-service';
import { ProcessAttachmentUpdateService } from './processAttachmentUpdateService';
import { FileItem } from 'ng2-file-upload';
import { IDboAttachment } from '../../model/interfaces/dbo-attachment';
import { IDboVwMessageWithAttachment } from '../../model/interfaces/dbo-vw-message-with-attachment';
import { MimeTypes } from '../../common/constants/MimeTypes';
import { forkJoin, Observable } from 'rxjs';
import { FILE_SIZE_LIMIT } from '../../common/constants/files';

@Component({
    selector: 'app-processAttachmentDocument-modal',
    templateUrl: './processAttachmentAddModal.html',
})
export class ProcessAttachmentAddModalComponent extends CommonDocumentsComponent implements OnInit {
    modalOptions: IModalOptions = {
        showConfirmButton: false,
        width: '50%',
    };
    duplicateAttachmentModalOptions: IModalOptions = {
        showCancelButton: false,
        showCloseButton: true,
        showConfirmButton: false,
        width: '700px',
    };
    maxFileSize = FILE_SIZE_LIMIT;

    _isEditing = false;
    _allowedMimeType = MimeTypes;
    get allowedMimeType(): string[] {
        return this._allowedMimeType;
    }

    entity: IAttachmentWithMetadata;
    entityListConfig: IEntityListConfig;
    doubleClickIsDisabled = false;
    docService: DboMessageDocumentService;
    modalService: ProcessAttachmentUpdateService;
    allAttachments: IAttachmentWithMetadata[] = [];
    selectedAttachment: IDboAttachment;
    metaDataService: AttachmentMetadataService;

    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSave: EventEmitter<void> = new EventEmitter<void>();

    duplicateFiles: File[] = [];
    fileQueue: FileItem[] = [];
    modalApi: IModalWrapperApi;

    //     constructor(  myroute: ActivatedRoute,  myrouter: Router,  injector: Injector,  notificationsService: NotificationsService, claimsService: ClaimsService, config: IEntityComponentsDocumentsModuleConfig) {
    //       super(myroute, myrouter, injector, notificationsService, claimsService, config);
    //    }

    ngOnInit(): void {
        this.allAttachments = this.allAttachments.filter((a) => a.MessageId !== this.entity.MessageId);
    }

    cancel(): void {
        this.onCancel.emit();
    }

    toggleEdit(): void {
        this.isEditing = !this.isEditing;
    }

    addDocs(skipDuplicates = false): void {
        this.fileQueue.forEach((file) => {
            if (skipDuplicates) {
                if (!this.duplicateFiles.some((x) => x.name === file._file.name)) {
                    this.addDoc(file);
                }
            } else {
                this.addDoc(file);
            }
        });
        this.duplicateFiles = [];
        this.fileQueue = [];
        this.modalApi.close();
    }

    addDoc(event: FileItem): void {
        const uploadedFile = event._file;
        const lastModifiedDate =  new Date(uploadedFile.lastModified);
        if (uploadedFile.size <= this.maxFileSize) {
            this.docService.saveDocument(this.entity.MessageId, uploadedFile, lastModifiedDate).subscribe((data) => {
                const newAttachment: IAttachmentWithMetadata = {
                    AttachmentFileName: data.FileName,
                    AttachmentId: data.Id,
                    AttachmentName: data.Name,
                    ContentType: data.ContentType,
                    MessageId: data.MessageId,
                    Name: data.Name,
                    SenderName: this.entity.SenderName,
                    Subject: this.entity.Subject,
                };
                this.modalService.addAttachment(newAttachment);
                this.cancel();
            });
        } else {
            this.notificationsService.error('File is too large to upload.');
            this.cancel();
        }
    }

    onAttachmentSelected(event: IDboVwMessageWithAttachment): void {
        this.docService.duplicateDocument(this.entity.MessageId, event.AttachmentId).subscribe((data) => {
            const newAttachment: IAttachmentWithMetadata = {
                AttachmentFileName: data.FileName,
                AttachmentId: data.Id,
                AttachmentName: data.Name,
                ContentType: data.ContentType,
                MessageId: data.MessageId,
                Name: data.Name,
                SenderName: this.entity.SenderName,
                Subject: this.entity.Subject,
            };
            this.modalService.addAttachment(newAttachment);
            this.cancel();
        });
    }

    checkForDuplicates(event: FileItem): Observable<number> {
        const uploadedFile = event._file;
        return this.docService.checkForDuplicates([this.entity.MessageId], uploadedFile);
    }

    addAllFiles(files: FileItem[]): void {
        this.fileQueue = files;
        const checkForDuplicatesObservableArray = files.map((f) => {
            return this.checkForDuplicates(f);
        });
        forkJoin(checkForDuplicatesObservableArray).subscribe((data) => {
            const [...returnData] = data;
            returnData.forEach((numberOfDuplicates, index) => {
                if (numberOfDuplicates > 0) {
                    this.duplicateFiles.push(files[index]._file);
                }
            });
            if (this.duplicateFiles.length > 0) {
                this.modalApi.show();
            } else {
                this.addDocs();
            }
        });
    }

}
