<div *ngIf="isReplyExpanded" class="emailBodyContainer" [class.emailInModal]="isInModal"
    (click)="updateLastSelectedMessageRow($event); suppress($event);">
    <span *ngIf="(message && (message.expanded || isInModal)) && replyMessageId && singleMessage"><button
            class="btn btn-primary pull-left" (click)="expandReply($event)"><i
                class="fa fa-arrow-right"></i></button></span>
    <div *ngIf="message && (message.expanded || isInModal) && singleMessage" class="panel panel-default panel-body"
        [ngClass]="replyMessageId == null ? 'emailViewFirst' : 'emailView'" [class.emailInModal]="isInModal"
        (click)="updateLastSelectedMessageRow($event); suppress($event)">
        <div class="row">
            <div class="col-md-11">
                <span style="display: flex;">
                    <p style="margin-right: 4px;"><b>From:</b></p>
                    <div class="addressContainer" *ngIf="singleMessage.From">
                        <p *ngFor="let address of singleMessage.From.split(','); let i = index">
                            {{address}}{{singleMessage.From.split(',')[i + 1] ? ',' : ''}}
                        </p>
                    </div>
                </span>
                <span style="display: flex;">
                    <p style="margin-right: 4px;"><b>To:</b></p>
                    <div class="addressContainer" *ngIf="singleMessage.ToAddress">
                        <p *ngFor="let address of singleMessage.ToAddress.split(','); let i = index">
                            {{address}}{{singleMessage.ToAddress.split(',')[i + 1] ? ',' : ''}}
                        </p>
                    </div>
                </span>
                <span style="display: flex;">
                    <p style="margin-right: 4px;"><b>CC:</b></p>
                    <div class="addressContainer" *ngIf="singleMessage.CcAddress">
                        <p *ngFor="let address of singleMessage.CcAddress.split(','); let i = index">
                            {{address}}{{singleMessage.CcAddress.split(',')[i + 1] ? ',' : ''}}
                        </p>
                    </div>
                </span>

                <span style="display: flex;" *ngIf="singleMessage?.ReceivedDate">
                    <p style="float: left;"><b>Received Date:</b> {{singleMessage.ReceivedDate | date}}</p>
                </span>

                <span style="display: flex;" *ngIf="singleMessage?.sentDate">
                    <p style="float: left;"><b>Sent Date:</b> {{singleMessage.sentDate | date:'medium'}}</p>
                </span>

                <span>
                    <p style="float: left;"><b>Subject:</b> {{singleMessage.Subject}}</p>
                </span>
                <div>
                    <button *ngIf="displayEmailBodyButton(singleMessage)"
                        [mtKeyboardShortcut]="mtKeyboardShortcutDef.convertBody" title="shift + b"
                        class="btn btn-primary emailBodyButton" (click)="convertToAttachment($event);">
                        Convert Email Body to Attachment
                    </button>
                </div>
            </div>
            <div class="col-md-1" style="display: flex;">
                <button class="btn btn-outline-primary" [mtKeyboardShortcut]="mtKeyboardShortcutDef.reply"
                    (click)="displayReply(replyTypes.Reply)">
                    <i class="fa fa-reply"></i>
                </button>
                <div ngbDropdown class="d-inline-block">
                    <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle><i
                            class="fa fa-ellipsis-v"></i></button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="overflow: auto;"
                        [mtKeyboardShortcut]="mtKeyboardShortcutDef.forward">
                        <button class="btn btn-transparent dropButton" ngbDropdownItem
                            (click)="displayReply(replyTypes.ReplyAll)">
                            <i class="fa fa-reply-all"></i> Reply All
                        </button>
                        <button class="btn btn-transparent dropButton" ngbDropdownItem
                            (click)="displayReply(replyTypes.Forward)">
                            <i class="fa fa-forward"></i> Forward
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="emailBody">
            <textarea *ngIf="!singleMessage.IsHtml" placeholder="Email Body" readonly="true" cols="250"
                [rows]="isInModal ? 15 : 5" style="resize: none;">
{{singleMessage.Body}}</textarea>
            <iframe *ngIf="singleMessage.IsHtml" [srcdoc]="singleMessage.HtmlBody" width="100%"
                [height]="isInModal ? '600px' : '150px'"></iframe>
        </div>
        <div *ngIf="attachments && attachments.length" class="attachmentDisplay">
            <div *ngFor="let attachment of attachments; let i = index" (click)="suppress($event)"
                class="attachmentContainer">
                <app-document-display [attachment]="attachment"></app-document-display>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isReplyExpanded && message && (message.expanded || isInModal)" class="emailBodyContainer"
    [class.emailInModal]="isInModal" (click)="updateLastSelectedMessageRow($event); suppress($event);">
    <span *ngIf="message && (message.expanded || isInModal) && singleMessage"><button class="btn btn-primary pull-left"
            (click)="expandReply($event)"><i class="fa fa-arrow-down"></i></button></span>
    <div *ngIf="message && (message.expanded || isInModal)&& singleMessage"
        class="panel panel-default panel-body emailView"
        (click)="updateLastSelectedMessageRow($event); suppress($event)">
        <div class="row">
            <div class="col-md-11">
                <p>
                    <b>{{singleMessage.ToAddress}}</b>
                    <b *ngIf="singleMessage.ReplyTypeId === replyTypes.Reply"><i class="fa fa-reply"></i></b>
                    <b *ngIf="singleMessage.ReplyTypeId === replyTypes.ReplyAll"><i class="fa fa-reply-all"></i></b>
                    <b *ngIf="singleMessage.ReplyTypeId === replyTypes.Forward"><i class="fa fa-forward"></i></b>
                </p>
                <p *ngIf="singleMessage.Body && singleMessage.Body.length"
                    style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                    {{singleMessage.Body}}
                </p>
                <p *ngIf="!singleMessage.Body || !singleMessage.Body.length" style="font-style: italic;">Message Has No
                    Body</p>
                <div *ngIf="attachments && attachments.length" class="attachmentDisplay">
                    <div *ngFor="let attachment of attachments; let i = index" (click)="suppress($event)"
                        class="attachmentContainer">
                        <app-document-display [attachment]="attachment"></app-document-display>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>