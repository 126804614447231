import { IEntityListConfig, EntityListConfig, IEntityListColumn, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';
import { IUser } from '../../model/interfaces/user';
import { IUsersPageColumns } from '../../model/interfaces/custom/IUsersPageColumns.d';

export class UsersEntityListConfig extends EntityListConfig {
    constructor(obj: IUsersPageColumns) {
        const listConfig: IEntityListConfig = {
            columns: [],
        };
        for (const prop in obj) {
            if (prop === 'Name' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        accessorFunction: function (user: IUser): string {
                            return `${user.FirstName} ${user.LastName}`;
                        },
                        name: 'Name',
                        sort: {
                            sortProperty: 'LastName',
                        },
                    }),
                );
            } else if (prop === 'Email' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        linkFunction: function (user: IUser): void {
                            window.open(`mailto:${user.Email}`);
                        },
                        name: 'Email',
                    }),
                );
            } else if (prop === 'Tenant' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        accessorFunction: (user: IUser) => user.AuthUser.Tenant.Name,
                        name: 'Tenant',
                        sort: {
                            sortProperty: 'AuthUser.Tenant.Name',
                        },
                    }),
                );
            } else if (prop === 'Role' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        accessorFunction: (user: IUser) => user.AuthUser.UserRoleType.Name,
                        name: 'Role',
                        sort: {
                            sortProperty: 'AuthUser.UserRoleType.Name',
                        },
                    }),
                );
            } else if (prop === 'Active' && obj[prop] === true) {
                listConfig.columns.push(
                    new EntityListColumn({
                        accessorFunction: (user: IUser) => (user.AuthUser.HasAccess ? 'Yes' : 'No'),
                        name: 'Active',
                        sort: {
                            sortProperty: 'AuthUser.HasAccess',
                        },
                    }),
                );
            }
        }

        super(listConfig);
    }
}
