import { Component, OnInit } from '@angular/core';

import { IMessageTag } from '../../model/interfaces/message-tag';
import { MessageTagService } from '../services/message-tag.service';

@Component({
    templateUrl: './message-tag-add.component.html',
})
export class MessageTagAddComponent implements OnInit {
    messageTag: IMessageTag;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private messagetagService: MessageTagService) {}

    ngOnInit(): void {
        this.messageTag = this.messagetagService.getEmptyMessageTag();
    }
}
