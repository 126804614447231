import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MtPhoneControlModule } from '@mt-ng2/phone-control';
import { DynamicFormModule } from '@mt-ng2/dynamic-form';

import { CustomPhoneComponent } from './custom-phone/custom-phone.component';
import { ModalModule } from '@mt-ng2/modal-module';

@NgModule({
    declarations: [CustomPhoneComponent],
    exports: [CustomPhoneComponent],
    imports: [CommonModule, NgbModule, RouterModule, DynamicFormModule, MtPhoneControlModule, ModalModule,],
})
export class CustomPhoneModule {
    static forRoot(): any {
        return {
            ngModule: CustomPhoneModule,
        };
    }
}
