import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IDboTaskTypeBacking } from '../../model/interfaces/dbo-task-type-backing';
import { Observable } from 'rxjs';
import { ITaskTypeTier } from '../../model/interfaces/task-type-tier';

@Injectable({
    providedIn: 'root',
})
export class DboTaskTypeBackingService extends MetaItemService<IDboTaskTypeBacking> {
    constructor(public http: HttpClient) {
        super('DboTaskTypeBackingService', 'Backing', 'BackingIds', '/backingTypes', http);
    }

    getTiers(): Observable<ITaskTypeTier[]> {
        return this.http.get<ITaskTypeTier[]>('/taskTypeTiers');
    }
}
