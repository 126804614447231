import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { Component, Input, OnInit, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntity, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IMessageRow } from './message-list-component';
import { Subscription } from 'rxjs';
import { IReplyMessage } from '../../model/interfaces/reply-message';
import { ReplyMessageService } from '../replyEmails/reply-message.service';
import { ReplyTypes } from '../../common/constants/Enum';
import { DboMessageService } from '../services/dbo-message.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    styles: [],
    templateUrl: './row-expander.component.html',
})
export class RowExpanderComponent implements IEntityListDynamicCellComponent, OnInit {
    get message(): IMessageRow {
        return this.entity as IMessageRow;
    }
    @Input() set message(message: IMessageRow) {
        this.entity = message;
    }

    @Input() entity: IEntity;

    @ViewChild('expandRow') expandRow: ElementRef<HTMLElement>;
    entityListComponentMembers: IEntityListComponentMembers;
    subscription: Subscription = new Subscription();
    replyMessage: IReplyMessage;
    replies: IReplyMessage[] = [];
    replyTypes = ReplyTypes;
    fullScreenEmailModal: IModalWrapperApi;
    modalOpened = false;
    paramMessageId: string;

    modalOptions: IModalOptions = {
        showCloseButton: true,
        showConfirmButton: false,
        width: '100%',
    };

    constructor(private messageService: DboMessageService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.replies = this.message.Replies;
        this.paramMessageId = this.route.snapshot.paramMap.get('id');
        if (this.paramMessageId?.length && !this.message.expanded) {
            setTimeout(() => {
                this.expandRow.nativeElement.click();
            });
        }
    }

    announceMessageExpanded(event: any): void {
        this.message.expanded = !this.message.expanded;
        this.messageService.lastOpenedRow = this.message;
        if (!this.message.expanded && this.replyMessage) {
            this.replyMessage.ReplyTypeId = null;
            this.updateReply(this.replyMessage);
        }
        event.stopPropagation();
    }

    updateReply(event: IReplyMessage): void {
        event.Subject = event.ReplyTypeId === this.replyTypes.Forward ? 'FWD: ' + this.message.Subject : 'RE: ' + this.message.Subject;
        this.replyMessage = event;
    }

    messageUpdate(event: IReplyMessage): void {
        this.message.Replies.push(event);
        this.messageService.announceGetMessages();
        this.fullScreenEmailModal.close();
    }

    trackById(index: number, item: IReplyMessage): number {
        return item.Id;
    }

    displayModal(event: any): void {
        event.stopPropagation();
        this.modalOpened = true;
        this.messageService.lastOpenedRow = this.message;
        this.fullScreenEmailModal.show();
    }

    closeModal(): void {
        if (this.replyMessage) {
            this.replyMessage.ReplyTypeId = null;
            this.updateReply(this.replyMessage);
        }
        this.fullScreenEmailModal.close();
        this.modalOpened = false;
    }
}
