import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';
import { ITaskMsgAuditLog } from '../../model/interfaces/task-msg-audit-log';
import { ITaskMsgAuditLogExtended } from '../../model/interfaces/custom/ITaskMessageAuditLogExtended';
import { TaskMsgLogType } from '../../common/constants/Enum';

export class AuditLogEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (auditLog: ITaskMsgAuditLog): string {
                        if (auditLog.User) {
                            return `${auditLog.User.FirstName} ${auditLog.User.LastName}`;
                        } else {
                            return 'System User';
                        }
                    },
                    name: 'User',
                    sort: {
                        sortProperty: 'User.LastName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (auditLog: ITaskMsgAuditLog): string {
                        const userEmail = auditLog.User?.Email;
                        if (userEmail != null && userEmail?.length > 0) {
                            const indexOfAt = userEmail.indexOf('@') + 1;
                            const indexOfTopLevelDomain = userEmail.substring(indexOfAt).indexOf('.') + indexOfAt;
                            return userEmail.substring(indexOfAt, indexOfTopLevelDomain);
                        } else {
                            return '';
                        }
                    },
                    name: 'Domain',
                    sort: {
                        sortProperty: 'Domain',
                    },
                }),
                new EntityListColumn({
                    accessors: ['MessageId'],
                    name: 'Email ID',
                    sort: {
                        sortProperty: 'MessageId',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (auditLog: ITaskMsgAuditLogExtended): string {
                        const taskIds = auditLog.DboMessage?.DboMessageTasks.map((mt) => mt.TaskId);
                        if (taskIds?.length > 0 && auditLog.TaskMsgAuditLogType.Id === TaskMsgLogType.MailingStatusSetProcessed) {
                            return taskIds.join(', ');
                        }
                        return '';
                    },
                    name: 'Task IDs',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: function (auditLog: ITaskMsgAuditLog): string {
                        if (auditLog.Timestamp) {
                            const timeAsDate = new Date(auditLog.Timestamp);
                            return timeAsDate.mtDate.format('Do MMMM YYYY h:mm a');
                        } else {
                            return '';
                        }
                    },
                    name: 'Timestamp',
                    // pipes: ['date',],
                    sort: {
                        defaultDirection: SortDirection.Desc,
                        isDefaultForSort: true,
                        sortProperty: 'Timestamp',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (auditLog: ITaskMsgAuditLog): string {
                        return `${auditLog.TaskMsgAuditLogType.Name}`;
                    },
                    name: 'Action',
                    sort: {
                        sortProperty: 'TaskMsgAuditLogType.Name',
                    },
                }),
                new EntityListColumn({
                    accessors: ['Details'],
                    name: 'Details',
                    sort: {
                        sortProperty: 'Details',
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
