<!-- Using a custom css modal instead of mt-modal-wrapper because this component can be opened inside an already-opened modal, while mt-modal-wrapper cannot handle stacked modals. mt-modal-wrapper internally uses sweetalerts2, and it is a known issue: https://github.com/sweetalert2/sweetalert2/issues/606#issuecomment-325697220 -->
<div class="popup-backdrop" #modalDiv>
    <div class="miles-card popup">
        <span class="popup-close-btn" (click)="onClose.emit()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
        <div style="min-width: 50vw;">
            <ng-content></ng-content>
        </div>
    </div>
</div>
