import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IAttachmentActionType } from '../model/interfaces/attachment-action-type';
import { Subject, Observable } from 'rxjs';
import { IVwFund } from '../model/interfaces/vw-fund';
import { IVwClient } from '../model/interfaces/vw-client';
import { IDboInvestment } from '../model/interfaces/dbo-investment';
import { IDboAttachment } from '../model/interfaces/dbo-attachment';
import { IDboTaskType } from '../model/interfaces/dbo-task-type';
import { IDboVwMessageWithAttachment } from '../model/interfaces/dbo-vw-message-with-attachment';
import { IUser } from '../model/interfaces/user';
import { ILoggedIn } from '@mt-ng2/auth-module';
import { IVwFundWithAltName } from '../model/interfaces/custom/IVwFundWithAltName';
import { IDboAttachmentTag } from '../model/interfaces/dbo-attachment-tag';

export interface IMetaDataObject {
    Clients: IVwClient[];
    DueDate?: Date;
    Fund: IVwFund;
    Id: number;
    Investment: IDboInvestment;
    IsDistributionNotice: boolean;
    IsFinalNotice?: boolean;
    IsHighPriority?: boolean;
    IsIgnored: boolean;
    IsPCAPAudited?: boolean;
    IsProcessed: boolean;
    Notes: string;
    Password: string;
    PcIncludedFlag?: boolean;
    RelatedFunds?: IVwFundWithAltName[];
    StatementDate?: Date;
    SupportingDocs: IDboAttachment[];
    TaskDateType: number;
    TaskType: IDboTaskType;
    ExcludeRelatedTaskCreation: boolean;
    TaxFormYear?: number;
    attachmentTags?: IDboAttachmentTag[];
    attachmentTagIds?: number[];
}

export interface IMetaDataObjectForAPI extends IMetaDataObject {
    DueDateString?: string;
    StatementDateString?: string;
}

export const emptyMetaDataObject = {
    Clients: [],
    DateNecessary: null,
    DueDate: null,
    Fund: null,
    Id: 0,
    Investment: null,
    IsDistributionNotice: false,
    IsHighPriority: false,
    IsIgnored: false,
    IsPCAPAudited: false,
    IsProcessed: false,
    Notes: '',
    Password: '',
    PcIncludedFlag: false,
    StatementDate: null,
    SupportingDocs: [],
    TaskDateType: null,
    TaskType: null,
    ExcludeRelatedTaskCreation: false,
    TaxFormYear: null,
    attachmentTags: [],
    attachmentTagIds: []
};

export interface IAttachmentWithMetadata extends IDboVwMessageWithAttachment {
    metadata?: IMetaDataObject;
    validation?: IAttachmentValidationObject;
    IsPending?: boolean;
    initialFundNote?: string;
    initialInvestmentNote?: string;
    potentialClients?: IVwClient[];
    relatedFunds?: IVwFundWithAltName[];
}

export interface IAttachmentValidationObject {
    clients: boolean;
    dateType: boolean;
    docType: boolean;
    fundInvestment: boolean;
}

export interface IAttachmentWithMetadataDTO {
    attachment: IDboVwMessageWithAttachment;
    metadata: IMetaDataObject;
    authUserID: number;
}

export interface IAttachmentWithMetadataDTOForAPI {
    attachment: IDboVwMessageWithAttachment;
    metadata: IMetaDataObjectForAPI;
    authUserID: number;
}

export function convertForAPI(obj: IAttachmentWithMetadataDTO): IAttachmentWithMetadataDTOForAPI {
    return obj
        ? {
            ...obj,
            metadata: obj.metadata
                ? {
                    ...obj.metadata,
                    DueDateString: obj.metadata.DueDate ? toLocalDate(obj.metadata.DueDate) : undefined,
                    StatementDateString: obj.metadata.StatementDate ? toLocalDate(obj.metadata.StatementDate) : undefined,
                }
                : obj.metadata,
        }
        : obj;
}

function toLocalDate(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

@Injectable({
    providedIn: 'root',
})
export class AttachmentMetadataService {
    refreshAttachmentsEvent = new Subject<IAttachmentWithMetadata>();
    refreshAttachmentObservable = this.refreshAttachmentsEvent.asObservable();

    announceTaskProcessEvent = new Subject<IAttachmentWithMetadata>();
    announceTaskProcessObservable = this.announceTaskProcessEvent.asObservable();

    constructor() { }

    getEmptyMetadataObject(): IMetaDataObject {
        return { ...emptyMetaDataObject };
    }

    addAttachment(attachmentWithMetadata: IAttachmentWithMetadata): void {
        this.refreshAttachmentsEvent.next(attachmentWithMetadata);
    }

    announceTaskProcess(attachment: IAttachmentWithMetadata): void {
        this.announceTaskProcessEvent.next(attachment);
    }
}
