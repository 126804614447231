import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { IDboInvestment } from '../../model/interfaces/dbo-investment';
import { InvestmentService } from '../investment-service';

@Component({
    templateUrl: './investment-header.component.html',
})
export class InvestmentHeaderComponent implements OnInit {
    investment: IDboInvestment;
    header$: Observable<string>;
    subscriptions: Subscription = new Subscription();

    constructor(private investmentService: InvestmentService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('Id');
        this.investmentService.setInitialTitle(id);
        this.header$ = this.investmentService.title$;

    }

}
