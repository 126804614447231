<mt-modal-wrapper [options]="modalOptions" (cancelClick)="cancel()">
    <div class="miles-form padded">
        <mt-custom-document
            *ngIf="!reportReturned"
            class="justUploader"
            [maxFileSize]="maxFileSize"
            [allowedMimeType]="allowedMimeType"
            (onAfterAddingFile)="showConfirm($event)"
            (onAddingFileFailed)="onWhenAddingFileFailed($event)"
        ></mt-custom-document>

        <span *ngIf="reportReturned">
            <p>
                {{download.TotalItems - download.Duplicates.length - download.Errors.length}} successful deal imports.
                <br />{{download.Duplicates.length}} duplicate deals. <br />{{download.Errors.length}} failures.
            </p>
        </span>
    </div>

    <button *ngIf="reportReturned" type="button" class="btn btn-primary" (click)="downloadReport()" style="float: right; margin-top: 20px;">
        Download Report
    </button>
    <div style="float: right; margin-top: 20px; margin-right: 4px;">
        <button *ngIf="!reportReturned && this.uploaded" class="btn btn-success" style="margin-right: 4px;" (click)="addDoc()">
            Upload
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()">
            Close
        </button>
    </div>
</mt-modal-wrapper>
