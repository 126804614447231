import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { IDboAttachmentTag } from '../../model/interfaces/dbo-attachment-tag';
import { TagColorComponent } from '../../message-tags/color-selector/message-tag-color-dynamic-cell';
export class AttachmentTagsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Name',
                }),
                new EntityListColumn({
                    component: TagColorComponent,
                    fireOnColumnSelected: true,
                    name: 'Color',
                    sort: { disableSort: true },
                    style: { width: 140 },
                }),
            ],
            delete: new EntityListDeleteColumn({
                columnHtml: (entity: IDboAttachmentTag) =>
                    '<div class="text-center">' +
                    (!entity.Archived
                        ? '<i class="fa fa-lg fa-trash" aria-hidden="true"></i>'
                        : '') +
                    (entity.Archived ? '<i class="fa fa-lg fa-undo overlay-fa"></i>' : '') +
                    '</div>',
                confirm: false,
                headerText: 'Archive',
            }),
        };
        super(listConfig);
    }
}
