export interface IMtSearchFilterDaterangeModuleConfig {
    afterText?: string;
    beforeText?: string;
    contextualDates?: IContextualQuarterTypes[];
    firstDayOfWeek?: DaysOfTheWeek;
    throughText?: string;
}

export enum DaysOfTheWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
}

export enum Months {
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December,
}

export interface ISearchFilterDaterangeValue {
    startDate: Date;
    endDate: Date;
}

export enum ContextualQuarterTypesEnum {
    Q1 = 1,
    Q2 = 2,
    Q3 = 3,
    Q4 = 4,
}

export interface IContextualQuarterTypes {
    Id: ContextualQuarterTypesEnum;
    Name: string;
}

export interface IQuarterYear {
    QuarterType: IContextualQuarterTypes;
    Year: number;
}

export const ContextualQuarterTypes: IContextualQuarterTypes[] = [
    { Id: ContextualQuarterTypesEnum.Q1, Name: 'First Quarter' },
    { Id: ContextualQuarterTypesEnum.Q2, Name: 'Second Quarter' },
    { Id: ContextualQuarterTypesEnum.Q3, Name: 'Third Quarter' },
    { Id: ContextualQuarterTypesEnum.Q4, Name: 'Fourth Quarter' },
];

export function getDateRangeByContextualFilter(contextualDateTypeId: number, year: number): Date {
    const today = new Date();
    const todayCopy = new Date();
    let date: Date;
    switch (contextualDateTypeId) {
        case ContextualQuarterTypesEnum.Q1:
            date = new Date(year, 3);
            return new Date(date.setDate(date.getDate() - 1));
        case ContextualQuarterTypesEnum.Q2:
            date = new Date(year, 6);
            return new Date(date.setDate(date.getDate() - 1));
        case ContextualQuarterTypesEnum.Q3:
            date = new Date(year, 9);
            return new Date(date.setDate(date.getDate() - 1));
        case ContextualQuarterTypesEnum.Q4:
            date = new Date(year, 12);
            return new Date(date.setDate(date.getDate() - 1));
        default:
            return new Date();
    }
}

export function getPreviousQuarter(): IQuarterYear {
    const today = new Date();
    const thisMonth = today.getMonth();
    if (thisMonth > 9 && thisMonth <= 12) {
        return { QuarterType: ContextualQuarterTypes[2], Year: today.getFullYear() };
    } else if (thisMonth > 6 && thisMonth <= 9) {
        return { QuarterType: ContextualQuarterTypes[1], Year: today.getFullYear() };
    } else if (thisMonth > 3 && thisMonth <= 6) {
        return { QuarterType: ContextualQuarterTypes[0], Year: today.getFullYear() };
    } else {
        return { QuarterType: ContextualQuarterTypes[3], Year: today.getFullYear() - 1 };
    }
}

export function getDateQuarter(today: Date): IQuarterYear {
    const thisMonth = today.getMonth();
    if (thisMonth > 9 && thisMonth <= 12) {
        return { QuarterType: ContextualQuarterTypes[3], Year: today.getFullYear() };
    } else if (thisMonth > 6 && thisMonth <= 9) {
        return { QuarterType: ContextualQuarterTypes[2], Year: today.getFullYear() };
    } else if (thisMonth > 3 && thisMonth <= 6) {
        return { QuarterType: ContextualQuarterTypes[1], Year: today.getFullYear() };
    } else {
        return { QuarterType: ContextualQuarterTypes[0], Year: today.getFullYear() };
    }
}
