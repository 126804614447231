import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { TaskDateData } from '../../model/TaskDateType';
import { IDboTaskType } from '../../model/interfaces/dbo-task-type';

export class DboTaskTypesEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Name',
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IDboTaskType) => {
                        if (entity.DboTaskTypeBacking) {
                            return entity.DboTaskTypeBacking.Name;
                        } else {
                            return '';
                        }
                    },
                    name: 'Fund/Tax Position',
                    sort: { sortProperty: 'TaskTypeBackingId' },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IDboTaskType) => {
                        if (entity.TaskTypeTier) {
                            return entity.TaskTypeTier.Name;
                        } else {
                            return 'None';
                        }
                    },
                    name: 'Tier',
                    sort: { sortProperty: 'TierId' },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IDboTaskType) => {
                        if (entity.TaskDateType) {
                            return TaskDateData[entity.TaskDateType].Name;
                        } else {
                            return 'None';
                        }
                    },
                    name: 'Task Date Type',
                    sort: { sortProperty: 'TaskDateType' },
                }),
            ],
            delete: new EntityListDeleteColumn({
                columnHtml: (entity: IDboTaskType) =>
                    '<div class="text-center">' +
                    '<i class="fa fa-lg fa-trash" aria-hidden="true"></i>' +
                    (entity.IsArchived ? '<i class="fa fa-lg fa-undo overlay-fa"></i>' : '') +
                    '</div>',
                confirm: false,
                headerText: 'Archive',
            }),
        };
        super(listConfig);
    }
}
