import { Component, EventEmitter, Output, OnInit, Input, Injector } from '@angular/core';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IEntityListConfig, IEntity } from '@mt-ng2/entity-list-module';
import { AuthService, ClaimsService } from '@mt-ng2/auth-module';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDocumentsComponent, IDocument } from '@mt-ng2/entity-components-documents';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { DboMessageDocumentService } from '../../messages/services/dbo-message-document-service';
import { InvestmentAddDealsReportService } from './investmentAddDealsUpdateService';
import { FileItem } from 'ng2-file-upload';
import { IDboAttachment } from '../../model/interfaces/dbo-attachment';
import { IDboVwMessageWithAttachment } from '../../model/interfaces/dbo-vw-message-with-attachment';
import { IDboInvestment } from '../../model/interfaces/dbo-investment';
import { InvestmentService } from '../investment-service';
import { IBlobDownload } from '../../model/interfaces/custom/blob-download';
import { IUploadDealReport } from '../../model/interfaces/custom/upload-deal-report';
import { FILE_SIZE_LIMIT } from '../../common/constants/files';

@Component({
    selector: 'app-investmentAddDeals-modal',
    templateUrl: './investmentAddDealsModal.html',
})
export class InvestmentAddDealsModalComponent extends CommonDocumentsComponent implements OnInit {
    modalOptions: IModalOptions = {
        showConfirmButton: false,
        width: '50%',
    };

    _allowedMimeType = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/vnd.ms-excel.template.macroEnabled.12',
        'application/vnd.ms-excel.addin.macroEnabled.12',
        'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    ];
    get allowedMimeType(): string[] {
        return this._allowedMimeType;
    }

    entityListConfig: IEntityListConfig;
    doubleClickIsDisabled = false;
    _isEditing = false;
    investmentService: InvestmentService;
    dealReportService: InvestmentAddDealsReportService;
    selectedAttachment: IDboAttachment;
    reportReturned = false;
    download: IUploadDealReport;
    uploaded = false;
    uploadedFile: File;
    maxFileSize = FILE_SIZE_LIMIT;

    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSave: EventEmitter<void> = new EventEmitter<void>();

    //     constructor(  myroute: ActivatedRoute,  myrouter: Router,  injector: Injector,  notificationsService: NotificationsService, claimsService: ClaimsService, config: IEntityComponentsDocumentsModuleConfig) {
    //       super(myroute, myrouter, injector, notificationsService, claimsService, config);
    //    }

    ngOnInit(): void {}

    cancel(): void {
        this.onCancel.emit();
    }

    toggleEdit(): void {
        this.isEditing = !this.isEditing;
    }

    showConfirm(event: FileItem): void {
        this.uploadedFile = event._file;
        this.uploaded = true;
    }

    addDoc(): void {
        this.investmentService.uploadExcel(this.uploadedFile).subscribe((data) => {
            if (data.Errors.length > 0) {
                this.download = data;
                this.reportReturned = true;
            } else {
                this.cancel();
            }
        });
    }

    downloadReport(): void {
        const element = document.createElement('a');
        element.setAttribute(
            'href',
            'data:text/plain;charset=utf-8,' +
                encodeURIComponent(
                    'Duplicates:\n\n -' + this.download.Duplicates.join('\n\n -') + '\n\nErrors:\n\n -' + this.download.Errors.join('\n\n -'),
                ),
        );
        element.setAttribute('download', 'Report');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        this.cancel();
    }
}
