<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>{{ tenant?.Name }}</h2>
    </div>
</div>
<div *ngIf="tenant">
    <app-tenant-access-settings *ngIf="editAccess" [tenant]="tenant"></app-tenant-access-settings>
    <br />
    <app-tenant-ad-settings [tenant]="tenant"></app-tenant-ad-settings>
    <br />
    <a [routerLink]="returnLink" class="btn btn-default">Close</a>
</div>
