import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IDboTaskType } from '../interfaces/dbo-task-type';
import { IDboTaskTypeBacking } from '../interfaces/dbo-task-type-backing';
import { ITaskTypeTier } from '../interfaces/task-type-tier';

export interface IDboTaskTypeDynamicControlsParameters {
    formGroup?: string;
    taskTypeBackings?: IDboTaskTypeBacking[];
    tiers?: ITaskTypeTier[];
}

export class DboTaskTypeDynamicControls {
    formGroup: string;
    taskTypeBackings: IDboTaskTypeBacking[];
    tiers: ITaskTypeTier[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private dbotasktype?: IDboTaskType, additionalParameters?: IDboTaskTypeDynamicControlsParameters) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'DboTaskType';
        this.taskTypeBackings = (additionalParameters && additionalParameters.taskTypeBackings) || undefined;
        this.tiers = (additionalParameters && additionalParameters.tiers) || undefined;

        this.Form = {
            ArchivedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived By',
                name: 'ArchivedById',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.dbotasktype && this.dbotasktype.ArchivedById) || null,
            }),
            ArchivedOn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived On',
                name: 'ArchivedOn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.dbotasktype && this.dbotasktype.ArchivedOn) || null,
            }),
            IsArchived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Archived',
                name: 'IsArchived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value:
                    this.dbotasktype && this.dbotasktype.hasOwnProperty('IsArchived') && this.dbotasktype.IsArchived !== null
                        ? this.dbotasktype.IsArchived
                        : false,
            }),
            IsInvestmentOnly: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Investment Only',
                name: 'IsInvestmentOnly',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value:
                    this.dbotasktype && this.dbotasktype.hasOwnProperty('IsInvestmentOnly') && this.dbotasktype.IsInvestmentOnly !== null
                        ? this.dbotasktype.IsInvestmentOnly
                        : false,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(50)],
                validators: { required: true, maxlength: 50 },
                value:
                    this.dbotasktype && this.dbotasktype.hasOwnProperty('Name') && this.dbotasktype.Name !== null
                        ? this.dbotasktype.Name.toString()
                        : '',
            }),
            TaskDateType: new DynamicField({
                formGroup: this.formGroup,
                label: 'Task Date Type',
                name: 'TaskDateType',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.dbotasktype && this.dbotasktype.TaskDateType) || null,
            }),
            TaskTypeBackingId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Task Type Backing',
                name: 'TaskTypeBackingId',
                options: this.taskTypeBackings,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.dbotasktype && this.dbotasktype.TaskTypeBackingId) || null,
            }),
            TierId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tier',
                name: 'TierId',
                options: this.tiers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.dbotasktype && this.dbotasktype.TierId) || null,
            }),
        };

        this.View = {
            ArchivedById: new DynamicLabel({
			    label: 'Archived By',
			    value: (this.dbotasktype && this.dbotasktype.ArchivedById) || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            ArchivedOn: new DynamicLabel({
			    label: 'Archived On',
			    value: (this.dbotasktype && this.dbotasktype.ArchivedOn) || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            IsArchived: new DynamicLabel({
			    label: 'Is Archived',
			    value: this.dbotasktype && this.dbotasktype.hasOwnProperty('IsArchived') && this.dbotasktype.IsArchived !== null
			        ? this.dbotasktype.IsArchived
			        : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsInvestmentOnly: new DynamicLabel({
			    label: 'Is Investment Only',
			    value: this.dbotasktype && this.dbotasktype.hasOwnProperty('IsInvestmentOnly') && this.dbotasktype.IsInvestmentOnly !== null
			        ? this.dbotasktype.IsInvestmentOnly
			        : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Name: new DynamicLabel({
			    label: 'Name',
			    value: this.dbotasktype && this.dbotasktype.hasOwnProperty('Name') && this.dbotasktype.Name !== null ? this.dbotasktype.Name.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            TaskDateType: new DynamicLabel({
			    label: 'Task Date Type',
			    value: (this.dbotasktype && this.dbotasktype.TaskDateType) || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            TaskTypeBackingId: new DynamicLabel({
			    label: 'Task Type Backing',
			    value: getMetaItemValue(this.taskTypeBackings as unknown as IMetaItem[], this.dbotasktype && this.dbotasktype.hasOwnProperty('TaskTypeBackingId') && this.dbotasktype.TaskTypeBackingId !== null
			        ? this.dbotasktype.TaskTypeBackingId
			        : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            TierId: new DynamicLabel({
			    label: 'Tier',
			    value: getMetaItemValue(this.tiers as unknown as IMetaItem[], this.dbotasktype && this.dbotasktype.hasOwnProperty('TierId') && this.dbotasktype.TierId !== null
			        ? this.dbotasktype.TierId
			        : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };
    }
}
