import { Component, EventEmitter, Output, OnInit, Input, Injector } from '@angular/core';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { IEntityListConfig, IEntity } from '@mt-ng2/entity-list-module';
import { AuthService, ClaimsService } from '@mt-ng2/auth-module';
import { IAttachmentWithMetadata, AttachmentMetadataService } from '../../../processAttachments/attachment-meta-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDocumentsComponent, IDocument } from '@mt-ng2/entity-components-documents';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FileItem } from 'ng2-file-upload';
import { IDboAttachment } from '../../../model/interfaces/dbo-attachment';
import { IDboVwMessageWithAttachment } from '../../../model/interfaces/dbo-vw-message-with-attachment';
import { MimeTypes } from '../../../common/constants/MimeTypes';
import { forkJoin, Observable } from 'rxjs';
import { FILE_SIZE_LIMIT } from '../../../common/constants/files';
import { DboMessageDocumentService } from '../../services/dbo-message-document-service';
import { ProcessAttachmentUpdateService } from '../../../processAttachments/processAttachment-add-modal/processAttachmentUpdateService';
@Component({
    selector: 'app-replyEmailAttachmentDocument-modal',
    templateUrl: './replyEmailAttachmentAddModal.html',
})
export class ReplyEmailAttachmentAddModalComponent extends CommonDocumentsComponent implements OnInit {
    maxFileSize = FILE_SIZE_LIMIT;

    _allowedMimeType = MimeTypes;
    get allowedMimeType(): string[] {
        return this._allowedMimeType;
    }

    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSave: EventEmitter<void> = new EventEmitter<void>();
    @Output() onFileUpload: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();

    fileQueue: FileItem[] = [];
    modalApi: IModalWrapperApi;

    //     constructor(  myroute: ActivatedRoute,  myrouter: Router,  injector: Injector,  notificationsService: NotificationsService, claimsService: ClaimsService, config: IEntityComponentsDocumentsModuleConfig) {
    //       super(myroute, myrouter, injector, notificationsService, claimsService, config);
    //    }

    ngOnInit(): void {
    }

    cancel(): void {
        this.onCancel.emit();
    }

    addAllFiles(files: FileItem[]): void {
        this.fileQueue = files;
        this.onFileUpload.emit(files);
    }
}
