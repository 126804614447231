import { IVwClient } from '../../model/partials/vw-client';
import {
    EntityListConfig,
    SortDirection,
    EntityListColumn,
    IEntityListConfig,
    EntityListDeleteColumn,
    EntityListSelectColumn,
    SelectTypes,
} from '@mt-ng2/entity-list-module';
import { InTransitCellComponent } from './client-inTransit-cell.component';

import { ClientService } from '../client.service';
import { ViewNoteComponent } from '../../common/components/notes-modal/view-note-component';
import { IEntityListConfigWithNotes } from '../../model/interfaces/custom/EntityListConfigWithNotes';
import { ViewClientAltNamesComponent } from '../client-alt-names-modal/client-viewAltNamesCell';

export class ClientsEntityListConfig extends EntityListConfig {
    constructor(notesService: ClientService) {
        const listConfig: IEntityListConfigWithNotes = {
            columns: [
                new EntityListColumn({
                    name: 'Name',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        isDefaultForSort: true,
                    },
                }),
                new EntityListColumn({
                    name: 'Currency',
                    style: { width: 100 },
                }),
                new EntityListColumn({
                    name: 'Client/Legal Entity',
                }),
                new EntityListColumn({
                    component: ViewClientAltNamesComponent,
                    fireOnColumnSelected: false,
                    name: 'Alternative Names',
                    sort: { disableSort: false, sortProperty: 'HasAltNames' },
                    style: { width: 200 },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IVwClient) =>
                        (entity.DboAccountToClients || [])
                            .filter((atc) => atc && atc.DboAccount && atc.DboAccount.Tenants)
                            .map((atc) => atc.DboAccount.Tenants)
                            .reduce((a, b) => [...a, ...b], [])
                            .filter((t) => t && t.Name)
                            .sort((t) => t.Id)
                            .map((t) => t.Name)
                            .join(', '),
                    name: 'Tenants',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    component: ViewNoteComponent,
                    fireOnColumnSelected: false,
                    name: 'Note',
                    style: { width: 140 },
                }),
                new EntityListColumn({
                    component: InTransitCellComponent,
                    fireOnColumnSelected: false,
                    name: 'In Transition',
                    sort: { disableSort: true },
                    style: { width: 140 },
                }),
            ],
            delete: new EntityListDeleteColumn({
                columnHtml: (entity: IVwClient) =>
                    '<div class="text-center">' +
                    '<i class="fa fa-lg fa-trash" aria-hidden="true"></i>' +
                    (entity.IsArchived ? '<i class="fa fa-lg fa-undo overlay-fa"></i>' : '') +
                    '</div>',
                confirm: false,
                headerText: 'Archive',
            }),
            noteService: notesService,
            rowClass: (entity: IVwClient) => (entity.IsArchived ? 'archived' : null),
            select: new EntityListSelectColumn(SelectTypes.Multi),
        };
        super(listConfig);
    }
}
