import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { IEmailContact } from '../../model/interfaces/email-contact';
import { EmailContactService } from '../services/email-contact.service';

@Component({
    templateUrl: './email-contact-header.component.html',
})
export class EmailContactHeaderComponent implements OnInit {
    emailContact: IEmailContact;
    header$: Observable<string>;
    subscriptions: Subscription = new Subscription();

    constructor(private emailContactService: EmailContactService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('Id');
        this.emailContactService.setInitialTitle(id);
        this.header$ = this.emailContactService.title$;
    }
}
