import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService, ILoginResponse } from '@mt-ng2/auth-module';
import { ActivatedRoute } from '@angular/router';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ADLoginService {
    constructor(
        private http: HttpClient,
        private activatedRoute: ActivatedRoute,
        private environmentService: EnvironmentService,
        private authService: AuthService,
    ) {}

    adLogin(username: string, password: string, remember: boolean, loginPath: string): Observable<ILoginResponse> {
        const data: any = {
            AuthClientID: this.environmentService.config.authClientId,
            AuthClientSecret: this.environmentService.config.authSecretVariable,
            LoginPath: loginPath,
            Password: password,
            Username: username,
        };
        if (this.activatedRoute && this.activatedRoute.snapshot && this.activatedRoute.snapshot.queryParams) {
            data.Tenant = this.activatedRoute.snapshot.queryParams.tenant;
        }
        return this.http.post<ILoginResponse>('/authUsers/ad-token', data).pipe(tap((response) => this.authService.saveToken(response, remember)));
    }

    validatePath(loginPath: string): Observable<boolean> {
        return this.http.get<boolean>(`/authUsers/ad-validate/${loginPath}`);
    }
}
