<mt-modal-wrapper [options]="modalOptions" (cancelClick)="cancel()">
    <div *ngIf="!isNoteList">
        <div *ngIf="entities && entities.length > 0; else elseBlock">
            <h4><b>Note for </b></h4>
            <div *ngFor="let entity of entities">
                {{entity.EmailId}}
            </div>
        </div>
        <ng-template #elseBlock>
            <h4><b>Note for : {{entity.Name}}</b></h4>
        </ng-template>
        <div *ngIf="entity && entity.Note" class="text-center">
            <textarea class="noteModalNoteInput" #noteInput [(ngModel)]="entityNote" style="margin-top: 5px; width: 100%;" rows="4" cols="100">
                {{entityNote}}
            </textarea>
        </div>
        <div *ngIf="entity && !entity.Note" class="text-center">
            <textarea class="noteModalNoteInput" #noteInput [(ngModel)]="entityNote" style="margin-top: 5px; width: 100%;" rows="4" cols="100">
                {{entityNote}}
            </textarea>
        </div>
        <div *ngIf="entities && entities.length" class="text-center">
            <textarea class="noteModalNoteInput" #noteInput [(ngModel)]="entityNote" style="margin-top: 5px; width: 100%;" rows="4" cols="100">
            {{entityNote}}
            </textarea>
        </div>
        <br /><br />
        <div class="buttonGroup">
            <button type="button" class="btn btn-primary" (click)="save()">Save</button>
            <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
        </div>
    </div>
    <div *ngIf="isNoteList" class="fullModal">
        <h3 class="noteHeader"><b>Notes for Email {{entity.EmailId}}</b></h3>
        <p *ngIf="!notes.length" class="noteHeader"><i>No Notes</i></p>
        <div class="scrollModal notes">
            <div *ngFor="let note of notes" class="singleNote">
                <div class="row">
                    <p *ngIf="note.User" class="noteBy">{{note.User.FirstName}} {{note.User.LastName}}</p>
                    <p *ngIf="!note.User" class="noteBy">System User</p>
                    <i class="noteOn">{{note.CreatedOn | date:'dd MMMM yyyy h:mm a'}}</i>
                    <p class="noteText">{{note.Note}}</p>
                </div>
                <hr />
            </div>
        </div>

        <div>
            <label for="note">Select Note</label>
            <select [ngModel]="selectedNote" (ngModelChange)="selectedNoteChanged($event)">
                <option class="form-control" [ngValue]="null" [selected]="true">Custom Note</option>
                <option class="form-control" *ngFor="let note of managedNotes" [ngValue]="note"
                    >{{note.Note.length > 50 ? note.Note.slice(0,50) + '...' : note.Note}}</option
                >
            </select>
        </div>
        <div *ngIf="!selectedNote">
            <textarea
                class="noteModalNoteInput form-control"
                #noteInput
                focus
                [(ngModel)]="newNoteText"
                style="resize: none; margin-top: 5px;"
                rows="4"
                cols="100"
                maxlength="250"
            ></textarea>
            <span class="small pull-right">{{newNoteText.length}}/250</span>
        </div>
        <div *ngIf="selectedNote" class="display-padded" style="text-align: left; font-size: 14px;">
            <span><label>Note:</label></span>
            <span>{{selectedNote.Note}}</span>
        </div>

        <div class="buttonGroup">
            <button type="button" class="btn btn-primary" (click)="addNote()" [disabled]="!selectedNote && !newNoteText.length">Add Note</button>
            <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
        </div>
    </div>
</mt-modal-wrapper>
