import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IDboAttachmentTag } from '../interfaces/dbo-attachment-tag';

export interface IDboAttachmentTagDynamicControlsParameters {
    formGroup?: string;
}

export class DboAttachmentTagDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private dboattachmenttag?: IDboAttachmentTag, additionalParameters?: IDboAttachmentTagDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DboAttachmentTag';

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.dboattachmenttag && this.dboattachmenttag.hasOwnProperty('Archived') && this.dboattachmenttag.Archived !== null ? this.dboattachmenttag.Archived : false,
            }),
            Color: new DynamicField({
                formGroup: this.formGroup,
                label: 'Color',
                name: 'Color',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.dboattachmenttag && this.dboattachmenttag.hasOwnProperty('Color') && this.dboattachmenttag.Color !== null ? this.dboattachmenttag.Color.toString() : '',
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.dboattachmenttag && this.dboattachmenttag.hasOwnProperty('Name') && this.dboattachmenttag.Name !== null ? this.dboattachmenttag.Name.toString() : '',
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.dboattachmenttag && this.dboattachmenttag.hasOwnProperty('Archived') && this.dboattachmenttag.Archived !== null ? this.dboattachmenttag.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Color: new DynamicLabel({
			    label: 'Color',
			    value: this.dboattachmenttag && this.dboattachmenttag.hasOwnProperty('Color') && this.dboattachmenttag.Color !== null ? this.dboattachmenttag.Color.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Name: new DynamicLabel({
			    label: 'Name',
			    value: this.dboattachmenttag && this.dboattachmenttag.hasOwnProperty('Name') && this.dboattachmenttag.Name !== null ? this.dboattachmenttag.Name.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
