import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef, EventEmitter } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { take } from 'rxjs/operators';
import { IEntity } from '../../../model/interfaces/base';
import { AuditModalComponent } from './audit-modal-component';
import { IEntityWithEmailId } from '../../../model/interfaces/custom/EntityWithEmailId';
@Injectable({
    providedIn: SharedModule,
})
export class AuditModalService {
    private _componentRef: ComponentRef<AuditModalComponent>;

    saved: EventEmitter<void> = new EventEmitter<void>();

    constructor(private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) {}

    showModal(entity: IEntityWithEmailId): void {
        this.destroyComponent();

        this._componentRef = this.componentFactoryResolver.resolveComponentFactory(AuditModalComponent).create(this.injector);
        this._componentRef.instance.entity = entity;
        this._componentRef.instance.onSave.pipe(take(1)).subscribe(() => this.saved.emit());
        this._componentRef.instance.onCancel.pipe(take(1)).subscribe(() => {
            this.destroyComponent();
        });

        this.appRef.attachView(this._componentRef.hostView);
        const domElem = (this._componentRef.hostView as EmbeddedViewRef<AuditModalComponent>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
    }

    private destroyComponent(): void {
        if (this._componentRef) {
            this.appRef.detachView(this._componentRef.hostView);
            this._componentRef.destroy();
            this._componentRef = null;
        }
    }
}
