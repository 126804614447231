import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IDboMessageManagedNote } from '../model/interfaces/dbo-message-managed-note';
import { Observable, Subject } from 'rxjs';
import { INoteEntity } from '../model/interfaces/custom/EntityListConfigWithNotes';
import { IMessageNote } from '../model/interfaces/message-note';

@Injectable({
    providedIn: 'root',
})
export class MessageManagedNotesService extends MetaItemService<IDboMessageManagedNote> {
    noteAddDeleteEvent = new Subject<void>();
    noteAddDeleteObservable = this.noteAddDeleteEvent.asObservable();

    isEditingEvent = new Subject<number>();
    isEditingObservable = this.isEditingEvent.asObservable();

    isSingleNoteBeingEditedEvent = new Subject<boolean>();
    isSingleNoteBeingEditedObservable = this.isSingleNoteBeingEditedEvent.asObservable();

    constructor(public http: HttpClient) {
        super('MessageManagedNotesService', 'Name', 'NameIds', '/manageNotes', http);
    }

    searchContentList<T extends { Note?: string }>(list: T[], term: string | null, minTermLength: number): T[] {
        if ((term ? term.length : 0) < minTermLength) {
            return [];
        }
        if (!term) {
            return [...list];
        }
        const lower = term.toLowerCase();

        return list.filter((e: T) => e && e.Note && e.Note.toLowerCase().includes(lower));
    }

    noteAddedOrDeleted(): void {
        this.noteAddDeleteEvent.next();
    }

    isEditingUpdate(id: number): void {
        this.isEditingEvent.next(id);
    }

    isSingleNoteBeingEdited(value: boolean): void {
        this.isSingleNoteBeingEditedEvent.next(value);
    }
}
