<div class="row">
    <div class="absolute-center col-md-12 text-center">
        <i style="font-size: 10em;" class="fa fa-frown-o" aria-hidden="true"></i>
        <h2>404</h2>
        <div class="lead">The page you are looking for was not found.</div>
        <div *ngIf="path" class="lead">
            Did you mean <a [routerLink]="[path]" class="btn btn-link">{{ path }}</a
            >?
        </div>
        <a routerLink="/" class="btn btn-link">Back to Home</a>
    </div>
</div>
