import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DynamicFormModule } from '@mt-ng2/dynamic-form';
import { SearchFilterDaterangeCustomComponent } from './search-filter-daterange/search-filter-daterange.component';
import { IMtSearchFilterDaterangeModuleConfig } from './search-filter-daterange/search-filter-daterange.library';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DateCustomComponent } from './quarter-date-picker/search-filter-daterange.component';

@NgModule({
    declarations: [SearchFilterDaterangeCustomComponent, DatePickerComponent, DateCustomComponent],
    exports: [SearchFilterDaterangeCustomComponent, DatePickerComponent, DateCustomComponent],
    imports: [CommonModule, DynamicFormModule, NgbModule, FormsModule],
})
export class DateTimeModule {
    static forRoot(config?: IMtSearchFilterDaterangeModuleConfig): any {
        return {
            ngModule: DateTimeModule,
            providers: [
                {
                    provide: 'search_filter_custom_module_config',
                    useValue: config,
                },
            ],
        };
    }
}
