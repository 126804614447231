import { NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { CustomerRoutingModule } from './customer-routing.module';

import { CustomerBasicInfoComponent } from './customer-basic-info/customer-basic-info.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomersComponent } from './customer-list/customers.component';
import { CustomerHeaderComponent } from './customer-header/customer-header.component';
import { CustomerSettingsComponent } from './customer-settings/customer-settings.component';

import { CustomerService } from './services/customer.service';
import { CustomerNotesService } from './services/customer-notes.service';
import { CustomerSourceService } from './services/customersource.service';
import { CustomerStatusService } from './services/customerstatus.service';
import { CustomerContactService } from './shared-entities/customer-contact.service';
import { CustomerAddressesService } from './services/customer-addresses.service';
import { CustomerDocumentsService } from './services/customer-documents.service';
import { CustomerPhonesService } from './services/customer-phones.service';
import { CustomerAddComponent } from './customer-add/customer-add.component';

@NgModule({
    declarations: [
        CustomersComponent,
        CustomerHeaderComponent,
        CustomerAddComponent,
        CustomerDetailComponent,
        CustomerBasicInfoComponent,
        CustomerSettingsComponent,
    ],
    imports: [SharedModule, CustomerRoutingModule],
})
export class CustomerModule {
    static forRoot(): any {
        return {
            ngModule: CustomerModule,
            providers: [
                CustomerService,
                CustomerNotesService,
                CustomerAddressesService,
                CustomerDocumentsService,
                CustomerPhonesService,
                CustomerSourceService,
                CustomerStatusService,
                CustomerContactService,
            ],
        };
    }
}
