import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IMessageTag } from '../../model/interfaces/message-tag';
import { Observable, Subject } from 'rxjs';
import { IDboMessageDTO } from '../../model/interfaces/custom/IDboMessageDTO';
import { IMessageTagParams } from '../../model/interfaces/custom/messageTagParams';

export const emptyMessageTag: IMessageTag = {
    AutoApply: false,
    Color: '',
    Id: 0,
    IgnoreEmail: false,
    IsDeleted: false,
    MarkAsTax: false,
    Name: '',
    RequireAllCriteriaGroups: false,
};

@Injectable({
    providedIn: 'root',
})
export class MessageTagService extends BaseService<IMessageTag> {
    constructor(public http: HttpClient) {
        super('/tags', http, null, { entityName: 'Message Tag' });
    }

    getEmptyMessageTag(): IMessageTag {
        return { ...emptyMessageTag };
    }

    toggleArchive(tagId: number, archive: boolean): any {
        return this.http.put(`/tags/archive/${tagId}`, archive, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
    }

    createUpdate(messageTag: IMessageTag): Observable<number> {
        return this.http.post<number>('/tags/updateCreate', messageTag, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
    }

    tagMessage(tagParams: IMessageTagParams): Observable<void> {
        return this.http.post<void>(`/tags/tagMessage/applyTags`, tagParams, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
    }

    deleteTagFromMessage(messageId: number, tagId: number, userId: number): Observable<void> {
        return this.http.delete<void>(`/tags/tagMessage/${messageId}/${tagId}/${userId}`);
    }
}
