import { NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { DboTaskTypeRoutingModule } from './dbo-task-type-routing.module';
import { DboTaskTypeAddComponent } from './dbo-task-type-add/dbo-task-type-add.component';
import { DboTaskTypeBasicInfoComponent } from './dbo-task-type-basic-info/dbo-task-type-basic-info.component';
import { DboTaskTypeDetailComponent } from './dbo-task-type-detail/dbo-task-type-detail.component';
import { DboTaskTypesComponent } from './dbo-task-type-list/dbo-task-types.component';
import { DboTaskTypeHeaderComponent } from './dbo-task-type-header/dbo-task-type-header.component';
import { FormRadioButtonListCustomComponent } from '../common/components/customFormRadioButton/customFormRadioButton';

@NgModule({
    declarations: [
        DboTaskTypesComponent,
        DboTaskTypeHeaderComponent,
        DboTaskTypeAddComponent,
        DboTaskTypeDetailComponent,
        DboTaskTypeBasicInfoComponent,
    ],
    imports: [SharedModule, DboTaskTypeRoutingModule]
})
export class DboTaskTypeModule {}
