import { IDboTaskType } from './../../model/interfaces/dbo-task-type.d';
import { IDboTaskDTO } from './../../model/interfaces/custom/IDboTaskDTO';
import { Component, OnInit, Input } from '@angular/core';
import { IDboTask } from '../../model/interfaces/dbo-task';
import { TaskDynamicConfig } from '../task.dynamic-config';
import { Router } from '@angular/router';
import { ClientService } from '../../clients/client.service';
import { FundService } from '../../funds/fund.service';
import { map } from 'rxjs/operators';
import { IVwClient } from '../../model/interfaces/vw-client';
import { IVwFund } from '../../model/interfaces/vw-fund';
import { Observable, Subject } from 'rxjs';
import { TaskTypeService } from '../task-doc-type-service';
import { TaskService } from '../task.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IDboInvestment } from '../../model/interfaces/dbo-investment';
import { InvestmentService } from '../../investments/investment-service';
import { IRelatedTaskDTO } from '../../model/interfaces/custom/IRelatedTaskDTO';
import { TaskDateType } from '../../model/TaskDateType';
import { IDboSaveTaskDTO } from '../../model/interfaces/custom/IDboTaskSaveDTO';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IClient } from './../../model/interfaces/custom/client';
import { TaskStatuses, TaskType } from '../../common/constants/Enum';
import { ContrastColorText } from '../../common/util';
import { IDboAttachmentTag } from '../../model/interfaces/dbo-attachment-tag';
import { AttachmentTagsService } from '../../attachment-tags/attachment-tags.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IDboTaskStatus } from '../../model/interfaces/dbo-task-status';
import { CommonService } from '../../common/services/common.service';
import { AuthService } from '@mt-ng2/auth-module';

@Component({
    selector: 'app-task-basic-info',
    styles: [
        `
        .tagList {
            display: flex;
            overflow-y: hidden;
            max-width: 120px;
        }

        .tagItem {
            text-overflow: ellipsis;
            padding: 2px;
            border-radius: 5px;
            margin-right: 2px;
            white-space: nowrap;
            overflow: hidden;
            text-align: center;
            align-items: center;
            min-width: 20px;
        }

        .tagName {
            display: inline;
            text-align: center;
            overflow: hidden;
            margin-top: auto;
            margin-bottom: auto;
        }

        .focusClass {
            background-color: rgb(170, 170, 170);
        }
        `,
    ],
    templateUrl: './task-basic-info.component.html',
})
export class TaskBasicInfoComponent extends ContrastColorText implements OnInit {
    @Input() task: IDboTaskDTO;
    @Input() canEdit: boolean;
    @Input() relatedTasks: IRelatedTaskDTO[];


    isEditing = false;
    isHovered: boolean;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: TaskDynamicConfig<IDboTask>;
    doubleClickIsDisabled = false;
    clientAssigned = '';
    clients$: Observable<IVwClient[]>;
    clients: IVwClient[];
    public clientSubject$ = new Subject<string>();
    clientsList: IVwClient[];

    funds$: Observable<IVwFund[]>;
    funds: IVwFund[];
    public fundSubject$ = new Subject<string>();
    fundsList: IVwFund;

    taskTypes: IDboTaskType[];
    taskStatuses: IDboTaskStatus[];
    selectedDocType: IDboTaskType;
    selectedDate: string;
    selectedTaskStatus : IDboTaskStatus;
    notes?: string;
    checkAllSelection = false;

    hasInvestmentId = false;
    investment$: Observable<IDboInvestment[]>;
    investments: IDboInvestment[];
    public investmentSubject$ = new Subject<string>();
    investmentList: IDboInvestment;

    showModal = false;
    taskUpdateReason = '';
    updatedTask: IDboSaveTaskDTO;

    isTaskHighPriority = false;
    IsPCAPAudited = false;
    IsDistributionNotice = false;
    attachmentTag: IDboAttachmentTag[] = [];
    selectedTagIds: number[] = [];

    taskTypePartnersCapitalStatementId = TaskType.PartnersCapitalStatement;

    modalOptions: IModalOptions = {
        showCancelButton: false,
        showConfirmButton: false,
        title: 'Please enter a reason for the changes',
        width: '50%',
    };
    disableStatusField: boolean;
    isCreatedByCurrentUser: boolean;
    disableFields: boolean;

    constructor(
        private router: Router,
        public clientService: ClientService,
        public fundService: FundService,
        public taskTypeService: TaskTypeService,
        public taskService: TaskService,
        public notificationsService: NotificationsService,
        public investmentService: InvestmentService,
        public attachmentTagService: AttachmentTagsService,
        public commonService : CommonService,
        public authService: AuthService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.hasInvestmentId = this.task.DboAttachmentExtended.InvestmentId ? true : false;
        this.getCommaSeparatedClients();

        this.authService.currentUser.subscribe(user=>{
            if(user){
                if(user.Id === this.task.CreatedByBreckId){
                    this.isCreatedByCurrentUser = true;
                }
            }
        })
        this.authService.currentUser.subscribe((currentUser) => {   
            if(currentUser){
                if(currentUser.Id === this.task.CreatedByBreckId){
                    this.isCreatedByCurrentUser = true;
                   
                }
            }
        });

        if (this.task?.Fund) {
            this.clientService.getClientsByFundId(this.task.Fund.Id, true).subscribe((clients: IVwClient[]) => {
                this.clients = clients;
            });
        }

        this.clientService.getAll().subscribe((clients: IVwClient[]) => {
            this.clientsList = clients.filter((client: IVwClient) => {
                return this.task.Clients.map((taskClient: IClient) => taskClient.Id).includes(client.Id);
            });
        });

        this.fundService.getAll().subscribe((funds: IVwFund[]) => {
            this.funds = funds;
            if (this.task.Fund) {
                this.fundsList = this.funds.filter((fund: IVwFund) => {
                    return this.task.Fund.Id === fund.Id;
                })[0];
            }
        });

        this.clients$ = this.clientSubject$.pipe(map((term) => this.searchClients(term)));
        this.funds$ = this.fundSubject$.pipe(map((term) => this.searchFunds(term)));

        this.investmentService.getAll().subscribe((investments: IDboInvestment[]) => {
            this.investments = investments;
            if (this.task.Investment) {
                this.investmentList = this.investments.filter((investment: IDboInvestment) => {
                    return this.task.Investment.Id === investment.Id;
                })[0];
            }
        });
        this.investment$ = this.investmentSubject$.pipe(map((term) => this.searchInvestments(term)));

        this.taskTypeService.getItems().subscribe((taskTypes: IDboTaskType[]) => {
            this.taskTypes = taskTypes;
            this.selectedDocType = this.taskTypes.filter((taskType: IDboTaskType) => {
                return this.task.DboTaskType.Id === taskType.Id;
            })[0];
            this.taskTypes = this.taskTypes.filter((taskType: IDboTaskType) => !taskType.IsArchived);
            if (this.selectedDocType.IsArchived) {
                this.taskTypes.unshift(this.selectedDocType);
            }
        });

        this.commonService.getTaskStatuses().subscribe((taskStatuses: IDboTaskStatus[]) => {
            this.taskStatuses = taskStatuses;
            this.selectedTaskStatus = taskStatuses.filter((taskStatus: IDboTaskStatus) => {
                return this.task.TaskStatus.Id === taskStatus.Id;
            })[0];
        })

        if (this.task?.HighPriority === true) {
            this.isTaskHighPriority = true;
        }

        if (this.task?.IsPCAPAudited === true) {
            this.IsPCAPAudited = true;
        }

        if (this.task?.IsDistributionNotice === true) {
            this.IsDistributionNotice = true;
        }

        this.notes = this.task.DboAttachmentExtended.Notes;

        this.disableStatusField =  this.task.TaskStatus.Id === TaskStatuses.Created || this.task.TaskStatus.Id === TaskStatuses.Pass || this.isCreatedByCurrentUser;

        this.checkAndDisableFields();

        this.attachmentTagService.getAll()
        .subscribe((data: IDboAttachmentTag[]) => {
            this.attachmentTag = [...data]
            this.selectedTagIds = this.task.Tags.map(t => t.Id);
        })
    }

    isStatusOptionDisabled(statusId: number): boolean {
        const disabledStatuses = [TaskStatuses.Renamed, TaskStatuses.Exception, TaskStatuses.Created, TaskStatuses.NoReviewNeeded];
        return disabledStatuses.includes(statusId);
    }

    onSelectedTagChange(): void{
        const tags = this.attachmentTag.filter(at => this.selectedTagIds.includes(at.Id));
        this.task.Tags = [...tags];
    }

    onTaskStatusChange(): void {
        this.task.TaskStatus = this.selectedTaskStatus;
    }

    showStatementDate(task: IDboTaskDTO): boolean {
        return this.selectedDocType && this.selectedDocType.TaskDateType === TaskDateType.StatementDate;
    }

    showDueDate(task: IDboTaskDTO): boolean {
        return this.selectedDocType && this.selectedDocType.TaskDateType === TaskDateType.DueDate;
    }

    showTaxFormYear(task: IDboTaskDTO): boolean {
        return this.selectedDocType && this.selectedDocType.TaskDateType === TaskDateType.TaxFormYear;
    }

    getRelatedTasks(): void {
        this.taskService.getRelatedTasks(this.task.Id).subscribe((response) => {
            this.relatedTasks = response.body;
        });
    }

    private searchInvestments(term: string | null): IDboInvestment[] {
        return this.taskService.searchNameList(this.investments, term, 2);
    }

    private searchClients(term: string | null): IVwClient[] {
        return this.taskService.searchNameList(this.clients, term, 2);
    }

    private searchFunds(term: string | null): IVwFund[] {
        return this.taskService.searchNameList(this.funds, term, 2);
    }

    getCommaSeparatedClients(): void {
        this.clientAssigned = '';
        for (const client of this.task.Clients) {
            this.clientAssigned = this.clientAssigned + ',' + client.Name;
        }
        this.clientAssigned = this.clientAssigned.substring(1, this.clientAssigned.length);
    }

    cancelClick(): void {
        void this.router.navigate(['/tasks']);
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }
    cancel(): void {
        this.isEditing = false;
    }

    private checkAndDisableFields(): void {
        if (this.task?.TaskStatus.Id === TaskStatuses.Pass) {
            this.disableFields = true;
            this.disableStatusField = true;
        }
    }

    onSubmit(form: any): void {
        if(form.selectedTaskStatus){
            this.task.TaskStatus = form.selectedTaskStatus;
        }
        if (this.showDueDate(this.task) && !this.task.DboAttachmentExtended.DateNecessary) {
            this.notificationsService.error('Due Date is required');
            return;
        }
        if (this.showStatementDate(this.task) && !this.task.DboAttachmentExtended.StatementDate) {
            this.notificationsService.error('Statement Date is required');
            return;
        }
        if (this.showTaxFormYear(this.task) && !this.task.DboAttachmentExtended.TaxFormYear) {
            this.notificationsService.error('Tax Form Year is required');
            return;
        }
        const obj: IDboSaveTaskDTO = {
            Clients: this.disableFields ? this.clientsList : form.clients,
            DateNecessary: form.dueDate,
            Fund: !this.hasInvestmentId ? (this.disableFields ? this.fundsList : form.fund) : null,
            Id: this.task.Id,
            Investment: this.hasInvestmentId ? form.investment : null,
            IsDistributionNotice: this.IsDistributionNotice,
            IsHighPriority: this.isTaskHighPriority,
            IsPCAPAudited: this.IsPCAPAudited,
            Notes: form.notes,
            Password: form.attachmentPass,
            StatementDate: form.statementDate,
            TaskDateType: form.selectedDoc.TaskDateType,
            TaskTypeId: form.selectedDoc.Id,
            TaxFormYear: form.taxFormYear,
            UpdateRelatedTaskIds: this.relatedTasks.filter((item) => item.Checked === true).map((item) => item.Id),
            Tags: this.task.Tags,
            StatusId: this.disableStatusField ? this.task.TaskStatus.Id :form.selectedTaskStatus?.Id
        };        
        this.updatedTask = obj;

        this.checkAndDisableFields();

        this.toggleModal();
    }

    toggleModal(): void {
        this.showModal = !this.showModal;
    }

    handleSave(): void {
        const reason = this.taskUpdateReason;
        const obj = this.updatedTask;
        obj.TaskUpdateReason = reason;

        if (obj && reason.length > 0) {
            this.taskService.saveTask(obj).subscribe(() => {
                this.notificationsService.success('Task saved successfully');
                this.isEditing = false;
                this.getTask(obj.Id);
                this.getRelatedTasks();
                this.checkAllSelection = false;
                this.taskUpdateReason = '';
            }, (error: HttpErrorResponse) => {
                this.notificationsService.error(`Error saving task. ${error.error  as string}`);
            });
            this.toggleModal();
        }

        if (!reason.length) {
            this.notificationsService.warning('Please enter a reason for the update');
        }
    }

    getTask(id: number): void {
        this.taskService.getTaskById(id).subscribe((response) => {
            this.task = response.body;
            this.getCommaSeparatedClients();
        });
    }

    checkAll(): void {
        this.relatedTasks.forEach((item) => {
            if (this.checkAllSelection) {
                item.Checked = true;
            } else {
                item.Checked = false;
            }
        });
    }

    onDocumentTypeChange(e): void {
        this.hasInvestmentId = e.IsInvestmentOnly;
    }

    onFundChange(fund: IVwFund): void {
        this.clients = [];
        this.clientsList = [];
        if (fund) {
            this.clientService.getClientsByFundId(fund.Id, true).subscribe((clients: IVwClient[]) => {
                if (clients.length > 0) {
                    this.clients = clients;
                }
            });
        }
    }
}
