import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues, IRoleGuarded } from '@mt-ng2/auth-module';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UsersComponent } from './user-list/users.component';
import { UserService } from './user.service';
import { UserHeaderComponent } from './user-header/user-header.component';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { CommonAddressesListComponent } from '@mt-ng2/entity-components-addresses';
import { CommonDocumentsListComponent } from '@mt-ng2/entity-components-documents';

import { ClaimTypes } from '../model/ClaimTypes';

const userEntityConfig: any = {
    addressesPath: 'addresses',
    claimType: ClaimTypes.Users,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    documentsPath: 'documents',
    entityIdParam: 'userId',
    notesPath: '',
    service: UserService,
    title: 'User Detail',
};

const userListRoleGuard: any = {
    claimType: ClaimTypes.Users,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Users',
};

export const userPaths = {
    users: 'users',
    usersAdd: 'users/add',
};

const userAddRoleGuard: any = {
    claimType: ClaimTypes.Users,
    claimValues: [ClaimValues.FullAccess],
    title: 'Users Add',
};

const userRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: UsersComponent,
        data: userListRoleGuard,
        path: userPaths.users,
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: UserDetailComponent, pathMatch: 'full' }],
        component: UserHeaderComponent,
        data: userAddRoleGuard,
        path: userPaths.usersAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: UserDetailComponent, pathMatch: 'full' }],
        component: UserHeaderComponent,
        data: userEntityConfig,
        path: `users/:${String(userEntityConfig.entityIdParam)}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(userRoutes)],
})
export class UserRoutingModule {}
