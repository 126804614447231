import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IDboFundAlternativeName } from '../model/interfaces/dbo-fund-alternative-name';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DboFundAlternativeNameService extends MetaItemService<IDboFundAlternativeName> {
    constructor(public http: HttpClient) {
        super('DboFundAlternativeNameService', 'Name', 'NameIds', '/altFundNames', http);
    }

    getByFundId(fundId: number): Observable<HttpResponse<IDboFundAlternativeName[]>> {
        return this.http.get<IDboFundAlternativeName[]>(`/altFundNames/namesByFund/${fundId}`, { observe: 'response' });
    }

    saveFundNamesById(fundNames: IDboFundAlternativeName[], fundId: number): Observable<void> {
        return this.http.put<void>(`/altFundNames/update/${fundId}`, fundNames);
    }
}
