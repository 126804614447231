import { Injectable } from '@angular/core';
import { IUserRole } from '../model/interfaces/user-role';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';

@Injectable()
export class UserRoleService extends BaseService<IUserRole> {
    private emptyUserRole: IUserRole = {
        Description: null,
        Id: 0,
        IsEditable: true,
        Name: null,
        TenantTypeId: 0,
        UserRoleTypeId: 0,
    };

    constructor(public http: HttpClient) {
        super('/userRoles', http);
    }

    getEmptyUserRole(): IUserRole {
        return { ...this.emptyUserRole };
    }

    deleteRole(roleId: number): Observable<IUserRole> {
        return this.http.delete<IUserRole>(`/userRoles/delete/${roleId}`);
    }

    getRolesWithClaims(): Observable<IUserRole[]> {
        return this.http.get<IUserRole[]>('/userRoles/withClaims');
    }

    saveUserRole(role: IUserRole): Observable<IUserRole> {
        if (role.Id > 0) {
            return this.http.put<IUserRole>('/userRoles/update', role);
        } else {
            return this.http.post<IUserRole>('/userRoles/create', role);
        }
    }

    updateClaims(roleId: number, claims: any): Observable<object> {
        return this.http.put(`/userRoles/${roleId}/updateClaims`, claims);
    }
}
