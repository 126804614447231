import { Component, OnInit } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IVwClient } from '../../model/interfaces/vw-client';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { FundService } from '../fund.service';
import { FundModalAltNamesService } from './fund-alt-names-modal-service';
import { IVwFund } from '../../model/interfaces/vw-fund';

@Component({
    styles: [],
    template: `
        <span *ngIf="hasAccess" class="btn-group btn-group-toggle">
            <span *ngIf="fund.HasAltNames">
                <button class="btn btn-primary" (click)="openModal($event)">View Alternative Names</button>
            </span>
        </span>
    `,
})
export class ViewFundAltNamesComponent implements IEntityListDynamicCellComponent, OnInit {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.fund = value as IVwFund;
    }
    fund: IVwFund;
    hasAccess = false;
    constructor(private fundService: FundService, private claimService: ClaimsService, private altNameModalService: FundModalAltNamesService) {}
    ngOnInit(): void {
        this.hasAccess = this.claimService.hasClaim(ClaimTypes.Funds, [ClaimValues.ReadOnly, ClaimValues.FullAccess]);
    }

    openModal(event: any): void {
        event.stopPropagation();
        this.altNameModalService.showModal(this.fund);
    }
}
