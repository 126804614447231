<div class="miles-editable miles-card padded" *ngIf="!isEditing" (mouseenter)="isHovered = true" (mouseleave)="isHovered = false">
    <h4>
        Password
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered" (click)="edit()" [style.cursor]="canEdit ? 'pointer' : 'default'">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <i>
        <b *ngIf="canEdit"> Click to Edit Password </b>
        <b *ngIf="!canEdit"> You do not have access to this feature.</b>
    </i>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Password</h4>

    <form [formGroup]="form" (ngSubmit)="onSubmit($event)">
        <mt-dynamic-field [form]="form" [field]="createPasswordField()" (valueChanges)="updateSendEmail($event)"></mt-dynamic-field>
        <mt-dynamic-field [form]="form" [field]="createConfirmPasswordField()"></mt-dynamic-field>
        <mt-dynamic-field [form]="form" [field]="sendEmailCheckbox"></mt-dynamic-field>

        <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-success">
            Save
        </button>
        <button type="button" Class="btn btn-flat btn-default" (click)="this.isEditing = false">
            Cancel
        </button>
    </form>
    <br />
</div>
