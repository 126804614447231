import { Component, EventEmitter, Input, OnInit, Output, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import {
    ISearchFilterDaterangeValue,
    IMtSearchFilterDaterangeModuleConfig,
    DaysOfTheWeek,
    getDateRangeByContextualFilter,
    ContextualQuarterTypesEnum,
    IContextualQuarterTypes,
    ContextualQuarterTypes,
    getPreviousQuarter,
    getDateQuarter,
} from './search-filter-daterange.library';
import { defaultSearchFilterModuleConfig } from './default-module-config';
import { DatePickerComponent } from '../date-picker/date-picker.component';

@Component({
    selector: 'app-quarter-picker-custom',
    styles: [
        `
            .show.dropdown {
                display: inline !important;
            }
            .form-padding {
                padding: 10px;
                width: 400px;
            }
            .selected-date {
            }
            .action-div {
                min-height: 30px;
            }
            .btn-clear {
                margin-right: 5px;
            }
            .error-spacer {
                clear: both;
            }
            #start-after-end-error {
                position: absolute;
                bottom: 0;
                left: 10px;
            }
            .dateContainer {
                display: flex;
                flex-wrap: nowrap;
            }
            .buttons {
                margin-top: 20px;
                margin-left: 10px;
            }
            .quarterlyZOrder {
                z-index: 999;
            }
        `,
    ],
    templateUrl: './search-filter-daterange.component.html',
})
export class DateCustomComponent implements OnInit {
    private _selectedDate: Date;
    @Input()
    set selectedDate(date: Date) {
        this._selectedDate = date;
    }
    get selectedDate(): Date {
        return this._selectedDate;
    }

    @Input() minDate: Date = null;
    @Input() maxDate: Date = null;
    @Input() contextualDateType: ContextualQuarterTypesEnum;
    @Input() availableContextualDates: IContextualQuarterTypes[];
    @Input() entity: string;
    @Input() enabled = true;
    @Output('onSelectionChanged') selectionChanged: EventEmitter<Date> = new EventEmitter<Date>();
    @ViewChild('ngbDropdown') ngbDropdown: NgbDropdown;
    @ViewChild('selectDateCalendar') dateCalendar: DatePickerComponent;
    @ViewChild('contextualField') contextualField: ElementRef;
    @ViewChild('openButton') openButton: ElementRef;
    displayDate = '';

    firstDayOfTheWeek: DaysOfTheWeek;
    contextualDateTypes = ContextualQuarterTypes;
    appliedRange: Date;
    selectedYear: number;

    constructor(
        @Inject('search_filter_custom_module_config')
        private searchFilterModuleConfig: IMtSearchFilterDaterangeModuleConfig,
        private _cdRef: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        if (this.selectedDate === null) {
            this.searchFilterModuleConfig = Object.assign(defaultSearchFilterModuleConfig, this.searchFilterModuleConfig);
            this.firstDayOfTheWeek = this.searchFilterModuleConfig.firstDayOfWeek;
            this.selectedYear = new Date().getFullYear();
            this.setContextualDateRanges();
            this.findInitialContextualDateValue();
        } else {
            this.findUpdatedContextualDateValue();
        }
        this.displayDate = this.selectedItemsText();
    }

    private findUpdatedContextualDateValue(): void {
        const updatedQuarterAndYear = getDateQuarter(this.selectedDate);
        this.contextualDateType = updatedQuarterAndYear.QuarterType.Id;
        this.selectedYear = updatedQuarterAndYear.Year;
    }

    private setContextualDateRanges(): void {
        if (this.availableContextualDates && this.availableContextualDates.length > 0) {
            this.contextualDateTypes = this.availableContextualDates;
            this.contextualField.nativeElement.focus();
        } else {
            this.contextualDateTypes = this.searchFilterModuleConfig.contextualDates;
        }
    }

    private findInitialContextualDateValue(): void {
        const previousQuarterAndYear = getPreviousQuarter();
        this.contextualDateType = previousQuarterAndYear.QuarterType.Id;
        this.selectedYear = previousQuarterAndYear.Year;
        this.contextualDateTypeChange(this.contextualDateType);
    }

    selectedItemsText(): string {
        const date = this.selectedDate ? this.selectedDate : null;
        if (!date) {
            return `Select Date`;
        }
        return `Date: <small>${this.getDateString(this.selectedDate)}</small>`;
    }

    getDateString(date: Date): string {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    selectedDateChanged(date: Date): void {
        this._selectedDate = date;
    }

    selectedTimeChanged(date: Date): void {
        this.selectedDate.setHours(date.getHours(), date.getMinutes(), date.getSeconds());
    }

    applyChanges(): void {
        this.selectionChanged.emit(this.selectedDate);
        this.displayDate = this.selectedItemsText();
        this.closeDropdown();
    }

    closeDropdown(): void {
        if (this.ngbDropdown && this.ngbDropdown.close) {
            this.ngbDropdown.close();
        }
    }

    clearValues(): void {
        this._selectedDate = null;
        this.contextualDateType = undefined;
        this.applyChanges();
    }

    contextualDateTypeChange(value: ContextualQuarterTypesEnum): void {
        if (!value) {
            return;
        }
        this._selectedDate = getDateRangeByContextualFilter(value, this.selectedYear);
    }

    yearChanged(value: number): void {
        if (!value) {
            return;
        }
        this._selectedDate = getDateRangeByContextualFilter(this.contextualDateType, value);
    }

    toggleOpen(event: any): void {
        if (event) {
            this._cdRef.detectChanges();
            this.contextualField.nativeElement.focus();
        }
    }
}
