import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { ITenant } from '../model/interfaces/tenant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class TenantService extends BaseService<ITenant> {
    baseUrl: string;
    constructor(public http: HttpClient) {
        super('/tenants', http);
        this.baseUrl = '/tenants';
    }

    saveTenantSettings(tenant: ITenant): Observable<number> {
        return this.http.post<number>(`${this.baseUrl}/${tenant.Id}/save-settings`, tenant.TenantAdSettings).pipe(catchError(void this.handleError));
    }

    saveTenantAccess(tenantId: number, denyTaskTypeIds: number[]): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/${tenantId}/save-access`, denyTaskTypeIds);
    }
}
