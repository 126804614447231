import {
    IEntityListConfig,
    EntityListConfig,
    EntityListColumn,
    EntityListDeleteColumn,
    SelectTypes,
    EntityListSelectColumn,
    SortDirection,
    IEntityListColumn,
} from '@mt-ng2/entity-list-module';
import { DatePipe } from '@angular/common';
import { ViewNoteComponent } from '../../common/components/notes-modal/view-note-component';
import { IEntityListConfigWithNotes } from '../../model/interfaces/custom/EntityListConfigWithNotes';
import { DboMessageService } from '../services/dbo-message.service';
import { ViewAuditComponent } from '../../common/components/view-audit-modal/view-audit-component';
import { IMessageColumn } from '../../model/interfaces/custom/IMessageColumn';
import { IDboMessageDTO } from '../../model/interfaces/custom/IDboMessageDTO';
import { RowExpanderComponent } from './row-expander.component';
import { IMessageRow } from './message-list-component';
import { TagOnMessageListComponent } from './tag-on-message-list.component';
import { ViewAssignedToComponent } from './view-assigned-to/view-assigned-to-component';
import { LockComponent } from './lock-component';
import { MessageState } from '../../common/constants/Enum';

export class MessagesEntityListConfig extends EntityListConfig {
    private static datePipe: DatePipe;

    constructor(messageService: DboMessageService, datePipe: DatePipe, columnVisibility: IMessageColumn[] = []) {
        MessagesEntityListConfig.datePipe = datePipe;
        const listConfig: IEntityListConfigWithNotes = {
            columns: [
                new EntityListColumn({
                    component: LockComponent,
                    fireOnColumnSelected: true,
                    name: '', // Expand
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    component: RowExpanderComponent,
                    fireOnColumnSelected: true,
                    name: '', // Expand
                    sort: { disableSort: true },
                }),
            ],
            isNoteList: true,
            noteService: messageService,
            rowClass: (entity: IMessageRow) => {
                const classes = ['message-list-row'];
                if (!entity.expanded) {
                    classes.push('message-list-row-collapsed');
                }

                return classes.join(' ');
            },
            select: new EntityListSelectColumn(SelectTypes.Multi, 40, '#FFC400', 'EmailId', null),
        };

        if (columnVisibility.length) {
            columnVisibility.forEach((c) => {
                if (c.IsVisible) {
                    if (messageColumnConfigDictionary[c.Name]) {
                        listConfig.columns.push(messageColumnConfigDictionary[c.Name] as IEntityListColumn);
                    }
                }
            });
        }
        super(listConfig);
    }

    static formattedDate(date: Date): string {
        return this.datePipe.transform(date, 'M/d/yy', 'UTC') + ' at ' + this.datePipe.transform(date, 'h:mm a', 'UTC');
    }
}
/* tslint:disable:object-literal-sort-keys */
export const messageColumnConfigDictionary = {
    EmailId: new EntityListColumn({
        accessors: ['EmailId'],
        fireOnColumnSelected: true,
        name: 'Email ID',
        sort: {
            defaultDirection: SortDirection.Desc,
            isDefaultForSort: true,
            sortProperty: 'EmailId',
        },
    }),
    TaskId: new EntityListColumn({
        accessorFunction: (entity: IDboMessageDTO) => {
            if (entity.TaskIds && entity.TaskIds.length) {
                if (entity.TaskIds.length === 1) {
                    return entity.TaskIds[0].toString();
                } else {
                    const sortedTaskIds = entity.TaskIds.sort((a, b) => a - b);
                    return `${sortedTaskIds[0]}-\n${sortedTaskIds[sortedTaskIds.length - 1]}`;
                }
            } else {
                return 'N/A';
            }
        },
        fireOnColumnSelected: true,
        name: 'Task IDs',
        sort: {
            disableSort: true,
        },
    }),
    From: new EntityListColumn({
        accessors: ['From'],
        columnClass: 'columnWordWrap fromColumn',
        fireOnColumnSelected: true,
        name: 'From',
        sort: {
            sortProperty: 'From',
        },
    }),
    Subject: new EntityListColumn({
        accessors: ['Subject'],
        columnClass: 'columnWordWrap subjectColumn',
        fireOnColumnSelected: true,
        name: 'Subject',
        sort: {
            sortProperty: 'Subject',
        },
    }),
    AssignedTo: new EntityListColumn({
        component: ViewAssignedToComponent,
        columnClass: 'columnNoWrapWord',
        fireOnColumnSelected: true,
        name: 'Assigned To',
        sort: {
            sortProperty: 'Assignee',
        },
    }),
    AssignedBy: new EntityListColumn({
        accessorFunction: (entity: IDboMessageDTO) => {
            if (entity.Assigner) {
                return `${entity.Assigner.FirstName} ${entity.Assigner.LastName}`;
            } else if (!entity.Assigner && entity.Assignee) {
                return 'System User';
            } else {
                return 'Unassigned';
            }
        },
        columnClass: 'columnNoWrapWord',
        fireOnColumnSelected: true,
        name: 'Assigned By',
        sort: {
            sortProperty: 'Assigner',
        },
    }),
    ReceivedDate: new EntityListColumn({
        accessorFunction: (entity: IDboMessageDTO) => {
            if (entity.ReceivedDate) {
                return MessagesEntityListConfig.formattedDate(entity.ReceivedDate);
            } else {
                return 'N/A';
            }
        },
        fireOnColumnSelected: true,
        name: 'Received Date',
        sort: {
            sortProperty: 'ReceivedDate',
        },
        style: { width: 90 },
    }),
    AttachmentCount: new EntityListColumn({
        accessors: ['AttachmentCount'],
        fireOnColumnSelected: true,
        name: 'Attachment Count',
        sort: {
            sortProperty: 'AttachmentCount',
        },
        style: { width: 50 },
    }),
    Forwarded: new EntityListColumn({
        accessorFunction: (entity: any) => {
            if (entity.WasForwarded) {
                return `<div><i class="fa fa-check"></i></div>`;
            } else {
                return '';
            }
        },
        bindToInnerHtml: true,
        fireOnColumnSelected: true,
        name: 'FWD:',
        sort: {
            sortProperty: 'Forwarded',
        },
        style: { width: 10 },
    }),
    Replied: new EntityListColumn({
        accessorFunction: (entity: any) => {
            if (entity.WasReplied) {
                return `<div><i class="fa fa-check"></i></div>`;
            } else {
                return '';
            }
        },
        bindToInnerHtml: true,
        fireOnColumnSelected: true,
        name: 'RE:',
        sort: {
            sortProperty: 'Replied',
        },
        style: { width: 10 },
    }),
    Tags: new EntityListColumn({
        component: TagOnMessageListComponent,
        fireOnColumnSelected: true,
        name: 'Tags',
        sort: {
            sortProperty: 'Tags',
        },
        style: { width: 100 },
    }),
    AutomationStatus: new EntityListColumn({
        accessorFunction: (entity: IDboMessageDTO) => {
            if (entity.AutomationStatus != null) {
                return `${entity.AutomationStatus.Name}`;
            } else {
                return 'No Status';
            }
        },
        fireOnColumnSelected: true,
        name: 'Automation',
        sort: {
            sortProperty: 'AutomationStatus',
        },
    }),
    Status: new EntityListColumn({
        accessorFunction: (entity: IDboMessageDTO) => {
            if (entity.MessageStateId != null) {
                switch (entity.MessageStateId) {
                    case MessageState.Ignored:
                        return 'N';
                    case MessageState.Processed:
                        return 'P';
                    case MessageState.PendingSort:
                        return 'U';
                    default:
                        return 'U';
                }
            } else {
                return 'U';
            }
        },
        fireOnColumnSelected: true,
        name: 'Status',
        sort: {
            sortProperty: 'MessageStatus',
        },
    }),
    LatestNote: new EntityListColumn({
        component: ViewNoteComponent,
        fireOnColumnSelected: true,
        name: 'Latest Note',
        sort: { sortProperty: 'Notes' },
        style: { width: 100 },
    }),
    AuditLog: new EntityListColumn({
        component: ViewAuditComponent,
        fireOnColumnSelected: true,
        name: 'Audit',
        sort: { disableSort: true },
        style: { width: 90 },
    }),
};
/* tslint:enable:object-literal-sort-keys */
