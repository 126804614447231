<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>Tax Positions</h2>
    </div>
</div>
<mt-search-bar (searched)="search($event)"></mt-search-bar>

<br />

<div class="row">
    <div class="col-md-6">
        <button type="button" class="btn btn-primary" (click)="export()">
            Export
        </button>
        <button type="button" class="btn btn-primary" (click)="uploadDeals()">
            Upload Deals
        </button>
    </div>
    <div class="col-md-6">
        <div class="pull-right">
            <button class="btn btn-transparent" type="button">
                <input type="checkbox" [(ngModel)]="includeArchived" (ngModelChange)="includeArchivedChanged($event)" />
                <span>Include Archived</span>
            </button>
        </div>
    </div>
</div>
<br />
<mt-entity-list
    [entities]="investments"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getInvestments()"
    (columnSortedEvent)="columnSorted($event)"
    (itemDeletedEvent)="toggleArchive($event)"
    (itemSelectedEvent)="investmentSelected($event)"
    [entityListConfig]="entityListConfig"
></mt-entity-list>

<div *ngIf="canAddInvestment" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/taxPositions', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
