import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import {
    IItemSelectedEvent,
    IColumnSortedEvent,
    SortDirection,
    EntityListExportService,
    EntityListExportConfig,
    IEntityListExportConfig,
    EntityListConfig,
} from '@mt-ng2/entity-list-module';
import { UserService } from '../user.service';
import { IUser } from '../../model/interfaces/user';
import { ClaimTypes } from '../../model/ClaimTypes';
import { UsersEntityListConfig } from './users.entity-list-config';
import { entityListModuleConfig } from '../../common/shared.module';
import { IEntitySearchParams } from '@mt-ng2/common-classes';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { ISearchFilterCheckboxValueChangedEvent } from '@mt-ng2/search-filter-checkbox-control';
import { forkJoin, from, Observable } from 'rxjs';
import { TenantService } from '../../tenants/tenant.service';
import { ITenant } from '../../model/interfaces/tenant';
import { UserRoleTypeService } from '../../user-roles/user-role-type.service';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IUsersPageColumns } from '../../model/interfaces/custom/IUsersPageColumns';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'app-users',
    styleUrls: ['./../../common/cdk-drag-drop-disables.css'],
    templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit {
    configureColumnsModalApi: IModalWrapperApi;
    searchControl = new UntypedFormControl();
    users: IUser[];
    tenants: MtSearchFilterItem[] = [];
    roleTypes: MtSearchFilterItem[] = [];
    includeInactive = false;
    currentPage = 1;
    query = '';
    total: number;
    canAddUser = false;
    canSeeAll: boolean;
    userPageColumns: IUsersPageColumns;
    columnList = [];
    entityListConfig = null;
    order: any;
    orderDirection: string;
    exportButton: IEntityListExportConfig;

    constructor(
        private userService: UserService,
        private claimsService: ClaimsService,
        private tenantService: TenantService,
        private roleTypeService: UserRoleTypeService,
        private exportService: EntityListExportService,
        private router: Router,
        public notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.canAddUser = this.claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess]);
        this.canSeeAll = this.claimsService.hasClaim(ClaimTypes.Users_SeeAll, [ClaimValues.FullAccess]);
        forkJoin(
            this.canSeeAll ? this.tenantService.getAll() : from<ITenant[][]>([[]]),
            this.roleTypeService.getAll(),
            this.userService.getUsersPageSavedColumns(),
        ).subscribe((data) => {
            const [tenantData, roleTypeData, userPageColumns] = data;
            this.tenants = tenantData.map((t) => new MtSearchFilterItem(t, false));
            this.roleTypes = roleTypeData.map((r) => new MtSearchFilterItem(r, false));
            this.userPageColumns = userPageColumns;
            this.entityListConfig = new UsersEntityListConfig(this.userPageColumns);
            this.entityListConfig.export = new EntityListExportConfig({
                exportName: 'Users List',
                getDataForExport: this.getUsersForExport.bind(this),
            });
            this.setColumnsList();
            this.order = this.entityListConfig.getDefaultSortProperty();
            this.orderDirection = this.entityListConfig.getDefaultSortDirection();
            this.getUsers();
        });
    }

    export(): void {
        this.getUsersForExport().subscribe((data) => {
            this.exportService.export(data, this.entityListConfig as EntityListConfig);
        });
    }

    getUsersForExport(): Observable<IUser[]> {
        return this.getUsersCall({ forExport: true }).pipe(map((answer) => answer.body));
    }
    getUsersCall(options = { forExport: false }): Observable<HttpResponse<IUser[]>> {
        const search = this.query;
        const extraParams = [
            new ExtraSearchParams({
                name: 'tenants',
                valueArray: this.getSelectedIds(this.tenants),
            }),
            new ExtraSearchParams({
                name: 'roleTypes',
                valueArray: this.getSelectedIds(this.roleTypes),
            }),
            new ExtraSearchParams({
                name: 'includeInactive',
                value: this.includeInactive ? 'true' : 'false',
            }),
        ];
        const searchEntity: IEntitySearchParams = {
            extraParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: options.forExport ? undefined : (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: options.forExport ? undefined : entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        return this.userService.get(searchparams);
    }

    getUsers(): void {
        this.getUsersCall().subscribe((answer) => {
            this.users = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.getUsers();
    }

    includeInactiveChanged(event: ISearchFilterCheckboxValueChangedEvent): void {
        this.includeInactive = event.value;
        this.getUsers();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getUsers();
    }

    userSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/users', event.entity.Id]);
    }

    private getSelectedIds(filterItems: MtSearchFilterItem[]): number[] {
        return filterItems.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    configureColumns(): void {
        this.configureColumnsModalApi.show();
    }

    drop(event: CdkDragDrop<string[]>): any {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    saveUserColumns(): void {
        const objToSave = {} as IUsersPageColumns;
        this.columnList.forEach((element) => {
            objToSave[element.name] = element.checked;
        });
        this.entityListConfig = new UsersEntityListConfig(objToSave);
        this.getUsers();
        this.userService.saveUsersPageColumns(objToSave).subscribe((response) => {
            this.notificationsService.success('Column Saved Successfully');
        });
    }

    setColumnsList(): void {
        // tslint:disable-next-line:forin
        for (const obj in this.userPageColumns) {
            this.columnList.push({ name: obj, checked: this.userPageColumns[obj], disabled: false });
        }
    }
}
