import { IDboTaskType } from './../model/interfaces/dbo-task-type.d';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaItemService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { ITaskTypeTier } from '../model/interfaces/task-type-tier';

@Injectable({
    providedIn: 'root',
})
export class TaskTypeService extends MetaItemService<IDboTaskType> {
    getEmptyTaskType(): IDboTaskType {
        return {
            Id: 0,
            IsArchived: false,
            Name: '',
            TaskTypeBackingId: 0,
        };
    }
    constructor(public http: HttpClient) {
        super('TaskTypeService', 'Type', 'TypeIds', '/taskTypes', http, null, { entityName: 'Task Types' });
    }

    toggleArchive(taskTypeId: number, archived: boolean): Observable<void> {
        return this.http.put<void>(`/taskTypes/archive/${taskTypeId}`, archived, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }
}
