import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { ICustomer } from '../../model/interfaces/customer';

export const emptyCustomer: ICustomer = {
    Id: 0,
    Name: null,
    SourceId: 0,
    StatusId: 0,
    Version: null,
};

@Injectable()
export class CustomerService extends BaseService<ICustomer> {
    constructor(public http: HttpClient) {
        super('/customers', http, null, { entityName: 'Customer' });
    }

    getEmptyCustomer(): ICustomer {
        return { ...emptyCustomer };
    }
}
