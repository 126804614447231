import { Component, EventEmitter, Output, OnInit, Input, Injector } from '@angular/core';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IEntityListConfig, IEntity } from '@mt-ng2/entity-list-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { MessageSearchService } from '../services/message-search.service';
import { IMessageRow } from '../message-list/message-list-component';
import { IMessageTag } from '../../model/interfaces/message-tag';
import { AssignTagUpdateService } from './assignTagUpdateService';
import { MessageTagService } from '../../message-tags/services/message-tag.service';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

@Component({
    selector: 'app-assignTag-modal',
    styles: [
        `
            .modalField {
                width: 40%;
                height: auto;
                display: flex;
                flex-wrap: nowrap;
                margin: auto;
                margin-bottom: 10px;
            }

            .modalField {
                text-align: left;
            }

            .modalInput {
                width: 40%;
            }
        `,
    ],
    templateUrl: './assignTagModal.html',
})
export class AssignTagModalComponent implements OnInit {
    modalOptions: IModalOptions = {
        showConfirmButton: false,
        width: '50%',
    };

    selectedMessages: IMessageRow[] = [];
    tags: MtSearchFilterItem[] = [];
    selectedTags: IMessageTag[] = [];
    entityListConfig: IEntityListConfig;
    doubleClickIsDisabled = false;
    isEditing = false;
    modalService: AssignTagUpdateService;
    messageTagService: MessageTagService;
    notificationService: NotificationsService;

    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSave: EventEmitter<void> = new EventEmitter<void>();

    //     constructor(  myroute: ActivatedRoute,  myrouter: Router,  injector: Injector,  notificationsService: NotificationsService, claimsService: ClaimsService, config: IEntityComponentsDocumentsModuleConfig) {
    //       super(myroute, myrouter, injector, notificationsService, claimsService, config);
    //    }

    ngOnInit(): void {}

    cancel(): void {
        this.onCancel.emit();
    }

    selectTags(event: any): void {
        this.selectedTags = event.selectedItems;
    }

    assignTags(): void {
        this.modalService.announceTags(this.selectedTags);
        this.cancel();
        this.deselectTags();
    }

    deselectTags(): void {
        this.tags.forEach((t) => (t.Selected = false));
    }
}
