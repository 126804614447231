import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IUserWithFullName } from '../../model/partials/user';
import { SharedModule } from '../../common/shared.module';
import { IMessageRow } from '../message-list/message-list-component';

@Injectable({
    providedIn: SharedModule,
})
export class AssignUserUpdateService {
    assignUserEvent = new Subject<IUserWithFullName>();
    selectMessagesEvent = new Subject<IMessageRow[]>();

    assignUserObservable = this.assignUserEvent.asObservable();
    selectMessageObservable = this.selectMessagesEvent.asObservable();

    announceSelectMessages(selectedMessages: IMessageRow[]): void {
        this.selectMessagesEvent.next(selectedMessages);
    }

    announceAssignUser(user: IUserWithFullName): void {
        this.assignUserEvent.next(user);
    }
}
