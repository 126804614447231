import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { EmailContactDynamicControls, IEmailContactDynamicControlsParameters } from '../model/form-controls/email-contact.form-controls';
import { IEmailContact } from '../model/interfaces/email-contact';

export class EmailContactDynamicConfig<T extends IEmailContact> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private emailContact: T, private configControls?: string[]) {
        super();
        const additionalParams: IEmailContactDynamicControlsParameters = {};
        const dynamicControls = new EmailContactDynamicControls(this.emailContact, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['FirstName', 'MiddleName', 'LastName', 'Email'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
