import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IAuthUser } from '../interfaces/auth-user';
import { IUserRole } from '../interfaces/user-role';
import { IUserRoleType } from '../interfaces/user-role-type';
import { ITenantAdSetting } from '../interfaces/tenant-ad-setting';
import { ITenant } from '../interfaces/tenant';
import { ITenantType } from '../interfaces/tenant-type';

export interface IAuthUserDynamicControlsParameters {
    formGroup?: string;
    roles?: IUserRole[];
    roleTypes?: IUserRoleType[];
    tenants?: ITenant[];
    tenantTypes?: ITenantType[];
    tenantAdSettings?: ITenantAdSetting[];
}

export class AuthUserDynamicControls {
    formGroup: string;
    roles: IUserRole[];
    roleTypes: IUserRoleType[];
    tenants: ITenant[];
    tenantTypes: ITenantType[];
    tenantAdSettings: ITenantAdSetting[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private authuser?: IAuthUser, additionalParameters?: IAuthUserDynamicControlsParameters) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'AuthUser';
        this.roles = (additionalParameters && additionalParameters.roles) || undefined;
        this.roleTypes = (additionalParameters && additionalParameters.roleTypes) || undefined;
        this.tenants = (additionalParameters && additionalParameters.tenants) || undefined;
        this.tenantTypes = (additionalParameters && additionalParameters.tenantTypes) || undefined;
        this.tenantAdSettings = (additionalParameters && additionalParameters.tenantAdSettings) || undefined;

        this.Form = {
            AdLoginUsername: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ad Login Username',
                name: 'AdLoginUsername',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(50)],
                validators: { maxlength: 50 },
                value:
                    this.authuser && this.authuser.hasOwnProperty('AdLoginUsername') && this.authuser.AdLoginUsername !== null
                        ? this.authuser.AdLoginUsername.toString()
                        : '',
            }),
            HasAccess: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Access',
                name: 'HasAccess',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value:
                    this.authuser && this.authuser.hasOwnProperty('HasAccess') && this.authuser.HasAccess !== null ? this.authuser.HasAccess : true,
            }),
            IsEditable: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Editable',
                name: 'IsEditable',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value:
                    this.authuser && this.authuser.hasOwnProperty('IsEditable') && this.authuser.IsEditable !== null
                        ? this.authuser.IsEditable
                        : true,
            }),
            Password: new DynamicField({
                formGroup: this.formGroup,
                label: 'Password',
                name: 'Password',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: { required: false },
                value:
                    this.authuser && this.authuser.hasOwnProperty('Password') && this.authuser.Password !== null
                        ? this.authuser.Password.toString()
                        : '',
            }),
            ResetKey: new DynamicField({
                formGroup: this.formGroup,
                label: 'Reset Key',
                name: 'ResetKey',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(64)],
                validators: { required: true, maxlength: 64 },
                value:
                    this.authuser && this.authuser.hasOwnProperty('ResetKey') && this.authuser.ResetKey !== null
                        ? this.authuser.ResetKey.toString()
                        : '',
            }),
            ResetKeyExpirationUtc: new DynamicField({
                formGroup: this.formGroup,
                label: 'Reset Key Expiration Utc',
                name: 'ResetKeyExpirationUtc',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.authuser && this.authuser.ResetKeyExpirationUtc) || null,
            }),
            RoleId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Role',
                name: 'RoleId',
                options: this.roles,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [noZeroRequiredValidator],
                validators: { required: true },
                value: (this.authuser && this.authuser.RoleId) || null,
            }),
            RoleTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Role Type',
                name: 'RoleTypeId',
                options: this.roleTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [noZeroRequiredValidator],
                validators: { required: true },
                value: (this.authuser && this.authuser.RoleTypeId) || null,
            }),
            Salt: new DynamicField({
                formGroup: this.formGroup,
                label: 'Salt',
                name: 'Salt',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(64)],
                validators: { required: true, maxlength: 64 },
                value: this.authuser && this.authuser.hasOwnProperty('Salt') && this.authuser.Salt !== null ? this.authuser.Salt.toString() : '',
            }),
            TenantAdSettingId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tenant Ad Setting',
                name: 'TenantAdSettingId',
                options: this.tenantAdSettings,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.authuser && this.authuser.TenantAdSettingId) || null,
            }),
            TenantId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tenant',
                name: 'TenantId',
                options: this.tenants,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [noZeroRequiredValidator],
                validators: { required: true },
                value: (this.authuser && this.authuser.TenantId) || null,
            }),
            TenantTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tenant Type',
                name: 'TenantTypeId',
                options: this.tenantTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [noZeroRequiredValidator],
                validators: { required: true },
                value: (this.authuser && this.authuser.TenantTypeId) || null,
            }),
            Username: new DynamicField({
                formGroup: this.formGroup,
                label: 'Username',
                name: 'Username',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(50)],
                validators: { required: true, maxlength: 50 },
                value:
                    this.authuser && this.authuser.hasOwnProperty('Username') && this.authuser.Username !== null
                        ? this.authuser.Username.toString()
                        : '',
            }),
        };

        this.View = {
            AdLoginUsername: new DynamicLabel({
			    label: 'Ad Login Username',
			    value: this.authuser && this.authuser.hasOwnProperty('AdLoginUsername') && this.authuser.AdLoginUsername !== null
			        ? this.authuser.AdLoginUsername.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            HasAccess: new DynamicLabel({
			    label: 'Has Access',
			    value: this.authuser && this.authuser.hasOwnProperty('HasAccess') && this.authuser.HasAccess !== null ? this.authuser.HasAccess : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsEditable: new DynamicLabel({
			    label: 'Is Editable',
			    value: this.authuser && this.authuser.hasOwnProperty('IsEditable') && this.authuser.IsEditable !== null ? this.authuser.IsEditable : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Password: new DynamicLabel({
			    label: 'Password',
			    value: this.authuser && this.authuser.hasOwnProperty('Password') && this.authuser.Password !== null ? this.authuser.Password.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Password,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ResetKey: new DynamicLabel({
			    label: 'Reset Key',
			    value: this.authuser && this.authuser.hasOwnProperty('ResetKey') && this.authuser.ResetKey !== null ? this.authuser.ResetKey.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ResetKeyExpirationUtc: new DynamicLabel({
			    label: 'Reset Key Expiration Utc',
			    value: (this.authuser && this.authuser.ResetKeyExpirationUtc) || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            RoleId: new DynamicLabel({
			    label: 'Role',
			    value: getMetaItemValue(this.roles as unknown as IMetaItem[], this.authuser && this.authuser.hasOwnProperty('RoleId') && this.authuser.RoleId !== null ? this.authuser.RoleId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            RoleTypeId: new DynamicLabel({
			    label: 'Role Type',
			    value: getMetaItemValue(this.roleTypes as unknown as IMetaItem[], this.authuser && this.authuser.hasOwnProperty('RoleTypeId') && this.authuser.RoleTypeId !== null
			        ? this.authuser.RoleTypeId
			        : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Salt: new DynamicLabel({
			    label: 'Salt',
			    value: this.authuser && this.authuser.hasOwnProperty('Salt') && this.authuser.Salt !== null ? this.authuser.Salt.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            TenantAdSettingId: new DynamicLabel({
			    label: 'Tenant Ad Setting',
			    value: getMetaItemValue(this.tenantAdSettings as unknown as IMetaItem[], this.authuser && this.authuser.hasOwnProperty('TenantAdSettingId') && this.authuser.TenantAdSettingId !== null
			        ? this.authuser.TenantAdSettingId
			        : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            TenantId: new DynamicLabel({
			    label: 'Tenant',
			    value: getMetaItemValue(this.tenants as unknown as IMetaItem[], this.authuser && this.authuser.hasOwnProperty('TenantId') && this.authuser.TenantId !== null ? this.authuser.TenantId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            TenantTypeId: new DynamicLabel({
			    label: 'Tenant Type',
			    value: getMetaItemValue(this.tenantTypes as unknown as IMetaItem[], this.authuser && this.authuser.hasOwnProperty('TenantTypeId') && this.authuser.TenantTypeId !== null
			        ? this.authuser.TenantTypeId
			        : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Username: new DynamicLabel({
			    label: 'Username',
			    value: this.authuser && this.authuser.hasOwnProperty('Username') && this.authuser.Username !== null ? this.authuser.Username.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };
    }
}
