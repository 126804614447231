<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>Clients</h2>
    </div>
</div>
<mt-search-bar (searched)="search($event)"></mt-search-bar>
<br />

<div class="row">
    <div class="col-md-6">
        <mt-search-filter-select [items]="tenants" entity="Tenants" (selectionChanged)="filterByTenants($event)"> </mt-search-filter-select>
        <button type="button" class="btn btn-primary" (click)="showAssignTenantsDialog()" [disabled]="!hasFullAccess">
            Assign
        </button>
        <button type="button" class="btn btn-primary" (click)="export()">
            Export
        </button>
    </div>
    <div class="col-md-2">
        <mt-search-filter-select [items]="archiveFilterStatus" (selectionChanged)="includeArchivedClients($event)" entity="Archive Status">
        </mt-search-filter-select>
    </div>
    <div class="col-md-2">
        <mt-search-filter-checkbox
            text="Only Unassigned"
            [value]="onlyUnassigned"
            (searchCheckboxChanged)="setOnlyUnassigned($event)"
        ></mt-search-filter-checkbox>
    </div>
    <div class="col-md-2">
        <button class="btn btn-transparent" type="button">
            <input type="checkbox" [(ngModel)]="onlyInTransit" (ngModelChange)="setOnlyInTransit($event)" />
            <span>Only In Transition</span>
        </button>
    </div>
</div>
<br />
<mt-entity-list
    [entities]="clients"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getClients()"
    (itemDeletedEvent)="toggleArchive($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
    (selectionChangedEvent)="onSelectionChanged($event)"
></mt-entity-list>

<mt-modal-wrapper
    [autoShow]="false"
    [showActions]="false"
    [options]="{ showCancelButton: false, showConfirmButton: false, width: 700 }"
    (ready)="assignModalApi = $event"
>
    <div *ngIf="selectedTenants && selectedTenants.length > 0 && HLSelected">
        <div *ngIf="mappedClients && mappedClients.length > 0">
            <h4>
                <b
                    >Tenant(s) <span *ngFor="let tenant of selectedTenants; let last = last">{{ tenant.Name }}{{ last ? '' : ', ' }}</span> will be
                    assigned to the following Clients</b
                >
            </h4>
            <div *ngFor="let client of mappedClients">
                <h5>{{ client.Name }}</h5>
            </div>
            <br />
        </div>
        <div *ngIf="tenantsExcludingHL && tenantsExcludingHL.length > 0 && unmappedClients.length > 0">
            <h4>
                <b
                    >Tenant's <span *ngFor="let tenant of tenantsExcludingHL; let last = last">{{ tenant.Name }}{{ last ? '' : ', ' }}</span> will be
                    assigned to the following Clients</b
                >
            </h4>
            <div *ngFor="let client of unmappedClients">
                <h5>{{ client.Name }}</h5>
            </div>
            <br />
        </div>
        <div *ngIf="HLTenant && unmappedClients && unmappedClients.length > 0">
            <h4>
                <b>Can not assign Tenant {{ HLTenant.Name }} to the following clients, Missing Mappings</b>
            </h4>
            <div *ngFor="let client of unmappedClients">
                <h5>{{ client.Name }}</h5>
            </div>
        </div>
        <br />
    </div>
    <div *ngIf="selectedTenants && selectedTenants.length == 0">
        <h4><b>All Tenants will be removed from the following Clients</b></h4>
        <div *ngFor="let client of selectedClients">
            <h5>{{ client.Name }}</h5>
        </div>
        <br />
    </div>
    <div *ngIf="selectedTenants && selectedTenants.length > 0 && !HLSelected">
        <h4>
            <b
                >Tenant(s) <span *ngFor="let tenant of selectedTenants; let last = last">{{ tenant.Name }}{{ last ? '' : ', ' }}</span> will be
                assigned to the following Clients</b
            >
        </h4>
        <div *ngFor="let client of selectedClients">
            <h5>{{ client.Name }}</h5>
        </div>
    </div>
    <div>
        <button type="button" (click)="assignTenants()" Class="btn btn-primary" *ngIf="checkValidClients()">
            Assign
        </button>
        <button type="button" Class="btn btn-default" (click)="closeSwal()">
            Cancel
        </button>
    </div>
</mt-modal-wrapper>
