import { Observable } from 'rxjs';
import { IMessageSearchDTO } from '../../model/interfaces/custom/IMessageSearchDTO';
import { IDboMessageSearch } from '../../model/interfaces/dbo-message-search';
import { BaseService } from '@mt-ng2/base-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { IMessageSearchObject } from '../../model/partials/message.search';

const emptySearchObject: IMessageSearchDTO = {
    CreatedDate: null,
    Id: 0,
    LastAccessedDate: null,
    Name: 'Add new search',
    newMessages: 0,
    SearchModel: null,
    searchObject: null,
    UserId: 0,
    VersionId: 2,
};

@Injectable({
    providedIn: 'root',
})
export class MessageSearchService extends BaseService<IDboMessageSearch> {
    constructor(public http: HttpClient) {
        super('/messageSearch', http);
    }

    getEmptySearchObject(): IMessageSearchDTO {
        return { ...emptySearchObject };
    }

    saveSearch(obj: IMessageSearchObject, searchId: number): Observable<number> {
        return this.http.post<number>(`/messageSearch/saveMessageSearch/${searchId}`, obj);
    }

    saveOrgSearch(obj: IMessageSearchObject, searchId: number, tenantId: number): Observable<number> {
        return this.http.post<number>(`/messageOrgSearch/saveMessageOrgSearch/${tenantId}/${searchId}`, obj);
    }

    getSavedSearchForUser(): Observable<HttpResponse<IMessageSearchDTO[]>> {
        return this.http.get<IMessageSearchDTO[]>(`/messageSearch/getSavedSearch`, { observe: 'response' });
    }

    getSavedSearchForTenant(tenantId: number): Observable<HttpResponse<IMessageSearchDTO[]>> {
        return this.http.get<IMessageSearchDTO[]>(`/messageOrgSearch/getSavedSearch/${tenantId}`, { observe: 'response' });
    }

    deleteSearch(searchId: number): Observable<void> {
        return this.http.delete<void>(`/messageSearch/deleteMessageSearch/${searchId}`);
    }

    deleteOrgSearch(searchId: number): Observable<void> {
        return this.http.delete<void>(`/messageOrgSearch/deleteMessageSearch/${searchId}`);
    }
}
