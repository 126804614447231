import {
    Component,
    Input,
    OnInit,
    OnDestroy,
    Output,
    EventEmitter,
    ViewChild,
    ViewChildren,
    QueryList,
    ElementRef,
    HostListener,
} from '@angular/core';
import { IVwFund } from '../../model/interfaces/vw-fund';
import { IDboInvestment } from '../../model/interfaces/dbo-investment';
import { IDboTaskType } from '../../model/interfaces/dbo-task-type';
import { IMessageWithAttachments } from '../../model/interfaces/custom/messageWithAttachment';
import { FundService } from '../../funds/fund.service';
import { InvestmentService } from '../../investments/investment-service';
import { TaskTypeService } from '../../tasks/task-doc-type-service';
import { ClientService } from '../../clients/client.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { IVwClient } from '../../model/interfaces/vw-client';
import { Router } from '@angular/router';
import { DboMessageService } from '../../messages/services/dbo-message.service';
import { IDboVwMessageWithAttachment } from '../../model/interfaces/dbo-vw-message-with-attachment';
import { DboMessageDocumentService } from '../../messages/services/dbo-message-document-service';
import { IAttachmentActionType } from '../../model/interfaces/attachment-action-type';
import { AttachmentActionTypeService } from '../attachment-action-type.service';
import { AttachmentMetadataService, IAttachmentWithMetadata, IAttachmentWithMetadataDTO } from '../attachment-meta-data.service';
import { IDboMessageDTO } from '../../model/interfaces/custom/IDboMessageDTO';
import { ProcessAttachmentUpdateService } from '../processAttachment-add-modal/processAttachmentUpdateService';
import { DboFundAlternativeNameService } from '../../funds/dbo-fund-alternative-name.service';
import { IVwFundWithAltName } from '../../model/interfaces/custom/IVwFundWithAltName';
import { AttachmentState, LocalStorageKeys, MessageState } from '../../common/constants/Enum';
import { AuthService } from '@mt-ng2/auth-module';
import { ProcessAttachmentComponent } from '../processAttachment/processAttachment.component';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { TaskService } from '../../tasks/task.service';
import { IDboVFundAltName } from '../../model/interfaces/dbo-v-fund-alt-name';
import { AttachmentProcessingService } from '../../common/services/attachment-processing-service';
import { TabCountService } from '../../common/services/tab-count-service';
import { LocalStorageService } from '../../common/services/local-storage-service';
import { IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
    selector: 'app-process-attachment-list',
    styleUrls: ['processAttachment-list.styles.less'],
    templateUrl: './processAttachment-list.component.html',
})
export class ProcessAttachmentListComponent implements OnInit, OnDestroy {
    messagesWithAttachments: IAttachmentWithMetadata[] = [];
    funds: IVwFundWithAltName[] = [];
    fundAltNames: IDboVFundAltName[] = [];
    investments: IDboInvestment[] = [];
    docTypes: IDboTaskType[] = [];
    clients: IVwClient[] = [];
    subscription = new Subscription();
    selectedMessageIds: number[] = [];
    loggedInUserId: number;
    taskProcessSubscription = new Subscription();
    isSubmitButtonActive = false;
    scrolled = true;
    attachmentsCheckLocked = false;

    // localStorageChanges$ = this.localStorageService.changes$;

    @HostListener('window:beforeunload') onBeforeUnload(): void {
        this.attachmentProcessingService.cleanProcessingTabLocalStorage();
        this.messageService.processingLock(this.selectedMessageIds, false, 0).subscribe();
    }

    constructor(
        private messageService: DboMessageService,
        private fundService: FundService,
        private investmentService: InvestmentService,
        private docTypeService: TaskTypeService,
        private clientService: ClientService,
        private metadataService: AttachmentMetadataService,
        private modalService: ProcessAttachmentUpdateService,
        private fundAltNameService: DboFundAlternativeNameService,
        private router: Router,
        private messageDocumentService: DboMessageDocumentService,
        private userService: AuthService,
        private notificationService: NotificationsService,
        private taskService: TaskService,
        private attachmentProcessingService: AttachmentProcessingService,
        private tabCountService: TabCountService,
        private localStorageService: LocalStorageService,
    ) {}
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.taskProcessSubscription.unsubscribe();
        this.attachmentProcessingService.cleanProcessingLocalStorage();
        this.messageService.processingLock(this.selectedMessageIds, false, 0).subscribe();
        // window.removeEventListener('storage', this._storageUpdateListener, false);
    }

    ngOnInit(): void {
        this.selectedMessageIds = this.attachmentProcessingService.messageIds;
        const isAlreadyProcessWindow = this.attachmentProcessingService.isProcessingTabOpen === true ? true : false;
        const searchEntity: IEntitySearchParams = {
            query: '',
            skip: 0,
            take: 30,
        };
        const searchparams = new SearchParams(searchEntity);
        if (isAlreadyProcessWindow) {
            this.notificationService.warning('Only one instance of Processing Attachments page may be open at a time.');
            void this.router.navigate(['/inbox'], { state: { processingWindowStillOpen: true } });
        } else {
            this.attachmentProcessingService.setIsProcessingTabOpen = true;
            if (!this.isMessageIdsEmpty()) {
                this.messageService.getDocumentsWithMessage(this.selectedMessageIds).subscribe((msg) => {
                    this.messagesWithAttachments = msg;
                    forkJoin(
                        this.fundService.getAll(),
                        this.investmentService.getAll(),
                        this.docTypeService.getAll(),
                        this.clientService.getAll(),
                        this.fundService.getAltNames(0),
                    ).subscribe((data) => {
                        const [funds, investments, docTypes, clients, altFundNames] = data;
                        this.funds = funds;
                        this.investments = investments.filter((i) => !i.IsArchived);
                        this.docTypes = docTypes.filter((d) => !d.IsArchived);
                        this.clients = clients;
                        this.clients.forEach((c) => (c.Name = `${c.Name} - (${c.ClientType}) - (${c.ClientTypeValue})`));
                        this.fundAltNames = altFundNames;

                        const altNamesAsVwFund: IVwFundWithAltName[] = this.fundAltNames.map((an) => ({
                            FundMgrId: 0,
                            HasAltNames: false,
                            Id: an.FundId,
                            isAltName: true,
                            IsArchived: false,
                            Name: `${an.AlternativeFundName} (${an.OriginalName})`,
                        }));
                        this.funds = [...funds, ...altNamesAsVwFund];
                        this.userService.currentUser.subscribe((data) => (this.loggedInUserId = data.AuthId));
                    });
                });

                this.subscription.add(
                    this.modalService.refreshAttachmentObservable.subscribe((data) => {
                        this.appendAttachmentToList(data);
                    }),
                );

                this.taskProcessSubscription.add(
                    this.metadataService.announceTaskProcessObservable.subscribe((data) => {
                        if (!this.attachmentsCheckLocked) {
                            this.checkNumberProcessed();
                        }
                    }),
                );
            }
        }

        this.attachmentProcessingService.messageIdChanges$.asObservable().subscribe((data) => {
            const newIds: number[] = data?.value?.filter((e) => !this.selectedMessageIds.some((sm) => sm === e));
            if (newIds?.length) {
                this.messageService.getDocumentsWithMessage(newIds).subscribe((data) => {
                    const newMessages = data;
                    this.messagesWithAttachments = [...this.messagesWithAttachments, ...data];
                    this.messagesWithAttachments.sort((a, b) => a.MessageId - b.MessageId);
                });
            }
        });
    }

    updateMessageWithAttachments(value: IAttachmentWithMetadata[]): void {
        this.messagesWithAttachments = [];
        setTimeout(() => {
            this.messagesWithAttachments = [...value];
        }, 100);
    }

    isMessageIdsEmpty(): boolean {
        if (!this.selectedMessageIds || (!this.selectedMessageIds.length && !this.messagesWithAttachments.length)) {
            this.notificationService.warning('No messages selected, returning to inbox.');
            void this.router.navigate(['/inbox']);
            return true;
        } else {
            return false;
        }
    }

    appendAttachmentToList(attachment: IAttachmentWithMetadata): void {
        const indexToSpliceInAt = this.messagesWithAttachments.map((e) => e.MessageId).lastIndexOf(attachment.MessageId);
        attachment.Number = this.messagesWithAttachments[indexToSpliceInAt].Number + 1;
        this.messagesWithAttachments.splice(indexToSpliceInAt + 1, 0, attachment);
    }

    ignoreChanged(event: any, attachment: IAttachmentWithMetadata): void {
        if (event) {
            this.messageDocumentService
                .setDocumentState(attachment.MessageId, attachment.AttachmentId, AttachmentState.Ignored, this.loggedInUserId)
                .subscribe(() => {
                    this.checkNumberProcessed();
                });
        } else {
            this.messageDocumentService
                .setDocumentState(attachment.MessageId, attachment.AttachmentId, AttachmentState.NotYetDispatched, this.loggedInUserId)
                .subscribe(() => {});
        }
    }

    setHref(attachment: IAttachmentWithMetadata): string {
        return this.messageDocumentService.getDocumentPath(attachment.MessageId, attachment.AttachmentId, true);
    }

    checkNumberProcessed(): void {
        const attachmentsRemainingToBeProcessed = this.messagesWithAttachments.filter((a) => !a.IsProcessed && !a.IsIgnored);
        if (attachmentsRemainingToBeProcessed.length <= 0) {
            this.markMessagesProcessed([], true);
        } else {
            const readyToProcess = this.messagesWithAttachments.filter((b) => !b.IsIgnored && !b.IsProcessed && !b.IsPending);
            if (readyToProcess.length <= 0) {
                this.isSubmitButtonActive = true;
            } else {
                this.isSubmitButtonActive = false;
            }
        }
    }

    getMessageIdWithoutAttachments(pendingAttachments: IAttachmentWithMetadataDTO[]): number[] {
        const messageIdsWithoutAttachments = [];
        this.selectedMessageIds.forEach((selectedMessageId) => {
            const doesSelectedMessageIdHaveAttachments = pendingAttachments.some((pd) => pd.attachment.MessageId === selectedMessageId);
            if (!doesSelectedMessageIdHaveAttachments) {
                messageIdsWithoutAttachments.push(selectedMessageId);
            }
        });
        return messageIdsWithoutAttachments;
    }

    submitAllAttachments(): void {
        const allMessageIds = this.messagesWithAttachments.map((m) => m.MessageId);
        const processing = this.messagesWithAttachments.filter((a) => a.IsPending);
        const processingAttachmentIds = processing.map((x) => x.AttachmentId);
        const pendingAttachments: IAttachmentWithMetadataDTO[] = processing.map((a: IAttachmentWithMetadata) => {
            const attachmentMetadataPackage: IAttachmentWithMetadataDTO = {
                attachment: {
                    AttachmentFileName: a.AttachmentFileName,
                    AttachmentId: a.AttachmentId,
                    AttachmentName: a.AttachmentName,
                    ContentType: a.ContentType,
                    MessageId: a.MessageId,
                    Name: a.Name,
                    ReceivedDate: a.ReceivedDate,
                    SenderName: a.SenderName,
                    Subject: a.Subject,
                },
                authUserID: this.loggedInUserId,
                metadata: a.metadata,
            };
            return attachmentMetadataPackage;
        });

        const messageIdsWithoutAttachments = this.getMessageIdWithoutAttachments(pendingAttachments);
        const obj = { pendingAttachments, messageIdsWithoutAttachments };
        this.taskService.CreateTasksReturnAlreadyProcessedMessages(obj, this.loggedInUserId).subscribe(
            (result) => {
                if (result.FailedToProcessAttachmentIds.length) {
                    this.messagesWithAttachments = this.messagesWithAttachments.map((x) => {
                        const wasProcessed =
                            processingAttachmentIds.includes(x.AttachmentId) && !result.FailedToProcessAttachmentIds.includes(x.AttachmentId);
                        return {
                            ...x,
                            IsPending: wasProcessed ? false : x.IsPending,
                            IsProcessed: wasProcessed ? true : x.IsProcessed,
                        };
                    });
                    this.notificationService.warning('Some attachments failed to process. Please try again.');
                } else {
                    if (result.AlreadyProcessedMessageIds.length <= 0) {
                        this.notificationService.success(`All Attachments have already been processed. Message(s) Marked 'Processed'`);
                    } else {
                        this.notificationService.warning(
                            `Attachments from Message${
                                result.AlreadyProcessedMessageIds.length > 1 ? 's' : ''
                            } ${result.AlreadyProcessedMessageIds.join(
                                ',',
                            )} couldn't be turned into tasks because associated message has already been processed.`,
                        );
                    }
                    setTimeout(() => window.close(), 2000);
                }
            },
            (err: HttpErrorResponse) => {
                this.notificationService.error(`Failed to process all attachments. Please try again. ${err.error as string}`);
            },
        );
    }

    markMessagesProcessed(removedIds: number[] = [], notifyAllAttachmentsProcessed = false): void {
        this.attachmentsCheckLocked = true;
        this.selectedMessageIds.forEach((id) => {
            this.messageService.setMessageState([id], MessageState.Processed, this.loggedInUserId).subscribe(() => {
                this.messageService.processingLock(this.selectedMessageIds, false, 0).subscribe(() => {
                    if (this.tabCountService.getTabCount > 1) {
                        if (removedIds.length) {
                            this.notificationService.warning(
                                `Attachments from Message ${removedIds.length > 1 ? 's' : ''} ${removedIds.join(
                                    ',',
                                )} couldn't be turned into tasks because associated message has already been processed.`,
                            );
                            setTimeout(() => window.close(), 2000);
                        }
                        if (notifyAllAttachmentsProcessed) {
                            this.notificationService.success(`All Attachments have already been processed. Message(s) Marked 'Processed'`);
                            setTimeout(() => window.close(), 2000);
                        } else {
                            window.close();
                        }
                    } else {
                        void this.router.navigate(['/inbox']);
                        if (removedIds.length) {
                            this.notificationService.warning(
                                `Attachments from Message ${removedIds.length > 1 ? 's' : ''} ${removedIds.join(
                                    ',',
                                )} couldn't be turned into tasks because associated message has already been processed.`,
                            );
                        }
                        if (notifyAllAttachmentsProcessed) {
                            this.notificationService.success(`All Attachments have already been processed. Message(s) Marked 'Processed'`);
                        }
                    }
                });
            });
        });
    }
}
