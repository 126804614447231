import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';

import { ManagedListComponent } from '@mt-ng2/managed-list-control';
import { DynamicField, DynamicFieldTypes, IDynamicField, LabelPosition, LabelPositions } from '@mt-ng2/dynamic-form';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

export interface IColumnData {
    field: string;
    width: string;
}

@Component({
    selector: 'app-editable-list',
    styleUrls: ['editable-list.less'],
    templateUrl: 'editable-list.component.html',
})
export class EditableListComponent extends ManagedListComponent implements OnInit {
    columns: IColumnData[];
    @Input('columns')
    set setColumns(columns: IColumnData[]) {
        this.columns = columns;
        this.fields = this.columns.map((column: IColumnData) => column.field);
    }

    constructor(private cdr: ChangeDetectorRef, fb: UntypedFormBuilder) {
        super(fb);
    }

    create(): void {
        super.create();
        this.cdr.detectChanges();
    }

    colWidth(fieldName): string {
        if (this.columns) {
            return this.columns.find((column: IColumnData) => column.field === fieldName).width;
        }
        return `${Math.floor(97 / this.fields.length)}%`;
    }

    getField(form: UntypedFormGroup, fieldName: string): DynamicField {
        const fieldToCopy = super.getField(form, fieldName);
        return new DynamicField({
            ...fieldToCopy,
            label: fieldToCopy.type.fieldType === DynamicFieldTypes.Checkbox ? '' : fieldToCopy.label,
        });
    }
}
