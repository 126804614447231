import { IDboSupportingDocument } from './../model/interfaces/dbo-supporting-document.d';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IHasDocuments } from '@mt-ng2/entity-components-documents';
import { ClaimTypes } from '../model/ClaimTypes';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { IBlobDownload } from '../model/interfaces/custom/blob-download';
import { CommonService } from '../common/services/common.service';
import { EnvironmentService } from '@mt-ng2/environment-module';

@Injectable({
    providedIn: 'root',
})
export class TaskDocumentsService extends BaseService<IDboSupportingDocument> {
    constructor(
        public http: HttpClient,
        public claimsService: ClaimsService,
        private commonService: CommonService,
        private envSvc: EnvironmentService,
    ) {
        super('/tasks', http);
    }

    getDocuments(Id: number, searchparameters: SearchParams): Observable<HttpResponse<IDboSupportingDocument[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IDboSupportingDocument[]>(`/tasks/${Id}/documents/getTaskDocuments`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError(void this.handleError));
    }

    saveDocument(taskId: number, file: File): any {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post(`/tasks/${taskId}/documents`, formData);
    }

    saveTasksDocument(taskIds: number[], file: File): any {
        const formData = new FormData();
        formData.append('File', file , file.name);
        taskIds.forEach((id) => {
            formData.append('TaskIds', id.toString());
        });
        return this.http.post(`/tasks/${taskIds[0]}/documents/saveSupportingDoc`, formData);
    }

    deleteDocument(taskId: number, docId: number): Observable<object> {
        return this.http.delete(`/tasks/${taskId}/documents/${docId}`, {
            responseType: 'text' as 'json',
        });
    }

    getDocumentPath(taskId: number, docId: number, fullPath = false): string {
        const basePath = fullPath ? `${this.envSvc.config.baseApiUrl}/api/v1` : '';
        return `${basePath}/tasks/${taskId}/documents/downloadSupportingDoc/${docId}`;
    }

    getOrigDocumentPath(taskId: number, fullPath = false): string {
        const basePath = fullPath ? `${this.envSvc.config.baseApiUrl}/api/v1` : '';
        return `${basePath}/tasks/${taskId}/documents/getOrigDocumentPath`;
    }

    getDocument(taskId: number, docId: number): Observable<IBlobDownload> {
        return this.http
            .get(this.getDocumentPath(taskId, docId), {
                observe: 'response',
                responseType: 'blob',
            })
            .pipe(map(this.commonService.mapBlobDownload));
    }

    downloadDocuments(taskId: number, docIds: number[], includeOriginalDoc: boolean): Observable<IBlobDownload> {
        return this.http
            .post(`/tasks/${taskId}/documents/${includeOriginalDoc.toString()}/downloadMultipleDocs`, docIds, {
                observe: 'response',
                responseType: 'blob',
            })
            .pipe(map(this.commonService.mapBlobDownload));
    }
}
