import { ITaskColumns } from './../model/interfaces/custom/ITaskColumns.d';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { IUser } from '../model/interfaces/user';
import { IPhone } from '../model/interfaces/base';
import { ICreateUserPayload } from '../model/interfaces/custom/create-user-payload';
import { IAddress } from '@mt-ng2/dynamic-form';
import { catchError } from 'rxjs/operators';
import { ITenant } from '../model/interfaces/tenant';
import { IUsersPageColumns } from '../model/interfaces/custom/IUsersPageColumns.d';
import { IMessageColumn } from '../model/interfaces/custom/IMessageColumn';
import { IUserSetup } from '../model/interfaces/custom/IUserSetup';

@Injectable()
export class UserService extends BaseService<IUser> {
    private emptyUser: IUser = {
        AuthUserId: 0,
        Email: null,
        FirstName: null,
        Id: 0,
        LastName: null,
        Version: null,
    };

    constructor(public http: HttpClient) {
        super('/users', http,null, { entityName: 'User' });
    }

    formatTitleText(user: IUser): void {
        this.setTitle(`User: ${user.FirstName} ${user.LastName}`);
    }

    getEmptyUser(): IUser {
        return { ...this.emptyUser };
    }

    createUser(data: ICreateUserPayload): Observable<number> {
        return this.http.post<number>(`/users/create`, data);
    }

    saveAddress(userId: number, address: IAddress): Observable<number> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<number>(`/users/${userId}/address`, address);
        } else {
            return this.http.put<number>(`/users/${userId}/address`, address);
        }
    }

    deleteAddress(userId: number): Observable<object> {
        return this.http.delete(`/users/${userId}/address`, {
            responseType: 'text' as 'json',
        });
    }

    savePhones(userId: number, phones: IPhone): any {
        return this.http.put<number>(`/users/${userId}/phones`, phones);
    }

    savePhoto(userId: number, photo: File): any {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);

        return this.http.post(`/users/${userId}/pic`, formData);
    }

    deletePhoto(userId: number): any {
        return this.http.delete(`/users/${userId}/pic`);
    }

    getTenantForUser(userId: number): Observable<ITenant> {
        return this.http.get<ITenant>(`/users/${userId}/tenant`).pipe(catchError(void this.handleError));
    }

    getUserSavedTaskColumns(): Observable<ITaskColumns> {
        return this.http.get<ITaskColumns>(`/users/getUserTaskColumnConfigs`);
    }

    getUserDefaultTaskColumns(): Observable<ITaskColumns> {
        return this.http.get<ITaskColumns>(`/users/getUserDefaultTaskColumnConfigs`);
    }

    saveUserTaskColumns(obj: ITaskColumns): any {
        return this.http.post(`/users/saveUserTaskColumns`, obj, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }

    getUserSavedMessageColumns(): Observable<IMessageColumn[]> {
        return this.http.get<IMessageColumn[]>(`/users/getUserMessageColumnConfigs`);
    }

    saveUserMessageColumns(obj: IMessageColumn[]): any {
        return this.http.post(`/users/saveUserMessageColumns`, obj, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }

    getUsersPageSavedColumns(): Observable<IUsersPageColumns> {
        return this.http.get<IUsersPageColumns>(`/users/getUsersPageColumnConfigs`);
    }

    saveUsersPageColumns(obj: IUsersPageColumns): any {
        return this.http.post(`/users/saveUsersPageColumns`, obj, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }

    setupNewUser(formData): Observable<object> {
        return this.http.post<IUserSetup>(`/users/setupNewUser`, formData);
    }

    searchNameList<T extends { FirstName?: string; LastName?: string; FullName?: string }>(
        list: T[],
        term: string | null,
        minTermLength: number,
    ): T[] {
        if ((term ? term.length : 0) < minTermLength) {
            return [];
        }
        if (!term) {
            return [...list];
        }
        const lower = term.toLowerCase();
        list.map((e: T) => (e.FullName = e.FirstName + ' ' + e.LastName));
        return list.filter((e: T) => e && e.FirstName && (e.FirstName + ' ' + e.LastName).toLowerCase().includes(lower));
    }
}
