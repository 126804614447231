import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { ICustomer } from '../../model/interfaces/customer';
import { CustomerDynamicConfig } from '../customer.dynamic-config';
import { CustomerService } from '../services/customer.service';
import { CustomerStatusService } from '../services/customerstatus.service';
import { CustomerSourceService } from '../services/customersource.service';
import { ICustomerSource } from '../../model/interfaces/customer-source';
import { ICustomerStatus } from '../../model/interfaces/customer-status';

@Component({
    selector: 'app-customer-basic-info',
    templateUrl: './customer-basic-info.component.html',
})
export class CustomerBasicInfoComponent implements OnInit {
    @Input() customer: ICustomer;
    @Input() canEdit: boolean;

    isEditing = false;
    isHovered: boolean;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: CustomerDynamicConfig<ICustomer>;
    doubleClickIsDisabled = false;

    sources: ICustomerSource[];
    statuses: ICustomerStatus[];

    get isNewCustomer(): boolean {
        return this.customer && this.customer.Id ? false : true;
    }

    constructor(
        private customerService: CustomerService,
        private customerStatusService: CustomerStatusService,
        private customerSourceService: CustomerSourceService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        forkJoin(this.customerSourceService.getItems(), this.customerStatusService.getItems()).subscribe((answers) => {
            const [sources, statuses] = answers;
            this.sources = sources;
            this.statuses = statuses;
            this.setConfig();
        });
    }

    setConfig(): void {
        this.formFactory = new CustomerDynamicConfig<ICustomer>(this.customer, this.sources, this.statuses);

        if (this.isNewCustomer) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewCustomer) {
            void this.router.navigate(['/customers']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.customer, form.value.Customer as ICustomer);
            this.saveCustomer();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    private saveCustomer(): void {
        if (this.isNewCustomer) {
            this.customerService
                .create(this.customer)                .subscribe((answer) => {
                    this.customer.Id = answer;
                    this.success(true);
                });
        } else {
            this.customerService
                .updateVersion(this.customer)                .subscribe((success) => {
                    this.customer.Version = success;
                    this.success();
                });
        }
    }

    private success(newCustomerSave?: boolean): void {
        if (newCustomerSave) {
            void this.router.navigate([`/customers/${this.customer.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.customerService.emitChange(this.customer);
        this.notificationsService.success('Customer saved successfully.');
    }
}
