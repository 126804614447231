import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { FundRoutingModule } from './fund-routing.module';
import { FundsComponent } from './fund-list/funds.component';
import { AlternativeFundNameDetailComponent } from './funds-detail/fund-detail.component';
import { ViewFundAltNamesComponent } from './fund-alt-names-modal/fund-viewAltNamesCell';
import { FundAltNameModalComponent } from './fund-alt-names-modal/fund-alt-names-modal';
import { ModalModule } from '@mt-ng2/modal-module';

@NgModule({
    declarations: [FundsComponent, AlternativeFundNameDetailComponent, ViewFundAltNamesComponent, FundAltNameModalComponent],
    imports: [SharedModule, FundRoutingModule, ModalModule]
})
export class FundModule {}
