<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>Library and Inbox Audit Log</h2>
    </div>
</div>

<div class="row">
    <div class="col-md-11">
        <div class="row">
            <div class="col-md-12">
                <button type="button" class="btn btn-primary" (click)="exportAuditLog()">
                    <i class="fa fa-download" aria-hidden="true"></i>&#10240;Export
                </button>
                <mt-search-filter-select [items]="messageStatuses" entity="Message States"> </mt-search-filter-select>
                <mt-search-filter-select [items]="logActionTypes" entity="Action Type"> </mt-search-filter-select>
                <app-search-filter-daterange-custom
                    entity="Date Filter"
                    [startDate]="startTimestamp"
                    [endDate]="endTimestamp"
                    (onSelectionChanged)="setTimestampRange($event)"
                ></app-search-filter-daterange-custom>
                <div style="display: inline-block;">
                    <mt-search-filter-select [items]="userTypeAuditLog" entity="User Type" (selectionChanged)="filterUsers()">
                    </mt-search-filter-select>
                </div>
                <div style="display: inline-block; width: 17%;">
                    <ng-select
                        [(ngModel)]="selectedUsers"
                        [hideSelected]="true"
                        [items]="(usersSubject$ | async) ? (users$ | async) : usersList"
                        [multiple]="true"
                        [typeahead]="usersSubject$"
                        bindLabel="Name"
                        [placeholder]="'Users'"
                        [groupBy]="'Domain'"
                    ></ng-select>
                </div>
                <ng-container *ngIf="showSelectAllUserBtn">
                    <div style="display: inline-block;">
                        <button class="btn btn-primary" (click)="updateSelectedUsers()">
                            <div *ngIf="selectAllUserText; else deselectAll">
                                <i class="fa fa-check-square-o" aria-hidden="true"></i> &#10240;select all
                            </div>
                            <ng-template #deselectAll>
                                <i class="fa fa-square-o" aria-hidden="true"></i>
                                &#10240;deselect all
                            </ng-template>
                        </button>
                    </div>
                </ng-container>
                <div style="display: inline-block; margin-left: 5px;">
                    <button type="button" class="btn btn-primary" (click)="search()">
                        <i class="fa fa-search" aria-hidden="true"></i>&#10240;Search
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<br />
<div>Email ID</div>
<div class="row">
    <div class="col-md-2">
        <mt-search-bar (searched)="onEmailIDSearch($event)"></mt-search-bar>
    </div>
</div>
<br />

<br />
<mt-entity-list
    [entities]="logs"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getAuditLogs()"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</mt-entity-list>
