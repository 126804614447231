import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '../../model/ClaimTypes';
import { IDboInvestment } from '../../model/interfaces/dbo-investment';
import { InvestmentService } from '../investment-service';

@Component({
    templateUrl: './investment-detail.component.html',
})
export class InvestmentDetailComponent implements OnInit {
    investment: IDboInvestment;
    canEdit: boolean;
    canAdd: boolean;

    constructor(
        private investmentService: InvestmentService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Investments, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = this.getIdFromRoute(this.route, 'Id');
        if (id) {
            this.getInvestmentById(id);
        } else {
            void this.router.navigate(['taxPositions']); // if no id found, go back to list
        }
    }

    getInvestmentById(id: number): void {
        this.investmentService.getById(id).subscribe((investment) => {
            if (investment === null) {
                this.notificationsService.error('Tax Position not found');
                void this.router.navigate(['taxPositions']);
            }
            this.investment = investment;
        });
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10)) ? null : parseInt(id, 10);
    }
}
