import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { IUserRole } from '../../model/interfaces/user-role';
import { UserRoleService } from '../user-role.service';

@Component({
    selector: 'app-user-header',
    templateUrl: './user-role-header.component.html',
})
export class UserRoleHeaderComponent implements OnInit {
    userRole: IUserRole;
    header$: Observable<string>;
    subscriptions: Subscription = new Subscription();

    constructor(private userRoleService: UserRoleService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        const id = +this.route.snapshot.paramMap.get('userRoleId');
        this.userRoleService.setInitialTitle(id);
        this.header$ = this.userRoleService.title$;
    }
}
