import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IMessageAutomationStatu } from '../../model/interfaces/message-automation-statu';

@Injectable({
    providedIn: 'root',
})
export class MessageAutomationStatuService extends MetaItemService<IMessageAutomationStatu> {
    constructor(public http: HttpClient) {
        super('MessageAutomationStatuService', 'Statu', 'StatuIds', '/automationStatus', http);
    }
}
