import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { SharedModule } from '../../shared.module';

@Injectable({
    providedIn: SharedModule,
})
export class NotesUpdatedService {
    hasNotesEvent = new Subject<boolean>();

    refreshLogsObservable = this.hasNotesEvent.asObservable();

    refreshLogs(hasNote: boolean): void {
        this.hasNotesEvent.next(hasNote);
    }
}
