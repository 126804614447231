import { InvestmentsComponent } from './investment-list/investments.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, ClaimValues, IRoleGuarded } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../model/ClaimTypes';
import { InvestmentHeaderComponent } from './investment-header/investment-header.component';
import { InvestmentDetailComponent } from './investment-detail/investment-detail.component';
import { InvestmentService } from './investment-service';
import { InvestmentAddComponent } from './investment-add/investment-add.component';

const investmentEntityConfig = {
    claimType: ClaimTypes.Investments,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'Id',
    service: InvestmentService,
    title: 'Tax Position Detail',
};

const investmentListRoleGuard: any = {
    claimType: ClaimTypes.Investments,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Tax Positions',
};

const investmentAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Investments,
    claimValues: [ClaimValues.FullAccess],
};

export const investmentPaths = {
    investments: 'taxPositions',
    investmentsAdd: 'taxPositions/add',
};

const investmentRoutes: Routes = [
    { canActivate: [AuthGuard], component: InvestmentsComponent, data: investmentListRoleGuard, path: investmentPaths.investments },
    {
        canActivate: [AuthGuard],
        children: [{ component: InvestmentAddComponent, path: '', pathMatch: 'full' }],
        component: InvestmentHeaderComponent,
        data: investmentAddRoleGuard,
        path: investmentPaths.investmentsAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: InvestmentDetailComponent, path: '', pathMatch: 'full' }],
        component: InvestmentHeaderComponent,
        data: investmentEntityConfig,
        path: `taxPositions/:${investmentEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(investmentRoutes)],
})
export class InvestmentRoutingModule {}
