<div class="expander-Container" (click)="announceMessageExpanded($event)">
    <div #expandRow class="message-row-expander" (click)="announceMessageExpanded($event)">
        <span style="display: flex;">
            <i
                style="position: relative; right: 10px;"
                class="fa"
                [class.fa-chevron-down]="message.expanded"
                [class.fa-chevron-right]="!message.expanded"
            ></i>
            <i class="fa fa-expand" (click)="displayModal($event)"></i>
        </span>

        <div *ngIf="message.expanded">
            <app-email-body-display [message]="message" (replyUpdate)="updateReply($event)"></app-email-body-display>
            <app-email-body-display
                *ngFor="let re of message.Replies; trackBy: trackById"
                [message]="message"
                [replyMessageId]="re.Id"
                (replyUpdate)="updateReply($event)"
                (messageUpdate)="messageUpdate($event)"
            ></app-email-body-display>
            <app-reply-email-display
                *ngIf="replyMessage"
                [message]="message"
                [replyMessage]="replyMessage"
                (messageUpdate)="messageUpdate($event)"
            ></app-reply-email-display>
        </div>
    </div>
</div>
<mt-modal-wrapper (ready)="fullScreenEmailModal = $event" (cancelClick)="closeModal()" [autoShow]="false" [options]="modalOptions">
    <div class="emailInModal" style="height: 100%;" *ngIf="message && modalOpened">
        <app-email-body-display [message]="message" [isInModal]="true" (replyUpdate)="updateReply($event)"></app-email-body-display>
        <app-email-body-display
            *ngFor="let re of message.Replies; trackBy: trackById"
            [message]="message"
            [isInModal]="true"
            [replyMessageId]="re.Id"
            (replyUpdate)="updateReply($event)"
            (messageUpdate)="messageUpdate($event)"
        ></app-email-body-display>
        <app-reply-email-display
            *ngIf="replyMessage"
            [message]="message"
            [isInModal]="true"
            [replyMessage]="replyMessage"
            (messageUpdate)="messageUpdate($event)"
        ></app-reply-email-display>
    </div>
</mt-modal-wrapper>
