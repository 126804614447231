<div *ngIf="task">
    <div class="row" *ngIf="hasFullAccess">
        <div class="col-md-2">
            <button type="button" class="btn btn-primary" (click)="downloadEmail()">
                Download Original Message
            </button>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-5">
            <app-task-basic-info [task]="task" [canEdit]="canEdit" [relatedTasks]="relatedTasks" ></app-task-basic-info>
        </div>
        <div class="col-md-5">
            <app-task-document [service]="taskDocumentService" [allowedMimeType]="allowedMimeType"
                [canEdit]="hasFullAccess" [attachment]="task.DboAttachment" [relatedTasks]="relatedTasksForDocs" ></app-task-document>
        </div>
    </div>
</div>