import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IEntityListConfig, IEntity } from '@mt-ng2/entity-list-module';
import { AuthService } from '@mt-ng2/auth-module';
import { TaskMsgAuditLogService } from '../../../audit/audit-services/task-msg-audit-log.service';
import { ITaskMsgAuditLog } from '../../../model/interfaces/task-msg-audit-log';
import { IDboMessage } from '../../../model/interfaces/dbo-message';
import { IDboMessageDTO } from '../../../model/interfaces/custom/IDboMessageDTO';
import { IEntityWithEmailId } from '../../../model/interfaces/custom/EntityWithEmailId';
import { ActivatedRoute } from '@angular/router';
import { ITaskUpdateReasonDTO } from '../../../model/interfaces/custom/ITaskUpdateReasonDTO';
import { ITaskMsgAuditLogTaskChanges } from '../../../model/interfaces/custom/ITaskMsgAuditLogTaskChanges';
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-logs-modal',
    styles: [
        `
            .fullModal {
                overflow-y: initial !important;
            }

            .scrollModal {
                height: 30vh;
                overflow-y: auto;
                overflow-x: hidden;
                width: 100%;
            }

            .logs {
                font-size: 14px;
                width: 100%;
                text-align: left;
            }
        `,
    ],
    templateUrl: './audit-modal-component.html',
})
export class AuditModalComponent implements OnInit {
    modalOptions: IModalOptions = {
        showConfirmButton: false,
        width: '50%',
    };

    entity: IEntityWithEmailId;
    entityListConfig: IEntityListConfig;
    isNoteList: boolean;
    doubleClickIsDisabled = false;
    isEditing = false;
    logs: ITaskMsgAuditLogTaskChanges[] = [];
    newNoteText = '';
    isTaskAuditLog = false;
    isMessageAuditLog = false;
    showTaskTableChanges = false;
    toggleTaskId = 0;

    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSave: EventEmitter<void> = new EventEmitter<void>();

    constructor(private auditService: TaskMsgAuditLogService, private router: ActivatedRoute) {}
    ngOnInit(): void {
        if (this.entity.EmailId) {
            this.auditService.getByMessageId(this.entity.EmailId).subscribe((data) => {
                this.logs = data;
                this.isMessageAuditLog = true;
                this.isTaskAuditLog = false;
            });
        } else {
            this.router.queryParams.subscribe((data) => {
                let messageId = +data?.messageId;
                const getMessageAudit = data?.getMessageAudit;

                if (getMessageAudit === 'false') {
                    messageId = 0;
                }

                if (messageId > 0) {
                    this.auditService.getByMessageId(messageId).subscribe((data) => {
                        this.logs = data;
                        this.isMessageAuditLog = true;
                        this.isTaskAuditLog = false;
                    });
                } else {
                    this.auditService.getByTaskId(this.entity.Id).subscribe((items: ITaskMsgAuditLogTaskChanges[]) => {
                        items.forEach((item: ITaskMsgAuditLogTaskChanges) => {
                            const json: ITaskUpdateReasonDTO = JSON.parse(item.Details);
                            json.Changes.forEach((change) => {
                                if (change.Field === 'StatementDate' || change.Field === 'DueDate') {
                                    const NewValue: string = change.NewValue;
                                    const OldValue: string = change.OldValue;

                                    const newDate = !isNaN(Date.parse(NewValue)) ? formatDate(Date.parse(NewValue), 'medium', 'en-US', 'UTC') : '';
                                    const oldDate = !isNaN(Date.parse(OldValue)) ? formatDate(Date.parse(OldValue), 'medium', 'en-US') : '';
                                    change.NewValue = newDate;
                                    change.OldValue = oldDate;
                                }
                            });
                            item.Details = json.Reason;
                            item.TaskChanges = json;
                        });

                        this.logs = items;
                        this.isTaskAuditLog = true;
                        this.isMessageAuditLog = false;
                    });
                }
            });
        }
    }

    cancel(): void {
        this.onCancel.emit();
    }

    toggleTaskIdTableChanges(log): void {
        this.toggleTaskId = log.Id;
    }
}
