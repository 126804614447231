import {
    DboFundAlternativeNameDynamicControls,
    IDboFundAlternativeNameDynamicControlsParameters,
} from '../form-controls/dbo-fund-alternative-name.form-controls';
import { IDboFundAlternativeName } from '../interfaces/dbo-fund-alternative-name';
import { DynamicLabel, DynamicFieldTypes, DynamicField } from '@mt-ng2/dynamic-form';

export class DboFundAlternativeNameDynamicControlsPartial extends DboFundAlternativeNameDynamicControls {
    constructor(dbofundalternativenamePartial?: IDboFundAlternativeName, additionalParameters?: IDboFundAlternativeNameDynamicControlsParameters) {
        super(dbofundalternativenamePartial, additionalParameters);

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        // (<DynamicField>this.Form.FundId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.Name).type.fieldType = DynamicFieldTypes.Input;

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        (<DynamicLabel>this.View.FundId).label = 'FundId';
        // (<DynamicLabel>this.View.Name).label = 'Some other Label';
    }
}
