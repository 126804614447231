<mt-modal-wrapper [options]="modalOptions" (cancelClick)="cancel()">
    <div style="font-size: 14px; height: 40vh;">
        <h3>Assign User</h3>
        <div style="width: 60%; margin-left: auto; margin-right: auto;">
            <ng-select
                #assignInput
                [items]="users$ | async"
                bindLabel="Name"
                [closeOnSelect]="true"
                [multiple]="false"
                [hideSelected]="true"
                [typeahead]="usersSubject$"
                [(ngModel)]="selectedUser"
                (change)="onUsersModelChange($event)"
                (open)="onUsersOpen()"
            >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <div class="typeaheadSelected">
                        <span aria-hidden="true" class="ng-value-icon left" (click)="clear(item)">×</span>
                        <span class="ng-value-label" title="{{item.Name}}">{{item.Name}}</span>
                    </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    <span class="ng-option-label typeaheadOption" title="{{item.Name}}">{{item.Name}}</span>
                </ng-template>
            </ng-select>
        </div>
        <div class="btn-group">
            <button type="button" class="swal2-confirm swal2-styled" (click)="assignUser()" [disabled]="!selectedUser">Assign User</button>
            <button type="button" class="swal2-cancel swal2-styled" (click)="cancel()">
                Cancel
            </button>
        </div>
    </div>
</mt-modal-wrapper>
