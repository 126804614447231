import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SharedModule } from '../../common/shared.module';

@Injectable({
    providedIn: SharedModule,
})
export class InboxAttachmentUpdateService {
    addAttachmentEvent = new Subject<any>();

    attachmentCompleteObservable = this.addAttachmentEvent.asObservable();

    announceAttachmentComplete(): void {
        this.addAttachmentEvent.next(null);
    }
}
