import { IEntityListDynamicCellComponent, IEntityListComponentMembers, IEntity } from '@mt-ng2/entity-list-module';
import { OnInit, OnDestroy, Component, ElementRef, ViewChild } from '@angular/core';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { MessageTagService } from '../message-tags/services/message-tag.service';
import { IDboMessageManagedNote } from '../model/interfaces/dbo-message-managed-note';
import { MessageManagedNotesService } from './message-managed-notes.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-tag-editable-note-cell',
    styles: [
        `
            .container {
                width: 100%;
                z-index: 999;
                padding: 0;
            }

            textarea {
                width: 100%;
            }

            button {
                float: right;
            }
        `,
    ],
    template: `
        <div *ngIf="isEditing" class="container" (click)="$event.stopPropagation(); $event.preventDefault()">
            <textarea #textArea style="resize:none" [(ngModel)]="item.Note" maxlength="250">{{ item.Note }}</textarea>
            <span class="small pull-right">{{ item.Note.length }}/250</span>
        </div>
        <div *ngIf="!isEditing" class="container" (click)="toggleEdit($event); $event.stopPropagation()">
            <span>{{ item.Note }}</span>
        </div>
    `,
})
export class EditableNoteComponent implements IEntityListDynamicCellComponent, OnInit {
    @ViewChild('textArea') textArea: ElementRef;
    entityListComponentMembers: IEntityListComponentMembers;

    set entity(value: IEntity) {
        this.item = value as IDboMessageManagedNote;
    }
    item: IDboMessageManagedNote;
    initialNote: string;
    isEditing = false;
    subscription = new Subscription();

    constructor(private noteService: MessageManagedNotesService, private notificationService: NotificationsService) {}
    ngOnInit(): void {
        this.initialNote = this.item.Note;
        if (this.item.Id === 0) {
            this.isEditing = true;
        }
        this.subscription.add(
            this.noteService.isEditingObservable.subscribe((id) => {
                if (id === this.item.Id) {
                    this.isEditing = !this.isEditing;
                } else {
                    this.isEditing = false;
                    if (this.item.Id === 0) {
                        this.announceRemoveBlankNote();
                    }
                }
            }),
        );
    }

    toggleEdit(event?: any): void {
        this.noteService.isEditingUpdate(this.item.Id);
    }

    announceRemoveBlankNote(): void {
        this.noteService.isEditingUpdate(0);
    }
}
