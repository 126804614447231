import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MessageRoutingModule } from './message-routing.module';
import { MessageListComponent } from './message-list/message-list-component';
import { EmailBodyDisplayComponent } from './email-body-display/email-body-display.component';
import { RowExpanderComponent } from './message-list/row-expander.component';
import { DocumentDisplayComponent } from './email-body-display/document-download-component';
import { SaveSearchModalComponent } from './saveSearchModal/saveSearchModal';
import { ModalModule } from '@mt-ng2/modal-module';
import { TagOnMessageListComponent } from './message-list/tag-on-message-list.component';
import { MultiselectControlModule } from '@mt-ng2/multiselect-control';
import { TagSelectModule } from '../common/components/tag-selector/tag-select-module';
import { ReplyEmailDisplayComponent } from './replyEmails/reply-email-display.component';
import { TypeAheadContactsComponent } from './replyEmails/typeahead-contacts';
import { AssignUserModalComponent } from './assignUserModal/assignUserModal';
import { AssignTagModalComponent } from './assignTagModal/assignTagModal';
import { LockComponent } from './message-list/lock-component';
import { InboxAttachmentAddModalComponent } from './inbox-attachments/inboxAttachmentAddModal.component';
import { ReplyEmailAttachmentAddModalComponent } from './replyEmails/replyEmails-add-modal/replyEmailAttachmentAddModal';
@NgModule({
    declarations: [
        MessageListComponent,
        EmailBodyDisplayComponent,
        RowExpanderComponent,
        LockComponent,
        SaveSearchModalComponent,
        TagOnMessageListComponent,
        ReplyEmailDisplayComponent,
        TypeAheadContactsComponent,
        AssignUserModalComponent,
        AssignTagModalComponent,
        InboxAttachmentAddModalComponent,
        ReplyEmailAttachmentAddModalComponent,
    ],
    imports: [SharedModule, MessageRoutingModule, NgSelectModule, DragDropModule, ModalModule, MultiselectControlModule, TagSelectModule]
})
export class MessageModule {}
