import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LocalStorageKeys } from '../constants/Enum';
import { LocalStorageService } from './local-storage-service';
@Injectable({
    providedIn: 'root',
})
export class TabCountService {
    constructor(public localStorageService: LocalStorageService) {}
    get getTabCount(): any {
        return this.localStorageService.get(LocalStorageKeys.tabCount);
    }

    incrementTabCount(): void {
        if (!this.getTabCount) {
            this.localStorageService.set(LocalStorageKeys.tabCount, 1);
        } else {
            const tabCount = this.localStorageService.get(LocalStorageKeys.tabCount);
            this.localStorageService.set(LocalStorageKeys.tabCount, +tabCount + 1);
        }
    }

    decrementTabCount(): void {
        if (this.getTabCount > 1) {
            this.localStorageService.set(LocalStorageKeys.tabCount, this.getTabCount - 1);
        }
    }
}
