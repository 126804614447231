import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { ITaskMsgAuditLogType } from '../../model/interfaces/task-msg-audit-log-type';

@Injectable({
    providedIn: 'root',
})
export class TaskMsgAuditLogTypeService extends MetaItemService<ITaskMsgAuditLogType> {
    constructor(public http: HttpClient) {
        super('TaskMsgAuditLogTypeService', 'Type', 'TypeIds', '/auditLogTypes', http);
    }
}
