import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { ClientRoutingModule } from './client-routing.module';
import { ClientsComponent } from './client-list/clients.component';
import { InTransitCellComponent } from './client-list/client-inTransit-cell.component';
import { ViewClientAltNamesComponent } from './client-alt-names-modal/client-viewAltNamesCell';
import { ClientAltNameModalComponent } from './client-alt-names-modal/client-alt-names-modal';
import { ModalModule } from '@mt-ng2/modal-module';

@NgModule({
    declarations: [ClientsComponent, InTransitCellComponent, ViewClientAltNamesComponent, ClientAltNameModalComponent],
    imports: [SharedModule, ClientRoutingModule, ModalModule]
})
export class ClientModule {}
