import { Component, OnInit } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IDboMessageDTO } from '../../../model/interfaces/custom/IDboMessageDTO';
import { AssignUserModalService } from '../../assignUserModal/assignUserModal.service';
import { IMessageRow } from '../message-list-component';

@Component({
    selector: 'app-view-assigned-to',
    styles: [],
    template: `
        <div class="viewAssignedToContainer" (click)="viewAssignedTo($event)">
            <span (click)="viewAssignedTo($event)" class="btn btn-transparent" title="Assign">
                <p>
                    {{ item.Assignee ? item.Assignee.FirstName + ' ' + item.Assignee.LastName : 'Unassigned' }}
                </p>
            </span>
        </div>
    `,
})
export class ViewAssignedToComponent implements IEntityListDynamicCellComponent, OnInit {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IDboMessageDTO) {
        this.item = (value as unknown) as IDboMessageDTO;
    }
    item: IDboMessageDTO;

    constructor(private modalService: AssignUserModalService) {}
    ngOnInit(): void {}

    viewAssignedTo($event: any): void {
        event.stopPropagation();
        this.modalService.showModal([this.item] as IMessageRow[], null);
    }
}
