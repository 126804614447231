import { IEmailDTO } from './../model/interfaces/custom/IEmailDTO.d';
import { ITaskSearchObject } from './../model/partials/task-search';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { IDboTask } from './../model/interfaces/dbo-task.d';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IDboTaskDTO } from '../model/interfaces/custom/IDboTaskDTO';
import { map, tap } from 'rxjs/operators';
import { IBlobDownload } from '../model/interfaces/custom/blob-download';
import { CommonService } from '../common/services/common.service';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { TaskListComponent, ITaskFilterData } from './task-list/task-list.component';
import { ITaskListSettings } from '../model/interfaces/custom/task-list-settings';
import { IVwClient } from '../model/interfaces/vw-client';
import { IVwFund } from '../model/interfaces/vw-fund';
import { IRelatedTaskDTO } from '../model/interfaces/custom/IRelatedTaskDTO';
import { IDboSaveTaskDTO } from '../model/interfaces/custom/IDboTaskSaveDTO';
import { IAttachmentWithMetadataDTO, convertForAPI } from '../processAttachments/attachment-meta-data.service';
import { TaskCloneResultDTO } from '../model/interfaces/custom/TaskCloneDTO';
import { IReplaceTaskClientDTO } from '../model/interfaces/custom/IReplaceTaskClientDTO';
import { IReplaceTaskFundClientDTO } from '../model/interfaces/custom/IReplaceTaskFundClientDTO';

@Injectable({
    providedIn: 'root',
})
export class TaskService extends BaseService<IDboTask> {
    constructor(public http: HttpClient, private commonService: CommonService, private envSvc: EnvironmentService) {
        super('/tasks', http);
    }

    GetClientsByFundIds(fundIds: number[]): Observable<HttpResponse<IVwClient[]>> {
        return this.http.post<IVwClient[]>(`/tasks/getClientsByFunds`, fundIds, { observe: 'response' });
    }
    GetFundsByClientIds(clientIds: number[]): Observable<HttpResponse<IVwFund[]>> {
        return this.http.post<IVwFund[]>(`/tasks/getFundsByClients`, clientIds, { observe: 'response' });
    }

    searchNameList<T extends { Name?: string }>(list: T[], term: string | null, minTermLength: number): T[] {
        if (list === undefined) {
            return [];
        }

        if ((term ? term.length : 0) < minTermLength) {
            return [];
        }
        if (!term) {
            return [...list];
        }
        const lower = term.toLowerCase();

        return list.filter((e: T) => e && e.Name && e.Name.toLowerCase().includes(lower));
    }

    getLatestTaskSearch(component: TaskListComponent): ISearchState {
        return (
            latestSearchState || {
                filterData: getTaskFilterData(component),
                filterSelection: this.getDefaultTaskSearch(component),
                results: [],
                total: 0,
            }
        );
    }

    getDefaultTaskSearch(component: TaskListComponent): ITaskSearchObject {
        return getDefaultTaskSearchObject(component);
    }

    setLatestSavedSearchId(id: number): void {
        latestSavedSearchId = id;
    }

    getLatestSavedSearchId(): number {
        return latestSavedSearchId;
    }

    clearLatestSearchState(): void {
        latestSearchState = null;
    }

    getTaskListSettings(): Observable<ITaskListSettings> {
        return this.http.get<ITaskListSettings>('/tasks/settings');
    }

    getRelatedTasks(taskId: number): Observable<HttpResponse<IRelatedTaskDTO[]>> {
        return this.http.get<IRelatedTaskDTO[]>(`/tasks/getRelatedTasks/${taskId}`, { observe: 'response' });
    }

    getTasks(obj: ITaskSearchObject, component: TaskListComponent): Observable<HttpResponse<IDboTaskDTO[]>> {
        return this.http
            .post<IDboTaskDTO[]>(`/tasks/getTasks`, obj, { observe: 'response' })
            .pipe(
                tap((response) => {
                    latestSearchState = {
                        filterData: getTaskFilterData(component),
                        filterSelection: obj,
                        results: response.body || [],
                        total: +response.headers.get('X-List-Count'),
                    };
                }),
            );
    }

    getTaskExport(searchParams: ITaskSearchObject): Observable<IBlobDownload> {
        return this.http
            .post(`/tasksExport/exportTaskSearch`, searchParams, {
                observe: 'response',
                responseType: 'blob',
            })
            .pipe(map(this.commonService.mapBlobDownload));
    }

    getTaskById(taskId: number): Observable<HttpResponse<IDboTaskDTO>> {
        return this.http.get<IDboTaskDTO>(`/tasks/getTaskById/${taskId}`, { observe: 'response' });
    }

    toggleArchive(taskId: number, archive: boolean): Observable<any> {
        return this.http.put(`/tasks/archive/${taskId}`, archive, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
    }

    saveTask(obj: any): Observable<void> {
        return this.http.post<void>(`/tasks/saveTask`, obj, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
    }

    downloadTaskEmail(taskId: number): Observable<string> {
        return this.http.get<string>(`/tasks/downloadTaskEmail/${taskId}`);
    }

    getTaskAttachmentPath(taskId: number, fullPath = false): string {
        const basePath = fullPath ? `${this.envSvc.config.baseApiUrl}/api/v1` : '';
        return `${basePath}/tasks/downloadTaskAttachment/${taskId}`;
    }

    downloadTaskAttachment(taskId: number): Observable<IBlobDownload> {
        return this.http
            .get(this.getTaskAttachmentPath(taskId), {
                observe: 'response',
                responseType: 'blob',
            })
            .pipe(map(this.commonService.mapBlobDownload));
    }

    downloadDocuments(taskIds: number[]): Observable<IBlobDownload> {
        return this.http
            .post(`/tasks/downloadMultipleDocs`, taskIds, {
                observe: 'response',
                responseType: 'blob',
            })
            .pipe(map(this.commonService.mapBlobDownload));
    }

    sendEmail(emailDTO: IEmailDTO): Observable<object> {
        return this.http.post(`/tasks/sendEmail`, emailDTO);
    }

    CreateTasksReturnAlreadyProcessedMessages(
        objs: { pendingAttachments: IAttachmentWithMetadataDTO[]; messageIdsWithoutAttachments: number[] },
        userId: number,
    ): Observable<ICreateNewTasksResult> {
        const objectsWithAttachments = objs.pendingAttachments;
        const convertedForApi = objectsWithAttachments.map((o) => convertForAPI(o));
        const messageIdsWithoutAttachments = objs.messageIdsWithoutAttachments;
        const messageData = { convertedForApi, messageIdsWithoutAttachments };

        return this.http.post<ICreateNewTasksResult>(`/tasks/createTask/${userId}`, messageData, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }

    checkForSimilarTasks(obj: IAttachmentWithMetadataDTO): Observable<boolean> {
        const convertedForApi = convertForAPI(obj);
        return this.http.post<boolean>(`/tasks/checkForSimilarTasks`, convertedForApi, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }

    getMasterClients(): Observable<string[]> {
        return this.http.get<string[]>(`/tasks/master-clients`);
    }

    getClientsByMasterClients(masterClients: string[]): Observable<any> {
        let queryParams = new HttpParams();
        masterClients.forEach((masterClient) => (queryParams = queryParams.append('masterClients', masterClient)));
        return this.http.get(`/tasks/master-clients/clients`, { params: queryParams });
    }

    checkIfTaskAttachmentsAreUnique(taskIds: number[]): Observable<boolean> {
        let queryParams = new HttpParams();
        taskIds.forEach((t) => (queryParams = queryParams.append('taskIds', t.toString())));
        return this.http.get<boolean>(`/tasks/check-task-attachments-unique`, { params: queryParams });
    }

    getClientsByTaskFundIds(taskIds: number[]): Observable<IVwClient[]> {
        let queryParams = new HttpParams();
        taskIds.forEach((t) => (queryParams = queryParams.append('taskIds', t.toString())));
        return this.http.get<IVwClient[]>(`/tasks/task-funds-clientIds`, { params: queryParams });
    }

    replaceTaskClients(replaceTaskClientDTO: IReplaceTaskClientDTO): Observable<number[]> {
        return this.http.post<number[]>(`/tasks/task-replace-clients`, replaceTaskClientDTO);
    }

    replaceTaskFundClient(replaceTaskFundClientDTO: IReplaceTaskFundClientDTO): Observable<number[]> {
        return this.http.post<number[]>(`/tasks/task-replace-fund-client`, replaceTaskFundClientDTO);
    }

    cloneSelectedTasks(taskIds: number[]): Observable<TaskCloneResultDTO> {
        return this.http.post<TaskCloneResultDTO>(`/tasks/cloneTasks`, taskIds, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }

    updateTaskPriority(taskIds: number[]): Observable<object> {
        return this.http.patch(`/tasks/updateTaskPriority`, taskIds);
    }
}

export interface ICreateNewTasksResult {
    AlreadyProcessedMessageIds: number[];
    FailedToProcessAttachmentIds: number[];
}

function getTaskFilterData(component: TaskListComponent): ITaskFilterData {
    return {
        clients: component.clients || [],
        funds: component.funds || [],
        investments: component.investments || [],
        taskTypes: component.taskTypes || [],
        tenants: component.tenants || [],
        usersProcessedBy: component.usersProcessedBy || [],
        taskStatuses: component.taskStatuses || [],
    };
}

function getDefaultTaskSearchObject(component: TaskListComponent): ITaskSearchObject {
    return {
        clientIds: [],
        documentTypeIds: [],
        dueDateContextualDateId: null,
        emailIds: '',
        endDueDate: '',
        endReceivedDate: '',
        endStatementDate: '',
        fundIds: [],
        includeArchive: false,
        includeTaxDocs: false,
        investmentIds: [],
        isHighPriority: false,
        name: '',
        order: component.order,
        orderDirection: component.orderDirection,
        processedByIds: [],
        query: '',
        receivedDateContextualDateId: null,
        savedSearchId: 0,
        skip: (component.currentPage - 1) * component.selectedElementPerPage,
        startDueDate: '',
        startReceivedDate: '',
        startStatementDate: '',
        statementDateContextualDateId: null,
        take: component.selectedElementPerPage,
        taskIds: '',
        taxFormYear: undefined,
        tenantIds: [],
        taskStatusIds :[]
        
    };
}

let latestSavedSearchId: number;
let latestSearchState: ISearchState;

export interface ISearchState {
    filterSelection: ITaskSearchObject;
    filterData: ITaskFilterData;
    results: IDboTaskDTO[];
    total: number;
}
