<mt-dynamic-form-select-wrapper [parentControl]="thisControl" [formGroup]="getGroup()">
    <div
        #inputElement
        class="btn-group btn-group-toggle"
        [class.hidden]="config.options.length === 0"
        ngbRadioGroup
        [formControlName]="config.name"
        [class.disabled]="config.disabled"
        (blur)="onBlur()"
        (focus)="onFocus()"
    >
        <label
            ngbButtonLabel
            *ngFor="let option of config.options"
            class="btn-primary-inverse"
            [attr.disabled]="config.disabled ? 'disabled' : null"
            [class.disabled]="config.disabled"
            [title]="option.Description"
        >
            <input ngbButton type="radio" (blur)="onBlur()" (focus)="onFocus()" [value]="option.Id" /> {{ option.Name }}
        </label>
    </div>
    <em [class.errortext]="hasError()" *ngIf="config.options.length == 0">{{ getNoItemsErrorMessage() }}</em>
</mt-dynamic-form-select-wrapper>
