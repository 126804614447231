export interface ITaskSearchObject {
    skip: number;
    take: number;
    taskIds: string;
    emailIds: string;
    clientIds: number[];
    fundIds: number[];
    documentTypeIds: number[];
    tenantIds: number[];
    startDueDate: string;
    endDueDate: string;
    startStatementDate: string;
    endStatementDate: string;
    startReceivedDate: string;
    endReceivedDate: string;
    includeArchive: boolean;
    name: string;
    query: string;
    includeTaxDocs: boolean;
    order: string;
    orderDirection: string;
    savedSearchId: number;
    investmentIds: number[];
    taxFormYear?: number;
    dueDateContextualDateId?: number;
    receivedDateContextualDateId?: number;
    statementDateContextualDateId?: number;
    email?: string;
    processedByIds: number[];
    takeAll?: boolean;
    isHighPriority?: boolean;
    masterClients?: string[];
    taskStatusIds?: number[];
}

export class TaskSearchObject implements ITaskSearchObject {
    skip: number;
    take: number;
    taskIds: string;
    emailIds: string;
    clientIds: number[];
    fundIds: number[];
    documentTypeIds: number[];
    startDueDate: string;
    tenantIds: number[];
    endDueDate: string;
    startStatementDate: string;
    endStatementDate: string;
    startReceivedDate: string;
    endReceivedDate: string;
    includeArchive: boolean;
    name: string;
    query: string;
    includeTaxDocs: boolean;
    order: string;
    orderDirection: string;
    savedSearchId: number;
    investmentIds: number[];
    taxFormYear?: number;
    dueDateContextualDateId?: number;
    receivedDateContextualDateId?: number;
    statementDateContextualDateId?: number;
    email?: string;
    processedByIds: number[];
    isHighPriority?: boolean;
    taskStatusIds?: number[];
    constructor(valueObject: ITaskSearchObject) {
        this.skip = valueObject.skip;
        this.take = valueObject.take;
        this.taskIds = valueObject.taskIds;
        this.emailIds = valueObject.emailIds;
        this.clientIds = valueObject.clientIds;
        this.fundIds = valueObject.fundIds;
        this.documentTypeIds = valueObject.documentTypeIds;
        this.startDueDate = valueObject.startDueDate;
        this.endDueDate = valueObject.endDueDate;
        this.startStatementDate = valueObject.startStatementDate;
        this.endStatementDate = valueObject.endStatementDate;
        this.startReceivedDate = valueObject.startReceivedDate;
        this.endReceivedDate = valueObject.endReceivedDate;
        this.includeArchive = valueObject.includeArchive;
        this.name = valueObject.name;
        this.query = valueObject.query;
        this.tenantIds = valueObject.tenantIds;
        this.includeTaxDocs = valueObject.includeTaxDocs;
        this.order = valueObject.order;
        this.orderDirection = valueObject.orderDirection;
        this.savedSearchId = valueObject.savedSearchId;
        this.investmentIds = valueObject.investmentIds;
        this.taxFormYear = valueObject.taxFormYear;
        this.dueDateContextualDateId = valueObject.dueDateContextualDateId;
        this.receivedDateContextualDateId = valueObject.receivedDateContextualDateId;
        this.statementDateContextualDateId = valueObject.statementDateContextualDateId;
        this.email = valueObject.email;
        this.processedByIds = valueObject.processedByIds;
        this.isHighPriority = valueObject.isHighPriority;
        this.taskStatusIds = valueObject.taskStatusIds;
    }
}
