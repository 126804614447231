import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef, EventEmitter } from '@angular/core';
import { SharedModule } from '../../common/shared.module';
import { take } from 'rxjs/operators';
import { InvestmentAddDealsModalComponent } from './investmentAddDealsModal';
import { DboMessageDocumentService } from '../../messages/services/dbo-message-document-service';
import { InvestmentAddDealsReportService } from './investmentAddDealsUpdateService';
import { IDboAttachment } from '../../model/interfaces/dbo-attachment';
import { TaskDocumentsService } from '../../tasks/task-document-service';
import { IDboInvestment } from '../../model/interfaces/dbo-investment';
import { InvestmentService } from '../investment-service';

@Injectable({
    providedIn: SharedModule,
})
export class InvestmentAddDealsModalService {
    private _componentRef: ComponentRef<InvestmentAddDealsModalComponent>;

    saved: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        private dealReportService: InvestmentAddDealsReportService,
        private investmentService: InvestmentService,
    ) {}

    showModal(): void {
        this.destroyComponent();
        this._componentRef = this.componentFactoryResolver.resolveComponentFactory(InvestmentAddDealsModalComponent).create(this.injector);
        this._componentRef.instance.dealReportService = this.dealReportService;
        this._componentRef.instance.investmentService = this.investmentService;
        this._componentRef.instance.onSave.pipe(take(1)).subscribe(() => this.saved.emit());
        this._componentRef.instance.onCancel.pipe(take(1)).subscribe(() => {
            this.destroyComponent();
        });

        this.appRef.attachView(this._componentRef.hostView);
        const domElem = (this._componentRef.hostView as EmbeddedViewRef<InvestmentAddDealsModalComponent>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
    }

    private destroyComponent(): void {
        if (this._componentRef) {
            this.appRef.detachView(this._componentRef.hostView);
            this._componentRef.destroy();
            this._componentRef = null;
        }
    }
}
