<mt-modal-wrapper [options]="modalOptions" (cancelClick)="cancel()">
    <div class="fullModal">
        <h2 id="swal2-title" class="swal2-title">
            <h2>Audit Logs</h2>
        </h2>
        <hr />
        <div class="scrollModal">
            <div class="table-responsive">
                <table class="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Action</th>
                            <th>Timestamp</th>
                            <th>Details</th>
                        </tr>
                    </thead>

                    <tbody *ngFor="let log of logs">
                        <tr>
                            <td *ngIf="log.User">
                                <div class="logs">{{log.User.FirstName}} {{log.User.LastName}}</div>
                            </td>
                            <td *ngIf="!log.User">
                                <div class="logs">System User</div>
                            </td>
                            <td>
                                <div class="logs">{{log.TaskMsgAuditLogType.Name}}</div>
                            </td>
                            <td>
                                <div class="logs">{{log.Timestamp | date:'dd MMMM yyyy h:mm a'}}</div>
                            </td>
                            <td *ngIf="isMessageAuditLog">
                                <div class="logs">{{log.Details}}</div>
                            </td>
                            <td *ngIf="isTaskAuditLog">
                                <div class="logs">{{log.Details}}</div>
                                <button class="btn btn-flat btn-success" (click)="toggleTaskIdTableChanges(log)">View
                                    Task
                                    Changes</button>
                            </td>
                        </tr>
                        <tr *ngIf="isTaskAuditLog">
                            <td colspan="4">
                                <app-audit-task-changes [taskId]="log.Id" [toggleTaskIdTable]="toggleTaskId"
                                    [logTaskChangesChanges]="log.TaskChanges.Changes">
                                </app-audit-task-changes>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <button type=" button" class="btn btn-default" (click)="cancel()">Cancel</button>
    </div>
</mt-modal-wrapper>