import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { EmailContactAddComponent } from './email-contact-add/email-contact-add.component';
import { EmailContactDetailComponent } from './email-contact-detail/email-contact-detail.component';
import { EmailContactsComponent } from './email-contact-list/email-contacts.component';
import { EmailContactService } from './services/email-contact.service';
import { EmailContactHeaderComponent } from './email-contact-header/email-contact-header.component';

import { ClaimTypes } from '../model/ClaimTypes';

const emailContactEntityConfig = {
    claimType: ClaimTypes.EmailContacts,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'Id',
    service: EmailContactService,
    title: 'Email Contact Detail',
};

const emailContactListRoleGuard: any = {
    claimType: ClaimTypes.EmailContacts,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'EmailContacts',
};

const emailContactAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.EmailContacts,
    claimValues: [ClaimValues.FullAccess],
};

export const emailContactPaths = {
    emailContacts: 'emailContacts',
    emailContactsAdd: 'emailContacts/add',
};

const emailContactRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: EmailContactsComponent,
        data: emailContactListRoleGuard,
        path: emailContactPaths.emailContacts,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: EmailContactAddComponent, path: '', pathMatch: 'full' }],
        component: EmailContactHeaderComponent,
        data: emailContactAddRoleGuard,
        path: emailContactPaths.emailContactsAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: EmailContactDetailComponent, path: '', pathMatch: 'full' }],
        component: EmailContactHeaderComponent,
        data: emailContactEntityConfig,
        path: `emailContacts/:${emailContactEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(emailContactRoutes)],
})
export class EmailContactRoutingModule {}
