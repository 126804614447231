import { EntityListConfig, SortDirection, EntityListColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';

export class CustomersEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Name',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                    },
                }),
                new EntityListColumn({
                    accessors: ['CustomerPhones'],
                    name: 'Phone',
                    pipes: ['primary', 'phone'],
                }),
                new EntityListColumn({
                    accessors: ['CustomerSource', 'Name'],
                    name: 'Source',
                }),
                new EntityListColumn({
                    accessors: ['CustomerStatus', 'Name'],
                    name: 'Status',
                }),
            ],
        };
        super(listConfig);
    }
}
