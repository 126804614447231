import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../model/ClaimTypes';
import { AuditListComponent } from './audit-list/audit-list-component';

const auditListRoleGuard: any = {
    claimType: ClaimTypes.Audit,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'Id',
    title: 'Audit Logs',
};

const taskRoutes: Routes = [{ path: 'audit', component: AuditListComponent, canActivate: [AuthGuard], data: auditListRoleGuard }];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(taskRoutes)],
})
export class AuditRoutingModule {}
