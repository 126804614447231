import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntity, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IMessageRow } from './message-list-component';
import { Subscription } from 'rxjs';
import { IReplyMessage } from '../../model/interfaces/reply-message';
import { ReplyMessageService } from '../replyEmails/reply-message.service';
import { ReplyTypes } from '../../common/constants/Enum';
import { DboMessageService } from '../services/dbo-message.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IModalWrapperApi } from '@mt-ng2/modal-module';

@Component({
    styles: [
        `
            /* .expander-Container {
        width: 100px;
        position:absolute;
    }
    .row-expander: {
        width: 100%;
    } */
        `,
    ],
    template: `
        <div *ngIf="message.IsProcessingLocked">
            <button class="btn btn-transparent" (click)="showModal($event)"><i class="fa fa-lock"></i></button>
        </div>
        <mt-modal-wrapper
            [autoShow]="false"
            [options]="{ showCancelButton: true, showConfirmButton: true, confirmButtonText: 'Unlock', width: 1000 }"
            (ready)="unlockMessageApi = $event"
            (okClick)="unlockMessage()"
        >
            <div>
                This message was locked by {{ fullName() }}, on
                {{ message.LastProcessingLockedDate ? (message.LastProcessingLockedDate | date: 'dd MMMM yyyy h:mm a') : 'Unknown Date' }}.
            </div>
        </mt-modal-wrapper>
    `,
})
export class LockComponent implements IEntityListDynamicCellComponent, OnInit {
    unlockMessageApi: IModalWrapperApi;
    get message(): IMessageRow {
        return this.entity as IMessageRow;
    }
    @Input() set message(message: IMessageRow) {
        this.entity = message;
    }

    @Input() entity: IEntity;
    entityListComponentMembers: IEntityListComponentMembers;
    subscription: Subscription = new Subscription();

    constructor(private messageService: DboMessageService, private notificationService: NotificationsService) {}
    ngOnInit(): void {}

    showModal(event: any): void {
        event.stopPropagation();
        this.unlockMessageApi.show();
    }

    unlockMessage(): void {
        this.messageService.processingLock([this.message.EmailId], false, 0).subscribe(() => {
            this.messageService.tagOnMessageSubject.next(this.message);
            this.notificationService.success('Unlocked Message');
        });
    }

    fullName(): string {
        let name = '';
        if (this.message.ProcessingLockedBy) {
            name = `${this.message.ProcessingLockedBy.FirstName} ${this.message.ProcessingLockedBy.LastName}`;
        } else {
            name = 'Unknown User';
        }
        return name;
    }
}
