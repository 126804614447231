import { Injectable } from '@angular/core';
import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IUserRoleType } from '../model/interfaces/user-role-type';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class UserRoleTypeService extends StaticMetaItemService<IUserRoleType> {
    constructor(public http: HttpClient) {
        super('UserRoleTypeService', 'User Role Type', 'UserRoleTypeIds', '/userRoles/roleTypes', http);
    }
}
