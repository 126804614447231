import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { MessageTagAddComponent } from './message-tag-add/message-tag-add.component';
import { MessageTagDetailComponent } from './message-tag-detail/message-tag-detail.component';
import { MessageTagsComponent } from './message-tag-list/message-tags.component';
import { MessageTagService } from './services/message-tag.service';
import { MessageTagHeaderComponent } from './message-tag-header/message-tag-header.component';
import { ClaimTypes } from '../model/ClaimTypes';

const messageTagEntityConfig = {
    claimType: ClaimTypes.Tags,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'messageTagId',
    service: MessageTagService,
    title: 'Tag Detail',
};

const messageTagListRoleGuard: any = {
    claimType: ClaimTypes.Tags,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Tags',
};

const messageTagAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Tags,
    claimValues: [ClaimValues.FullAccess],
};

export const messageTagPaths = {
    messageTags: 'tags',
    messageTagsAdd: 'tags/add',
};

const messageTagRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: MessageTagsComponent,
        data: messageTagListRoleGuard,
        path: messageTagPaths.messageTags,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: MessageTagAddComponent, path: '', pathMatch: 'full' }],
        component: MessageTagHeaderComponent,
        data: messageTagAddRoleGuard,
        path: messageTagPaths.messageTagsAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: MessageTagDetailComponent, path: '', pathMatch: 'full' }],
        component: MessageTagHeaderComponent,
        data: messageTagEntityConfig,
        path: `tags/:${messageTagEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(messageTagRoutes)],
})
export class MessageTagRoutingModule {}
