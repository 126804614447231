import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../model/ClaimTypes';
import { MessageListComponent } from './message-list/message-list-component';

const messageListRoleGuard: any = {
    claimType: ClaimTypes.Tasks,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'Id',
    title: 'Inbox',
};

const taskRoutes: Routes = [
    { path: 'inbox', component: MessageListComponent, canActivate: [AuthGuard], data: messageListRoleGuard },
    { path: 'inbox/:id', component: MessageListComponent, canActivate: [AuthGuard], data: messageListRoleGuard },
    // {
    //     canActivate: [AuthGuard],
    //     children: [
    //         { path: '', component: TaskDetailComponent, pathMatch: 'full' },

    //     ],
    //     component: TaskHeaderComponent,
    //     data: taskListRoleGuard,
    //     path: `tasks/:${taskListRoleGuard.entityIdParam}`,
    // },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(taskRoutes)],
})
export class MessageRoutingModule {}
