import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavSidebarComponent } from '@mt-ng2/nav-module';
import { AuthService, ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { ClaimTypes } from './../../../model/ClaimTypes';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-custom-nav-sidebar',
    styleUrls: ['custom-nav-sidebar.less'],
    templateUrl: 'custom-nav-sidebar.component.html',
})
export class CustomNavSidebarComponent extends NavSidebarComponent implements OnInit, OnDestroy {
    myTenantPath = '/my-tenant';
    showManageTenant = false;
    private subscriptions: Subscription = new Subscription();

    constructor(private authService: AuthService, private claimSvc: ClaimsService) {
        super();
    }
    ngOnInit(): void {
        this.subscriptions.add(
            this.authService.appReady.subscribe((ready) => {
                if (ready) {
                    this.showManageTenant =
                        !this.claimSvc.hasClaim(ClaimTypes.Tenants_SeeAll, [ClaimValues.FullAccess]) &&
                        this.claimSvc.hasClaim(ClaimTypes.Tenants, [ClaimValues.FullAccess]);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
