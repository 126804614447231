import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IVwFund } from '../model/interfaces/vw-fund';
import { INoteService } from '../model/interfaces/custom/EntityListConfigWithNotes';
import { IDboVFundAltName } from '../model/interfaces/dbo-v-fund-alt-name';

@Injectable({
    providedIn: 'root',
})
export class FundService extends BaseService<IVwFund> implements INoteService {
    constructor(public http: HttpClient) {
        super('/funds', http);
    }

    setArchivedStatus(fundId: number, archived: boolean): Observable<void> {
        return this.http.put<void>(`/funds/archive/${fundId}`, archived, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }

    updateNote(id: number, note: string): Observable<void> {
        return this.http.post<void>(`/funds/note/${id}`, { Note: note });
    }

    getAltNames(id: number): Observable<IDboVFundAltName[]> {
        return this.http.get<IDboVFundAltName[]>(`/funds/altNames/${id}`);
    }

    getRelatedFunds(anchorFundId: number): Observable<number[]> {
        return this.http.get<number[]>(`/funds/${anchorFundId}/related-funds`);
    }
}
