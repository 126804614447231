import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { Component, Input, OnInit, ViewChild, Output, OnDestroy, ElementRef, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IVwFund } from '../../model/interfaces/vw-fund';
import { IDboInvestment } from '../../model/interfaces/dbo-investment';
import { IDboTaskType } from '../../model/interfaces/dbo-task-type';
import { IDboVwMessageWithAttachment } from '../../model/interfaces/dbo-vw-message-with-attachment';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TaskService } from '../../tasks/task.service';
import { TaskDynamicConfig } from '../../tasks/task.dynamic-config';
import { IDboTask } from '../../model/interfaces/dbo-task';
import { FormGroup, NgForm, DefaultValueAccessor, NgModel } from '@angular/forms';
import { IVwClient } from '../../model/interfaces/vw-client';
import { TaskDateType } from '../../model/TaskDateType';
import { IDboTaskDTO } from '../../model/interfaces/custom/IDboTaskDTO';
import { IDboAttachment } from '../../model/interfaces/dbo-attachment';
import { DboMessageDocumentService } from '../../messages/services/dbo-message-document-service';
import { IAttachmentActionType } from '../../model/interfaces/attachment-action-type';
import { AttachmentActionTypeService } from '../attachment-action-type.service';
import { AttachmentMetadataService, IMetaDataObject, IAttachmentWithMetadata, IAttachmentWithMetadataDTO } from '../attachment-meta-data.service';
import { AttachmentActionEnum, TaskTier, BackingEnum, AttachmentState, MessageState, TaskType } from '../../common/constants/Enum';
import { ProcessAttachmentUpdateService } from '../processAttachment-add-modal/processAttachmentUpdateService';
import { ProcessAttachmentAddModalService } from '../processAttachment-add-modal/processAttachmentAddModal.service';
import { FundService } from '../../funds/fund.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IVwFundWithAltName } from '../../model/interfaces/custom/IVwFundWithAltName';
import { ClientService } from '../../clients/client.service';
import { FileItem } from 'ng2-file-upload';
import { TaskDocumentsService } from '../../tasks/task-document-service';
import { IDboSupportingDocument } from '../../model/interfaces/dbo-supporting-document';
import { InvestmentService } from '../../investments/investment-service';
import { IDboSaveTaskDTO } from '../../model/interfaces/custom/IDboTaskSaveDTO';
import { DboMessageService } from '../../messages/services/dbo-message.service';
import { ILoggedIn, AuthService } from '@mt-ng2/auth-module';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DateCustomComponent } from '../../common/components/date-time/quarter-date-picker/search-filter-daterange.component';
import { DatePickerComponent } from '../../common/components/date-time/date-picker/date-picker.component';
import { Router } from '@angular/router';
import { MimeTypes } from '../../common/constants/MimeTypes';
import { FILE_SIZE_LIMIT } from '../../common/constants/files';
import { AttachmentTagsService } from '../../attachment-tags/attachment-tags.service';
import { IDboAttachmentTag } from '../../model/interfaces/dbo-attachment-tag';


@Component({
    selector: 'app-process-attachment',
    styles: [
        `
            .smallButton {
                height: 20px;
                text-align: center;
                vertical-align: middle;
                line-height: 5px;
            }

            .checkLabel {
                font-weight: normal;
                margin-left: 10px;
            }

            .supportDocsContainer {
                display: flex;
                flex-wrap: nowrap;
                margin-right: 5px;
            }

            .allSupportingDocsContainer {
                display: flex;
            }

            .dueDateContainer {
                display: flex;
                flex-wrap: nowrap;
            }

            .singleDatePicker {
                margin-top: -15px;
            }

            .buttonSetRight {
                float: right;
            }

            .errorBorder {
                border: 1px solid #dd4b39;
            }

            .hideHead {
                opacity: 0;
                display: none;
            }

            .meTable {
                table-layout: fixed;
                width: 100%;
            }

            .clientListItem {
                display: block;
            }

            .pendingDisplay {
                display: inline-block;
                margin-right: 5%;
                width: 15%;
                vertical-align: top;
            }
        `,
    ],
    templateUrl: './processAttachment.component.html',
})
export class ProcessAttachmentComponent implements OnInit, OnDestroy, OnChanges {
    @Input() attachment: IAttachmentWithMetadata;
    @Input() attachmentIndex: number;
    @Input() fundsList: IVwFundWithAltName[] = [];
    @Input() investmentList: IDboInvestment[] = [];
    @Input() allDocTypes: IDboTaskType[] = [];
    @Input() clientsList: IVwClient[] = [];
    @Input() allAttachments: IAttachmentWithMetadata[];
    @Output() updateMessageWithAttachments = new EventEmitter<IAttachmentWithMetadata[]>();
    @ViewChild('taskForm') taskForm: NgForm;
    @ViewChild('fundField') fundField: NgSelectComponent;
    @ViewChild('investmentField') investmentField: NgSelectComponent;
    @ViewChild('docTypeField') docTypeField: ElementRef;
    @ViewChild('clientField') clientField: NgSelectComponent;
    @ViewChild('statementDateField') statementDateField: DateCustomComponent;
    @ViewChild('fundNote') fundNote: ElementRef;

    config: any = { formObject: [], viewOnly: [] };
    formFactory: TaskDynamicConfig<IDboTask>;
    form: NgForm;
    href = '';

    maxFileSize = FILE_SIZE_LIMIT;

    funds$: Observable<IVwFundWithAltName[]>;
    funds: IVwFundWithAltName[] = [];
    public fundSubject$ = new Subject<string>();

    investment$: Observable<IDboInvestment[]>;
    investments: IDboInvestment[];
    public investmentSubject$ = new Subject<string>();

    selectedClients: IVwClient[];
    clients$: Observable<IVwClient[]>;
    clients: IVwClient[];
    public clientSubject$ = new Subject<string>();

    filteredDocTypes: IDboTaskType[] = [];
    selectedDocType: IDboTaskType;

    selectedActionType: IAttachmentActionType = { Id: AttachmentActionEnum.None, Name: 'None' };

    includeExited = false;

    disableSelectAllWhileClientsLoad = false;

    allowedMimeType = MimeTypes;
    showAddSupportingDocs = false;
    loggedInUserId: number;

    isTaxDocument = false;
    isDistributionNotice = false;
    currUserId = 0;

    subscription = new Subscription();

    clientTypeaheadPlaceholderText = 'Loading...';
    isPendingClientsListExpanded = false;

    isShowTaxFormYear = true;
    taxFormYearValue: number;

    similarTasksWarningModal: IModalWrapperApi;
    similarTasksModalOptions: IModalOptions = {
        showCancelButton: false,
        showCloseButton: true,
        showConfirmButton: false,
        width: '700px',
    };

    documentTypesWarningModal: IModalWrapperApi;
    documentTypesModalOptions: IModalOptions = {
        showCancelButton: false,
        showCloseButton: true,
        showConfirmButton: true,
        width: '700px',
    };

    copyDownWarningModal: IModalWrapperApi;
    copyDownModalOptions: IModalOptions = {
        showCancelButton: false,
        showCloseButton: true,
        showConfirmButton: true,
        width: '700px',
    };


    taskTypePartnersCapitalStatementId = TaskType.PartnersCapitalStatement;
    isAnchorFundAllClientsNotSelected = false;
    numberOfRelatedFunds = 0;

    attachmentTag: IDboAttachmentTag[] = [];

    constructor(
        private metadateService: AttachmentMetadataService,
        private messageDocumentService: DboMessageDocumentService,
        private taskService: TaskService,
        private modalService: ProcessAttachmentAddModalService,
        private attachmentUpdateService: ProcessAttachmentUpdateService,
        private fundService: FundService,
        private notificationService: NotificationsService,
        private clientService: ClientService,
        private supportingDocService: TaskDocumentsService,
        private investmentService: InvestmentService,
        private authService: AuthService,
        private messageService: DboMessageService,
        private userService: AuthService,
        private router: Router,
        private attachmentTagsService: AttachmentTagsService
    ) { }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.fundsList) {
            this.funds = this.fundsList;
            this.funds$ = this.fundSubject$.pipe(map((term) => this.searchFunds(term)));
        }
        if (changes.investmentList) {
            this.investments = this.investmentList;
            this.investment$ = this.investmentSubject$.pipe(map((term) => this.searchInvestments(term)));
        }
        if (changes.allDocTypes) {
            this.filteredDocTypes = this.allDocTypes;
        }
        if (changes.clientsList) {
            this.clients$ = this.clientSubject$.pipe(
                map((term) => {
                    return this.searchClients(term);
                }),
            );
        }
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        if (!this.attachment.metadata) {
            this.attachment.metadata = this.metadateService.getEmptyMetadataObject();
        }

        this.attachment.initialFundNote = '';
        this.attachment.initialInvestmentNote = '';

        this.attachment.validation = {
            clients: false,
            dateType: false,
            docType: false,
            fundInvestment: false,
        };
        this.userService.currentUser.subscribe((data) => (this.loggedInUserId = data.AuthId));
        this.checkIfAllAttachmentsProcessed();
        this.setClientTypeaheadSearch();

        this.taxFormYearValue = new Date().getFullYear() - 1;
        this.attachmentTagsService.getAll()
            .subscribe((data: IDboAttachmentTag[]) => this.attachmentTag = [...data])
    }

    private searchFunds(term: string | null): IVwFund[] {
        if (term) {
            return this.taskService.searchNameList(this.funds, term, 2);
        } else {
            return this.funds;
        }
    }

    private searchInvestments(term: string | null): IDboInvestment[] {
        return this.taskService.searchNameList(this.investments, term, 2);
    }

    private searchClients(term: string | null): IVwClient[] {
        if (term) {
            return this.taskService.searchNameList(this.attachment.potentialClients, term, 2);
        } else {
            return this.attachment.potentialClients;
        }
    }

    checkIfAllAttachmentsProcessed(): void {
        this.metadateService.announceTaskProcess(this.attachment);
    }

    fundSelected(event: any): void {
        this.attachment.metadata.Clients = [];
        this.attachment.metadata.ExcludeRelatedTaskCreation = false;
        this.clientsList = [];
        this.attachment.potentialClients = [];
        this.attachment.metadata.Fund = event;
        this.includeExited = false;
        this.attachment.metadata.PcIncludedFlag = false;
        this.disableSelectAllWhileClientsLoad = true;
        this.fundSubject$.next('');
        this.clearClientSearch();
        this.clientTypeaheadPlaceholderText = 'Loading...';
        if (!event) {
            this.filteredDocTypes = this.allDocTypes;
            this.attachment.metadata.TaskType = null;
            this.attachment.initialFundNote = '';
            this.disableSelectAllWhileClientsLoad = false;
        } else {
            this.attachment.initialFundNote = this.attachment.metadata.Fund.Note;
            if (this.attachment.metadata.Investment) {
                this.attachment.metadata.Clients = [];
                this.attachment.metadata.TaskType = null;
            }
            this.attachment.metadata.Investment = null;
            this.attachment.validation.fundInvestment = false;
            this.filteredDocTypes = this.allDocTypes.filter(
                (e) => e.TaskTypeBackingId === BackingEnum.Fund || e.TaskTypeBackingId === BackingEnum.Both,
            );
            this.clientService.getClientsByFundId(this.attachment.metadata.Fund.Id, this.includeExited).subscribe((data) => {
                this.appendShortNameClientTypeToClient(data);
                this.disableSelectAllWhileClientsLoad = false;
                if (this.attachment.metadata.TaskType && this.attachment.metadata.TaskType.TierId === TaskTier.Tier2) {
                    this.selectAllClients();
                }
            });
        }
    }

    investmentSelected(event: any): void {
        this.attachment.metadata.Clients = [];
        this.clientsList = [];
        this.attachment.potentialClients = [];
        this.attachment.metadata.Investment = event;
        this.includeExited = false;
        this.attachment.metadata.PcIncludedFlag = false;
        this.clearClientSearch();
        this.clientTypeaheadPlaceholderText = 'Loading...';
        if (!event) {
            this.filteredDocTypes = this.allDocTypes;
            this.attachment.metadata.TaskType = null;
            this.attachment.initialInvestmentNote = '';
        } else {
            if (this.attachment.metadata.Fund) {
                this.attachment.metadata.Clients = [];
                this.attachment.metadata.TaskType = null;
            }
            this.attachment.initialInvestmentNote = this.attachment.metadata.Investment.Note;
            this.attachment.metadata.Fund = null;
            this.attachment.validation.fundInvestment = false;
            this.filteredDocTypes = this.allDocTypes.filter(
                (e) => e.TaskTypeBackingId === BackingEnum.Investment || e.TaskTypeBackingId === BackingEnum.Both,
            );
            this.clientService.getClientsByInvestmentId(this.attachment.metadata.Investment.Id).subscribe((data) => {
                this.appendShortNameClientTypeToClient(data);
                if (this.attachment.metadata.TaskType && this.attachment.metadata.TaskType.TierId === TaskTier.Tier2) {
                    this.selectAllClients();
                }
            });
        }
    }


    attachmentTagSelected(event: any): void {
        this.attachment.metadata.attachmentTags = this.attachmentTag.filter((at) => event.includes(at.Id));
    }

    clientSelect(event: any): void {
        this.attachment.metadata.Clients = event;
        this.attachment.validation.clients = false;
    }

    clearClientSearch(): void {
        this.clientSubject$.next('');
    }

    onDocumentTypeChange(event: IDboTaskType): void {
        if(event?.Id === TaskType.EstimatedPartnersCapitalStatement || event?.Id === TaskType.AuditedFinancialStatement){
            this.documentTypesWarningModal.show();
        }

        if (event && event.DboTaskTypeBacking && event.DboTaskTypeBacking.Name === 'Tax Position') {
            this.isTaxDocument = true;
        } else {
            this.isTaxDocument = false;
        }

        this.isDistributionNotice = event && TaskType.DistributionNotice === event.Id;

        this.attachment.metadata.DueDate = null;
        this.attachment.metadata.StatementDate = this.attachment.metadata.StatementDate !== null ? this.attachment.metadata.StatementDate : null;
        this.attachment.metadata.TaxFormYear = null;
        this.attachment.metadata.TaskType = event;
        this.attachment.metadata.PcIncludedFlag = false;
        if (!event) {
            this.attachment.metadata.Fund = null;
            this.attachment.metadata.Investment = null;
        }
        if (event) {
            this.attachment.validation.docType = false;
            this.attachment.metadata.TaskType = event;
            this.attachment.metadata.TaskDateType = event.TaskDateType;
            this.includeExited = false;
            this.attachment.validation.dateType = false;
            if (
                this.attachment.metadata.TaskType.TierId === TaskTier.Tier2 &&
                (this.attachment.metadata.Fund || this.attachment.metadata.Investment)
            ) {
                this.selectAllClients();
            }
        }
    }

    showStatementDate(): boolean {
        return this.attachment.metadata.TaskType && this.attachment.metadata.TaskType.TaskDateType === TaskDateType.StatementDate;
    }

    showDueDate(): boolean {
        return this.attachment.metadata.TaskType && this.attachment.metadata.TaskType.TaskDateType === TaskDateType.DueDate;
    }

    showTaxFormYear(): boolean {
        if (this.attachment.metadata.TaskType && this.attachment.metadata.TaskType.TaskDateType === TaskDateType.TaxFormYear) {
            this.attachment.metadata.TaxFormYear = this.taxFormYearValue;
        }
        return this.attachment.metadata.TaskType && this.attachment.metadata.TaskType.TaskDateType === TaskDateType.TaxFormYear;
    }

    showReceivedDate(): boolean {
        return (
            (this.attachment.metadata.TaskType && this.attachment.metadata.TaskType.TaskDateType === TaskDateType.ReceivedDateOnly) ||
            (this.attachment.metadata.TaskType && this.attachment.metadata.TaskType.TaskDateType === null)
        );
    }

    dueDateSelection(event: Date): void {
        this.attachment.metadata.DueDate = event;
        this.attachment.metadata.TaxFormYear = null;
        this.attachment.metadata.StatementDate = null;
        this.attachment.validation.dateType = false;
    }

    statementDateSelection(event: Date): void {
        this.attachment.metadata.StatementDate = event;
        this.attachment.metadata.DueDate = null;
        this.attachment.metadata.TaxFormYear = null;
        this.attachment.validation.dateType = false;
    }

    taxFormYearSelection(event: number): void {
        this.taxFormYearValue = event;
        this.attachment.metadata.TaxFormYear = this.taxFormYearValue;
        this.attachment.metadata.StatementDate = null;
        this.attachment.metadata.DueDate = null;
        this.attachment.validation.dateType = false;
    }

    duplicateAttachment(): void {
        this.messageDocumentService.duplicateDocument(this.attachment.MessageId, this.attachment.AttachmentId).subscribe((data) => {
            const newAttachment: IAttachmentWithMetadata = JSON.parse(JSON.stringify(this.attachment));
            newAttachment.AttachmentId = data.Id;
            newAttachment.Name = data.Name;
            newAttachment.potentialClients = this.attachment.potentialClients;
            const newAttachmentMeta = newAttachment.metadata;
            newAttachmentMeta.Fund = this.attachment.metadata.Fund;
            newAttachmentMeta.Investment = this.attachment.metadata.Investment;
            newAttachmentMeta.TaskType = this.attachment.metadata.TaskType;
            newAttachmentMeta.TaskDateType = this.attachment.metadata.TaskDateType;
            newAttachmentMeta.DueDate = this.attachment.metadata.DueDate;
            newAttachmentMeta.StatementDate = this.attachment.metadata.StatementDate;
            newAttachmentMeta.TaxFormYear = this.attachment.metadata.TaxFormYear;
            newAttachmentMeta.SupportingDocs = [...this.attachment.metadata.SupportingDocs];
            newAttachmentMeta.PcIncludedFlag = this.attachment.metadata.PcIncludedFlag;

            this.attachmentUpdateService.addAttachment(newAttachment);
        });
    }

    appendAttachmentToList(attachment: IAttachmentWithMetadata): void {
        const indexToSpliceInAt = this.allAttachments.map((e) => e.MessageId).lastIndexOf(attachment.MessageId);
        attachment.Number = this.allAttachments[indexToSpliceInAt].Number + 1;
        this.allAttachments.splice(indexToSpliceInAt + 1, 0, attachment);
    }

    addAttachment(): void {
        this.modalService.showModal(this.attachment, this.allAttachments);
    }

    updateFundNote(event: any): void {
        this.fundService.updateNote(this.attachment.metadata.Fund.Id, this.attachment.metadata.Fund.Note).subscribe(() => {
            this.notificationService.success('Fund Note updated');
            this.attachment.initialFundNote = this.attachment.metadata.Fund.Note;
        });
    }

    updateInvestmentNote(event: any): void {
        this.investmentService.updateNote(this.attachment.metadata.Investment.Id, this.attachment.metadata.Investment.Note).subscribe(() => {
            this.notificationService.success('Investment Note updated');
        });
    }

    selectAllClients(): void {
        if (this.attachment.potentialClients !== undefined && this.attachment.potentialClients.length > 0) {
            this.attachment.metadata.Clients = this.attachment.potentialClients;
        } else {
            this.attachment.metadata.Clients = [];
        }
        this.attachment.validation.clients = false;
    }

    unselectAllClients(): void {
        this.attachment.metadata.Clients = [];
    }

    appendShortNameClientTypeToClient(clients: IVwClient[]): void {
        clients.forEach((c) => (c.Name = `${c.Name} - (${c.ClientType}) - (${c.ClientTypeValue})`));
        this.attachment.potentialClients = clients;
        this.clientsList = clients;
        this.setClientTypeaheadSearch();
    }

    toggleExited(): void {
        this.includeExited = !this.includeExited;
        this.clientService.getClientsByFundId(this.attachment.metadata.Fund.Id, this.includeExited).subscribe((data) => {
            this.appendShortNameClientTypeToClient(data);
            this.attachment.metadata.Clients = this.attachment.metadata.Clients.filter((c) =>
                this.attachment.potentialClients.some((b) => b.Id === c.Id),
            );
        });
    }

    toggleAddSupportingDocs(): void {
        this.showAddSupportingDocs = !this.showAddSupportingDocs;
    }

    addSupportingDoc(event: FileItem): void {
        const uploadedFile = event._file;
        this.messageDocumentService.saveUnassociatedDocument(this.attachment.AttachmentId, uploadedFile).subscribe((data: IDboAttachment) => {
            this.attachment.metadata.SupportingDocs = [...this.attachment.metadata.SupportingDocs, data];
            this.toggleAddSupportingDocs();
        });
    }

    onWhenAddingFileFailed(event: any): void {
        this.notificationService.error('Error adding document');
    }

    removeFromSupportingDocList(index: number): void {
        this.attachment.metadata.SupportingDocs.splice(index, 1);
    }

    onSupportingDocChanged(event: any): void {
        const addedAttachment: IDboAttachment = {
            ContentType: event.ContentType,
            FileName: event.AttachmentFileName,
            Id: event.AttachmentId,
            MessageId: event.MessageId,
            Name: event.Name,
        };
        this.attachment.metadata.SupportingDocs = [...this.attachment.metadata.SupportingDocs, addedAttachment];
        this.toggleAddSupportingDocs();
    }

    sendUpdatedMessages(): void {
        this.updateMessageWithAttachments.emit(this.allAttachments);
    }

    showCopyDownWarningModal(): void{
        this.copyDownWarningModal.show()
    }

    copyDownAll(event: any): void {
        this.showCopyDownWarningModal();
        if (this.checkFundOrInvestment()) {
            const currAttachmentIndex = this.allAttachments.indexOf(this.attachment);
            for (let i = currAttachmentIndex; i < this.allAttachments.length; i++) {
                if (!this.allAttachments[i].IsIgnored && !this.allAttachments[i].IsProcessed) {
                    const currAttachment = this.allAttachments[i].metadata;
                    const originAttachment = this.attachment.metadata;

                    currAttachment.Fund = originAttachment.Fund;
                    currAttachment.Investment = originAttachment.Investment;
                    currAttachment.TaskType = originAttachment.TaskType;
                    currAttachment.Clients = originAttachment.Clients;
                    currAttachment.TaskDateType = originAttachment.TaskDateType;
                    currAttachment.DueDate = originAttachment.DueDate;
                    currAttachment.TaxFormYear = originAttachment.TaxFormYear;
                    currAttachment.StatementDate = originAttachment.StatementDate;
                    currAttachment.SupportingDocs = [...originAttachment.SupportingDocs];
                    currAttachment.PcIncludedFlag = originAttachment.PcIncludedFlag;
                    currAttachment.IsPCAPAudited = originAttachment.IsPCAPAudited;
                    currAttachment.Password = originAttachment.Password;
                    currAttachment.attachmentTags = [...originAttachment.attachmentTags];
                    currAttachment.attachmentTagIds = [...originAttachment.attachmentTagIds]

                    this.allAttachments[i].initialFundNote = this.attachment.initialFundNote;
                    this.allAttachments[i].initialInvestmentNote = this.attachment.initialInvestmentNote;
                    this.allAttachments[i].validation = { ...this.attachment.validation };
                    this.allAttachments[i].potentialClients = [...this.attachment.potentialClients];
                }
            }
            this.sendUpdatedMessages();
        }
    }

    copyDownFundDateOnly(event: any): void {
        this.showCopyDownWarningModal();
        if (this.checkFundOrInvestment()) {
            if (!this.checkDateValidity()) {
                this.notificationService.warning('Date Field has not been populated and will not be copied down.');
            }
            const currAttachmentIndex = this.allAttachments.indexOf(this.attachment);
            for (let i = currAttachmentIndex; i < this.allAttachments.length; i++) {
                if (!this.allAttachments[i].IsIgnored && !this.allAttachments[i].IsProcessed) {
                    const currAttachment = this.allAttachments[i].metadata;
                    const originAttachment = this.attachment.metadata;

                    currAttachment.Fund = originAttachment.Fund;
                    currAttachment.Investment = originAttachment.Investment;
                    currAttachment.TaskDateType = originAttachment.TaskDateType;
                    currAttachment.DueDate = originAttachment.DueDate;
                    currAttachment.TaxFormYear = originAttachment.TaxFormYear;
                    currAttachment.StatementDate = originAttachment.StatementDate;

                    this.allAttachments[i].initialFundNote = this.attachment.initialFundNote;
                    this.allAttachments[i].initialInvestmentNote = this.attachment.initialInvestmentNote;
                    this.allAttachments[i].validation = { ...this.attachment.validation };
                    this.allAttachments[i].potentialClients = [...this.attachment.potentialClients];
                }
            }
            this.sendUpdatedMessages();
        }
    }

    checkFundOrInvestment(): boolean {
        const fundOrInvestment = this.attachment.metadata.Fund || this.attachment.metadata.Investment ? true : false;
        if (!fundOrInvestment) {
            this.notificationService.error('Must include a fund/investment to copy down');
        }
        return fundOrInvestment;
    }

    markAttachmentProcessed(markProcessed = true): void {
        if (this.isValid() && markProcessed) {
            const attachmentMetadataPackage: IAttachmentWithMetadataDTO = {
                attachment: {
                    AttachmentFileName: this.attachment.AttachmentFileName,
                    AttachmentId: this.attachment.AttachmentId,
                    AttachmentName: this.attachment.AttachmentName,
                    ContentType: this.attachment.ContentType,
                    MessageId: this.attachment.MessageId,
                    Name: this.attachment.Name,
                    ReceivedDate: this.attachment.ReceivedDate,
                    SenderName: this.attachment.SenderName,
                    Subject: this.attachment.Subject,
                },
                authUserID: 0,
                metadata: this.attachment.metadata,
            };
            if (attachmentMetadataPackage.metadata.TaskType.CheckForDuplicates) {
                this.taskService.checkForSimilarTasks(attachmentMetadataPackage).subscribe((similarTasksExist) => {
                    if (similarTasksExist) {
                        this.similarTasksWarningModal.show();
                    } else {
                        this.setAttachmentToPendingAndCheckProcessedCount();
                    }
                });
            } else {
                this.setAttachmentToPendingAndCheckProcessedCount();
            }
            this.assignRelatedAttachments();
        } else {
            this.attachment.IsPending = false;
            this.checkIfAllAttachmentsProcessed();
        }
    }

    assignRelatedAttachments(): void {
        const documentTypeId = this.attachment.metadata.TaskType.Id;
        this.numberOfRelatedFunds = 0;
        this.attachment.metadata.RelatedFunds = [];
        this.isAnchorFundAllClientsNotSelected = this.attachment.metadata?.Clients.length !== this.attachment?.potentialClients.length;
        if (
            documentTypeId === TaskType.AuditedFinancialStatement ||
            documentTypeId === TaskType.EstimatedFinancialStatement ||
            documentTypeId === TaskType.FinancialStatement ||
            documentTypeId === TaskType.InvestmentSchedule ||
            documentTypeId === TaskType.Report
        ) {
            const anchorFundId = this.attachment?.metadata?.Fund?.Id;
            this.fundService.getRelatedFunds(anchorFundId).subscribe((relatedFundIds: number[]) => {
                this.numberOfRelatedFunds = relatedFundIds.length;
                if (this.isAnchorFundAllClientsNotSelected && this.numberOfRelatedFunds > 0) {
                    return;
                }

                for (const relatedFundId of relatedFundIds) {
                    this.clientService.getClientsByFundId(relatedFundId, true).subscribe((clientsList: IVwClient[]) => {
                        if (clientsList?.length) {
                            const fund = this.fundsList.find((f) => f.Id === relatedFundId);
                            this.attachment.metadata.RelatedFunds.push(fund);
                        }
                    });
                }
            });
        }
    }

    setAttachmentToPendingAndCheckProcessedCount(): void {
        this.attachment.IsPending = true;
        this.similarTasksWarningModal.close();
        this.checkIfAllAttachmentsProcessed();
    }

    isValid(): boolean {
        const hasFundOrInvestment = this.attachment.metadata.Fund || this.attachment.metadata.Investment ? true : false;
        const hasClients = this.attachment.metadata.Clients.length ? true : false;
        const hasDocType = this.attachment.metadata.TaskType ? true : false;
        const hasDate = this.checkDateValidity();

        if (!hasDate) {
            this.attachment.validation.dateType = true;
            if (this.attachment.metadata.TaskDateType) {
                if (this.attachment.metadata.TaskDateType === TaskDateType.StatementDate) {
                    this.statementDateField.openButton.nativeElement.focus();
                }
            }
        }
        if (!hasDocType) {
            this.attachment.validation.docType = true;
            this.docTypeField.nativeElement.focus();
        }
        if (!hasClients) {
            this.attachment.validation.clients = true;
            this.clientField.focus();
        }
        if (!hasFundOrInvestment) {
            this.attachment.validation.fundInvestment = true;
            if (this.attachment.metadata.TaskType && this.attachment.metadata.TaskType.IsInvestmentOnly) {
                this.investmentField.focus();
            } else {
                this.fundField.focus();
            }
        }

        if (!(hasFundOrInvestment && hasClients && hasDocType && hasDate)) {
            this.notificationService.error('Required Data is Missing, Please update and resubmit attachment');
        }
        return hasFundOrInvestment && hasClients && hasDocType && hasDate;
    }

    checkDateValidity(): boolean {
        if (this.attachment.metadata.TaskDateType === TaskDateType.StatementDate) {
            return this.attachment.metadata.StatementDate ? true : false;
        } else if (this.attachment.metadata.TaskDateType === TaskDateType.DueDate) {
            return this.attachment.metadata.DueDate ? true : false;
        } else if (this.attachment.metadata.TaskDateType === TaskDateType.TaxFormYear) {
            return this.attachment.metadata.TaxFormYear ? true : false;
        } else if (this.attachment.metadata.TaskDateType === TaskDateType.ReceivedDateOnly) {
            return true;
        } else {
            return false;
        }
    }

    setClientTypeaheadSearch(): void {
        if (this.attachment.potentialClients !== undefined) {
            if (this.attachment.potentialClients.length > 0) {
                this.clientTypeaheadPlaceholderText = 'Type to Search';
            } else {
                this.clientTypeaheadPlaceholderText = 'No Clients Found';
            }
        }
        if (this.funds.length > 0) {
            this.clientTypeaheadPlaceholderText = 'Loading...';
        }
        this.clientTypeaheadPlaceholderText = 'Please select a fund';
    }

    expandPendingClientsList(): void {
        this.isPendingClientsListExpanded = !this.isPendingClientsListExpanded;
    }

    resizeTextArea(): void {
        this.fundNote.nativeElement.style.height = 'auto';
        this.fundNote.nativeElement.style.height = `${String(this.fundNote.nativeElement.scrollHeight)}px`;
    }
}
