import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { IMessageTag } from '../../model/interfaces/message-tag';
import { MessageTagService } from '../services/message-tag.service';

@Component({
    templateUrl: './message-tag-header.component.html',
})
export class MessageTagHeaderComponent implements OnInit {
    messageTag: IMessageTag;
    header$: Observable<string>;
    subscriptions: Subscription = new Subscription();

    constructor(private messageTagService: MessageTagService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('messageTagId');
        this.messageTagService.setInitialTitle(id);
        this.header$ = this.messageTagService.title$;
    }

}
