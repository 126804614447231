import { Component, HostListener, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { NavService } from '@mt-ng2/nav-module';
import { AuthService } from '@mt-ng2/auth-module';
import { environment } from './environments/environment';
import { PrintModeService } from '@mt-ng2/entity-list-module';
import { TabCountService } from './common/services/tab-count-service';

@Component({
    selector: 'app-root',
    template: `
        <div
            [hidden]="appReady === null"
            class="skin-blue sidebar-mini"
            [class.sidebar-open]="!sidebarCollapsed && showNav"
            [class.sidebar-collapse]="sidebarCollapsed || !showNav"
            [class.sidebar-mini]="showNav"
        >
            <div class="wrapper">
                <app-custom-nav></app-custom-nav>
                <div class="container-fluid content-wrapper" [style.min-height]="showFooter ? null : '100vh'">
                    <ng-progress thick="true" data-position="top"></ng-progress>
                    <router-outlet></router-outlet>
                    <br />
                </div>
                <mt-footer></mt-footer>
            </div>
        </div>
        <div [hidden]="appReady !== null" [style.position]="'relative'">
            <div class="pulse">
                <span></span>

                <img class="logo-img " src="{{ logoFull }}" alt="Logo" />
            </div>
        </div>
    `,
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'app';

    sidebarCollapsed = false;
    showNav = false;
    showFooter = false;
    appReady: boolean = null;
    logoFull = `${environment.imgPath}logo-full.png`;
    subscriptions: Subscription = new Subscription();

    @HostListener('window:beforeunload') onBeforeUnload(): void {
        this.tabCountService.decrementTabCount();
    }

    constructor(
        private navService: NavService,
        private authService: AuthService,
        private printModeService: PrintModeService,
        private tabCountService: TabCountService,
        vcr: ViewContainerRef,
    ) {}

    ngOnInit(): void {
        this.tabCountService.incrementTabCount();
        this.subscriptions.add(
            this.printModeService.printMode.subscribe((inPrintMode) => {
                this.navService.showNav.next(!inPrintMode);
                this.navService.showFooter.next(!inPrintMode);
            }),
        );
        this.subscriptions.add(
            this.authService.appReady.subscribe((answer) => {
                this.appReady = answer;
            }),
        );
        this.sidebarCollapsed = this.navService.sidebarCollapsed.getValue();
        this.subscriptions.add(
            this.navService.sidebarCollapsed.subscribe((sidebarCollapsed: boolean) => {
                this.sidebarCollapsed = sidebarCollapsed;
            }),
        );

        this.showNav = this.navService.showNav.getValue();
        this.subscriptions.add(
            this.navService.showNav.subscribe((showNav: boolean) => {
                this.showNav = showNav;
            }),
        );

        this.showFooter = this.navService.showFooter.getValue();
        this.subscriptions.add(
            this.navService.showFooter.subscribe((showFooter: boolean) => {
                this.showFooter = showFooter;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
