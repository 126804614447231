<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>
            <mt-route-back-button></mt-route-back-button>
            Managed Notes
        </h2>
    </div>
</div>
<div class="padded">
    <div class="row">
        <div class="selectContainer">
            <ng-select
                [items]="(managedNoteSubject$ | async) ? (managedNotes$ | async) : managedNotesList"
                placeholder="Search"
                bindLabel="Note"
                [virtualScroll]="true"
                [closeOnSelect]="false"
                [multiple]="true"
                [hideSelected]="true"
                [typeahead]="managedNoteSubject$"
                [(ngModel)]="selectedNotes"
                name="Content"
                (ngModelChange)="notesSelected($event)"
            >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <div class="typeaheadSelected">
                        <span aria-hidden="true" class="ng-value-icon left" (click)="clear(item)">×</span>
                        <span class="ng-value-label" title="{{ item.Name }}">{{ item.Note }}</span>
                    </div>
                </ng-template>

                <ng-template ng-option-tmp let-item="item">
                    <span class="ng-option-label typeaheadOption" title="{{ item.Note }}">{{ item.Note }}</span>
                </ng-template>
            </ng-select>
        </div>
        <div>
            <div class="pull-right">
                <button class="btn btn-transparent" type="button">
                    <input type="checkbox" [(ngModel)]="includeArchived" (ngModelChange)="includeArchivedChanged($event)" />
                    <span>Include Archived</span>
                </button>
            </div>
        </div>
    </div>

    <br />
    <mt-entity-list
        [entityListConfig]="entityListConfig"
        [(entities)]="selectedNotes.length ? selectedNotes : managedNotes"
        [total]="total"
        [(currentPage)]="currentPage"
        [(itemsPerPage)]="itemsPerPage"
        (pageChanged)="getNotes()"
    >
    </mt-entity-list>

    <div class="fab-wrap-b-r" (click)="displayAddNote()">
        <a class="btn btn-primary btn-fab-lg">
            <span class="fa fa-plus"></span>
        </a>
    </div>
</div>
