export enum UserRoleEnum {
    InternalAdmin = 1,
    InternalUser = 2,
    ExternalAdmin = 3,
    ExternalUser = 4,
    SuperAdmin = 5,
}

export enum TenantTypeEnum {
    Internal = 1,
    External = 2,
}

export enum RoleTypeEnum {
    Admin = 1,
    User = 2,
    SuperAdmin = 3,
}

export enum BackingEnum {
    Investment = 1,
    Fund = 2,
    Both = 3,
}

export enum LogTypeEnum {
    MailingReceived = 1,
    MailingAssigned = 2,
    MailingForwarded = 9,
    MailingReplied = 10,
}

export enum AttachmentActionEnum {
    None = 1,
    AddAttachment = 2,
    IgnoreAttachment = 3,
    DuplicateAttachment = 4,
}

export enum TaskTier {
    Tier1 = 1,
    Tier2 = 2,
}

export enum InvestmentStatus {
    Active = 'Active',
    Exited = 'Exited',
    Sold = 'Sold',
}

export enum MessageState {
    PendingSort = 1,
    Ignored = 2,
    Processed = 3,
    Sent = 4,
    Duplicative = 5,
}

export enum AttachmentState {
    NotYetDispatched = 1,
    Ignored = 2,
    Processed = 3,
    DoesNotGetProcessed = 6,
    Sent = 8,
    Duplicative = 9,
}

export enum ReplyTypes {
    None = 0,
    Reply = 1,
    ReplyAll = 2,
    Forward = 3,
}

export enum MessageSearchStatus {
    UnProcessed = 1,
    NoAction = 2,
    Processed = 3,
}

export enum MessageSearchTaxStatus {
    Tax = 1,
    NonTax = 2,
}

export enum LockStatuses {
    Unlocked = 1,
    Locked = 2,
}

export enum LocalStorageKeys {
    isProcessingTabOpen = 'isProcessingTabOpen',
    selectedMessageIds = 'selectedMessageIds',
    tabCount = 'tabCount',
}

export enum UserTypesAuditLog {
    PMC = 0,
    ShoreGroup,
    Other,
}

export enum TaskMsgLogType {
    MailingReceived = 1,
    MailingAssigned = 2,
    AutomationUpdatedByApi = 3,
    MailingStatusSet = 4,
    TagAssigned = 5,
    TagRemoved = 6,
    TagRuleApplied = 7,
    MailingForwarded = 9,
    MailingReplied = 10,
    TaskUpdatedReason = 11,
    MailingStatusSetProcessed = 12,
    MailingStatusSetUnprocessed = 13,
    MailingStatusSetIgnored = 14,
}

export enum TaskType {
    FinancialStatement = 1,
    Report = 2,
    PartnersCapitalStatement = 3,
    AuditedFinancialStatement = 4,
    InvestmentSchedule = 5,
    DistributionNotice = 10,
    EstimatedFinancialStatement = 35,
    EstimatedPartnersCapitalStatement = 36,
}

export enum TaskStatuses {
    Created = 1,
    Renamed = 2,
    Pass = 3,
    Exception = 4,
    NoReviewNeeded = 5
}