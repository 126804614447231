import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../model/ClaimTypes';
import { ClientsComponent } from './client-list/clients.component';

const clientListRoleGuard: any = {
    claimType: ClaimTypes.Clients,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Clients',
};
const clientRoutes: Routes = [{ path: 'clients', component: ClientsComponent, canActivate: [AuthGuard], data: clientListRoleGuard }];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(clientRoutes)],
})
export class ClientRoutingModule {}
