<div class="miles-card padded">
    <div class="form-group">
        <h4>Access Settings</h4>
        <div class="form-input">
            <span class="control-label">Allowed Document Types</span>
            <mt-search-filter-select [items]="taskTypeSelection" entity="Document Types"></mt-search-filter-select>
        </div>
        <button type="submit" class="btn btn-success" (click)="update()">
            Save
        </button>
    </div>
</div>
