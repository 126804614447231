import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { IDboTaskType } from '../../model/interfaces/dbo-task-type';
import { TaskTypeService } from '../../tasks/task-doc-type-service';

@Component({
    templateUrl: './dbo-task-type-header.component.html',
})
export class DboTaskTypeHeaderComponent implements OnInit {
    dboTaskType: IDboTaskType;
    header$: Observable<string>;
    subscriptions: Subscription = new Subscription();

    constructor(private dboTaskTypeService: TaskTypeService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('dboTaskTypeId');
        this.dboTaskTypeService.setInitialTitle(id);
        this.header$ = this.dboTaskTypeService.title$;
    }
}
