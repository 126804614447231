<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>
            <mt-route-back-button></mt-route-back-button>
            {{ selectedFund?.Name }}
            <mt-audit-logs-for-record *ngIf="selectedFund" [recordId]="selectedFund.Id" entity="FundNote">
                <ng-template #auditModalTriggerTemplate let-auditModalTemplateApi>
                    <button class="btn btn-primary" (click)="auditModalTemplateApi.showLogsModal($event)">Audit Log</button>
                </ng-template>
            </mt-audit-logs-for-record>
        </h2>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
        <mt-managed-list
            componentTitle="Alternative Names"
            [items]="items"
            [fields]="['Name']"
            [dynamicForm]="dynamicForm"
            [canAdd]="true"
            [canRemove]="true"
            [sortable]="true"
            [duplicateCheck]="true"
            (saved)="saveList($event)"
        ></mt-managed-list>
    </div>
</div>
