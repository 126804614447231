import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ICustomerSource } from '../../model/interfaces/customer-source';
import { MetaItemService } from '@mt-ng2/base-service';

@Injectable()
export class CustomerSourceService extends MetaItemService<ICustomerSource> {
    constructor(public http: HttpClient) {
        super('CustomerSourceService', 'Source', 'SourceIds', '/customersources', http);
    }
}
