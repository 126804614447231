/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

/**
 * component designed to provide events when it comes into or out of the viewport.
 */
@Component({
    selector: 'scroll-check',
    styles: [
        `
            .scrollCheck {
                position: relative;
            }
        `,
    ],
    template: '<span #scrollCheck class="scrollCheck" [style.bottom]="offset">{{ debuggingText }}</span>',
})
export class ScrollCheckComponent implements AfterViewInit {
    /**
     * string to apply to the style.bottom of this element, allowing for an offset of where it is visible
     * versus where it was placed in the html.
     *
     * default value '0px'
     */
    @Input() offset = '0px';
    /**
     * string to load in this element, allowing for visibility of the element for debugging purposes.  Only
     * intended for debugging usage.
     *
     * default value ''
     */
    @Input() debuggingText = '';

    @Output() inViewHasChanged = new EventEmitter<boolean>();
    @Output() cameIntoView = new EventEmitter<null>();
    @Output() wentOutOfView = new EventEmitter<null>();

    private _observer = new IntersectionObserver(this._onIntersect.bind(this), {
        rootMargin: '0px',
        threshold: 1.0,
    });

    @ViewChild('scrollCheck') private _scrollCheckElement: ElementRef;

    constructor() {}

    ngAfterViewInit(): void {
        this._observer.observe(this._scrollCheckElement.nativeElement as Element);
    }

    private _onIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver): void {
        const isOnScreen = entries[0].isIntersecting;
        this.inViewHasChanged.emit(isOnScreen);
        if (isOnScreen) {
            this.cameIntoView.emit();
        } else {
            this.wentOutOfView.emit();
        }
    }
}
