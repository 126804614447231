import { NgModule } from '@angular/core';
import { ModalModule } from '@mt-ng2/modal-module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EntityListModule } from '@mt-ng2/entity-list-module';
import { MtTagFilterSelectComponent } from './mt-search-tag/mt-search-tag';
import { TagMultiselectComponent } from './tag-multi-select/tag-multi-select';

@NgModule({
    declarations: [MtTagFilterSelectComponent, TagMultiselectComponent],
    exports: [MtTagFilterSelectComponent, TagMultiselectComponent],
    imports: [CommonModule, FormsModule, NgbModule],
})
export class TagSelectModule {}
