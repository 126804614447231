import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomerPhonesService {
    constructor(private http: HttpClient) {}

    saveCustomerPhones(customerId: number, phoneCollection: any): any {
        return this.http.put(`/customers/${customerId}/phones`, phoneCollection, { responseType: 'text' as 'json' });
    }
}
