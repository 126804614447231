import { Component, EventEmitter, Output, OnInit, Input, Injector, ViewChild, ElementRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IEntityListConfig, IEntity } from '@mt-ng2/entity-list-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IMessageRow } from '../message-list/message-list-component';
import { IUserWithFullName } from '../../model/partials/user';
import { AssignUserUpdateService } from './assignUserUpdateService';
import { Observable, Subject } from 'rxjs';
import { IUser } from '../../model/interfaces/user';
import { map } from 'rxjs/operators';
import { searchNameList } from '../../common/util';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
    selector: 'app-assignUser-modal',
    styles: [
        `
            .modalField {
                width: 40%;
                height: auto;
                display: flex;
                flex-wrap: nowrap;
                margin: auto;
                margin-bottom: 10px;
            }

            .modalField {
                text-align: left;
            }

            .modalInput {
                width: 40%;
            }
        `,
    ],
    templateUrl: './assignUserModal.html',
})
export class AssignUserModalComponent implements OnInit, AfterViewInit {
    @ViewChild('assignInput') assignInput: NgSelectComponent;
    modalOptions: IModalOptions = {
        showConfirmButton: false,
        width: '50%',
    };

    selectedMessages: IMessageRow[] = [];
    users: IUserWithFullName[] = [];
    selectedUser: IUserWithFullName;
    entityListConfig: IEntityListConfig;
    doubleClickIsDisabled = false;
    isEditing = false;
    modalService: AssignUserUpdateService;
    notificationService: NotificationsService;
    users$: Observable<IUserWithFullName[]>;
    public usersSubject$ = new Subject<string>();

    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSave: EventEmitter<void> = new EventEmitter<void>();

    ngOnInit(): void {
        this.users.forEach((x) => {
            x.Name = x.FirstName + ' ' + x.LastName;
        });
        this.users$ = this.usersSubject$.pipe(map((term) => this.searchUsers(term)));
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.assignInput.focus(), 1);
    }

    private searchUsers(term: string | null): IUserWithFullName[] {
        return searchNameList(this.users, term, 0);
    }

    cancel(): void {
        this.onCancel.emit();
    }

    assignUser(): void {
        if (this.selectedUser) {
            this.modalService.announceSelectMessages(this.selectedMessages);
            this.modalService.announceAssignUser(this.selectedUser);
            this.cancel();
            this.selectedUser = null;
        }
    }

    onUsersModelChange(event: any): void {
        this.selectedUser = event;
    }

    onUsersOpen(): void {
        this.usersSubject$.next('');
    }
}
