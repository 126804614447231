import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
    selector: 'app-stackable-modal',
    templateUrl: './stackable-modal.component.html',
    styleUrls: ['./stackable-modal.component.css'],
})
export class StackableModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @Output() onClose = new EventEmitter<void>();

    @ViewChild('modalDiv', { static: true }) modalDiv: ElementRef<HTMLDivElement> | undefined;

    constructor(private renderer: Renderer2) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.appendToBody();
    }

    private appendToBody() {
        if (this.modalDiv?.nativeElement) {
            // Use Renderer2 to append the element to the body
            this.renderer.appendChild(document.body, this.modalDiv.nativeElement);
        }
    }

    ngOnDestroy(): void {
        if (this.modalDiv?.nativeElement) {
            // Use Renderer2 to remove the element from the body
            this.renderer.removeChild(document.body, this.modalDiv.nativeElement);
        }
    }
}
