import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Subject, Subscription } from 'rxjs';
import { IPhone, IPhoneType, DefaultPhoneTypes } from '@mt-ng2/phone-control';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-custom-phone',
    templateUrl: './custom-phone.component.html',
})
export class CustomPhoneComponent implements OnInit, OnDestroy {
    @Input() editingComponent: Subject<string>;
    _canEdit: boolean;
    @Input('canEdit')
    get canEdit(): boolean {
        return this._canEdit;
    }
    set canEdit(value: boolean) {
        this._canEdit = value;
    }
    _canAdd: boolean;
    @Input('canAdd')
    get canAdd(): boolean {
        return this._canAdd;
    }
    set canAdd(value: boolean) {
        this._canAdd = value;
    }
    @Input('PhoneArray')
    set PhoneArray(value: any[]) {
        this._phones = value;
        this.phones = value;
        // this.selectedPhone = null;
    }
    get PhoneArray(): any[] {
        return this._phones;
    }

    @Input('PhoneTypes') PhoneTypes: IPhoneType[]; // phone types input
    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();

    private _phones: any[];

    private _isEditing = false;
    get isEditing(): boolean {
        // return this.selectedPhone && this._canEdit;
        return this._isEditing && this._canEdit;
    }
    set isEditing(value: boolean) {
        this._isEditing = value;
    }

    newPhone: IPhone = {
        Extension: '',
        IsPrimary: true,
        Phone: '',
        PhoneTypeId: 2,
    };

    phones = [];
    subscriber: Subscription = new Subscription();
    phoneForm: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.phoneForm = this.fb.group({});
        this.setVariables();
    }

    ngOnDestroy(): void {
        this.subscriber.unsubscribe();
    }

    markAllFormFieldsAsTouched(formGroup: UntypedFormGroup | UntypedFormArray): void {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched();
            } else if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
                this.markAllFormFieldsAsTouched(control);
            }
        });
    }

    setVariables(): void {
        const config: IEntityRouteConfig = <IEntityRouteConfig>this.route.parent.snapshot.data;
        if (this._canEdit === undefined) {
            this._canEdit = config && config.claimType ? this.claimsService.hasClaim(config.claimType, [ClaimValues.FullAccess]) : false;
        }
        if (this._canAdd === undefined) {
            this._canAdd = this._canEdit;
        }
        if (!this.PhoneTypes) {
            this.PhoneTypes = DefaultPhoneTypes;
        }
    }

    savePhones(): void {
        // TODO: is this how we handle touched?
        if (this.phoneForm.valid) {
            const phoneCollection = {
                Phones: this.getPhonesFromForm(this.phoneForm),
            };
            this.onSave.emit(phoneCollection);
            const phoneGroups = phoneCollection.Phones.sort((a, b) => {
                return (b.IsPrimary ? 1 : 0) - (a.IsPrimary ? 1 : 0);
            }).map((phone) => this.fb.group(phone));
            const phones = this.fb.array(phoneGroups);
            this.phoneForm.setControl('phones', phones);
            this.isEditing = false;
        } else {
            this.notificationsService.error('Save Failed');
            this.markAllFormFieldsAsTouched(this.phoneForm);
        }
    }

    private getPhonesFromForm(form: UntypedFormGroup): IPhone[] {
        const Phones: IPhone[] = [];
        form.value.phones.forEach((phone: IPhone) => {
            phone.Phone = phone.Phone.replace(/\D/g, '');
            if (phone.Phone && phone.Phone.length >= 10) {
                phone.Phone = phone.Phone.substring(0, 10);
                Phones.push(phone);
            }
        });
        return Phones;
    }

    selectPhone(): void {
        this.isEditing = true;
    }

    cancel(): void {
        this.isEditing = false;
    }
}
