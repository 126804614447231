import { IDboSupportingDocumentExtended } from './../../model/interfaces/custom/IDboSupportingDocumentExtended.d';
import { saveAs } from 'file-saver';
import { IDboSupportingDocument } from './../../model/interfaces/dbo-supporting-document.d';
import { Component, OnInit, Injector, Optional, Input } from '@angular/core';
import { CommonDocumentsComponent } from '@mt-ng2/entity-components-documents';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, IUserDetails } from '@mt-ng2/auth-module';
import { TaskDocumentsService } from '../task-document-service';
import { IEntitySearchParams, SearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';
import { SortDirection } from '@mt-ng2/entity-list-module';
import { IBlobDownload } from '../../model/interfaces/custom/blob-download';
import { IDboAttachment } from '../../model/interfaces/dbo-attachment';
import { IDboAttachmentExtended } from '../../model/interfaces/dbo-attachment-extended';
import { MimeTypes } from '../../common/constants/MimeTypes';
import { FILE_SIZE_LIMIT } from '../../common/constants/files';
import { IRelatedTaskDTO } from '../../model/interfaces/custom/IRelatedTaskDTO';
import { FileItem } from 'ng2-file-upload';

@Component({
    selector: 'app-task-document',
    templateUrl: './task-document.component.html',
})
export class TaskDocumentComponent extends CommonDocumentsComponent {
    get supportingDocs(): IDboSupportingDocumentExtended[] {
        return (this.documentArray as any) as IDboSupportingDocumentExtended[];
    }
    get hasFullAccess(): boolean {
        return this.canEdit;
    }

    get originalDoc(): IAttachmentChecked {
        return (this.attachment as any) as IAttachmentChecked;
    }

    @Input() attachment: IDboAttachment;
    @Input() relatedTasks: IRelatedTaskDTO[];

    includeArchived = false;
    checkAllSelection = false;
    _allowedMimeType = MimeTypes;
    maxFileSize = FILE_SIZE_LIMIT;

    /*  constructor(  myroute: ActivatedRoute,  myrouter: Router,  injector: Injector,  notificationsService: NotificationsService,  claimsService: ClaimsService,    config: IEntityComponentsDocumentsModuleConfig) {
      super(myroute, myrouter, injector, notificationsService, claimsService, config);
   } */

    includeArchivedChanged(): void {
        this.getDocuments();
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IncludeArchived',
                value: String(this.includeArchived),
            }),
        );

        return _extraSearchParams;
    }

    addDocuments(fileItem: FileItem): void{
        const file = fileItem._file;
        const taskIds = [this.parentId];
        taskIds.push(...this.relatedTasks.filter((rt) => rt.Checked === true).map((rt) => rt.Id));
        ((this._service as unknown) as TaskDocumentsService).saveTasksDocument(taskIds, file).subscribe(() => {
            this.notificationsService.success("Document Added Successfully");
            this.getDocuments();
        }, () => {
            this.notificationsService.error("Error Uploading Document");
        });
        this.isEditing = false;
    }

    getDocuments(): void {
        if (this.service) {
            const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();
            const searchparamProperties: IEntitySearchParams = {
                extraParams: _extraSearchParams,
                order: 'DateUpload',
                orderDirection: SortDirection.Desc,
                query: '',
                take: this._max,
            };
            const searchparams = new SearchParams(searchparamProperties);
            this.service.getDocuments(this.parentId, searchparams).subscribe((response) => {
                this._documentArray = response.body;
                this._total = +response.headers.get('X-List-Count');
            });
        }
    }

    deleteDoc(index): void {
        if (!this._documentsPassedIn) {
            this._service.deleteDocument(this.parentId, this._documentArray[index].Id).subscribe(() => {
                this.getDocuments();
            });
        }
        this.documentDeleted.emit(this._documentArray[index]);
    }

    downloadDoc(index): void {
        if (!this._documentsPassedIn) {
            window.open(((this._service as unknown) as TaskDocumentsService).getDocumentPath(this.parentId, this._documentArray[index].Id, true));
        } else {
            this.documentDownloaded.emit(this._documentArray[index]);
        }
    }

    downloadOriginalDoc(): void {
        window.open(((this._service as unknown) as TaskDocumentsService).getOrigDocumentPath(this.parentId, true));
    }

    downloadDocs(): void {
        const includeOriginalDoc = this.originalDoc.IsChecked ? true : false;
        const selectedDocIds = this._documentArray.filter((element) => (element as any).IsChecked === true).map((element) => element.Id);
        if (!this.isSelectionEmpty()) {
            ((this._service as unknown) as TaskDocumentsService)
                .downloadDocuments(this.parentId, selectedDocIds, includeOriginalDoc)
                .subscribe((x: IBlobDownload) => {
                    saveAs(x.blob, x.fileName);
                });
        }
    }

    isSelectionEmpty(): boolean {
        const selectedDocs = this.combineOriginalAndSubDocs()
            .filter((element) => element.IsChecked === true)
            .map((element) => element.Id);
        if (selectedDocs.length) {
            return false;
        }
        return true;
    }

    combineOriginalAndSubDocs(): any[] {
        return [...(this as any)._documentArray, this.originalDoc];
    }

    checkAll(): void {
        this.relatedTasks.forEach((item) => {
            if (this.checkAllSelection) {
                item.Checked = true;
            } else {
                item.Checked = false;
            }
        });
    }
}

interface IAttachmentChecked extends IDboAttachment {
    IsChecked?: boolean;
}
