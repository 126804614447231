import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService } from '@mt-ng2/auth-module';

import { AuthEntityService } from '../../auth-entity.service';
import { AuthUserDynamicConfig } from '../auth-user.dynamic-config';
import { IAuthUser } from '../../../model/interfaces/auth-user';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicField } from '@mt-ng2/dynamic-form';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginConfig } from '@mt-ng2/login-module';
import { LoginConfigOverride } from '../../../common/configs/login.config';
@Component({
    selector: 'app-auth-user-password',
    templateUrl: './auth-user-password.component.html',
})
export class AuthUserPasswordComponent implements OnInit {
    @Input('AuthUser') authUser: IAuthUser;
    @Input('canEdit') canEdit: boolean;
    @Output('updateVersion') updateVersion: EventEmitter<string> = new EventEmitter<string>();
    config: any = {};
    formFactory: AuthUserDynamicConfig<IAuthUser>;
    doubleClickIsDisabled = false;
    isEditing: boolean;
    isHovered: boolean;
    isPasswordEmpty = true;
    configControls: string[] = ['Password', 'ConfirmPassword', 'SendResetEmail'];
    form: UntypedFormGroup;
    passwordField: DynamicField;
    confirmPasswordField: DynamicField;
    sendEmailCheckbox: DynamicField;
    loginConfig = new LoginConfig(LoginConfigOverride);
    passwordRegexPattern = this.loginConfig.passwordPattern;
    failedPatternMessage = LoginConfigOverride.messageOverrides.failedPattern;
    passwordValue = '';

    constructor(
        private notificationsService: NotificationsService,
        private authEntityService: AuthEntityService,
        private authService: AuthService,
        private fb: UntypedFormBuilder,
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            AuthUser: this.fb.group({}),
        });
        this.createSendEmailCheckbox();
        this.isEditing = false;
        this.config = { formObject: [], viewOnly: [] };
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new AuthUserDynamicConfig<IAuthUser>(this.authUser, true, null, null, this.configControls, false, 0, this.isPasswordEmpty);
        this.config = this.formFactory.getForUpdate();
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancel(): void {
        this.isEditing = false;
    }

    createPasswordField(): DynamicField {
        return (this.passwordField = new DynamicField({
            failedPatternMessage: this.failedPatternMessage,
            formGroup: 'AuthUser',
            label: 'Password',
            name: 'Password',
            type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Password }),
            validation: [Validators.pattern(this.passwordRegexPattern)],
            validators: {
                pattern: this.passwordRegexPattern,
                required: false,
            },
            value: this.passwordValue,
        } as IDynamicField));
    }

    createConfirmPasswordField(): DynamicField {
        return (this.confirmPasswordField = new DynamicField({
            failedPatternMessage: this.failedPatternMessage,
            formGroup: 'AuthUser',
            label: 'Confirm Password',
            name: 'ConfirmPassword',
            type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Password }),
            validation: [Validators.pattern(this.passwordRegexPattern)],
            validators: {
                pattern: this.passwordRegexPattern,
                required: false,
            },
            value: '',
        } as IDynamicField));
    }

    createSendEmailCheckbox(isRequired = true): void {
        this.sendEmailCheckbox = new DynamicField({
            formGroup: 'AuthUser',
            label: 'Send Password Reset Email',
            name: 'SendResetEmail',
            type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Checkbox }),
            validators: { required: isRequired },
            value: '',
        } as IDynamicField);
    }

    updateSendEmail(value: string): void {
        if (value.length > 0) {
            this.createSendEmailCheckbox(false);
        }
    }

    onSubmit(): void {
        const form: UntypedFormGroup = this.form;
        if (form.value.AuthUser.Password.length === 0 && form.value.AuthUser.SendResetEmail.length === 0) {
            common.markAllFormFieldsAsTouched(form);
            this.error();
        } else {
            if (form.value.AuthUser.SendResetEmail) {
                this.authEntityService.sendResetPasswordEmail(this.authUser.Id).subscribe(() => {
                    this.notificationsService.success('Password reset link sent');
                    this.isEditing = false;
                });
            }

            if (form.value.AuthUser.Password.length > 0) {
                if (this.authService.matchPassword(form)) {
                    if (form.valid) {
                        this.authEntityService
                            .savePassword(this.authUser.Id, form.value.AuthUser.Password as string, form.value.AuthUser.ConfirmPassword as string)                            .subscribe(
                                (answer) => {
                                    this.success();
                                    this.isEditing = false;
                                    this.updateVersion.emit(answer);
                                },
                                (err) => {
                                    if (err.status === 418) {
                                        this.error('Please ensure password is correct.');
                                    } else {
                                        this.error();
                                    }
                                },
                            );
                    } else {
                        common.markAllFormFieldsAsTouched(form);
                        this.error();
                    }
                } else {
                    this.error('Passwords do not match.');
                }
            }
        }
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Password save failed.  Please check the form and try again. You may not be authorized to make this update.');
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Password Updated Successfully');
    }
}
