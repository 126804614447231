<span [class.hidden]="!enabled" ngbDropdown autoClose="outside" class="dropdown" #ngbDropdown="ngbDropdown" (openChange)="toggleOpen($event)">
    <button ngbDropdownToggle #openButton class="btn btn-transparent dropdown-toggle" type="button">
        <span [innerHtml]="displayDate"></span>
        <span class="caret"></span>
    </button>
    <div ngbDropdownMenu #dropdownField class="quarterlyZOrder">
        <div class="form-padding quarterlyZOrder">
            <div class="action-div row">
                <div class="col-md-6">
                    <label for="ContextualDateType">Select Quarter</label>
                    <select
                        #contextualField
                        class="form-control"
                        style="width: 100%;"
                        name="ContextualDateType"
                        [(ngModel)]="contextualDateType"
                        (ngModelChange)="contextualDateTypeChange($event)"
                    >
                        <option *ngFor="let type of contextualDateTypes" [ngValue]="type.Id"> {{ type.Name }}</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="Year">Select Year</label>
                    <input class="form-control" type="number" [(ngModel)]="selectedYear" (ngModelChange)="yearChanged($event)" />
                </div>
            </div>
            <div class="dateContainer">
                <app-date-picker
                    #selectDateCalendar
                    class="start-date"
                    style="width: 70%;"
                    label="Statement Date"
                    [value]="selectedDate"
                    [firstDayOfTheWeek]="firstDayOfTheWeek"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    (valueChanged)="selectedDateChanged($event)"
                    (timeChanged)="selectedTimeChanged($event)"
                    [showTimePicker]="false"
                ></app-date-picker>
                <div class="col-md-6 buttons">
                    <button (click)="applyChanges()" class="btn btn-primary btn-sm pull-right" type="button">
                        Apply
                    </button>
                    <button (click)="clearValues()" class="btn btn-default btn-sm pull-right btn-clear" type="button">
                        Clear
                    </button>
                </div>
            </div>
        </div>
    </div>
</span>
<span *ngIf="!enabled">
    <button disabled class="btn btn-transparent" type="button">
        <span [innerHtml]="displayDate"></span>
    </button>
</span>
