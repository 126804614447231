import { IVwClient } from './../model/partials/vw-client.d';
import { BaseService } from '@mt-ng2/base-service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IClientTenantParams } from '../model/interfaces/custom/client-tenant-params';
import { Observable, Subject } from 'rxjs';
import { IClient } from '../model/interfaces/custom/client';
import { INoteService } from '../model/interfaces/custom/EntityListConfigWithNotes';
import { IDboVCommitment } from '../model/interfaces/dbo-v-commitment';
import { IDboVClientAltName } from '../model/interfaces/dbo-v-client-alt-name';
import { IVClientInvestment } from '../model/interfaces/v-client-investment';

@Injectable({
    providedIn: 'root',
})
export class ClientService extends BaseService<IVwClient> implements INoteService {
    refreshClientsEvent = new Subject<IVwClient>();

    refreshClientsObservable = this.refreshClientsEvent.asObservable();

    constructor(public http: HttpClient) {
        super('/clients', http);
    }

    setArchivedStatus(clientId: number, archived: boolean): Observable<void> {
        return this.http.put<void>(`/clients/archive/${clientId}`, archived, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }

    setTransitionStatus(clientId: number, hasTransitioned: boolean): Observable<void> {
        return this.http.put<void>(`/clients/inTransit/${clientId}`, hasTransitioned, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }

    assignTenantsToClients(ctp: IClientTenantParams): Observable<void> {
        return this.http.put<void>('/clients/assignTenants', ctp);
    }

    getClientsMapping(clientIds: number[]): Observable<HttpResponse<IClient[]>> {
        return this.http.post<IClient[]>(`/clients/getClientMapping`, clientIds, { observe: 'response' });
    }

    refreshClients(updatedClient: IVwClient): void {
        this.refreshClientsEvent.next(updatedClient);
    }

    updateNote(id: number, note: string): Observable<void> {
        return this.http.post<void>(`/clients/note/${id}`, { Note: note });
    }

    getClientsByFundId(fundId: number, activeOnly: boolean): Observable<IVwClient[]> {
        return this.http.post<IVwClient[]>(`/clients/getClientsByFundId/${fundId}`, activeOnly, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }

    getClientsByInvestmentId(investmentId: number): Observable<IVwClient[]> {
        return this.http.get<IVwClient[]>(`/clients/getClientsByInvestmentId/${investmentId}`);
    }

    getDeals(investmentId: number, includeArchived = false): Observable<IVClientInvestment[]> {
        return this.http.post<IVClientInvestment[]>(`/clients/getDeals/${investmentId}`, includeArchived, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }

    getClientAltNames(clientId: number): Observable<IDboVClientAltName[]> {
        return this.http.get<IDboVClientAltName[]>(`/clients/getClientAltNames/${clientId}`);
    }

    toggleArchiveDeal(clientId: number, investmentId: number): Observable<void> {
        return this.http.post<void>(`/clients/toggleArchiveDeal/${investmentId}`, clientId, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }

    getByIds(clientIds: number[]): Observable<IVwClient[]> {
        return this.http.post<IVwClient[]>(`/clients/getByMultipleIds`, clientIds, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }
}
