import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../common/shared.module';
import { AttachmentTagsRoutingModule } from './attachment-tags-routing.module';
import { AttachmentTagListComponent } from './attachment-tag-list/attachment-tag-list.component';
import { AttachmentTagBasicInfoComponent } from './attachment-tag-basic-info/attachment-tag-basic-info.component';
import { MtColorPickerControlModule } from '@mt-ng2/color-picker-control';


@NgModule({
  declarations: [
    AttachmentTagListComponent,
    AttachmentTagBasicInfoComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    AttachmentTagsRoutingModule,
    MtColorPickerControlModule
  ]
})
export class AttachmentTagsModule { }
