import { InvestmentsEntityListConfig } from './investments.entity-list-config';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { InvestmentService } from '../investment-service';
import { entityListModuleConfig } from '../../common/shared.module';
import { IEntitySearchParams, ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { NotificationsService } from '@mt-ng2/notifications-module';
import {
    EntityListColumn,
    EntityListExportConfig,
    EntityListExportService,
    IColumnSortedEvent,
    IItemSelectedEvent,
    SortDirection,
} from '@mt-ng2/entity-list-module';
import { IDboInvestment } from '../../model/interfaces/dbo-investment';
import { Observable, Subscription } from 'rxjs';
import { NotesUpdatedService } from '../../common/components/notes-modal/notes-modal-updated-service';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { InvestmentAddDealsModalService } from '../investment-add-deals-modal/investmentAddDealsModal.service';
import { InvestmentAddDealsReportService } from '../investment-add-deals-modal/investmentAddDealsUpdateService';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';

@Component({
    selector: 'app-investment',
    templateUrl: './investments.component.html',
})
export class InvestmentsComponent implements OnInit, OnDestroy {
    query: string;
    investments: IDboInvestment[];
    total: number;
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    entityListConfig = new InvestmentsEntityListConfig(this.investmentService);
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    includeArchived = false;
    subscription = new Subscription();
    dealReportSubscription = new Subscription();
    canAddInvestment = false;

    constructor(
        private notesUpdatedObservable: NotesUpdatedService,
        private claimsService: ClaimsService,
        private router: Router,
        private exportService: EntityListExportService,
        public investmentService: InvestmentService,
        public notificationsService: NotificationsService,
        public dealUploadModalService: InvestmentAddDealsModalService,
        public dealReportService: InvestmentAddDealsReportService,
    ) {}
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.dealReportSubscription.unsubscribe();
    }

    ngOnInit(): void {
        if (!this.claimsService.hasClaim(ClaimTypes.TaxPositions_SeePage, [ClaimValues.FullAccess])) {
            void this.router.navigate(['/home']);
            return;
        }
        this.entityListConfig.export = new EntityListExportConfig({
            exportName: 'Tax Position List',
            getDataForExport: this.getInvestmentsForExport.bind(this),
        });
        this.canAddInvestment = this.claimsService.hasClaim(ClaimTypes.Investments, [ClaimValues.FullAccess]);
        this.subscription.add(
            this.notesUpdatedObservable.refreshLogsObservable.subscribe(() => {
                this.getInvestments();
            }),
        );
        this.dealReportSubscription.add(
            this.dealReportService.dealUploadReportObservable.subscribe((data) => {
                this.notificationsService.warning('There were errors with some lines in spreadsheet. Click to download report.');
            }),
        );
        this.getInvestments();
    }

    getInvestments(): void {
        this.getInvestmentsCall().subscribe((response) => {
            this.investments = response.body;
            this.total = +response.headers.get('X-List-Count');
        });
    }

    getInvestmentsCall(options = { forExport: false }): Observable<HttpResponse<IDboInvestment[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: String(this.includeArchived),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        if (options.forExport) {
            searchEntity.skip = 0;
            searchEntity.take = this.total;
        }
        const searchparams = new SearchParams(searchEntity);

        return this.investmentService.get(searchparams);
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getInvestments();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getInvestments();
    }

    includeArchivedChanged(event: boolean): void {
        this.includeArchived = event;
        this.getInvestments();
    }
    toggleArchive(event: any): void {
        const entity = event.entity as IDboInvestment;
        if (entity.IsArchived === null) {
            entity.IsArchived = false;
        }
        const archived = !entity.IsArchived;
        entity.IsArchived = archived;
        this.investmentService.toggleArchive(entity.Id, archived).subscribe(() => {
            this.getInvestments();
            this.notificationsService.success(`${archived ? 'Archived' : 'UnArchived'} successfully`);
        });
    }

    investmentSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/taxPositions', event.entity.Id]);
    }

    export(): void {
        this.getInvestmentsForExport().subscribe((data) => {
            const exportColumns = [
                new EntityListColumn({
                    accessorFunction: (investment: IDboInvestment) => investment.Id.toString(),
                    name: 'Tax Position Id',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        isDefaultForSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (investment: IDboInvestment) => investment.Name,
                    name: 'Tax Position Name',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                    },
                }),
            ];
            this.entityListConfig.columns = exportColumns;
            this.exportService.export(data, this.entityListConfig);
        });
    }

    getInvestmentsForExport(): Observable<IDboInvestment[]> {
        return this.getInvestmentsCall({ forExport: true }).pipe(map((answer) => answer.body));
    }

    uploadDeals(): void {
        this.dealUploadModalService.showModal();
    }
}
