import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { ICustomerSource } from '../interfaces/customer-source';

export interface ICustomerSourceDynamicControlsParameters {
    formGroup?: string;
}

export class CustomerSourceDynamicControls {
    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customersource?: ICustomerSource, additionalParameters?: ICustomerSourceDynamicControlsParameters) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'CustomerSource';

        this.Form = {
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(50)],
                validators: { required: true, maxlength: 50 },
                value:
                    this.customersource && this.customersource.hasOwnProperty('Name') && this.customersource.Name !== null
                        ? this.customersource.Name.toString()
                        : '',
            }),
            Sort: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sort',
                name: 'Sort',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.customersource && this.customersource.Sort) || 0,
            }),
        };

        this.View = {
            Name: new DynamicLabel({
			    label: 'Name',
			    value: this.customersource && this.customersource.hasOwnProperty('Name') && this.customersource.Name !== null
			        ? this.customersource.Name.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Sort: new DynamicLabel({
			    label: 'Sort',
			    value: (this.customersource && this.customersource.Sort) || 0,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
        };
    }
}
