import { CustomerDynamicControls, ICustomerDynamicControlsParameters } from '../form-controls/customer.form-controls';
import { ICustomer } from '../interfaces/customer';
import { DynamicField } from '@mt-ng2/dynamic-form';
import { ClaimTypes } from '../ClaimTypes';

export class CustomerDynamicControlsPartial extends CustomerDynamicControls {
    constructor(customer?: ICustomer, additionalParamters?: ICustomerDynamicControlsParameters) {
        super(customer, additionalParamters);

        // if we have an existing customer, than disabled state of Status is determined
        // using the Customer_CanChangeStatus ClaimType
        if (customer && customer.Id) {
            (this.Form.StatusId as DynamicField).disabled = ClaimTypes.Customers_CanChangeStatus;
        }
    }
}
