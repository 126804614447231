import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntity, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IMessageRow } from './message-list-component';
import { Subscription } from 'rxjs';
import { ContrastColorText } from '../../common/util';
import { MessageTagService } from '../../message-tags/services/message-tag.service';
import { IMessageTag } from '../../model/interfaces/message-tag';
import { DboMessageService } from '../services/dbo-message.service';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';

@Component({
    styles: [
        `
            .tagList {
                display: flex;
                overflow-y: hidden;
                max-width: 120px;
            }
            .tagItem {
                text-overflow: ellipsis;
                padding: 2px;
                border-radius: 5px;
                margin-right: 2px;
                white-space: nowrap;
                overflow: hidden;
                text-align: center;
                align-items: center;
                min-width: 20px;
            }
            .delete {
                padding-right: 4px;
                padding-left: 4px;
                margin-left: -4px;
                border-radius: 2px;
                width: 20px;
                display: inline;
                float: left;
                margin-top: auto;
                margin-bottom: auto;
            }

            .tagName {
                display: inline;
                text-align: center;
                overflow: hidden;
                margin-top: auto;
                margin-bottom: auto;
            }
        `,
    ],
    templateUrl: './tag-on-message-list.component.html',
})
export class TagOnMessageListComponent extends ContrastColorText implements IEntityListDynamicCellComponent, OnInit, OnDestroy {
    @Input() entity: IEntity;
    get message(): IMessageRow {
        return this.entity as IMessageRow;
    }
    set message(message: IMessageRow) {
        this.message = message;
    }
    tags: IMessageTag[] = [];
    currUser: ILoggedIn;

    entityListComponentMembers: IEntityListComponentMembers;
    subscription: Subscription = new Subscription();
    constructor(private tagService: MessageTagService, private messageService: DboMessageService, private authService: AuthService) {
        super();
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.tags = this.message.Tags;
        this.authService.currentUser.subscribe((data) => {
            this.currUser = data;
        });
    }

    deleteTag(tag: IMessageTag, index: number): void {
        this.tags.splice(index, 1);
        this.tagService.deleteTagFromMessage(this.message.EmailId, tag.Id, this.currUser.Id).subscribe(() => {
            this.messageService.messageTagRealtime(this.message);
        });
    }
}
