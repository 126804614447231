import { Component, OnInit, Input } from '@angular/core';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { TaskTypeService } from '../../../tasks/task-doc-type-service';
import { ITenant } from '../../../model/interfaces/tenant';
import { IDboTaskType } from '../../../model/interfaces/dbo-task-type';
import { TenantService } from '../../tenant.service';

@Component({
    selector: 'app-tenant-access-settings',
    templateUrl: './tenant-access-settings.component.html',
})
export class TenantAccessSettingsComponent implements OnInit {
    private _tenant: ITenant;
    get tenant(): ITenant {
        return this._tenant;
    }
    @Input() set tenant(value: ITenant) {
        this._tenant = value;
        this.setTaskTypeSelection();
    }
    taskTypes: IDboTaskType[];
    taskTypeSelection: MtSearchFilterItem[] = [];
    get ready(): boolean {
        return !!(this.tenant && this.taskTypes);
    }

    constructor(private taskTypeSvc: TaskTypeService, private tenantSvc: TenantService, private notifySvc: NotificationsService) {}

    ngOnInit(): void {
        this.taskTypeSvc.getItems().subscribe((items) => {
            this.taskTypes = items;
            this.setTaskTypeSelection();
        });
    }

    update(): void {
        if (this.ready) {
            this.tenantSvc
                .saveTenantAccess(
                    this.tenant.Id,
                    this.taskTypeSelection.filter((x) => !x.Selected).map((x) => (x.Item as IDboTaskType).Id),
                )
                .subscribe(() => {
                    this.notifySvc.success('Saved Successfully');
                });
        }
    }

    private setTaskTypeSelection(): void {
        if (this.ready) {
            this.taskTypeSelection = this.taskTypes.map(
                (dt) =>
                    new MtSearchFilterItem(
                        dt,
                        !this.tenant.TenantDenyTaskTypes || !this.tenant.TenantDenyTaskTypes.some((deny) => dt.Id === deny.TaskTypeId),
                    ),
            );
        }
    }
}
