<div class="row">
    <div class="col-md-7">
        <h2 [mtSetTitle]="header$">
            <mt-route-back-button></mt-route-back-button>
        </h2>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
        <div class="miles-editable miles-card padded" *ngIf="!isEditing" (mouseenter)="isHovered = true" (mouseleave)="isHovered = false">
            <h4>
                Attachment Tag Info
                <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered" (click)="edit()" [style.cursor]="canEdit ? 'pointer' : 'default'">
                    <i class="fa fa-lg fa-edit"></i>
                </span>
            </h4>
            <mt-dynamic-view [config]="config.viewOnly"></mt-dynamic-view>
        </div>
        <div *ngIf="isEditing" class="miles-form padded">
            <h4>Attachment Tag Info</h4>
            <mt-dynamic-form [config]="config.formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)">
                <div *ngIf="attachmentTag">
                    <button type="submit" mtDisableClickDuringHttpCalls class="btn btn-flat btn-success">
                        Save
                    </button>
                    <button type="button" class="btn btn-flat btn-default" (click)="cancelClick()">
                        Cancel
                    </button>
                </div>
            </mt-dynamic-form>
            <br />
        </div>
    </div>
</div>
