import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IDboFundAlternativeName } from '../interfaces/dbo-fund-alternative-name';

export interface IDboFundAlternativeNameDynamicControlsParameters {
    formGroup?: string;
}

export class DboFundAlternativeNameDynamicControls {
    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private dbofundalternativename?: IDboFundAlternativeName, additionalParameters?: IDboFundAlternativeNameDynamicControlsParameters) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'DboFundAlternativeName';

        this.Form = {
            FundId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Fun',
                name: 'FundId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: (this.dbofundalternativename && this.dbofundalternativename.FundId) || null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(50)],
                validators: { required: true, maxlength: 50 },
                value:
                    this.dbofundalternativename && this.dbofundalternativename.hasOwnProperty('Name') && this.dbofundalternativename.Name !== null
                        ? this.dbofundalternativename.Name.toString()
                        : '',
            }),
        };

        this.View = {
            FundId: new DynamicLabel({
			    label: 'Fun',
			    value: (this.dbofundalternativename && this.dbofundalternativename.FundId) || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            Name: new DynamicLabel({
			    label: 'Name',
			    value: this.dbofundalternativename && this.dbofundalternativename.hasOwnProperty('Name') && this.dbofundalternativename.Name !== null
			        ? this.dbofundalternativename.Name.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };
    }
}
