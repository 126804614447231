import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef, EventEmitter } from '@angular/core';
import { SharedModule } from '../../common/shared.module';
import { take } from 'rxjs/operators';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IMessageRow } from '../message-list/message-list-component';
import { AssignTagModalComponent } from './assignTagModal';
import { IMessageTag } from '../../model/interfaces/message-tag';
import { AssignTagUpdateService } from './assignTagUpdateService';
import { MessageTagService } from '../../message-tags/services/message-tag.service';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

@Injectable({
    providedIn: SharedModule,
})
export class AssignTagModalService {
    private _componentRef: ComponentRef<AssignTagModalComponent>;

    saved: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        private modalService: AssignTagUpdateService,
        private notificationService: NotificationsService,
        private messageTagService: MessageTagService,
    ) {}

    showModal(selectedMessages: IMessageRow[], tags: MtSearchFilterItem[] = []): void {
        this.destroyComponent();
        this._componentRef = this.componentFactoryResolver.resolveComponentFactory(AssignTagModalComponent).create(this.injector);
        this._componentRef.instance.selectedMessages = selectedMessages;
        this._componentRef.instance.tags = tags;
        this._componentRef.instance.modalService = this.modalService;
        this._componentRef.instance.messageTagService = this.messageTagService;
        this._componentRef.instance.notificationService = this.notificationService;
        this._componentRef.instance.onSave.pipe(take(1)).subscribe(() => this.saved.emit());
        this._componentRef.instance.onCancel.pipe(take(1)).subscribe(() => {
            this.destroyComponent();
        });

        this.appRef.attachView(this._componentRef.hostView);
        const domElem = (this._componentRef.hostView as EmbeddedViewRef<AssignTagModalComponent>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
    }

    private destroyComponent(): void {
        if (this._componentRef) {
            this.appRef.detachView(this._componentRef.hostView);
            this._componentRef.destroy();
            this._componentRef = null;
        }
    }
}
