import { Component, OnInit } from '@angular/core';
import { IDboInvestment } from '../../model/interfaces/dbo-investment';
import { InvestmentService } from '../investment-service';

@Component({
    templateUrl: './investment-add.component.html',
})
export class InvestmentAddComponent implements OnInit {
    investment: IDboInvestment;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private investmentService: InvestmentService) {}

    ngOnInit(): void {
        this.investment = this.investmentService.getEmptyInvestment();
    }
}
