import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormRadioButtonListComponent } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-custom-radio',
    styles: [
        `
            .inside-box-validation {
                position: relative !important;
                top: -35px;
                bottom: -35px;
                margin-bottom: -35px;
                right: 3px;
                float: right;
                font-size: 70%;
            }
            .inside-box-maxlength {
                position: relative !important;
                bottom: -13px;
                top: -13px;
                margin-bottom: -13px;
                right: 3px;
                font-size: 70%;
            }
        `,
    ],
    templateUrl: './customFormRadioButton.html',
})
export class FormRadioButtonListCustomComponent extends FormRadioButtonListComponent {}
