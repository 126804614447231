<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>Document Types</h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <br />
</div>
<div class="row">
    <div class="pull-right">
        <mt-search-filter-select [items]="backingTypes" entity="Backing Type" (selectionChanged)="selectedBackingTypesChanged($event)">
        </mt-search-filter-select>
        <mt-search-filter-select [items]="tiers" entity="Tier" (selectionChanged)="selectedTiersChanged($event)"> </mt-search-filter-select>
        <mt-search-filter-select [items]="dateTypes" entity="Dates" (selectionChanged)="selectedDateTypesChanged($event)">
        </mt-search-filter-select>
        <button class="btn btn-transparent" type="button">
            <input type="checkbox" [(ngModel)]="includeArchive" (ngModelChange)="includeArchivedChanged($event)" />
            <span>Include Archived</span>
        </button>
        <br />
    </div>
</div>

<mt-entity-list
    [entities]="dboTaskTypes"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getDboTaskTypes()"
    (itemSelectedEvent)="dboTaskTypeSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
    (itemDeletedEvent)="toggleArchive($event)"
>
</mt-entity-list>

<div *ngIf="canAddDboTaskType" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/documentTypes', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
