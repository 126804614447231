<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && investment.Id > 0"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Tax Position Info
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered" (click)="edit()">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <mt-dynamic-view [config]="config.viewOnly"></mt-dynamic-view>
</div>

<div *ngIf="isEditing" class="miles-form padded">
    <div>
        <h4>Tax Position Info</h4>
        <mt-dynamic-form [config]="config.formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)">
            <div>
                <h4>Deals</h4>
            </div>
            <div>
                <span class="pull-right" *ngIf="!isNewInvestment">
                    <input type="checkbox" [(ngModel)]="includeArchived" (ngModelChange)="includeArchivedChanged($event)" />
                    <span>Include Archived</span>
                </span>
                <br />

                <div class="table-responsive dealTable">
                    <table class="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Client</th>
                                <th *ngIf="!isNewInvestment" style="text-align: center;">Archive</th>
                                <th *ngIf="isNewInvestment" style="text-align: center;">Remove</th>
                            </tr>
                        </thead>

                        <tbody *ngFor="let client of deals; let i = index">
                            <tr>
                                <td>
                                    <div>{{ client.Name }}</div>
                                </td>
                                <td *ngIf="!client.IsArchived && !client.Id">
                                    <div style="text-align: center;" (click)="toggleArchiveDeal(client.ClientId, client)">
                                        <i class="fa fa-trash"></i>
                                    </div>
                                </td>
                                <td *ngIf="client.IsArchived && !client.Id">
                                    <div style="text-align: center;" (click)="toggleArchiveDeal(client.ClientId, client)">
                                        <i class="fa fa-undo"></i>
                                    </div>
                                </td>
                                <td *ngIf="client.Id">
                                    <div style="text-align: center;" (click)="toggleArchiveDeal(client.ClientId, client, i)">
                                        <i class="fa fa-undo"></i>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="float: right;" (click)="openModal()">
                    <a class="btn btn-primary btn-fab">
                        <span class="fa fa-plus"></span>
                    </a>
                </div>
            </div>

            <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
                Cancel
            </button>
        </mt-dynamic-form>
    </div>
</div>
