import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { ICustomer } from '../../model/interfaces/customer';
import { CustomerService } from '../services/customer.service';

@Component({
    templateUrl: './customer-header.component.html',
})
export class CustomerHeaderComponent implements OnInit {
    customer: ICustomer;
    header$: Observable<string>;
    subscriptions: Subscription = new Subscription();

    constructor(private customerService: CustomerService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('customerId');
        this.customerService.setInitialTitle(id);
        this.header$ = this.customerService.title$;
    }
}
