<mt-modal-wrapper [options]="modalOptions" (cancelClick)="cancel()">
    <div class="miles-form padded">
        <h3>Save Search</h3>

        <div class="modalField">
            <label class="col-md-8">Selected Search</label>
            <div class="col-md-4">
                <select [(ngModel)]="entity" (ngModelChange)="selectedSearchChanged($event)">
                    <option *ngFor="let search of allMessageSearches" [ngValue]="search">{{search.Name}}</option>
                </select>
            </div>
        </div>

        <div class="modalField">
            <label class="col-md-8">Search Name</label>
            <div class="col-md-4">
                <input type="text" [(ngModel)]="newName" />
            </div>
        </div>

        <div class="modalField" *ngIf="orgFilterAccess">
            <label class="col-md-8">Is Organization Search</label>
            <div class="col-md-2">
                <input type="checkbox" [(ngModel)]="isOrgSearch" />
            </div>
        </div>

        <div class="row">
            <button type="button" class="btn btn-default" (click)="saveSearch()">Save Search</button>
            <button type="button" class="btn btn-default" (click)="cancel()">
                Cancel
            </button>
        </div>
    </div>
</mt-modal-wrapper>