import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef, EventEmitter } from '@angular/core';
import { SharedModule } from '../../common/shared.module';
import { take } from 'rxjs/operators';
import { ProcessAttachmentAddModalComponent } from './processAttachmentAddModal';
import { IAttachmentWithMetadata, AttachmentMetadataService } from '../attachment-meta-data.service';
import { DboMessageDocumentService } from '../../messages/services/dbo-message-document-service';
import { ProcessAttachmentUpdateService } from './processAttachmentUpdateService';
import { IDboAttachment } from '../../model/interfaces/dbo-attachment';
import { TaskDocumentsService } from '../../tasks/task-document-service';
import { InboxAttachmentAddModalComponent } from '../../messages/inbox-attachments/inboxAttachmentAddModal.component';
import { InboxAttachmentUpdateService } from '../../messages/inbox-attachments/InboxAttachmentUpdateService';
import { DboMessageService } from '../../messages/services/dbo-message.service';

@Injectable({
    providedIn: SharedModule,
})
export class ProcessAttachmentAddModalService {
    private _componentRef: ComponentRef<any>;
    private isProcessAttachmentsCall: boolean;

    saved: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        private modalService: ProcessAttachmentUpdateService,
        private inboxModalService: InboxAttachmentUpdateService,
        private documentService: DboMessageDocumentService,
        private metaDataService: AttachmentMetadataService,
        private taskDocService: TaskDocumentsService,
        private messageService: DboMessageService,
    ) {}

    showModal(attachment: IAttachmentWithMetadata, allAttachments: IAttachmentWithMetadata[], documentIds?: number[]): void {
        this.destroyComponent();
        this.isProcessAttachmentsCall = attachment !== null;
        if (this.isProcessAttachmentsCall) {
            this._componentRef = this.componentFactoryResolver.resolveComponentFactory(ProcessAttachmentAddModalComponent).create(this.injector);
        } else {
            this._componentRef = this.componentFactoryResolver.resolveComponentFactory(InboxAttachmentAddModalComponent).create(this.injector);
            this._componentRef.instance.documentIds = documentIds;
        }

        this._componentRef.instance.entity = attachment;
        this._componentRef.instance.docService = this.documentService;
        this._componentRef.instance.messageService = this.messageService;
        this._componentRef.instance.allAttachments = allAttachments;
        this._componentRef.instance.metaDataService = this.metaDataService;
        if (this.isProcessAttachmentsCall) {
            this._componentRef.instance.modalService = this.modalService;
            this._componentRef.instance.onSave.pipe(take(1)).subscribe(() => this.saved.emit());
        } else {
            this._componentRef.instance.modalService = this.inboxModalService;
        }
        this._componentRef.instance.onCancel.pipe(take(1)).subscribe(() => {
            this.destroyComponent();
        });

        this.appRef.attachView(this._componentRef.hostView);

        let domElem: HTMLElement;
        if (this.isProcessAttachmentsCall) {
            domElem = (this._componentRef.hostView as EmbeddedViewRef<ProcessAttachmentAddModalComponent>).rootNodes[0] as HTMLElement;
        } else {
            domElem = (this._componentRef.hostView as EmbeddedViewRef<InboxAttachmentAddModalComponent>).rootNodes[0] as HTMLElement;
        }
        document.body.appendChild(domElem);
    }

    private destroyComponent(): void {
        if (this._componentRef) {
            this.appRef.detachView(this._componentRef.hostView);
            this._componentRef.destroy();
            this._componentRef = null;
        }
    }
}
