/* eslint-disable @typescript-eslint/unbound-method */
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    IDynamicField,
    IDynamicFieldType,
    InputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IUserSetup } from '../../model/interfaces/custom/IUserSetup';
import { UserService } from '../user.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-user-setup',
    styleUrls: ['./user-setup.component.css'],
    templateUrl: './user-setup.component.html',
})
export class UserSetupComponent implements OnInit {
    form: UntypedFormGroup;
    userNameTextbox: DynamicField;
    firstNameTextbox: DynamicField;
    lastNameTextbox: DynamicField;
    emailTextbox: DynamicField;
    userTypeDropdown: DynamicField;

    constructor(private fb: UntypedFormBuilder, private notificationsService: NotificationsService, private userService: UserService) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            UserSetup: this.fb.group({}),
        });
        this.createUserNameControl();
    }

    createUserNameControl(): void {
        this.firstNameTextbox = new DynamicField({
            formGroup: 'UserSetup',
            label: 'First name',
            name: 'firstName',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { required: true },
            value: '',
        } as IDynamicField);

        this.lastNameTextbox = new DynamicField({
            formGroup: 'UserSetup',
            label: 'Last name',
            name: 'lastName',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { required: true },
            value: '',
        } as IDynamicField);

        this.userTypeDropdown = new DynamicField({
            formGroup: 'UserSetup',
            label: 'User Type',
            name: 'userType',
            options: [
                { Id: 0, Name: 'PMC / Hamilton Lane' },
                { Id: 1, Name: 'Offshore' },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { required: true },
            value: 0,
        } as IDynamicField);

        this.emailTextbox = new DynamicField({
            formGroup: 'UserSetup',
            label: 'Email',
            name: 'email',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            } as IDynamicFieldType),
            validation: [Validators.required, Validators.email],
            validators: { required: true, email: true },
            value: '',
        } as IDynamicField);
    }

    submitForm(): void {
        if (this.form.valid) {
            const formValues = this.form.value.UserSetup;
            const email: string = formValues?.email?.trim();
            const data: IUserSetup = {
                email: email,
                firstName: formValues.firstName.trim(),
                lastName: formValues.lastName.trim(),
                userName: email.split('@')[0],
                userType: formValues.userType,
            };
            this.userService.setupNewUser(data).subscribe(
                (resp) => {
                    this.notificationsService.success('User added to persons table.');
                    this.form.reset();
                },
                (err: HttpErrorResponse) => this.notificationsService.error(err.error as string),
            );
        } else {
            this.notificationsService.error('Please check the user form.');
        }
    }
}
