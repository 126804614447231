import { Component, ViewChild, ElementRef, Input, AfterViewInit, Output, EventEmitter, ChangeDetectorRef, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbDate, NgbDateStruct, NgbDatepicker, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

@Component({
    selector: 'app-date-picker',
    styles: [
        `
            .selected-date {
                color: white;
                background-color: #337ab7;
            }
            .date-picker-day-view {
                text-align: center;
                width: 2rem;
                height: 2rem;
                line-height: 2rem;
                border-radius: 0.25rem;

                &.outside {
                    opacity: 0.5;
                }
            }
            .date-text > span,
            .date-text > input {
                width: 8em;
                height: 1.45em;
            }
            .fa-calendar {
                margin-left: 8px;
            }
            .inside-box-validation {
                position: relative !important;
                top: -35px;
                bottom: -35px;
                margin-bottom: -35px;
                right: 3px;
                float: right;
                font-size: 70%;
            }
            .selected-date-label {
                min-width: 170px;
            }
            .date-picker-styles {
                min-width: 170px;
            }
        `,
    ],
    templateUrl: './date-picker.component.html',
})
export class DatePickerComponent implements AfterViewInit {
    @Input() isAM = true;
    private _value: Date;
    @Input()
    set value(date: Date) {
        this.setValue(date, {
            navigateCalendar: !this.showDatePicker,
            updateEditText: !this.showDatePicker,
        });
    }
    get value(): Date {
        return this._value;
    }
    @Input() label: string;
    @Input() firstDayOfTheWeek: number;
    @Input() minDate: NgbDateStruct = null;
    @Input() maxDate: NgbDateStruct = null;
    @Input() showTimePicker = false;
    @Output() valueChanged = new EventEmitter<Date>();
    @Output() timeChanged = new EventEmitter<Date>();
    @ViewChild('datePicker') datePicker: NgbDatepicker;

    model: NgbDateStruct;
    modelTime: NgbTimeStruct;
    showDatePicker = false;
    editDateText: string;
    selectedDateText: string;
    hasError = false;
    viewInit = false;

    constructor(private cdr: ChangeDetectorRef, private datePipe: DatePipe) {
        this.value = null;
    }

    ngAfterViewInit(): void {
        this.viewInit = true;
        setTimeout(() => {
            this.cdr.detectChanges();
            this.navigateCalendarToValue(this.value);
        });
    }

    toggleDatePicker(): void {
        this.showDatePicker = !this.showDatePicker;
    }

    editDateChanged(value: string): void {
        const parsed = this.parseDateString(value);
        this.setValue(parsed, {
            emitEvent: true,
            navigateCalendar: !!parsed,
        });
    }

    dateSelected(date: NgbDate): void {
        this.showDatePicker = false;
        this.setValue(new Date(date.year, date.month - 1, date.day), {
            emitEvent: true,
            updateEditText: true,
        });
    }

    timeSelected(time: any): void {
        this.modelTime = { hour: time.hour, minute: time.minute, second: time.second };
        const newDate = new Date(this.model.year, this.model.month - 1, this.model.day, this.modelTime.hour, this.modelTime.minute);
        this.setValue(newDate, {
            emitEvent: false,
            emitTimeEvent: true,
            updateEditText: true,
        });
    }

    private setValue(date: Date, options?: ISetValueOptions): void {
        this._value = date;
        this.setDateText(date);
        if (options) {
            if (options.updateEditText) {
                this.editDateText = date ? date.toLocaleDateString() : null;
            }
            if (options.navigateCalendar) {
                this.navigateCalendarToValue(date);
            }
            if (options.emitEvent) {
                this.valueChanged.emit(date);
            }
            if (options.emitTimeEvent) {
                this.timeChanged.emit(date);
            }
        }
    }

    private setDateText(date: Date): void {
        const placeHolder = 'Select a date...';
        if (this.showTimePicker) {
            this.selectedDateText = date && date instanceof Date ? this.datePipe.transform(date, 'M/d/yyyy h:mm') : placeHolder;
        } else {
            this.selectedDateText = date && date instanceof Date ? this.datePipe.transform(date, 'M/d/yyyy') : placeHolder;
        }
    }

    private navigateCalendarToValue(date: Date): void {
        if (this.viewInit) {
            const dateValue = date || new Date();
            if (!this.isAM) {
                dateValue.setHours(23, 59, 59, 0);
            }
            const ngbDate: NgbDate = new NgbDate(dateValue.getFullYear(), dateValue.getMonth() + 1, dateValue.getDate());
            const datepicker: any = this.datePicker;
            this.model = ngbDate;
            (<NgbDatepicker>datepicker).navigateTo({
                day: ngbDate.day,
                month: ngbDate.month,
                year: ngbDate.year,
            });
            let ngbTime: any = { hour: 0, minute: 0, second: 0 };
            if (!this.isAM) {
                ngbTime = { hour: 23, minute: 59, second: 59 };
            }
            this.modelTime = ngbTime;
        }
    }

    private parseDateString(value: string): Date {
        if (value && /\D/.test(value)) {
            const parsed = moment(value);
            if (parsed.isValid()) {
                return new Date(parsed.toISOString());
            }
        }
        return null;
    }
}

interface ISetValueOptions {
    emitEvent?: boolean;
    emitTimeEvent?: boolean;
    navigateCalendar?: boolean;
    updateEditText?: boolean;
}
