<div class="row">
    <div class="col-md-1">
        <h2 mtSetTitle>Library</h2>
    </div>
    <div class="col-md-11">
        <br />
        <b>Select from saved search:</b>
        <div
            class="btn-group btn-group-toggle"
            ngbRadioGroup
            name="radioBasic"
            [(ngModel)]="selectedSearch"
            (ngModelChange)="filterDownTaskBasedOnSelectedSearch()"
        >
            <label
                ngbButtonLabel
                *ngFor="let search of savedSearches"
                class="btn-primary-inverse"
                [style.background-color]="search.Id === 0 ? 'orange' : 'white'"
            >
                <input ngbButton type="radio" [value]="search" />{{ search.Name }}({{ search.newDocs }})
            </label>
        </div>
    </div>
</div>
<br />
<div class="panel panel-default">
    <div class="panel-body">
        <div class="search-bar-container">
            <div class="row">
                <div class="col-md-7">
                    <div class="row">
                        <div class="col-md-2">
                            Master Clients:
                        </div>
                        <div class="col-md-10">
                            <ng-select
                                (change)="onMasterClientChange()"
                                [(ngModel)]="selectedMasterClients"
                                [closeOnSelect]="true"
                                [hideSelected]="true"
                                [items]="(masterClientsSubject$ | async) ? (masterClients$ | async) : masterClients"
                                [multiple]="true"
                                [typeahead]="masterClientsSubject$"
                                [virtualScroll]="false"
                                bindLabel="Name"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-2">
                            Clients:
                        </div>
                        <div class="col-md-10">
                            <ng-select
                                (change)="onClientsModelChange($event)"
                                (open)="clientsOpen()"
                                [(ngModel)]="clientsList"
                                [closeOnSelect]="true"
                                [hideSelected]="false"
                                [items]="(clientSubject$ | async) ? (clients$ | async) : clients"
                                [multiple]="true"
                                [typeahead]="clientSubject$"
                                [virtualScroll]="true"
                                bindLabel="Name"
                            >
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <div class="typeaheadSelected">
                                        <span aria-hidden="true" class="ng-value-icon left" (click)="clear(item)">×</span>
                                        <span class="ng-value-label" title="{{ item.Name }}">{{ item.Name }}</span>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span class="ng-option-label typeaheadOption" title="{{ item.Name }}">{{ item.Name }}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <br />
                    <div class="row" [class.hidden]="includeTaxDocs">
                        <div class="col-md-2">
                            Funds:
                        </div>
                        <div class="col-md-10">
                            <ng-select
                                (change)="onFundsModelChange($event)"
                                (open)="fundsOpen()"
                                [(ngModel)]="fundsList"
                                [closeOnSelect]="true"
                                [hideSelected]="false"
                                [items]="(fundSubject$ | async) ? (funds$ | async) : funds"
                                [multiple]="true"
                                [typeahead]="fundSubject$"
                                [virtualScroll]="true"
                                bindLabel="Name"
                            >
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <div class="typeaheadSelected">
                                        <span aria-hidden="true" class="ng-value-icon left" (click)="clear(item)">×</span>
                                        <span class="ng-value-label" title="{{ item.Name }}">{{ item.Name }}</span>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span class="ng-option-label typeaheadOption" title="{{ item.Name }}">{{ item.Name }}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <br />
                    <div class="row" [class.hidden]="!includeTaxDocs">
                        <div class="col-md-2">
                            Tax Positions:
                        </div>
                        <div class="col-md-10">
                            <ng-select
                                [items]="investment$ | async"
                                [disabled]="!includeTaxDocs"
                                bindLabel="Name"
                                [closeOnSelect]="true"
                                [multiple]="true"
                                [hideSelected]="true"
                                [typeahead]="investmentSubject$"
                                [(ngModel)]="investmentList"
                                (open)="investmentOpen()"
                            >
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <div class="typeaheadSelected">
                                        <span aria-hidden="true" class="ng-value-icon left" (click)="clear(item)">×</span>
                                        <span class="ng-value-label" title="{{ item.Name }}">{{ item.Name }}</span>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span class="ng-option-label typeaheadOption" title="{{ item.Name }}">{{ item.Name }}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="row docTypes">
                        <div class="col-md-4">
                            Document Types:
                        </div>
                        <div class="col-md-8">
                            <ng-select
                                [(ngModel)]="selectedDocumentTypes"
                                [hideSelected]="true"
                                [items]="(documentTypesSubject$ | async) ? (documentTypes$ | async) : documentTypes"
                                [multiple]="true"
                                [typeahead]="documentTypesSubject$"
                                bindLabel="Name"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-4">
                            Task IDs:
                        </div>
                        <div class="col-md-8">
                            <textarea class="task-list-filter" rows="1" style="resize: none;" [(ngModel)]="searchByTaskIDs"></textarea>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-4">
                            Email IDs:
                        </div>
                        <div class="col-md-8">
                            <textarea class="task-list-filter" rows="1" style="resize: none;" [(ngModel)]="searchByEmailIDs"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-md-12">
                    <div class="pull-right">
                        <app-search-filter-daterange-custom
                            entity="Due Date"
                            [startDate]="startDueDate"
                            [endDate]="endDueDate"
                            [contextualDateType]="dueDateContextualDateId"
                            (contextualDateTypeChanged)="setDueDateContextualDateType($event)"
                            (onSelectionChanged)="setDueDates($event)"
                            [enabled]="!includeTaxDocs"
                            [showTimepicker]="false"
                        ></app-search-filter-daterange-custom>
                        <app-search-filter-daterange-custom
                            entity="Statement Date"
                            [startDate]="startStatementDate"
                            [endDate]="endStatementDate"
                            [contextualDateType]="statementDateContextualDateId"
                            (contextualDateTypeChanged)="setStatementDateContextualDateType($event)"
                            (onSelectionChanged)="setStatementDates($event)"
                            [enabled]="!includeTaxDocs"
                            [showTimepicker]="false"
                        ></app-search-filter-daterange-custom>
                        <app-search-filter-daterange-custom
                            entity="Received Date"
                            [startDate]="startReceivedDate"
                            [endDate]="endReceivedDate"
                            [contextualDateType]="receivedDateContextualDateId"
                            (contextualDateTypeChanged)="setReceivedDateContextualDateType($event)"
                            (onSelectionChanged)="setReceivedDates($event)"
                        ></app-search-filter-daterange-custom>
                        <mt-search-filter-select [items]="taskStatuses" entity="Status" [hidden]="!canViewAllTasksRegardlessOfStatus"> </mt-search-filter-select>
                        <mt-search-filter-select [items]="tenants" entity="Tenants" [hidden]="!hasFullAccess"> </mt-search-filter-select>
                        <mt-search-filter-select [items]="usersProcessedBy" [entity]="'Processed By'"></mt-search-filter-select>
                        <button class="btn btn-transparent" type="button">
                            <input type="checkbox" [(ngModel)]="isHighPriority" (ngModelChange)="isHighPriorityChanged($event)" />
                            <span>High Priority</span>
                        </button>
                        <button class="btn btn-transparent" type="button">
                            <input type="checkbox" [(ngModel)]="includeArchive" (ngModelChange)="includeArchivedChanged($event)" />
                            <span>Include Archived</span>
                        </button>
                        <button class="btn btn-transparent" type="button">
                            <input type="checkbox" [(ngModel)]="includeTaxDocs" (ngModelChange)="includeTaxDoc($event)" />
                            <span>Only Tax Docs</span>
                        </button>
                        <input type="number" placeholder="Tax Form Year" [(ngModel)]="taxFormYear" *ngIf="showTaxFilters" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-8">
        <button type="button" class="btn btn-primary" (click)="configureColumns()">
            Configure Columns
        </button>
        <button type="button" class="btn btn-primary" (click)="downloadDocuments()">
            Download Docs
        </button>
        <button type="button" class="btn btn-primary" (click)="downloadAllDocuments()">
            Download All Docs
        </button>
        <button type="button" class="btn btn-primary" (click)="showEmailDialog()">
            Send Email
        </button>
        <button type="button" class="btn btn-primary" (click)="export()">
            Export
        </button>
        <button type="button" class="btn btn-primary" (click)="cloneSelectedTasks()">
            PIF Update
        </button>
        <button *ngIf="selectedTasks.length > 0" type="button" class="btn btn-primary" (click)="markSelectedTasksAsHighPriority()">
            Mark tasks as High Priority
        </button>
        <button *ngIf="selectedTasks.length > 0" type="button" class="btn btn-primary" (click)="showReplaceTaskClientsModal()">
            Replace tasks clients
        </button>
    </div>
    <div class="col-md-4">
        <button type="button" class="btn btn-primary" (click)="searchTasks()">
            Search
        </button>
        <button type="button" class="btn btn-primary" (click)="saveSearch()">
            Save Search
        </button>
        <button type="button" class="btn btn-primary" (click)="deleteSearch()">
            Delete Search
        </button>
        <button type="button" class="btn btn-primary" (click)="clearSearch()">
            Clear Search
        </button>
    </div>
</div>
<br/>
<div class="row">
    <div class="col-md-8">
        <button *ngIf="selectedTasks.length > 0" type="button" class="btn btn-primary" (click)="showReplaceFundClient()">
            Replace tasks fund
        </button>
    </div>
</div>


<br />
<div *ngIf="tasks && entityListConfig">
    <mt-entity-list
        [entityListConfig]="entityListConfig"
        [entities]="tasks"
        [selectedEntities]="selectedTasks"
        [total]="total"
        [(currentPage)]="currentPage"
        [(itemsPerPage)]="selectedElementPerPage"
        (pageChanged)="getTasks()"
        (columnSortedEvent)="columnSorted($event)"
        (itemDeletedEvent)="toggleArchive($event)"
        (selectionChangedEvent)="onSelectionChanged($event)"
    >
    </mt-entity-list>

    # Records/Page
    <select [(ngModel)]="selectedElementPerPage" (ngModelChange)="selectedRecordsPerPage()">
        <option *ngFor="let elem of elementPerPage" [value]="elem">{{ elem }}</option>
    </select>
    (Total Results: {{ total }})
</div>

<mt-modal-wrapper
    [autoShow]="false"
    [options]="{ showCancelButton: true, showConfirmButton: true, confirmButtonText: 'Save', width: 500 }"
    (ready)="configureColumnsModalApi = $event"
    (okClick)="saveUserColumns()"
>
    <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="columnList" class="example-list" (cdkDropListDropped)="drop($event)">
        <div class="example-box" *ngFor="let item of columnList; let i = index" [cdkDragDisabled]="item.disabled" cdkDrag>
            <label><input [(ngModel)]="item.checked" type="checkbox" />&#10240;{{ item.name }}</label>
        </div>
    </div>
</mt-modal-wrapper>

<mt-modal-wrapper
    [autoShow]="false"
    [options]="{ showCancelButton: true, showConfirmButton: true, confirmButtonText: 'Send', width: 700 }"
    (ready)="emailModalApi = $event"
    (okClick)="sendEmail()"
>
    <div class="row">
        <div class="col-md-12">
            <b>To:</b>
            <input type="text" [(ngModel)]="to" class="form-control" />
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-12">
            <b>Subject:</b>
            <input type="text" [(ngModel)]="subject" class="form-control" />
        </div>
    </div>
    <br />
    <div class="row">
        <b>Email Body:</b>
        <div class="col-md-12">
            <textarea cols="5" rows="10" [(ngModel)]="emailBody" class="form-control"></textarea>
        </div>
    </div>
</mt-modal-wrapper>

<mt-modal-wrapper
    [autoShow]="false"
    title="Save Search"
    [options]="{ showCancelButton: true, showConfirmButton: true, confirmButtonText: 'Save', width: 500 }"
    (ready)="saveTaskNameModalApi = $event"
    (okClick)="confirmSaveSearch()"
>
    <div class="row">
        <div class="col-md-12 form-group">
            <div style="text-align: left;" class="col-md-3">
                Name:
            </div>
            <div class="col-md-9">
                <input type="text" [(ngModel)]="searchName" class="form-control" maxlength="50" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 form-group">
            <div style="text-align: left;" class="col-md-3">
                Email:
            </div>
            <div class="col-md-9">
                <input type="text" [(ngModel)]="searchEmail" class="form-control" maxlength="50" />
            </div>
        </div>
    </div>
</mt-modal-wrapper>

<mt-modal-wrapper
    [autoShow]="false"
    title="Replace Clients"
    [options]="{ showCancelButton: true, showConfirmButton: true, confirmButtonText: 'Replace', width: 800 }"
    (ready)="replaceClientsModalApi = $event"
    mtDisableClickDuringHttpCalls
    (okClick)="onReplace()"
>
    <div>
        <div class="row" style="height: 300px;">
            <div class="col-md-6">
                <label>Task Common Clients</label>
                <ng-select
                    [items]="selectedTasksClients"
                    [multiple]="false"
                    [hideSelected]="true"
                    [(ngModel)]="selectedOriginalClients"
                    style="font-size: 16px;"
                    bindLabel="Name"
                ></ng-select>
            </div>
            <div class="col-md-6">
                <label>Replacement Clients</label>
                <ng-select
                    [items]="selectedTasksFundClients$ | async"
                    [multiple]="false"
                    [hideSelected]="true"
                    [(ngModel)]="selectedReplacementClients"
                    style="font-size: 16px;"
                    bindLabel="Name"
                ></ng-select>
            </div>
        </div>
    </div>
</mt-modal-wrapper>

<mt-modal-wrapper
    [autoShow]="false"
    title="Replace Fund and Clients"
    [options]="{ showCancelButton: true, showConfirmButton: true, confirmButtonText: 'Replace', width: 700 }"
    (ready)="replaceFundClientsModalApi = $event"
    mtDisableClickDuringHttpCalls
    (okClick)="onReplaceTaskFundClient()"
>
<div style="height: 375px;">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-2">
                Clients:
            </div>
            <div class="col-md-10">
                <ng-select
                    (change)="onClientChange($event)"
                    (open)="clientsOpen()"
                    [(ngModel)]="taskReplacementClient"
                    [closeOnSelect]="true"
                    [hideSelected]="false"
                    [items]="(clientSubject$ | async) ? (clients$ | async) : clients"
                    [multiple]="false"
                    [typeahead]="clientSubject$"
                    [virtualScroll]="true"
                    bindLabel="Name"
                >
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <div class="typeaheadSelected">
                            <span aria-hidden="true" class="ng-value-icon left" (click)="clear(item)">×</span>
                            <span class="ng-value-label" title="{{ item.Name }}">{{ item.Name }}</span>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span class="ng-option-label typeaheadOption" title="{{ item.Name }}">{{ item.Name }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-md-2">
                Funds:
            </div>
            <div class="col-md-10">
                <ng-select
                    (change)="onFundChange($event)"
                    (open)="fundsOpen()"
                    [(ngModel)]="taskReplacementFund"
                    [closeOnSelect]="true"
                    [hideSelected]="false"
                    [items]="(fundSubject$ | async) ? (funds$ | async) : funds"
                    [multiple]="false"
                    [typeahead]="fundSubject$"
                    [virtualScroll]="true"
                    bindLabel="Name"
                >
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <div class="typeaheadSelected">
                            <span aria-hidden="true" class="ng-value-icon left" (click)="clear(item)">×</span>
                            <span class="ng-value-label" title="{{ item.Name }}">{{ item.Name }}</span>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span class="ng-option-label typeaheadOption" title="{{ item.Name }}">{{ item.Name }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </div>
</div>
</mt-modal-wrapper>
