import { IDboSupportingDocument } from '../../model/interfaces/dbo-supporting-document';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IHasDocuments } from '@mt-ng2/entity-components-documents';
import { ClaimTypes } from '../../model/ClaimTypes';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { IBlobDownload } from '../../model/interfaces/custom/blob-download';
import { CommonService } from '../../common/services/common.service';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { IDboAttachment } from '../../model/interfaces/dbo-attachment';
import { IDboVwMessageWithAttachment } from '../../model/interfaces/dbo-vw-message-with-attachment';
import { IEmailDTO } from '../../model/interfaces/custom/IEmailDTO';
import { IDboReplyMessageAttachment } from '../../model/interfaces/dbo-reply-message-attachment';

@Injectable({
    providedIn: 'root',
})
export class DboMessageDocumentService extends BaseService<IDboAttachment> {
    constructor(
        public http: HttpClient,
        public claimsService: ClaimsService,
        private commonService: CommonService,
        private envSvc: EnvironmentService,
    ) {
        super('/inbox', http);
    }

    getDocuments(messageId: number): Observable<HttpResponse<IDboAttachment[]>> {
        return this.http
            .get<IDboAttachment[]>(`/inbox/${messageId}/documents/getMessageDocuments`, {
                headers: { ignoreProgressBar: '' },
                observe: 'response',
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    getReplyDocuments(messageId: number, replyMessageId: number): Observable<HttpResponse<IDboReplyMessageAttachment[]>> {
        return this.http
            .get<IDboReplyMessageAttachment[]>(`/inbox/${messageId}/documents/getReplyMessageDocuments/${replyMessageId}`, {
                headers: { ignoreProgressBar: '' },
                observe: 'response',
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    getImagePath(messageId: number, cid: string, fullPath = true): string {
        const basePath = fullPath ? `${this.envSvc.config.baseApiUrl}/api/v1` : '';
        return `${basePath}/inbox/${messageId}/documents/getInlineImagePath/${cid}`;
    }

    getDocumentPath(messageId: number, attachmentId: number, fullPath = false, isReplyAttachment = false): string {
        const basePath = fullPath ? `${this.envSvc.config.baseApiUrl}/api/v1` : '';
        if (!isReplyAttachment) {
            return `${basePath}/inbox/${messageId}/documents/getAttachmentPath/${attachmentId}`;
        } else {
            return `${basePath}/inbox/${messageId}/documents/getReplyAttachmentPath/${attachmentId}`;
        }
    }

    getDocument(messageId: number, attachmentId: number): Observable<IBlobDownload> {
        return this.http
            .get(this.getDocumentPath(messageId, attachmentId), {
                observe: 'response',
                responseType: 'blob',
            })
            .pipe(map(this.commonService.mapBlobDownload));
    }

    saveDocument(messageId: number, file: File, dateModified: Date): Observable<IDboAttachment> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        let dateFormattedAsString: string = null;
        if (dateModified) {
            dateFormattedAsString = dateModified.mtDate.format('DD-MM-yyyy HH:mm:ss');
        }
        return this.http.post<IDboAttachment>(`/inbox/${messageId}/documents/${dateFormattedAsString}`, formData);
    }

    saveDocumentMultiple(messageIds: number[], file: File, dateModified: Date): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        let dateFormattedAsString: string = null;
        if (dateModified) {
            dateFormattedAsString = dateModified.mtDate.format('DD-MM-yyyy HH:mm:ss');
        }
        return this.http.post<any>(`/inbox/0/documents/multiple/${messageIds.join(',')}/${dateFormattedAsString}`, formData);
    }

    duplicateDocument(messageId: number, attachmentId: number): Observable<IDboAttachment> {
        return this.http.post<IDboAttachment>(`/inbox/${messageId}/documents/duplicateAttachment`, attachmentId);
    }

    saveUnassociatedDocument(messageId: number, file: File): Observable<IDboAttachment> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post<IDboAttachment>(`/inbox/${messageId}/documents/uploadUnassociatedDoc`, formData);
    }

    setDocumentState(messageId: number, attachmentId: number, attachmentStateId: number, authUserId: number): Observable<void> {
        return this.http.post<void>(`/inbox/${messageId}/documents/setAttachmentState/${attachmentId}/${attachmentStateId}`, authUserId);
    }

    sendEmail(emailDTO: IEmailDTO): Observable<number> {
        const formData: FormData = new FormData();
        formData.append('Bcc', emailDTO.Bcc);
        formData.append('Body', emailDTO.Body);
        formData.append('Cc', emailDTO.Cc);
        formData.append('IsForwards', String(emailDTO.IsForwards));
        formData.append('MessageId', String(emailDTO.MessageId));
        formData.append('RemovedAttachmentIds', emailDTO.RemovedAttachmentIds.join(','));
        emailDTO.ReplyAttachments.forEach((attachment) => formData.append('ReplyAttachments', attachment, attachment.name));
        formData.append('Subject', emailDTO.Subject);
        formData.append('TaskIds', emailDTO.TaskIds.join(','));
        formData.append('To', emailDTO.To);
        return this.http.post<number>(`/inbox/${emailDTO.MessageId}/documents/sendEmail`, formData);
    }

    checkForDuplicates(messageIds: number[], file: File): Observable<number> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<number>(`/inbox/0/documents/checkForDuplicates/${messageIds.join(',')}`, formData);
    }
}
