import { Component, Input, OnInit } from '@angular/core';
import { ITaskChangesDTO } from '../../../../model/interfaces/custom/ITaskChangesDTO';

@Component({
    selector: 'app-audit-task-changes',
    templateUrl: './audit-task-changes-component.html',
})
export class AuditTaskChangesComponent implements OnInit {
    @Input() logTaskChangesChanges: ITaskChangesDTO[];
    @Input() taskId;
    @Input() toggleTaskIdTable;

    ngOnInit(): void {
        for (const lg of this.logTaskChangesChanges) {
            if (lg.Field === 'Fund' || lg.Field === 'DocumentType') {
                lg.OldValue = lg.OldValue.Name;
                lg.NewValue = lg.NewValue.Name;
            }
        }
    }

    checkIfObject(lg: ITaskChangesDTO): boolean {
        return typeof lg.NewValue === 'object';
    }
    checkIfDateType(lg: ITaskChangesDTO): boolean {
        return typeof lg.NewValue === 'number';
    }
    checkIfStringType(lg: ITaskChangesDTO): boolean {
        return typeof lg.NewValue === 'string';
    }
}
