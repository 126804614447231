import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { AttachmentTagBasicInfoComponent } from './attachment-tag-basic-info/attachment-tag-basic-info.component';
import { AttachmentTagListComponent } from './attachment-tag-list/attachment-tag-list.component';
import { ClaimTypes } from '../model/ClaimTypes';
import { AttachmentTagsService } from './attachment-tags.service';

const attachmentTagEntityConfig = {
  claimType: ClaimTypes.Tags,
  claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
  entityIdParam: 'attachmentTagId',
  service: AttachmentTagsService,
  title: 'Attachment Tag Detail',
};

const attachmentTagListRoleGuard: any = {
  claimType: ClaimTypes.Tags,
  claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
  title: 'Attachment Tags',
};

export const attachmentTagPaths = {
  attachmentTags: 'attachment-tag',
  attachmentTagsAdd: 'attachment-tag/add',
};

const routes: Routes = [
  {
    canActivate: [AuthGuard],
    component: AttachmentTagListComponent,
    data: attachmentTagListRoleGuard,
    path: attachmentTagPaths.attachmentTags,
  },
  {
    canActivate: [AuthGuard],
    children: [{ component: AttachmentTagBasicInfoComponent, path: '', pathMatch: 'full' }],
    component: AttachmentTagBasicInfoComponent,
    data: attachmentTagEntityConfig,
    path: `attachment-tag/:${attachmentTagEntityConfig.entityIdParam}`,
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)]
})
export class AttachmentTagsRoutingModule { }
