import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { IEntitySearchParams } from '@mt-ng2/common-classes';

import { ICustomer } from '../../model/interfaces/customer';
import { ClaimTypes } from '../../model/ClaimTypes';
import { CustomersEntityListConfig } from './customers.entity-list-config';
import { entityListModuleConfig } from '../../common/shared.module';
import { CustomerService } from '../services/customer.service';
import { CustomerStatusService } from '../services/customerstatus.service';
import { CustomerSourceService } from '../services/customersource.service';

@Component({
    selector: 'app-customers',
    templateUrl: './customers.component.html',
})
export class CustomersComponent implements OnInit {
    query = '';
    sources: MtSearchFilterItem[] = [];
    statuses: MtSearchFilterItem[] = [];

    customers: ICustomer[];
    total: number;
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    entityListConfig = new CustomersEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddCustomer = false;

    constructor(
        private customerService: CustomerService,
        private claimsService: ClaimsService,
        private customerStatusService: CustomerStatusService,
        private customerSourcesService: CustomerSourceService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.canAddCustomer = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
        this.customerSourcesService.getSearchFilterItems().subscribe((answer) => (this.sources = answer));
        this.customerStatusService.getSearchFilterItems().subscribe((answer) => (this.statuses = answer));
        this.getCustomers();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedSourceIds: number[] = this.getSelectedFilters(this.sources);
        const selectedStatusIds: number[] = this.getSelectedFilters(this.statuses);
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'SourceIds',
                valueArray: selectedSourceIds,
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'StatusIds',
                valueArray: selectedStatusIds,
            }),
        );

        return _extraSearchParams;
    }

    getCustomers(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.customerService.get(searchparams).subscribe((answer) => {
            this.customers = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getCustomers();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getCustomers();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getCustomers();
    }

    customerSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/customers', event.entity.Id]);
    }
}
