import { IEntityListDynamicCellComponent, IEntityListComponentMembers, IEntity } from '@mt-ng2/entity-list-module';
import { OnInit, OnDestroy, Component } from '@angular/core';
import { IMessageTag } from '../../model/interfaces/message-tag';
import { MessageTagService } from '../services/message-tag.service';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-tag-color-cell',
    styles: [
        `
            .container {
                width: 100%;
                z-index: 999;
                padding: 0;
            }
            input {
                opacity: 0;
            }
        `,
    ],
    template: `
        <div class="container" (click)="$event.stopPropagation()">
            <custom-color-picker-control
                id="colorPicker"
                *ngIf="item && item.Color"
                [(colorPicker)]="item.Color"
                cpPosition="left"
                (click)="$event.stopPropagation()"
                [cpCmykEnabled]="false"
                [cpFallbackColor]="item.Color"
                (colorPickerSelect)="changeColor($event)"
                [cpOKButton]="true"
            ></custom-color-picker-control>
            <!-- <mt-color-picker-control id="colorPicker" *ngIf="item && item.Color" [(colorPicker)]=item.Color cpPosition="left" (click)="$event.stopPropagation()" (colorPickerSelect)="changeColor($event)" [cpOKButton]="true"></mt-color-picker-control> -->
        </div>
    `,
})
export class TagColorComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;

    set entity(value: IEntity) {
        this.item = value as IMessageTag;
    }
    item: IMessageTag;
    constructor(private messageTagService: MessageTagService) {}

    changeColor(event: any): void {
        this.item.Color = event;
        this.messageTagService.update(this.item).subscribe(() => {});
    }
}
