import { Component, EventEmitter, Output, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IEntityListConfig, IEntity } from '@mt-ng2/entity-list-module';

import { AuthService } from '@mt-ng2/auth-module';
import { ClientService } from '../client.service';
import { IDboVCommitment } from '../../model/interfaces/dbo-v-commitment';
import { IDboVClientAltName } from '../../model/interfaces/dbo-v-client-alt-name';

@Component({
    selector: 'app-client-altName-modal',
    styles: [
        `
            .fullModal {
                overflow-y: initial !important;
            }

            .scrollModal {
                height: 30vh;
                overflow-y: auto;
                overflow-x: hidden;
                width: 100%;
            }
        `,
    ],
    templateUrl: './client-alt-names-modal.html',
})
export class ClientAltNameModalComponent implements OnInit {
    modalOptions: IModalOptions = {
        showConfirmButton: false,
        width: '50%',
    };

    entity: IEntity;
    entityListConfig: IEntityListConfig;

    doubleClickIsDisabled = false;
    isEditing = false;

    altNames: IDboVClientAltName[] = [];

    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSave: EventEmitter<void> = new EventEmitter<void>();

    constructor(private clientService: ClientService) {}
    ngOnInit(): void {
        this.clientService.getClientAltNames(this.entity.Id).subscribe((data) => {
            this.altNames = data;
        });
    }

    cancel(): void {
        this.onCancel.emit();
    }

    save(): void {}
}
