import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntity, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { Subscription } from 'rxjs';
import { ContrastColorText } from '../../common/util';
import { MessageTagService } from '../../message-tags/services/message-tag.service';
import { IMessageTag } from '../../model/interfaces/message-tag';
import { AttachmentTagsService } from '../../attachment-tags/attachment-tags.service';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { IDboTaskDTO } from '../../model/interfaces/custom/IDboTaskDTO';
import { IDboAttachmentTag } from '../../model/interfaces/dbo-attachment-tag';
@Component({
    styles: [
        `
            .tagList {
                display: flex;
                overflow-y: hidden;
                max-width: 120px;
            }
            .tagItem {
                text-overflow: ellipsis;
                padding: 2px;
                border-radius: 5px;
                margin-right: 2px;
                white-space: nowrap;
                overflow: hidden;
                text-align: center;
                align-items: center;
                min-width: 20px;
            }
            .delete {
                padding-right: 4px;
                padding-left: 4px;
                margin-left: -4px;
                border-radius: 2px;
                width: 20px;
                display: inline;
                float: left;
                margin-top: auto;
                margin-bottom: auto;
            }

            .tagName {
                display: inline;
                text-align: center;
                overflow: hidden;
                margin-top: auto;
                margin-bottom: auto;
            }
        `,
    ],
    templateUrl: './tag-on-task-list.component.html',
})
export class TagOnTaskListComponent extends ContrastColorText implements IEntityListDynamicCellComponent, OnInit, OnDestroy {
    @Input() entity: IEntity;
    get task(): IDboTaskDTO {
        return this.entity as IDboTaskDTO;
    }
    set task(task: IDboTaskDTO) {
        this.task = task;
    }
    tags: IDboAttachmentTag[] = [];
    currUser: ILoggedIn;

    entityListComponentMembers: IEntityListComponentMembers;
    subscription: Subscription = new Subscription();
    constructor(private tagService: MessageTagService, private attachmentTagService: AttachmentTagsService, private authService: AuthService) {
        super();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.tags = this.task.Tags;
        this.authService.currentUser.subscribe((data) => {
            this.currUser = data;
        });
    }

    deleteTag(tag: IDboAttachmentTag, index: number): void {
        this.tags.splice(index, 1);
        this.attachmentTagService.deleteTagFromTask(this.task.Id, tag.Id).subscribe(() => {
        });
    }
}
