import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Directive({ selector: '[copy-clipboard]' })
export class CopyClipboardDirective {
    @Input('copy-to')
    public payload: string;

    @Output('copied')
    public copied: EventEmitter<string> = new EventEmitter<string>();
    constructor(private notificationService: NotificationsService) {}
    @HostListener('click', ['$event'])
    public onClick(event: MouseEvent): void {
        event.preventDefault();
        if (!this.payload) {
            return;
        }

        const listener = (e: ClipboardEvent) => {
            // tslint:disable-next-line: no-string-literal
            const clipboard = e.clipboardData || window['clipboardData'];
            clipboard.setData('text', this.payload.toString());
            e.preventDefault();

            this.copied.emit(this.payload);
            this.notificationService.success(`${this.payload} copied to clipboard`);
        };

        document.addEventListener('copy', listener, false);
        document.execCommand('copy');
        document.removeEventListener('copy', listener, false);
    }
}
