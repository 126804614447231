import { Component, OnInit } from '@angular/core';

import { ICustomer } from '../../model/partials/customer';
import { CustomerService } from '../services/customer.service';

@Component({
    templateUrl: './customer-add.component.html',
})
export class CustomerAddComponent implements OnInit {
    customer: ICustomer;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private customerService: CustomerService) {}

    ngOnInit(): void {
        this.customer = this.customerService.getEmptyCustomer();
    }
}
