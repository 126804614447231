<mt-modal-wrapper [options]="modalOptions" (cancelClick)="cancel()">
    <div class="miles-form padded" style="font-size: 14px; height: 40vh;">
        <h3>Assign Tag</h3>
        <div class="row">
            <mt-tag-search-filter-select
                #tagInput
                *ngIf="selectedMessages && selectedMessages.length"
                autoclose="false"
                (onSelectionChanged)="selectTags($event)"
                [items]="tags"
                entity="Select Tags"
            >
            </mt-tag-search-filter-select>
        </div>

        <div class="row">
            <button type="button" class="swal2-confirm swal2-styled" (click)="assignTags()">Assign</button>
            <button type="button" class="swal2-cancel swal2-styled" (click)="cancel()">
                Cancel
            </button>
        </div>
    </div>
</mt-modal-wrapper>
