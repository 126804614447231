import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IDboAttachmentTag } from '../model/interfaces/dbo-attachment-tag';
import { Observable } from 'rxjs';

export const emptyAttachmentTag: IDboAttachmentTag = {
  Archived: false,
  Color: '',
  Name: '',
  Id: 0
}

@Injectable({
  providedIn: 'root'
})
export class AttachmentTagsService extends BaseService<IDboAttachmentTag>{
  constructor(public http: HttpClient) {
    super('/attachment-tags', http, null, {entityName: 'Attachment Tag'});
  }

  getEmptyAttachmentTag(): IDboAttachmentTag {
    return { ...emptyAttachmentTag };
  }

  toggleArchive(tagId: number, archive: boolean): Observable<object> {
    return this.http.put(`/attachment-tags/archive/${tagId}`, archive, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }

  deleteTagFromTask(taskId: number, tagId: number): Observable<object>{
    return this.http.delete(`/attachment-tags/task/${taskId}/tag/${tagId}`);
  }

}
