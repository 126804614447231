import { Component, EventEmitter, Output, OnInit, Input, Injector } from '@angular/core';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IEntityListConfig, IEntity } from '@mt-ng2/entity-list-module';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDocumentsComponent, IDocument } from '@mt-ng2/entity-components-documents';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { DboMessageDocumentService } from '../services/dbo-message-document-service';
import { FileItem } from 'ng2-file-upload';
import { IDboAttachment } from '../../model/interfaces/dbo-attachment';
import { IDboVwMessageWithAttachment } from '../../model/interfaces/dbo-vw-message-with-attachment';
import { SaveSearchModalService } from './saveSearchModal.service';
import { SaveSearchUpdateService } from './saveSearchUpdateService';
import { IMessageSearchDTO } from '../../model/interfaces/custom/IMessageSearchDTO';
import { MessageSearchObject } from '../../model/partials/message.search';
import { MessageSearchService } from '../services/message-search.service';

@Component({
    selector: 'app-saveSearch-modal',
    styles: [
        `
            .modalField {
                width: 40%;
                height: auto;
                display: flex;
                flex-wrap: nowrap;
                margin: auto;
                margin-bottom: 10px;
            }

            .modalField {
                text-align: left;
            }

            .modalInput {
                width: 40%;
            }
        `,
    ],
    templateUrl: './saveSearchModal.html',
})
export class SaveSearchModalComponent implements OnInit {
    modalOptions: IModalOptions = {
        showConfirmButton: false,
        width: '50%',
    };

    entity: IMessageSearchDTO;
    entityListConfig: IEntityListConfig;
    doubleClickIsDisabled = false;
    isEditing = false;
    modalService: SaveSearchUpdateService;
    allMessageSearches: IMessageSearchDTO[];
    messageSearchService: MessageSearchService;
    notificationService: NotificationsService;

    newName = '';
    isOrgSearch = false;
    orgFilterAccess = false;

    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSave: EventEmitter<void> = new EventEmitter<void>();

    //     constructor(  myroute: ActivatedRoute,  myrouter: Router,  injector: Injector,  notificationsService: NotificationsService, claimsService: ClaimsService, config: IEntityComponentsDocumentsModuleConfig) {
    //       super(myroute, myrouter, injector, notificationsService, claimsService, config);
    //    }

    ngOnInit(): void {
        this.newName = this.entity.Name;
        this.isOrgSearch = this.entity.IsOrgSearch ? true : false;
        if (!this.orgFilterAccess) {
            this.allMessageSearches = this.allMessageSearches.filter((e) => !e.IsOrgSearch);
        }
    }

    cancel(): void {
        this.onCancel.emit();
    }

    toggleEdit(): void {
        this.isEditing = !this.isEditing;
    }

    canSaveSearch(): boolean {
        return this.newName.length ? true : false;
    }

    saveSearch(): void {
        if (this.canSaveSearch()) {
            const newSearch = {
                CreatedDate: null,
                Id: 0,
                IsOrgSearch: this.isOrgSearch,
                LastAccessedDate: null,
                Name: this.newName,
                newMessages: 0,
                SearchModel: null,
                searchObject: null,
                UserId: 0,
                VersionId: 1,
            };
            this.modalService.announceSavedSearch(newSearch);
            this.onCancel.emit();
        } else {
            this.notificationService.error('Search Name is required');
        }
    }

    selectedSearchChanged(event: any): void {
        this.newName = event.Name;
        this.isOrgSearch = event.IsOrgSearch;
    }
}
