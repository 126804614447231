import { FundsComponent } from './fund-list/funds.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../model/ClaimTypes';
import { AlternativeFundNameDetailComponent } from './funds-detail/fund-detail.component';

const fundListRoleGuard: any = {
    claimType: ClaimTypes.Funds,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'Id',
    title: 'Funds',
};
const fundRoutes: Routes = [{ path: 'funds', component: FundsComponent, canActivate: [AuthGuard], data: fundListRoleGuard }];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(fundRoutes)],
})
export class FundRoutingModule {}
