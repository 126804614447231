<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>Email Contacts</h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <br />
</div>
<div>
    <div class="pull-right">
        <button class="btn btn-transparent" type="button">
            <input type="checkbox" [(ngModel)]="includeArchived" (ngModelChange)="includeArchivedChanged($event)" />
            <span>Include Archived</span>
        </button>
    </div>
</div>

<mt-entity-list
    [entities]="emailContacts"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getEmailContacts()"
    (itemSelectedEvent)="emailContactSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    (itemDeletedEvent)="toggleArchive($event)"
    [entityListConfig]="entityListConfig"
>
</mt-entity-list>

<div *ngIf="canAddEmailContact" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/emailContacts', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
