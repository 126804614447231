import { MessageTagDynamicControls, IMessageTagDynamicControlsParameters } from '../form-controls/message-tag.form-controls';
import { IMessageTag } from '../interfaces/message-tag';
import { DynamicLabel, DynamicFieldType, DynamicFieldTypes, InputTypes } from '@mt-ng2/dynamic-form';

export class MessageTagDynamicControlsPartial extends MessageTagDynamicControls {
    constructor(messagetagPartial?: IMessageTag, additionalParameters?: IMessageTagDynamicControlsParameters) {
        super(messagetagPartial, additionalParameters);

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        // (<DynamicField>this.Form.AutoApply).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.Color).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DeletedById).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DeletedOn).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.IgnoreEmail).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.IsDeleted).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.MarkAsTax).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.MessageManagedNoteId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.MessageTagContainsId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.Name).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.RequireAllCriteriaGroups).type.fieldType = DynamicFieldTypes.Input;

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        // (<DynamicLabel>this.View.AutoApply).label = 'Some other Label';
        (<DynamicLabel>this.View.AutoApplyOnShoreGroupPattern).label = 'Auto Apply On ShoreGroup Pattern';
        (<DynamicLabel>this.View.AutoApplyOnShoreGroupPattern).value =
            messagetagPartial.AutoApplyOnShoreGroupPattern === null ||
                messagetagPartial.AutoApplyOnShoreGroupPattern === undefined ||
                messagetagPartial.AutoApplyOnShoreGroupPattern === false
                ? false
                : true;

        (<DynamicLabel>this.View.AutoApplyOnMessageHaveThreadId).label = 'Auto Apply If Message contains Thread Id';
        (<DynamicLabel>this.View.AutoApplyOnMessageHaveThreadId).value =
            messagetagPartial.AutoApplyOnMessageHaveThreadId === null ||
                messagetagPartial.AutoApplyOnMessageHaveThreadId === undefined ||
                messagetagPartial.AutoApplyOnMessageHaveThreadId === false
                ? false
                : true;


        (<DynamicLabel>(
            this.View.Color
        )).valueHtml = `<div style='position:relative'><div style='position:absolute; bottom: 2px; left: 50px; background-color:${messagetagPartial.Color}; width:100px; height: 15px;'></div></div>`;
        // (<DynamicLabel>this.View.DeletedById).label = 'Some other Label';
        // (<DynamicLabel>this.View.DeletedOn).label = 'Some other Label';
        (<DynamicLabel>this.View.IgnoreEmail).label = 'Set Email to No Action';
        // (<DynamicLabel>this.View.IsDeleted).label = 'Some other Label';
        // (<DynamicLabel>this.View.MarkAsTax).label = 'Some other Label';
        (<DynamicLabel>this.View.MessageNote).label = 'Required Note';
        (<DynamicLabel>this.View.MessageNote).value = (() => {
            if (messagetagPartial) {
                if (!messagetagPartial.AutoApply && messagetagPartial.RequiresNote) {
                    return 'Users are required to manually set a note when applying this tag.';
                } else if (messagetagPartial.MessageNote) {
                    return messagetagPartial.MessageNote;
                } else if (messagetagPartial.DboMessageManagedNote) {
                    return messagetagPartial.DboMessageManagedNote.Note;
                } else {
                    return '';
                }
            } else {
                return '';
            }
        })();
        (<DynamicLabel>this.View.AutoApplyOnAutomationSet).label = 'Automatically set tag when automation status is set (API)';
        (<DynamicLabel>this.View.AutoApplyOnAutomationSet).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Textbox,
            scale: null,
        });
        (<DynamicLabel>this.View.AutoApplyOnAutomationSet).value =
            messagetagPartial && messagetagPartial.AutoApplyOnAutomationSet
                ? `${messagetagPartial.DboMessageTagsAutomationStatus.map((x: any) => x.MessageAutomationStatu.Name).join(',')}`
                : 'No';

        // (<DynamicLabel>this.View.MessageTagContainsId).value = messagetagPartial && messagetagPartial.MessageTagCriteriaContains ? messagetagPartial.MessageTagCriteriaContains.Content : '';
        // (<DynamicLabel>this.View.MessageTagCriteriaFroms).label = "Email From"
        // (<DynamicLabel>this.View.Name).label = 'Some other Label';
        // (<DynamicLabel>this.View.RequireAllCriteriaGroups).label = 'Some other Label';
    }
}
