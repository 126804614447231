import { Component, EventEmitter, Output, OnInit, Input, Injector } from '@angular/core';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IEntityListConfig, IEntity } from '@mt-ng2/entity-list-module';
import { CommonDocumentsComponent, IDocument } from '@mt-ng2/entity-components-documents';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AddDealUpdateService } from './investmentDealUpdateService';
import { IVwClient } from '../../model/interfaces/vw-client';
import { Observable, Subject } from 'rxjs';
import { ClientService } from '../../clients/client.service';
import { IDboInvestment } from '../../model/interfaces/dbo-investment';
import { map } from 'rxjs/operators';
import { TaskService } from '../../tasks/task.service';
import { IDboVClientAltName } from '../../model/interfaces/dbo-v-client-alt-name';

@Component({
    selector: 'app-investmentDeal-modal',
    templateUrl: './investmentDealAddModal.html',
})
export class InvestmentDealAddModalComponent extends CommonDocumentsComponent implements OnInit {
    modalOptions: IModalOptions = {
        showConfirmButton: false,
        width: '50%',
    };

    entity: IDboInvestment;
    entityListConfig: IEntityListConfig;
    doubleClickIsDisabled = false;
    _isEditing = false;
    modalService: AddDealUpdateService;
    clientService: ClientService;
    taskService: TaskService;

    selectedClients: IVwClient[];
    clients$: Observable<IVwClient[]>;
    clients: IVwClient[];
    public clientSubject$ = new Subject<string>();
    clientAltNames: IDboVClientAltName[] = [];
    altNamesAsClients: IVwClient[] = [];
    fullClientsList: IVwClient[] = [];

    newInvestmentSelectedClients: IVwClient[];

    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSave: EventEmitter<void> = new EventEmitter<void>();

    ngOnInit(): void {
        this.clientService.getAll().subscribe((data) => {
            this.clients = data;
            this.fullClientsList = data;
            this.clients$ = this.clientSubject$.pipe(
                map((term) => {
                    return this.searchClients(term);
                }),
            );
            if (this.newInvestmentSelectedClients) {
                this.selectedClients = this.newInvestmentSelectedClients;
                this.filterOutDuplicateClientsByAltName();
            } else {
                this.clientService.getClientsByInvestmentId(this.entity.Id).subscribe((data) => {
                    this.selectedClients = data;
                    this.filterOutDuplicateClientsByAltName();
                });
            }
        });
        this.altNamesAsClients = this.clientAltNames.map(
            (c): IVwClient => ({ Id: c.ClientId, Name: c.ClientName, IsArchived: false, HasTransitioned: false, HasAltNames: false }),
        );
    }

    private searchClients(term: string | null): IVwClient[] {
        if (term) {
            return this.taskService.searchNameList(this.clients, term, 2);
        } else {
            return this.clients;
        }
    }

    filterOutDuplicateClientsByAltName(): void {
        this.clients = [...this.fullClientsList, ...this.altNamesAsClients];
        this.clients = this.clients.filter((c) => !this.selectedClients.some((a) => a.Id === c.Id));
        this.clientSubject$.next('');
    }

    cancel(): void {
        this.onCancel.emit();
    }

    toggleEdit(): void {
        this.isEditing = !this.isEditing;
    }

    addDeals(): void {
        this.modalService.addDeals(this.selectedClients);
        this.cancel();
    }
}
