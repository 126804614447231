<div class="login-box">
    <img class="logo-img" src="{{ logoFull }}" alt="Logo" />
    <div class="login-box-body">
        <p class="login-box-msg">Sign in using your AD credentials to start your session</p>
        <div *ngIf="!config.hideRegularSignIn && loginForm">
            <form [formGroup]="loginForm" (submit)="onLogin()">
                <div class="form-group has-feedback" [class.has-error]="showUsernameRequiredError()">
                    <input type="text" autofocus class="form-control" placeholder="Username" formControlName="username" />
                    <span class="fa fa-user form-control-feedback"></span>
                    <div *ngIf="showUsernameRequiredError()" class="small errortext" [style.position]="'absolute'">
                        Username is required
                    </div>
                </div>
                <div class="form-group has-feedback" [class.has-error]="showPasswordRequiredError()">
                    <input #Password type="password" autocomplete="off" class="form-control" placeholder="Password" formControlName="password" />
                    <span class="fa fa-lock form-control-feedback"></span>
                    <div *ngIf="showPasswordRequiredError()" class="small errortext" [style.position]="'absolute'">
                        Password is required
                    </div>
                </div>
                <div class="padded block-parent">
                    <div *ngIf="config.allowRememberMe" class="form-check">
                        <input type="checkbox" id="rememberMe" class="form-check-input" formControlName="rememberMe" />
                        <label class="form-check-label" for="rememberMe"> Remember Me</label>
                    </div>
                    <button [disabled]="!loginForm.valid" type="submit" class="btn btn-primary btn-flat inline-block block-right">
                        Login
                    </button>
                </div>
            </form>

            <ng-container *ngFor="let link of config.loginComponentLinks">
                <span style="cursor: pointer;" [innerHtml]="link.linkHtml" [routerLink]="link.routerLinkPath"></span><br />
            </ng-container>
        </div>
    </div>
</div>
