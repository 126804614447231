import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { InvestmentRoutingModule } from './investment-routing.module';
import { InvestmentsComponent } from './investment-list/investments.component';
import { InvestmentBasicInfoComponent } from './investment-basic-info/investment-basic-info.component';
import { InvestmentDetailComponent } from './investment-detail/investment-detail.component';
import { InvestmentHeaderComponent } from './investment-header/investment-header.component';
import { InvestmentAddComponent } from './investment-add/investment-add.component';
import { InvestmentDealAddModalComponent } from './investment-add-deal-modal/investmentDealAddModal';
import { ModalModule } from '@mt-ng2/modal-module';
import { NgSelectModule } from '@ng-select/ng-select';
import { InvestmentAddDealsModalComponent } from './investment-add-deals-modal/investmentAddDealsModal';

@NgModule({
    declarations: [
        InvestmentsComponent,
        InvestmentHeaderComponent,
        InvestmentAddComponent,
        InvestmentDetailComponent,
        InvestmentBasicInfoComponent,
        InvestmentDealAddModalComponent,
        InvestmentAddDealsModalComponent,
    ],
    imports: [SharedModule, InvestmentRoutingModule, NgSelectModule, ModalModule]
})
export class InvestmentModule {}
