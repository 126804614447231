import { FundsEntityListConfig } from './funds.entity-list-config';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FundService } from '../fund.service';
import { entityListModuleConfig } from '../../common/shared.module';
import { IEntitySearchParams, ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IItemDeletedEvent, IColumnSortedEvent, SortDirection, EntityListExportService, EntityListExportConfig } from '@mt-ng2/entity-list-module';
import { IVwFund } from '../../model/interfaces/vw-fund';
import { ISearchFilterCheckboxValueChangedEvent } from '@mt-ng2/search-filter-checkbox-control';
import { Router } from '@angular/router';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { NotesUpdatedService } from '../../common/components/notes-modal/notes-modal-updated-service';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'app-fund',
    templateUrl: './funds.component.html',
})
export class FundsComponent implements OnInit, OnDestroy {
    query: string;
    funds: IVwFund[];
    total: number;
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    entityListConfig = new FundsEntityListConfig(this.fundService);
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    includeArchived = false;
    canEditAltNames = false;
    subscription = new Subscription();

    constructor(
        private notesUpdatedObservable: NotesUpdatedService,
        private exportService: EntityListExportService,
        public fundService: FundService,
        public notificationsService: NotificationsService,
        public router: Router,
        public claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        if (!this.claimsService.hasClaim(ClaimTypes.Funds_SeePage, [ClaimValues.FullAccess])) {
            void this.router.navigate(['/home']);
            return;
        }
        this.entityListConfig.export = new EntityListExportConfig({
            exportName: 'Funds List',
            getDataForExport: this.getFundsForExport.bind(this),
        });
        this.canEditAltNames = this.claimsService.hasClaim(ClaimTypes.ManageFundAltNames, [ClaimValues.FullAccess]);
        this.subscription.add(
            this.notesUpdatedObservable.refreshLogsObservable.subscribe(() => {
                this.getFunds();
            }),
        );
        this.getFunds();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getFundsCall(options = { forExport: false }): Observable<HttpResponse<IVwFund[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: String(this.includeArchived),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        if (options.forExport) {
            searchEntity.skip = 0;
            searchEntity.take = this.total;
        }

        const searchparams = new SearchParams(searchEntity);
        return this.fundService.get(searchparams);
    }

    getFunds(): void {
        this.getFundsCall().subscribe((response) => {
            this.funds = response.body;
            this.total = +response.headers.get('X-List-Count');
        });
    }

    includeArchivedFunds(event: ISearchFilterCheckboxValueChangedEvent): void {
        this.includeArchived = event.value;
        this.getFunds();
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getFunds();
    }

    toggleArchive(event: IItemDeletedEvent): void {
        const entity = (event.entity as unknown) as IVwFund;
        const archived = !entity.IsArchived;
        entity.IsArchived = archived;
        this.fundService.setArchivedStatus(entity.Id, archived).subscribe(() => {
            this.notificationsService.success(`${archived ? 'Archived' : 'UnArchived'} successfully`);
        });
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getFunds();
    }

    export(): void {
        this.getFundsForExport().subscribe((data) => {
            this.exportService.export(data, this.entityListConfig);
        });
    }

    getFundsForExport(): Observable<IVwFund[]> {
        return this.getFundsCall({ forExport: true }).pipe(map((answer) => answer.body));
    }
}
