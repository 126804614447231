import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IDboMessageTagCriteriaTo } from '../../model/interfaces/dbo-message-tag-criteria-to';
import { Observable } from 'rxjs';

export const emptyMessageTagCriteriaTo: IDboMessageTagCriteriaTo = {
    Id: 0,
    MessageTagId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class MessageTagCriteriaToService extends BaseService<IDboMessageTagCriteriaTo> {
    constructor(public http: HttpClient) {
        super('/tagsTo', http);
    }

    getEmptyMessageTagCriteriaTo(): IDboMessageTagCriteriaTo {
        return { ...emptyMessageTagCriteriaTo };
    }

    deleteAllByTagId(id: number): Observable<void> {
        return this.http.delete<void>(`/tagsTo/deleteByTagId/${id}`);
    }
}
