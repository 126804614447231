<div #stickyHead id="headerForHeight" class="stickyHeader">
    <div class="row">
        <div class="col-md-1">
            <h2 mtSetTitle>Inbox</h2>
        </div>

        <div class="col-md-11">
            <br />
            <b>Select from saved search:</b>
            <div
                class="btn-group btn-group-toggle"
                ngbRadioGroup
                name="radioBasic"
                [(ngModel)]="selectedSearch"
                (ngModelChange)="filterDownMessageBasedOnSelectedSearch()"
            >
                <label
                    ngbButtonLabel
                    *ngFor="let search of savedSearches"
                    [ngClass]="{'newSearch' : search.Id === 0, 'orgSearch': search.IsOrgSearch && search.Id > 0, 'regSearch': !search.IsOrgSearch && search.Id > 0}"
                    [style.background-color]="search.IsOrgSearch ? 'rgba(0, 136, 186, 0.25)' : ''"
                >
                    <input ngbButton type="radio" [value]="search" />{{ search.Name }}({{ search.newMessages }})
                </label>
            </div>
        </div>
    </div>
    <div>
        <mt-search-bar (searched)="search($event)"></mt-search-bar>
    </div>
    <br />
    <div class="panel panel-default">
        <div class="panel-body" [ngClass]="{'collapse' : isSearchCollapsed, 'collapse.show': !isSearchCollapsed }">
            <div class="search-bar-container">
                <div class="row">
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-md-2">
                                Email Subject:
                            </div>
                            <div class="col-md-10">
                                <custom-search-bar
                                    [(searchVal)]="currSearchObject.subject"
                                    (onSearch)="emailSubjectSearch($event)"
                                ></custom-search-bar>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-2">
                                Email Body:
                            </div>
                            <div class="col-md-10">
                                <custom-search-bar [(searchVal)]="currSearchObject.body" (onSearch)="emailBodySearch($event)"></custom-search-bar>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-2">
                                From:
                            </div>
                            <div class="col-md-10">
                                <custom-search-bar [(searchVal)]="currSearchObject.from" (onSearch)="emailFromSearch($event)"></custom-search-bar>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="row">
                            <div class="col-md-4">
                                Assigned To:
                            </div>
                            <div class="col-md-8">
                                <ng-select
                                    (change)="selectedAssigneeChanged($event)"
                                    [items]="assigneeSearch"
                                    [multiple]="true"
                                    bindLabel="Item.Name"
                                    bindValue="Item.Id"
                                    [(ngModel)]="assigneeSearchModel"
                                >
                                </ng-select>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-4">
                                Assigned By:
                            </div>
                            <div class="col-md-8">
                                <ng-select
                                    (change)="selectedAssignerChanged($event)"
                                    [items]="assignerSearch"
                                    [multiple]="true"
                                    bindLabel="Item.Name"
                                    bindValue="Item.Id"
                                    [(ngModel)]="assignerSearchModel"
                                >
                                </ng-select>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-4">
                                Email IDs:
                            </div>
                            <div class="col-md-8">
                                <textarea
                                    class="message-list-filter"
                                    rows="1"
                                    style="resize: none;"
                                    [(ngModel)]="currSearchObject.messageIds"
                                ></textarea>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-4">
                                Task IDs:
                            </div>
                            <div class="col-md-8">
                                <textarea
                                    class="message-list-filter"
                                    rows="1"
                                    style="resize: none;"
                                    [(ngModel)]="currSearchObject.taskIds"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-md-12">
                        <div class="pull-right">
                            <mt-search-filter-select
                                [items]="lockSearchOptions"
                                entity="Lock Status"
                                showSelectAll="false"
                                (selectionChanged)="wasLockFilterChanged($event)"
                            ></mt-search-filter-select>
                            <mt-search-filter-select
                                [items]="statusSearchOptions"
                                entity="Status"
                                showSelectAll="false"
                                (selectionChanged)="wasStatusFilterChanged($event)"
                            ></mt-search-filter-select>
                            <mt-search-filter-select
                                [items]="taxSearchOptions"
                                entity="Tax Status"
                                showSelectAll="false"
                                (selectionChanged)="wasTaxFilterChanged($event)"
                            ></mt-search-filter-select>
                            <app-search-filter-daterange-custom
                                entity="Received Date"
                                [startDate]="startReceivedDate"
                                [endDate]="endReceivedDate"
                                [contextualDateType]="currSearchObject.contextualDateId"
                                (contextualDateTypeChanged)="setSearchContextualDateType($event)"
                                (onSelectionChanged)="setReceivedDates($event)"
                            ></app-search-filter-daterange-custom>
                            <mt-search-filter-select
                                [items]="automationStatus"
                                entity="Automation Status"
                                (selectionChanged)="selectedStatusChanged($event)"
                            >
                            </mt-search-filter-select>
                            <ng-container *ngIf="!untaggedMessagesCheckbox"> </ng-container>
                            <mt-tag-search-filter-select
                                (click)="$event.stopPropagation();"
                                (onSelectionChanged)="selectedTagsChanged($event)"
                                [items]="searchFilterTags"
                                entity="Tags"
                                [disabled]="untaggedMessagesCheckbox"
                            >
                            </mt-tag-search-filter-select>

                            <div>
                                <input
                                    type="checkbox"
                                    name="untaggedMessages"
                                    id="untaggedMessages"
                                    [(ngModel)]="untaggedMessagesCheckbox"
                                    (ngModelChange)="onUntaggedChanged(untaggedMessagesCheckbox)"
                                />
                                <label for="untaggedMessages">Untagged Messages</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <i
            class="fa"
            [ngClass]="{'fa-caret-up': !isSearchCollapsed, 'fa-caret-down': isSearchCollapsed}"
            style="float: right; font-size: 20px;"
            (click)="toggleSearchCollapse()"
        ></i>
    </div>
    <div class="allButtons">
        <div class="inboxButtonsLeft">
            <button type="button" class="btn btn-primary configureColumnStyle" (click)="configureColumns()" [disabled]="!messages">
                Configure Columns
            </button>
            <button
                *ngIf="!attachmentProcessingService.getIsProcessingTabOpen"
                class="btn btn-primary configureColumnStyle"
                [mtKeyboardShortcut]="mtKeyboardShortcutDef.process"
                title="ctrl + p"
                (click)="moveToProcessSelectedMessages()"
                [disabled]=" !selectedMessages || !selectedMessages.length"
            >
                Process Attachments
            </button>
            <button
                *ngIf="attachmentProcessingService.getIsProcessingTabOpen"
                class="btn btn-primary configureColumnStyle"
                [mtKeyboardShortcut]="mtKeyboardShortcutDef.process"
                title="ctrl + p"
                (click)="moveToProcessSelectedMessages()"
                [disabled]=" !selectedMessages || !selectedMessages.length"
            >
                Add to Processing
            </button>
            <button
                class="btn btn-primary configureColumnStyle"
                (click)="addAttachment()"
                [disabled]=" !selectedMessages || !selectedMessages.length"
            >
                Add Attachment
            </button>
            <span *ngIf="(hasAccess.AssignAny || hasAccess.AssignSelf) && messages && messages.length" style="display: inherit;">
                <span *ngIf="hasAccess.AssignAny" style="display: inherit;">
                    <button
                        [mtKeyboardShortcut]="mtKeyboardShortcutDef.assign"
                        type="button"
                        title="ctrl + a"
                        class="btn btn-primary configureColumnStyle"
                        style="margin-left: 4px; display: inline-block;"
                        (click)="showAssignUserDialog()"
                        [disabled]="!selectedMessages || !selectedMessages.length"
                    >
                        Assign
                    </button>
                </span>
                <span *ngIf="!hasAccess.AssignAny && hasAccess.AssignSelf" style="display: inherit;">
                    <button
                        #assignInput
                        [mtKeyboardShortcut]="mtKeyboardShortcutDef.assign"
                        title="ctrl + a"
                        class="btn btn-primary configureColumnStyle"
                        (click)="showAssignUserDialog()"
                        [disabled]="!selectedMessages || !selectedMessages.length"
                    >
                        Assign Self
                    </button>
                </span>
            </span>
            <span *ngIf="(hasAccess.Tags) && messages && messages.length" style="display: inherit;">
                <span *ngIf="hasAccess.Tags" (click)="$event.stopPropagation();" style="display: inherit;">
                    <span>
                        <button
                            class="btn btn-primary configureColumnStyle"
                            [mtKeyboardShortcut]="mtKeyboardShortcutDef.tag"
                            title="ctrl + alt + t"
                            style="margin-left: 4px; display: inline-block;"
                            (click)="$event.stopPropagation(); selectTags()"
                            [disabled]="!selectedMessages || !selectedMessages.length"
                        >
                            Tag
                        </button>
                    </span>
                </span>
            </span>
            <span>
                <button
                    class="btn btn-primary configureColumnStyle"
                    style="margin-left: 4px; display: inline-block;"
                    (click)="unlockSelectMessages()"
                >
                    Unlock Emails
                </button>
            </span>
            <span>
                <button
                    class="btn btn-primary configureColumnStyle"
                    (click)="addNoteSelectMessage()"
                    [disabled]=" !selectedMessages || !selectedMessages.length"
                >
                    Add Notes
                </button>
            </span>
            <span>
                <button
                    class="btn btn-primary configureColumnStyle"
                    (click)="openGetEmailModal()"
                >
                    Get Email
                </button>
            </span>
        </div>

        <div class="searchButtonContainer">
            <button type="button" class="btn btn-primary searchButton" (click)="searchMessages()">
                Search
            </button>
            <button type="button" class="btn btn-primary searchButton" (click)="saveSearch()">
                Save Search
            </button>
            <button type="button" class="btn btn-primary searchButton" (click)="deleteSearch()">
                Delete Search
            </button>
            <button type="button" class="btn btn-primary searchButton" (click)="clearSearch()">
                Clear Search
            </button>
        </div>
    </div>
</div>

<div #stickyHeadResult *ngIf="messages && entityListConfig" class="stickyHeadBacker">
    <div class="stickyHeadResults" id="resultsForHeight">(Total Results: {{total}}) (Selected Messages: {{selectedMessages.length}})</div>
    <div class="stickyHeadFill"></div>
</div>
<br />
<section>
    <div
        *ngIf="messages && entityListConfig"
        [ngClass]="isSearchCollapsed ? 'stickyEntityHeadSmall' : 'stickyEntityHeadBig'"
        [style.marginTop.px]="getStickyHeadMarginTop()"
        class="messageListContainer"
    >
        <mt-entity-list
            class="stickyEntityHead"
            [entityListConfig]="entityListConfig"
            [(entities)]="messages"
            [(selectedEntities)]="selectedMessages"
            (columnSortedEvent)="columnSorted($event)"
            (selectionChangedEvent)="onSelectionChanged($event)"
        >
        </mt-entity-list>
        <div *ngIf="loadingMore" class="fa-3x" style="display: flex; justify-content: center;">
            <i class="fa fa-circle-o-notch fa-spin"></i>
        </div>
        <scroll-check offset="75px" (cameIntoView)="getMore()"></scroll-check>
    </div>
</section>

<mt-modal-wrapper
    [autoShow]="false"
    [options]="{ showCancelButton: true, showConfirmButton: true, confirmButtonText: 'Save', width: 500 }"
    (ready)="configureColumnsPopUpApi = $event"
    (okClick)="saveUserColumns()"
>
    <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="userMessageColumns" class="example-list" (cdkDropListDropped)="drop($event)">
        <div class="example-box" *ngFor="let item of userMessageColumns; let i = index" [cdkDragDisabled]="false" cdkDrag>
            <label><input [(ngModel)]="item.IsVisible" type="checkbox" style="margin-right: 6px;" />{{ item.Name }}</label>
        </div>
    </div>
</mt-modal-wrapper>

<mt-modal-wrapper
    [autoShow]="false"
    [options]="{ showCancelButton: false, showConfirmButton: false, confirmButtonText: 'Ok', width: 700 }"
    (ready)="manualNoteModalApi = $event"
    (okClick)="manualNoteDialogConfirm()"
>
    <div class="row">
        <h4><b>This Tag Requires A Note:</b></h4>

        <div>
            <label for="note">Select Note</label>
            <select [ngModel]="selectedNote" (ngModelChange)="selectedNoteChanged($event)">
                <option class="form-control" [ngValue]="null" [selected]="true">Custom Note</option>
                <option class="form-control" *ngFor="let note of managedNotes" [ngValue]="note"
                    >{{note.Note.length > 50 ? note.Note.slice(0,50) + '...' : note.Note}}</option
                >
            </select>
        </div>
        <div *ngIf="!selectedNote">
            <textarea
                class="noteModalNoteInput form-control"
                #noteInput
                focus
                [(ngModel)]="manualNote"
                style="resize: none; margin-top: 5px;"
                rows="4"
                cols="100"
                maxlength="250"
            ></textarea>
            <span class="small pull-right">{{manualNote.length}}/250</span>
        </div>
        <div *ngIf="selectedNote" class="display-padded" style="text-align: left; font-size: 14px;">
            <span><label>Note:</label></span>
            <span>{{manualNote}}</span>
        </div>
        <div>
            <button class="btn btn-primary" style="width: 50px;" (click)="manualNoteDialogConfirm()" [disabled]="!selectedNote && !manualNote.length">
                Ok
            </button>
            <button class="btn btn-secondary" style="width: 50px;" (click)="manualNoteDialogCancel()">Cancel</button>
        </div>
    </div>
</mt-modal-wrapper>

<mt-modal-wrapper
    [autoShow]="false"
    [options]="assignEmailModalOptions"
    (ready)="assignEmailModalApi = $event"
    (okClick)="assignEmails($event)"
>
    <h3>Assign Emails</h3>
    <div style="font-size: 14px; height: 45vh;">
        <div>
            <div>
                <label>Number Of Emails: </label>
                <mt-search-filter-select 
                [items]="messageService.numberOfEmailsAssignOptions"
                [(ngModel)]="selectedOptions" 
                entity="Number Of Emails"
                [showSelectAllButtons]="false"
                (selectionChanged)="selectSources($event)"
                ></mt-search-filter-select>
            </div>
            <div style="margin-top: 20px;">
                <label>Tags: </label>
                <mt-tag-search-filter-select
                (click)="$event.stopPropagation();"
                (onSelectionChanged)="selectedTagsForAssignEmails($event)"
                [items]="assignEmailSearchFilterTags"
                entity="Tags">
                </mt-tag-search-filter-select>
            </div>
        </div>
    </div>
</mt-modal-wrapper>

