import { Component, Input } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntity, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { ITaskRow } from './task-list.component';

@Component({
    templateUrl: './row-expander.component.html',
})
export class RowExpanderComponent implements IEntityListDynamicCellComponent {
    @Input() entity: IEntity;
    entityListComponentMembers: IEntityListComponentMembers;
    get task(): ITaskRow {
        return this.entity as ITaskRow;
    }
}
