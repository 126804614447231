import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef, EventEmitter } from '@angular/core';
import { take } from 'rxjs/operators';
import { SharedModule } from '../../common/shared.module';
import { IEntity } from '../../model/interfaces/base';
import { FundAltNameModalComponent } from './fund-alt-names-modal';

@Injectable({
    providedIn: SharedModule,
})
export class FundModalAltNamesService {
    private _componentRef: ComponentRef<FundAltNameModalComponent>;

    saved: EventEmitter<void> = new EventEmitter<void>();

    constructor(private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) {}

    showModal(entity: IEntity): void {
        this.destroyComponent();

        this._componentRef = this.componentFactoryResolver.resolveComponentFactory(FundAltNameModalComponent).create(this.injector);
        this._componentRef.instance.entity = entity;

        this._componentRef.instance.onSave.pipe(take(1)).subscribe(() => this.saved.emit());
        this._componentRef.instance.onCancel.pipe(take(1)).subscribe(() => {
            this.destroyComponent();
        });

        this.appRef.attachView(this._componentRef.hostView);
        const domElem = (this._componentRef.hostView as EmbeddedViewRef<FundAltNameModalComponent>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
    }

    private destroyComponent(): void {
        if (this._componentRef) {
            this.appRef.detachView(this._componentRef.hostView);
            this._componentRef.destroy();
            this._componentRef = null;
        }
    }
}
