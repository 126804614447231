import { IEntity } from '../../model/interfaces/base';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { Component } from '@angular/core';
import { IDboTaskDTO } from '../../model/interfaces/custom/IDboTaskDTO';
import { TaskDocumentsService } from '../task-document-service';
import { EnvironmentService } from '@mt-ng2/environment-module';

@Component({
    styles: [
        `
            ol {
                padding-inline-start: 15px;
            }
        `,
    ],
    template: `
        <div (click)="suppress($event)">
            <ol>
                <li *ngFor="let doc of supportingDocs" class="wrapword">
                    <a [href]="doc.href" target="_blank">{{ doc.Name }}</a>
                </li>
            </ol>
        </div>
    `,
})
export class SupportingDocumentsListComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        const dboTaskDto = value as IDboTaskDTO;
        this.supportingDocs = dboTaskDto.DboSupportingDocuments
            ? dboTaskDto.DboSupportingDocuments.map((d) => ({
                  href: this.taskDocumentService.getDocumentPath(dboTaskDto.Id, d.Id, true),
                  Name: d.Name,
              }))
            : [];
    }

    supportingDocs: ISupportingDocInfo[];
    constructor(public taskDocumentService: TaskDocumentsService, private envSvc: EnvironmentService) {}

    suppress(event: Event): void {
        event.stopPropagation();
    }
}

interface ISupportingDocInfo {
    href: string;
    Name: string;
}
