import { CustomNavHeaderComponent } from './nav/custom-nav/customer-nav-header/customer-nav-header.component';
import { ClientModule } from './clients/client.module';
import { FundModule } from './funds/fund.module';
import { TenantModule } from './tenants/tenant.module';
import { ErrorHandler, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';

import { AuthModule } from '@mt-ng2/auth-module';
import { EnvironmentModule, EnvironmentModuleConfigToken } from '@mt-ng2/environment-module';
import { NotificationsModule } from '@mt-ng2/notifications-module';
import { BreckErrorHandler } from '@mt-ng2/errors-module';
import { MtLoginModule, LoginModuleConfigToken } from '@mt-ng2/login-module';

import { AppRoutingModule } from './app-routing.module';
import { AppNavModule } from './nav/app-nav.module';
import { SharedModule } from './common/shared.module';
import { AppComponent } from './app.component';
import { environment } from './environments/environment';
import { CustomerModule } from './customers/customer.module';
import { UserModule } from './users/user.module';
import { UserRoleModule } from './user-roles/user-roles.module';
import { AppSettingsComponent } from './app-settings.component';
import { AppSettingsService } from './app-settings.service';
import { LoginConfigOverride } from './common/configs/login.config';

import { ToastrModule } from 'ngx-toastr';

import { NotFoundComponent } from './default-routes/not-found/not-found.component';
import { DashboardModule } from '@mt-ng2/dashboard';
import { recommmendedPagesModuleConfig } from './common/configs/recommended-pages.config';
import { TaskModule } from './tasks/task.module';
import { DownloadComponent } from './common/components/download/download.component';

import { ADLoginComponent } from './common/components/ad-login/ad-login.component';
import { ADLoginService } from './common/services/ad-login.service';
import { CustomNavComponent } from './nav/custom-nav/custom-nav.component';
import { CustomNavSidebarComponent } from './nav/custom-nav/custom-nav-sidebar/custom-nav-sidebar.component';
import { InvestmentModule } from './investments/investment.module';
import { NotesModalModule } from './common/components/notes-modal/notes-modal-module';
import { MessageModule } from './messages/message.module';
import { DboTaskTypeModule } from './dbo-task-types/dbo-task-type.module';
import { AuditModule } from './audit/audit-module';
import { AuditModalModule } from './common/components/view-audit-modal/audit-modal-module';
import { ProcessAttachementsModule } from './processAttachments/processAttachment.module';
import { TypeAheadModule, MatchingStrategy, ITypeAheadConfig, TypeAheadModuleConfigToken } from '@mt-ng2/type-ahead-control';
import { DocumentDisplayComponent } from './messages/email-body-display/document-download-component';
import { MessageTagModule } from './message-tags/message-tag.module';
import { AttachmentTagsModule } from './attachment-tags/attachment-tags.module';
import { ManagedNotesModule } from './message-managed-notes/message-managed-notes.module';
import { MultiselectControlModule } from '@mt-ng2/multiselect-control';
import { TagSelectModule } from './common/components/tag-selector/tag-select-module';
import { EmailContactModule } from './email-contacts/email-contact.module';
import { AccessRequestsModuleConfigService } from '@mt-ng2/access-requests-module-config';
import { of } from 'rxjs';
import { CookieModule } from '@mt-ng2/cookie';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';


export const typeAheadConfig: ITypeAheadConfig = {
    matchingStrategy: MatchingStrategy.StartsWith,
};

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        AppSettingsComponent,
        ADLoginComponent,
        CustomNavComponent,
        CustomNavSidebarComponent,
        CustomNavHeaderComponent,
        DownloadComponent,
        NotFoundComponent,
    ],
    imports: [
        BrowserModule,
        NgbModule,
        SharedModule.forRoot(),
        HttpClientModule,
        NgProgressModule.withConfig({
            color: '#ff8b56',
            spinnerPosition: 'left',
            thick: false,
        }),
        NgProgressHttpModule,
        // https://dev.4miles.com/#/questions/1386
        CookieModule.withOptions({ secure: true, sameSite: 'strict'}),
        EnvironmentModule,
        AuthModule.forRoot(),
        CustomerModule.forRoot(),
        UserModule.forRoot(),
        UserRoleModule.forRoot(),
        NotesModalModule,
        AuditModalModule,
        FundModule,
        AuditModule,
        InvestmentModule,
        ClientModule,
        TaskModule,
        MessageModule,
        DboTaskTypeModule,
        MessageTagModule,
        AttachmentTagsModule,
        TenantModule,
        ManagedNotesModule,
        EmailContactModule,
        TagSelectModule,
        ProcessAttachementsModule,
        AppRoutingModule,
        NotificationsModule,
        NgxMaskModule.forRoot(),
        AppNavModule.forRoot(),
        DashboardModule.forRoot(recommmendedPagesModuleConfig),
        MtLoginModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-left',
        }),
        TypeAheadModule,
        MultiselectControlModule,
    MtDisableDuringHttpCallsModule.forRoot()],
    providers: [
        { provide: ErrorHandler, useClass: BreckErrorHandler },
        AppSettingsService,
        ADLoginService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LoginModuleConfigToken, useValue: LoginConfigOverride },
        { provide: TypeAheadModuleConfigToken, useValue: typeAheadConfig },
        
        // https://dev.4miles.com/#/questions/950
        { provide: AccessRequestsModuleConfigService, useValue: { accessRequestsEnabled: of(false) } },
		{ provide: EnvironmentModuleConfigToken, useValue: environment },
    ],
})
export class AppModule {}
