import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { ProcessAttachmentComponent } from './processAttachment/processAttachment.component';
import { ProcessAttachmentListComponent } from './processAttachment-list/processAttachment-list.component';
import { ProcessAttachmentRoutingModule } from './processAttachment-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from '@mt-ng2/modal-module';
import { ProcessAttachmentAddModalComponent } from './processAttachment-add-modal/processAttachmentAddModal';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MtDateModule } from '@mt-ng2/date-module';

@NgModule({
    declarations: [ProcessAttachmentComponent, ProcessAttachmentListComponent, ProcessAttachmentAddModalComponent],
    imports: [SharedModule, ProcessAttachmentRoutingModule, NgSelectModule, ModalModule, CommonModule, FormsModule, NgbModule, MtDateModule]
})
export class ProcessAttachementsModule {}
