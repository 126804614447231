import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TaskService } from '../task.service';
import { IDboTaskDTO } from '../../model/interfaces/custom/IDboTaskDTO';
import { AuditModalService } from '../../common/components/view-audit-modal/view-audit-modal-service';
import { IEntityWithEmailId } from '../../model/interfaces/custom/EntityWithEmailId';
@Component({
    selector: 'app-task-header',
    templateUrl: './task-header.component.html',
})
export class TaskHeaderComponent implements OnInit {
    task: IDboTaskDTO;
    header: string;
    taskId: number;
    constructor(
        private route: ActivatedRoute,
        private taskService: TaskService,
        private auditModalService: AuditModalService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.taskId = this.getIdFromRoute(this.route, 'Id');
        if (this.taskId) {
            this.taskService.getTaskById(this.taskId).subscribe((response) => {
                this.task = response.body;
            });
        }
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10)) ? null : parseInt(id, 10);
    }

    showAudits(): void {
        const taskId = this.getIdFromRoute(this.route, 'Id');
        const queryParams: Params = { getMessageAudit: 'false' };

        void this.router.navigate([], {
            queryParams: queryParams,
            queryParamsHandling: 'merge',
            relativeTo: this.route,
        });

        const taskEntityId: IEntityWithEmailId = { Id: taskId };

        if (taskId) {
            this.auditModalService.showModal(taskEntityId);
        }
    }
}
