import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IMessageTag } from '../../model/interfaces/message-tag';
import { SharedModule } from '../../common/shared.module';

@Injectable({
    providedIn: SharedModule,
})
export class AssignTagUpdateService {
    assignTagEvent = new Subject<IMessageTag[]>();

    assignTagObservable = this.assignTagEvent.asObservable();

    announceTags(tags: IMessageTag[]): void {
        this.assignTagEvent.next(tags);
    }
}
