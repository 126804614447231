import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'custom-search-bar',
    styles: [],
    template: `
        <div>
            <div class="input-group" style="display:flex">
                <input class="form-control" type="text" placeholder="Search" [ngModel]="searchVal" (ngModelChange)="newValue($event)" />
                <span class="input-group-button">
                    <button class="btn btn-default btn-nohover btn-flat" type="button" (click)="clearSearch()">
                        <i class="fa"></i>
                        <i class="fa fa-remove"></i>
                        <i class="fa"></i>
                    </button>
                </span>
            </div>
        </div>
    `,
})
export class CustomSearchBar {
    @Input() searchVal = '';
    @Output() onSearch = new EventEmitter<string>();
    constructor() {}
    clearSearch(): void {
        this.searchVal = '';
    }

    newValue(newValue: string): void {
        this.onSearch.emit(newValue);
    }
}
