import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IMessageTagForward } from '../../model/interfaces/message-tag-forward';
import { Observable } from 'rxjs';

export const emptyMessageTagForward: IMessageTagForward = {
    Email: null,
    Id: 0,
    MessageTagId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class MessageTagForwardService extends BaseService<IMessageTagForward> {
    constructor(public http: HttpClient) {
        super('/tagsForward', http);
    }

    getEmptyMessageTagForward(): IMessageTagForward {
        return { ...emptyMessageTagForward };
    }

    deleteAllByTagId(id: number): Observable<void> {
        return this.http.delete<void>(`/tagsForward/deleteByTagId/${id}`);
    }
}
