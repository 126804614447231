import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
    IEntityListDynamicCellComponent,
    IEntityListComponentMembers,
    IEntity,
    EntityListConfig,
    IEntityListConfig,
} from '@mt-ng2/entity-list-module';
import { Subscription } from 'rxjs';
import { AuditModalService } from './view-audit-modal-service';
import { IEntityWithEmailId } from '../../../model/interfaces/custom/EntityWithEmailId';
import { IDboMessageDTO } from '../../../model/interfaces/custom/IDboMessageDTO';

@Component({
    selector: 'app-view-audit',
    styles: [
        `
            .viewAuditContainer {
                width: 100%;
            }
        `,
    ],
    template: `
        <div class="viewAuditContainer">
            <span (click)="viewAudit($event)" class="btn btn-transparent" title="{{ item.LastAuditLogDetails }}">
                <p>View</p>
            </span>
        </div>
    `,
})
export class ViewAuditComponent implements IEntityListDynamicCellComponent, OnInit {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntityWithEmailId) {
        this.item = value as IDboMessageDTO;
    }
    item: IDboMessageDTO;

    constructor(private modalService: AuditModalService) {}
    ngOnInit(): void {}

    viewAudit($event: any): void {
        event.stopPropagation();
        this.modalService.showModal(this.item);
    }
}
