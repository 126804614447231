import { NgModule } from '@angular/core';
import { ModalModule } from '@mt-ng2/modal-module';
import { ViewNoteComponent } from './view-note-component';
import { NotesModalComponent } from './notes-modal-component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EntityListModule } from '@mt-ng2/entity-list-module';

@NgModule({
    declarations: [ViewNoteComponent, NotesModalComponent],
    imports: [ModalModule, CommonModule, FormsModule, NgbModule]
})
export class NotesModalModule {}
