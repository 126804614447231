import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { IDboTaskType } from '../model/interfaces/dbo-task-type';
import { IDboTaskTypeBacking } from '../model/interfaces/dbo-task-type-backing';
import { DboTaskTypeDynamicControlsExtended } from '../model/partials/dbo-task-type.form-controls';
import { TaskDateType } from '../model/TaskDateType';
import { ITaskTypeTier } from '../model/interfaces/task-type-tier';
import { ITaskDateType } from '../model/interfaces/custom/ITaskDateType';

export class DboTaskTypeDynamicConfig<T extends IDboTaskType> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private dboTaskType: T, private backings: IDboTaskTypeBacking[], private tiers: ITaskTypeTier[], private configControls?: string[]) {
        super();
        const additionalParams = {
            taskTypeBackings: backings,
            tiers: tiers,
        };

        const dynamicControls = new DboTaskTypeDynamicControlsExtended(this.dboTaskType, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Name', 'TaskTypeBackingId', 'TierId', 'TaskDateType'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
