import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent } from '@mt-ng2/entity-list-module';

import { EmailContactService } from '../services/email-contact.service';
import { IEmailContact } from '../../model/interfaces/email-contact';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { EmailContactsEntityListConfig } from './email-contacts.entity-list-config';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-email-contacts',
    templateUrl: './email-contacts.component.html',
})
export class EmailContactsComponent implements OnInit {
    emailContacts: IEmailContact[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new EmailContactsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    includeArchived = false;

    canAddEmailContact = false;

    constructor(
        private emailContactService: EmailContactService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.canAddEmailContact = this.claimsService.hasClaim(ClaimTypes.EmailContacts, [ClaimValues.FullAccess]);
        this.getEmailContacts();
    }

    getEmailContactsCall(): Observable<HttpResponse<IEmailContact[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: String(this.includeArchived),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.emailContactService.get(searchparams);
    }

    getEmailContacts(): void {
        this.getEmailContactsCall().subscribe((answer) => {
            this.emailContacts = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getEmailContacts();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getEmailContacts();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getEmailContacts();
    }

    emailContactSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/emailContacts', event.entity.Id]);
    }

    includeArchivedChanged(event: boolean): void {
        this.includeArchived = event;
        this.getEmailContacts();
    }

    toggleArchive(event: IItemDeletedEvent): void {
        const entity = (event.entity as unknown) as IEmailContact;
        const archived = !entity.IsArchived;
        entity.IsArchived = archived;
        this.emailContactService.update(entity).subscribe(() => {
            this.notificationService.success(entity.IsArchived ? 'Archived Successfully' : 'Unarchived Successfully');
            this.getEmailContacts();
        });
    }
}
