import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { IMessageRow } from '../message-list/message-list-component';
import { DboMessageService } from '../services/dbo-message.service';
import { IDboMessage } from '../../model/interfaces/dbo-message';
import { DboMessageDocumentService } from '../services/dbo-message-document-service';
import { IDboAttachment } from '../../model/interfaces/dbo-attachment';
import { IBlobDownload } from '../../model/interfaces/custom/blob-download';
import { MimeTypes } from '../../common/constants/MimeTypes';

@Component({
    selector: 'app-document-display',
    styles: [
        `
            a {
                margin-right: 20px;
                flex-basis: 1em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        `,
    ],
    template: ``,
    templateUrl: './document-download.html',
})
export class DocumentDisplayComponent implements OnInit {
    @Input() attachment: IDboAttachment;
    allowedMimeType = MimeTypes;
    href: string;

    constructor(private messageDocumentService: DboMessageDocumentService) {}

    ngOnInit(): void {
        this.setHref();
    }

    setIcon(attachment: IDboAttachment): string {
        if (
            attachment.ContentType === 'application/excel' ||
            attachment.ContentType === 'application/vnd.ms-excel' ||
            attachment.ContentType === 'application/vnd.ms-excel.sheet.binary.macroenabled' ||
            attachment.ContentType === 'application/vnd.ms-excel.sheet.macroenabled.12' ||
            attachment.ContentType === 'application/vnd.ms-xpsdocument' ||
            attachment.ContentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            return 'fa fa-file-excel-o';
        } else if (
            attachment.ContentType === 'image/gif' ||
            attachment.ContentType === 'image/jpeg' ||
            attachment.ContentType === 'image/jpg' ||
            attachment.ContentType === 'image/png' ||
            attachment.ContentType === 'image/tiff' ||
            attachment.ContentType === 'image/x-citrix-jpeg'
        ) {
            return 'fa fa-picture-o';
        } else if (
            attachment.ContentType === 'application/msword' ||
            attachment.ContentType === 'application/vnd.openxmlformats-officedocument.word' ||
            attachment.ContentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            attachment.ContentType === 'application/word'
        ) {
            return 'fa fa-file-word-o';
        } else if (attachment.ContentType === 'application/zip' || attachment.ContentType === 'application/x-zip-compressed') {
            return 'fa fa-file-archive-o';
        } else if (attachment.ContentType === 'text/csv' || attachment.ContentType === 'application/octet-stream') {
            return 'fa fa-table';
        } else if (attachment.ContentType === 'application/pdf') {
            return 'fa fa-file-pdf-o';
        } else if (attachment.ContentType === 'application/powerpoint') {
            return 'fa fa-file-powerpoint-o';
        } else if (attachment.ContentType === 'application/rtf') {
            return 'fa fa-file-text-o';
        } else if (attachment.ContentType === 'text/calendar') {
            return 'fa fa-calendar';
        } else if (attachment.ContentType === 'text/html') {
            return 'fa fa-html5';
        } else if (attachment.ContentType === 'text/plain') {
            return 'fa fa-file-o';
        } else if (attachment.ContentType === 'text/x-vcard') {
            return 'fa fa-address-card-o';
        } else if (attachment.ContentType === 'message/rfc822') {
            return 'fa fa-envelope-o';
        } else if (attachment.ContentType === 'a/p') {
            return 'fa fa-square';
        } else if (attachment.ContentType === 'unknown') {
            return 'fa fa-question-circle-o';
        }
        return 'fa fa-question-circle-o';
    }

    setHref(): void {
        this.href = this.messageDocumentService.getDocumentPath(
            this.attachment.MessageId,
            this.attachment.Id,
            true,
            this.attachment.isReplyAttachment,
        );
    }

    suppress(event: Event): void {
        event.stopPropagation();
    }
}
