import { IEntity } from '../../model/interfaces/base';
import { IClient } from '../../model/interfaces/custom/client';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { Component } from '@angular/core';
import { IDboTaskDTO } from '../../model/interfaces/custom/IDboTaskDTO';

@Component({
    styles: [
        `
            ol {
                padding-inline-start: 15px;
            }
        `,
    ],
    template: `
        <div (click)="$event.stopPropagation()">
            <ol>
                <li *ngFor="let client of clientIDNames">{{ client }}</li>
            </ol>
        </div>
    `,
})
export class ClientListComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.dboTaskDto = value as IDboTaskDTO;
        this.clientIDNames = this.dboTaskDto.Clients ? this.dboTaskDto.Clients.map((c) => c.Name) : [];
    }

    clientIDNames: string[];
    dboTaskDto: IDboTaskDTO;
    constructor() {}
}
