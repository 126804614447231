import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { IEmailContact } from '../../model/interfaces/email-contact';

export class EmailContactsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: (entity: IEmailContact) => {
                        if (entity.FirstName || entity.LastName || entity.MiddleName) {
                            return `${entity.FirstName ? entity.FirstName + ' ' : ''}${entity.MiddleName ? entity.MiddleName + ' ' : ''}${
                                entity.LastName ? entity.LastName : ''
                            }`;
                        } else {
                            return 'No Name Listed';
                        }
                    },
                    name: 'Name',
                    sort: {
                        disableSort: false,
                        sortProperty: 'LastName',
                    },
                }),
                new EntityListColumn({
                    accessors: ['Email'],
                    name: 'Email',
                }),
            ],
            delete: new EntityListDeleteColumn({
                columnHtml: (entity: IEmailContact) =>
                    '<div class="text-center investment-archive">' +
                    '<i class="fa fa-lg fa-trash" aria-hidden="true"></i>' +
                    (entity.IsArchived ? '<i class="fa fa-lg fa-undo overlay-fa"></i>' : '') +
                    '</div>',
                confirm: false,
                headerText: 'Archive',
            }),
        };
        super(listConfig);
    }
}
