import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../model/ClaimTypes';
import { MessageManagedNotesComponent } from './message-managed-notes-list.component';

const fundListRoleGuard: any = {
    claimType: ClaimTypes.ManageNotes,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'Id',
    title: 'Managed Notes',
};
const fundRoutes: Routes = [{ path: 'manageNotes', component: MessageManagedNotesComponent, canActivate: [AuthGuard], data: fundListRoleGuard }];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(fundRoutes)],
})
export class ManageNotesRoutingModule {}
