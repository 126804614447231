import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { MessageTagService } from '../services/message-tag.service';
import { IMessageTag } from '../../model/interfaces/message-tag';
import { ClaimTypes } from '../../model/ClaimTypes';

@Component({
    templateUrl: './message-tag-detail.component.html',
})
export class MessageTagDetailComponent implements OnInit {
    messageTag: IMessageTag;
    canEdit: boolean;
    canAdd: boolean;

    constructor(
        private messageTagService: MessageTagService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Tags, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = this.getIdFromRoute(this.route, 'messageTagId');
        if (id) {
            this.getMessageTagById(id);
        } else {
            void this.router.navigate(['messageTags']); // if no id found, go back to list
        }
    }

    getMessageTagById(id: number): void {
        this.messageTagService.getById(id).subscribe((messageTag) => {
            if (messageTag === null) {
                this.notificationsService.error('MessageTag not found');
                void this.router.navigate(['messageTags']);
            }
            this.messageTag = messageTag;
        });
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10)) ? null : parseInt(id, 10);
    }
}
