import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { MessageTagDynamicControls, IMessageTagDynamicControlsParameters } from '../model/form-controls/message-tag.form-controls';
import { IMessageTag } from '../model/interfaces/message-tag';
import { MessageTagDynamicControlsPartial } from '../model/partials/message-tag-partial.form-controls';

export class MessageTagDynamicConfig<T extends IMessageTag> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private messageTag: T, private configControls?: string[]) {
        super();
        const dynamicControls = new MessageTagDynamicControlsPartial(this.messageTag);
        // default form implementation can be overridden at the component level

        if (!configControls) {
            this.configControls = [
                'Name',
                'Color',
                'AutoApply',
                'AutoApplyOnAutomationSet',
                'AutoApplyOnShoreGroupPattern',
                'AutoApplyOnMessageHaveThreadId',
                'RequireAllCriteriaGroups',
                'IgnoreEmail',
                'MarkAsTax',
                'AutomationStatusOn',
                'MessageNote',
                'MessageTagContainsId',
                'MessageTagCriteriaFroms',
                'MessageTagForwards',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
