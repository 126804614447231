<div class="miles-editable miles-card padded" *ngIf="!isEditing && user.Id > 0" (mouseenter)="isHovered = true" (mouseleave)="isHovered = false">
    <h4>
        User Info
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered" (click)="edit()" [style.cursor]="canEdit ? 'pointer' : 'default'">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <mt-dynamic-view [config]="config.viewOnly"></mt-dynamic-view>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>User Info</h4>
    <mt-dynamic-form
        [config]="config.formObject"
        ([isEditing])="(isEditing)"
        (submitted)="formSubmitted($event)"
        (formCreated)="formCreated($event)"
    >
        <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-success">
            Save
        </button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
            Cancel
        </button>
    </mt-dynamic-form>
</div>
