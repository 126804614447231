import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '../model/ClaimTypes';
import { ProcessAttachmentListComponent } from './processAttachment-list/processAttachment-list.component';
import { ProcessAttachmentAddModalComponent } from './processAttachment-add-modal/processAttachmentAddModal';
import { ProcessAttachmentComponent } from './processAttachment/processAttachment.component';

const processAttachmentRoleGuard: any = {
    claimType: ClaimTypes.ProcessAttachments,
    claimValues: [ClaimValues.FullAccess],
    entityIdParam: 'AttachmentId',
    title: 'processAttachments',
};

export const processAttachment = {
    path: 'processAttachments',
};

const processAttachmentRoutes: Routes = [
    {
        canActivate: [AuthGuard],

        component: ProcessAttachmentListComponent,
        data: processAttachmentRoleGuard,
        path: processAttachment.path,
    },
    {
        canActivate: [AuthGuard],
        children: [
            {
                component: ProcessAttachmentAddModalComponent,
                path: `processAttachments/${String(processAttachmentRoleGuard.entityIdParam)}`,
                pathMatch: 'full',
            },
        ],
        component: ProcessAttachmentComponent,
        data: processAttachmentRoleGuard,
        path: processAttachment.path,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(processAttachmentRoutes)],
})
export class ProcessAttachmentRoutingModule {}
