import { Component, EventEmitter, Input, OnInit, Output, Inject, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import {
    ISearchFilterDaterangeValue,
    ContextualDateTypesEnum,
    IMtSearchFilterDaterangeModuleConfig,
    DaysOfTheWeek,
    getDateRangeByContextualFilter,
    ContextualDateTypes,
    IContextualDateTypes,
} from './search-filter-daterange.library';
import { defaultSearchFilterModuleConfig } from './default-module-config';

@Component({
    selector: 'app-search-filter-daterange-custom',
    styles: [
        `
            .show.dropdown {
                display: inline !important;
            }
            .form-padding {
                padding: 10px;
                width: 400px;
            }
            .start-date {
                float: left;
            }
            .end-date {
                float: right;
            }
            .action-div {
                min-height: 30px;
            }
            .btn-clear {
                margin-right: 5px;
            }
            .error-spacer {
                clear: both;
            }
            #start-after-end-error {
                position: absolute;
                bottom: 0;
                left: 10px;
            }
        `,
    ],
    templateUrl: './search-filter-daterange.component.html',
})
export class SearchFilterDaterangeCustomComponent implements OnInit, OnChanges {
    private _startDate: Date;
    @Input()
    set startDate(date: Date) {
        this._startDate = date;
        this.setAppliedRange();
    }
    get startDate(): Date {
        return this._startDate;
    }

    private _endDate: Date;
    @Input()
    set endDate(date: Date) {
        this._endDate = date;
        this.setAppliedRange();
    }
    get endDate(): Date {
        return this._endDate;
    }

    @Input() minDate: Date = null;
    @Input() maxDate: Date = null;
    @Input() contextualDateType: ContextualDateTypesEnum;
    @Input() availableContextualDates: IContextualDateTypes[];
    @Input() entity: string;
    @Input() enabled = true;
    @Input() showTimepicker = true;
    @Output('onSelectionChanged') selectionChanged: EventEmitter<ISearchFilterDaterangeValue> = new EventEmitter<ISearchFilterDaterangeValue>();
    @Output('contextualDateTypeChanged') contextualDateTypeChanged = new EventEmitter<number>();
    @ViewChild('ngbDropdown') ngbDropdown: NgbDropdown;

    firstDayOfTheWeek: DaysOfTheWeek;
    contextualDateTypes = ContextualDateTypes;
    appliedRange: ISearchFilterDaterangeValue;

    constructor(
        @Inject('search_filter_custom_module_config')
        private searchFilterModuleConfig: IMtSearchFilterDaterangeModuleConfig,
    ) {}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.contextualDateType) {
            this.contextualDateTypeChange(changes.contextualDateType.currentValue as ContextualDateTypesEnum);
        }
    }

    ngOnInit(): void {
        this.searchFilterModuleConfig = Object.assign(defaultSearchFilterModuleConfig, this.searchFilterModuleConfig);
        this.firstDayOfTheWeek = this.searchFilterModuleConfig.firstDayOfWeek;
        this.setContextualDateRanges();
        this.setAppliedRange();
    }

    private setContextualDateRanges(): void {
        if (this.availableContextualDates && this.availableContextualDates.length > 0) {
            this.contextualDateTypes = this.availableContextualDates;
        } else {
            this.contextualDateTypes = this.searchFilterModuleConfig.contextualDates;
        }
    }

    private setAppliedRange(): void {
        this.appliedRange = {
            endDate: this.endDate,
            startDate: this.startDate,
        };
    }

    isValid(): boolean {
        return !this.hasDatesReversedError();
    }

    hasDatesReversedError(): boolean {
        return this.startDate && this.endDate && this.startDate > this.endDate;
    }

    selectedItemsText(): string {
        const start = this.appliedRange ? this.appliedRange.startDate : null;
        const end = this.appliedRange ? this.appliedRange.endDate : null;
        if (!start && !end) {
            return `Any ${this.entity}`;
        }
        if (!start) {
            return `${this.entity}: <small>${this.searchFilterModuleConfig.beforeText} ${this.getDateString(end)}</small>`;
        }
        if (!end) {
            return `${this.entity}: <small>${this.searchFilterModuleConfig.afterText} ${this.getDateString(start)}</small>`;
        }
        return `${this.entity}: <small>${this.getDateString(start)} ${this.searchFilterModuleConfig.throughText} ${this.getDateString(end)}</small>`;
    }

    getDateString(date: Date): string {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    startDateChanged(date: Date): void {
        this._startDate = date;
    }

    startDateTimeChanged(date: Date): void {
        this.startDate.setHours(date.getHours(), date.getMinutes(), date.getSeconds());
        this.setAppliedRange();
    }

    endDateChanged(date: Date): void {
        this._endDate = date;
    }

    endDateTimeChanged(date: Date): void {
        this.endDate.setHours(date.getHours(), date.getMinutes(), date.getSeconds());
        this.setAppliedRange();
    }

    applyChanges(): void {
        if (this.isValid()) {
            this.setAppliedRange();
            this.selectionChanged.emit({
                endDate: this.endDate,
                startDate: this.startDate,
            });
            this.closeDropdown();
        }
    }

    closeDropdown(): void {
        if (this.ngbDropdown && this.ngbDropdown.close) {
            this.ngbDropdown.close();
        }
    }

    clearValues(): void {
        this._startDate = null;
        this._endDate = null;
        this.applyChanges();
    }

    contextualDateTypeChange(value: ContextualDateTypesEnum): void {
        if (!value) {
            return;
        }
        const range = getDateRangeByContextualFilter(value, this.firstDayOfTheWeek, this.startDate, this.endDate);
        this._startDate = range.startDate;
        this._endDate = range.endDate;
        this.applyChanges();
        this.contextualDateTypeChanged.emit(value);
    }
}
