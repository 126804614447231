import { TenantService } from '../../tenants/tenant.service';
import { ClientService } from '../client.service';
import { IVwClient } from '../../model/partials/vw-client';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { entityListModuleConfig, TenantEnums } from '../../common/shared.module';
import { IEntitySearchParams, ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { NotificationsService } from '@mt-ng2/notifications-module';
import {
    IItemDeletedEvent,
    IColumnSortedEvent,
    SortDirection,
    ISelectionChangedEvent,
    EntityListExportService,
    EntityListExportConfig,
    EntityListColumn,
} from '@mt-ng2/entity-list-module';
import { ClientsEntityListConfig } from './clients.entity-list-config';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { ITenant } from '../../model/interfaces/tenant';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { ISearchFilterCheckboxValueChangedEvent } from '@mt-ng2/search-filter-checkbox-control';
import { IClient } from '../../model/interfaces/custom/client';
import { iif, Observable, Subscription } from 'rxjs';
import { NotesUpdatedService } from '../../common/components/notes-modal/notes-modal-updated-service';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { ArchiveFilterStatus } from '../../common/constants/ArchiveStatusTypes';
import { IModalWrapperApi } from '@mt-ng2/modal-module';

@Component({
    selector: 'app-client',
    templateUrl: './clients.component.html',
})
export class ClientsComponent implements OnInit, OnDestroy {
    assignModalApi: IModalWrapperApi;
    query: string;
    clients: IVwClient[];
    selectedClients: IVwClient[];
    selectedTenants: ITenant[];
    clientsWithMappingInfo: IClient[];
    tenants: MtSearchFilterItem[] = [];
    total: number;
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    entityListConfig = new ClientsEntityListConfig(this.clientService);
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    archiveFilterIds: number[] = [1];
    tenantNames: number[] = [];
    onlyUnassigned = false;
    onlyInTransit = false;
    hasFullAccess = false;
    mappedClients: IClient[];
    HLTenant: ITenant;
    unmappedClients: IClient[];
    tenantsExcludingHL: ITenant[];
    HLSelected: boolean;
    subscription = new Subscription();
    archiveFilterStatus = ArchiveFilterStatus;
    constructor(
        private notesUpdatedObservable: NotesUpdatedService,
        private exportService: EntityListExportService,
        public claimsService: ClaimsService,
        public clientService: ClientService,
        public tenantService: TenantService,
        public notificationsService: NotificationsService,
    ) {}
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.subscription.add(
            this.notesUpdatedObservable.refreshLogsObservable.subscribe(() => {
                this.getClients();
            }),
        );
        this.getClients();
        this.subscription.add(
            this.clientService.refreshClientsObservable.subscribe((data) => {
                this.getClients();
            }),
        );
        this.tenantService.getAll().subscribe((answer) => {
            this.tenants = answer.filter((t) => t.AccountId).map((t) => new MtSearchFilterItem(t, false));
        });
        this.hasFullAccess = this.claimsService.hasClaim(ClaimTypes.Clients_SeeAll, [ClaimValues.FullAccess]);
        this.entityListConfig.export = new EntityListExportConfig({
            exportName: 'Client List',
            getDataForExport: this.getClientsForExport.bind(this),
        });
    }

    getClients(): void {
        this.getClientsCall().subscribe((response) => {
            this.clients = response.body;
            this.total = +response.headers.get('X-List-Count');
        });
    }

    getClientsCall(options = { forExport: false }): Observable<HttpResponse<IVwClient[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'archiveFilterIds',
                valueArray: this.archiveFilterIds,
            }),
            new ExtraSearchParams({
                name: 'onlyUnassigned',
                value: String(this.onlyUnassigned),
            }),
            new ExtraSearchParams({
                name: 'onlyInTransit',
                value: String(this.onlyInTransit),
            }),
            new ExtraSearchParams({
                name: 'tenantNames',
                valueArray: this.tenantNames,
            }),
        );
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        if (options.forExport) {
            searchEntity.skip = 0;
            searchEntity.take = this.total;
        }
        const searchparams = new SearchParams(searchEntity);
        return this.clientService.get(searchparams);
    }

    includeArchivedClients(event: any): void {
        this.archiveFilterIds = event.selectedItems.map((x) => x.Id);
        this.getClients();
    }

    filterByTenants(event: any): void {
        this.tenantNames = event.selectedItems.map((x) => x.Name);
        this.getClients();
    }

    setOnlyUnassigned(event: ISearchFilterCheckboxValueChangedEvent): void {
        this.onlyUnassigned = event.value;
        this.getClients();
    }

    setOnlyInTransit(event: any): void {
        this.onlyInTransit = event;
        this.getClients();
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getClients();
    }

    toggleArchive(event: IItemDeletedEvent): void {
        const entity = (event.entity as unknown) as IVwClient;
        const archived = !entity.IsArchived;
        entity.IsArchived = archived;
        this.clientService.setArchivedStatus(entity.Id, archived).subscribe(() => {
            this.notificationsService.success(`${archived ? 'Archived' : 'UnArchived'} successfully`);
        });
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getClients();
    }

    getSelectedAccountIds(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => (item.Item as ITenant).AccountId);
    }

    getTenants(filterObj: MtSearchFilterItem[]): ITenant[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item as ITenant);
    }

    checkValidClients(): boolean {
        if (this.selectedTenants) {
            return this.selectedTenants.length === 1 && this.HLSelected && (!this.mappedClients || this.mappedClients.length === 0) ? false : true;
        } else {
            return false;
        }
    }

    showAssignTenantsDialog(): void {
        if (this.selectedClients && this.selectedClients.length) {
            this.selectedTenants = this.getTenants(this.tenants);
            this.HLSelected = this.selectedTenants.findIndex((x) => x.Id === TenantEnums.HL) > -1;

            if (this.selectedTenants.length === 0 || !this.HLSelected) {
                this.assignModalApi.show();
            } else if (this.selectedTenants.length > 0) {
                this.clientService.getClientsMapping(this.selectedClients.map((item) => item.Id)).subscribe((response) => {
                    this.clientsWithMappingInfo = response.body;
                    this.mappedClients = this.clientsWithMappingInfo.filter((item) => item.HasMapping === true);
                    this.unmappedClients = this.clientsWithMappingInfo.filter((item) => item.HasMapping === false);
                    this.tenantsExcludingHL = this.selectedTenants.filter((x) => x.Id !== TenantEnums.HL);
                    this.HLTenant = this.selectedTenants.find((x) => x.Id === TenantEnums.HL);
                    this.assignModalApi.show();
                });
            }
        } else {
            this.notificationsService.error('No Client selected');
        }
    }
    closeSwal(): void {
        this.assignModalApi.close();
    }

    assignTenants(): void {
        this.clientService
            .assignTenantsToClients({
                AccountIds: this.selectedTenants.map((item) => item.AccountId),
                ClientIds: this.selectedClients.map((item) => item.Id),
            })
            .subscribe(() => {
                this.notificationsService.success('Tenants successfully assigned to Clients');
                this.getClients();
                this.assignModalApi.close();
            });
    }

    onSelectionChanged(event: ISelectionChangedEvent): void {
        if (event.selectedEntities.length > 0) {
            this.selectedClients = event.selectedEntities.map((item) => item as IVwClient);
        } else {
            this.selectedClients = [];
        }
    }

    export(): void {
        this.getClientsForExport().subscribe((data) => {
            const exportColumns = [
                new EntityListColumn({
                    accessorFunction: (client: IVwClient) => client.Id.toString(),
                    name: 'Client Id',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        isDefaultForSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (client: IVwClient) => client.Name,
                    name: 'Client Name',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                    },
                }),
            ];
            this.entityListConfig.columns = exportColumns;
            this.exportService.export(data, this.entityListConfig);
        });
    }

    getClientsForExport(): Observable<IVwClient[]> {
        return this.getClientsCall({ forExport: true }).pipe(map((answer) => answer.body));
    }
}
