/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { OnInit, Component, OnDestroy, Injector } from '@angular/core';
import { ParamMap } from '@angular/router';
import { LoginOldStyleComponent } from '@mt-ng2/login-module';
import { IUserDetails, MtAuthGuard } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ADLoginService } from '../../services/ad-login.service';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '../../services/local-storage-service';
import { LocalStorageKeys } from '../../constants/Enum';

export interface ICustomUserDetails extends IUserDetails {
    TenantId: number;
    UserRoleId: number;
}

@Component({
    templateUrl: 'ad-login.component.html',
})
export class ADLoginComponent extends LoginOldStyleComponent implements OnInit, OnDestroy {
    returnUrl: string;
    loginPath: string;
    subscriptions: Subscription = new Subscription();
    constructor(private adLoginService: ADLoginService, private localStorageService: LocalStorageService, injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.localStorageService.clear();
        this.returnUrl = this.activatedRoute.snapshot.queryParams[MtAuthGuard.Return_Url_QueryParam];
        this.subscriptions.add(
            this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
                this.loginPath = params.get('adPath');
                this.validateADPath();
            }),
        );
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subscriptions.unsubscribe();
    }

    validateADPath(): void {
        this.adLoginService.validatePath(this.loginPath).subscribe((valid: boolean) => {
            if (!valid) {
                void this.router.navigate(['/login']);
            }
        });
    }

    onLogin(): void {
        if (this.loginForm.valid) {
            const values = this.loginForm.value;
            this.adLoginService.adLogin(values.username, values.password, values.rememberMe, this.loginPath).subscribe(
                ((successResponse) => {
                    if (this.returnUrl) {
                        void this.router.navigateByUrl(this.returnUrl);
                    } else {
                        void this.router.navigate(['/home']);
                    }
                }).bind(this),
                (errorResponse) => {
                    if (errorResponse.status === 418) {
                        if (errorResponse.error === 'DomainLoginEmailSent') {
                            this.notificationsService.success('A login link has been emailed to you');
                        } else {
                            this.notificationsService.error('Username/Password is not correct');
                        }
                    } else {
                        this.notificationsService.error('Email account already exists');
                    }
                },
            );
        } else {
            markAllFormFieldsAsTouched(this.loginForm);
        }
    }

    showUsernameRequiredError(): boolean {
        return this.loginForm.get('username').hasError('required') && this.loginForm.get('username').touched;
    }

    showPasswordRequiredError(): boolean {
        return this.loginForm.get('password').hasError('required') && this.loginForm.get('password').touched;
    }
}
