import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { UserRoleService } from '../user-role.service';
import { IUserRole } from '../../model/interfaces/user-role';
import { UserRoleDynamicConfig } from '../user-role.dynamic-config';

@Component({
    selector: 'app-user-role-basic-info',
    templateUrl: './user-role-basic-info.component.html',
})
export class UserRoleBasicInfoComponent implements OnInit {
    @Input() userRole: IUserRole;
    @Input() canEdit: boolean;
    roles: IUserRole[];
    isEditing: boolean;
    isHovered: boolean;
    config: any = {};
    userRoleForm: UntypedFormGroup;
    formFactory: UserRoleDynamicConfig<IUserRole>;
    doubleClickIsDisabled = false;

    constructor(private userRoleService: UserRoleService, private notificationsService: NotificationsService, private router: Router) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.config = { formObject: [], viewOnly: [] };
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new UserRoleDynamicConfig<IUserRole>(this.userRole);
        if (this.userRole.Id === 0) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.userRole.Id === 0) {
            void this.router.navigate(['/roles']);
        } else {
            this.isEditing = false;
        }
    }
    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.userRole, form.value.UserRole as IUserRole);
            this.userRoleService
                .saveUserRole(this.userRole)
                .subscribe((answer) => {
                    this.success();
                    this.userRoleService.emitChange(this.userRole);
                    if (this.userRole.Id === 0) {
                        void this.router.navigate([`/roles/${answer.Id}`]);
                    } else {
                        this.isEditing = false;
                        this.setConfig();
                    }
                });
        } else {
            common.markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('User Role saved successfully.');
    }
}
