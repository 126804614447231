import { TenantListComponent } from './task-list/tenant-list-component';
import { NgModule } from '@angular/core';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskRoutingModule } from './task-routing.module';
import { SharedModule } from '../common/shared.module';
import { ClientListComponent } from './task-list/client-list-component';
import { SupportingDocumentsListComponent } from './task-list/supporting-document-list-component';
import { TagOnTaskListComponent } from './task-list/tag-on-task-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { TaskHeaderComponent } from './task-header/task-header.component';
import { TaskBasicInfoComponent } from './task-basic-info/task-basic-info.component';
import { TaskDocumentComponent } from './task-document/task-document.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DocumentNameComponent } from './task-list/document.component';
import { RowExpanderComponent } from './task-list/row-expander.component';
import { RowSelectorComponent } from './task-list/row-selector.component';
import { ModalModule } from '@mt-ng2/modal-module';
@NgModule({
    declarations: [
        TaskListComponent,
        ClientListComponent,
        SupportingDocumentsListComponent,
        TagOnTaskListComponent,
        TenantListComponent,
        TaskDetailComponent,
        TaskHeaderComponent,
        TaskBasicInfoComponent,
        TaskDocumentComponent,
        DocumentNameComponent,
        RowExpanderComponent,
        RowSelectorComponent,
    ],
    imports: [SharedModule, TaskRoutingModule, NgSelectModule, DragDropModule, ModalModule]
})
export class TaskModule {}
