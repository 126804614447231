import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SharedModule } from '../../common/shared.module';

import { IAttachmentWithMetadata } from '../attachment-meta-data.service';

@Injectable({
    providedIn: SharedModule,
})
export class ProcessAttachmentUpdateService {
    addAttachmentEvent = new Subject<IAttachmentWithMetadata>();

    refreshAttachmentObservable = this.addAttachmentEvent.asObservable();

    addAttachment(newAttachment: IAttachmentWithMetadata): void {
        this.addAttachmentEvent.next(newAttachment);
    }
}
