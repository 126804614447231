import { Component, Input, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { MessageTagService } from '../services/message-tag.service';
import { MessageTagDynamicConfig } from '../message-tag.dynamic-config';

import { IMessageTag } from '../../model/interfaces/message-tag';
import { DynamicField, DynamicFieldType, IDynamicFieldType, IDynamicField, InputTypes, DynamicFieldTypes, DynamicLabel } from '@mt-ng2/dynamic-form';
import { IMessageTagCriteriaContain } from '../../model/interfaces/message-tag-criteria-contain';
import { IMessageTagCriteriaFrom } from '../../model/interfaces/message-tag-criteria-from';
import { IDboMessageTagCriteriaTo } from '../../model/interfaces/dbo-message-tag-criteria-to';

import { IMessageTagForward } from '../../model/interfaces/message-tag-forward';
import { MessageTagForwardService } from '../services/message-tag-forward.service';
import { MessageTagCriteriaFromService } from '../services/message-tag-criteria-from.service';
import { MessageTagContainsService } from '../services/message-tag-contain.service';
import { IDboMessageManagedNote } from '../../model/interfaces/dbo-message-managed-note';
import { MessageManagedNotesService } from '../../message-managed-notes/message-managed-notes.service';
import { MessageAutomationStatuService } from '../../messages/services/message-automation-statu.service';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IMessageAutomationStatu } from '../../model/interfaces/message-automation-statu';
import { IDboMessageTagsAutomationStatu } from '../../model/interfaces/dbo-message-tags-automation-statu';
import { MessageTagCriteriaToService } from '../services/message-tag-criteria-to.service';

@Component({
    selector: 'app-message-tag-basic-info',
    styles: [
        `
            .container {
                margin-right: 5%;
                width: 100%;
            }

            .ruleContainer {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                width: 100%;
            }
            .nameAndColor {
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
            }
            .name {
                margin-right: 200px;
            }

            .form-control textarea {
                max-width: 100%;
            }

            .inactiveBtn {
                background-color: white;
                color: black;
            }
        `,
    ],
    templateUrl: './message-tag-basic-info.component.html',
})
export class MessageTagBasicInfoComponent implements OnInit {
    @ViewChild('emailInput') emailFromField: ElementRef;
    @ViewChild('emailInputTo') emailToField: ElementRef;

    @ViewChild('emailForwardInput') emailForwardField: ElementRef;
    get isNewMessageTag(): boolean {
        return this.messageTag && this.messageTag.Id ? false : true;
    }
    @Input() messageTag: IMessageTag;
    @Input() canEdit: boolean;

    form: UntypedFormGroup;

    isEditing = false;
    isHovered: boolean;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: MessageTagDynamicConfig<IMessageTag>;
    doubleClickIsDisabled = false;

    requiresNote = false;
    forwardTo = false;
    containsKeyWords = false;

    emailFromInput = '';
    emailFrom = false;

    emailToInput = '';
    emailTo = false;

    emailDomainToggle = false;
    emailToDomainToggle = false;
    emailFromDomainToggle = false;

    emailForwardsInput = '';
    keywordContainsInput = '';
    isSubjectContains = true;
    isBodyContains = true;

    inputColor: DynamicField;
    name: DynamicField;
    inputKeyWords: DynamicField;
    note: DynamicField;
    notelabel: DynamicLabel;

    managedNotes: IDboMessageManagedNote[] = [];
    selectedNote: IDboMessageManagedNote = null;
    customNote = '';

    selectMenuAndOr = [
        { Name: 'AND', Value: true },
        { Name: 'OR', Value: false },
    ];

    autoTagError = false;

    automationStatusOptions: MtSearchFilterItem[] = [];

    constructor(
        private messageTagService: MessageTagService,
        private notificationsService: NotificationsService,
        private router: Router,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private tagFromService: MessageTagCriteriaFromService,
        private tagToService: MessageTagCriteriaToService,
        private tagForwardService: MessageTagForwardService,
        private tagContainsService: MessageTagContainsService,
        private managedNoteService: MessageManagedNotesService,
        private automationStatusService: MessageAutomationStatuService,
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            messageTagForm: this.fb.group({}),
        });

        if (!this.messageTag.MessageTagCriteriaContains) {
            this.messageTag.MessageTagCriteriaContains = [];
        }

        if (!this.messageTag.MessageTagForwards) {
            this.messageTag.MessageTagForwards = [];
        }

        this.automationStatusService.getSearchFilterItems().subscribe((data) => {
            if (!this.messageTag.DboMessageTagsAutomationStatus) {
                this.messageTag.DboMessageTagsAutomationStatus = [];
            }
            this.automationStatusOptions = data;
            this.automationStatusOptions.forEach((value) => {
                if (this.messageTag.DboMessageTagsAutomationStatus.some((x) => x.AutomationStatusId === value.Item.Id)) {
                    value.Selected = true;
                }
            });
        });

        this.managedNoteService.getAll().subscribe((data) => {
            this.managedNotes = data;
            this.setSelectedNote();
        });

        if (!this.messageTag.MessageTagCriteriaFroms) {
            this.messageTag.MessageTagCriteriaFroms = [];
        }

        if (!this.messageTag.DboMessageTagCriteriaToes) {
            this.messageTag.DboMessageTagCriteriaToes = [];
        }

        this.containsKeyWords = this.messageTag.MessageTagCriteriaContains.length ? true : false;
        if (this.containsKeyWords) {
            this.updateNullValuesOnCriteriaContains();
        }
        this.emailFrom = this.messageTag.MessageTagCriteriaFroms && this.messageTag.MessageTagCriteriaFroms.length ? true : false;
        this.emailTo = this.messageTag.DboMessageTagCriteriaToes && this.messageTag.DboMessageTagCriteriaToes.length ? true : false;
        this.forwardTo = this.messageTag.MessageTagForwards && this.messageTag.MessageTagForwards.length ? true : false;
        this.customNote = this.messageTag.MessageNote ? this.messageTag.MessageNote : '';

        this.setConfig();
    }

    updateNullValuesOnCriteriaContains(): void {
        this.messageTag.MessageTagCriteriaContains.forEach((cc) => {
            if (cc.IsBodyContains == null) {
                cc.IsBodyContains = true;
            }
            if (cc.IsSubjectContains == null) {
                cc.IsSubjectContains = true;
            }
        });
    }

    setConfig(): void {
        this.formFactory = new MessageTagDynamicConfig<IMessageTag>(this.messageTag);

        this.addAdditionalFields();
        this.addAdditionalLabels();

        if (this.isNewMessageTag) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
            this.addAdditionalLabels();
            this.setSelectedNote();
        }
    }

    setSelectedNote(): void {
        if (this.messageTag.ManagedNoteId) {
            this.selectedNote = this.managedNotes.find((e) => e.Id === this.messageTag.ManagedNoteId);
            this.messageTag.MessageNote = null;
        } else if (this.messageTag.MessageNote) {
            this.form.controls.messageTagForm.patchValue({ Note: this.messageTag.MessageNote });
            this.selectedNote = null;
        } else {
            this.selectedNote = null;
            this.messageTag.MessageNote = null;
            this.form.controls.messageTagForm.patchValue({ Note: '' });
        }
    }

    addAdditionalFields(): void {
        this.name = new DynamicField({
            formGroup: 'messageTagForm',
            label: 'Tag Name',
            name: 'Name',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            } as IDynamicFieldType),
            value: this.messageTag.Name,
        } as IDynamicField);
        this.name.setMaxLength(50);
        this.name.setRequired(true);

        this.inputColor = new DynamicField({
            formGroup: 'messageTagForm',
            label: 'Color',
            name: 'Color',
            type: new DynamicFieldType({
                colorpickerOptions: {
                    cpDisableInput: true,
                    cpOKButton: true,
                    cpOutputFormat: 'hex',
                    position: 'bottom',
                    positionOffsetPercentage: 10,
                },
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Colorpicker,
            } as IDynamicFieldType),
            value: this.messageTag.Color,
        } as IDynamicField);
        this.inputColor.setMaxLength(7);
        this.inputColor.setRequired(true);
        this.inputColor.setPattern(new RegExp('^#(?:[0-9a-fA-F]{3}){1,2}$'));
        this.inputColor.failedPatternMessage = 'Color must be hex code.';

        this.note = new DynamicField({
            formGroup: 'messageTagForm',
            label: 'Note',
            name: 'Note',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textarea,
            } as IDynamicFieldType),
            value: this.messageTag.MessageNote ? this.messageTag.MessageNote : '',
        } as IDynamicField);
        this.note.setMaxLength(250);
    }

    addAdditionalLabels(): void {
        this.config.viewOnly.push(
            new DynamicLabel({
			    label: 'Contains Keywords',
			    value: this.messageTag && this.messageTag.MessageTagCriteriaContains
			        ? this.messageTag.MessageTagCriteriaContains.map((e) => e.Content).join(', ')
			        : 'None',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textbox,
			        scale: null,
			    })
			}
            ),
        );

        this.config.viewOnly.push(
            new DynamicLabel({
			    label: 'Autotag From',
			    value: this.messageTag && this.messageTag.MessageTagCriteriaFroms
			        ? this.messageTag.MessageTagCriteriaFroms.map((e) => (e.Email ? e.Email : e.Domain)).join(', ')
			        : 'None',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textbox,
			        scale: null,
			    })
			}
            ),
        );

        this.config.viewOnly.push(
            new DynamicLabel({
			    label: 'Autotag To',
			    value: this.messageTag && this.messageTag.DboMessageTagCriteriaToes
			        ? this.messageTag.DboMessageTagCriteriaToes.map((e) => (e.Email ? e.Email : e.Domain)).join(', ')
			        : 'None',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textbox,
			        scale: null,
			    })
			}
            ),
        );

        this.config.viewOnly.push(
            new DynamicLabel({
			    label: 'Forward To',
			    value: this.messageTag && this.messageTag.MessageTagForwards ? this.messageTag.MessageTagForwards.map((e) => e.Email).join(', ') : 'None',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textbox,
			        scale: null,
			    })
			}
            ),
        );

        this.notelabel = new DynamicLabel({
			    label: 'Note',
			    value: this.messageTag && this.messageTag.DboMessageManagedNote ? this.messageTag.DboMessageManagedNote.Note : this.messageTag.MessageNote,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textbox,
			        scale: null,
			    })
			}
        );
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewMessageTag) {
            void this.router.navigate(['/tags']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        this.messageTag.Name = this.form.controls.messageTagForm.value.Name;
        this.messageTag.ManagedNoteId = this.messageTag.RequiresNote && this.selectedNote ? this.selectedNote.Id : null;
        this.messageTag.DboMessageManagedNote = this.messageTag.RequiresNote && this.selectedNote ? this.selectedNote : null;
        this.messageTag.MessageNote =
            this.messageTag.RequiresNote && this.form.controls.messageTagForm.value.Note && this.form.controls.messageTagForm.value.Note.length
                ? this.form.controls.messageTagForm.value.Note
                : null;
        this.checkIfEmailContains();
        this.checkIfEmailForward();
        this.checkIfEmailFrom();
        this.checkIfEmailTo();
        if (form.valid && this.checkAutoTagRules() && this.checkValidCriteriaContains()) {
            this.formFactory.assignFormValues(this.messageTag, form.value.MessageTag as IMessageTag);
            if (this.notelabel) {
                this.notelabel.value = this.setNoteLabelValue();
            }
            if (this.messageTag?.AutoApplyOnShoreGroupPattern === null) {
                this.messageTag.AutoApplyOnShoreGroupPattern = false;
            }
            if (this.messageTag?.AutoApplyOnMessageHaveThreadId === null) {
                this.messageTag.AutoApplyOnMessageHaveThreadId = false;
            }
            this.saveMessageTag();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    checkValidCriteriaContains(): boolean {
        if (this.messageTag.MessageTagCriteriaContains.some((cc) => !cc.IsBodyContains && !cc.IsSubjectContains)) {
            this.notificationsService.error('Confirm all key words check subject, body, or both.');
            return false;
        } else {
            return true;
        }
    }

    checkAutoTagRules(): boolean {
        let hasRules = false;
        if (this.messageTag.AutoApply) {
            (this.containsKeyWords && this.messageTag.MessageTagCriteriaContains.length) ||
            (this.emailFrom && this.messageTag.MessageTagCriteriaFroms.length) ||
            (this.emailTo && this.messageTag.DboMessageTagCriteriaToes.length) ||
            this.messageTag.AutoApplyOnShoreGroupPattern === true ||
            this.messageTag.AutoApplyOnMessageHaveThreadId === true
                ? (hasRules = true)
                : (hasRules = false);
        } else {
            hasRules = true;
        }
        if (!hasRules) {
            this.notificationsService.error('Confirm at least one criteria box is checked for autotag.');
        }
        this.autoTagError = !hasRules;
        return hasRules;
    }

    setNoteLabelValue(): string {
        {
            if (this.messageTag) {
                if (this.messageTag.MessageNote) {
                    return this.messageTag.MessageNote;
                    // eslint-disable-next-line no-dupe-else-if
                } else if (this.messageTag.MessageNote) {
                    return this.messageTag.DboMessageManagedNote.Note;
                } else {
                    return '';
                }
            } else {
                return '';
            }
        }
    }

    checkIfEmailContains(): void {
        if (!this.containsKeyWords) {
            this.tagContainsService.deleteAllByTagId(this.messageTag.Id).subscribe(() => {
                this.messageTag.MessageTagCriteriaContains = [];
            });
        }
    }

    checkIfEmailFrom(): void {
        if (!this.emailFrom) {
            this.tagFromService.deleteAllByTagId(this.messageTag.Id).subscribe(() => {
                this.messageTag.MessageTagCriteriaFroms = [];
            });
        }
    }

    checkIfEmailTo(): void {
        if (!this.emailTo) {
            this.tagToService.deleteAllByTagId(this.messageTag.Id).subscribe(() => {
                this.messageTag.DboMessageTagCriteriaToes = [];
            });
        }
    }

    checkIfEmailForward(): void {
        if (!this.forwardTo) {
            this.tagForwardService.deleteAllByTagId(this.messageTag.Id).subscribe(() => {
                this.messageTag.MessageTagForwards = [];
            });
        }
    }

    toggleEmailDomain(value: boolean, emailType: string): void {
        if (emailType === 'to') {
            this.emailToDomainToggle = value;
        } else if (emailType === 'from') {
            this.emailFromDomainToggle = value;
        }
    }

    addFromEmailOrDomain(event: any): void {
        this.autoTagError = false;

        const newCriteriaFrom: IMessageTagCriteriaFrom = {
            Domain: null,
            Email: null,
            Id: 0,
            MessageTagId: this.messageTag.Id,
        };

        if (!this.emailFromDomainToggle) {
            newCriteriaFrom.Email = this.emailFromInput;
        } else {
            newCriteriaFrom.Domain = this.emailFromInput;
        }

        if (this.emailFrom && this.emailFromInput.length) {
            if (this.emailFieldsValid(!this.emailFromDomainToggle, this.emailFromInput)) {
                this.messageTag.MessageTagCriteriaFroms.push(newCriteriaFrom);
                this.emailFromInput = '';
            } else {
                this.emailFromField.nativeElement.focus();
            }
        }
    }

    addToEmailOrDomain(event: any): void {
        this.autoTagError = false;

        const newCriteriaTo: IDboMessageTagCriteriaTo = {
            Domain: null,
            Email: null,
            Id: 0,
            MessageTagId: this.messageTag.Id,
        };

        if (!this.emailToDomainToggle) {
            newCriteriaTo.Email = this.emailToInput;
        } else {
            newCriteriaTo.Domain = this.emailToInput;
        }

        if (this.emailTo && this.emailToInput.length) {
            if (this.emailFieldsValid(!this.emailToDomainToggle, this.emailToInput)) {
                this.messageTag.DboMessageTagCriteriaToes.push(newCriteriaTo);
                this.emailToInput = '';
            } else {
                this.emailToField.nativeElement.focus();
            }
        }
    }

    emailFieldsValid(isEmail: boolean, input: string): boolean {
        let isValid = true;
        if (!input.length) {
            isValid = false;
            this.notificationsService.error('Email must have length');
        } else if (isEmail) {
            const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!emailRegex.test(input)) {
                isValid = false;
                this.notificationsService.error(`${input} does not match email format`);
            }
        } else {
            const domainRegex = /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const isDomain = domainRegex.test(input);
            if (!isDomain) {
                isValid = false;
                this.notificationsService.error(`${input} does not match domain format`);
            }
        }

        return isValid;
    }

    containsValid(input: string): boolean {
        let isValid = true;
        if (input.length <= 0) {
            isValid = false;
            this.notificationsService.error('Keywords must have length');
        }
        if (input.length > 250) {
            isValid = false;
            this.notificationsService.error('Keywords cannot be longer than 250 characters');
        }
        return isValid;
    }

    changeAndOr(event: any): void {
        this.messageTag.RequireAllCriteriaGroups = event.target.value;
    }

    addContainWord(event: any): void {
        this.autoTagError = false;
        const newEmailContains: IMessageTagCriteriaContain = {
            Content: this.keywordContainsInput,
            Id: 0,
            IsBodyContains: this.isBodyContains,
            IsSubjectContains: this.isSubjectContains,
            MessageTagId: this.messageTag.Id,
        };
        if (this.containsValid(this.keywordContainsInput)) {
            this.messageTag.MessageTagCriteriaContains.push(newEmailContains);
            this.keywordContainsInput = '';
            this.isBodyContains = true;
            this.isSubjectContains = true;
        }
    }

    removeContainWord(contains: IMessageTagCriteriaContain, index: number): void {
        if (this.isNewMessageTag || contains.Id === 0) {
            this.messageTag.MessageTagCriteriaContains.splice(index, 1);
        } else {
            this.tagContainsService.delete(contains.Id).subscribe(() => {
                this.messageTag.MessageTagCriteriaContains.splice(index, 1);
            });
        }
    }
    addEmailForward(event: any): void {
        const newEmailForward: IMessageTagForward = {
            Email: this.emailForwardsInput,
            Id: 0,
            MessageTagId: this.messageTag.Id,
        };
        if (this.emailFieldsValid(true, this.emailForwardsInput)) {
            this.messageTag.MessageTagForwards.push(newEmailForward);
            this.emailForwardsInput = '';
        } else {
            this.emailForwardField.nativeElement.focus();
        }
    }

    removeEmailFrom(from: IMessageTagCriteriaFrom, index: number): void {
        if (this.isNewMessageTag || from.Id === 0) {
            this.messageTag.MessageTagCriteriaFroms.splice(index, 1);
        } else {
            this.tagFromService.delete(from.Id).subscribe(() => {
                this.messageTag.MessageTagCriteriaFroms.splice(index, 1);
            });
        }
    }

    removeToEmail(toEmail: IDboMessageTagCriteriaTo, index: number): void {
        if (this.isNewMessageTag || toEmail.Id === 0) {
            this.messageTag.DboMessageTagCriteriaToes.splice(index, 1);
        } else {
            this.tagToService.delete(toEmail.Id).subscribe(() => {
                this.messageTag.DboMessageTagCriteriaToes.splice(index, 1);
            });
        }
    }

    removeEmailForward(forward: IMessageTagForward, index: number): void {
        if (this.isNewMessageTag || forward.Id === 0) {
            this.messageTag.MessageTagForwards.splice(index, 1);
        } else {
            this.tagForwardService.delete(forward.Id).subscribe(() => {
                this.messageTag.MessageTagForwards.splice(index, 1);
            });
        }
    }

    selectedNoteChanged(event: IDboMessageManagedNote): void {
        if (event) {
            this.selectedNote = event;
            this.messageTag.MessageNote = null;
            this.notelabel.value = event.Note;
        } else {
            this.selectedNote = null;
            this.form.controls.messageTagForm.patchValue({ Note: '' });
        }
    }

    changeColor(event: any): void {
        this.messageTag.Color = event;
    }

    wasAutomationStatusChanged(event: any): void {
        this.messageTag.DboMessageTagsAutomationStatus = event.selectedItems.map(
            (x: IMessageAutomationStatu): IDboMessageTagsAutomationStatu => ({ Id: 0, AutomationStatusId: x.Id, MessageTagId: 0 }),
        );
    }

    private saveMessageTag(): void {
        if (this.isNewMessageTag) {
            this.messageTagService
                .createUpdate(this.messageTag)                .subscribe((answer) => {
                    this.messageTag.Id = answer;
                    this.success(true);
                });
        } else {
            this.messageTagService
                .createUpdate(this.messageTag)                .subscribe((success) => {
                    this.messageTagService.getById(success).subscribe((data) => {
                        this.messageTag = data;
                        this.success();
                    });
                });
        }
    }

    private success(newMessageTagSave?: boolean): void {
        if (newMessageTagSave) {
            void this.router.navigate([`/tags/${this.messageTag.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.messageTagService.emitChange(this.messageTag);
        this.notificationsService.success('Tag saved successfully.');
    }
}
