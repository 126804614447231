import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { ILoggedIn, AuthService } from '@mt-ng2/auth-module';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TenantService } from '../tenant.service';
import { ITenant } from '../../model/interfaces/tenant';
import { UserService } from '../../users/user.service';
import { ClaimTypes } from '../../model/ClaimTypes';

@Component({
    selector: 'app-tenant-detail',
    templateUrl: './tenant-detail.component.html',
})
export class TenantDetailComponent {
    tenant: ITenant;
    returnLink: string;
    editAccess = false;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private tenantService: TenantService,
        private userService: UserService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // check if my-tenant path
        if (this.route.snapshot.routeConfig.path === 'my-tenant') {
            this.userService.getTenantForUser(this.authService.currentUser.getValue().Id).subscribe((tenant: ITenant) => (this.tenant = tenant));
            this.returnLink = '/home';
        } else {
            const claimTypes = this.authService.currentUser.getValue().ClaimValues;
            if (claimTypes[ClaimTypes.Tenants_SeeAll] === undefined) {
                void this.router.navigate(['/home']);
            }
            this.subscriptions.add(
                this.route.paramMap
                    .pipe(
                        switchMap((params: ParamMap) => {
                            return this.tenantService.getById(Number(params.get('tenantId')));
                        }),
                    )
                    .subscribe((tenant: ITenant) => {
                        this.tenant = tenant;
                        this.editAccess = !!this.tenant.AccountId || this.tenant.AccountId === 0;
                    }),
            );
            this.returnLink = '/tenants';
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
