import { Component, OnInit, OnDestroy } from '@angular/core';
import { saveAs } from 'file-saver';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { DownloadTypes } from '../../../model/DownloadTypes';
import { TaskService } from '../../../tasks/task.service';
import { TaskDocumentsService } from '../../../tasks/task-document-service';
import { ITaskSearchObject } from '../../../model/partials/task-search';
import { IDownloadObject } from '../../../model/interfaces/custom/download-object';
import { CommonService } from '../../services/common.service';

@Component({
    selector: 'app-download',
    styleUrls: ['download.less'],
    templateUrl: './download.component.html',
})
export class DownloadComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription = new Subscription();
    constructor(
        private taskService: TaskService,
        private docService: TaskDocumentsService,
        private activatedRoute: ActivatedRoute,
        private commonService: CommonService,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.activatedRoute.queryParams.subscribe((params: Params) => {
                this.download(this.commonService.parseDownloadRoute(params));
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    download(downloadObject: IDownloadObject): void {
        switch (downloadObject.downloadType) {
            case DownloadTypes.Tasks:
                this.taskService.getTaskExport(downloadObject.data as ITaskSearchObject).subscribe((response) => {
                    saveAs(response.blob, response.fileName);
                });
                break;
            case DownloadTypes.Attachment:
                this.taskService.downloadTaskAttachment(downloadObject.data as number).subscribe((response) => {
                    saveAs(response.blob, response.fileName);
                });
                break;
            case DownloadTypes.SupportingDocument: {
                const [taskId, docId] = downloadObject.data as [number, number];
                this.docService.getDocument(taskId, docId).subscribe((response) => {
                    saveAs(response.blob, response.fileName);
                });
                break;
            }
            default:
                break;
        }
    }
}
