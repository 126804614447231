import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { Observable, Subject } from 'rxjs';

import { IReplyMessage } from '../../model/interfaces/reply-message';

export const emptyReplyMessage: IReplyMessage = {
    Id: 0,
    IsHtml: null,
    ParentMessageId: 0,
    ReplyTypeId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class ReplyMessageService extends BaseService<IReplyMessage> {
    updateReplyTypeEvent = new Subject<number>();
    updateReplyObservable = this.updateReplyTypeEvent.asObservable();

    constructor(public http: HttpClient) {
        super('/replymessage', http);
    }

    getEmptyReplyMessage(): IReplyMessage {
        return { ...emptyReplyMessage };
    }

    announceReplyTypeUpdate(typeId: number): void {
        this.updateReplyTypeEvent.next(typeId);
    }
}
