<mt-modal-wrapper [options]="modalOptions" (cancelClick)="cancel()">
    <div class="fullModal">
        <h2 id="swal2-title" class="swal2-title"><h2>Client Alternative Names</h2></h2>
        <hr />
        <div class="scrollModal">
            <div class="table-responsive">
                <table class="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Alternative Name</th>
                            <th>Associated Fund</th>
                        </tr>
                    </thead>

                    <tbody *ngIf="altNames && altNames.length">
                        <tr *ngFor="let name of altNames" style="text-align: left;">
                            <td>{{name.ClientName}}</td>
                            <td>{{name.FundName}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
    </div>
</mt-modal-wrapper>
