import { IEntity } from '../../model/interfaces/base';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { Component } from '@angular/core';
import { IDboTaskDTO } from '../../model/interfaces/custom/IDboTaskDTO';
import { TaskService } from '../task.service';

@Component({
    styles: [
        `
            ol {
                padding-inline-start: 15px;
            }
        `,
    ],
    template: `
        <div (click)="suppress($event)" class="wrapword">
            <a [href]="href" target="_blank">{{ name }}</a>
        </div>
    `,
})
export class DocumentNameComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        const dboTaskDto = value as IDboTaskDTO;
        this.name = dboTaskDto.AttachmentName;
        this.href = this.taskSvc.getTaskAttachmentPath(dboTaskDto.Id, true);
    }

    name: string;
    href: string;
    constructor(private taskSvc: TaskService) {}

    suppress(event: Event): void {
        event.stopPropagation();
    }
}
