import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { MessageTagService } from '../services/message-tag.service';
import { IMessageTag } from '../../model/interfaces/message-tag';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { MessageTagsEntityListConfig } from './message-tags.entity-list-config';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-message-tags',
    templateUrl: './message-tags.component.html',
})
export class MessageTagsComponent implements OnInit {
    messageTags: IMessageTag[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new MessageTagsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    includeArchive = false;

    canAddMessageTag = false;

    constructor(
        private messageTagService: MessageTagService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.canAddMessageTag = this.claimsService.hasClaim(ClaimTypes.Tags, [ClaimValues.FullAccess]);
        this.getMessageTags();
    }

    getMessageTagsCall(): Observable<HttpResponse<IMessageTag[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: String(this.includeArchive),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.messageTagService.get(searchparams);
    }

    getMessageTags(): void {
        this.getMessageTagsCall().subscribe((answer) => {
            this.messageTags = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getMessageTags();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getMessageTags();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getMessageTags();
    }

    messageTagSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/tags', event.entity.Id]);
    }

    toggleArchive(event: any): void {
        const entity = event.entity as IMessageTag;
        const archived = !entity.IsDeleted;
        entity.IsDeleted = archived;
        this.messageTagService.toggleArchive(entity.Id, archived).subscribe(() => {
            this.getMessageTags();
            this.notificationsService.success(`${archived ? 'Archived' : 'UnArchived'} successfully`);
        });
    }

    includeArchivedChanged(event: boolean): void {
        this.includeArchive = event;
        this.getMessageTags();
    }
}
