import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef, EventEmitter } from '@angular/core';
import { SharedModule } from '../../common/shared.module';
import { take } from 'rxjs/operators';
import { SaveSearchModalComponent } from './saveSearchModal';
import { DboMessageDocumentService } from '../services/dbo-message-document-service';
import { TaskDocumentsService } from '../../tasks/task-document-service';
import { SaveSearchUpdateService } from './saveSearchUpdateService';
import { IMessageSearchDTO } from '../../model/interfaces/custom/IMessageSearchDTO';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Injectable({
    providedIn: SharedModule,
})
export class SaveSearchModalService {
    private _componentRef: ComponentRef<SaveSearchModalComponent>;

    saved: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        private modalService: SaveSearchUpdateService,
        private notificationService: NotificationsService,
    ) {}

    showModal(messageSearch: IMessageSearchDTO, allMessageSearches: IMessageSearchDTO[], orgFilterAccess = false): void {
        this.destroyComponent();
        this._componentRef = this.componentFactoryResolver.resolveComponentFactory(SaveSearchModalComponent).create(this.injector);
        this._componentRef.instance.entity = messageSearch;
        this._componentRef.instance.modalService = this.modalService;
        this._componentRef.instance.allMessageSearches = allMessageSearches;
        this._componentRef.instance.notificationService = this.notificationService;
        this._componentRef.instance.orgFilterAccess = orgFilterAccess;
        this._componentRef.instance.onSave.pipe(take(1)).subscribe(() => this.saved.emit());
        this._componentRef.instance.onCancel.pipe(take(1)).subscribe(() => {
            this.destroyComponent();
        });

        this.appRef.attachView(this._componentRef.hostView);
        const domElem = (this._componentRef.hostView as EmbeddedViewRef<SaveSearchModalComponent>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
    }

    private destroyComponent(): void {
        if (this._componentRef) {
            this.appRef.detachView(this._componentRef.hostView);
            this._componentRef.destroy();
            this._componentRef = null;
        }
    }
}
