// tslint:disable:member-ordering
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { forkJoin, Subject, Observable, Subscription } from 'rxjs';
import { IDboMessageManagedNote } from '../model/interfaces/dbo-message-managed-note';
import { DboMessageManagedNoteDynamicControls } from '../model/form-controls/dbo-message-managed-note.form-controls';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageManagedNotesService } from './message-managed-notes.service';
import { map } from 'rxjs/operators';
import { TaskService } from '../tasks/task.service';
import { entityListModuleConfig } from '../common/shared.module';
import { MessageNotesEntityListConfig } from './message-notes-entity-list-config';
import { IItemDeletedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';

@Component({
    selector: 'app-managed-notes-component',
    styles: [
        `
            button {
                float: right;
            }
        `,
    ],
    templateUrl: './message-managed-notes-list.component.html',
})
export class MessageManagedNotesComponent implements OnInit, OnDestroy {
    dynamicForm = new DboMessageManagedNoteDynamicControls().Form;
    items: IDboMessageManagedNote[] = [];
    formValue: string;

    currentPage = 1;
    itemsPerPage = 10;
    query = '';
    total: number;
    entityListConfig = new MessageNotesEntityListConfig();

    managedNotes$: Observable<IDboMessageManagedNote[]>;
    managedNotes: IDboMessageManagedNote[];
    public managedNoteSubject$ = new Subject<string>();
    managedNotesList: IDboMessageManagedNote[] = [];
    selectedNotes: IDboMessageManagedNote[] = [];

    noteAddDeleteSubscription = new Subscription();

    removeBlankNoteSubscription = new Subscription();

    includeArchived = false;

    constructor(
        public managedNoteService: MessageManagedNotesService,
        private noticiationService: NotificationsService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}
    ngOnDestroy(): void {
        this.noteAddDeleteSubscription.unsubscribe();
    }
    ngOnInit(): void {
        this.getNotes();

        this.managedNotes$ = this.managedNoteSubject$.pipe(map((term) => this.searchNotes(term)));

        this.noteAddDeleteSubscription.add(
            this.managedNoteService.noteAddDeleteObservable.subscribe(() => {
                this.getNotes();
            }),
        );

        this.removeBlankNoteSubscription.add(
            this.managedNoteService.isEditingObservable.subscribe((id) => {
                this.removeBlankNote(id);
            }),
        );

        this.managedNoteService.getAll().subscribe((data) => {
            this.managedNotesList = data;
        });
    }

    private searchNotes(term: string | null): IDboMessageManagedNote[] {
        return this.managedNoteService.searchContentList(this.managedNotesList, term, 1);
    }

    spliceInEmptyVal(): void {
        const emptyNote: IDboMessageManagedNote = {
            Id: 0,
            Note: '',
            Name: '',
        };
        this.managedNotes.unshift(emptyNote);
    }

    removeBlankNote(id: number): void {
        if (id === 0 && this.managedNotes[0].Id === 0) {
            this.managedNotes.splice(0, 1);
        }
    }

    notesOpen(): void {
        this.managedNoteSubject$.next('');
    }

    notesSelected(event: any): void {
        this.selectedNotes = event;
        if (this.selectedNotes.length) {
            this.total = this.selectedNotes.length;
        } else {
            this.total = this.items.length;
        }
    }

    saveList(form: UntypedFormGroup): void {
        if (form.valid) {
            this.items = [...form.value.ManagedNotes];
            this.managedNoteService.updateList(this.items).subscribe(() => {
                this.noticiationService.success('Managed Notes Saved');
                form.value.ManagedNotes = [];
            });
        } else {
            markAllFormFieldsAsTouched(form);
            this.noticiationService.error('Save failed.  Please check the form and try again.');
        }
    }

    recordsPerPage(): void {
        this.currentPage = 1;
        this.getNotes();
    }

    getNotes(): void {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: String(this.includeArchived),
            }),
        );
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'Id',
            orderDirection: SortDirection.Desc,
            query: '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };
        const searchparams = new SearchParams(searchEntity);
        this.managedNoteService.get(searchparams).subscribe((data) => {
            this.managedNotes = data.body;
            this.managedNotesList = [...data.body];
            this.total = +data.headers.get('X-List-Count');
            this.managedNotes$ = this.managedNoteSubject$.pipe(map((term) => this.searchNotes(term)));
        });
    }

    displayAddNote(): void {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: String(this.includeArchived),
            }),
        );
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'Id',
            orderDirection: SortDirection.Desc,
            query: '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };
        const searchparams = new SearchParams(searchEntity);
        this.managedNoteService.get(searchparams).subscribe((data) => {
            this.managedNotes = data.body;
            this.managedNotesList = [...data.body];
            this.total = +data.headers.get('X-List-Count');
            this.managedNotes$ = this.managedNoteSubject$.pipe(map((term) => this.searchNotes(term)));
            this.spliceInEmptyVal();
        });
    }

    includeArchivedChanged(event: boolean): void {
        this.includeArchived = event;
        this.getNotes();
    }
}
