<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>Users</h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <mt-search-filter-select *ngIf="canSeeAll" entity="Tenants" [items]="tenants" (selectionChanged)="getUsers()"></mt-search-filter-select>
    <mt-search-filter-select entity="Roles" [items]="roleTypes" (selectionChanged)="getUsers()"></mt-search-filter-select>
    <mt-search-filter-checkbox
        text="Include Inactive"
        [value]="includeInactive"
        (searchCheckboxChanged)="includeInactiveChanged($event)"
    ></mt-search-filter-checkbox>
</div>
<div class="row">
    <div class="col-md-8">
        <button type="button" *ngIf="canSeeAll" class="btn btn-primary" (click)="configureColumns()">
            Configure Columns
        </button>
        <button type="button" *ngIf="canSeeAll" class="btn btn-primary" (click)="export()">
            Export
        </button>
    </div>
</div>
<br />
<mt-entity-list
    *ngIf="users && entityListConfig"
    [entities]="users"
    [total]="total"
    [(currentPage)]="currentPage"
    (pageChanged)="getUsers()"
    (itemSelectedEvent)="userSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</mt-entity-list>

<app-user-setup> </app-user-setup>

<div *ngIf="canAddUser" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/users', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>

<mt-modal-wrapper
    [autoShow]="false"
    [options]="{ showCancelButton: true, showConfirmButton: true, confirmButtonText: 'Save', width: 500 }"
    (ready)="configureColumnsModalApi = $event"
    (okClick)="saveUserColumns()"
>
    <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="columnList" class="example-list" (cdkDropListDropped)="drop($event)">
        <div class="example-box" *ngFor="let item of columnList; let i = index" [cdkDragDisabled]="item.disabled" cdkDrag>
            <label><input [(ngModel)]="item.checked" type="checkbox" />{{ item.name }}</label>
        </div>
    </div>
</mt-modal-wrapper>
