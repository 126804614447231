export enum TaskDateType {
    ReceivedDateOnly = 0,
    StatementDate = 1,
    DueDate = 2,
    TaxFormYear = 3,
}

export const TaskDateData = [
    { Id: TaskDateType.ReceivedDateOnly, Name: 'No Date' },
    { Id: TaskDateType.StatementDate, Name: 'Statement Date' },
    { Id: TaskDateType.DueDate, Name: 'Due Date' },
    { Id: TaskDateType.TaxFormYear, Name: 'Tax Form Year' },
];
