import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { TenantService } from '../../../tenants/tenant.service';
import { UserRoleTypeService } from '../../../user-roles/user-role-type.service';

import { IUserRoleType } from '../../../model/interfaces/user-role-type';
import { ITenant } from '../../../model/interfaces/tenant';
import { ITenantAdSetting } from '../../../model/interfaces/tenant-ad-setting';
import { TenantSettingDynamicControlsPartial } from '../../../model/partials/tenant-setting-partial.form-controls';
import { ITenantSettingDynamicControlsParameters } from '../../../model/partials/tenant-setting-partial.form-controls';
import { IExpandableObject } from '../../../model/expandable-object';
import { UntypedFormGroup } from '@angular/forms';
import { IColumnData } from '../../../common/components/editable-list/editable-list.component';

@Component({
    selector: 'app-tenant-ad-settings',
    templateUrl: './tenant-ad-settings.component.html',
})
export class TenantADSettingsComponent implements OnInit {
    @Input('tenant') tenant: ITenant;
    isHovered: boolean;
    config: any = {};
    tenantForm: any;
    tenantSettingsForm: IExpandableObject;
    duplicateCheck = true;
    sortable = false;
    canAdd = true;
    canRemove = true;
    doubleClickIsDisabled = false;
    roleTypes: IUserRoleType[];

    columns: IColumnData[] = [
        { field: 'AdEnabled', width: '7%' },
        { field: 'AdServerPath', width: '25%' },
        { field: 'AdLoginPath', width: '15%' },
        { field: 'AdDefaultRoleTypeId', width: '10%' },
        { field: 'AdUserIdAttribute', width: '10%' },
        { field: 'AdFirstNameField', width: '10%' },
        { field: 'AdLastNameField', width: '10%' },
        { field: 'AdEmailField', width: '10%' },
    ];

    constructor(
        private notificationsService: NotificationsService,
        private tenantService: TenantService,
        private roleTypeService: UserRoleTypeService,
    ) {}

    ngOnInit(): void {
        this.getUserRoleTypes();
        this.config = { formObject: [], viewOnly: [] };
    }

    getUserRoleTypes(): void {
        this.roleTypeService.getAll().subscribe((roleTypes: IUserRoleType[]) => {
            this.roleTypes = roleTypes;
            this.setForm();
        });
    }

    setForm(): void {
        const additionalParams: ITenantSettingDynamicControlsParameters = {
            roleTypes: this.roleTypes,
        };
        this.tenantSettingsForm = new TenantSettingDynamicControlsPartial(null, additionalParams).Form;
    }

    saveSettings(form: UntypedFormGroup): void {
        if (form.valid) {
            this.tenant.TenantAdSettings = form.value.ADSettings.map((tenantSetting: any) => {
                tenantSetting.TenantId = this.tenant.Id;
                tenantSetting.AdEnabled = tenantSetting.AdEnabled === '' ? false : tenantSetting.AdEnabled;
                return tenantSetting as ITenantAdSetting;
            });
            this.tenantService
                .saveTenantSettings(this.tenant).subscribe((answer) => {
                    answer ? (this.success(), this.tenantService.emitChange(this.tenant), this.setForm()) : this.error();
                });
        } else {
            this.error();
        }
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error(`Save failed. Please check the form and try again.`);
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
