<div class="miles-editable miles-card padded" *ngIf="!isEditing">
    <h4>
        Phone
        <span class="pull-right show-on-hover" *ngIf="canEdit" (click)="selectPhone()" [style.cursor]="canEdit ? 'pointer' : 'default'">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <div *ngIf="phones | primary" class="display-padded">
        <span>
            <b>Primary Phone:</b>
            {{ phones | primary | phone }}
        </span>
    </div>
    <div *ngIf="!phones || phones.length === 0">
        <i>No Phones</i>
    </div>
</div>
<div class="miles-form padded" *ngIf="isEditing">
    <form [formGroup]="phoneForm" (ngSubmit)="savePhones()">
        <h4>Phone</h4>
        <div>
            <label for="phoneNumbers">Phone Number(s)</label>
            <mt-phone [PhoneArray]="PhoneArray" [PhoneTypes]="PhoneTypes" [parentForm]="phoneForm" [canAdd]="canAdd"></mt-phone>
            <br />
        </div>
        <button type="submit" class="btn btn-flat btn-success">
            Save
        </button>
        <button (click)="cancel()" class="btn btn-default btn-flat">
            Cancel
        </button>
    </form>
</div>
