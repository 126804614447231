import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IUserRole } from '../interfaces/user-role';
import { ITenantType } from '../interfaces/tenant-type';
import { IUserRoleType } from '../interfaces/user-role-type';

export interface IUserRoleDynamicControlsParameters {
    formGroup?: string;
    userRoleTypes?: IUserRoleType[];
    tenantTypes?: ITenantType[];
}

export class UserRoleDynamicControls {
    formGroup: string;
    userRoleTypes: IUserRoleType[];
    tenantTypes: ITenantType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private userrole?: IUserRole, additionalParameters?: IUserRoleDynamicControlsParameters) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'UserRole';
        this.userRoleTypes = (additionalParameters && additionalParameters.userRoleTypes) || undefined;
        this.tenantTypes = (additionalParameters && additionalParameters.tenantTypes) || undefined;

        this.Form = {
            Description: new DynamicField({
                formGroup: this.formGroup,
                label: 'Description',
                name: 'Description',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(500)],
                validators: { maxlength: 500 },
                value:
                    this.userrole && this.userrole.hasOwnProperty('Description') && this.userrole.Description !== null
                        ? this.userrole.Description.toString()
                        : '',
            }),
            IsEditable: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Editable',
                name: 'IsEditable',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value:
                    this.userrole && this.userrole.hasOwnProperty('IsEditable') && this.userrole.IsEditable !== null
                        ? this.userrole.IsEditable
                        : true,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(50)],
                validators: { required: true, maxlength: 50 },
                value: this.userrole && this.userrole.hasOwnProperty('Name') && this.userrole.Name !== null ? this.userrole.Name.toString() : '',
            }),
            TenantTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tenant Type',
                name: 'TenantTypeId',
                options: this.tenantTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [noZeroRequiredValidator],
                validators: { required: true },
                value: (this.userrole && this.userrole.TenantTypeId) || null,
            }),
            UserRoleTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User Role Type',
                name: 'UserRoleTypeId',
                options: this.userRoleTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [noZeroRequiredValidator],
                validators: { required: true },
                value: (this.userrole && this.userrole.UserRoleTypeId) || null,
            }),
        };

        this.View = {
            Description: new DynamicLabel({
			    label: 'Description',
			    value: this.userrole && this.userrole.hasOwnProperty('Description') && this.userrole.Description !== null
			        ? this.userrole.Description.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsEditable: new DynamicLabel({
			    label: 'Is Editable',
			    value: this.userrole && this.userrole.hasOwnProperty('IsEditable') && this.userrole.IsEditable !== null ? this.userrole.IsEditable : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Name: new DynamicLabel({
			    label: 'Name',
			    value: this.userrole && this.userrole.hasOwnProperty('Name') && this.userrole.Name !== null ? this.userrole.Name.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            TenantTypeId: new DynamicLabel({
			    label: 'Tenant Type',
			    value: getMetaItemValue(this.tenantTypes as unknown as IMetaItem[], this.userrole && this.userrole.hasOwnProperty('TenantTypeId') && this.userrole.TenantTypeId !== null
			        ? this.userrole.TenantTypeId
			        : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            UserRoleTypeId: new DynamicLabel({
			    label: 'User Role Type',
			    value: getMetaItemValue(this.userRoleTypes as unknown as IMetaItem[], this.userrole && this.userrole.hasOwnProperty('UserRoleTypeId') && this.userrole.UserRoleTypeId !== null
			        ? this.userrole.UserRoleTypeId
			        : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };
    }
}
