/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prefer-const */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { entityListModuleConfig } from '../../common/shared.module';
import { IEntitySearchParams, ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { NotificationsService } from '@mt-ng2/notifications-module';
import {
    IItemDeletedEvent,
    IColumnSortedEvent,
    SortDirection,
    IItemSelectedEvent,
    EntityListExportService,
    EntityListExportConfig,
} from '@mt-ng2/entity-list-module';
import { IVwFund } from '../../model/interfaces/vw-fund';
import { ISearchFilterCheckboxValueChangedEvent } from '@mt-ng2/search-filter-checkbox-control';
import { Router } from '@angular/router';
import { AuthService, ClaimsService, ClaimValues, ILoggedIn } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { NotesUpdatedService } from '../../common/components/notes-modal/notes-modal-updated-service';
import { Subscription, forkJoin, Observable, Subject } from 'rxjs';
import { ITaskMsgAuditLog } from '../../model/interfaces/task-msg-audit-log';
import { AuditLogEntityListConfig } from './audit-list-config';
import { TaskMsgAuditLogTypeService } from '../audit-services/task-msg-audit-log-type.service';
import { TaskMsgAuditLogService } from '../audit-services/task-msg-audit-log.service';
import { ITaskMsgAuditLogType } from '../../model/interfaces/task-msg-audit-log-type';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { ISearchFilterDaterangeValue } from '../../common/components/date-time/search-filter-daterange/search-filter-daterange.library';
import { DatePipe } from '@angular/common';
import { UserService } from '../../users/user.service';
import { IUser } from '../../model/interfaces/user';
import { ISearchbarControlAPI } from '@mt-ng2/searchbar-control';
import { ITenant } from '../../model/interfaces/tenant';
import { IAuthUser } from '../../model/interfaces/auth-user';
import { UserRoleEnum, UserTypesAuditLog } from '../../common/constants/Enum';
import { DboMessageService } from '../../messages/services/dbo-message.service';
import { MessageStatusSearchService } from '../../messages/services/message-status-search.service';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { searchNameList } from '../../common/util';
import { INamedEntity } from '@mt-ng2/multiselect-control';
import { ISearchFilterUserDomain } from '../../model/interfaces/custom/ISearchFilterUserDomain';

@Component({
    selector: 'app-audit',
    templateUrl: './audit-list-component.html',
})
export class AuditListComponent implements OnInit {
    query: string;
    logs: ITaskMsgAuditLog[];
    logActionTypes: MtSearchFilterItem[] = [];
    messageStatuses: MtSearchFilterItem[] = [];
    total: number;
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    entityListConfig = new AuditLogEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    startTimestamp: Date;
    endTimestamp: Date;
    pipe = new DatePipe('en-US');
    searchbarControlAPI: ISearchbarControlAPI;
    emailIDSearch = 0;
    currUser: IAuthUser;
    userTypeAuditLog: MtSearchFilterItem[] = [];
    usersDomain: ISearchFilterUserDomain[] = [];

    users: MtSearchFilterItem<INamedEntity | ISearchFilterUserDomain>[] = [];
    allUsersList: any[] = [];
    usersList: any[] = [];
    users$: Observable<any>;
    selectedUsers = [];
    showSelectAllUserBtn = false;
    selectAllUserText = true;

    usersSubject$ = new Subject<string>();

    constructor(
        public notificationsService: NotificationsService,
        public router: Router,
        public claimsService: ClaimsService,
        public auditService: TaskMsgAuditLogService,
        public auditActionTypeService: TaskMsgAuditLogTypeService,
        public userService: UserService,
        public authService: AuthService,
        public messageService: DboMessageService,
        public messageStatusService: MessageStatusSearchService,
        private exportService: EntityListExportService,
    ) {}

    ngOnInit(): void {
        forkJoin(
            this.auditActionTypeService.getSearchFilterItems(),
            this.userService.getAll(),
            this.messageStatusService.getSearchFilterItems(),
        ).subscribe((data) => {
            this.authService.currentUser.subscribe((currUser) => {
                let [logActionTypes, users, messageStatuses] = data;
                const currUserId = currUser.Id;
                this.logActionTypes = logActionTypes;
                this.messageStatuses = messageStatuses;

                users = users.filter((u) => u.AuthUser.HasAccess);
                this.userService.getById(currUserId).subscribe((data: IUser) => {
                    this.currUser = data.AuthUser;

                    if (this.currUser && this.currUser.RoleId !== UserRoleEnum.SuperAdmin && this.currUser.RoleId !== UserRoleEnum.InternalAdmin) {
                        const filteredUsers = users.filter((u) => u.AuthUser.TenantId === this.currUser.TenantId);
                        this.users = this.getUserSearchFilterItems(filteredUsers);
                    } else {
                        this.users = this.getUserSearchFilterItems(users);
                    }

                    this.users.push({ Item: { Id: 0, Name: 'System User', Domain: 'none' }, Selected: false });
                    this.allUsersList = this.users.map((u) => u.Item);
                    this.usersList = this.allUsersList;
                    this.users$ = this.usersSubject$.pipe(map((term) => this.searchUsers(term)));
                    this.search();
                });
            });
        });

        this.entityListConfig.export = new EntityListExportConfig({
            exportName: 'Audit export',
            getDataForExport: this.getAuditForExport.bind(this),
        });

        this.userTypeAuditLog = this.getUserTypeAuditLog();
    }

    searchUsers(term: string): any[] {
        return searchNameList(this.usersList, term, 1);
    }

    private getUserTypeAuditLog(): MtSearchFilterItem[] {
        const userTypes = [];
        for (let index = 0; index < Object.keys(UserTypesAuditLog).filter((u) => isNaN(Number(u))).length; index++) {
            userTypes.push({ Item: { Id: index, Name: UserTypesAuditLog[index] }, Selected: false });
        }
        return userTypes;
    }

    filterUsers(): void {
        const selectedUserTypes: number[] = this.getSelectedFilters(this.userTypeAuditLog);
        this.UpdateUserSelectFilter(selectedUserTypes);
        const filteredSelectedUsers = [];
        for (const userTypeId of selectedUserTypes) {
            if (userTypeId === UserTypesAuditLog.PMC) {
                const pmcUsers = this.allUsersList.filter((u) => u.Domain === 'privatemarketconnect');
                filteredSelectedUsers.push(...pmcUsers);
            }
            if (userTypeId === UserTypesAuditLog.ShoreGroup) {
                const shoreGrpUsers = this.allUsersList.filter((u) => u.Domain === 'shoregrp');
                filteredSelectedUsers.push(...shoreGrpUsers);
            }
            if (userTypeId === UserTypesAuditLog.Other) {
                const otherUsers = this.allUsersList.filter((u) => u.Domain === 'none');
                filteredSelectedUsers.push(...otherUsers);
            }
        }
        if (filteredSelectedUsers.length > 0) {
            this.usersList = filteredSelectedUsers;
            return;
        }
        this.usersList = this.allUsersList;
    }

    private UpdateUserSelectFilter(selectedUserTypes: number[]): void {
        if (selectedUserTypes.length > 0 && selectedUserTypes.length < this.userTypeAuditLog.length) {
            this.showSelectAllUserBtn = true;
            this.selectAllUserText = true;
        }

        if (selectedUserTypes.length === 0 || selectedUserTypes.length === this.userTypeAuditLog.length) {
            this.showSelectAllUserBtn = false;
        }
    }

    private getUserSearchFilterItems(users: IUser[]): MtSearchFilterItem[] {
        let domain = '';
        return users.map((u) => {
            const userEmail = u.Email;
            if (userEmail != null && userEmail.length) {
                const indexOfAt = userEmail.indexOf('@') + 1;
                const indexOfTopLevelDomain = userEmail.substring(indexOfAt).indexOf('.') + indexOfAt;
                domain = userEmail.substring(indexOfAt, indexOfTopLevelDomain);
            }
            let user: MtSearchFilterItem<ISearchFilterUserDomain>;
            if (domain === 'shoregrp' || domain === 'privatemarketconnect') {
                user = { Item: { Id: u.Id, Name: `${u.FirstName} ${u.LastName}  (${domain})`, Domain: domain }, Selected: false };
            } else {
                user = { Item: { Id: u.Id, Name: `${u.FirstName} ${u.LastName}`, Domain: 'none' }, Selected: false };
            }
            return user;
        });
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedActionIds: number[] = this.getSelectedFilters(this.logActionTypes);
        const selectedUserIds: number[] = this.selectedUsers.map((u) => u.Id);
        const selectedMessageStatusIds: number[] = this.getSelectedFilters(this.messageStatuses);
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ActionIds',
                valueArray: selectedActionIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'UserIds',
                valueArray: selectedUserIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'MessageStatusIds',
                valueArray: selectedMessageStatusIds,
            }),
        );

        if (this.startTimestamp) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'StartTimestamp',
                    value: this.pipe.transform(this.startTimestamp, 'M/d/yyyy h:mm:ss a'),
                }),
            );
        }

        if (this.endTimestamp) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'EndTimestamp',
                    value: this.pipe.transform(this.endTimestamp, 'M/d/yyyy h:mm:ss a'),
                }),
            );
        }

        if (this.emailIDSearch) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'EmailId',
                    valueArray: [this.emailIDSearch],
                }),
            );
        }

        if (this.currUser && this.currUser.RoleId !== UserRoleEnum.SuperAdmin && this.currUser.RoleId !== UserRoleEnum.InternalAdmin) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'currUserTenantId',
                    value: this.currUser.TenantId.toString(),
                }),
            );
        }

        return _extraSearchParams;
    }

    search(): void {
        this.currentPage = 1;
        this.getAuditLogs();
    }

    getAuditLogs(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };
        const searchparams = new SearchParams(searchEntity);
        this.auditService.get(searchparams).subscribe((response) => {
            this.logs = response.body;
            this.total = +response.headers.get('X-List-Count');
        });
    }

    getSearchParams(options = { forExport: false }): SearchParams {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        if (options.forExport) {
            searchEntity.skip = 0;
            searchEntity.take = this.total;
        }

        const searchparams = new SearchParams(searchEntity);
        return searchparams;
    }

    getAuditLogsObserveable(): Observable<HttpResponse<ITaskMsgAuditLog[]>> {
        const searchParams = this.getSearchParams({ forExport: true });
        return this.auditService.get(searchParams);
    }

    getAuditForExport(): Observable<ITaskMsgAuditLog[]> {
        return this.getAuditLogsObserveable().pipe(map((answer) => answer.body));
    }

    setTimestampRange(event: ISearchFilterDaterangeValue): void {
        this.startTimestamp = event.startDate;
        this.endTimestamp = event.endDate;
    }

    onEmailIDSearch(query: string): void {
        this.emailIDSearch = +query;
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getAuditLogs();
    }

    export(): void {
        this.getAuditForExport().subscribe((data) => {
            this.exportService.export(data, this.entityListConfig);
        });
    }

    exportAuditLog(): void {
        const searchParams = this.getSearchParams({ forExport: true });
        this.auditService.exportTasks(searchParams).subscribe((res) => {
            saveAs(res.blob, res.fileName);
        });
    }

    updateSelectedUsers(): void {
        if (this.selectAllUserText) {
            this.selectedUsers = this.usersList;
        } else {
            this.selectedUsers = [];
        }
        this.selectAllUserText = !this.selectAllUserText;
    }
}
