import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ITenantAdSetting } from '../interfaces/tenant-ad-setting';
import { ITenant } from '../interfaces/tenant';

export interface ITenantAdSettingDynamicControlsParameters {
    formGroup?: string;
    tenants?: ITenant[];
}

export class TenantAdSettingDynamicControls {
    formGroup: string;
    tenants: ITenant[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private tenantadsetting?: ITenantAdSetting, additionalParameters?: ITenantAdSettingDynamicControlsParameters) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'TenantAdSetting';
        this.tenants = (additionalParameters && additionalParameters.tenants) || undefined;

        this.Form = {
            AdDefaultRoleTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ad Default Role Type',
                name: 'AdDefaultRoleTypeId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: (this.tenantadsetting && this.tenantadsetting.AdDefaultRoleTypeId) || null,
            }),
            AdEmailField: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ad Email Field',
                name: 'AdEmailField',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(100)],
                validators: { required: true, maxlength: 100 },
                value:
                    this.tenantadsetting && this.tenantadsetting.hasOwnProperty('AdEmailField') && this.tenantadsetting.AdEmailField !== null
                        ? this.tenantadsetting.AdEmailField.toString()
                        : '',
            }),
            AdEnabled: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ad Enabled',
                name: 'AdEnabled',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value:
                    this.tenantadsetting && this.tenantadsetting.hasOwnProperty('AdEnabled') && this.tenantadsetting.AdEnabled !== null
                        ? this.tenantadsetting.AdEnabled
                        : false,
            }),
            AdFirstNameField: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ad First Name Field',
                name: 'AdFirstNameField',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(100)],
                validators: { required: true, maxlength: 100 },
                value:
                    this.tenantadsetting && this.tenantadsetting.hasOwnProperty('AdFirstNameField') && this.tenantadsetting.AdFirstNameField !== null
                        ? this.tenantadsetting.AdFirstNameField.toString()
                        : '',
            }),
            AdLastNameField: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ad Last Name Field',
                name: 'AdLastNameField',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(100)],
                validators: { required: true, maxlength: 100 },
                value:
                    this.tenantadsetting && this.tenantadsetting.hasOwnProperty('AdLastNameField') && this.tenantadsetting.AdLastNameField !== null
                        ? this.tenantadsetting.AdLastNameField.toString()
                        : '',
            }),
            AdLoginPath: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ad Login Path',
                name: 'AdLoginPath',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(250)],
                validators: { required: true, maxlength: 250 },
                value:
                    this.tenantadsetting && this.tenantadsetting.hasOwnProperty('AdLoginPath') && this.tenantadsetting.AdLoginPath !== null
                        ? this.tenantadsetting.AdLoginPath.toString()
                        : '',
            }),
            AdServerPath: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ad Server Path',
                name: 'AdServerPath',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(250)],
                validators: { required: true, maxlength: 250 },
                value:
                    this.tenantadsetting && this.tenantadsetting.hasOwnProperty('AdServerPath') && this.tenantadsetting.AdServerPath !== null
                        ? this.tenantadsetting.AdServerPath.toString()
                        : '',
            }),
            AdUserIdAttribute: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ad User Id Attribute',
                name: 'AdUserIdAttribute',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(100)],
                validators: { required: true, maxlength: 100 },
                value:
                    this.tenantadsetting &&
                    this.tenantadsetting.hasOwnProperty('AdUserIdAttribute') &&
                    this.tenantadsetting.AdUserIdAttribute !== null
                        ? this.tenantadsetting.AdUserIdAttribute.toString()
                        : '',
            }),
            TenantId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tenant',
                name: 'TenantId',
                options: this.tenants,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [noZeroRequiredValidator],
                validators: { required: true },
                value: (this.tenantadsetting && this.tenantadsetting.TenantId) || null,
            }),
        };

        this.View = {
            AdDefaultRoleTypeId: new DynamicLabel({
			    label: 'Ad Default Role Type',
			    value: (this.tenantadsetting && this.tenantadsetting.AdDefaultRoleTypeId) || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            AdEmailField: new DynamicLabel({
			    label: 'Ad Email Field',
			    value: this.tenantadsetting && this.tenantadsetting.hasOwnProperty('AdEmailField') && this.tenantadsetting.AdEmailField !== null
			        ? this.tenantadsetting.AdEmailField.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            AdEnabled: new DynamicLabel({
			    label: 'Ad Enabled',
			    value: this.tenantadsetting && this.tenantadsetting.hasOwnProperty('AdEnabled') && this.tenantadsetting.AdEnabled !== null
			        ? this.tenantadsetting.AdEnabled
			        : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            AdFirstNameField: new DynamicLabel({
			    label: 'Ad First Name Field',
			    value: this.tenantadsetting && this.tenantadsetting.hasOwnProperty('AdFirstNameField') && this.tenantadsetting.AdFirstNameField !== null
			        ? this.tenantadsetting.AdFirstNameField.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            AdLastNameField: new DynamicLabel({
			    label: 'Ad Last Name Field',
			    value: this.tenantadsetting && this.tenantadsetting.hasOwnProperty('AdLastNameField') && this.tenantadsetting.AdLastNameField !== null
			        ? this.tenantadsetting.AdLastNameField.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            AdLoginPath: new DynamicLabel({
			    label: 'Ad Login Path',
			    value: this.tenantadsetting && this.tenantadsetting.hasOwnProperty('AdLoginPath') && this.tenantadsetting.AdLoginPath !== null
			        ? this.tenantadsetting.AdLoginPath.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            AdServerPath: new DynamicLabel({
			    label: 'Ad Server Path',
			    value: this.tenantadsetting && this.tenantadsetting.hasOwnProperty('AdServerPath') && this.tenantadsetting.AdServerPath !== null
			        ? this.tenantadsetting.AdServerPath.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            AdUserIdAttribute: new DynamicLabel({
			    label: 'Ad User Id Attribute',
			    value: this.tenantadsetting && this.tenantadsetting.hasOwnProperty('AdUserIdAttribute') && this.tenantadsetting.AdUserIdAttribute !== null
			        ? this.tenantadsetting.AdUserIdAttribute.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            TenantId: new DynamicLabel({
			    label: 'Tenant',
			    value: getMetaItemValue(this.tenants as unknown as IMetaItem[], this.tenantadsetting && this.tenantadsetting.hasOwnProperty('TenantId') && this.tenantadsetting.TenantId !== null
			        ? this.tenantadsetting.TenantId
			        : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };
    }
}
