<app-stackable-modal (onClose)="cancel()">
    <div style="min-width: 50vw;">
        <div class="miles-form padded">
            <mt-custom-document
                class="justUploader"
                [maxFileSize]="maxFileSize"
                [allowedMimeType]="allowedMimeType"
                (onAfterAddingAll)="addAllFiles($event)"
                (onAddingFileFailed)="onWhenAddingFileFailed($event)"
            ></mt-custom-document>
        </div>
        <button type="button" class="btn btn-primary btn-default" (click)="cancel()" style="float: right; margin-top: 20px;">
            close
        </button>
    </div>
</app-stackable-modal>
