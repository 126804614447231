import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IEntityListComponentMembers, IEntity, IEntityListDynamicCellComponent } from '@mt-ng2/entity-list-module';

@Component({
    templateUrl: './row-selector.component.html',
})
export class RowSelectorComponent implements IEntityListDynamicCellComponent {
    @Input() entity: IEntity;
    entityListComponentMembers: IEntityListComponentMembers;
    constructor(private router: Router) {}
    select(): void {
        const entityObj = { EmailId: 0, ...this.entity };
        void this.router.navigate(['/library', this.entity.Id], { queryParams: { messageId: entityObj.EmailId } });
    }
}
