import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IEmailContact } from '../interfaces/email-contact';
import { IUser } from '../interfaces/user';

export interface IEmailContactDynamicControlsParameters {
    formGroup?: string;
    archivedBies?: IUser[];
    createdBies?: IUser[];
}

export class EmailContactDynamicControls {
    formGroup: string;
    archivedBies: IUser[];
    createdBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private emailcontact?: IEmailContact, additionalParameters?: IEmailContactDynamicControlsParameters) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'EmailContact';
        this.archivedBies = (additionalParameters && additionalParameters.archivedBies) || undefined;
        this.createdBies = (additionalParameters && additionalParameters.createdBies) || undefined;

        this.Form = {
            ArchivedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived By',
                name: 'ArchivedById',
                options: this.archivedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.emailcontact && this.emailcontact.ArchivedById) || null,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.emailcontact && this.emailcontact.CreatedById) || null,
            }),
            CreatedOn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created On',
                name: 'CreatedOn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.emailcontact && this.emailcontact.CreatedOn) || null,
            }),
            Email: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email',
                name: 'Email',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(50), Validators.email],
                validators: { required: true, maxlength: 50, email: true },
                value:
                    this.emailcontact && this.emailcontact.hasOwnProperty('Email') && this.emailcontact.Email !== null
                        ? this.emailcontact.Email.toString()
                        : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(50)],
                validators: { maxlength: 50 },
                value:
                    this.emailcontact && this.emailcontact.hasOwnProperty('FirstName') && this.emailcontact.FirstName !== null
                        ? this.emailcontact.FirstName.toString()
                        : '',
            }),
            IsArchived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Archived',
                name: 'IsArchived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value:
                    this.emailcontact && this.emailcontact.hasOwnProperty('IsArchived') && this.emailcontact.IsArchived !== null
                        ? this.emailcontact.IsArchived
                        : false,
            }),
            LastArchivedOn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Archived On',
                name: 'LastArchivedOn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.emailcontact && this.emailcontact.LastArchivedOn) || null,
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(50)],
                validators: { required: true, maxlength: 50 },
                value:
                    this.emailcontact && this.emailcontact.hasOwnProperty('LastName') && this.emailcontact.LastName !== null
                        ? this.emailcontact.LastName.toString()
                        : '',
            }),
            LastUpdatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Updated By',
                name: 'LastUpdatedById',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.emailcontact && this.emailcontact.LastUpdatedById) || null,
            }),
            LastUpdatedOn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Updated On',
                name: 'LastUpdatedOn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.emailcontact && this.emailcontact.LastUpdatedOn) || null,
            }),
            MiddleName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Middle Name',
                name: 'MiddleName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(50)],
                validators: { maxlength: 50 },
                value:
                    this.emailcontact && this.emailcontact.hasOwnProperty('MiddleName') && this.emailcontact.MiddleName !== null
                        ? this.emailcontact.MiddleName.toString()
                        : '',
            }),
        };

        this.View = {
            ArchivedById: new DynamicLabel({
			    label: 'Archived By',
			    value: getMetaItemValue(this.archivedBies as unknown as IMetaItem[], this.emailcontact && this.emailcontact.hasOwnProperty('ArchivedById') && this.emailcontact.ArchivedById !== null
			        ? this.emailcontact.ArchivedById
			        : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.emailcontact && this.emailcontact.hasOwnProperty('CreatedById') && this.emailcontact.CreatedById !== null
			        ? this.emailcontact.CreatedById
			        : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedOn: new DynamicLabel({
			    label: 'Created On',
			    value: (this.emailcontact && this.emailcontact.CreatedOn) || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            Email: new DynamicLabel({
			    label: 'Email',
			    value: this.emailcontact && this.emailcontact.hasOwnProperty('Email') && this.emailcontact.Email !== null
			        ? this.emailcontact.Email.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FirstName: new DynamicLabel({
			    label: 'First Name',
			    value: this.emailcontact && this.emailcontact.hasOwnProperty('FirstName') && this.emailcontact.FirstName !== null
			        ? this.emailcontact.FirstName.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsArchived: new DynamicLabel({
			    label: 'Is Archived',
			    value: this.emailcontact && this.emailcontact.hasOwnProperty('IsArchived') && this.emailcontact.IsArchived !== null
			        ? this.emailcontact.IsArchived
			        : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LastArchivedOn: new DynamicLabel({
			    label: 'Last Archived On',
			    value: (this.emailcontact && this.emailcontact.LastArchivedOn) || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            LastName: new DynamicLabel({
			    label: 'Last Name',
			    value: this.emailcontact && this.emailcontact.hasOwnProperty('LastName') && this.emailcontact.LastName !== null
			        ? this.emailcontact.LastName.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LastUpdatedById: new DynamicLabel({
			    label: 'Last Updated By',
			    value: (this.emailcontact && this.emailcontact.LastUpdatedById) || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            LastUpdatedOn: new DynamicLabel({
			    label: 'Last Updated On',
			    value: (this.emailcontact && this.emailcontact.LastUpdatedOn) || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            MiddleName: new DynamicLabel({
			    label: 'Middle Name',
			    value: this.emailcontact && this.emailcontact.hasOwnProperty('MiddleName') && this.emailcontact.MiddleName !== null
			        ? this.emailcontact.MiddleName.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };
    }
}
