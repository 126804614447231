import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import {
    IEntityListDynamicCellComponent,
    IEntityListComponentMembers,
    IEntity,
    EntityListConfig,
    IEntityListConfig,
} from '@mt-ng2/entity-list-module';
import { NotesModalService } from '../notes-modal/notes-modal-service';
import { NotesUpdatedService } from './notes-modal-updated-service';
import { Subscription } from 'rxjs';
import { IEntityListConfigWithNotes, INoteEntity, INoteService } from '../../../model/interfaces/custom/EntityListConfigWithNotes';

@Component({
    selector: 'app-view-note',
    styles: [
        `
            #noteParent {
                position: relative;
                display: inline-block;
            }
            #noteParent .displayNote {
                visibility: hidden;
                width: 100px;
                height: auto;
                text-align: center;
                /* padding: 5px 0; */
                border-radius: 6px;
                position: absolute;
                z-index: 999;
                bottom: 125%;
                left: 50%;
                margin-left: -100px;
                opacity: 0;
                transition: opacity 0.3s;
                white-space: pre-line;
            }

            #noteParent .displayNote::after {
                content: ' ';
                position: absolute;
                top: 100%; /* At the bottom of the tooltip */
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: black transparent transparent transparent;
            }
            #noteParent:hover .displayNote {
                visibility: visible;
                opacity: 1;
                margin-top: auto;
                margin-bottom: auto;
            }
            .noteContainer {
                width: 100%;
            }
        `,
    ],
    template: `
        <div class="noteContainer" (click)="viewNote($event)">
            <span (click)="viewNote($event)" class="btn btn-transparent" [title]="fullNote ? fullNote : 'Note'">
                <div *ngIf="item && (item.Note || (item.Notes && item.Notes.length > 0))" id="noteParent">
                    <p>{{ previewNote }}</p>
                </div>
                <p *ngIf="item && !item.Note && (!item.Notes || item.Notes.length <= 0)">Add Note</p>
            </span>
        </div>
    `,
})
export class ViewNoteComponent implements IEntityListDynamicCellComponent, OnInit, OnDestroy {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.item = value as INoteEntity;
    }
    item: INoteEntity;
    service: INoteService;
    isNoteList: boolean;
    fullNote = '';
    previewNote = '';
    subscription = new Subscription();

    constructor(private modalService: NotesModalService, private notesUpdatedService: NotesUpdatedService) {}
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
    ngOnInit(): void {
        this.service = ((this.entityListComponentMembers.entityListConfig as IEntityListConfig) as IEntityListConfigWithNotes).noteService;
        this.isNoteList = ((this.entityListComponentMembers.entityListConfig as IEntityListConfig) as IEntityListConfigWithNotes).isNoteList;
        this.fullNote = this.item.Note;
        this.setPreviewNote(this.item.Note);

        if (this.isNoteList && this.item.Notes.length) {
            const notesEndIndex = this.item.Notes.length - 1;
            const latestNote = this.item.Notes[notesEndIndex].Note;
            this.fullNote = latestNote;
            this.setPreviewNote(latestNote);
        }

        this.subscription.add(
            this.notesUpdatedService.refreshLogsObservable.subscribe(() => {
                if (this.isNoteList) {
                    this.service.getNotes(this.item.EmailId).subscribe((notes) => {
                        if (notes.length) {
                            this.fullNote = notes[0].Note;
                            this.setPreviewNote(notes[0].Note);
                            this.item.Notes = notes;
                        }
                    });
                }
            }),
        );
    }

    setPreviewNote(fullNote: string): void {
        if (fullNote) {
            this.previewNote = fullNote.length > 5 ? fullNote.slice(0, 10) + '...' : fullNote;
        }
    }

    viewNote(event: any): void {
        event.stopPropagation();
        this.modalService.showModal(this.item, this.service, this.isNoteList);
    }
}
