import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IMessageTagForward } from '../../model/interfaces/message-tag-forward';
import { Observable } from 'rxjs';
import { IMessageTagCriteriaContain } from '../../model/interfaces/message-tag-criteria-contain';

export const emptyMessageTagContains: IMessageTagCriteriaContain = {
    Content: '',
    Id: 0,
    IsBodyContains: true,
    IsSubjectContains: true,
    MessageTagId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class MessageTagContainsService extends BaseService<IMessageTagCriteriaContain> {
    constructor(public http: HttpClient) {
        super('/tagsContains', http);
    }

    getEmptyMessageTagForward(): IMessageTagCriteriaContain {
        return { ...emptyMessageTagContains };
    }

    deleteAllByTagId(id: number): Observable<void> {
        return this.http.delete<void>(`/tagsContains/deleteByTagId/${id}`);
    }
}
