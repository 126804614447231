<div class="miles-card padded" *ngIf="!showUploadArea">
    <h4>Documents</h4>
    <div class="row">
        <div class="col-md-4 col-md-offset-8">
            <input
                type="checkbox"
                [(ngModel)]="includeArchived"
                name="includeArchivedText"
                text="Include Archived"
                (ngModelChange)="includeArchivedChanged()"
            />
            <label for="includeArchivedText">Include Archived</label>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <td></td>
                    <td>
                        <b style="color: white;">Name</b>
                    </td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <input type="checkbox" [(ngModel)]="originalDoc.IsChecked" />
                    </td>
                    <td>
                        <span class="fa fa-fw {{ originalDoc.Name | fileicon }}" (click)="downloadOriginalDoc()"></span>
                        <span (click)="downloadOriginalDoc()"> {{ originalDoc.Name }}</span>
                    </td>
                    <td></td>
                </tr>
                <tr *ngFor="let document of supportingDocs; let i = index" [class.archived]="document.IsVisible === false">
                    <td>
                        <input type="checkbox" [(ngModel)]="document.IsChecked" />
                    </td>
                    <td>
                        <span class="fa fa-fw {{ document.Name | fileicon }}" (click)="downloadDoc(i)"></span>
                        <span (click)="downloadDoc(i)"> {{ document.Name }}</span>
                    </td>
                    <td *ngIf="document.IsVisible">
                        <div class="text-center" [hidden]="!hasFullAccess" (click)="deleteDoc(i)">
                            <i class="fa fa-trash fa-lg" aria-hidden="true"></i>
                        </div>
                    </td>
                    <td *ngIf="!document.IsVisible">
                        <div class="text-center" [hidden]="!hasFullAccess" (click)="deleteDoc(i)">
                            <i class="fa fa-trash fa-lg" aria-hidden="true"></i><i class="fa fa-lg fa-undo overlay-fa"></i>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row">
        <div class="col-md-2">
            <button type="button" class="btn btn-primary" [disabled]="isSelectionEmpty()" (click)="downloadDocs()">
                Download Docs
            </button>
        </div>
    </div>

    <div *ngIf="showTotal" class="pull-right max-of-total">
        <span>{{ _max }} of {{ _total }}</span>
    </div>
    <i *ngIf="!documentArray.length">No Documents</i>
    <div *ngIf="canAdd" class="fab-wrap">
        <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="isEditing = true">
            <span class="fa fa-plus"></span>
        </button>
    </div>
    <div *ngIf="showTotal" class="show-on-hover">
        <a (click)="seeAll()" class="btn btn-primary btn-flat see-all"
            >see all
            <span class="badge">{{ _total }}</span>
        </a>
    </div>
</div>

<div [hidden]="!showUploadArea" class="miles-form padded">
    <mt-custom-document
        [maxFileSize]="maxFileSize"
        [allowedMimeType]="allowedMimeType"
        (onAfterAddingFile)="addDocuments($event)"
        (onAddingFileFailed)="onWhenAddingFileFailed($event)"
    ></mt-custom-document>
    <br />
    <button type="button" class="btn btn-flat btn-default" (click)="cancel()">
        Cancel
    </button>
</div>
