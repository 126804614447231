import { EntityListConfig, SortDirection, EntityListColumn, IEntityListConfig, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { IVwFund } from '../../model/interfaces/vw-fund';
import { ViewNoteComponent } from '../../common/components/notes-modal/view-note-component';
import { FundService } from '../fund.service';
import { IEntityListConfigWithNotes } from '../../model/interfaces/custom/EntityListConfigWithNotes';
import { ViewFundAltNamesComponent } from '../fund-alt-names-modal/fund-viewAltNamesCell';

export class FundsEntityListConfig extends EntityListConfig {
    constructor(noteService: FundService) {
        const listConfig: IEntityListConfigWithNotes = {
            columns: [
                new EntityListColumn({
                    name: 'Name',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        isDefaultForSort: true,
                    },
                }),
                new EntityListColumn({
                    component: ViewFundAltNamesComponent,
                    fireOnColumnSelected: false,
                    name: 'Alternative Names',
                    sort: { disableSort: false, sortProperty: 'HasAltNames' },
                    style: { width: 200 },
                }),
                new EntityListColumn({
                    name: 'Currency',
                    style: { width: 100 },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IVwFund) => {
                        if (entity.GeneralPartner) {
                            return `${entity.GeneralPartner}`;
                        } else {
                            return 'No General Partner';
                        }
                    },
                    name: 'General Partner',
                    sort: {
                        disableSort: false,
                        sortProperty: 'GeneralPartner',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IVwFund) => {
                        if (entity.Note) {
                            return entity.Note;
                        } else {
                            return '';
                        }
                    },
                    component: ViewNoteComponent,
                    fireOnColumnSelected: false,
                    name: 'Note',
                    style: { width: 300 },
                }),
            ],
            delete: new EntityListDeleteColumn({
                columnHtml: (entity: IVwFund) =>
                    '<div class="text-center">' +
                    '<i class="fa fa-lg fa-trash" aria-hidden="true"></i>' +
                    (entity.IsArchived ? '<i class="fa fa-lg fa-undo overlay-fa"></i>' : '') +
                    '</div>',
                confirm: false,
                headerText: 'Archive',
            }),
            noteService: noteService,
            rowClass: (entity: IVwFund) => (entity.IsArchived ? 'archived' : null),
        };
        super(listConfig);
    }
}
