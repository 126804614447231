import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { CommonDocumentsComponent } from '@mt-ng2/entity-components-documents';
import { DboMessageDocumentService } from '../../messages/services/dbo-message-document-service';
import { FileItem } from 'ng2-file-upload';
import { MimeTypes } from '../../common/constants/MimeTypes';
import { InboxAttachmentUpdateService } from './InboxAttachmentUpdateService';
import { DboMessageService } from '../services/dbo-message.service';
import { MessageState } from '../../common/constants/Enum';
import { IDboMessageDTO } from '../../model/interfaces/custom/IDboMessageDTO';
import { map } from 'rxjs/operators';
import { forkJoin, from, Observable } from 'rxjs';
import { FILE_SIZE_LIMIT } from '../../common/constants/files';

@Component({
    selector: 'app-inbox-attachment-add-modal',
    templateUrl: './inboxAttachmentAddModal.component.html',
})
export class InboxAttachmentAddModalComponent extends CommonDocumentsComponent implements OnInit {
    modalOptions: IModalOptions = {
        showConfirmButton: false,
        width: '50%',
    };
    duplicateAttachmentModalOptions: IModalOptions = {
        showCancelButton: false,
        showCloseButton: true,
        showConfirmButton: false,
        width: '700px',
    };
    maxFileSize = FILE_SIZE_LIMIT;
    _isEditing = false;
    _allowedMimeType = MimeTypes;
    get allowedMimeType(): string[] {
        return this._allowedMimeType;
    }

    modalService: InboxAttachmentUpdateService;
    doubleClickIsDisabled = false;
    docService: DboMessageDocumentService;
    messageService: DboMessageService;
    documentIds: number[];
    modalApi: IModalWrapperApi;

    duplicateFiles: File[] = [];
    fileQueue: FileItem[] = [];

    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

    ngOnInit(): void {}

    cancel(): void {
        this.onCancel.emit();
    }

    addDoc(event: FileItem): void {
        const uploadedFile = event._file;
        const lastModifiedDate =  new Date(uploadedFile.lastModified);
        if (uploadedFile.size <= this.maxFileSize) {
            this.docService.saveDocumentMultiple(this.documentIds, uploadedFile, lastModifiedDate).subscribe(() => {
                this.messageService.setMessageState(this.documentIds, MessageState.PendingSort).subscribe(() => {
                    this.notificationsService.success('Attachment successfully attached to Messages');
                    this.modalService.announceAttachmentComplete();
                    this.cancel();
                });
            });
        } else {
            this.notificationsService.error('File is too large to upload.');
            this.cancel();
        }
    }

    addDocs(skipDuplicates = false): void {
        this.fileQueue.forEach((file) => {
            if (skipDuplicates) {
                if (!this.duplicateFiles.some((x) => x.name === file._file.name)) {
                    this.addDoc(file);
                }
            } else {
                this.addDoc(file);
            }
        });
        this.duplicateFiles = [];
        this.fileQueue = [];
        this.modalApi.close();
    }

    checkForDuplicates(event: FileItem): Observable<number> {
        const uploadedFile = event._file;
        return this.docService.checkForDuplicates(this.documentIds, uploadedFile);
    }

    addAllFiles(files: FileItem[]): void {
        this.fileQueue = files;
        const checkForDuplicatesObservableArray = files.map((f) => {
            return this.checkForDuplicates(f);
        });
        forkJoin(...checkForDuplicatesObservableArray).subscribe((data) => {
            const [...returnData] = data;
            returnData.forEach((numberOfDuplicates, index) => {
                if (numberOfDuplicates > 0) {
                    this.duplicateFiles.push(files[index]._file);
                }
            });
            if (this.duplicateFiles.length > 0) {
                this.modalApi.show();
            } else {
                this.addDocs();
            }
        });
    }
}
