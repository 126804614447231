<div>
    <div class="row">
        <div class="col-md-7">
            <h2>
                <mt-route-back-button></mt-route-back-button>
                {{ header }}
                <mt-audit-logs-for-record *ngIf="task" [recordId]="task.AttachmentId" entity="DboAttachment">
                    <ng-template #auditModalTriggerTemplate let-auditModalTemplateApi>
                        <button class="btn btn-primary" (click)="auditModalTemplateApi.showLogsModal($event)">Attachment Audit Log</button>
                    </ng-template>
                </mt-audit-logs-for-record>
                <mt-audit-logs-for-record *ngIf="task" [recordId]="task.Id" entity="DboTask">
                    <ng-template #auditModalTriggerTemplate let-auditModalTemplateApi>
                        <button class="btn btn-primary" (click)="auditModalTemplateApi.showLogsModal($event)">Task Audit Log</button>
                    </ng-template>
                </mt-audit-logs-for-record>
                <button class="btn btn-primary" (click)="showAudits()">Get Update Reason Logs</button>
            </h2>
        </div>
    </div>
</div>
<router-outlet></router-outlet>