import { Component, OnInit } from '@angular/core';
import { NavComponent } from '@mt-ng2/nav-module';
@Component({
    selector: 'app-custom-nav',
    template: `
        <app-custom-nav-header></app-custom-nav-header>
        <app-custom-nav-sidebar></app-custom-nav-sidebar>
    `,
})
export class CustomNavComponent extends NavComponent {}
