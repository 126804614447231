<mt-modal-wrapper [options]="modalOptions" (cancelClick)="cancel()">
    <div class="miles-form padded">
        <div class="display-padded dynamic-field">
            <label for="selectedClients">Clients </label>
            <div>
                <ng-select
                    #clientField
                    class="clientContainer"
                    [items]="(clientSubject$ | async) ? (clients$ | async) : clients"
                    bindLabel="Name"
                    [closeOnSelect]="false"
                    [virtualScroll]="true"
                    [typeahead]="clientSubject$"
                    [multiple]="true"
                    [hideSelected]="true"
                    [(ngModel)]="selectedClients"
                    [clearSearchOnAdd]="true"
                    name="selectedClients"
                    (ngModelChange)="filterOutDuplicateClientsByAltName()"
                >
                    <ng-template ng-label-tmp let-item="item" let-clear="clear" class="clientContainer">
                        <div class="typeaheadSelected">
                            <span aria-hidden="true" class="ng-value-icon left" (click)="clear(item)">×</span>
                            <span class="ng-value-label" title="{{item.Name}}">{{item.Name}}</span>
                        </div>
                    </ng-template>

                    <div class="break"></div>
                    <ng-template ng-option-tmp let-item="item">
                        <div>
                            <span class="ng-option-label typeaheadOption" title="{{item.Name}}">{{item.Name}}</span>
                        </div>
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </div>
    <div style="float: right; margin-top: 20px;">
        <button type="button" class="btn btn-flat btn-success" (click)="addDeals()">
            Save
        </button>
        <button type="button" class="btn btn-flat btn-default" (click)="cancel()">
            Cancel
        </button>
    </div>
</mt-modal-wrapper>
