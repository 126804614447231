import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { IMessageTag } from '../../model/interfaces/message-tag';
import { TagColorComponent } from '../color-selector/message-tag-color-dynamic-cell';

export class MessageTagsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Name',
                }),
                new EntityListColumn({
                    component: TagColorComponent,
                    fireOnColumnSelected: true,
                    name: 'Color',
                    sort: { disableSort: true },
                    style: { width: 140 },
                }),
            ],
            delete: new EntityListDeleteColumn({
                columnHtml: (entity: IMessageTag) =>
                    '<div class="text-center">' +
                    (!entity.MessageUnArchivableTags || !entity.MessageUnArchivableTags.length
                        ? '<i class="fa fa-lg fa-trash" aria-hidden="true"></i>'
                        : '') +
                    (entity.IsDeleted ? '<i class="fa fa-lg fa-undo overlay-fa"></i>' : '') +
                    '</div>',
                confirm: false,
                headerText: 'Archive',
            }),
        };
        super(listConfig);
    }
}
