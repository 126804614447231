<div #replyScrollAnchor></div>
<div class="emailBodyContainer" [class.emailInModal]="isInModal" (click)="suppress($event)">
    <div *ngIf="replyMessage && replyMessage.ReplyTypeId" class="panel panel-default panel-body emailView"
        [class.emailInModal]="isInModal" (click)="suppress($event)">
        <div class="row">
            <div class="col-md-1" style="display: flex;">
                <button class="btn btn-outline-primary" *ngIf="replyMessage.ReplyTypeId === replyTypes.Reply"><i
                        class="fa fa-reply"></i></button>
                <button class="btn btn-outline-primary" *ngIf="replyMessage.ReplyTypeId === replyTypes.ReplyAll">
                    <i class="fa fa-reply-all"></i>
                </button>
                <button class="btn btn-outline-primary" *ngIf="replyMessage.ReplyTypeId === replyTypes.Forward"><i
                        class="fa fa-forward"></i></button>
                <div ngbDropdown class="d-inline-block">
                    <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle><i
                            class="fa fa-ellipsis-v"></i></button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="overflow: auto; margin: auto;">
                        <button class="btn btn-transparent dropButton" ngbDropdownItem
                            (click)="changeReplyType(replyTypes.Reply)">
                            <i class="fa fa-reply"></i>Reply
                        </button>
                        <button class="btn btn-transparent dropButton" ngbDropdownItem
                            (click)="changeReplyType(replyTypes.ReplyAll)">
                            <i class="fa fa-reply-all"></i> Reply All
                        </button>
                        <button class="btn btn-transparent dropButton" ngbDropdownItem
                            (click)="changeReplyType(replyTypes.Forward)">
                            <i class="fa fa-forward"></i> Forward
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-11">
                <p style="display: flex;">
                    <b style="width: 58px; margin-right: 5px;">To:</b>
                    <app-typeahead-contacts style="width: 100%;" (outputContacts)="updateTo($event)"
                        [priorContacts]="replyMessage.ReplyTypeId !== replyTypes.Forward ? to : ''"
                        [sharedContacts]="sharedContacts" [currUser]="currUser"></app-typeahead-contacts>
                </p>
                <p style="display: flex;">
                    <b style="width: 58px; margin-right: 5px;">CC:</b>
                    <app-typeahead-contacts style="width: 100%;" (outputContacts)="updateCc($event)"
                        [priorContacts]="replyMessage.ReplyTypeId !== replyTypes.Forward ? cc : ''"
                        [sharedContacts]="sharedContacts" [currUser]="currUser"></app-typeahead-contacts>
                </p>
                <p style="display: flex;">
                    <b style="width: 58px; margin-right: 5px;">BCC:</b>
                    <app-typeahead-contacts style="width: 100%;" (outputContacts)="updateBcc($event)"
                        [priorContacts]="replyMessage.ReplyTypeId !== replyTypes.Forward ? bcc : ''"
                        [sharedContacts]="sharedContacts" [currUser]="currUser"></app-typeahead-contacts>
                </p>
                <p style="display: flex;">
                    <b style="width: 58px; margin-right: 5px;">Subject:</b>
                    <input class="form-control" type="text" [(ngModel)]="replyMessage.Subject" />
                </p>
            </div>
        </div>

        <div class="emailBody">
            <textarea placeholder="Email Body" cols="250" rows="5" style="resize: vertical;" name="messageBody"
                [(ngModel)]="replyMessage.Body"></textarea>
        </div>

        <div *ngIf="attachments && attachments.length && replyMessage.ReplyTypeId === replyTypes.Forward"
            class="attachmentDisplay">
            <div *ngFor="let attachment of attachments; let i = index" (click)="suppress($event)"
                class="attachmentContainerList">
                <app-document-display [attachment]="attachment"></app-document-display>
                <span><input type="checkbox" [(ngModel)]="attachment.Include" /></span>
            </div>
        </div>

        <div *ngIf="replyAttachments && replyAttachments.length" class="attachmentDisplay">
            <div *ngFor="let replyAttachment of replyAttachments; let i = index" (click)="suppress($event)"
                class="attachmentContainerList">
                <app-document-display [attachment]="replyAttachment"></app-document-display>
                <span><input type="checkbox" [(ngModel)]="replyAttachment.Include" /></span>
            </div>
        </div>

        <div *ngIf="showAddAttachmentModal">
            <app-replyEmailAttachmentDocument-modal (onCancel)="closeModal($event)"
                (onFileUpload)="onFileUpload($event)"></app-replyEmailAttachmentDocument-modal>
        </div>

        <div class="buttons pull-right">
            <button class="btn" (click)="addAttachment()">Add Attachment</button>
            <button class="btn btn-primary" (click)="send()">Send</button>
            <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
            <button *ngIf="attachments && attachments.length && replyMessage.ReplyTypeId === replyTypes.Forward"
                class="btn btn-secondary" (click)="removeAllAttachments()">
                Remove All Attachments
            </button>
        </div>
    </div>
</div>