import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IDboInvestment } from '../interfaces/dbo-investment';

export interface IDboInvestmentDynamicControlsParameters {
    formGroup?: string;
}

export class DboInvestmentDynamicControls {
    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private dboinvestment?: IDboInvestment, additionalParameters?: IDboInvestmentDynamicControlsParameters) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'DboInvestment';

        this.Form = {
            ArchivedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived By',
                name: 'ArchivedById',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.dboinvestment && this.dboinvestment.ArchivedById) || null,
            }),
            ArchivedOn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived On',
                name: 'ArchivedOn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.dboinvestment && this.dboinvestment.ArchivedOn) || null,
            }),
            IsArchived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Archived',
                name: 'IsArchived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value:
                    this.dboinvestment && this.dboinvestment.hasOwnProperty('IsArchived') && this.dboinvestment.IsArchived !== null
                        ? this.dboinvestment.IsArchived
                        : false,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(200)],
                validators: { required: true, maxlength: 200 },
                value:
                    this.dboinvestment && this.dboinvestment.hasOwnProperty('Name') && this.dboinvestment.Name !== null
                        ? this.dboinvestment.Name.toString()
                        : '',
            }),
            Note: new DynamicField({
                formGroup: this.formGroup,
                label: 'Note',
                name: 'Note',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(800)],
                validators: { maxlength: 800 },
                value:
                    this.dboinvestment && this.dboinvestment.hasOwnProperty('Note') && this.dboinvestment.Note !== null
                        ? this.dboinvestment.Note.toString()
                        : '',
            }),
        };

        this.View = {
            ArchivedById: new DynamicLabel({
			    label: 'Archived By',
			    value: (this.dboinvestment && this.dboinvestment.ArchivedById) || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            ArchivedOn: new DynamicLabel({
			    label: 'Archived On',
			    value: (this.dboinvestment && this.dboinvestment.ArchivedOn) || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            IsArchived: new DynamicLabel({
			    label: 'Is Archived',
			    value: this.dboinvestment && this.dboinvestment.hasOwnProperty('IsArchived') && this.dboinvestment.IsArchived !== null
			        ? this.dboinvestment.IsArchived
			        : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Name: new DynamicLabel({
			    label: 'Name',
			    value: this.dboinvestment && this.dboinvestment.hasOwnProperty('Name') && this.dboinvestment.Name !== null
			        ? this.dboinvestment.Name.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Note: new DynamicLabel({
			    label: 'Note',
			    value: this.dboinvestment && this.dboinvestment.hasOwnProperty('Note') && this.dboinvestment.Note !== null
			        ? this.dboinvestment.Note.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };
    }
}
