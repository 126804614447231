// tslint:disable:member-ordering
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { FundService } from '../fund.service';
import { IVwFund } from '../../model/interfaces/vw-fund';
import { ActivatedRoute, Router } from '@angular/router';
import { DboFundAlternativeNameDynamicControlsPartial } from '../../model/partials/dbo-fund-alternative-name-partial.form-controls';
import { DboFundAlternativeNameService } from '../dbo-fund-alternative-name.service';
import { forkJoin } from 'rxjs';
import { IDboFundAlternativeName } from '../../model/interfaces/dbo-fund-alternative-name';
import { AuditModalService } from '../../common/components/view-audit-modal/view-audit-modal-service';

@Component({
    selector: 'app-fund-detail-component',
    templateUrl: './fund-detail.component.html',
})
export class AlternativeFundNameDetailComponent implements OnInit {
    dynamicForm = new DboFundAlternativeNameDynamicControlsPartial().Form;
    selectedFund: IVwFund;
    items: IDboFundAlternativeName[] = [];
    formValue: string;

    constructor(
        private fundService: FundService,
        private noticiationService: NotificationsService,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: AuditModalService,
        public altNameService: DboFundAlternativeNameService,
    ) {}
    ngOnInit(): void {
        // get the id from the route
        const id = this.getIdFromRoute(this.route, 'Id');
        if (id) {
            forkJoin(this.fundService.getById(id), this.altNameService.getByFundId(id)).subscribe((data) => {
                const [selectedFund, altNames] = data;
                this.selectedFund = selectedFund;
                this.items = altNames.body;
            });
        } else {
            void this.router.navigate(['funds']); // if no id found, go back to list
        }
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10)) ? null : parseInt(id, 10);
    }

    saveList(form: UntypedFormGroup): void {
        if (form.valid) {
            this.items = form.value.AlternativeNames;
            this.items.forEach((item) => (item.FundId = this.selectedFund.Id));
            this.altNameService.saveFundNamesById(this.items, this.selectedFund.Id).subscribe(() => {
                this.noticiationService.success('Alternative Fund Name saved successfully.');
            });
        } else {
            markAllFormFieldsAsTouched(form);
            this.noticiationService.error('Save failed.  Please check the form and try again.');
        }
    }
}
