import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IStatesService, IState, ICountriesService, ICountry } from '@mt-ng2/dynamic-form';
import { common } from '@mt-ng2/common-functions';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadTypes } from '../../model/DownloadTypes';
import { appPaths } from '../../default-routes/app-paths.library';
import { Params } from '@angular/router';
import { IDownloadObject } from '../../model/interfaces/custom/download-object';
import { IBlobDownload } from '../../model/interfaces/custom/blob-download';
import { IDboTaskStatus } from '../../model/interfaces/dbo-task-status';

@Injectable()
export class CommonService implements IStatesService, ICountriesService {
    private _states: IState[];
    private _countries: ICountry[];

    constructor(private http: HttpClient) {}

    getStates(): Observable<IState[]> {
        if (!this._states) {
            return this.http.get<IState[]>('/options/states').pipe(
                tap((answer) => {
                    common.sortByProperty(answer, 'Name');
                    this._states = answer;
                }),
            );
        } else {
            return of(this._states);
        }
    }

    getCountries(): Observable<ICountry[]> {
        if (!this._countries) {
            return this.http.get<ICountry[]>('/options/countries').pipe(
                tap((answer) => {
                    common.sortByProperty(answer, 'Name');
                    const indexOfUS = answer.findIndex((country) => country.CountryCode === 'US');
                    answer.splice(0, 0, answer.splice(indexOfUS, 1)[0]);
                    this._countries = answer;
                }),
            );
        } else {
            return of(this._countries);
        }
    }

    getDownloadRoute(type: DownloadTypes, data: any): string {
        return `/#/${appPaths.download}?t=${type}&d=${encodeURIComponent(this.getRouteDataString(type, data))}`;
    }

    private getRouteDataString(type: DownloadTypes, data: any): string {
        switch (type) {
            case DownloadTypes.Attachment:
                return data;
            case DownloadTypes.SupportingDocument:
                return `${String(data[0])}_${String(data[1])}`;
            default:
                return JSON.stringify(data);
        }
    }

    private parseRouteDataString(type: DownloadTypes, dataString: string): any {
        switch (type) {
            case DownloadTypes.Attachment:
                return parseInt(dataString, 10);
            case DownloadTypes.SupportingDocument: {
                const [taskId, docId] = dataString.split('_');
                return [parseInt(taskId, 10), parseInt(docId, 10)];
            }
            default:
                return JSON.parse(dataString);
        }
    }

    parseDownloadRoute(routeParams: Params): IDownloadObject {
        const dlType = +routeParams.t as DownloadTypes;
        return {
            data: this.parseRouteDataString(dlType, routeParams.d as string),
            downloadType: dlType,
        };
    }

    mapBlobDownload = (response: HttpResponse<Blob>): IBlobDownload => {
        return {
            blob: response.body,
            fileName: response.headers.get('X-File-Name'),
        };
    }

    getTaskStatuses(): Observable<IDboTaskStatus[]> {
        return this.http.get<IDboTaskStatus[]>(`/options/task-statuses`);
    }
}
