<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && messageTag.Id > 0"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Tag Info
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit" (click)="edit()"></i>
        </span>
    </h4>
    <mt-dynamic-view [config]="config.viewOnly"></mt-dynamic-view>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Tag Info</h4>
    <div class="container">
        <div class="row nameAndColor">
            <mt-dynamic-field class="name" [form]="form" [field]="name"></mt-dynamic-field>
            <!-- <mt-dynamic-field [form]="form" [field]="inputColor"></mt-dynamic-field> -->
            <span>
                <label>Color *</label>
                <custom-color-picker-control
                    id="colorPicker"
                    [(colorPicker)]="messageTag.Color"
                    cpPosition="left"
                    (click)="$event.stopPropagation()"
                    [cpCmykEnabled]="false"
                    cpFallbackColor="#FFFFFF"
                    (colorPickerSelect)="changeColor($event)"
                    [cpOKButton]="true"
                ></custom-color-picker-control>
            </span>
        </div>
        <div>
            <input [(ngModel)]="messageTag.AutoApplyOnAutomationSet" type="checkbox" />
            <label>Automatically set tag when automation status is set (API)</label>
            <mt-search-filter-select
                *ngIf="messageTag.AutoApplyOnAutomationSet"
                [items]="automationStatusOptions"
                entity="Automation Status"
                showSelectAll="false"
                (selectionChanged)="wasAutomationStatusChanged($event)"
            ></mt-search-filter-select>
        </div>
        <div>
            <input [(ngModel)]="messageTag.AutoApply" type="checkbox" />
            <label>Automatically Set Tag and Apply Rules upon Receipt</label>
        </div>
    </div>
    <div *ngIf="messageTag.AutoApply">
        <h4>Auto Tag Criteria:</h4>
        <div class="display-padded dynamic-field" [ngClass]="{ 'has-error': autoTagError }">
            <input [(ngModel)]="containsKeyWords" type="checkbox" />
            <label>Email Contains Key Words</label>
        </div>
        <div *ngIf="containsKeyWords" class="table-responsive">
            <table class="table table-hover table-striped">
                <thead>
                    <tr>
                        <th>Content</th>
                        <th>In Subject</th>
                        <th>In Body</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody *ngFor="let word of messageTag.MessageTagCriteriaContains; let i = index">
                    <tr>
                        <td>
                            <input type="text" [(ngModel)]="word.Content" style="width: 100%;" />
                        </td>
                        <td><input type="checkbox" [(ngModel)]="word.IsSubjectContains" /></td>
                        <td><input type="checkbox" [(ngModel)]="word.IsBodyContains" /></td>
                        <td>
                            <button class="btn btn-primary" (click)="removeContainWord(word, i)"><i class="fa fa-trash"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td>
                            <input type="text" [(ngModel)]="keywordContainsInput" style="width: 100%;" />
                        </td>
                        <td><input type="checkbox" [(ngModel)]="isSubjectContains" /></td>
                        <td><input type="checkbox" [(ngModel)]="isBodyContains" /></td>
                        <td>
                            <button class="btn btn-primary" (click)="addContainWord($event)"><i class="fa fa-plus"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div style="margin-bottom: 5%;">
            <select [(ngModel)]="messageTag.RequireAllCriteriaGroups" (change)="changeAndOr($event)">
                <option *ngFor="let option of selectMenuAndOr" [value]="option.Value">{{ option.Name }}</option>
            </select>
        </div>

        <div class="display-padded dynamic-field" [ngClass]="{ 'has-error': autoTagError }">
            <input [(ngModel)]="emailFrom" type="checkbox" />
            <label>Email From</label>
        </div>

        <div *ngIf="emailFrom" class="table-responsive">
            <table class="table table-hover table-striped">
                <thead>
                    <tr>
                        <th>Value</th>
                        <th>Email Or Domain</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody *ngFor="let email of messageTag.MessageTagCriteriaFroms; let i = index">
                    <tr>
                        <td>
                            <input *ngIf="email.Email" type="text" [(ngModel)]="email.Email" style="width: 100%;" />
                            <input *ngIf="email.Domain" type="text" [(ngModel)]="email.Domain" style="width: 100%;" />
                        </td>
                        <td>
                            <div *ngIf="email.Email" style="width: 100%;">
                                <p>Email</p>
                            </div>
                            <div *ngIf="email.Domain" style="width: 100%;">
                                <p>Domain</p>
                            </div>
                        </td>
                        <td>
                            <button class="btn btn-primary" (click)="removeEmailFrom(email, i)"><i class="fa fa-trash"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td>
                            <input #emailInput type="text" [(ngModel)]="emailFromInput" style="width: 100%;" />
                        </td>
                        <td>
                            <span class="btn-group btn-group-toggle">
                                <span
                                    (click)="toggleEmailDomain(false, 'from')"
                                    [ngClass]="!emailFromDomainToggle ? 'btn btn-primary active' : 'btn btn-secondary inactiveBtn'"
                                >
                                    <p>Email</p>
                                </span>
                                <span
                                    (click)="toggleEmailDomain(true, 'from')"
                                    [ngClass]="emailFromDomainToggle ? 'btn btn-primary active' : 'btn btn-secondary inactiveBtn'"
                                >
                                    <p>Domain</p>
                                </span>
                            </span>
                        </td>
                        <td>
                            <button class="btn btn-primary" (click)="addFromEmailOrDomain($event)"><i class="fa fa-plus"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="display-padded dynamic-field" [ngClass]="{ 'has-error': autoTagError }">
            <input [(ngModel)]="emailTo" type="checkbox" />
            <label>Email To</label>
        </div>

        <div *ngIf="emailTo" class="table-responsive">
            <table class="table table-hover table-striped">
                <thead>
                    <tr>
                        <th>Value</th>
                        <th>Email Or Domain</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody *ngFor="let email of messageTag.DboMessageTagCriteriaToes; let i = index">
                    <tr>
                        <td>
                            <input *ngIf="email.Email" type="text" [(ngModel)]="email.Email" style="width: 100%;" />
                            <input *ngIf="email.Domain" type="text" [(ngModel)]="email.Domain" style="width: 100%;" />
                        </td>
                        <td>
                            <div *ngIf="email.Email" style="width: 100%;">
                                <p>Email</p>
                            </div>
                            <div *ngIf="email.Domain" style="width: 100%;">
                                <p>Domain</p>
                            </div>
                        </td>
                        <td>
                            <button class="btn btn-primary" (click)="removeToEmail(email, i)"><i class="fa fa-trash"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td>
                            <input #emailInputTo type="text" [(ngModel)]="emailToInput" style="width: 100%;" />
                        </td>
                        <td>
                            <span class="btn-group btn-group-toggle">
                                <span
                                    (click)="toggleEmailDomain(false, 'to')"
                                    [ngClass]="!emailToDomainToggle ? 'btn btn-primary active' : 'btn btn-secondary inactiveBtn'"
                                >
                                    <p>Email</p>
                                </span>
                                <span
                                    (click)="toggleEmailDomain(true, 'to')"
                                    [ngClass]="emailToDomainToggle ? 'btn btn-primary active' : 'btn btn-secondary inactiveBtn'"
                                >
                                    <p>Domain</p>
                                </span>
                            </span>
                        </td>
                        <td>
                            <button class="btn btn-primary" (click)="addToEmailOrDomain($event)"><i class="fa fa-plus"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="display-padded dynamic-field" [ngClass]="{ 'has-error': autoTagError }">
            <input [(ngModel)]="messageTag.AutoApplyOnShoreGroupPattern" type="checkbox" />
            <label>Automatically Set Tag based on Shoregroup email patterns</label>
        </div>

        <div class="display-padded dynamic-field" [ngClass]="{ 'has-error': autoTagError }">
            <input [(ngModel)]="messageTag.AutoApplyOnMessageHaveThreadId" type="checkbox" />
            <label>Automatically Set Tag if Email contains Thread Id</label>
        </div>
    </div>

    <div>
        <h4>Rules:</h4>
        <div class="container ruleContainer">
            <div>
                <input [(ngModel)]="messageTag.IgnoreEmail" type="checkbox" />
                <label>Set Email to No Action</label>
            </div>
            <div>
                <input [(ngModel)]="messageTag.MarkAsTax" type="checkbox" />
                <label>Mark as Tax</label>
            </div>
            <div>
                <input [(ngModel)]="messageTag.AutomationStatusOn" type="checkbox" />
                <label>Turn On Automation</label>
            </div>
            <div>
                <input [(ngModel)]="messageTag.RequiresNote" type="checkbox" />
                <label>Email Requires Note</label>
            </div>

            <div *ngIf="messageTag.RequiresNote && messageTag.AutoApply">
                <label for="note">Select From Standard Note</label>
                <select [ngModel]="selectedNote" (ngModelChange)="selectedNoteChanged($event)">
                    <option [ngValue]="null">Custom Note</option>
                    <option class="form-control" *ngFor="let note of managedNotes" [ngValue]="note">{{
                        note.Note.length > 50 ? note.Note.slice(0, 50) + '...' : note.Note
                    }}</option>
                </select>
            </div>

            <div *ngIf="messageTag.RequiresNote && !messageTag.AutoApply">
                <span>Users will be required to manually add a note when this tag is applied.</span>
            </div>

            <div style="width: 100%;" *ngIf="messageTag.RequiresNote && messageTag.AutoApply">
                <mt-dynamic-view *ngIf="selectedNote && notelabel" [config]="[notelabel]"></mt-dynamic-view>
                <mt-dynamic-field *ngIf="!selectedNote" [form]="form" [field]="note" class="textForm"></mt-dynamic-field>
            </div>
            <div>
                <input [(ngModel)]="forwardTo" type="checkbox" />
                <label>Forward Email To</label>
            </div>
        </div>
        <div *ngIf="forwardTo" class="table-responsive">
            <table class="table table-hover table-striped">
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody *ngFor="let email of messageTag.MessageTagForwards; let i = index">
                    <tr>
                        <td>
                            <input type="text" [(ngModel)]="email.Email" style="width: 100%;" />
                        </td>
                        <td>
                            <button class="btn btn-primary" (click)="removeEmailForward(email, i)"><i class="fa fa-trash"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td>
                            <input #emailForwardInput type="text" [(ngModel)]="emailForwardsInput" style="width: 100%;" />
                        </td>
                        <td>
                            <button class="btn btn-primary" (click)="addEmailForward($event)"><i class="fa fa-plus"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <button
        type="submit"
        mtDisableClickDuringHttpCalls
        Class="btn btn-flat btn-success"
        (click)="formSubmitted(form)"
    >
        Save
    </button>
    <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
        Cancel
    </button>

    <!-- <mt-dynamic-form [config]="config.formObject" ([isEditing])="isEditing" (submitted)="formSubmitted($event)">
        <button type="submit" mtDisableClickDuringHttpCalls
            Class="btn btn-flat btn-success">
            Save
        </button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
            Cancel
        </button>
    </mt-dynamic-form> -->
</div>
