/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LocalStorageKeys } from '../constants/Enum';
import { LocalStorageService } from './local-storage-service';
@Injectable({
    providedIn: 'root',
})
export class AttachmentProcessingService implements OnDestroy {
    messageIdChanges$ = new Subject<any>();
    processingTabChanges$ = new Subject<any>();
    isProcessingTabOpen = false;
    private tabClosedListener: any;
    constructor(public localStorageService: LocalStorageService) {
        this.tabClosedListener = this.storageEventListener.bind(this);
        window.addEventListener('storage', this.tabClosedListener);
    }
    ngOnDestroy(): void {
        window.removeEventListener('storage', this.tabClosedListener);
    }
    private storageEventListener(event: StorageEvent): void {
        if (event.storageArea === localStorage) {
            let v;
            try {
                v = JSON.parse(event.newValue);
            } catch (e) {
                v = event.newValue;
            }
            if (event.key === LocalStorageKeys.selectedMessageIds) {
                this.messageIdChanges$.next({ key: event.key, value: v });
            }
            if (event.key === LocalStorageKeys.isProcessingTabOpen) {
                this.processingTabChanges$.next({ key: event.key, value: v });
            }
        }
    }
    get getIsProcessingTabOpen(): any {
        this.isProcessingTabOpen = this.localStorageService.get(LocalStorageKeys.isProcessingTabOpen);
        return this.isProcessingTabOpen;
    }
    set setIsProcessingTabOpen(value: any) {
        this.localStorageService.set(LocalStorageKeys.isProcessingTabOpen, value);
        this.isProcessingTabOpen = value;
        this.processingTabChanges$.next(value);
    }

    get messageIds(): number[] {
        return this.localStorageService.get(LocalStorageKeys.selectedMessageIds);
    }

    addMessages(messageIds: number[]): void {
        this.localStorageService.set(LocalStorageKeys.selectedMessageIds, messageIds);
        this.messageIdChanges$.next({
            // tslint:disable-next-line: object-literal-sort-keys
            key: LocalStorageKeys.selectedMessageIds,
            type: 'set',
            value: messageIds,
        });
    }

    cleanProcessingLocalStorage(): void {
        this.localStorageService.remove(LocalStorageKeys.isProcessingTabOpen);
        this.localStorageService.remove(LocalStorageKeys.selectedMessageIds);
    }

    cleanProcessingTabLocalStorage(): void {
        this.localStorageService.remove(LocalStorageKeys.isProcessingTabOpen);
    }
}
