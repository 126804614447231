import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    localStorage: Storage;

    constructor() {
        this.localStorage = window.localStorage;
    }

    get(key: string): any {
        if (this.isLocalStorageSupported) {
            return JSON.parse(this.localStorage.getItem(key));
        }
        return null;
    }
    set(key: string, value: any): boolean {
        if (this.isLocalStorageSupported) {
            this.localStorage.setItem(key, JSON.stringify(value));
            return true;
        }
        return false;
    }
    remove(key: string): boolean {
        if (this.isLocalStorageSupported) {
            this.localStorage.removeItem(key);
            return true;
        }
        return false;
    }

    clear(): void {
        this.localStorage.clear();
    }

    get isLocalStorageSupported(): boolean {
        return !!this.localStorage;
    }
}
