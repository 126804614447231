import { Component, OnInit } from '@angular/core';

import { IDboTaskType } from '../../model/interfaces/dbo-task-type';
import { TaskTypeService } from '../../tasks/task-doc-type-service';

@Component({
    templateUrl: './dbo-task-type-add.component.html',
})
export class DboTaskTypeAddComponent implements OnInit {
    dboTaskType: IDboTaskType;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private dbotasktypeService: TaskTypeService) {}

    ngOnInit(): void {
        this.dboTaskType = this.dbotasktypeService.getEmptyTaskType();
    }
}
