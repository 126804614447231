import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuditRoutingModule } from './audit-routing.module';
import { AuditListComponent } from './audit-list/audit-list-component';

@NgModule({
    declarations: [AuditListComponent],
    imports: [SharedModule, AuditRoutingModule, NgSelectModule, DragDropModule],
})
export class AuditModule {}
