import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../model/ClaimTypes';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskHeaderComponent } from './task-header/task-header.component';
import { TaskDetailComponent } from './task-detail/task-detail.component';

const taskListRoleGuard: any = {
    claimType: ClaimTypes.Tasks,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'Id',
    title: 'Library',
};

const taskRoutes: Routes = [
    { path: 'library', component: TaskListComponent, canActivate: [AuthGuard], data: taskListRoleGuard },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: TaskDetailComponent, pathMatch: 'full' }],
        component: TaskHeaderComponent,
        data: taskListRoleGuard,
        path: `library/:${String(taskListRoleGuard.entityIdParam)}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(taskRoutes)],
})
export class TaskRoutingModule {}
