import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef, EventEmitter } from '@angular/core';
import { SharedModule } from '../../common/shared.module';
import { take } from 'rxjs/operators';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IMessageRow } from '../message-list/message-list-component';
import { MessageTagService } from '../../message-tags/services/message-tag.service';
import { IUserWithFullName } from '../../model/partials/user';
import { AssignUserModalComponent } from './assignUserModal';
import { AssignUserUpdateService } from './assignUserUpdateService';
import { UserService } from '../../users/user.service';
import { TenantTypeEnum } from '../../common/constants/Enum';

@Injectable({
    providedIn: SharedModule,
})
export class AssignUserModalService {
    private _componentRef: ComponentRef<AssignUserModalComponent>;

    saved: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        private modalService: AssignUserUpdateService,
        private userService: UserService,
        private notificationService: NotificationsService,
    ) {}

    showModal(selectedMessages: IMessageRow[], users: IUserWithFullName[]): void {
        this.destroyComponent();
        if (users === null) {
            this.userService.getAll().subscribe((allUsers) => {
                users = allUsers;
                users = users.filter((u) => u.AuthUser.HasAccess && u.AuthUser.TenantTypeId === TenantTypeEnum.Internal);
                users.unshift({Id: 0, FirstName: '', LastName: 'Unassigned', AuthUserId: 0, Email: null, Version: [] });
                this.createComponent(selectedMessages, users);
            });
        } else {
            this.createComponent(selectedMessages, users);
        }
    }
    private createComponent(selectedMessages: IMessageRow[], users: IUserWithFullName[]): void {
        this._componentRef = this.componentFactoryResolver.resolveComponentFactory(AssignUserModalComponent).create(this.injector);
        this._componentRef.instance.selectedMessages = selectedMessages;
        this._componentRef.instance.users = users;
        this._componentRef.instance.modalService = this.modalService;
        this._componentRef.instance.notificationService = this.notificationService;
        this._componentRef.instance.onSave.pipe(take(1)).subscribe(() => this.saved.emit());
        this._componentRef.instance.onCancel.pipe(take(1)).subscribe(() => {
            this.destroyComponent();
        });

        this.appRef.attachView(this._componentRef.hostView);
        const domElem = (this._componentRef.hostView as EmbeddedViewRef<AssignUserModalComponent>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
    }

    private destroyComponent(): void {
        if (this._componentRef) {
            this.appRef.detachView(this._componentRef.hostView);
            this._componentRef.destroy();
            this._componentRef = null;
        }
    }
}
