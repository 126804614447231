import { NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { MessageTagRoutingModule } from './message-tag-routing.module';
import { MessageTagAddComponent } from './message-tag-add/message-tag-add.component';
import { MessageTagBasicInfoComponent } from './message-tag-basic-info/message-tag-basic-info.component';
import { MessageTagDetailComponent } from './message-tag-detail/message-tag-detail.component';
import { MessageTagsComponent } from './message-tag-list/message-tags.component';
import { MessageTagHeaderComponent } from './message-tag-header/message-tag-header.component';
import { TagColorComponent } from './color-selector/message-tag-color-dynamic-cell';
import { CustomColorPickerControlComponent } from '../common/components/custom-color-picker/customColorPicker';

@NgModule({
    declarations: [
        MessageTagsComponent,
        MessageTagHeaderComponent,
        MessageTagAddComponent,
        MessageTagDetailComponent,
        MessageTagBasicInfoComponent,
        CustomColorPickerControlComponent,
        TagColorComponent,
    ],
    imports: [SharedModule, MessageTagRoutingModule],
})
export class MessageTagModule {}
