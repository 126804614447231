import {
    TenantAdSettingDynamicControls,
    ITenantAdSettingDynamicControlsParameters as ITenantSettingDynamicControlsParametersBase,
} from '../form-controls/tenant-ad-setting.form-controls';
import { IUserRoleType } from '../interfaces/user-role-type';
import { ITenantAdSetting } from '../interfaces/tenant-ad-setting';
import { DynamicField, DynamicFieldTypes, InputTypes, LabelPosition, LabelPositions } from '@mt-ng2/dynamic-form';

export interface ITenantSettingDynamicControlsParameters extends ITenantSettingDynamicControlsParametersBase {
    roleTypes: IUserRoleType[];
}

export class TenantSettingDynamicControlsPartial extends TenantAdSettingDynamicControls {
    roleTypes: IUserRoleType[];

    constructor(tenantSetting?: ITenantAdSetting, additionalParameters?: ITenantSettingDynamicControlsParameters) {
        super(tenantSetting, additionalParameters);

        this.roleTypes = (additionalParameters && additionalParameters.roleTypes) || undefined;

        (<DynamicField>this.Form.AdDefaultRoleTypeId).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.AdDefaultRoleTypeId).options = this.roleTypes;
        (<DynamicField>this.Form.AdServerPath).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.AdLoginPath).type.inputType = InputTypes.Textbox;

        // Labels
        (<DynamicField>this.Form.AdServerPath).label = 'Server Path';
        (<DynamicField>this.Form.AdLoginPath).label = 'Login Path';
        (<DynamicField>this.Form.AdUserIdAttribute).label = 'User ID Attribute';
        (<DynamicField>this.Form.AdFirstNameField).label = 'First Name Field';
        (<DynamicField>this.Form.AdLastNameField).label = 'Last Name Field';
        (<DynamicField>this.Form.AdEmailField).label = 'Email Field';
        (<DynamicField>this.Form.AdEnabled).label = 'Enabled';
        (<DynamicField>this.Form.AdEnabled).labelPosition = new LabelPosition({ position: LabelPositions.Hidden });
        (<DynamicField>this.Form.AdDefaultRoleTypeId).label = 'Default Role Type';
    }
}
