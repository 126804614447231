import { Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, IDynamicFieldType, IDynamicField, InputTypes, DynamicFieldTypes, DynamicLabel } from '@mt-ng2/dynamic-form';
import { DboAttachmentTagDynamicControls, IDboAttachmentTagDynamicControlsParameters } from '../form-controls/dbo-attachment-tag.form-controls';
import { IDboAttachmentTag } from '../interfaces/dbo-attachment-tag';

export class DboAttachmentTagDynamicControlsPartial extends DboAttachmentTagDynamicControls {

    constructor(dboattachmenttagPartial?: IDboAttachmentTag, additionalParameters?: IDboAttachmentTagDynamicControlsParameters) {
        super(dboattachmenttagPartial, additionalParameters);

        (<DynamicLabel>(
            this.View.Color
        )).valueHtml = `<div style='position:relative'><div style='position:absolute; bottom: 2px; left: 50px; background-color:${dboattachmenttagPartial.Color}; width:100px; height: 15px;'></div></div>`;

        (<DynamicField>this.Form.Color) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Color',
            name: 'Color',
            type: new DynamicFieldType({
                colorpickerOptions: {
                    cpDisableInput: true,
                    cpOKButton: true,
                    cpOutputFormat: 'hex',
                    position: 'bottom',
                    positionOffsetPercentage: 10,
                },
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Colorpicker,
            } as IDynamicFieldType),
            value: dboattachmenttagPartial.Color,
            validation: [Validators.required],
            validators: { 'required': true }
        } as IDynamicField);

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        // (<DynamicField>this.Form.Color).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.Name).type.fieldType = DynamicFieldTypes.Input;

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        // (<DynamicLabel>this.View.Color).label = 'Some other Label';
        // (<DynamicLabel>this.View.Name).label = 'Some other Label';

    }
}
