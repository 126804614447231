<div class="main-header hidden-sm hidden-xs" [hidden]="!showNav">
    <!-- Logo -->
    <a [routerLink]="['/']" class="logo pointer">
        <!-- mini logo for sidebar mini 50x50 pixels -->
        <span class="logo-mini">
            <!-- <img [src]="logoUrl" alt="minilogo" class="logo-img" /> -->
            {{ appName }}
        </span>
        <!-- logo for regular state and mobile devices -->
        <div class="logo-lg">
            <img class="visible-md-block visible-lg-block" [src]="fullLogoUrl" alt="logo" />
            <b>{{ appName }}</b>
        </div>
    </a>

    <!-- Header Navbar: style can be found in header.less -->
    <nav class="navbar navbar-static-top" role="navigation">
        <!-- Navbar Right Menu -->
        <div class="navbar-custom-menu">
            <div class="nav navbar-nav custom-header-area visible-lg-block" [innerHtml]="customHeaderHtml"></div>
            <mt-header-current-user-details></mt-header-current-user-details>
            <mt-header-new-tab></mt-header-new-tab>
            <mt-header-shortcut-help></mt-header-shortcut-help>
            <mt-header-submit-help></mt-header-submit-help>
        </div>
    </nav>
</div>
<!-- header for small size and down -->
<div class="main-header hidden-md hidden-lg hidden-xl" [hidden]="!showNav">
    <nav class="navbar navbar-static-top mobile-navbar" role="navigation">
        <div data-toggle="offcanvas" class="sidebar-toggle" role="button" (click)="toggleSidebarCollapse()">
            <span class="sr-only">Toggle navigation</span>
        </div>
        <a [routerLink]="['/']" class="pointer">
            <span class="logo mobile-app-name">
                <b>{{ appName }}</b>
            </span>
        </a>

        <!-- Navbar Right Menu -->
        <div class="navbar-custom-menu">
            <div class="nav navbar-nav custom-header-area visible-lg-block" [innerHtml]="customHeaderHtml"></div>
            <mt-header-current-user-details></mt-header-current-user-details>
            <mt-header-new-tab></mt-header-new-tab>
            <mt-header-shortcut-help></mt-header-shortcut-help>
            <mt-header-submit-help></mt-header-submit-help>
        </div>
    </nav>
</div>
