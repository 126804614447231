import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { ITaskMsgAuditLog } from '../../model/interfaces/task-msg-audit-log';
import { Observable } from 'rxjs';
import { SearchParams } from '@mt-ng2/common-classes';
import { map } from 'rxjs/operators';
import { CommonService } from '../../common/services/common.service';
import { IBlobDownload } from '../../model/interfaces/custom/blob-download';

export const emptyTaskMsgAuditLog: ITaskMsgAuditLog = {
    Details: null,
    Id: 0,
    LogTypeId: 0,
    Timestamp: null,
    UserId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class TaskMsgAuditLogService extends BaseService<ITaskMsgAuditLog> {
    constructor(public http: HttpClient, private commonService: CommonService) {
        super('/taskmsgauditlogs', http);
    }

    getEmptyTaskMsgAuditLog(): ITaskMsgAuditLog {
        return { ...emptyTaskMsgAuditLog };
    }

    getByMessageId(id: number): Observable<ITaskMsgAuditLog[]> {
        return this.http.get<ITaskMsgAuditLog[]>(`/taskmsgauditlogs/getByMessageId/${id}`);
    }

    getByTaskId(id: number): Observable<ITaskMsgAuditLog[]> {
        return this.http.get<ITaskMsgAuditLog[]>(`/taskmsgauditlogs/getByTaskId/${id}`);
    }

    exportTasks(searchParams: SearchParams): Observable<IBlobDownload> {
        let queryParams = new HttpParams();
        queryParams = queryParams
            .append('Query', searchParams.query)
            .append('skip', searchParams.query)
            .append('take', searchParams.take.toString())
            .append('order', searchParams.order)
            .append('orderdirection', searchParams.orderDirection);

        let extraParams = new HttpParams();
        searchParams.extraParams.forEach((param) => {
            if (param.value !== undefined) {
                extraParams = extraParams.append(param.name, param.value);
            }
            if (param.valueArray !== undefined) {
                const joinedValueArray = param.valueArray.join(',');
                extraParams = extraParams.append(param.name, joinedValueArray);
            }
        });

        queryParams = queryParams.append('extraparams', extraParams.toString());
        return this.http
            .get(`/taskmsgauditlogs/exportAuditLog`, { params: queryParams, observe: 'response', responseType: 'blob' })
            .pipe(map(this.commonService.mapBlobDownload));
    }
}
