import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IMessageStatusSearch } from '../../model/interfaces/message-status-search';

@Injectable({
    providedIn: 'root',
})
export class MessageStatusSearchService extends MetaItemService<IMessageStatusSearch> {
    constructor(public http: HttpClient) {
        super('MessageStatusSearchService', 'Search', 'SearchIds', '/messageStatusSearch', http);
    }
}
