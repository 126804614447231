<div class="table-container">
    <div class="md-col-7" class="generalHeader">
        <h2 mtSetTitle style="width: 85%;">
            <mt-route-back-button></mt-route-back-button>
            Process Attachments
            <button class="btn btn-primary pull-right" [disabled]="!isSubmitButtonActive" (click)="submitAllAttachments()">Process All</button>
        </h2>
    </div>
    <div class="fillBackground"></div>

    <section>
        <div class="container">
            <table class="table table-hover table-striped table-fixed">
                <thead [ngClass]="scrolled ? 'staticHead' : 'hide'">
                    <tr>
                        <td style="width: 100%;" colspan="8"></td>
                    </tr>
                </thead>
                <thead>
                    <tr *ngIf="!scrolled" class="header">
                        <th>#</th>
                        <th>From</th>
                        <th>Subject</th>
                        <th>Email Id</th>
                        <th>Attachment Name</th>
                        <th>Attachment Type</th>
                        <th>Ignore</th>
                        <th>Processed</th>
                    </tr>
                    <tr *ngIf="scrolled" class="header">
                        <th>
                            #
                            <div [ngClass]="scrolled ? 'sticky' : 'hide'">#</div>
                        </th>
                        <th>
                            From
                            <div [ngClass]="scrolled ? 'sticky' : 'hide'">From</div>
                        </th>
                        <th>
                            Subject
                            <div [ngClass]="scrolled ? 'sticky' : 'hide'">Subject</div>
                        </th>
                        <th>
                            Email Id
                            <div [ngClass]="scrolled ? 'sticky' : 'hide'">Email Id</div>
                        </th>
                        <th>
                            Attachment Name
                            <div [ngClass]="scrolled ? 'sticky' : 'hide'">Attachment Name</div>
                        </th>
                        <th>
                            Attachment Type
                            <div [ngClass]="scrolled ? 'sticky' : 'hide'">Attachment Type</div>
                        </th>
                        <th>
                            Ignore
                            <div [ngClass]="scrolled ? 'sticky' : 'hide'">Ignore</div>
                        </th>
                        <th>
                            Processed
                            <div [ngClass]="scrolled ? 'sticky' : 'hide'"><p style="float: right; margin-right: 10px;">Processed</p></div>
                        </th>
                    </tr>
                </thead>
                <tbody *ngFor="let attachment of messagesWithAttachments; let i = index" style="border-bottom: 2px solid black;">
                    <tr>
                        <td>{{ i + 1 }}</td>
                        <td>{{ attachment.SenderName }}</td>
                        <td>{{ attachment.Subject }}</td>
                        <td>{{ attachment.MessageId }}</td>
                        <td>
                            <a [href]="setHref(attachment)" target="_blank">{{ attachment.Name }}</a>
                        </td>
                        <td>{{ attachment.ContentType }}</td>
                        <td>
                            <input
                                *ngIf="!attachment.IsProcessed && !attachment.IsPending"
                                type="checkbox"
                                [(ngModel)]="attachment.IsIgnored"
                                (ngModelChange)="ignoreChanged($event, attachment)"
                            />
                        </td>
                        <td>
                            <i *ngIf="attachment.IsProcessed && !attachment.IsPending" class="fa fa-check"></i>
                            <p *ngIf="attachment.IsPending">Pending</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="8">
                            <app-process-attachment
                                [(allAttachments)]="messagesWithAttachments"
                                [attachment]="attachment"
                                [(fundsList)]="funds"
                                [investmentList]="investments"
                                [allDocTypes]="docTypes"
                                [clientsList]="clients"
                                (updateMessageWithAttachments)="updateMessageWithAttachments($event)"
                            >
                            </app-process-attachment>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</div>
