import { IDboInvestment } from './../../model/interfaces/dbo-investment.d';
import { saveAs } from 'file-saver';
import { ITaskColumns } from './../../model/interfaces/custom/ITaskColumns.d';
import { UserService } from './../../users/user.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FundService } from './../../funds/fund.service';
import { IDboTaskDTO } from './../../model/interfaces/custom/IDboTaskDTO';
import { TasksEntityListConfig } from './task-list-config';
import { TaskService } from './../task.service';
import { Component, OnInit } from '@angular/core';
import { IColumnSortedEvent, SortDirection, IItemDeletedEvent, ISelectionChangedEvent } from '@mt-ng2/entity-list-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { DatePipe } from '@angular/common';
import { TaskTypeService } from '../task-doc-type-service';
import { Observable, Subject, forkJoin } from 'rxjs';
import { IVwClient } from '../../model/interfaces/vw-client';
import { ClientService } from '../../clients/client.service';
import { map } from 'rxjs/operators';
import { IVwFund } from '../../model/interfaces/vw-fund';
import { TenantService } from '../../tenants/tenant.service';
import { TaskSearchService } from '../task-search-service';
import { ITaskSearchObject, TaskSearchObject } from '../../model/partials/task-search';
import { ITaskSearchDTO } from '../../model/interfaces/custom/ITaskSearchDTO';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ClaimTypes } from '../../model/ClaimTypes';
import { DownloadTypes } from '../../model/DownloadTypes';
import { CommonService } from '../../common/services/common.service';
import { InvestmentService } from '../../investments/investment-service';
import {
    DaysOfTheWeek,
    getDateRangeByContextualFilter,
    ISearchFilterDaterangeValue,
} from '../../common/components/date-time/search-filter-daterange/search-filter-daterange.library';
import { BackingEnum, TaskStatuses } from '../../common/constants/Enum';
import { IVwFundWithAltName } from '../../model/interfaces/custom/IVwFundWithAltName';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { TaskCloneResultDTO } from '../../model/interfaces/custom/TaskCloneDTO';
import { IClient } from '../../model/interfaces/custom/client';
import { IReplaceTaskClientDTO } from '../../model/interfaces/custom/IReplaceTaskClientDTO';
import { HttpErrorResponse } from '@angular/common/http';
import { IReplaceTaskFundClientDTO } from '../../model/interfaces/custom/IReplaceTaskFundClientDTO';
import { TenantSearchFilterItem } from '../../model/interfaces/custom/tenant-search-filter-item';
@Component({
    selector: 'app-task-list',
    styleUrls: ['./../../common/cdk-drag-drop-disables.css'],
    templateUrl: './task-list.component.html',
})
export class TaskListComponent implements OnInit {
    configureColumnsModalApi: IModalWrapperApi;
    emailModalApi: IModalWrapperApi;
    saveTaskNameModalApi: IModalWrapperApi;
    replaceClientsModalApi: IModalWrapperApi;
    replaceFundClientsModalApi: IModalWrapperApi;

    query: string;
    tasks: IDboTaskDTO[] = [];
    selectedTasks: IDboTaskDTO[] = [];

    selectedTasksFundClients$ = new Subject<IVwClient[]>();
    selectedTasksClients: IClient[] = [];
    selectedTasksFundClients: IVwClient[] = [];

    selectedOriginalClients: IClient;
    selectedReplacementClients: IVwClient;

    doubleClickIsDisabled = false;

    clientIds: number[] = [];
    fundIds: number[] = [];
    total: number;
    currentPage = 1;

    documentTypes$: Observable<any>;
    documentTypesSubject$ = new Subject<string>();
    documentTypes = [];
    selectedDocumentTypes = [];
    taskTypes: MtSearchFilterItem[] = [];
    taskStatuses : MtSearchFilterItem[] = [];

    usersProcessedBy: MtSearchFilterItem[] = [];
    entityListConfig = null;
    order: any;
    orderDirection: string;
    pipe = new DatePipe('en-US');
    clients$: Observable<IVwClient[]>;
    clients: IVwClient[] = [];
    public clientSubject$ = new Subject<string>();
    clientsList: IVwClient[] = [];
    funds$: Observable<IVwFundWithAltName[]>;
    funds: IVwFundWithAltName[] = [];
    altNamesAsVwFund: IVwFundWithAltName[] = [];
    public fundSubject$ = new Subject<string>();
    fundsList: IVwFundWithAltName[] = [];
    tenants: MtSearchFilterItem[] = [];
    isHighPriority = false;
    includeArchive = false;
    includeTaxDocs = false;
    showTaxFilters = false;
    searchByTaskIDs = '';
    searchByEmailIDs = '';
    searchName: string;
    searchEmail: string;
    savedSearches: ITaskSearchDTO[] = [];
    selectedSearch: ITaskSearchDTO;
    emptySearchObject: ITaskSearchDTO = {
        CreatedDate: null,
        Id: 0,
        LastAccessedDate: null,
        Name: 'Add new search',
        newDocs: 0,
        SearchModel: null,
        searchObject: null,
        UserId: 0,
        VersionId: 1,
    };
    startDueDate: Date;
    endDueDate: Date;
    startStatementDate: Date;
    endStatementDate: Date;
    startReceivedDate: Date;
    endReceivedDate: Date;
    taxFormYear?: number;
    savedSearchId: number;
    taskColumns: ITaskColumns;
    columnList = [];
    hasFullAccess = false;
    canViewAllTasksRegardlessOfStatus = false;
    to: string;
    emailBody: string;
    subject: string;
    dueDateContextualDateId: number;
    receivedDateContextualDateId: number;
    statementDateContextualDateId: number;
    investment$: Observable<IDboInvestment[]>;
    investments: IDboInvestment[] = [];
    public investmentSubject$ = new Subject<string>();
    investmentList: IDboInvestment[] = [];
    elementPerPage = [10, 20, 50, 100];
    selectedElementPerPage = 10;

    masterClients$: Observable<any>;
    masterClientsSubject$ = new Subject<string>();
    masterClients: any[] = [];
    selectedMasterClients = [];

    taskReplacementClient: IVwClient;
    taskReplacementFund: IVwClient;

    constructor(
        public taskService: TaskService,
        public notificationsService: NotificationsService,
        public tenantService: TenantService,
        public clientService: ClientService,
        public taskTypeService: TaskTypeService,
        public fundService: FundService,
        public taskSearchService: TaskSearchService,
        private userService: UserService,
        private claimsService: ClaimsService,
        private commonService: CommonService,
        private investmentService: InvestmentService,
    ) {}

    ngOnInit(): void {
        const previousState = this.taskService.getLatestTaskSearch(this);
        this.clients = previousState.filterData.clients;
        this.funds = previousState.filterData.funds;
        this.investments = previousState.filterData.investments;
        this.tenants = previousState.filterData.tenants;
        this.taskTypes = previousState.filterData.taskTypes;

        this.documentTypes = this.taskTypes.map((t) => t.Item);

        this.usersProcessedBy = previousState.filterData.usersProcessedBy;
        this.tasks = previousState.results;
        this.total = previousState.total;
        forkJoin({
            allUsers: this.userService.getAll(),
            altNames: this.fundService.getAltNames(0),
            defaultTaskColumns: this.userService.getUserDefaultTaskColumns(),
            investments: this.investmentService.getAll(),
            masterClients: this.taskService.getMasterClients(),
            settings: this.taskService.getTaskListSettings(),
            taskColumns: this.userService.getUserSavedTaskColumns(),
            taskTypes: this.taskTypeService.getSearchFilterItems(),
            tenants: this.tenantService.getAll(),
            taskStatuses : this.commonService.getTaskStatuses()
        }).subscribe(({ allUsers, altNames, investments, settings, taskColumns, defaultTaskColumns, taskTypes, tenants, taskStatuses, masterClients }) => {
            this.masterClients = masterClients.map((mc) => {
                return { Item: mc, Selected: false, Name: mc };
            });

            this.showTaxFilters = settings.ShowTaxFilters;
            taskColumns = Object.assign(defaultTaskColumns, taskColumns);
            this.taskColumns = taskColumns;
            this.hasFullAccess = this.claimsService.hasClaim(ClaimTypes.Tasks_SeeAll, [ClaimValues.FullAccess]);
            this.canViewAllTasksRegardlessOfStatus = this.claimsService.hasClaim(
                ClaimTypes.Tasks_canViewAllTasksRegardlessOfStatus as number,
                [ClaimValues.FullAccess]
              );  
              
            this.entityListConfig = new TasksEntityListConfig(
                this.taskColumns,
                this.pipe,
                this.hasFullAccess,
                this.showTaxFilters,
                this.includeTaxDocs,
            );
            this.setColumnsList();
            this.order = this.entityListConfig.getDefaultSortProperty();
            this.orderDirection = this.entityListConfig.getDefaultSortDirection();
            this.taskTypes = taskTypes.filter((element) => !element.Item.IsArchived);
            this.documentTypes = this.taskTypes.map((t) => t.Item);
            this.altNamesAsVwFund = altNames.map((an) => ({
                FundMgrId: 0,
                HasAltNames: false,
                Id: an.FundId,
                isAltName: true,
                IsArchived: false,
                Name: `${an.AlternativeFundName} (${an.OriginalName})`,
            }));
            this.tenants = tenants.filter((it) => it.AccountId || it.AccountId === 0).map((it) => new MtSearchFilterItem(it, false));
            this.usersProcessedBy = allUsers
                .filter((u) => u.AuthUser.HasAccess)
                .map(
                    (u) =>
                        new MtSearchFilterItem(
                            {
                                ...u,
                                Name: u.FirstName + ' ' + u.LastName,
                            },
                            false,
                        ),
                );
            this.investments = investments;
            this.populateFiltersBasedOnSelectedSearch(previousState.filterSelection);
            this.taskStatuses = taskStatuses.map((ts) => new MtSearchFilterItem(ts, false));
        });

        this.clients$ = this.clientSubject$.pipe(map((term) => this.searchClients(term)));
        this.funds$ = this.fundSubject$.pipe(map((term) => this.searchFunds(term)));
        this.investment$ = this.investmentSubject$.pipe(map((term) => this.searchInvestments(term)));
        this.documentTypes$ = this.documentTypesSubject$.pipe(map((term) => this.searchDocumentTypes(term)));
        this.masterClients$ = this.masterClientsSubject$.pipe(map((term) => this.searchMasterClients(term)));
        this.savedSearchId = this.taskService.getLatestSavedSearchId();
        this.getAllSearches();
    }

    private searchClients(term: string | null): IVwClient[] {
        return this.taskService.searchNameList(this.clients, term, this.minSearchClient());
    }

    private searchFunds(term: string | null): IVwFund[] {
        return this.taskService.searchNameList(this.funds, term, this.minSearchFundOrInvestment());
    }

    private searchInvestments(term: string | null): IDboInvestment[] {
        return this.taskService.searchNameList(this.investments, term, this.minSearchFundOrInvestment());
    }

    private searchDocumentTypes(term: string): any {
        return this.taskService.searchNameList(this.documentTypes, term, 1);
    }

    private searchMasterClients(term: string): any {
        return this.taskService.searchNameList(this.masterClients, term, 2);
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    clientsOpen(): void {
        this.clientSubject$.next('');
    }

    fundsOpen(): void {
        this.fundSubject$.next('');
    }

    investmentOpen(): void {
        this.investmentSubject$.next('');
    }

    private isFundOrInvestmentSelected(): boolean {
        return this.includeTaxDocs ? this.investmentList.length > 0 : this.fundsList.length > 0;
    }

    private isClientSelected(): boolean {
        return this.clientsList.length > 0;
    }

    private minSearchClient(): number {
        return this.isFundOrInvestmentSelected() ? 0 : 2;
    }

    private minSearchFundOrInvestment(): number {
        return this.isClientSelected() ? 0 : 2;
    }

    onClientsModelChange(model: IVwClient[]): void {
        this.clientIds = this.clientsList ? this.clientsList.map((element) => element.Id) : [];
        this.taskService.GetFundsByClientIds(this.clientIds).subscribe((response) => {
            this.funds = response.body;
        });
    }

    onFundsModelChange(model: IVwFund[]): void {
        this.fundIds = this.fundsList ? this.fundsList.map((element) => element.Id) : [];
        this.taskService.GetClientsByFundIds(this.fundIds).subscribe((response) => {
            this.clients = response.body;
        });
    }

    onClientChange(model: IVwClient[]): void {
        const clientId: number[] = this.taskReplacementClient ? [this.taskReplacementClient.Id] : [];
        this.taskService.GetFundsByClientIds(clientId).subscribe((response) => {
            this.funds = response.body;
        });
    }

    onFundChange(model: IVwFund[]): void {
        const fundId: number[] = this.taskReplacementFund ? [this.taskReplacementFund.Id] : [];
        this.taskService.GetClientsByFundIds(fundId).subscribe((response) => {
            this.clients = response.body;
        });
    }


    onMasterClientChange(): void {
        const selectedMasterClients: string[] = this.selectedMasterClients.map((c) => c.Item);
        if (selectedMasterClients.length === 0) {
            this.clientsList = [];
        }
        this.taskService.getClientsByMasterClients(selectedMasterClients).subscribe((clients: IVwClient[]) => {
            this.clientsList = clients.filter((c) => this.clientsList.some((cl) => cl.Id === c.Id));
            this.clients = this.appendShortNameClientTypeToClient(clients);
        });
    }

    selectedRecordsPerPage(): void {
        this.currentPage = 1;
        this.getTasks();
    }

    toggleArchive(event: IItemDeletedEvent): void {
        event.entity.IsArchived = !event.entity.IsArchived;
        this.taskService.toggleArchive(+event.entity.Id, Boolean(event.entity.IsArchived).valueOf()).subscribe(() => {
            if (event.entity.IsArchived) {
                this.notificationsService.success('Archived successfully');
            } else {
                this.notificationsService.success('UnArchived successfully');
            }
        });
    }

    setDueDates(event: ISearchFilterDaterangeValue): void {
        this.startDueDate = event.startDate;
        this.endDueDate = event.endDate;
        this.dueDateContextualDateId = 0;
    }

    setStatementDates(event: ISearchFilterDaterangeValue): void {
        this.startStatementDate = event.startDate;
        this.endStatementDate = event.endDate;
        this.statementDateContextualDateId = 0;
    }

    setReceivedDates(event: ISearchFilterDaterangeValue): void {
        this.startReceivedDate = event.startDate;
        this.endReceivedDate = event.endDate;
        this.receivedDateContextualDateId = 0;
    }

    getTasks(): void {
        this.taskService.getTasks(this.returnSearchObject(), this).subscribe((response) => {
            this.selectedTasks = [];
            this.tasks = response.body;
            this.total = +response.headers.get('X-List-Count');
        });
    }

    getAllTasks(): void {
        const searchObj = this.returnSearchObject();
        searchObj.takeAll = true;
        this.taskService.getTasks(searchObj, this).subscribe((response) => {
            this.selectedTasks = [];
            this.tasks = response.body;
            if (this.tasks) {
                this.selectedTasks.push(...this.tasks);
            }
            this.total = +response.headers.get('X-List-Count');
            this.downloadDocuments();
        });
    }

    export(): void {
        const searchObject = this.returnSearchObject();

        if (this.selectedTasks.length > 0) {
            searchObject.taskIds = this.selectedTasks.map((t) => t.Id.toString()).join(',');
        }

        window.open(this.commonService.getDownloadRoute(DownloadTypes.Tasks, searchObject), '_blank');
    }

    commaSeparateList(list: any[]): string {
        return list.length > 0 ? list.join(',') : null;
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getTasks();
    }

    includeArchivedChanged(event: any): void {
        this.includeArchive = event ? true : false;
    }

    isHighPriorityChanged(event: any): void {
        this.isHighPriority = event ? true : false;
    }

    includeTaxDoc(event: any): void {
        this.includeTaxDocs = event ? true : false;
        if (this.includeTaxDocs) {
            this.documentTypes = this.documentTypes.filter((t) => t.TaskTypeBackingId !== BackingEnum.Fund);
        } else {
            this.taskTypeService.getSearchFilterItems().subscribe((data) => {
                this.documentTypes = data.map((d) => d.Item);
            });
        }
        this.entityListConfig = new TasksEntityListConfig(this.taskColumns, this.pipe, this.hasFullAccess, this.showTaxFilters, this.includeTaxDocs);
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getTasks();
    }
    filterSelectionChanged(): void {
        this.currentPage = 1;
    }

    getSelectedTenants(filterObj: MtSearchFilterItem<TenantSearchFilterItem>[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.AccountId);
    }

    returnSearchObject(): ITaskSearchObject {
        return {
            clientIds: this.clientsList ? this.clientsList.map((element) => element.Id) : [],
            documentTypeIds: this.selectedDocumentTypes.length ? this.selectedDocumentTypes.map((i) => i.Id) : [],
            dueDateContextualDateId: this.dueDateContextualDateId,
            email: this.searchEmail,
            emailIds: this.searchByEmailIDs,
            endDueDate: this.endDueDate ? this.pipe.transform(this.endDueDate, 'M/d/yyyy h:mm:ss a') : '',
            endReceivedDate: this.endReceivedDate ? this.pipe.transform(this.endReceivedDate, 'M/d/yyyy h:mm:ss a') : '',
            endStatementDate: this.endStatementDate ? this.pipe.transform(this.endStatementDate, 'M/d/yyyy h:mm:ss a') : '',
            fundIds: this.fundsList ? this.fundsList.map((element) => element.Id) : [],
            includeArchive: this.includeArchive,
            includeTaxDocs: this.includeTaxDocs,
            investmentIds: this.investmentList ? this.investmentList.map((element) => element.Id) : [],
            isHighPriority: this.isHighPriority,
            masterClients: this.selectedMasterClients ? this.selectedMasterClients.map((e) => e.Item) : [],
            name: this.searchName,
            order: this.order,
            orderDirection: this.orderDirection,
            processedByIds: this.usersProcessedBy ? this.getSelectedFilters(this.usersProcessedBy) : [],
            query: this.query,
            receivedDateContextualDateId: this.receivedDateContextualDateId,
            savedSearchId: this.selectedSearch ? this.selectedSearch.Id : 0,
            skip: (this.currentPage - 1) * this.selectedElementPerPage,
            startDueDate: this.startDueDate ? this.pipe.transform(this.startDueDate, 'M/d/yyyy h:mm:ss a') : '',
            startReceivedDate: this.startReceivedDate ? this.pipe.transform(this.startReceivedDate, 'M/d/yyyy h:mm:ss a') : '',
            startStatementDate: this.startStatementDate ? this.pipe.transform(this.startStatementDate, 'M/d/yyyy h:mm:ss a') : '',
            statementDateContextualDateId: this.statementDateContextualDateId,
            take: this.selectedElementPerPage,
            takeAll: false,
            taskIds: this.searchByTaskIDs,
            taxFormYear: this.taxFormYear,
            tenantIds: this.getSelectedFilters(this.tenants),
            taskStatusIds: this.getSelectedFilters(this.taskStatuses)
        };
    }

    populateFiltersBasedOnSelectedSearch(obj: ITaskSearchObject): void {
        this.selectedElementPerPage = obj.take;
        this.currentPage = obj.skip / obj.take + 1;
        this.order = obj.order ? obj.order : this.order;
        this.orderDirection = obj.orderDirection ? obj.orderDirection : this.orderDirection;
        this.query = obj.query;
        this.dueDateContextualDateId = obj.dueDateContextualDateId;
        this.statementDateContextualDateId = obj.statementDateContextualDateId;
        this.receivedDateContextualDateId = obj.receivedDateContextualDateId;

        this.taskService.GetFundsByClientIds(obj.clientIds).subscribe((response) => {
            this.funds = response.body;
            this.fundsList = this.funds.filter((element) => {
                return obj.fundIds.includes(element.Id);
            });
            this.fundIds = this.fundsList ? this.fundsList.map((element) => element.Id) : [];
            const altFundNames = this.altNamesAsVwFund.filter((afn) => this.funds.some((f) => afn.Id === f.Id));
            this.funds = this.funds.concat(altFundNames);
        });

        this.taskService.GetClientsByFundIds(obj.fundIds).subscribe((response) => {
            this.clients = response.body;
            this.clients = this.appendShortNameClientTypeToClient(this.clients);

            this.clientsList = this.clients.filter((element) => {
                return obj.clientIds.includes(element.Id);
            });
            this.clientIds = this.clientsList ? this.clientsList.map((element) => element.Id) : [];
        });

        this.investmentList = this.investments.filter((element) => {
            return obj.investmentIds.includes(element.Id);
        });
        this.searchName = obj.name;
        this.searchEmail = obj.email;
        this.searchByTaskIDs = obj.taskIds;
        this.searchByEmailIDs = obj.emailIds;
        if (obj.dueDateContextualDateId) {
            this.dueDateContextualDateId = obj.dueDateContextualDateId;
            const range = getDateRangeByContextualFilter(obj.dueDateContextualDateId, DaysOfTheWeek.Sunday, this.startDueDate, this.endDueDate);
            this.startDueDate = range.startDate;
            this.endDueDate = range.endDate;
        } else {
            if (obj.startDueDate) {
                this.startDueDate = new Date(obj.startDueDate);
            } else {
                this.startDueDate = null;
            }
            if (obj.endDueDate) {
                this.endDueDate = new Date(obj.endDueDate);
            } else {
                this.endDueDate = null;
            }
        }
        if (obj.statementDateContextualDateId) {
            this.statementDateContextualDateId = obj.statementDateContextualDateId;
            const range = getDateRangeByContextualFilter(
                obj.statementDateContextualDateId,
                DaysOfTheWeek.Sunday,
                this.startStatementDate,
                this.endStatementDate,
            );
            this.startStatementDate = range.startDate;
            this.endStatementDate = range.endDate;
        } else {
            if (obj.startStatementDate) {
                this.startStatementDate = new Date(obj.startStatementDate);
            } else {
                this.startStatementDate = null;
            }
            if (obj.endStatementDate) {
                this.endStatementDate = new Date(obj.endStatementDate);
            } else {
                this.endStatementDate = null;
            }
        }
        if (obj.receivedDateContextualDateId) {
            this.receivedDateContextualDateId = obj.receivedDateContextualDateId;
            const range = getDateRangeByContextualFilter(
                obj.receivedDateContextualDateId,
                DaysOfTheWeek.Sunday,
                this.startReceivedDate,
                this.endReceivedDate,
            );
            this.startReceivedDate = range.startDate;
            this.endReceivedDate = range.endDate;
        } else {
            if (obj.startReceivedDate) {
                this.startReceivedDate = new Date(obj.startReceivedDate);
            } else {
                this.startReceivedDate = null;
            }
            if (obj.endReceivedDate) {
                this.endReceivedDate = new Date(obj.endReceivedDate);
            } else {
                this.endReceivedDate = null;
            }
        }
        this.tenants.forEach((element) => {
            if (obj.tenantIds.includes(+element.Item.Id)) {
                element.Selected = true;
            } else {
                element.Selected = false;
            }
        });

        this.taskStatuses.forEach((element) => {
            if (obj.taskStatusIds.includes(+element.Item.Id)) {
                element.Selected = true;
            } else {
                element.Selected = false;
            }
        });

        this.selectedDocumentTypes = this.documentTypes.filter((i) => obj.documentTypeIds.includes(+i.Id));

        this.usersProcessedBy.forEach((element) => {
            element.Selected = obj.processedByIds && obj.processedByIds.includes(+element.Item.Id);
        });

        this.includeArchive = obj.includeArchive;
        this.includeTaxDocs = obj.includeTaxDocs;
        this.isHighPriority = obj.isHighPriority;
        this.taxFormYear = obj.taxFormYear ? obj.taxFormYear : null;
    }

    appendShortNameClientTypeToClient(clients: IVwClient[]): IVwClient[] {
        clients.forEach((c) => (c.Name = `${c.Name} - (${c.ClientType}) - (${c.ClientTypeValue})`));
        return clients;
    }

    getAllSearches(): void {
        this.taskSearchService.getSavedSearchForUser().subscribe((response) => {
            this.savedSearches = response.body;
            if (this.savedSearches.length < 5) {
                this.savedSearches.push(this.emptySearchObject);
            }
            this.selectedSearch = this.savedSearches.filter((element) => element.Id === this.savedSearchId)[0];
        });
    }

    canSaveSearch(): boolean {
        return (
            this.savedSearches.length > 0 &&
            ((this.selectedSearch && this.selectedSearch.Id > 0) || this.savedSearches.filter((s) => s.Id > 0).length < 5)
        );
    }

    searchTasks(): void {
        this.getTasks();
    }

    saveSearch(): void {
        if (this.canSaveSearch()) {
            this.saveTaskNameModalApi.show();
        }
    }

    confirmSaveSearch(): void {
        if (this.canSaveSearch()) {
            if (this.searchName) {
                this.taskSearchService
                    .saveSearch(new TaskSearchObject(this.returnSearchObject()), this.selectedSearch ? this.selectedSearch.Id : 0)
                    .subscribe((response) => {
                        this.notificationsService.success('SearchSaved');
                        this.savedSearchId = response;
                        this.getAllSearches();
                    });
            } else {
                this.notificationsService.error('Search Name is required');
            }
        }
    }

    deleteSearch(): void {
        if (!this.selectedSearch) {
            this.notificationsService.error('Select the search');
            return;
        }
        this.taskSearchService.deleteSearch(this.selectedSearch.Id).subscribe(() => {
            this.getAllSearches();
            this.notificationsService.success('Successfully deleted search');
            this.searchName = '';
        });
    }

    clearSearch(): void {
        this.selectedSearch = null;
        this.taskService.setLatestSavedSearchId(0);
        this.taskService.clearLatestSearchState();
        this.resetFilters();
    }

    filterDownTaskBasedOnSelectedSearch(): void {
        this.taskService.setLatestSavedSearchId(this.selectedSearch.Id);
        if (this.selectedSearch.Id) {
            this.populateFiltersBasedOnSelectedSearch({
                clientIds: this.selectedSearch.searchObject.clientIds,
                documentTypeIds: this.selectedSearch.searchObject.documentTypeIds,
                dueDateContextualDateId: this.selectedSearch.searchObject.dueDateContextualDateId,
                email: this.selectedSearch.searchObject.email,
                emailIds: this.selectedSearch.searchObject.emailIds,
                endDueDate: this.selectedSearch.searchObject.endDueDate
                    ? this.pipe.transform(this.selectedSearch.searchObject.endDueDate, 'M/d/yyyy h:mm:ss a')
                    : '',
                endReceivedDate: this.selectedSearch.searchObject.endReceivedDate
                    ? this.pipe.transform(this.selectedSearch.searchObject.endReceivedDate, 'M/d/yyyy h:mm:ss a')
                    : '',
                endStatementDate: this.selectedSearch.searchObject.endStatementDate
                    ? this.pipe.transform(this.selectedSearch.searchObject.endStatementDate, 'M/d/yyyy h:mm:ss a')
                    : '',
                fundIds: this.selectedSearch.searchObject.fundIds,
                includeArchive: this.selectedSearch.searchObject.includeArchive,
                includeTaxDocs: this.selectedSearch.searchObject.includeTaxDocs,
                investmentIds: this.selectedSearch.searchObject.investmentIds,
                name: this.selectedSearch.searchObject.name,
                order: this.selectedSearch.searchObject.order,
                orderDirection: this.selectedSearch.searchObject.orderDirection,
                processedByIds: this.selectedSearch.searchObject.processedByIds,
                query: this.selectedSearch.searchObject.query,
                receivedDateContextualDateId: this.selectedSearch.searchObject.receivedDateContextualDateId,
                savedSearchId: this.selectedSearch.Id,
                skip: this.selectedSearch.searchObject.skip,
                startDueDate: this.selectedSearch.searchObject.startDueDate
                    ? this.pipe.transform(this.selectedSearch.searchObject.startDueDate, 'M/d/yyyy h:mm:ss a')
                    : '',
                startReceivedDate: this.selectedSearch.searchObject.startReceivedDate
                    ? this.pipe.transform(this.selectedSearch.searchObject.startReceivedDate, 'M/d/yyyy h:mm:ss a')
                    : '',
                startStatementDate: this.selectedSearch.searchObject.startStatementDate
                    ? this.pipe.transform(this.selectedSearch.searchObject.startStatementDate, 'M/d/yyyy h:mm:ss a')
                    : '',
                statementDateContextualDateId: this.selectedSearch.searchObject.statementDateContextualDateId,
                take: this.selectedSearch.searchObject.take,
                taskIds: this.selectedSearch.searchObject.taskIds,
                taxFormYear: this.selectedSearch.searchObject.taxFormYear,
                tenantIds: this.selectedSearch.searchObject.tenantIds,
                taskStatusIds: this.selectedSearch.searchObject.taskStatusIds
            });
            this.getTasks();
        } else {
            this.resetFilters();
        }
    }

    resetFilters(): void {
        this.populateFiltersBasedOnSelectedSearch(this.taskService.getDefaultTaskSearch(this));
    }
    setColumnsList(): void {
        let addIsTaxDoc = this.showTaxFilters;
        // tslint:disable-next-line:forin
        for (const obj in this.taskColumns) {
            if (obj === 'Tenants' && this.hasFullAccess) {
                this.columnList.push({ name: obj, checked: this.taskColumns[obj], disabled: false });
            } else if (obj === 'IsTaxDoc') {
                addIsTaxDoc = false;
                if (this.showTaxFilters) {
                    this.columnList.unshift({ name: obj, checked: this.taskColumns[obj], disabled: false });
                }
            } else if (obj !== 'Id' && obj !== 'DocumentName' && obj !== 'Delete' && obj !== 'Tenants') {
                this.columnList.push({ name: obj, checked: this.taskColumns[obj], disabled: false });
            }
        }
        if (this.canViewAllTasksRegardlessOfStatus && !Object.prototype.hasOwnProperty.call(this.taskColumns, 'Status')) {
            this.columnList.push({ name: 'Status', checked: false, disabled: false });
        }
        if (addIsTaxDoc) {
            this.columnList.unshift({ name: 'IsTaxDoc', checked: true, disabled: false });
        }
        if (!Object.prototype.hasOwnProperty.call(this.taskColumns, 'TaxFormYear')) {
            this.columnList.push({ name: 'TaxFormYear', checked: true, disabled: false });
        }
        if (!Object.prototype.hasOwnProperty.call(this.taskColumns, 'EmailId')) {
            this.columnList.push({ name: 'EmailId', checked: true, disabled: false });
        }
        if (!Object.prototype.hasOwnProperty.call(this.taskColumns, 'IsPCAPAudited')) {
            this.columnList.push({ name: 'IsPCAPAudited', checked: false, disabled: false });
        }
        if (!Object.prototype.hasOwnProperty.call(this.taskColumns, 'Tags')) {
            this.columnList.push({ name: 'Tags', checked: false, disabled: false });
        }
        if (!Object.prototype.hasOwnProperty.call(this.taskColumns, 'IsDistributionNotice')) {
            this.columnList.push({ name: 'IsDistributionNotice', checked: false, disabled: false });
        }
    }

    saveUserColumns(): void {
        const objToSave = {} as ITaskColumns;
        objToSave.Id = true;
        objToSave.DocumentName = true;
        this.columnList.forEach((element) => {
            objToSave[element.name] = element.checked;
        });
        if (this.hasFullAccess) {
            objToSave.Delete = true;
        } else {
            objToSave.Delete = false;
        }
        this.entityListConfig = new TasksEntityListConfig(objToSave, this.pipe, this.hasFullAccess, this.showTaxFilters, this.includeTaxDocs);
        this.getTasks();
        this.userService.saveUserTaskColumns(objToSave).subscribe((response) => {
            this.taskColumns = objToSave;
            this.notificationsService.success('Column Saved Successfully');
        });
    }

    configureColumns(): void {
        this.configureColumnsModalApi.show();
    }

    drop(event: CdkDragDrop<string[]>): any {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    onSelectionChanged(event: ISelectionChangedEvent): void {
        this.selectedTasks = event.selectedEntities.map((item) => item as IDboTaskDTO);
    }

    downloadDocuments(): void {
        if (this.selectedTasks && this.selectedTasks.length) {
            this.taskService.downloadDocuments(this.selectedTasks.map((t) => t.Id)).subscribe((x) => {
                saveAs(x.blob, x.fileName);
            });
        } else {
            this.notificationsService.error('No task selected');
        }
    }

    downloadAllDocuments(): void {
        this.getAllTasks();
    }

    showEmailDialog(): void {
        if (this.selectedTasks && this.selectedTasks.length) {
            this.to = '';
            this.subject = '';
            this.emailBody = '';
            this.emailModalApi.show();
        } else {
            this.notificationsService.error('No task selected');
        }
    }

    sendEmail(): void {
        this.taskService
            .sendEmail({ Body: this.emailBody, To: this.to, TaskIds: this.selectedTasks.map((t) => t.Id), Subject: this.subject })
            .subscribe(() => {
                this.notificationsService.success('Email Sent successfully');
            });
    }

    setDueDateContextualDateType(newVal: number): void {
        this.dueDateContextualDateId = newVal;
    }

    setStatementDateContextualDateType(newVal: number): void {
        this.statementDateContextualDateId = newVal;
    }

    setReceivedDateContextualDateType(newVal: number): void {
        this.receivedDateContextualDateId = newVal;
    }

    cloneSelectedTasks(): void {
        const taskIds = this.selectedTasks.map((t) => t.Id);
        if (taskIds.length === 0) {
            this.notificationsService.error(`Please Select A Task To Clone`);
            return;
        }
        this.taskService.cloneSelectedTasks(taskIds).subscribe((taskCloneDTO: TaskCloneResultDTO) => {
            const failedTaskIds = taskCloneDTO.FailedTaskIds;
            const successfulTaskIds = taskCloneDTO.SuccessfulTaskIds;
            if (failedTaskIds.length > 0) {
                const failedTasks = failedTaskIds.length === 1 ? failedTaskIds.toString() : failedTaskIds.join(', ');
                this.notificationsService.error(`Following Tasks Failed To Clone: ${failedTasks}`);
            }
            if (successfulTaskIds.length > 0) {
                const successfulTasks = successfulTaskIds.length === 1 ? successfulTaskIds.toString() : successfulTaskIds.join(', ');
                this.notificationsService.success(`Following Tasks Were Cloned Successfully: ${successfulTasks}`);
            }
        });
    }

    markSelectedTasksAsHighPriority(): void {
        const selectedTaskIds = this.selectedTasks.map((t) => t.Id);
        this.taskService.updateTaskPriority(selectedTaskIds).subscribe(
            () => {
                this.notificationsService.success('Tasks marked as high priority.');
            },
            () => {
                this.notificationsService.error('There was an error marking tasks as high priority.');
            },
        );
    }

    showReplaceTaskClientsModal(): void {
        this.taskService.checkIfTaskAttachmentsAreUnique(this.selectedTasks.map((st) => st.Id)).subscribe((result: boolean) => {
            if (result === false) {
                this.notificationsService.warning('Please selects all the tasks associated with this attachment. Wait for 20 min');
                return;
            }

            this.selectedTasksFundClients$.next([]);
            this.selectedTasksClients = [];

            this.selectedOriginalClients = null;
            this.selectedReplacementClients = null;

            if (this.selectedTasks.map((st) => st.Clients).length > 0) {
                const distinctClientsList = this.selectedTasks
                    .map((st) => st.Clients)
                    .reduce((acc, val) => {
                        return acc.filter((c) => val.some((v) => v.Id === c.Id));
                    });

                if (distinctClientsList.length === 0) {
                    this.notificationsService.warning('No common clients found.');
                    return;
                }

                this.selectedTasksClients.push(...distinctClientsList);
            }

            if (this.selectedTasksClients.length > 0) {
                this.taskService.getClientsByTaskFundIds(this.selectedTasks.map((st) => st.Id)).subscribe((clients: IVwClient[]) => {
                    if (clients.length === 0) {
                        this.notificationsService.warning('No replacement clients found.');
                        return;
                    }
                    clients = clients.filter((c) => this.selectedTasksClients.every((sc) => sc.Id !== c.Id));
                    this.selectedTasksFundClients$.next([...clients]);
                    this.replaceClientsModalApi.show();
                });
            }
        });
    }

    showReplaceFundClient(): void{
        this.replaceFundClientsModalApi.show();
    }

    onReplace(): void {
        const taskIds = this.selectedTasks.map((st) => st.Id);
        const originalClients = this.selectedOriginalClients.Id;
        const replacementClientIds = this.selectedReplacementClients.Id;
        const replaceTaskClientDTO: IReplaceTaskClientDTO = {
            OriginalClientIds: [originalClients],
            ReplacementClientIds: [replacementClientIds],
            TaskIds: taskIds,
        };
        this.taskService.replaceTaskClients(replaceTaskClientDTO).subscribe(
            (taskIds) => {
                this.notificationsService.success(`${taskIds.join(', ')} clients replaced successfully`);
                this.getTasks();
            },
            (error: HttpErrorResponse) => {
                this.notificationsService.error(error.error as string);
            },
        );
    }

    onReplaceTaskFundClient(): void{
        if(this.taskReplacementClient === null || this.taskReplacementClient === undefined){
            this.notificationsService.error("Please select a client");
        }
        if(this.taskReplacementFund === null || this.taskReplacementFund === undefined){
            this.notificationsService.error("Please select a fund");
        }
        const taskIds = this.selectedTasks.map((st) => st.Id);
        const replaceTaskFundClientDTO: IReplaceTaskFundClientDTO = {
            newClientId: this.taskReplacementClient.Id,
            newFundId: this.taskReplacementFund.Id,
            taskIds: [...taskIds]
        };
        this.taskService.replaceTaskFundClient(replaceTaskFundClientDTO).subscribe((newTaskIds) => {
            this.notificationsService.success(`Successfully cloned the tasks`);
            this.getTasks();
        }, () => {
            this.notificationsService.error(`Error cloning the tasks`);
        });
    }
}

export interface ITaskRow extends IDboTaskDTO {
    expanded?: boolean;
}

export interface ITaskFilterData {
    clients: IVwClient[];
    funds: IVwFund[];
    investments: IDboInvestment[];
    tenants: MtSearchFilterItem[];
    taskTypes: MtSearchFilterItem[];
    usersProcessedBy: MtSearchFilterItem[];
    taskStatuses: MtSearchFilterItem[];
}
