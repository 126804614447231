import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '../model/ClaimTypes';
import { TenantListComponent } from './tenant-list/tenant-list.component';
import { TenantDetailComponent } from './tenant-detail/tenant-detail.component';
import { TenantService } from './tenant.service';

const tenantEntityConfig: any = {
    claimType: ClaimTypes.Tenants,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    documentsPath: 'documents',
    entityIdParam: 'tenantId',
    service: TenantService,
    title: 'Tenant Detail',
};

const tenantListRoleGuard: any = {
    claimType: ClaimTypes.Tenants,
    claimValues: [ClaimValues.FullAccess],
    title: 'Tenants',
};

export const tenantPaths = {
    tenants: 'tenants',
};

const tenantRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: TenantListComponent,
        data: tenantListRoleGuard,
        path: tenantPaths.tenants,
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: TenantDetailComponent, pathMatch: 'full' }],
        component: TenantDetailComponent,
        data: tenantEntityConfig,
        path: `tenants/:${String(tenantEntityConfig.entityIdParam)}`,
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: TenantDetailComponent, pathMatch: 'full' }],
        component: TenantDetailComponent,
        data: tenantEntityConfig,
        path: `my-tenant`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(tenantRoutes)],
})
export class TenantRoutingModule {}
