import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ChangeDetectorRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription, forkJoin, Observable, Subject } from 'rxjs';
import { IMessageRow } from '../message-list/message-list-component';
import { DboMessageService } from '../services/dbo-message.service';
import { IDboMessage } from '../../model/interfaces/dbo-message';
import { DboMessageDocumentService } from '../services/dbo-message-document-service';
import { IDboAttachment } from '../../model/interfaces/dbo-attachment';
import { IBlobDownload } from '../../model/interfaces/custom/blob-download';
import { MimeTypes } from '../../common/constants/MimeTypes';
import { EmailContactService } from '../../email-contacts/services/email-contact.service';
import { IEmailContact } from '../../model/interfaces/email-contact';
import { map } from 'rxjs/operators';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ReplyMessageService } from './reply-message.service';

@Component({
    selector: 'app-typeahead-contacts',
    styles: [],
    template: `
        <div>
            <ng-select
                #typeahead
                [items]="(contactSubject$ | async) ? (contacts$ | async) : contacts"
                bindLabel="Email"
                [closeOnSelect]="true"
                [virtualScroll]="true"
                [typeahead]="contactSubject$"
                [multiple]="true"
                [hideSelected]="true"
                [(ngModel)]="selectedContacts"
                (ngModelChange)="outputToSharedContacts()"
                name="selectedContacts"
                [clearSearchOnAdd]="true"
                [addTag]="addContact.bind(this)"
            >
                <ng-template ng-label-tmp let-item="item" let-clear="clear" class="clientContainer">
                    <div class="typeaheadSelected">
                        <span aria-hidden="true" class="ng-value-icon left" (click)="clear(item)">×</span>
                        <span class="ng-value-label" title="{{ item.Email }}">{{ item.Email }}</span>
                    </div>
                </ng-template>

                <div class="break"></div>
                <ng-template ng-option-tmp let-item="item">
                    <div>
                        <span class="ng-option-label typeaheadOption" title="{{ item.Email }}">
                            <p>
                                <b>{{ item.FirstName }} {{ item.MiddleName }} {{ item.LastName }}</b>
                            </p>
                            <p style="font-size:11px">{{ item.Email }}</p>
                        </span>
                    </div>
                </ng-template>
            </ng-select>
        </div>
    `,
})
export class TypeAheadContactsComponent implements OnInit, OnChanges {
    @ViewChild('typeahead') ngSelectComponent: NgSelectComponent;
    @Input() priorContacts: string;
    @Input() currUser: ILoggedIn = null;
    @Input() sharedContacts: string[] = [];
    @Output() outputContacts = new EventEmitter<string>();
    selectedContacts: IEmailContact[] = [];
    contacts$: Observable<IEmailContact[]>;
    contacts: IEmailContact[] = [];
    fullContactsList: IEmailContact[] = [];
    public contactSubject$ = new Subject<string>();
    retrievedContacts = false;
    subscription = new Subscription();

    constructor(
        private emailContactService: EmailContactService,
        private notificationService: NotificationsService,
        private authService: AuthService,
        private detectChanges: ChangeDetectorRef,
    ) {}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.priorContacts) {
            this.refreshSelectedContacts();
        }
        if (changes.sharedContacts) {
            this.filterOutSharedContacts();
        }
    }

    ngOnInit(): void {
        this.refreshSelectedContacts();
    }

    searchContacts(term: string | null): any {
        if (term) {
            const returnedItems = this.emailContactService.searchNameList(this.contacts, term, 2);
            if (returnedItems.length) {
                return returnedItems;
            } else {
                if (term[term.length - 1] === ',') {
                    const termRemovedComma = term.slice(0, term.length - 1);
                    this.addContact(termRemovedComma);
                }
            }
        } else {
            return this.selectedContacts;
        }
    }

    getContacts(): void {
        this.emailContactService.getAll().subscribe((data) => {
            this.contacts = data;
            this.fullContactsList = data;
            this.filterOutSharedContacts();
            this.outputToSharedContacts();
            this.contacts$ = this.contactSubject$.pipe(
                map((term) => {
                    return this.searchContacts(term);
                }),
            );
            this.contactSubject$.next('');
        });
    }

    filterOutSharedContacts(): void {
        this.contacts = this.fullContactsList.filter((e) => !this.sharedContacts.some((sc) => sc === e.Email));
    }

    suppress(event: Event): void {
        event.stopPropagation();
    }

    outputToSharedContacts(): void {
        const contactsAsString = this.selectedContacts.map((e) => e.Email).join();
        this.outputContacts.emit(contactsAsString);
    }

    addContact(email: string): void {
        if (this.doesContactAlreadyExist(email)) {
            this.notificationService.error('Email is already a recipient');
        } else {
            const newContact: IEmailContact = {
                CreatedById: this.currUser.Id,
                CreatedOn: new Date(),
                Email: email,
                Id: 0,
                IsArchived: false,
                LastName: email,
            };
            this.emailContactService.create(newContact).subscribe(
                (data) => {
                    this.notificationService.success('New Contact Added');
                    newContact.Id = data;
                    this.contacts.push(newContact);
                    const oldContacts = [...this.selectedContacts];
                    this.selectedContacts = [];
                    this.selectedContacts.push(...oldContacts, newContact);
                    this.getContacts();
                    this.ngSelectComponent.close();
                    this.contactSubject$.next('');
                    this.outputToSharedContacts();
                },
                (err) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    this.notificationService.error(Object.values(err.error).join());
                },
            );
        }
    }

    doesContactAlreadyExist(contact: string): boolean {
        return this.sharedContacts.some((e) => e === contact);
    }

    refreshSelectedContacts(): void {
        this.selectedContacts = [];
        if (this.priorContacts && this.priorContacts.length) {
            const contactsAsArr = this.priorContacts.split(',');
            contactsAsArr.forEach((c) => {
                const newContact: IEmailContact = {
                    CreatedById: 0,
                    CreatedOn: new Date(),
                    Email: c,
                    Id: 0,
                    IsArchived: false,
                    LastName: c,
                };
                this.selectedContacts.push(newContact);
            });
        }
        this.getContacts();
    }
}
