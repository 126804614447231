<div>
    <div *ngIf="!attachment.IsIgnored && !attachment.IsProcessed && !attachment.IsPending" class="miles-editable miles-card padded">
        <div class="row">
            <div class="display-padded">
                <form #taskForm="ngForm">
                    <div class="col-md-5">
                        <div
                            *ngIf="!attachment.metadata.TaskType || !attachment.metadata.TaskType.IsInvestmentOnly"
                            class="display-padded dynamic-field form-input space-for-validation"
                            [ngClass]="{ 'has-error': attachment.validation.fundInvestment }"
                        >
                            <label for="funds">Fund Name*</label>
                            <div style="display: flex; flex-wrap: nowrap; justify-content: space-between;">
                                <ng-select
                                    #fundField
                                    (ngModelChange)="fundSelected($event)"
                                    [(ngModel)]="attachment.metadata.Fund"
                                    [closeOnSelect]="true"
                                    [disabled]="attachment.metadata.Investment"
                                    [hideSelected]="false"
                                    [items]="(fundSubject$ | async) ? (funds$ | async) : funds"
                                    [multiple]="false"
                                    [ngClass]="{ errorBorder: attachment.validation.fundInvestment }"
                                    [typeToSearchText]="fundsList.length ? 'Type to search' : 'Loading...'"
                                    [typeahead]="fundSubject$"
                                    [virtualScroll]="true"
                                    bindLabel="Name"
                                    name="fund"
                                    style="flex-grow: 0.9;"
                                >
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <div class="typeaheadSelected">
                                            <span class="ng-value-label" title="{{ item.Name }}">{{ item.Name }}</span>
                                        </div>
                                    </ng-template>

                                    <div class="break"></div>
                                    <ng-template ng-option-tmp let-item="item">
                                        <div>
                                            <span
                                                class="ng-option-label typeaheadOption"
                                                title="{{ item.Name }}"
                                                [ngStyle]="{ color: item?.isAltName ? '#fb8c00' : '#000000' }"
                                                >{{ item.Name }}</span
                                            >
                                        </div>
                                    </ng-template>
                                </ng-select>
                                <span
                                    ><button class="btn btn-primary" copy-clipboard [copy-to]="attachment.metadata.Fund?.Name">
                                        <i class="fa fa-copy"></i></button
                                ></span>
                            </div>
                        </div>
                        <div *ngIf="attachment.metadata.Fund" class="display-padded">
                            <label for="fundNote"
                                >Fund Note
                                <button
                                    class="btn smallButton"
                                    (click)="updateFundNote($event)"
                                    [disabled]="attachment.metadata.Fund.Note === attachment.initialFundNote"
                                >
                                    Update Note
                                </button></label
                            >
                            <textarea
                                #fundNote
                                name="fundNote"
                                class="form-control"
                                [(ngModel)]="attachment.metadata.Fund.Note"
                                style="resize: vertical; overflow: hidden;"
                                (ngModelChange)="resizeTextArea($event)"
                            ></textarea>
                        </div>

                        <div
                            *ngIf="!attachment.metadata.TaskType || attachment.metadata.TaskType.IsInvestmentOnly"
                            class="display-padded dynamic-field"
                            [ngClass]="{ 'has-error': attachment.validation.fundInvestment }"
                        >
                            <label for="investment">Tax Position*</label>
                            <div style="display: flex; flex-wrap: nowrap; justify-content: space-between;">
                                <ng-select
                                    #investmentField
                                    [items]="(investmentSubject$ | async) ? (investment$ | async) : investments"
                                    [virtualScroll]="true"
                                    bindLabel="Name"
                                    [closeOnSelect]="true"
                                    [multiple]="false"
                                    [hideSelected]="false"
                                    [typeahead]="investmentSubject$"
                                    [(ngModel)]="attachment.metadata.Investment"
                                    name="investment"
                                    (ngModelChange)="investmentSelected($event)"
                                    [ngClass]="{ errorBorder: attachment.validation.fundInvestment }"
                                    [disabled]="attachment.metadata.Fund"
                                    [typeToSearchText]="investmentList.length ? 'Type to search' : 'Loading...'"
                                    style="flex-grow: 0.9;"
                                >
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <div class="typeaheadSelected">
                                            <span class="ng-value-label" title="{{ item.Name }}">{{ item.Name }}</span>
                                        </div>
                                    </ng-template>

                                    <div class="break"></div>
                                    <ng-template ng-option-tmp let-item="item">
                                        <div>
                                            <span class="ng-option-label typeaheadOption" title="{{ item.Name }}">{{ item.Name }}</span>
                                        </div>
                                    </ng-template>
                                </ng-select>
                                <span
                                    ><button class="btn btn-primary" copy-clipboard [copy-to]="attachment.metadata.Investment?.Name">
                                        <i class="fa fa-copy"></i></button
                                ></span>
                            </div>
                        </div>
                        <div *ngIf="attachment.metadata.Investment" class="display-padded">
                            <label for="investmentNote"
                                >Tax Position Note
                                <button
                                    class="btn smallButton"
                                    (click)="updateInvestmentNote($event)"
                                    [disabled]="attachment.metadata.Investment.Note === attachment.initialInvestmentNote"
                                >
                                    Update Note
                                </button></label
                            >
                            <textarea
                                name="investmentNote"
                                class="form-control"
                                [(ngModel)]="attachment.metadata.Investment.Note"
                                style="resize: none;"
                            ></textarea>
                        </div>

                        <div class="display-padded dynamic-field" [ngClass]="{ 'has-error': attachment.validation.docType }">
                            <label for="selectedDoc"
                                >Document Type*
                                <label
                                    *ngIf="attachment.metadata.TaskType && attachment.metadata.TaskType.TierId === 2"
                                    for="pcIncluded"
                                    class="checkLabel"
                                    >PC Included</label
                                >
                                <input
                                    *ngIf="attachment.metadata.TaskType && attachment.metadata.TaskType.TierId === 2"
                                    type="checkbox"
                                    name="pcIncluded"
                                    [(ngModel)]="attachment.metadata.PcIncludedFlag"
                                />
                                <ng-container
                                    *ngIf="
                                        attachment.metadata?.TaskType?.Id && attachment.metadata.TaskType.Id === taskTypePartnersCapitalStatementId
                                    "
                                >
                                    <label for="isPCAPAudited" class="checkLabel">
                                        Is PCAP Audited
                                    </label>
                                    <input type="checkbox" name="isPCAPAudited" [(ngModel)]="attachment.metadata.IsPCAPAudited" />
                                </ng-container>
                            </label>
                            <select
                                #docTypeField
                                name="doctype"
                                [(ngModel)]="attachment.metadata.TaskType"
                                (ngModelChange)="onDocumentTypeChange($event)"
                                name="taskType"
                                class="form-control"
                            >
                                <option [ngValue]="null"></option>
                                <option *ngIf="!filteredDocTypes.length">Loading...</option>
                                <option class="form-control" *ngFor="let taskType of filteredDocTypes" [ngValue]="taskType">{{
                                    taskType.Name
                                }}</option>
                            </select>
                        </div>

                        <div class="display-padded dynamic-field" [ngClass]="{ 'has-error': attachment.validation.clients }">
                            <label for="selectedClients"
                                >Clients*
                                <button class="btn smallButton" (click)="selectAllClients()" [disabled]="disableSelectAllWhileClientsLoad">
                                    Select All
                                </button>
                                <button class="btn smallButton" (click)="unselectAllClients()">Unselect All</button>
                                <span *ngIf="attachment.metadata.Fund" class="smallButton">
                                    <label style="font-weight: normal;" for="includeExited">Include Exited Clients</label>
                                    <input [ngModel]="includeExited" name="includeExited" (ngModelChange)="toggleExited()" type="checkbox" />
                                </span>
                            </label>
                            <div>
                                <ng-select
                                    #clientField
                                    class="clientContainer"
                                    [items]="(clientSubject$ | async) ? (clients$ | async) : attachment.potentialClients"
                                    bindLabel="Name"
                                    [closeOnSelect]="false"
                                    [virtualScroll]="true"
                                    [typeahead]="clientSubject$"
                                    [multiple]="true"
                                    [hideSelected]="false"
                                    [(ngModel)]="attachment.metadata.Clients"
                                    (ngModelChange)="clientSelect($event)"
                                    name="selectedClients"
                                    [ngClass]="{ errorBorder: attachment.validation.clients }"
                                    [typeToSearchText]="clientTypeaheadPlaceholderText"
                                >
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear" class="clientContainer">
                                        <div class="typeaheadSelected">
                                            <span aria-hidden="true" class="ng-value-icon left" (click)="clear(item)">×</span>
                                            <span class="ng-value-label" title="{{ item.Name }}">{{ item.Name }}</span>
                                        </div>
                                    </ng-template>

                                    <div class="break"></div>
                                    <ng-template ng-option-tmp let-item="item">
                                        <div>
                                            <span class="ng-option-label typeaheadOption" title="{{ item.Name }}">{{ item.Name }}</span>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>

                        <div
                            class="display-padded dynamic-field"
                            [ngClass]="{ 'has-error': attachment.validation.dateType }"
                            *ngIf="showDueDate() || attachment.metadata.DueDate"
                        >
                            <label for="dueDate">Due Date*</label>
                            <div class="singleDatePicker">
                                <app-date-picker
                                    #dueDateField
                                    [value]="attachment.metadata.DueDate"
                                    (valueChanged)="dueDateSelection($event)"
                                    [showTimePicker]="false"
                                ></app-date-picker>
                            </div>
                        </div>

                        <div
                            class="display-padded dynamic-field"
                            [ngClass]="{ 'has-error': attachment.validation.dateType }"
                            *ngIf="showStatementDate() || attachment.metadata.StatementDate"
                        >
                            <label for="statementDate">Statement Date*</label>
                            <div>
                                <app-quarter-picker-custom
                                    #statementDateField
                                    [selectedDate]="attachment.metadata.StatementDate"
                                    (onSelectionChanged)="statementDateSelection($event)"
                                ></app-quarter-picker-custom>
                            </div>
                        </div>
                        <div
                            class="display-padded dynamic-field"
                            [ngClass]="{ 'has-error': attachment.validation.dateType }"
                            *ngIf="showTaxFormYear() || attachment.metadata.TaxFormYear"
                        >
                            <label for="taxFormYear">Tax Form Year*</label>
                            <input
                                type="number"
                                class="form-control"
                                [(ngModel)]="taxFormYearValue"
                                (ngModelChange)="taxFormYearSelection($event)"
                                name="taxFormDate"
                            />
                        </div>
                        <div class="displayed-padded" *ngIf="showReceivedDate()">
                            <label for="receivedDate">Received Date*</label>
                            <div>
                                <p>{{ attachment.ReceivedDate | date }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-7">
                        <div class="display-padded" *ngIf="attachmentTag.length > 0">
                            <label for="attachmentTag">Attachment Tag</label>
                            <ng-select
                                name="attachmentTag"
                                [item]="attachmentTag"
                                [(ngModel)]="attachment.metadata.attachmentTagIds"
                                (ngModelChange)="attachmentTagSelected($event)"
                                [multiple]="true"
                            >
                                <ng-option *ngFor="let tag of attachmentTag" [value]="tag.Id">{{ tag.Name }}</ng-option>
                            </ng-select>
                        </div>

                        <div class="display-padded">
                            <label for="newtasknotes">Notes</label>
                            <textarea
                                [rows]="attachment.metadata.Fund || attachment.metadata.Investment ? 5 : 1"
                                name="newtasknotes"
                                class="form-control"
                                [(ngModel)]="attachment.metadata.Notes"
                            ></textarea>
                        </div>

                        <div class="display-padded">
                            <label for="supportingDocs">Supporting Documents</label>

                            <div class="listSupportingDocs" *ngIf="!showAddSupportingDocs">
                                <button class="btn btn-primary" (click)="toggleAddSupportingDocs()">Add Supporting Document</button>
                                <div class="allSupportingDocsContainer">
                                    <span *ngFor="let doc of attachment.metadata.SupportingDocs; let i = index">
                                        <div class="supportDocsContainer">
                                            <button type="button" (click)="removeFromSupportingDocList(i)" class="close xButton" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                            <span class="ng-value-label ng-star-inserted">{{ doc.Name }}</span>
                                        </div>
                                    </span>
                                </div>
                            </div>

                            <div *ngIf="showAddSupportingDocs" class="row">
                                <div *ngIf="allAttachments.length" class="col-md-5">
                                    <h4>Select Attachment From List</h4>
                                    <label for="selectedDoc">Attachments</label>
                                    <select
                                        name="doctype"
                                        [ngModel]="allAttachments"
                                        (ngModelChange)="onSupportingDocChanged($event)"
                                        name="attachment"
                                        class="form-control"
                                    >
                                        <option class="form-control" *ngFor="let attachment of allAttachments" [ngValue]="attachment">{{
                                            attachment.Name
                                        }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <mt-custom-document
                                        [maxFileSize]="maxFileSize"
                                        [allowedMimeType]="allowedMimeType"
                                        (onAfterAddingFile)="addSupportingDoc($event)"
                                        (onAddingFileFailed)="onWhenAddingFileFailed($event)"
                                    ></mt-custom-document>
                                </div>
                            </div>
                        </div>

                        <div class="display-padded">
                            <label for="newTaskPass">Attachment Password</label>
                            <input type="text" class="form-control" name="newTaskPass" maxlength="100" [(ngModel)]="attachment.metadata.Password" />
                        </div>
                        <div class="display-padded">
                            <label for="highPriority">High Priority</label>
                            <input type="checkbox" name="highPriority" [(ngModel)]="attachment.metadata.IsHighPriority" />
                            <span *ngIf="isTaxDocument" style="margin-left: 5px;">
                                <label for="finalNotice">Final Notice</label>
                                <input type="checkbox" name="finalNotice" [(ngModel)]="attachment.metadata.IsFinalNotice" />
                            </span>
                            <span *ngIf="isDistributionNotice" style="margin-left: 5px;">
                                <label for="distributionNotice">Final Distribution</label>
                                <input type="checkbox" name="distributionNotice" [(ngModel)]="attachment.metadata.IsDistributionNotice" />
                            </span>
                        </div>
                        <div class="display-padded" style="margin-top: 5px;">
                            <span class="buttonSetLeft">
                                <button class="btn btn-primary" (click)="copyDownFundDateOnly($event)">Copy Down Fund and Date Only</button>
                                <button class="btn btn-primary" (click)="copyDownAll($event)">Copy Down All</button>
                                <button class="btn btn-primary" (click)="duplicateAttachment()">Duplicate Attachment</button>
                                <button class="btn btn-primary" (click)="addAttachment()">Add Attachment</button>
                            </span>

                            <span class="buttonSetRight">
                                <button class="btn btn-primary" style="background-color: #065388;" (click)="markAttachmentProcessed()">Submit</button>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div *ngIf="attachment.IsPending && (attachment.metadata.Fund || attachment.metadata.Investment)" class="row">
        <div class="col-md-7">
            <span *ngIf="attachment.metadata.Fund" class="pendingDisplay">
                <label>Fund: </label>
                <span>{{ attachment.metadata.Fund.Name }}</span>
            </span>
            <span *ngIf="attachment.metadata.Investment" class="pendingDisplay">
                <label>Tax Position: </label>
                <span>{{ attachment.metadata.Investment.Name }}</span>
            </span>
            <span *ngIf="attachment.metadata.TaskType" class="pendingDisplay">
                <label>Document Type: </label>
                <span>{{ attachment.metadata.TaskType.Name }}</span>
            </span>
            <span *ngIf="attachment.metadata.Clients" class="pendingDisplay" (click)="expandPendingClientsList()">
                <label>Clients: </label>
                <div *ngIf="isPendingClientsListExpanded" title="Click to Collapse">
                    <div *ngFor="let client of attachment.metadata.Clients">- {{ client.Name }}</div>
                </div>
                <div *ngIf="!isPendingClientsListExpanded">
                    <div><i>Click to Expand</i></div>
                </div>
            </span>
            <span *ngIf="showDueDate()" class="pendingDisplay">
                <label>Due Date: </label>
                <span>{{ attachment.metadata.DueDate | date }}</span>
            </span>
            <span *ngIf="showStatementDate()" class="pendingDisplay">
                <label>Due Date: </label>
                <span>{{ attachment.metadata.StatementDate | date }}</span>
            </span>
            <span *ngIf="showTaxFormYear()" class="pendingDisplay">
                <label>Due Date: </label>
                <span>{{ attachment.metadata.TaxFormYear }}</span>
            </span>
            <span *ngIf="showReceivedDate()" class="pendingDisplay">
                <label>Due Date: </label>
                <span>{{ attachment.ReceivedDate | date }}</span>
            </span>
        </div>
        <div class="col-md-5">
            <span class="buttonSetRight" style="margin-right: 8%;">
                <button class="btn btn-primary" style="background-color: darkblue;" (click)="markAttachmentProcessed(false)">Edit</button>
            </span>
        </div>
        <br />
    </div>
    <div class="row">
        <div class="col-md-7" style="margin-top: 5px;" *ngIf="attachment.metadata.RelatedFunds && attachment.metadata.RelatedFunds.length">
            <div *ngIf="attachment.metadata.Clients.length === 1">
                <label>Excluded Related Task Creation: </label>
                <input type="checkbox" [(ngModel)]="attachment.metadata.ExcludeRelatedTaskCreation">
            </div>
            <div>
                <label>Related Funds: </label>
                <p *ngFor="let relatedFund of attachment.metadata.RelatedFunds">
                    {{ relatedFund?.Name }}
                </p>
            </div>
        </div>
        <div class="col-md-7" style="margin-top: 5px;" *ngIf="isAnchorFundAllClientsNotSelected && numberOfRelatedFunds > 0">
            <span>
                <label>Note: Anchor Fund is selected. Tasks for related funds will only be created if all clients are selected.</label>
            </span>
        </div>
    </div>
</div>

<mt-modal-wrapper
    (ready)="similarTasksWarningModal = $event"
    (okClick)="setAttachmentToPendingAndCheckProcessedCount()"
    (cancelClick)="similarTasksWarningModal.close()"
    title="Warning"
    [autoShow]="false"
    [options]="similarTasksModalOptions"
>
    <div>
        <p>A Document has already been saved to this position.</p>
    </div>
    <div>
        <button type="button" (click)="setAttachmentToPendingAndCheckProcessedCount()" Class="btn btn-primary">
            Process Anyway
        </button>
        <button type="button" Class="btn btn-default" (click)="similarTasksWarningModal.close()">
            Close
        </button>
    </div>
</mt-modal-wrapper>

<mt-modal-wrapper
    (ready)="documentTypesWarningModal = $event"
    (okClick)="documentTypesWarningModal.close()"
    title="Warning"
    [autoShow]="false"
    [options]="documentTypesModalOptions"
>
    <div>
        <p>Please check the difference between Audited vs Unaudited vs Estimated</p>
    </div>
</mt-modal-wrapper>

<mt-modal-wrapper
    (ready)="copyDownWarningModal = $event"
    (okClick)="copyDownWarningModal.close()"
    title="Warning"
    [autoShow]="false"
    [options]="copyDownModalOptions"
>
    <div>
        <p>Please check doc type and date - data is pre-filled</p>
    </div>
</mt-modal-wrapper>
