import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IMessageTag } from '../interfaces/message-tag';
import { IDboMessageManagedNote } from '../interfaces/dbo-message-managed-note';

export interface IMessageTagDynamicControlsParameters {
    formGroup?: string;
    managedNotes?: IDboMessageManagedNote[];
}

export class MessageTagDynamicControls {
    formGroup: string;
    managedNotes: IDboMessageManagedNote[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private messagetag?: IMessageTag, additionalParameters?: IMessageTagDynamicControlsParameters) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'MessageTag';
        this.managedNotes = (additionalParameters && additionalParameters.managedNotes) || undefined;

        this.Form = {
            AutoApply: new DynamicField({
                formGroup: this.formGroup,
                label: 'Auto Apply',
                name: 'AutoApply',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value:
                    this.messagetag && this.messagetag.hasOwnProperty('AutoApply') && this.messagetag.AutoApply !== null
                        ? this.messagetag.AutoApply
                        : false,
            }),
            AutoApplyOnAutomationSet: new DynamicField({
                formGroup: this.formGroup,
                label: 'Auto Apply On Automation Set',
                name: 'AutoApplyOnAutomationSet',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value:
                    this.messagetag && this.messagetag.hasOwnProperty('AutoApplyOnAutomationSet') && this.messagetag.AutoApplyOnAutomationSet !== null
                        ? this.messagetag.AutoApplyOnAutomationSet
                        : false,
            }),
            AutoApplyOnMessageHaveThreadId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Auto Apply On Message Have Thread',
                name: 'AutoApplyOnMessageHaveThreadId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value:
                    this.messagetag &&
                    this.messagetag.hasOwnProperty('AutoApplyOnMessageHaveThreadId') &&
                    this.messagetag.AutoApplyOnMessageHaveThreadId !== null
                        ? this.messagetag.AutoApplyOnMessageHaveThreadId
                        : false,
            }),
            AutoApplyOnShoreGroupPattern: new DynamicField({
                formGroup: this.formGroup,
                label: 'Auto Apply On Shore Group Pattern',
                name: 'AutoApplyOnShoreGroupPattern',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value:
                    this.messagetag &&
                    this.messagetag.hasOwnProperty('AutoApplyOnShoreGroupPattern') &&
                    this.messagetag.AutoApplyOnShoreGroupPattern !== null
                        ? this.messagetag.AutoApplyOnShoreGroupPattern
                        : false,
            }),
            AutomationStatusOn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Automation Status On',
                name: 'AutomationStatusOn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value:
                    this.messagetag && this.messagetag.hasOwnProperty('AutomationStatusOn') && this.messagetag.AutomationStatusOn !== null
                        ? this.messagetag.AutomationStatusOn
                        : false,
            }),
            Color: new DynamicField({
                formGroup: this.formGroup,
                label: 'Color',
                name: 'Color',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(7)],
                validators: { required: true, maxlength: 7 },
                value:
                    this.messagetag && this.messagetag.hasOwnProperty('Color') && this.messagetag.Color !== null
                        ? this.messagetag.Color.toString()
                        : '',
            }),
            DeletedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Deleted By',
                name: 'DeletedById',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.messagetag && this.messagetag.DeletedById) || null,
            }),
            DeletedOn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Deleted On',
                name: 'DeletedOn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.messagetag && this.messagetag.DeletedOn) || null,
            }),
            IgnoreEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ignore Email',
                name: 'IgnoreEmail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value:
                    this.messagetag && this.messagetag.hasOwnProperty('IgnoreEmail') && this.messagetag.IgnoreEmail !== null
                        ? this.messagetag.IgnoreEmail
                        : false,
            }),
            IsDeleted: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Deleted',
                name: 'IsDeleted',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value:
                    this.messagetag && this.messagetag.hasOwnProperty('IsDeleted') && this.messagetag.IsDeleted !== null
                        ? this.messagetag.IsDeleted
                        : false,
            }),
            ManagedNoteId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Managed Note',
                name: 'ManagedNoteId',
                options: this.managedNotes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: (this.messagetag && this.messagetag.ManagedNoteId) || null,
            }),
            MarkAsTax: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mark As Tax',
                name: 'MarkAsTax',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value:
                    this.messagetag && this.messagetag.hasOwnProperty('MarkAsTax') && this.messagetag.MarkAsTax !== null
                        ? this.messagetag.MarkAsTax
                        : false,
            }),
            MessageNote: new DynamicField({
                formGroup: this.formGroup,
                label: 'Message Note',
                name: 'MessageNote',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(250)],
                validators: { maxlength: 250 },
                value:
                    this.messagetag && this.messagetag.hasOwnProperty('MessageNote') && this.messagetag.MessageNote !== null
                        ? this.messagetag.MessageNote.toString()
                        : '',
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(50)],
                validators: { required: true, maxlength: 50 },
                value:
                    this.messagetag && this.messagetag.hasOwnProperty('Name') && this.messagetag.Name !== null ? this.messagetag.Name.toString() : '',
            }),
            RequireAllCriteriaGroups: new DynamicField({
                formGroup: this.formGroup,
                label: 'Require All Criteria Groups',
                name: 'RequireAllCriteriaGroups',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value:
                    this.messagetag && this.messagetag.hasOwnProperty('RequireAllCriteriaGroups') && this.messagetag.RequireAllCriteriaGroups !== null
                        ? this.messagetag.RequireAllCriteriaGroups
                        : false,
            }),
            RequiresNote: new DynamicField({
                formGroup: this.formGroup,
                label: 'Requires Note',
                name: 'RequiresNote',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value:
                    this.messagetag && this.messagetag.hasOwnProperty('RequiresNote') && this.messagetag.RequiresNote !== null
                        ? this.messagetag.RequiresNote
                        : false,
            }),
        };

        this.View = {
            AutoApply: new DynamicLabel({
			    label: 'Auto Apply',
			    value: this.messagetag && this.messagetag.hasOwnProperty('AutoApply') && this.messagetag.AutoApply !== null
			        ? this.messagetag.AutoApply
			        : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            AutoApplyOnAutomationSet: new DynamicLabel({
			    label: 'Auto Apply On Automation Set',
			    value: this.messagetag && this.messagetag.hasOwnProperty('AutoApplyOnAutomationSet') && this.messagetag.AutoApplyOnAutomationSet !== null
			        ? this.messagetag.AutoApplyOnAutomationSet
			        : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            AutoApplyOnMessageHaveThreadId: new DynamicLabel({
			    label: 'Auto Apply On Message Have Thread',
			    value: this.messagetag &&
			        this.messagetag.hasOwnProperty('AutoApplyOnMessageHaveThreadId') &&
			        this.messagetag.AutoApplyOnMessageHaveThreadId !== null
			        ? this.messagetag.AutoApplyOnMessageHaveThreadId
			        : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            AutoApplyOnShoreGroupPattern: new DynamicLabel({
			    label: 'Auto Apply On Shore Group Pattern',
			    value: this.messagetag &&
			        this.messagetag.hasOwnProperty('AutoApplyOnShoreGroupPattern') &&
			        this.messagetag.AutoApplyOnShoreGroupPattern !== null
			        ? this.messagetag.AutoApplyOnShoreGroupPattern
			        : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            AutomationStatusOn: new DynamicLabel({
			    label: 'Automation Status On',
			    value: this.messagetag && this.messagetag.hasOwnProperty('AutomationStatusOn') && this.messagetag.AutomationStatusOn !== null
			        ? this.messagetag.AutomationStatusOn
			        : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Color: new DynamicLabel({
			    label: 'Color',
			    value: this.messagetag && this.messagetag.hasOwnProperty('Color') && this.messagetag.Color !== null ? this.messagetag.Color.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DeletedById: new DynamicLabel({
			    label: 'Deleted By',
			    value: (this.messagetag && this.messagetag.DeletedById) || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            DeletedOn: new DynamicLabel({
			    label: 'Deleted On',
			    value: (this.messagetag && this.messagetag.DeletedOn) || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            IgnoreEmail: new DynamicLabel({
			    label: 'Ignore Email',
			    value: this.messagetag && this.messagetag.hasOwnProperty('IgnoreEmail') && this.messagetag.IgnoreEmail !== null
			        ? this.messagetag.IgnoreEmail
			        : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsDeleted: new DynamicLabel({
			    label: 'Is Deleted',
			    value: this.messagetag && this.messagetag.hasOwnProperty('IsDeleted') && this.messagetag.IsDeleted !== null
			        ? this.messagetag.IsDeleted
			        : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ManagedNoteId: new DynamicLabel({
			    label: 'Managed Note',
			    value: getMetaItemValue(this.managedNotes as unknown as IMetaItem[], this.messagetag && this.messagetag.hasOwnProperty('ManagedNoteId') && this.messagetag.ManagedNoteId !== null
			        ? this.messagetag.ManagedNoteId
			        : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            MarkAsTax: new DynamicLabel({
			    label: 'Mark As Tax',
			    value: this.messagetag && this.messagetag.hasOwnProperty('MarkAsTax') && this.messagetag.MarkAsTax !== null
			        ? this.messagetag.MarkAsTax
			        : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            MessageNote: new DynamicLabel({
			    label: 'Message Note',
			    value: this.messagetag && this.messagetag.hasOwnProperty('MessageNote') && this.messagetag.MessageNote !== null
			        ? this.messagetag.MessageNote.toString()
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Name: new DynamicLabel({
			    label: 'Name',
			    value: this.messagetag && this.messagetag.hasOwnProperty('Name') && this.messagetag.Name !== null ? this.messagetag.Name.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            RequireAllCriteriaGroups: new DynamicLabel({
			    label: 'Require All Criteria Groups',
			    value: this.messagetag && this.messagetag.hasOwnProperty('RequireAllCriteriaGroups') && this.messagetag.RequireAllCriteriaGroups !== null
			        ? this.messagetag.RequireAllCriteriaGroups
			        : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            RequiresNote: new DynamicLabel({
			    label: 'Requires Note',
			    value: this.messagetag && this.messagetag.hasOwnProperty('RequiresNote') && this.messagetag.RequiresNote !== null
			        ? this.messagetag.RequiresNote
			        : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };
    }
}
