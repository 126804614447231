import { IAttachmentIncludes } from './../../model/interfaces/custom/attachment-includes.d';
import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    NgZone,
    ViewChild,
    ElementRef,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { IMessageRow } from '../message-list/message-list-component';
import { DboMessageService } from '../services/dbo-message.service';
import { IDboMessage } from '../../model/interfaces/dbo-message';
import { DboMessageDocumentService } from '../services/dbo-message-document-service';
import { IDboAttachment } from '../../model/interfaces/dbo-attachment';
import { IBlobDownload } from '../../model/interfaces/custom/blob-download';
import { MimeTypes } from '../../common/constants/MimeTypes';
import { IReplyMessage } from '../../model/interfaces/reply-message';
import { ReplyMessageService } from './reply-message.service';
import { LogTypeEnum, ReplyTypes } from '../../common/constants/Enum';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IEmailContact } from '../../model/interfaces/email-contact';
import { TaskMsgAuditLogService } from '../../audit/audit-services/task-msg-audit-log.service';
import { ITaskMsgAuditLog } from '../../../app/model/interfaces/task-msg-audit-log';
import { ProcessAttachmentAddModalService } from '../../processAttachments/processAttachment-add-modal/processAttachmentAddModal.service';
import { FileItem } from 'ng2-file-upload';
@Component({
    selector: 'app-reply-email-display',
    styles: [
        `
            .emailView {
                margin-left: 55px;
                /* overflow-wrap: break-word; */
                width: 80vw;
            }
            .emailBody {
                display: flex;
                align-items: bottom;
                flex-wrap: row;
                margin-top: 5px;
            }
            .emailBodyButton {
                margin-top: auto;
                margin-left: 4px;
                float: right;
            }
            .attachmentDisplay {
                display: flex;
                min-width: 0;
                overflow-x: scroll;
            }

            .attachmentDisplay a {
                margin-right: 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .attachmentDisplay input {
                margin-right: 20px;
                margin-left: -20px;
            }

            .emailBodyContainer {
                width: 100vw;
                margin-left: -60px;
                padding: 10px;
                z-index: 1;
                max-height: 800px;
            }

            .buttons {
                margin-top: 5px;
            }
            .emailInModal.emailView {
                width: 80vw;
                margin-left: 35px;
            }

            .emailInModal.emailBodyContainer {
                width: 100vw;
                margin: 10px 10px 10px 10px !important;
            }
        `,
    ],
    templateUrl: './reply-email-display.html',
})
export class ReplyEmailDisplayComponent implements OnInit, OnChanges {
    @Input() message: IMessageRow;
    @Input() replyMessage: IReplyMessage;
    @Input() isInModal = false;
    @Output() messageUpdate = new EventEmitter<IReplyMessage>();
    @ViewChild('replyScrollAnchor') replyScrollAnchor: ElementRef;
    allowedMimeType = MimeTypes;
    singleMessage: IDboMessage;
    attachments: IAttachmentIncludes[] = [];
    subscription: Subscription = new Subscription();
    name: string;
    href: string;
    replyTypes = ReplyTypes;
    currUser: ILoggedIn;
    initialReplySubject = '';
    sharedContacts: string[] = [];

    to = '';
    cc = '';
    bcc = '';
    showAddAttachmentModal = false;
    fileQueue: FileItem[] = [];
    replyAttachments: IAttachmentIncludes[] = [];

    constructor(
        private messageDocumentService: DboMessageDocumentService,
        private authService: AuthService,
        private notificationService: NotificationsService,
        private changeDetect: ChangeDetectorRef,
        private ngZone: NgZone,
        public auditService: TaskMsgAuditLogService,
        private addAttachmentModalService: ProcessAttachmentAddModalService,
    ) {}

    ngOnInit(): void {
        this.initialReplySubject = this.message.Subject;

        this.updateReply();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.replyMessage) {
            this.updateReply();
        }
    }

    updateReply(): void {
        this.updateSelectedContacts();
        this.updateSharedContacts();
        this.messageDocumentService.getDocuments(this.message.EmailId).subscribe((data) => {
            this.attachments = data.body.filter((a) => !this.replyMessage?.DboReplyMessageRemovedAttachments.some((b) => b.AttachmentId === a.Id));
            this.attachments.forEach((a) => (a.Include = true));
            this.authService.currentUser.subscribe((data) => (this.currUser = data));
        });
        if (this.replyScrollAnchor) {
            this.replyScrollAnchor.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    messageDownloadPath(attachment: IDboAttachment): void {
        this.messageDocumentService.getDocumentPath(attachment.MessageId, attachment.Id, true);
    }

    suppress(event: Event): void {
        event.stopPropagation();
    }

    changeReplyType(replyId: number): void {
        this.replyMessage.ReplyTypeId = replyId;
        this.replyMessage.Subject = replyId === this.replyTypes.Forward ? 'FWD: ' + this.initialReplySubject : 'RE: ' + this.initialReplySubject;
        this.updateSelectedContacts();
        this.updateSharedContacts();
    }

    updateSelectedContacts(): void {
        if (this.replyMessage.ReplyTypeId === this.replyTypes.ReplyAll) {
            this.to = this.replyMessage.ToAddress ? this.replyMessage.ToAddress + ',' + this.replyMessage.DboMessage.SenderEmailAddress : '';
            this.cc = this.replyMessage.CcAddress ? this.replyMessage.CcAddress : '';
            this.bcc = this.replyMessage.BccAddress ? this.replyMessage.BccAddress : '';
        } else if (this.replyMessage.ReplyTypeId === this.replyTypes.Reply) {
            this.to = this.replyMessage.ToAddress ? this.replyMessage.DboMessage.SenderEmailAddress : '';
            this.cc = '';
            this.bcc = '';
        } else {
            this.to = '';
            this.cc = '';
            this.bcc = '';
        }
    }

    cancel(): void {
        this.replyMessage.ReplyTypeId = null;
    }

    updateTo(event: string): void {
        this.to = event;
        this.updateSharedContacts();
    }

    updateCc(event: string): void {
        this.cc = event;
        this.updateSharedContacts();
    }

    updateBcc(event: string): void {
        this.bcc = event;
        this.updateSharedContacts();
    }

    updateSharedContacts(): void {
        this.sharedContacts = [...(this.to?.split(',') || []), ...(this.cc?.split(',') || []), ...(this.bcc?.split(',') || [])];
    }

    createAuditLog(): ITaskMsgAuditLog {
        const newAuditEntry = this.auditService.getEmptyTaskMsgAuditLog();
        newAuditEntry.UserId = this.currUser.Id;
        newAuditEntry.MessageId = this.message.EmailId;
        newAuditEntry.Timestamp = new Date();

        if (this.replyMessage.ReplyTypeId === this.replyTypes.Reply || this.replyMessage.ReplyTypeId === this.replyTypes.ReplyAll) {
            newAuditEntry.LogTypeId = LogTypeEnum.MailingReplied;
            newAuditEntry.Details = `${this.currUser.Name} replied to ${this.to}`;
        } else if (this.replyMessage.ReplyTypeId === this.replyTypes.Forward) {
            newAuditEntry.LogTypeId = LogTypeEnum.MailingForwarded;
            newAuditEntry.Details = `${this.currUser.Name} forwarded to ${this.to}`;
        }
        return newAuditEntry;
    }

    removeAllAttachments(): void {
        this.attachments.forEach((a) => (a.Include = false));
        this.replyAttachments.forEach((r) => (r.Include = false));
    }

    filterReplyAttachments(): FileItem[] {
        const selectedFileAttachments = [
            ...this.replyAttachments
                .filter((fa) => fa.Include)
                .map((fa) => {
                    return { FileName: fa.FileName, ContentType: fa.ContentType };
                }),
        ];
        const selectedReplyAttachments = this.fileQueue.filter((file) =>
            selectedFileAttachments.some((t) => t.FileName === file._file.name && t.ContentType === file._file.type),
        );

        return selectedReplyAttachments;
    }

    send(): void {
        this.messageDocumentService
            .sendEmail({
                Bcc: this.bcc,
                Body: this.replyMessage.Body,
                Cc: this.cc,
                IsForwards: this.replyMessage.ReplyTypeId === this.replyTypes.Forward ? true : false,
                MessageId: this.message.EmailId,
                RemovedAttachmentIds: [
                    ...this.attachments.filter((a) => !a.Include).map((a) => a.Id),
                    ...this.replyMessage.DboReplyMessageRemovedAttachments.map((a) => a.AttachmentId),
                ],
                ReplyAttachments: this.filterReplyAttachments().map((file) => file._file),
                Subject: this.replyMessage.Subject,
                TaskIds: [],
                To: this.to,
            })
            .subscribe((newReplyId) => {
                this.notificationService.success('Email Sent');

                // Audit log
                const newAuditEntryList: ITaskMsgAuditLog[] = [];
                newAuditEntryList.push(this.createAuditLog());
                if (newAuditEntryList.length) {
                    this.auditService.updateList(newAuditEntryList).subscribe();
                }

                this.replyAttachments = [];

                if (this.replyMessage.ReplyTypeId === this.replyTypes.Forward) {
                    this.message.WasForwarded = true;
                } else {
                    this.message.WasReplied = true;
                }
                this.replyMessage.ReplyTypeId = null;
                this.replyMessage.Id = newReplyId;
                this.replyMessage.ToAddress = this.to;
                this.replyMessage.CcAddress = this.cc;
                this.replyMessage.BccAddress = this.bcc;
                // eslint-disable-next-line no-self-assign
                this.replyMessage.Body = this.replyMessage.Body;
                this.messageUpdate.emit(this.replyMessage);
            });
    }

    addAttachment(): void {
        this.showAddAttachmentModal = true;
    }

    closeModal(): void {
        this.showAddAttachmentModal = false;
    }

    onFileUpload(files: FileItem[]): void {
        this.fileQueue = files;
        this.fileQueue.forEach((file: FileItem, index: number) => {
            const temp: IAttachmentIncludes = {
                ContentType: file._file.type,
                FileName: file._file.name,
                Id: index,
                Include: true,
                MessageId: this.message.EmailId,
                Name: file._file.name,
            };
            this.replyAttachments.push(temp);
        });
    }
}
