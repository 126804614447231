import { environment } from './../../environments/environment.prod';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { IMessageRow } from '../message-list/message-list-component';
import { DboMessageService } from '../services/dbo-message.service';
import { IDboMessage } from '../../model/interfaces/dbo-message';
import { DboMessageDocumentService } from '../services/dbo-message-document-service';
import { IDboAttachment } from '../../model/interfaces/dbo-attachment';
import { IBlobDownload } from '../../model/interfaces/custom/blob-download';
import { MimeTypes } from '../../common/constants/MimeTypes';
import { ReplyTypes } from '../../common/constants/Enum';
import { IReplyMessage } from '../../model/interfaces/reply-message';
import { IKeyboardShortcutListenerConstructorObject, KeyboardShortcutHandler, KeyboardKeys } from '@mt-ng2/keyboard-shortcuts-module';

import { ReplyMessageService } from '../replyEmails/reply-message.service';
import { IWrapperMessage } from '../../model/interfaces/custom/IWrapperMessage';
import { IDboReplyMessageAttachment } from '../../model/interfaces/dbo-reply-message-attachment';
import { single } from 'rxjs/operators';

@Component({
    selector: 'app-email-body-display',
    styles: [
        `
            .emailViewFirst {
                margin-top: 50px;
                margin-left: 40px;
                /* overflow-wrap: break-word; */
                width: 80vw;
            }
            .emailView {
                margin-left: 55px;
                /* overflow-wrap: break-word; */
                width: 80vw;
            }
            .emailBody {
                display: flex;
                align-items: bottom;
                flex-wrap: row;
                margin-top: 20px;
                resize: vertical;
                overflow: auto;
            }
            .emailBodyButton {
                margin-top: auto;
                margin-left: 4px;
                float: right;
            }
            .attachmentDisplay {
                display: flex;
                min-width: 0;
                flex-wrap: wrap;
            }

            .attachmentDisplay a {
                margin-right: 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .emailBodyContainer {
                width: 100vw;
                margin: 10px 10px 10px -50px;
                z-index: 1;
                /* max-height: 850px; */
            }

            .addressContainer {
                display: flex;
                flex-wrap: wrap;
            }

            .addressContainer p {
                margin-right: 4px;
            }
            .emailInModal.emailViewFirst {
                width: 80vw;
                margin-left: 55px;
            }

            .emailInModal.emailBodyContainer {
                width: 100vw;
                margin: 0px !important;
            }
        `,
    ],
    templateUrl: './email-body-display.html',
})
export class EmailBodyDisplayComponent implements OnInit {
    @Input() message: IMessageRow;
    @Input() replyType = 0;
    @Input() replyMessageId: number = null;
    @Input() isInModal = false;
    @Output() messageUpdate = new EventEmitter<IMessageRow>();
    @Output() replyUpdate = new EventEmitter<IReplyMessage>();
    allowedMimeType = MimeTypes;
    replyTypes = ReplyTypes;
    singleMessage: IWrapperMessage;
    attachments: IDboAttachment[] = [];
    subscription: Subscription = new Subscription();
    name: string;
    href: string;

    isReplyExpanded = false;

    mtKeyboardShortcutDef: Record<string, IKeyboardShortcutListenerConstructorObject> = {
        convertBody: { description: 'convert body', handler: this.convertEmailHotkey(), keyBinding: [KeyboardKeys.Ctrl, 'b'] },
        forward: { description: 'forward', handler: this.forwardHotkey(), keyBinding: [KeyboardKeys.Ctrl, KeyboardKeys.Alt, 'f'] },
        reply: { description: 'reply', handler: this.replyHotkey(), keyBinding: [KeyboardKeys.Ctrl, KeyboardKeys.Alt, 'r'] },
    };

    constructor(
        private messageService: DboMessageService,
        private messageDocumentService: DboMessageDocumentService,
        private changeDetectorRef: ChangeDetectorRef,
        private replyService: ReplyMessageService,
    ) {}

    ngOnInit(): void {
        if (!this.replyMessageId) {
            this.isReplyExpanded = true;
            forkJoin(this.messageService.getById(this.message.EmailId), this.messageDocumentService.getDocuments(this.message.EmailId)).subscribe(
                (data) => {
                    const [singleMessage, attachments] = data;
                    let modifiedHtmlBody = '';
                    if (singleMessage && singleMessage?.HtmlBody) {
                        let htmlBody = singleMessage?.HtmlBody;
                        const originalAnchorTags = htmlBody.match(/<a[^>]*>([^<]+)<\/a>/g);
                        const updatesAnchorTags = originalAnchorTags?.map((x) => x.slice(0, 3) + "target='_blank' " + x.slice(3));

                        originalAnchorTags?.forEach((tag, index) => {
                            const modifiedTag = updatesAnchorTags[index];
                            htmlBody = htmlBody.replace(tag, modifiedTag);
                        });

                        modifiedHtmlBody = htmlBody;

                        const captureCIds = modifiedHtmlBody.match(/(?<=<img[^>]+.*src=")([^">]+)(?=")/g);
                        captureCIds?.forEach((captureCId) => {
                            // * Don't update the image src if it starts with http.
                            if (!captureCId.startsWith('http')) {
                                const cid = captureCId.substring(captureCId.indexOf('cid:') + 4);
                                const imageUrl = this.messageDocumentService.getImagePath(singleMessage.Id, cid);
                                modifiedHtmlBody = modifiedHtmlBody.replace(captureCId, imageUrl);
                            }
                        });
                    }

                    this.singleMessage = {
                        BccAddress: singleMessage.BccAddress,
                        Body: singleMessage.Body,
                        CcAddress: singleMessage.CcAddress,
                        EmailId: singleMessage.Id,
                        From: singleMessage.SenderEmailAddress,
                        HtmlBody: modifiedHtmlBody.length ? modifiedHtmlBody : singleMessage?.HtmlBody,
                        Id: singleMessage.Id,
                        IsHtml: singleMessage.IsHtml,
                        IsReply: false,
                        ReceivedDate: singleMessage.ReceivedDate,
                        Subject: singleMessage.Subject,
                        ToAddress: singleMessage.ToAddress,
                        WasBodyConvertedToAttachment: singleMessage.WasBodyConvertedToAttachment,
                    };
                    this.attachments = attachments.body;
                    this.changeDetectorRef.detectChanges();
                },
            );
        } else {
            forkJoin(
                this.replyService.getById(this.replyMessageId),
                this.messageDocumentService.getDocuments(this.message.EmailId),
                this.messageDocumentService.getReplyDocuments(this.message.EmailId, this.replyMessageId),
            ).subscribe((data) => {
                const [singleMessage, attachments, replyAttachments] = data;
                this.singleMessage = {
                    BccAddress: singleMessage.BccAddress,
                    Body: singleMessage.Body,
                    CcAddress: singleMessage.CcAddress,
                    EmailId: singleMessage.ParentMessageId,
                    From: singleMessage.FromEmail ? singleMessage.FromEmail : environment.defaultEmailFrom,
                    HtmlBody: singleMessage.HtmlBody,
                    Id: singleMessage.Id,
                    IsHtml: singleMessage.IsHtml,
                    IsReply: true,
                    RemovedAttachmentIds: singleMessage.DboReplyMessageRemovedAttachments.map((a) => a.AttachmentId),
                    ReplyTypeId: singleMessage.ReplyTypeId,
                    sentDate: singleMessage.SentDate,
                    Subject: singleMessage.Subject,
                    ToAddress: singleMessage.ToAddress,
                    WasBodyConvertedToAttachment: this.message.WasBodyConvertedToAttachment,
                };
                if (singleMessage.ReplyTypeId === ReplyTypes.Forward) {
                    if (this.singleMessage.RemovedAttachmentIds.length) {
                        this.attachments = attachments.body.filter((a) => !this.singleMessage.RemovedAttachmentIds.some((b) => b === a.Id));
                    } else {
                        this.attachments = attachments.body;
                    }
                }

                replyAttachments.body.forEach((attachment) => {
                    const temp: IDboAttachment = {
                        ContentType: attachment.ContentType,
                        FileName: attachment.FileName,
                        Id: attachment.Id,
                        isReplyAttachment: true,
                        MessageId: this.message.EmailId,
                        Name: attachment.DisplayName,
                    };
                    this.attachments.push(temp);
                });

                this.changeDetectorRef.detectChanges();
            });
        }
    }

    messageDownloadPath(attachment: IDboAttachment): void {
        this.messageDocumentService.getDocumentPath(attachment.MessageId, attachment.Id, true);
    }

    suppress(event: Event): void {
        event.stopPropagation();
    }

    convertToAttachment(event: any): void {
        event.stopPropagation();
        const file = new File([`${this.generateEmailBodyWithChain()}`], `ConvertedEmailBody.doc`, {
            type: 'application/word',
        });
        this.message.AttachmentCount += 1;
        this.singleMessage.WasBodyConvertedToAttachment = true;
        this.messageDocumentService.saveDocument(this.message.EmailId, file, null).subscribe((data) => {
            this.attachments = [...this.attachments, data];
            this.changeDetectorRef.detectChanges();
            if (!this.replyMessageId) {
                this.messageService.updatePartial({ WasBodyConvertedToAttachment: true }, this.singleMessage.Id).subscribe();
            }
        });
    }

    generateEmailBodyWithChain(): string {
        let emailBodyChained = '';
        const primaryEmail = this.emailBodyToHtml(
            this.singleMessage.IsHtml ? this.singleMessage.HtmlBody : this.singleMessage.Body,
            this.singleMessage.IsHtml,
            this.singleMessage.Subject,
            this.singleMessage.From,
            this.singleMessage.ToAddress,
            this.singleMessage.CcAddress,
        );
        emailBodyChained += primaryEmail;
        if (this.message.Replies.length > 0) {
            emailBodyChained += '<br /> <h4>Replies and Forwards</h4> <hr />';
        }
        this.message.Replies.forEach((r) => {
            emailBodyChained += '<br />';
            const reply = this.emailBodyToHtml(
                r.IsHtml ? r.HtmlBody : r.Body,
                r.IsHtml,
                r.Subject,
                r.FromEmail ? r.FromEmail : '',
                r.ToAddress,
                r.CcAddress,
            );
            emailBodyChained += reply;
        });
        return (
            `<html><head><br /><meta http-equiv="Content-Type" content="text/html; charset=utf-8"><meta content="text/html; charset=us-ascii"><meta name="Generator" content="Microsoft Word 15 (filtered medium)"></head>
        <body>` +
            emailBodyChained +
            `</body>
        </html>`
        );
    }

    emailBodyToHtml(body: string, isBodyHtml: boolean, subject: string, from: string, to: string, cc: string): string {
        if (!isBodyHtml) {
            body = body.replace(/(\r\n|\n|\r)/gm, '<br />');
        }
        const emailAsHtml = `
        <p><b>From: </b>${from ? from : environment.defaultEmailFrom}</p>
        <p><b>To: </b>${to}</p>
        <p><b>CC: </b>${cc ? cc : ''}</p>
        <p><b>Subject: </b>${subject}</p>
        <br />
        <div>
        ${isBodyHtml ? body : '<p>' + body + '</p>'}
        </div>
        `;
        return emailAsHtml;
    }

    displayEmailBodyButton(message: IDboMessage): boolean {
        if (message && !this.replyMessageId) {
            if (!message.WasBodyConvertedToAttachment && (message.Body || message.HtmlBody)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    displayReply(replyTypeId: number): void {
        const newReply: IReplyMessage = {
            BccAddress: this.singleMessage.BccAddress,
            Body: '',
            CcAddress: this.singleMessage.CcAddress,
            DboMessage: { ...this.message, SenderEmailAddress: this.message.From },
            DboReplyMessageRemovedAttachments: this.singleMessage.RemovedAttachmentIds
                ? this.singleMessage.RemovedAttachmentIds.map((i) => ({ Id: 0, AttachmentId: i, ReplyMessageId: 0 }))
                : [],
            HtmlBody: '',
            Id: 0,
            IsHtml: false,
            ParentMessageId: this.message.Id,
            ReplyTypeId: replyTypeId,
            Subject: this.message.Subject,
            ToAddress: this.singleMessage.ToAddress,
        };
        this.replyUpdate.emit(newReply);
    }

    forwardHotkey(): KeyboardShortcutHandler {
        return (event: KeyboardEvent) => {
            event.preventDefault();
            if (this.message.EmailId === this.messageService.lastOpenedRow.EmailId) {
                this.displayReply(this.replyTypes.Forward);
            }
        };
    }

    replyHotkey(): KeyboardShortcutHandler {
        return (event: KeyboardEvent) => {
            event.preventDefault();
            if (this.message.EmailId === this.messageService.lastOpenedRow.EmailId) {
                this.displayReply(this.replyTypes.Reply);
            }
        };
    }

    convertEmailHotkey(): KeyboardShortcutHandler {
        return (event: KeyboardEvent) => {
            event.preventDefault();
            if (this.message.EmailId === this.messageService.lastOpenedRow.EmailId) {
                this.convertToAttachment(event);
            }
        };
    }

    updateLastSelectedMessageRow(event: any): void {
        this.messageService.lastOpenedRow = this.message;
    }

    expandReply(event: any): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.suppress(event);
        if (this.replyMessageId) {
            this.isReplyExpanded = !this.isReplyExpanded;
        }
    }
}
