<div class="nav-sidebar-section">
    <div class="main-sidebar sidebar">
        <mt-sidebar-current-user-details></mt-sidebar-current-user-details>
        <div *ngIf="showManageTenant" class="user-panel">
            <a class="tenant-link" [routerLink]="[myTenantPath]">Edit my Tenant</a>
        </div>
        <div class="nav-panel-section" [style.max-height]="navTreeMaxHeight ? navTreeMaxHeight : null">
            <mt-sidebar-content-section></mt-sidebar-content-section>
        </div>
    </div>
</div>
