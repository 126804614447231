import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SharedModule } from '../../common/shared.module';
import { IMessageSearchDTO } from '../../model/interfaces/custom/IMessageSearchDTO';

@Injectable({
    providedIn: SharedModule,
})
export class SaveSearchUpdateService {
    addSearchEvent = new Subject<IMessageSearchDTO>();

    addSearchObservable = this.addSearchEvent.asObservable();

    announceSavedSearch(newSearch: IMessageSearchDTO): void {
        this.addSearchEvent.next(newSearch);
    }
}
