<div class="miles-card padded" [formGroup]="form">
    <div class="form-group" [formArrayName]="cleanTitle">
        <h4>{{ cleanTitle }}</h4>
        <div *ngIf="fields.length === 1" [style.margin-top]="'20px'"></div>
        <div
            *ngIf="fields.length > 1"
            class="container-fluid nopadding"
            [class.sortable-row-margins]="sortable"
            [class.removable-row-margins]="canRemove"
        >
            <div class="row-fluid">
                <div *ngFor="let field of fields" class="editable-row nopadding" [style.width]="colWidth(field)">
                    <label>{{ getLabel(field) }}</label>
                </div>
            </div>
        </div>
        <div *ngIf="sortable" cdkDropList [cdkDropListData]="currentFormArray.controls" (cdkDropListDropped)="drop($event)">
            <div *ngFor="let item of currentFormArray.controls; let i = index" cdkDrag [cdkDragData]="item" [formGroupName]="i">
                <span class="pull-left" cdkDragHandle>
                    <span class="btn btn-default btn-flat move-handle">
                        <span class="fa fa-fw fa-ellipsis-v"></span>
                        <span class="fa fa-fw fa-ellipsis-v second-ellipsis"></span>
                    </span>
                </span>

                <span class="pull-right" *ngIf="canRemove || !item.value.Id">
                    <span class="btn btn-default btn-flat remove-button" (click)="delete(i)">
                        <span class="fa fa-fw fa-remove"></span>
                    </span>
                </span>

                <div
                    class="container-fluid nopadding"
                    [class.sortable-row-margins]="sortable"
                    [class.removable-row-margins]="canRemove || !item.value.Id"
                >
                    <div class="row-fluid">
                        <div *ngFor="let field of fields" class="editable-row input nopadding" [style.width]="colWidth(field)">
                            <mt-dynamic-field [field]="getField(item, field)" [form]="item" [overrideForm]="true"></mt-dynamic-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!sortable">
            <div *ngFor="let item of currentFormArray.controls; let i = index" [formGroupName]="i">
                <span class="pull-right" *ngIf="canRemove || !item.value.Id">
                    <span class="btn btn-default btn-flat remove-button" (click)="delete(i)">
                        <span class="fa fa-fw fa-remove"></span>
                    </span>
                </span>

                <div class="container-fluid nopadding" [class.removable-row-margins]="canRemove || !item.value.Id">
                    <div class="row-fluid">
                        <div *ngFor="let field of fields" class="editable-row input nopadding" [style.width]="colWidth(field)">
                            <mt-dynamic-field [field]="getField(item, field)" [form]="item" [overrideForm]="true"></mt-dynamic-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="hasDuplicateItems()" class="small errortext pull-right" [style.position]="'relative'">
            Duplicate items are not allowed
        </div>
        <div class="add-button fab-wrap" *ngIf="canAdd">
            <button type="button" class="btn btn-default btn-fab-md btn-fab-center" (click)="create()">
                <span class="fa fa-plus"></span>
            </button>
        </div>
        <br />
        <button type="submit" class="btn btn-success" (click)="update()">
            Save
        </button>
    </div>
</div>
